






















import { Component, Vue, Prop } from 'vue-property-decorator';
import OtButton from '@/components/global/ot-button.vue';
import { Organisation } from '../models/organisation-models';

@Component({
  components: {
    OtButton,
  },
})
export default class OtOrganisationDropdownSelector extends Vue {
  // * PROPS

  @Prop({ type: Array, required: true }) orgList!: Organisation[];
  @Prop() private orgId!: string;

  // * REFS

  // * DATA

  private get selectedOrg() {
    return this.orgList.find(org => org.gid === this.orgId);
  }
  // This is to shut the linter up about the selectedOrg.name being possibly undefined in the template
  private get selectedOrgName() {
    return this.selectedOrg?.name || '';
  }

  // * COMPUTED
  get organisationsForDropdown() {
    return this.orgList.map(org => {
      return {
        title: org.name,
        value: org.gid,
      };
    });
  }

  // * WATCHERS

  // * METHODS
  updateSelection(item: { title: string; value: string }) {
    this.$emit('update:org', item.value);
  }

  // * LIFECYCLE
}
