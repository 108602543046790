
















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class OtComingSoonPage extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private data: unknown;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
