



















































































































































import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IAddressDisplayModel, IInputTab } from '@/components/global/common-models';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtSelect, { ISelectItem } from '@/components/global/ot-select.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { OtOrganisationType } from '@/types/organisation-enums';
import { returnAddressString } from '@/utils/address-utils';
import { EMPTY_HELPER_TEXT_STRING } from '@/utils/constants';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass, isEmail } from '@/utils/validation-utils';
import { getTimeZones } from '@vvo/tzdb';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { OrganisationDetailsFormObject, OtOrganisationTypesMap } from '../models/organisation-models';
import { ROUTE_SETTINGS, ROUTE_SETTINGS_EDIT } from './../settings-routes';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtTag,
    OtButton,
    OtTextField,
    OtTextarea,
    OtPhoneNumberField,
    OtAddressField,
    OtSelect,
    OtAutocomplete,
  },
})
export default class OtEditOrganisationDetails extends Vue {
  // * PROPS

  // * REFS
  @Ref('orgDetailsFormRef') private readonly orgDetailsFormRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private formData = OrganisationDetailsFormObject.createEmpty();
  private isLoading = true;
  private originalThumbprint = '';
  private currentThumbprint = '';
  private saving = false;

  private get organisationTypeItems(): ISelectItem<OtOrganisationType>[] {
    return Array.from(OtOrganisationTypesMap.keys()).map(t => {
      return {
        label: OtOrganisationTypesMap.get(t) || '',
        data: t,
      };
    });
  }

  private get settingsTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Organisation Details',
        tabRoute: {
          params: { orgId: this.orgId },
          name: ROUTE_SETTINGS_EDIT,
        },
      },
    ];
  }

  // Validation rules

  private contactEmailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];
  private organisationEmailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];

  private abnRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || value.length === 11 || `ABN must be 11 digits`,
  ];

  private acnRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || value.length === 9 || `ACN must be 9 digits`,
  ];

  private arbnRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || value.length === 9 || `ARBN must be 9 digits`,
  ];

  public validate() {
    return this.orgDetailsFormRef.validate();
  }

  // * COMPUTED

  private get emptyHelperTextString(): string {
    return EMPTY_HELPER_TEXT_STRING;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  private get organisationType(): ISelectItem<OtOrganisationType> | null {
    return this.organisationTypeItems.find(t => t.data === this.formData?.organisationType) || null;
  }

  private set organisationType(value: ISelectItem<OtOrganisationType> | null) {
    if (!value?.data) return;

    if (this.formData?.organisationType) {
      this.formData.organisationType = value.data;
    }
  }

  get streetAddress(): IAutocompleteItem<IAddressDisplayModel> | null {
    if (!this.formData?.streetAddress) return null;
    return {
      label: returnAddressString(this.formData.streetAddress),
      data: this.formData.streetAddress,
    };
  }

  set streetAddress(value: IAutocompleteItem<IAddressDisplayModel> | null) {
    if (this.formData) {
      if (value?.data) {
        this.formData.streetAddress = value.data;
      }
    }
  }

  get postalAddress(): IAutocompleteItem<IAddressDisplayModel> | null {
    if (!this.formData?.postalAddress) return null;
    return {
      label: returnAddressString(this.formData.postalAddress),
      data: this.formData.postalAddress,
    };
  }

  set postalAddress(value: IAutocompleteItem<IAddressDisplayModel> | null) {
    if (this.formData) {
      if (value?.data) {
        this.formData.postalAddress = value.data;
      }
    }
  }

  // ----------------- TIMEZONE AUTOCOMPLETE -----------------

  private allTimezones = getTimeZones()
    .filter(t => t.countryCode === 'AU')
    .flatMap(t => t.group)
    .sort((a, b) => a.localeCompare(b));

  private get selectedTimezone(): IAutocompleteItem<string> {
    const timezone = this.formData.timezone;
    if (timezone) {
      return { label: timezone, data: timezone };
    }
    return { label: '', data: '' };
  }
  private set selectedTimezone(val: IAutocompleteItem<string>) {
    if (val?.data) {
      this.formData.timezone = val.data;
    } else {
      this.formData.timezone = null;
    }
  }

  private get allTimezoneItems(): IAutocompleteItem<string>[] {
    const timezoneNames: IAutocompleteItem<string>[] = this.allTimezones.map(t => {
      return { label: t, data: t };
    });
    return timezoneNames;
  }

  private timezoneRules: Array<(value: IAutocompleteItem<string> | null) => boolean | string> = [
    (value: IAutocompleteItem<string> | null) => !value || !!value.data || `Project Timezone is required`,
  ];

  get editRoute(): string {
    return ROUTE_SETTINGS_EDIT;
  }

  get settingsRoute(): string {
    return ROUTE_SETTINGS;
  }

  private get orgId() {
    return this.$route.params.orgId || vxm.userProfile.defaultOrganisationId || '';
  }

  private get orgList() {
    return vxm.userProfile.userProfile?.organisations || [];
  }

  private get orgName() {
    return this.orgList.find(org => org.gid === this.orgId)?.name || '';
  }

  // * WATCHERS

  @Watch('routePath')
  private async checkRoutePath() {
    this.setOrgNameToBreadcrumb();

    if (this.$route.name === ROUTE_SETTINGS_EDIT && this.orgId) {
      this.isLoading = true;
      await this.getOrganisationDetails();
      this.isLoading = false;
    }
  }

  // watch the organisation details and update the thumbprint
  @Watch('formData', { deep: true })
  private formDataChanged(val: OrganisationDetailsFormObject) {
    this.currentThumbprint = this.getOrganisationDetailsThumbprint(val);
  }

  // * METHODS

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getStreetAddressThumbprint(address: IAddressDisplayModel): string {
    const vals = {
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      suburb: address.suburb || '',
      postcode: address.postcode || '',
      state: address.state || '',
      country: address.country || '',
    };
    return JSON.stringify(vals);
  }

  private getOrganisationDetailsThumbprint(orgDetails: OrganisationDetailsFormObject): string {
    const vals = {
      name: orgDetails.name || '',
      code: orgDetails.code || '',
      abn: orgDetails.abn || '',
      acn: orgDetails.acn || '',
      arbn: orgDetails.arbn || '',
      streetAddress: this.getStreetAddressThumbprint(orgDetails.streetAddress),
      postalAddress: this.getStreetAddressThumbprint(orgDetails.postalAddress),
      organisationType: orgDetails.organisationType || '',
      contactName: orgDetails.contactName || '',
      contactJobTitle: orgDetails.contactJobTitle || '',
      contactMobile: orgDetails.contactMobile || '',
      contactPhone: orgDetails.contactPhone || '',
      contactEmail: orgDetails.contactEmail || '',
      contactFax: orgDetails.contactFax || '',
      phone: orgDetails.phone || '',
      fax: orgDetails.fax || '',
      email: orgDetails.email || '',
      timezone: orgDetails.timezone || '',
    };
    return JSON.stringify(vals);
  }

  private async updateOrganisation() {
    const valid = this.validate();

    if (valid && this.formData) {
      this.saving = true;
      const requestModel = OrganisationDetailsFormObject.createRequestModel(this.formData);
      const apiResponse = await executeApi(
        () => this.api.organisation().postUpdateOrganisationDetails(this.orgId, undefined, requestModel),
        'Update Organisation Details',
      );
      if (apiResponse && apiResponse.success) {
        this.setFormToCleanState();
        // Even though we set the form to be clean just above,
        // the dirty modal still appears unless we reroute on the next tick
        this.$nextTick(() => {
          this.$router.push({ name: ROUTE_SETTINGS, params: { orgId: this.orgId } });
        });
      }
      this.saving = false;
    }
  }

  setOrgNameToBreadcrumb() {
    vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
  }

  // Get the organisation details
  private async getOrganisationDetails() {
    this.isLoading = true;
    const result = await executeApi(
      () => this.api.organisation().getOrganisationDetails(this.orgId),
      'Load Organisation Details',
    );
    if (result.success && result.data) {
      this.formData = OrganisationDetailsFormObject.createFromApiResponse(result.data);
      this.originalThumbprint = this.getOrganisationDetailsThumbprint(this.formData);
      this.currentThumbprint = this.originalThumbprint;
      this.isLoading = false;
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();

    if (this.orgName) {
      vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
    }
  }
}
