import { UserStatus } from '@/models/user-models';
import { ContractStatus, GetOrganisationUserContractsContractModel, ProjectStatus } from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { AccessType } from '@/models/shared-models';

export interface IOrganisationUserContract {
  gid: string;
  reference: string;
  name: string;
  status: ContractStatus;
  projectCode: string;
  projectName: string;
  organisationGid: string;
  projectGid: string;
  projectStatus: ProjectStatus;
  organisationUser?: UserStatus;
  projectUser?: UserStatus;
  contractUser?: UserStatus;
  currentUserOrganisationUser?: UserStatus;
  currentUserProjectUser?: UserStatus;
  currentUserContractUser?: UserStatus;
}

export class OrganisationUserContract implements IOrganisationUserContract {
  public gid!: string;
  public reference!: string;
  public name!: string;
  public status!: ContractStatus;
  public organisationGid!: string;
  public projectGid!: string;
  public projectCode!: string;
  public projectName!: string;
  public projectStatus!: ProjectStatus;
  public organisationUser?: UserStatus;
  public projectUser?: UserStatus;
  public contractUser?: UserStatus;
  public currentUserOrganisationUser?: UserStatus;
  public currentUserProjectUser?: UserStatus;
  public currentUserContractUser?: UserStatus;

  public constructor(value: IOrganisationUserContract) {
    Object.assign(this, value);
  }

  public static createEmpty(): OrganisationUserContract {
    return new OrganisationUserContract({
      gid: '',
      reference: '',
      name: '',
      status: ContractStatus.Active,
      organisationGid: '',
      projectGid: '',
      projectCode: '',
      projectName: '',
      projectStatus: ProjectStatus.Active,
      organisationUser: undefined,
      projectUser: undefined,
      contractUser: undefined,
      currentUserOrganisationUser: undefined,
      currentUserProjectUser: undefined,
      currentUserContractUser: undefined,
    });
  }

  public static createFromApiResponse(model: GetOrganisationUserContractsContractModel): OrganisationUserContract {
    return new OrganisationUserContract({
      gid: model.gid,
      reference: model.reference,
      name: model.name,
      status: model.status,
      organisationGid: model.organisationGid,
      projectGid: model.projectGid,
      projectName: model.projectName,
      projectCode: model.projectCode,
      projectStatus: model.projectStatus,
      organisationUser: model.organisationUser ? UserStatus.createFromApiResponse(model.organisationUser) : undefined,
      projectUser: model.projectUser ? UserStatus.createFromApiResponse(model.projectUser) : undefined,
      contractUser: model.contractUser ? UserStatus.createFromApiResponse(model.contractUser) : undefined,
      currentUserOrganisationUser: model.currentUserOrganisationUser
        ? UserStatus.createFromApiResponse(model.currentUserOrganisationUser)
        : undefined,
      currentUserProjectUser: model.currentUserProjectUser
        ? UserStatus.createFromApiResponse(model.currentUserProjectUser)
        : undefined,
      currentUserContractUser: model.currentUserContractUser
        ? UserStatus.createFromApiResponse(model.currentUserContractUser)
        : undefined,
    });
  }

  public static compareByName(a: OrganisationUserContract, b: OrganisationUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const contractNameA = a.name;
    const contractNameB = b.name;
    return contractNameA.localeCompare(contractNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByReference(a: OrganisationUserContract, b: OrganisationUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const referenceA = a.reference;
    const referenceB = b.reference;
    return referenceA.localeCompare(referenceB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByContractRole(a: OrganisationUserContract, b: OrganisationUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const titleA = a.contractUser?.title || '';
    const titleB = b.contractUser?.title || '';
    // if they are both undefined, then we want to sort them as equal - otherwise if one is undefined,
    // we want to sort it as greater than the other one
    // logically that seems ok to me but again, you might have a better idea.
    // Treat empty strings as greater than any non-empty value
    if (!titleA && !titleB) {
      return 0;
    } else if (!titleA && titleB) {
      return 1 * sortModifier;
    } else if (titleA && !titleB) {
      return -1 * sortModifier;
    }

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, contract: OrganisationUserContract) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return contract.name.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], contract: OrganisationUserContract) {
    if (statuses.length) {
      return statuses.some(
        status =>
          contract.organisationUser?.status === status ||
          contract.projectUser?.status === status ||
          contract.contractUser?.status === status,
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], contract: OrganisationUserContract) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return Boolean(contract.organisationUser);
          case AccessType.Projects:
            return Boolean(contract.projectUser);
          case AccessType.Contracts:
            return Boolean(contract.contractUser);
          default:
            return false;
        }
      });
    }
    return true;
  }
}

export interface IOrganisationUserContractsContracts {
  project: GetOrganisationUserContractsContractModel;
}
