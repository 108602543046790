























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class OtPagination extends Vue {
  // * PROPS
  @Prop(Number) private value!: number;
  @Prop(Number) private pageCount!: number;
  @Prop(Boolean) private showLabel!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get pageValue() {
    return this.value;
  }

  private set pageValue(value: number) {
    this.$emit('input', value);
  }

  // * WATCHERS

  // * METHODS
  private setCurrentPage(value: number) {
    this.pageValue = value;
  }

  // * LIFECYCLE
}
