















import { Component, Vue } from 'vue-property-decorator';
import OtBreadcrumbs from '@/components/global/ot-breadcrumbs.vue';

@Component({
  components: {
    OtBreadcrumbs,
  },
})
export default class OtBreadcrumbsWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private crumbsExample1 = [
    {
      text: 'Dashboard',
      disabled: true,
      href: 'dashboard',
    },
  ];

  private crumbsExample2 = [
    {
      text: 'Dashboard',
      disabled: false,
      href: 'dashboard',
    },
    {
      text: 'Link 1',
      disabled: true,
      href: 'Link 1',
    },
  ];

  private crumbsExample3 = [
    {
      text: 'Dashboard',
      disabled: false,
      href: 'dashboard',
    },
    {
      text: 'Link 1',
      disabled: false,
      href: 'Link 1',
    },
    {
      text: 'Link 2',
      disabled: true,
      href: 'Link 2',
    },
  ];

  private crumbsExample4 = [
    {
      text: 'Dashboard',
      disabled: false,
      href: 'dashboard',
    },
    {
      text: 'Link 1',
      disabled: false,
      href: 'Link 1',
    },
    {
      text: 'Link 2',
      disabled: false,
      href: 'Link 2',
    },
    {
      text: 'Link 3',
      disabled: true,
      href: 'Link 3',
    },
  ];

  private crumbsExample5 = [
    {
      text: 'Dashboard',
      disabled: false,
      href: 'dashboard',
    },
    {
      text: 'Link 1',
      disabled: false,
      href: 'Link 1',
    },
    {
      text: 'Link 2',
      disabled: false,
      href: 'Link 2',
    },
    {
      text: 'Link 3',
      disabled: false,
      href: 'Link 3',
    },
    {
      text: 'Link 4',
      disabled: true,
      href: 'Link 4',
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
