import { format, parse } from 'date-fns';

export class ZonelessDate extends Date {
  constructor(value?: string | Date | null | ZonelessDate) {
    let date = value;
    if (typeof value === 'string') {
      date = ZonelessDate.fromZonelessDateFormat(value);
    }
    if (date) {
      super(date);
    } else {
      super();
    }
  }

  // The timezone isn't important and so the browser timezone is fine to use in this case
  // No need to worry about UTC conversions
  public static fromZonelessDateFormat(date: string | undefined): ZonelessDate | null {
    if (date) {
      return new ZonelessDate(parse(date, 'yyyy-MM-dd', new Date()));
    }
    return null;
  }

  // The timezone isn't important and so the browser timezone is fine to use in this case
  // No need to worry about UTC conversions
  /** returns the date formatted in yyyy-MM-dd */
  public static toZonelessDateFormat(date: ZonelessDate): string {
    return format(date, 'yyyy-MM-dd');
  }

  /** returns the date formatted in yyyy-MM-dd */
  public toZonelessDateFormat(): string {
    return ZonelessDate.toZonelessDateFormat(this);
  }

  public toJSON(): string {
    return this.toZonelessDateFormat();
  }

  public toString(): string {
    return this.toZonelessDateFormat();
  }
}
