import { OtTimePeriodUnit } from '@/types/unit-enums';
import { CalendarTypeEnum } from '@/wf-components/models/data-driven-enums';

export function dayOrDaysString(val: number | null | undefined, durationUnit?: OtTimePeriodUnit | null) {
  if (val === null || val === undefined) {
    return undefined;
  }
  const unit = durationUnit ?? OtTimePeriodUnit.Days;
  return val === 1 || val === -1 ? unit.singular : unit.plural;
}

export function calendarTypeString(val: CalendarTypeEnum | null | undefined) {
  if (val === null || val === undefined) {
    return undefined;
  }
  return val
    .toLowerCase()
    .replace('days', '')
    .replace('day', '');
}

export function durationString(
  val: number | null | undefined,
  calendarType: CalendarTypeEnum | null | undefined,
  durationUnit?: OtTimePeriodUnit | null,
) {
  // will return something like "1 calendar day" "6 working days"
  // for a while one of the enum values had Day or Days at the end of the string. This was dumb. We cannot rule out being dumb again
  // so we need to make sure we don't end up outputing "day/days day/days"

  const days = dayOrDaysString(val, durationUnit);
  const calendar = calendarTypeString(calendarType);

  if (days === undefined || calendar === undefined) {
    return undefined;
  }

  return `${val} ${calendar} ${days.toLowerCase()}`;
}
