
































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { ZonelessDate } from '@/types/zoneless-date';
import { OtDataDrivenOverridableDateValue, OverridableDateOptionKeyEnum } from '../models/data-driven-result';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { ISyncableQuestionClient } from '../models/data-driven-client-interfaces';

const ContractorNotStatedOption: IRadioGroupOption = {
  label: 'Not Stated',
  key: OverridableDateOptionKeyEnum.NotStated,
};
const ContractorSubmittedOption: IRadioGroupOption = {
  label: 'Date nominated by Contractor',
  key: OverridableDateOptionKeyEnum.DateSubmittedByContractor,
};
const AdminNotStatedOption: IRadioGroupOption = {
  label: 'Not Stated',
  key: OverridableDateOptionKeyEnum.NotStated,
};
const AdminSubmittedOption: IRadioGroupOption = {
  label: 'Date submitted by Administrator',
  key: OverridableDateOptionKeyEnum.DateSubmittedByAdministrator,
};

@Component({
  components: {
    OtCheckbox,
    OtTextarea,
    OtRadioGroup,
    OtDatePicker,
  },
})
export default class WfOverridableOptionalDateEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public formContainerElement?: HTMLElement;

  // * REFS

  // * DATA
  private allowOverrideToNotSet = false;

  private title: string | null = null;
  private description: string | null = null;

  // * COMPUTED
  private get contractorOptions(): IRadioGroupOption[] {
    return [ContractorNotStatedOption, ContractorSubmittedOption];
  }

  private get administratorOptions(): IRadioGroupOption[] {
    return [AdminNotStatedOption, AdminSubmittedOption];
  }

  private overrideSelectedPrivate = false;
  private get overrideSelected() {
    return this.overrideSelectedPrivate;
  }
  private set overrideSelected(val: boolean) {
    this.overrideSelectedPrivate = val;
    if (!val) {
      if (this.allowOverrideToNotSet) {
        this.adminValue = null;
      }
      this.adminDate = null;
    }
    this.onChange();
  }

  private syncCheckboxValuePrivate = false;
  private get syncCheckboxValue() {
    return this.syncCheckboxValuePrivate;
  }
  private set syncCheckboxValue(val: boolean) {
    this.syncCheckboxValuePrivate = val;
    this.onChange();
  }

  private contractorValuePrivate: IRadioGroupOption | null = null;
  private get contractorValue() {
    return this.contractorValuePrivate;
  }
  private set contractorValue(val: IRadioGroupOption | null) {
    this.contractorValuePrivate = val;
    if (val?.key === ContractorNotStatedOption.key) {
      this.contractorDate = null;
    }
    this.onChange();
  }

  private get disableContractorDatePicker() {
    return this.contractorValue?.key !== ContractorSubmittedOption.key;
  }

  private contractorDatePrivate: Date | null = null;
  private get contractorDate() {
    return this.contractorDatePrivate;
  }
  private set contractorDate(val: Date | null) {
    this.contractorDatePrivate = val;
    this.onChange();
  }

  private adminValuePrivate: IRadioGroupOption | null = null;
  private get adminValue() {
    return this.adminValuePrivate;
  }
  private set adminValue(val: IRadioGroupOption | null) {
    this.adminValuePrivate = val;
    if (val?.key === AdminNotStatedOption.key) {
      this.adminDate = null;
    }
    this.onChange();
  }

  private get disableAdminDatePicker() {
    return this.adminValue?.key !== AdminSubmittedOption.key;
  }

  private adminDatePrivate: Date | null = null;
  private get adminDate() {
    return this.adminDatePrivate;
  }
  private set adminDate(val: Date | null) {
    this.adminDatePrivate = val;
    this.onChange();
  }

  private notesPrivate: string | null = null;
  private get notes() {
    return this.notesPrivate;
  }
  private set notes(val: string | null) {
    this.notesPrivate = val;
    this.onChange();
  }

  private get contractorRequiredMessage() {
    if (this.contractorValue?.key === ContractorSubmittedOption.key) {
      return `Contractor's date is required`;
    }
    return undefined;
  }

  private get contractorRadioRequiredMessage() {
    if (this.question.isMandatory) {
      return `Contractor's selection is required`;
    }
    return undefined;
  }

  private get administratorRequiredMessage() {
    if (this.adminValue?.key === AdminSubmittedOption.key) {
      return `Administrator's date is required`;
    }
    return undefined;
  }

  private get administratorRadioRequiredMessage() {
    if (this.question.isMandatory && this.overrideSelected) {
      return `Administrator's selection is required`;
    }
    return undefined;
  }

  private get notesRequiredMessage() {
    if (this.overrideSelected) {
      return `Notes are required`;
    }
    return undefined;
  }

  private get secondaryLabel() {
    return this.overrideSelected ? 'Mandatory text' : 'Optional text';
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OverridableDateValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-overridable-optional-date-edit -> result -> ResultType is incorrect. Expected OverridableDateValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get notesDescription() {
    return this.question.notesDescription;
  }

  private get showSyncCheckbox() {
    // Here would be good to check against the result type of this.syncQuestion.
    // We don't have the result type linked to the question so we can't at the moment.
    // But it would be nice to hide this and log a warning if they aren't the same.
    return !!this.question.syncFromQuestion;
  }

  private get syncQuestion() {
    return (
      this.definition.layouts
        .flatMap(l => l.sections.flatMap(s => s.questions))
        .find(q => q.key === this.question.syncFromQuestion?.questionKey) || null
    );
  }

  private get syncCheckboxLabel() {
    if (this.question.syncFromQuestion) {
      const questionTitle = this.syncQuestion?.title;
      return `${this.question.title} is the same as ${questionTitle}`;
    }
    return '';
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.overrideSelectedPrivate = this.result?.adminOverride || false;
    this.contractorDatePrivate = this.stringToDate(this.result?.contractorValue);
    this.adminDatePrivate = this.stringToDate(this.result?.adminValue);
    this.notesPrivate = this.result?.text || null;
    this.contractorValuePrivate =
      this.contractorOptions.find(o => o.key === this.result?.selectedContractorOptionKey) || null;
    this.adminValuePrivate = this.administratorOptions.find(o => o.key === this.result?.selectedAdminOptionKey) || null;
  }

  // * METHODS
  public onChange() {
    // We're using zoneless date to make sure the client timezone doesn't mess with the evaluated date string
    const contractorValue = this.contractorDate ? new ZonelessDate(this.contractorDate) : null;
    const adminValue = this.adminDate ? new ZonelessDate(this.adminDate) : null;

    const result = new OtDataDrivenOverridableDateValue({
      resultType: 'OverridableDateValueModel',
      contractorValue: contractorValue?.toString() || null,
      adminValue: adminValue?.toString() || null,
      adminOverride: this.overrideSelected,
      date: this.overrideSelected ? adminValue : contractorValue,
      text: this.notes,
      selectedContractorOptionKey: this.contractorValue?.key || null,
      selectedAdminOptionKey: this.adminValue?.key || null,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });

    if (this.showSyncCheckbox) {
      (val.client as ISyncableQuestionClient) = {
        syncChecked: this.syncCheckboxValuePrivate,
        syncingWithQuestion: this.question.syncFromQuestion?.questionKey,
      };
    }

    this.$emit('input', val);
  }

  private stringToDate(val: string | null | undefined): Date | null {
    if (val) {
      return new Date(val);
    }
    return null;
  }

  // * LIFECYCLE
  private created() {
    this.allowOverrideToNotSet =
      this.question.configs?.some(
        x =>
          x.key.localeCompare('allowOverrideToNotSet', undefined, { sensitivity: 'base' }) === 0 && x.value === 'true',
      ) ?? false;

    this.title = this.question.title;
    this.description = this.question.description;

    if (this.value?.client && 'syncChecked' in (this.value?.client as ISyncableQuestionClient)) {
      this.syncCheckboxValuePrivate = (this.value?.client as ISyncableQuestionClient).syncChecked || false;
    } else {
      this.syncCheckboxValuePrivate = this.question.syncFromQuestion?.checkedByDefault || false;
    }

    this.valueChanged();

    if (this.contractorDate) {
      this.contractorValuePrivate = ContractorSubmittedOption;
    } else if (this.value) {
      // If there is a value object but no contractor date
      this.contractorValuePrivate = ContractorNotStatedOption;
    }

    if (!this.allowOverrideToNotSet) {
      // don't care if they have selected a value or not, or if they've selected admin override and not filled in a date
      // if we cannot override to not selected then the admin submitted MUST be selected
      this.adminValuePrivate = AdminSubmittedOption;
    } else if (this.adminDate) {
      this.adminValuePrivate = AdminSubmittedOption;
    } else if (this.value && this.overrideSelected) {
      // If there is a value object and the field is required but no admin date
      this.adminValuePrivate = AdminNotStatedOption;
    }
  }
}
