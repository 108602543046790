import { render, staticRenderFns } from "./ot-table.vue?vue&type=template&id=5a1512b7&scoped=true&"
import script from "./ot-table.vue?vue&type=script&lang=ts&"
export * from "./ot-table.vue?vue&type=script&lang=ts&"
import style0 from "./ot-table.vue?vue&type=style&index=0&id=5a1512b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a1512b7",
  null
  
)

export default component.exports