import {
  IGoogleAutocompleteService,
  IGoogleAutocompleteSessionToken,
  IGooglePlacesService,
} from '@/services/google/google-types';
import { createModule, mutation } from 'vuex-class-component';

const vuexModule = createModule({
  namespaced: 'google',
  strict: false,
});

export default class GoogleModule extends vuexModule {
  private _autocompleteService: IGoogleAutocompleteService | null = null;
  private _placesService: IGooglePlacesService | null = null;
  private _sessionToken: IGoogleAutocompleteSessionToken | null = null;

  // * GETTERS
  public get autocompleteService() {
    return this._autocompleteService;
  }

  public get placesService() {
    return this._placesService;
  }

  public get sessionToken() {
    return this._sessionToken;
  }

  // * MUTATIONS
  @mutation public setAutocompleteService(service: IGoogleAutocompleteService) {
    this._autocompleteService = service;
  }

  @mutation public setPlacesService(service: IGooglePlacesService) {
    this._placesService = service;
  }

  @mutation public setSessionToken(token: IGoogleAutocompleteSessionToken) {
    this._sessionToken = token;
  }
}
