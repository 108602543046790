
























import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { IUserStatus } from '@/models/user-models';
import { OtStatusType, OtUserStatus, userStatusOrder } from '@/types/status-enums';

@Component({
  components: {
    OtTag,
  },
})
export default class OtUserStatusTags extends Vue {
  @Prop({ default: () => [] }) private userStatuses!: IUserStatus[] | IUserStatus;

  private isExpanded = false;

  private get userStatusesArray() {
    return Array.isArray(this.userStatuses) ? this.userStatuses : [this.userStatuses];
  }

  private get hasAny() {
    return Boolean(this.userStatusesArray.length);
  }

  private get hasMoreThanOne() {
    return this.statusesForDisplay.length > 1;
  }

  private toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  private get statusesForDisplay() {
    // Make the Map to hold the status and count
    const statusCounts = new Map<OtUserStatus, number>();
    // spin thru the statuses
    for (const userStatus of this.userStatusesArray) {
      // get the current count, coalesce to 0 if it isn't there
      const currentCount = statusCounts.get(userStatus.status) ?? 0;
      // add 1 to it, then set it back into the Map
      statusCounts.set(userStatus.status, currentCount + 1);
    }
    // Maps are a bit of a pain to work with
    // Make an array with the entries, which will be an IEnumerable (a bit like an array, but doesn't have the @#$%ing map method)
    // of arrays, it'll look something like
    // [[Active, 2], [Inactive, 3]] etc
    // then turn this into something a bit more pleasant to work with, an array of objects with a TagStatus and the suffix text
    // and make sure it's sorted
    const forDisplay = Array.from(statusCounts.entries())
      .map(([key, value]): { status: TagStatus; suffix: string } => ({
        status: { type: OtStatusType.User, status: key },
        suffix: value > 1 ? ` (${value})` : '',
      }))
      .sort(
        (a, b) => userStatusOrder[a.status.status as OtUserStatus] - userStatusOrder[b.status.status as OtUserStatus],
      );

    return forDisplay;
  }

  private get firstStatus() {
    const result = this.statusesForDisplay[0];
    return result;
  }

  private get restOfStatuses() {
    return this.statusesForDisplay.slice(1);
  }
}
