import { FilterXSS, IFilterXSSOptions } from 'xss';

const options: IFilterXSSOptions = {
  whiteList: {
    span: [],
    br: [],
    div: [],
    p: [],
    b: [],
    strong: [],
    i: [],
    em: [],
    ins: [],
    u: [],
    ul: [],
    ol: [],
    li: [],
    dl: [],
    dt: [],
    dd: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    hr: [],
    mark: [],
  },
  stripIgnoreTag: true,
};
export const sanitizer = new FilterXSS(options);
