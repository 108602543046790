







































































































import OtButton from '@/components/global/ot-button.vue';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtEmailContractorDialog, {
  EmailContractorDialogParams,
} from '@/components/specific-modals/ot-email-contractor-dialog.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { OtOpenSegmentStatusSet } from '@/types/status-enums';
import { compareToBrowserTimeZone, convertAndFormatDateAndTime, formatDateAndTime } from '@/utils/date-utils';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { OtDataDrivenLayout } from '@/wf-components/models/data-driven-layout';
import { OtDataDrivenPhase } from '@/wf-components/models/data-driven-phase';
import { getSelectedKeysForDependsOn, passesDependsOn } from '@/wf-components/utils/depends-on-utils';
import WfSummaryPanel from '@/wf-components/wf-summary-panel.vue';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import {
  ClaimEmailObject,
  ClaimResultsObject,
  FinishedClaimRecommendationsObject,
  SegmentConfig,
  SegmentInstanceDetails,
} from '../claims-models';
import OtClaimOverrideDetails from './ot-claim-override-details.vue';
import OtClaimRecommendation from './ot-claim-recommendation.vue';
import OtClaimRuns from './ot-claim-runs.vue';

type BaseRow = {
  runGid: string;
  date: Date;
  phase: OtDataDrivenPhase;
  isExpanded: boolean;
};

type RunRow = BaseRow & {
  rowType: 'run';
  evaluation: OtClientEvaluation;
  instance: OtDataDrivenInstance;
  recommendation: ClaimResultsObject;
  layouts: OtDataDrivenLayout[];
};

type EmailRow = BaseRow & {
  rowType: 'email';
  email: ClaimEmailObject;
};

type OutcomeRow = RunRow | EmailRow;

const DEFAULT_PHASE = new OtDataDrivenPhase({ key: 'default', label: 'Entered' });

@Component({
  components: {
    OtExpansionPanel,
    OtButton,
    OtLoadingSpinner,
    OtEmailContractorDialog,
    OtClaimRecommendation,
    OtClaimRuns,
    OtClaimOverrideDetails,
    WfSummaryPanel,
  },
})
export default class OtClaimWorkflowAndOutcomesIndex extends Vue {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private definition!: OtDataDrivenDefinition | null | undefined;
  @Prop() private instances!: OtDataDrivenInstances | null | undefined;
  @Prop({ default: () => [] }) private evaluations!: OtClientEvaluation[];
  @Prop() private segmentConfig!: SegmentConfig | null | undefined;

  // * REFS
  @Ref('emailDialogRef') private readonly emailDialogRef!: OtEmailContractorDialog;

  // * DATA
  private api = new OtApi();
  private loading = false;
  private claimResultDetails: FinishedClaimRecommendationsObject | null = null;
  private outcomes: OutcomeRow[] | null = null;
  private parentClaimDetails: SegmentInstanceDetails | null = null;

  // * COMPUTED
  private get showSummaryPanel() {
    return Boolean(this.parentClaimDetails);
  }

  private get claimDetailsSegmentName() {
    return this.claimDetails.workflowSegment.name;
  }

  private get isEditEnabled() {
    // this check is the same check as in ot-claims-table (OtClaimsTable) getClaimLink where we decide if we are taking them to the
    // "fill in the claim" or this view the claim. Probably a bit over the top, but it's super future proof
    return OtOpenSegmentStatusSet.has(this.claimDetails.status);
  }

  private get showReceivalDescription() {
    return this.claimDetails.documentReceivalType?.value === 'OTHER';
  }

  private get timeZoneComparison() {
    return compareToBrowserTimeZone(this.claimDetails.contract.timezone);
  }

  // * WATCHERS

  // * METHODS
  private async getParentDetails() {
    if (!this.claimDetails.parentClaim?.gid) {
      this.parentClaimDetails = null;
      return;
    }
    const parentClaimGid = this.claimDetails.parentClaim?.gid;
    if (parentClaimGid) {
      // TODO consider a shorter parent details call to get just the summary info from wherever it is. Seems YUGE
      // to request the entire segment instance details
      // I guess, with proper caching, this'd be pluck off the shelf time
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceDetails(parentClaimGid),
        'Load Parent Workflow Details',
      );

      if (result.success && result.data) {
        this.parentClaimDetails = SegmentInstanceDetails.createFromApiResponse(result.data);
      }
    }
  }

  private convertAndFormatDateAndTime(date: Date | null) {
    if (date) {
      return convertAndFormatDateAndTime(date, this.claimDetails.contract.timezone, { overrideJoin: '-' });
    }
    return '';
  }

  private convertAndFormatDate(date: Date | null) {
    if (date) {
      return convertAndFormatDateAndTime(date, this.claimDetails.contract.timezone, { excludeTime: true });
    }
    return '';
  }

  private formatDate(date: Date | null) {
    if (date) {
      return formatDateAndTime(date, { excludeTime: true });
    }
    return '';
  }

  private formatDateAndTime(date: Date | null) {
    if (date) {
      return formatDateAndTime(date, { overrideJoin: '-' });
    }
    return '';
  }

  private rebuildDisplay() {
    if (!this.instances) {
      console.error('claim-workflow-and-outcomes/index -> rebuildDisplay -> no instances. Bailing:');
      return;
    }
    /* from the eng
      Combine the Recommendation runs with the response runs and evaluation runs and build up objects for displaying in individual run rows - the recommendation, the outcome, the messages, the response, the evaluation, the phase name.
      Convert the Recommendation emails into objects for displaying in individual email rows
      Sort the rows to display so they are displayed in date order, earliest date first
      Expand the last run row by default
    */

    // tack the instances in as RunRows
    const rawOutcomes: OutcomeRow[] = [];
    for (const instance of this.instances?.instances) {
      const evaluation = this.evaluations.find(x => x.runGid === instance.runGid);
      let phase = this.segmentConfig?.phases.find(x => x.key === instance.phaseKey);
      const recommendation = this.claimResultDetails?.recommendations.find(x => x.runGid === instance.runGid);

      if (!evaluation) {
        console.warn(
          'claim-workflow-and-outcomes/index -> rebuildDisplay -> Cound not find evaluation for instance. Skipping instance:',
          { instance, evaluations: this.evaluations },
        );
      }
      if (!phase) {
        console.warn(
          'claim-workflow-and-outcomes/index -> rebuildDisplay -> Cound not find phase for instance. Will use default phase:',
          { instance, segmentConfig: this.segmentConfig, DEFAULT_PHASE },
        );
        phase = DEFAULT_PHASE;
      }
      if (!recommendation) {
        console.warn(
          'claim-workflow-and-outcomes/index -> rebuildDisplay -> Cound not find recommendation for instance. Skipping instance:',
          { instance, claimResultDetails: this.claimResultDetails },
        );
      }
      if (!instance.finished) {
        console.warn(
          'claim-workflow-and-outcomes/index -> rebuildDisplay -> Instance is unfinished. Skipping instance:',
          { instance },
        );
      }
      if (evaluation && recommendation && instance.finished) {
        // build up the layouts. Could do this in the onExpanded, but here is as good a spot as any
        // could put this into a method, the logic is similar between a bunch of spots, but copy paste never hurt anybody

        const selectedKeys = getSelectedKeysForDependsOn(instance, this.definition, evaluation);

        const pageLayouts = this.definition?.layouts.filter(l => l.type === LayoutTypeEnum.Page) || [];

        const layoutsThatPassDependsOn =
          pageLayouts.filter(layout => passesDependsOn(layout.dependsOn, selectedKeys)) || [];

        rawOutcomes.push({
          rowType: 'run',
          date: instance.finished.utc,
          instance: instance,
          isExpanded: false,
          phase,
          evaluation,
          recommendation,
          runGid: instance.runGid,
          layouts: layoutsThatPassDependsOn,
        });
      }
    }

    // then tack on the email rows
    if (this.claimResultDetails?.emails) {
      for (const email of this.claimResultDetails?.emails) {
        const instance = this.instances.instances.find(x => x.runGid === email.runGid);
        if (!instance) {
          console.warn(
            'claim-workflow-and-outcomes/index -> rebuildDisplay -> Cound not find instance for email. This is weird, but we can keep processing:',
            { email, instances: this.instances },
          );
        }
        let phase = this.segmentConfig?.phases.find(x => x.key === instance?.phaseKey);
        if (!phase) {
          console.warn(
            'claim-workflow-and-outcomes/index -> rebuildDisplay -> Cound not find phase for instance for email. Will use default phase:',
            { email, instance, segmentConfig: this.segmentConfig, DEFAULT_PHASE },
          );
          phase = DEFAULT_PHASE;
        }
        if (!email.sentDateTimeUTC) {
          console.warn('claim-workflow-and-outcomes/index -> rebuildDisplay -> Email is unsent. Skipping email:', {
            email,
          });
        }
        if (email.sentDateTimeUTC) {
          rawOutcomes.push({
            rowType: 'email',
            date: email.sentDateTimeUTC,
            runGid: email.runGid,
            email: email,
            phase,
            isExpanded: false,
          });
        }
      }
    }

    // sort everything
    rawOutcomes.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    // expand the last non email row
    let i = rawOutcomes.length;
    while (i--) {
      if (rawOutcomes[i].rowType === 'run') {
        rawOutcomes[i] = { ...rawOutcomes[i], isExpanded: true };
        break;
      }
    }
    console.log('claim-workflow-and-outcomes/index -> rebuildDisplay -> outcomes', rawOutcomes);
    this.outcomes = rawOutcomes;
  }

  private async showEmail(row: EmailRow) {
    const params = new EmailContractorDialogParams({
      readonly: true,
      emailContent: row.email.content,
      recipientEmails: row.email.emailDetails?.recipients || [],
      emailSubject: row.email.emailDetails?.subject || null,
    });

    await this.emailDialogRef.open(params);
  }

  // * LIFECYCLE
  private async created() {
    this.loading = true;
    console.log('claim-workflow-and-outcomes/index -> created -> stuff', {
      segmentConfig: this.segmentConfig,
      claimDetails: this.claimDetails,
      definition: this.definition,
      instances: this.instances,
      evaluations: this.evaluations,
    });
    const apiResult = await executeApi(
      () => this.api.segmentInstances().getSegmentInstanceRecommendation(this.claimDetails.gid),
      'Get Recommendation',
    );

    if (apiResult.success && apiResult.data) {
      this.claimResultDetails = FinishedClaimRecommendationsObject.createFromApiResponse(apiResult.data);
      this.rebuildDisplay();
      await this.getParentDetails();
    }
    this.loading = false;
  }
}
