














































import { Component, Vue } from 'vue-property-decorator';
import OtDatePicker, { DatepickerTypeEnum } from '@/components/global/ot-date-picker.vue';

@Component({
  components: {
    OtDatePicker,
  },
})
export default class OtDatePickerWrapper extends Vue {
  // * PROPS

  // * DATA
  private singleDateValue = '';
  private singleDateValueRequired = '';
  private singleDateValueLight = '';
  private dateRangeValues: string[] = [];
  private multipleDateValues: string[] = [];
  private singleType = DatepickerTypeEnum.Single;
  private rangeType = DatepickerTypeEnum.Range;
  private multipleType = DatepickerTypeEnum.Multiple;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
