import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import { store, vxm } from '@/store';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import VueMeta from 'vue-meta';
import VJsoneditor from 'v-jsoneditor';
import * as FullStory from '@fullstory/browser';

import AuthService from '@/services/auth.service';
import vuetify from './plugins/vuetify';
import { dirtyFormsExist } from './utils/validation-utils';
import { CreateErrorSnackbar } from './services/api.service';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import { AppInsightsPlugin } from './plugins/app-insights';
import { format, isValid, parseISO } from 'date-fns';
import Handlebars, { HelperOptions } from 'handlebars';

import 'tiptap-vuetify/dist/main.css';

import '@/wf-components/workflow-components';
import { currencyFormatterDecimals, currencyFormatterWholeNumber } from './utils/currency-format-utils';
import { OtDataDrivenDaySelection } from './wf-components/models/data-driven-result';
import { durationString } from './utils/duration-utils';

if (process.env.VUE_APP_INSIGHTS_CONNECTION_STRING) {
  Vue.use(AppInsightsPlugin, {
    config: {
      connectionString: process.env.VUE_APP_INSIGHTS_CONNECTION_STRING,
      // instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY,
    },
  });
}

FullStory.init({ orgId: process.env.VUE_APP_FULLSTORY_ORG_ID, devMode: process.env.NODE_ENV === 'development' });
Vue.prototype.$FullStory = FullStory;

Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(VJsoneditor);
Vue.use(TiptapVuetifyPlugin, {
  vuetify: vuetify,
  iconsGroup: 'mdi',
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

// TODO consider moving this to some kind of setupHandlebars method
Handlebars.registerHelper('formatDate', (text, options: HelperOptions) => {
  if (!text) {
    return undefined;
  }
  const rawDate = String(text);
  const parsedDate = parseISO(rawDate);
  if (isValid(parsedDate)) {
    const dateFormat = options.hash.format ? String(options.hash.format) : 'EEEE, MMMM d, yyyy';
    const formatted = format(parsedDate, dateFormat);
    return new Handlebars.SafeString(formatted);
  } else {
    console.warn('Handlebars helper formatDate, cannot parse text as date', { text });
    return new Handlebars.SafeString(rawDate);
  }
});
Handlebars.registerHelper('formatCurrency', text => {
  if (!text) {
    return undefined;
  }
  const rawVal = String(text);
  const parsedVal = parseFloat(rawVal);
  if (!isNaN(parsedVal)) {
    // at some stage we could take an options hash to specify it it should do a smart (omit decimals) or dumb
    // not today
    if (parsedVal % 1 === 0) {
      return new Handlebars.SafeString(currencyFormatterWholeNumber.format(parsedVal));
    }
    return new Handlebars.SafeString(currencyFormatterDecimals.format(parsedVal));
  } else {
    console.warn('Handlebars helper formatCurrency, cannot parse text as number', { text });
    return new Handlebars.SafeString(rawVal);
  }
});

Handlebars.registerHelper('formatDaySelection', value => {
  if (!value) {
    return undefined;
  }
  if (value instanceof OtDataDrivenDaySelection) {
    return new Handlebars.SafeString(durationString(value.number, value.calendarType) ?? '');
  } else {
    console.warn('Handlebars helper formatDaySelection, value is not OtDataDrivenDaySelection', { value });
    return new Handlebars.SafeString('');
  }
});

// TODO consider moving this to some kind of setupAxios method
const auth = new AuthService();

// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // const authToken = store.getters['auth/authToken'];
    const authToken = await auth.getAccessToken();
    if (authToken) {
      // console.log('injecting bearer token:' + config.path);
      config.headers.Authorization = `Bearer ${authToken}`;
    } else {
      // console.log('no bearer token to inject:' + config.path);
    }
    // this tells axios that ANY STATUS is a good status
    // otherwise it'll reject the http call with its own funy Error object
    // and the openapi2tsclient Axios template doesn't do try catching of the calls
    // so you never get any details out at the caller for, say, a 422 error
    // We can't be any more selective about "this is good this is bad" until the axios template gets an overhaul
    config.validateStatus = () => {
      return true;
    };
    return config;
  },
  (err: AxiosError) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // route to login, and set the return url to the current url
      // console.log('401 Unauthorised received.');
      if (!dirtyFormsExist()) {
        auth.login();
      } else {
        const queueMessages = vxm.snackbar.snackbarAlertsQueue.map(snackbar => snackbar.message);
        const message = `Unable to authorise request, Please refresh the page and try again`;
        // Make sure we don't spam the same error if the user isn't authenticated
        // Only show this message if it isn't already visible
        if (!queueMessages.includes(message)) {
          CreateErrorSnackbar(message);
        }
      }
    }
    return error;
  },
);
