import { OtClientEvaluation } from '../models/client-evaluation';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenDependsOn } from '../models/data-driven-depends-on';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { getSelectedKeysFromResponses } from './response-key-utils';

export function getSelectedKeysForDependsOn(
  instance: OtDataDrivenInstance | undefined | null,
  definition: OtDataDrivenDefinition | undefined | null,
  evaluation: OtClientEvaluation | undefined | null,
) {
  const responseKeys = getSelectedKeysFromResponses(instance);
  const configKeys = definition?.evaluation?.configKeys || [];
  const evaluationKeys = evaluation?.keys || [];

  const allKeys = responseKeys.concat(evaluationKeys).concat(configKeys);

  return allKeys;
}

export function passesDependsOn(dependsOn: OtDataDrivenDependsOn | undefined | null, selectedKeys: string[]) {
  if (!dependsOn) {
    // no depends on, instapass
    return true;
  }
  if (dependsOn.options.length === 0) {
    // has a depends on, but no options, instapass
    return true;
  }
  // dependsOn is an array of arrays
  // inner is AND'd together - every key from inner array must exist
  // outer is OR'd together - only have to get a match for one of the inner arrays
  // This should be case insensitive to match https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2662596647/P11041R02+-+Depends+On+Process
  return dependsOn.options.some(dependsOnOptionSet =>
    //      this is the OR  ↑   only one of the inner arrays needs to match
    dependsOnOptionSet.every(dependsOnKey => {
      // this is the AND  ↑  all of the key in the inner must pass
      const dependsOnKeyMatcher = new RegExp('^' + dependsOnKey + '$', 'i');
      return selectedKeys.some(selectedKey => dependsOnKeyMatcher.test(selectedKey));
      // and it passes if   ↑  selected keys has it
      // could also have used .find here and checked return, or findIndex, but .some felt semantically better
    }),
  );
}
