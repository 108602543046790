





































































































































































import { Component, Vue } from 'vue-property-decorator';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputAction, IInputTab } from '@/components/global/common-models';
import OtButton from '@/components/global/ot-button.vue';
import OtTabs from '@/components/global/ot-tabs.vue';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtButton,
    OtTabs,
  },
})
export default class OtComingSoonPageWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private crumbsExample = [
    {
      text: 'Dashboard',
      disabled: false,
      href: 'dashboard',
    },
    {
      text: 'Link 1',
      disabled: false,
      href: 'Link 1',
    },
    {
      text: 'Link 2',
      disabled: true,
      href: 'Link 2',
    },
  ];

  private sectionTitle1 = 'Section Title 1';
  private primaryContextBtn1 = {
    actionName: 'Action 3',
    action: {
      name: 'Dashboard',
      params: {
        what: 'test',
      },
    },
  };
  private secondaryContextBtns1: IInputAction[] = [
    {
      actionName: 'Action 1',
      action: this.exampleAction1,
    },
    {
      actionName: 'Action 2',
      action: {
        name: 'Dashboard',
      },
    },
    {
      actionName: 'Action 4',
      action: this.exampleAction4,
    },
  ];
  private tabsData1: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Active Tab',
      tabRoute: {
        name: 'Playground',
        params: { component: 'OtStandardHeaderWrapper' },
      },
    },
    {
      tabId: 1,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 2,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
  ];

  private sectionTitle2 = 'Section Title 2';
  private isDiableContextBtn2 = true;
  private primaryContextBtn2 = {
    actionName: 'Action 1',
    action: this.exampleAction1,
  };
  private tabsData2: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 1,
      tabText: 'Active Tab',
      tabRoute: {
        name: 'Playground',
        params: { component: 'OtStandardHeaderWrapper' },
      },
    },
    {
      tabId: 2,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
  ];

  private sectionTitle4 = 'Section Title 4';
  private isDiableBreadcrumbs4 = true;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private exampleAction1() {
    alert('action 1');
  }

  private exampleAction2() {
    alert('action 2');
  }

  private exampleAction3() {
    alert('action 3');
  }

  private exampleAction4() {
    alert('action 4');
  }

  // * LIFECYCLE
}
