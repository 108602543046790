

















































































































import OtContractAmendmentsForm from '@/areas/projects/contracts/ot-contract-amendments-form.vue';
import OtContractDetailsForm from '@/areas/projects/contracts/ot-contract-details-form.vue';
import OtContractSetupForm from '@/areas/projects/contracts/ot-contract-setup-form.vue';
import OtWizardArchetype from '@/components/global/archetypes/ot-wizard-archetype.vue';
import { DangerModalParams } from '@/components/global/modal/form-modal-models';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import { EntityModifiedResponse } from '@/models/entity-modified-response-model';
import ApiResponse from '@/services/api-models';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import {
  PostUpdateContractStatusRequestModel,
  PostUpsertContractWorkflowsRequestModel,
} from '@/services/generated/api';
import { vxm } from '@/store';
import { OtContractStatus, OtProjectStatus } from '@/types/status-enums';
import { IDropdownAction, IWizardContentComponent } from '@/types/wizard-types';
import { capitalizeString } from '@/utils/string-utils';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { v4 as uuid } from 'uuid';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';
import { ROUTE_PROJECT_CONTRACTS, ROUTE_PROJECT_DEFAULT } from '../projects-routes';
import { ContractDetails, ContractDetailsPost, ContractWorkflow, parsedApiContractEnums } from './contract-models';
import { ROUTE_CONTRACT_ADD, ROUTE_CONTRACT_DEFAULT } from './contract-routes';

type WizardStepModelsType = ContractDetailsPost | ContractDetails | ContractWorkflow[] | OtDataDrivenInstance;

interface IAmendmentStep {
  stepName: string;
  contractWorkflowGid: string;
}

@Component({
  components: {
    OtWizardArchetype,
    OtButton,
    OtContractDetailsForm,
    OtLoadingSpinner,
    OtContractSetupForm,
    OtContractAmendmentsForm,
  },
})
export default class OtCreateContractWizard extends Vue {
  // * PROPS

  // * REFS
  @Ref('currentStepComponentRef') private readonly currentStepComponentRef!: IWizardContentComponent;

  // * DATA
  private api = new OtApi();
  private baseWizardSteps: string[] = ['Contract Details', 'Contract Setup'];
  private wizardTitle = 'Add Contract';
  private saving = false;
  private isLoading = false;
  private stepComponentProps: Record<string, unknown> = {};
  private stepComponentListeners: Record<string, unknown> = {};

  // This really should be the cotract details form object. Not the proper contract details object
  // there's things we need to be null in the initial screen state
  // that are NOT allowed to be null in the db. Projected Creation Date is one that just keeps kicking Dan over and over and over
  private contractDetails = ContractDetails.createNew(this.projectGid, '');
  private projectDetails = ProjectDetails.createEmpty();

  private amendmentSteps: IAmendmentStep[] = [];
  private currentAmendmentsDefinition: OtDataDrivenDefinition | null = null;
  private currentAmendmentsInstance: OtDataDrivenInstance | null = null;
  // we don't actually have an evaluation object for amendments, but the renderer expects one, so we'll give it an empty one
  private evaluationForAmendments = OtClientEvaluation.createEmpty();
  // nor do we have instances, but the renderer expects some
  private instancesForAmendments = OtDataDrivenInstances.createEmpty();

  private saveAsPendingActionKey = uuid();
  private saveAndContinueLaterActionKey = uuid();

  // * COMPUTED
  private activeStepIndexPrivate = 0;
  private get activeStepIndex() {
    return this.activeStepIndexPrivate;
  }
  private set activeStepIndex(val: number) {
    this.activeStepIndexPrivate = val;

    this.isLoading = true;

    this.getDataForCurrentStep()
      .then(() => {
        this.setCurrentStepComponentProps();
        this.isLoading = false;
      })
      .catch(() => {
        this.setCurrentStepComponentProps();
        this.isLoading = false;
      });
  }

  private get activeAmendmentStep() {
    if (this.activeStepIndex >= 2 && !!this.amendmentSteps.length) {
      return this.amendmentSteps[this.activeStepIndex - 2];
    }
    return null;
  }

  private async getDataForCurrentStep() {
    switch (this.currentStepComponent) {
      case OtContractDetailsForm:
        await this.getContractDetails();
        break;
      case OtContractSetupForm:
        await this.getContractDetails();
        break;
      case OtContractAmendmentsForm:
        if (this.activeAmendmentStep) {
          await Promise.all([
            this.getAmendmentsDefinition(this.activeAmendmentStep.contractWorkflowGid),
            this.getAmendmentsInstance(this.activeAmendmentStep.contractWorkflowGid),
          ]);
        }
        break;
    }
  }

  private get activeStepName() {
    return this.effectiveWizardSteps[this.activeStepIndex];
  }

  private get effectiveWizardSteps() {
    const amendments = this.amendmentSteps.map(s => s.stepName || '') || [];
    return this.baseWizardSteps.concat(amendments);
  }

  private get instanceTitle() {
    return this.contractDetails?.name || '';
  }

  private get currentStepComponent() {
    if (this.activeStepIndex > this.effectiveWizardSteps.length - 1 || this.activeStepIndex < 0) {
      return null;
    }

    switch (this.activeStepIndex) {
      case 0:
        return OtContractDetailsForm;
      case 1:
        return OtContractSetupForm;
      default:
        return OtContractAmendmentsForm;
    }
  }

  private get showBackButton() {
    return this.activeStepIndex !== 0;
  }

  private get activeStepIsLastinArray() {
    return this.activeStepIndex === this.effectiveWizardSteps.length - 1;
  }

  private get isOnLastWizardStep() {
    const workflowsWithAmendments = this.contractDetails.workflows.filter(w => w.isAmended);
    // More steps might get generated after the first 2 are completed.
    // That's why there's an extra check to make sure we're on at least step 3 if there are workflows with amendments
    return this.activeStepIsLastinArray && (this.activeStepIndex > 1 || !workflowsWithAmendments.length);
  }

  private get secondarySaveActions(): IDropdownAction[] {
    const saveAsPendingAction = {
      key: this.saveAsPendingActionKey,
      onClick: this.saveAsPending,
      label: 'Save as pending contract',
    };
    const saveAndContinueLaterAction = {
      key: this.saveAndContinueLaterActionKey,
      onClick: this.saveAndContinueLater,
      label: 'Save and continue later',
    };

    const isParentProjectPending = this.projectDetails.status === OtProjectStatus.Pending;

    if (this.isOnLastWizardStep && !isParentProjectPending) {
      return [saveAsPendingAction, saveAndContinueLaterAction];
    }
    return [saveAndContinueLaterAction];
  }

  private get saveBtnText() {
    const isParentProjectActive = this.projectDetails.status === OtProjectStatus.Active;
    if (!this.isOnLastWizardStep) {
      return 'Save & Continue';
    }
    // IF parent Project is Active, else the Secondary Option “Save as pending contract” becomes the Primary option and “Save & Create” is no longer available at all.
    if (isParentProjectActive) {
      return 'Save & Create Contract';
    } else {
      return 'Save as pending contract';
    }
  }

  private get saveBtnSubtext() {
    if (!this.activeStepIsLastinArray) {
      return `To ${this.effectiveWizardSteps[this.activeStepIndex + 1]}`;
    }
    return '';
  }

  private get projectGid() {
    return this.$route.params.projectGid;
  }

  private get contractGid(): string | null {
    return (this.$route.query.contractGid as string) || null;
  }

  private get hasAlreadyBeenSaved() {
    return !!this.contractGid;
  }

  private get dangerActions(): IDropdownAction[] {
    const cancelContractAction = {
      key: uuid(),
      onClick: this.cancelContract,
      label: 'Cancel this contract',
    };
    return [cancelContractAction];
  }

  private get amendmentsLayoutKey() {
    if (this.currentAmendmentsDefinition) {
      return this.currentAmendmentsDefinition.layoutsOrdered.filter(l => l.type === LayoutTypeEnum.Page)[0].key;
    }
    return null;
  }

  private get showContinueText() {
    return !this.amendmentSteps.length;
  }

  // * WATCHERS

  // * METHODS
  private setCurrentStepComponentProps() {
    // Keep in mind that the stepComponentProps will be reactive and
    // update the props on the component if you assign an object to it.
    // Modifying properties directly ( Eg. this.stepComponentProps.isEditMode = false)
    // doesn't cause the component to update the props
    this.stepComponentProps = {};
    this.stepComponentListeners = {};

    switch (this.activeStepIndex) {
      case 0:
        this.stepComponentProps = {};
        break;
      case 1:
        this.stepComponentProps = {
          typeGid: this.contractDetails.type.gid,
          contractWorkflows: this.contractDetails.workflows,
        };
        this.stepComponentListeners = {
          'update:contractWorkflows': this.updateWorkflows,
        };
        break;
      default:
        this.stepComponentProps = {
          definition: this.currentAmendmentsDefinition,
          layoutKey: this.amendmentsLayoutKey,
          instance: this.currentAmendmentsInstance,
          evaluation: this.evaluationForAmendments,
          instances: this.instancesForAmendments,
        };
        this.stepComponentListeners = {
          'update:instance': this.updateAmendmentsInstance,
        };
        break;
    }
  }

  private async backClicked() {
    this.activeStepIndex--;
  }

  private async cancelWizard() {
    this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
  }

  private async cancelContract() {
    this.saving = true;

    const params = new DangerModalParams({
      confirmText: 'Cancel Contract',
      cancelText: 'Return to wizard',
      title: 'Are you sure you want to cancel this contract?',
      message: `If you cancel this contract it will be deleted from the system and you won’t be able to continue this wizard. If you want to continue this wizard later, click the arrow next to the save button and and select 'Save and continue later'`,
    });
    const result = await vxm.modal.openDangerModal(params);
    if (result.ok) {
      const apiResposne = await this.setContractStatus(OtContractStatus.Cancelled);
      if (apiResposne.success) {
        this.currentStepComponentRef.setFormToCleanState();
        this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
      }
    }

    this.saving = false;
  }

  private async setContractStatus(status: OtContractStatus): Promise<ApiResponse<unknown>> {
    const contractGid = this.contractGid;
    if (contractGid) {
      const requestModel = new PostUpdateContractStatusRequestModel({
        contractStatus: parsedApiContractEnums[status],
        rowVersion: this.contractDetails.rowVersion,
      });
      const errorOverride: IApiErrorOverride[] = [
        {
          status: 409,
          message: `Unable to change the status of contract ${this.contractDetails.reference} to ${capitalizeString(
            status.toString().toLowerCase(),
          )} - contract has been modified by someone else. Please refresh the page and try again.`,
        },
      ];

      const apiResult = await executeApi(
        () => this.api.contracts().postUpdateContractStatus(contractGid, requestModel),
        `Set contract status to: ${status}`,
        errorOverride,
      );
      if (apiResult.success && apiResult.data) {
        // patch the row version onto the contract details we loaded
        // strikes me that we probably should reload the whole dang thing
        // but it's been working fine like this, so let's just do it this way and all move on
        const result = EntityModifiedResponse.createFromApiResponse(apiResult.data);
        console.log('ot-create-contract-wizard -> setContractStatus -> setting rowVersion to', result.rowVersion);
        this.contractDetails.rowVersion = result.rowVersion;
      }
      return apiResult;
    }
    return ApiResponse.CreateEmptyFailure(-1);
  }

  private async updateContractDetails(model: ContractDetailsPost): Promise<ApiResponse<unknown>> {
    const contractGid = this.contractGid;
    if (contractGid) {
      const requestModel = ContractDetailsPost.createUpdateRequestModel(model);
      const errorOverride: IApiErrorOverride[] = [
        {
          status: 409,
          message: `Unable to update contract details - contract has been modified by someone else. Please refresh the page and try again.`,
        },
      ];

      const apiResult = await executeApi(
        () => this.api.contracts().postUpdateContractDetails(contractGid, undefined, requestModel),
        `Update contract details`,
        errorOverride,
      );
      if (apiResult.success && apiResult.data) {
        // patch the row version onto the contract details we loaded
        // strikes me that we probably should reload the whole dang thing
        // but it's been working fine like this, so let's just do it this way and all move on
        const result = EntityModifiedResponse.createFromApiResponse(apiResult.data);
        console.log('ot-create-contract-wizard -> updateContractDetails -> setting rowVersion to', result.rowVersion);
        this.contractDetails.rowVersion = result.rowVersion;
      }
      return apiResult;
    }
    return ApiResponse.CreateEmptyFailure(-1);
  }

  private async saveCurrentStep(model: WizardStepModelsType): Promise<ApiResponse<unknown>> {
    switch (this.currentStepComponent) {
      case OtContractDetailsForm:
        if (this.hasAlreadyBeenSaved) {
          return this.updateContractDetails(model as ContractDetailsPost);
        } else {
          return this.insertNewContract(uuid(), model as ContractDetails);
        }
      case OtContractSetupForm:
        return this.upsertContractWorkflows(model as ContractWorkflow[]);
      case OtContractAmendmentsForm:
        return this.updateContractAmendments(model as OtDataDrivenInstance);
      default:
        console.error(
          'ot-create-contract-wizard -> saveCurrentStep -> unknown step component: ',
          this.currentStepComponent,
        );
        return ApiResponse.CreateEmptyFailure(-1);
    }
  }

  private async handlePrimarySaveClicked() {
    if (this.isOnLastWizardStep && this.projectDetails.status === OtProjectStatus.Pending) {
      return this.saveAsPending();
    }

    if (!this.currentStepComponentRef.validate()) {
      this.showValidationSnackbarError();
      return;
    }

    this.saving = true;
    const model = (await this.currentStepComponentRef.submit(true)) as WizardStepModelsType | null;

    if (!model) {
      // Step failed validation
      this.saving = false;
      return;
    }

    const apiResponse = await this.saveCurrentStep(model);

    if (this.isOnLastWizardStep) {
      if (apiResponse.success) {
        await this.setContractStatus(OtContractStatus.Active);
        this.currentStepComponentRef.setFormToCleanState();
        this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
      } else {
        this.activeStepIndex = 0;
      }
    } else {
      if (apiResponse.success) {
        this.activeStepIndex++;
      }
    }

    this.saving = false;
  }

  private async saveAsPending() {
    if (this.currentStepComponentRef.validate()) {
      const model = (await this.currentStepComponentRef.submit(true)) as WizardStepModelsType | null;

      if (!model) {
        // Step failed validation
        return;
      }
      this.saving = true;
      const apiResponse = await this.saveCurrentStep(model);

      if (apiResponse.success) {
        await this.setContractStatus(OtContractStatus.Pending);
        this.currentStepComponentRef.setFormToCleanState();
        this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
      }
      this.saving = false;
    } else {
      this.showValidationSnackbarError();
    }
  }

  private updateWorkflows(model: ContractWorkflow[]) {
    this.contractDetails.workflows = model;
    // The props don't update automatically
    // So we need to set the new value every time it updates
    this.stepComponentProps.contractWorkflows = this.contractDetails.workflows;
  }

  private updateAmendmentsInstance(model: OtDataDrivenInstance) {
    this.currentAmendmentsInstance = model;
    this.stepComponentProps.instance = this.currentAmendmentsInstance;
  }

  private async saveAndContinueLater() {
    if (this.currentStepComponentRef.validate()) {
      const model = (await this.currentStepComponentRef.submit(true)) as WizardStepModelsType | null;

      if (!model) {
        // Step failed validation
        return;
      }
      this.saving = true;
      const apiResponse = await this.saveCurrentStep(model);
      if (apiResponse.success) {
        this.currentStepComponentRef.setFormToCleanState();
        this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
      }
      this.saving = false;
    } else {
      this.showValidationSnackbarError();
    }
  }

  private showValidationSnackbarError() {
    this.createSnackbarError(
      'There is a validation error on the form that prevented us from saving the contract, please check the form for errors',
    );
  }

  private createSnackbarError(message: string) {
    const snackbarError = new SnackbarItem({
      type: SnackbarTypeEnum.Error,
      message: message,
    });
    vxm.snackbar.addToSnackbarQueue(snackbarError);
  }

  private async insertNewContract(gid: string, model: ContractDetails): Promise<ApiResponse<unknown>> {
    this.contractDetails = model;
    const requestModel = ContractDetails.createRequestModel(model, gid);

    const apiResponse = await executeApi(
      () => this.api.contracts().postInsertContract(undefined, requestModel),
      'Insert New Contract',
    );

    // We don't need to care about the rowversion on the response here. We navigate, which will reload the contract for us
    if (apiResponse.success) {
      this.contractDetails.gid = gid;
      await this.$router.push({
        name: ROUTE_CONTRACT_ADD,
        params: { projectGid: this.contractDetails.projectGid },
        query: { contractGid: gid },
      });
    }

    return apiResponse;
  }

  private async upsertContractWorkflows(model: ContractWorkflow[]): Promise<ApiResponse<unknown>> {
    const workflows = ContractWorkflow.createRequestModel(model);
    const requestModel = new PostUpsertContractWorkflowsRequestModel({
      contractWorkflows: workflows,
      rowVersion: this.contractDetails.rowVersion,
    });
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 409,
        message: `Unable to update contract workflows - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    const apiUpsertResponse = await executeApi(
      () => this.api.contracts().postUpsertContractWorkflows(this.contractDetails.gid, undefined, requestModel),
      `Update Contract Workflows ${this.contractDetails.name} (${this.contractDetails.gid})`,
      errorOverride,
    );

    if (apiUpsertResponse.success && apiUpsertResponse.data) {
      // patch the row version onto the contract details we loaded
      const result = EntityModifiedResponse.createFromApiResponse(apiUpsertResponse.data);
      this.contractDetails.rowVersion = result.rowVersion;
      console.log('ot-create-contract-wizard -> upsertContractWorkflows -> setting rowVersion to', result.rowVersion);
      this.amendmentSteps = model
        .filter(w => w.isAmended)
        .map(w => ({ stepName: w.name, contractWorkflowGid: w.gid || '' }));
    }

    return apiUpsertResponse;
  }

  private async updateContractAmendments(model: OtDataDrivenInstance): Promise<ApiResponse<void>> {
    const apiResponse = await executeApi(
      () =>
        this.api
          .segmentInstances()
          .postContractWorkflowAmendmentsResponse(model.gid, undefined, model.createContractWorkflowRequestModel()),
      'Save Contract Workflow Responses',
    );
    return apiResponse;
  }

  private async getProjectDetails() {
    if (this.projectGid) {
      const result = await executeApi(
        () => this.api.projects().getProjectDetails(this.projectGid),
        'Load Project Details',
      );
      if (result.success && result.data) {
        this.projectDetails = ProjectDetails.createFromApiResponse(result.data);
        if (
          this.projectDetails.status === OtProjectStatus.Suspended ||
          this.projectDetails.status === OtProjectStatus.Complete
        ) {
          console.log('Project is suspended or complete');
          {
            const modalParams = new DangerModalParams({
              title: 'Cannot create contract',
              message: `You cannot add a Contract to a ${this.projectDetails.status} Project`,
              confirmText: 'Ok',
              hideCancelButton: true,
            });

            const modalResult = await vxm.modal.openDangerModal(modalParams);
            if (modalResult.ok) {
              this.$router.push({
                name: ROUTE_PROJECT_DEFAULT,
                params: { projectGid: this.projectGid },
              });
            }
            return;
          }
        }
      }
    }
  }

  private async getContractDetails() {
    const contractGid = this.contractGid;
    if (contractGid) {
      const apiResponse = await executeApi(
        () => this.api.contracts().getContractDetails(contractGid),
        'Get Contract details',
      );
      if (apiResponse.success && apiResponse.data?.contract) {
        const contract = ContractDetails.createFromApiResponse(apiResponse.data.contract);
        if (contract.status !== OtContractStatus.Draft) {
          // they CANNOT edit non draft contracts, bump them to the view contract page
          this.$router.push({
            name: ROUTE_CONTRACT_DEFAULT,
            params: { projectGid: contract.projectGid, contractGid: contract.gid },
          });
          return;
        }
        this.contractDetails = contract;
        console.log('ot-wf-create-contract-wizard -> getContractDetails -> contractDetails should be', { ...contract });
        console.log('ot-wf-create-contract-wizard -> getContractDetails -> contractDetails is', {
          ...this.contractDetails,
        });
      } else {
        this.createSnackbarError('Unable to find incomplete contract');
        this.$router.push({ name: ROUTE_PROJECT_CONTRACTS, params: { projectGid: this.contractDetails.projectGid } });
      }
    }
  }

  private async getAmendmentsDefinition(contractWorkflowGid: string) {
    const apiResponse = await executeApi(
      () => this.api.contractWorkflows().getContractWorkflowAmendmentsDefinition(contractWorkflowGid),
      `Get Contract Amendment Definition: ${contractWorkflowGid}`,
    );
    if (apiResponse.success && apiResponse.data) {
      this.currentAmendmentsDefinition = OtDataDrivenDefinition.createFromApiResponse(apiResponse.data);
    }
  }

  private async getAmendmentsInstance(contractWorkflowGid: string) {
    const apiResponse = await executeApi(
      () => this.api.contractWorkflows().getContractWorkflowAmendmentsResponse(contractWorkflowGid),
      `Get Contract Amendment Responses: ${contractWorkflowGid}`,
    );
    if (apiResponse.success && apiResponse.data) {
      // This is a bit of a hack, but...
      // As part of https://redgum.atlassian.net/browse/OTMAN-413
      // we made it so that if the contract wasn't amended, we'd go off and load the DEFAULT amendments
      // which was needed so we could show the effective ammendments to an "unamended" contract (one created from a template)
      // These default amendments come with a GUID in them, which isn't the correct ContractWorkflow guid
      // so, if we try and save an amended contract, we (rightly so) get a 404
      // Rather than change the GetContractWorkflowAmendmentsDefinition, which we're worried about,
      // we're going to patch the object here to have the correct guid so we can save it
      const resp = OtDataDrivenInstance.createFromApiResponse(apiResponse.data);
      resp.gid = contractWorkflowGid;
      this.currentAmendmentsInstance = resp;
    }
  }

  // * LIFECYCLE
  private async created() {
    this.isLoading = true;
    const apiCalls: Promise<void>[] = [this.getProjectDetails()];

    if (this.contractGid) {
      apiCalls.push(this.getContractDetails());
      await Promise.all(apiCalls);
      this.amendmentSteps = this.contractDetails.workflows
        .filter(w => w.isAmended)
        .map(w => ({
          stepName: w.name,
          contractWorkflowGid: w.gid || '',
        }));
    } else {
      await Promise.all(apiCalls);
      this.contractDetails = ContractDetails.createNew(this.projectGid, this.projectDetails.timezone);
    }

    this.setCurrentStepComponentProps();

    this.isLoading = false;
  }
}
