import { UnsavedChangesModal } from '@/components/specific-modals/unsaved-changes-modal-models';
import { createModule, mutation, action } from 'vuex-class-component';
import {
  FormModalResult,
  FormModalParams,
  FormModal,
  DangerModal,
  DangerModalParams,
  DangerModalResult,
} from './form-modal-models';

const vuexModule = createModule({
  namespaced: 'modal',
  strict: false,
});

export default class ModalModule extends vuexModule {
  public formModalRef: FormModal | null = null;
  public dangerModalRef: DangerModal | null = null;
  public unsavedChangesModalRef: UnsavedChangesModal | null = null;

  // * GETTERS

  // * MUTATIONS
  @mutation
  public setFormModalRef(modalRef: FormModal): void {
    this.formModalRef = modalRef;
  }

  @mutation
  public setDangerModal(modalRef: DangerModal): void {
    this.dangerModalRef = modalRef;
  }

  @mutation
  public setUnsavedChangesModalRef(modalRef: UnsavedChangesModal): void {
    this.unsavedChangesModalRef = modalRef;
  }

  // * ACTIONS
  @action
  public async openFormModal(params: FormModalParams) {
    if (this.formModalRef) {
      const result = await this.formModalRef.open(params);
      return result;
    }
    return new FormModalResult({ ok: false });
  }

  @action
  public async openDangerModal(params: DangerModalParams) {
    if (this.dangerModalRef) {
      const result = await this.dangerModalRef.open(params);
      return result;
    }
    return new DangerModalResult({ ok: false });
  }

  @action
  public async openUnsavedChangesModal() {
    if (this.unsavedChangesModalRef) {
      const result = await this.unsavedChangesModalRef.open();
      return result;
    }
    return new FormModalResult({ ok: false });
  }
}
