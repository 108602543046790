






































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtBreadcrumbs from '@/components/global/ot-breadcrumbs.vue';
import OtButton from '@/components/global/ot-button.vue';
import { IInputAction, IInputBreadcrumb } from '../common-models';
import OtTabs from '@/components/global/ot-tabs.vue';
import { Location } from 'vue-router';

@Component({
  components: {
    OtBreadcrumbs,
    OtButton,
    OtTabs,
  },
})
export default class OtStandardHeaderArchetype extends Vue {
  // * PROPS
  @Prop() private sectionTitle!: string;
  @Prop() private subTitle?: string;
  @Prop({ default: false }) private isDiableContextBtn!: boolean;
  @Prop() private primaryContextBtn?: IInputAction;
  @Prop() private secondaryContextBtns?: IInputAction[];
  @Prop() private isDisableBreadcrumbs?: boolean;
  @Prop() private overrideBreadcrumbs?: IInputBreadcrumb[];

  // * REFS

  // * DATA

  // * COMPUTED
  private get isShowDropdown() {
    if (this.secondaryContextBtns && this.secondaryContextBtns.length > 0) {
      return true;
    }
    return false;
  }

  //  set the subtitle
  private get hasSubTitle() {
    return this.subTitle ? true : false;
  }

  private get isShowBreadcrumbs() {
    if (this.isDisableBreadcrumbs) {
      return !this.isDisableBreadcrumbs;
    }
    return this.$route.name !== 'Dashboard';
  }

  private isActionALocation(action: Location | (() => void)) {
    if (typeof action === 'function') {
      return false;
    }

    if (typeof action === 'object') {
      return true;
    }

    return false;
  }

  // * WATCHERS

  // * METHODS

  // }

  // * LIFECYCLE
}
