import {
  DataDrivenInstanceModel as ApiDataDrivenInstanceModel,
  DataDrivenInstancesModel as ApiDataDrivenInstancesModel,
  PostUpdateSegmentInstanceResponseRequestModel as ApiPostUpdateSegmentInstanceResponseRequestModel,
  PostContractWorkflowAmendmentsResponseRequestModel as ApiPostContractWorkflowAmendmentsResponseRequestModel,
} from '@/services/generated/api';
import { OtDataDrivenResponse } from './data-driven-response';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenModified } from './data-driven-modified';

export interface IDataDrivenInstance {
  gid: string;
  runGid: string;
  versionId: number;
  responses: OtDataDrivenResponse[]; // For initial request, property will be an empty array
  created: OtDataDrivenModified | null;
  finished: OtDataDrivenModified | null;
  phaseKey: string | null;
}

export class OtDataDrivenInstance implements IDataDrivenInstance {
  public gid!: string;
  public versionId!: number;
  public responses!: OtDataDrivenResponse[];
  public runGid!: string;
  public created!: OtDataDrivenModified | null;
  public finished!: OtDataDrivenModified | null;
  public phaseKey!: string | null;

  constructor(value: IDataDrivenInstance) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OtDataDrivenInstance({
      gid: '',
      runGid: '',
      versionId: 0,
      responses: [],
      created: null,
      finished: null,
      phaseKey: null,
    });
  }

  public static createFromApiResponse(model: ApiDataDrivenInstanceModel) {
    return new OtDataDrivenInstance({
      gid: model.gid,
      runGid: model.runGid,
      versionId: model.versionId,
      responses: model.responses.map(r => OtDataDrivenResponse.createFromApiResponse(r)),
      created: model.created ? OtDataDrivenModified.createFromApiResponse(model.created) : null,
      finished: model.finished ? OtDataDrivenModified.createFromApiResponse(model.finished) : null,
      phaseKey: model.phaseKey || null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenInstance>) {
    return new OtDataDrivenInstance({
      gid: model.gid || uuid(),
      runGid: model.gid || uuid(),
      versionId: model.versionId || 0,
      responses: model.responses?.map(r => OtDataDrivenResponse.createFromJson(r)) || [],
      created: model.created || null,
      finished: model.finished || null,
      phaseKey: model.phaseKey || null,
    });
  }

  public static createContractWorkflowRequestModel(model: OtDataDrivenInstance) {
    return new ApiPostContractWorkflowAmendmentsResponseRequestModel({
      runGid: model.runGid,
      versionId: model.versionId,
      responses: model.responses.map(r => r.createRequestModel()),
    });
  }

  public static createUpdateSegmentInstanceResponseModel(params: { model: OtDataDrivenInstance }) {
    return new ApiPostUpdateSegmentInstanceResponseRequestModel({
      responses: params.model.responses.map(r => r.createRequestModel()),
    });
  }

  public createContractWorkflowRequestModel() {
    return OtDataDrivenInstance.createContractWorkflowRequestModel(this);
  }

  public createUpdateSegmentInstanceResponseModel() {
    return OtDataDrivenInstance.createUpdateSegmentInstanceResponseModel({ model: this });
  }

  public static compareByCreated(left: OtDataDrivenInstance, right: OtDataDrivenInstance) {
    return OtDataDrivenModified.compareByDate(left.created, right.created);
  }
}

export interface IDataDrivenInstances {
  gid: string;
  instances: IDataDrivenInstance[];
}

export class OtDataDrivenInstances implements IDataDrivenInstances {
  public gid!: string;
  public instances!: OtDataDrivenInstance[];

  constructor(value: IDataDrivenInstances) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OtDataDrivenInstances({
      gid: '',
      instances: [],
    });
  }
  public static createFromApiResponse(model: ApiDataDrivenInstancesModel) {
    return new OtDataDrivenInstances({
      gid: model.gid,
      instances: model.instances.map(x => OtDataDrivenInstance.createFromApiResponse(x)),
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenInstances>) {
    return new OtDataDrivenInstances({
      gid: model.gid || uuid(),
      instances: model.instances?.map(x => OtDataDrivenInstance.createFromJson(x)) || [],
    });
  }
}
