export function capitalizeString(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isNumber(string: string): boolean {
  // TODO this reckons "1 banana" is a number. I'm no mathologist, but I don't think it is
  return !Number.isNaN(parseFloat(string));
}

const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
} as { [key: string]: string };

export function escapeHTML(str: string): string {
  return str.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag);
}

export function removeAllHtmlTags(str: string): string {
  return str.replace(/<[^>]*>/g, '');
}

const matchNonNumbers = /[^0-9]/g;
const matchNonNumbersOrNegative = /[^0-9-]/g;

function stripNonNumericChars(text: string, allowNegative = false): string {
  // Remove anything that isn't a number
  const cleanText = text.replaceAll(allowNegative ? matchNonNumbersOrNegative : matchNonNumbers, '');
  return cleanText;
}

export function filterPaste(event: ClipboardEvent, textValue: string | null, allowNegative = false) {
  const rawData = event.clipboardData?.getData('text') || '';
  const data = stripNonNumericChars(rawData, allowNegative);

  event.preventDefault();

  if (data) {
    const textField = event.target as HTMLInputElement;
    const startPos = textField.selectionStart || 0;
    const endPos = textField.selectionEnd || 0;

    const stringStart = textValue?.slice(0, startPos) || '';
    const stringEnd = textValue?.slice(endPos || 0, textValue?.length || 0) || '';
    textValue = stringStart + data + stringEnd;

    // move cursor to correct position after paste
    const resetCursor = (field: HTMLInputElement) => {
      field.selectionStart = startPos + data.length;
      field.selectionEnd = startPos + data.length;
    };

    return { textValue, resetCursor, startPos, data };
  }
  return null;
}

// Produces and output like: item 1, item 2, item 3 and item 4
export function joinArray(
  arr: string[],
  options?: { seperator?: string; finalSeperator?: string; boldItems?: boolean },
) {
  const combinedItems = arr.reduce((previousValue, item, index, array) => {
    const term = `${options?.boldItems ? '<b>' : ''}${item}${options?.boldItems ? '</b>' : ''}`;
    if (index === 0) {
      return `${previousValue}${term}`;
    } else if (index === array.length - 1) {
      return `${previousValue}${options?.finalSeperator || ' and '}${term}`;
    } else {
      return `${previousValue}${options?.seperator || ', '}${term}`;
    }
  }, '');

  return combinedItems;
}
