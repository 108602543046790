// not convinced this is the correct spot for this file, but it'll do for now

import { ClientEvaluationModel as ApiClientEvaluationModel } from '@/services/generated/api';
import { OtDataDrivenModified } from './data-driven-modified';

export interface IDataDrivenContext {
  key: string;
  value: string | null;
  values: string[];
}
export interface IClientEvaluation {
  keys: string[];
  context2: { [key: string]: unknown };
  runGid: string;
  phaseKey: string | null;
  created: OtDataDrivenModified | null;
  finished: OtDataDrivenModified | null;
}

export class OtClientEvaluation implements IClientEvaluation {
  public keys!: string[];
  public context2!: { [key: string]: unknown };
  public runGid!: string;
  public phaseKey!: string | null;
  public created!: OtDataDrivenModified | null;
  public finished!: OtDataDrivenModified | null;

  constructor(value: IClientEvaluation) {
    Object.assign(this, value);
  }
  public static createEmpty() {
    return new OtClientEvaluation({
      keys: [],
      context2: {},
      runGid: '',
      phaseKey: null,
      created: null,
      finished: null,
    });
  }
  public static createFromApiResponse(model: ApiClientEvaluationModel) {
    return new OtClientEvaluation({
      keys: model.keys,
      context2: model.context2 ?? {},
      runGid: model.runGid,
      phaseKey: model.phaseKey || null,
      created: model.created ? OtDataDrivenModified.createFromApiResponse(model.created) : null,
      finished: model.finished ? OtDataDrivenModified.createFromApiResponse(model.finished) : null,
    });
  }
}
