














import { Component, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';

@Component({})
export default class OtSnackbarWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private openDefault() {
    const item = new SnackbarItem({ type: SnackbarTypeEnum.Default, message: 'Default Snackbar', displayTime: 1000 });
    vxm.snackbar.addToSnackbarQueue(item);
  }

  private openError() {
    const item = new SnackbarItem({
      type: SnackbarTypeEnum.Error,
      message: ['Error Snackbar', 'Second Line'],
      displayTime: 5000,
    });
    vxm.snackbar.addToSnackbarQueue(item);
  }

  private openPrimary() {
    const item = new SnackbarItem({
      type: SnackbarTypeEnum.Primary,
      message: 'Primary Snackbar',
    });
    vxm.snackbar.addToSnackbarQueue(item);
  }

  private openSuccess() {
    const item = new SnackbarItem({ type: SnackbarTypeEnum.Success, message: 'Success Snackbar', displayTime: null });
    vxm.snackbar.addToSnackbarQueue(item);
  }

  // * LIFECYCLE
}
