











































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { asForm, IValidate, IVForm } from '@/utils/type-utils';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import { v4 as uuid } from 'uuid';
import { parseISO, parse, isAfter, isBefore, max, min, format } from 'date-fns';

export enum DatepickerTypeEnum {
  Single = 1,
  Multiple = 2,
  Range = 3,
}

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
  },
})
export default class OtDatePicker extends Vue implements IValidate {
  // * PROPS
  @Prop({ default: DatepickerTypeEnum.Single }) private datePickerType!: DatepickerTypeEnum;
  @Prop({ default: null }) private value!: Date | Date[] | null;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop() private rules?: Array<(value: string | string[] | null) => boolean | string>;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ default: null }) private formContainerElement!: HTMLElement | null;
  @Prop() private errorMessages?: Array<string>;
  // set this to false if you need the datepicker to be able to "hang outside" the parent component (like in a dialog)
  // but be careful, if the parent component is scrollable, the picker will no longer "follow" the field and it'll look dumb
  @Prop({ type: Boolean, default: true }) private attachToField!: boolean;

  // * REFS
  @Ref('datePickerFieldRef') private readonly datePickerFieldRef!: HTMLDivElement;
  @Ref('comboboxRef') private readonly comboboxRef!: Vue;

  // * DATA
  private datePickerIsOpenPrivate = false;
  private get datePickerIsOpen() {
    return this.datePickerIsOpenPrivate;
  }
  private set datePickerIsOpen(value: boolean) {
    this.datePickerIsOpenPrivate = value;
    if (!value) {
      // only validate when closed
      this.validate();
    }
  }
  private componentId = '';
  private isRequiredRule = (value: string | string[] | null) =>
    (!!value && value.length !== 0) || this.requiredMessage || '';
  // private valid = true;
  private hasBeenValidatedBefore = false;
  private errorBucket: string[] = [];

  private observer: MutationObserver | null = null;
  private openDatePickerAboveInput = false;

  // * COMPUTED
  public get dates(): string[] | string | null {
    if (Array.isArray(this.value)) {
      return this.value.map(d => format(d, 'yyyy-MM-dd'));
    } else if (this.value) {
      return format(this.value, 'yyyy-MM-dd');
    }
    return null;
  }
  public set dates(v: string[] | string | null) {
    // console.log('datepicker dates set', { v, hbv: this.hasBeenValidatedBefore });
    switch (this.datePickerType) {
      case DatepickerTypeEnum.Single:
        this.datePickerIsOpen = false;
        break;
      case DatepickerTypeEnum.Range:
        if (Array.isArray(v) && v.length == 2) {
          this.datePickerIsOpen = false;
        }
        break;
    }
    if (this.hasBeenValidatedBefore) {
      this.validate(false, v);
    }

    if (Array.isArray(v)) {
      this.$emit(
        'input',
        v.map(c => parse(c, 'yyyy-MM-dd', new Date())),
      );
    } else if (v) {
      this.$emit('input', parse(v, 'yyyy-MM-dd', new Date()));
    } else {
      this.$emit('input', null);
    }
  }

  private get firstDate(): Date | null {
    if (this.dates && Array.isArray(this.dates) && this.dates.length) {
      return parseISO(this.dates[0]);
    }
    return null;
  }
  private get lastDate(): Date | null {
    if (this.dates && Array.isArray(this.dates) && this.dates.length >= 1) {
      return parseISO(this.dates[1]);
    }
    return null;
  }

  private get startDate(): Date | null {
    if (this.firstDate && this.lastDate) {
      return min([this.firstDate, this.lastDate]);
    } else if (this.firstDate) {
      return this.firstDate;
    }
    return null;
  }

  private get endDate(): Date | null {
    if (this.firstDate && this.lastDate) {
      return max([this.firstDate, this.lastDate]);
    } else if (this.lastDate) {
      return this.lastDate;
    }
    return null;
  }

  private get isSingleDatepicker(): boolean {
    return this.datePickerType === DatepickerTypeEnum.Single;
  }

  private get isMultipleDatepicker(): boolean {
    return this.datePickerType === DatepickerTypeEnum.Multiple;
  }

  private get isRangeDatepicker(): boolean {
    return this.datePickerType === DatepickerTypeEnum.Range;
  }

  private get inputRules(): Array<(value: string | string[] | null) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  private get effectiveErrorMessages() {
    if (this.errorBucket && this.errorMessages) {
      return this.errorBucket.concat(this.errorMessages);
    } else if (this.errorBucket) {
      return this.errorBucket;
    } else if (this.errorMessages) {
      return this.errorMessages;
    }
    return null;
  }

  // * WATCHERS

  @Watch('requiredMessage')
  private requiredMessageChanged() {
    if (this.hasBeenValidatedBefore) {
      this.validate();
    }
  }

  @Watch('errorMessages')
  private errorMessagesChanged() {
    // fairly sure we want to run validation all the time when the error messages change, not just when we've previously been validated
    // but I'm torn - this is a bit like the required message changing like above, and it runs only after we've done one validation run
    // this works for now, but it's something to look at if the users ever report "it's validating too early". I can't think of a scenario
    // where it might, but you never know
    // if (this.hasBeenValidatedBefore) {
    this.validate();
    // }
  }

  // * METHODS
  public resetValidation() {
    // this.valid = true;
    this.errorBucket = [];
    this.hasBeenValidatedBefore = false;
  }

  public reset() {
    this.dates = null;
    this.resetValidation();
  }

  // even though we aren't using the 'force' parameter we still need it
  // because vuetify will be calling this function and it expects one there
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(force = false, value?: string | string[] | null): boolean {
    // console.log('datepicker validate', { force, value, dates: this.dates });
    if (!this.readonly) {
      const errorBucket = [];
      value = value ?? this.dates;

      this.hasBeenValidatedBefore = true;

      for (let index = 0; index < this.inputRules.length; index++) {
        const rule = this.inputRules[index];
        const valid = typeof rule === 'function' ? rule(value) : rule;

        if (valid === false || typeof valid === 'string') {
          errorBucket.push(valid || '');
        }
      }

      this.errorBucket = errorBucket;
      return !this.effectiveErrorMessages || this.effectiveErrorMessages.length === 0;
    }
    return true;
  }

  private displayRangeItem(item: string | string[]) {
    if (Array.isArray(item) && item.length == 2) {
      return true;
    }
    return false;
  }

  private formatDate(date: string): string {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  private functionEvents(val: string): string | undefined {
    if (this.datePickerType === DatepickerTypeEnum.Range) {
      const parsedDate = parseISO(val);
      if (this.startDate && this.endDate) {
        const isStart = parsedDate.valueOf() === this.startDate.valueOf();
        const isEnd = parsedDate.valueOf() === this.endDate.valueOf();
        if (isStart && isEnd) {
          return 'active-date --only';
        } else if (isStart) {
          return 'active-date --start';
        } else if (isEnd) {
          return 'active-date --end';
        } else if (isAfter(parsedDate, this.startDate) && isBefore(parsedDate, this.endDate)) {
          return 'active-date --middle';
        }
      } else if (this.startDate) {
        const isStart = parsedDate.valueOf() === this.startDate.valueOf();
        if (isStart) {
          return 'active-date --only';
        }
      }
    }
  }

  private transferAllEventClassesToButton(targetElement: Element): void {
    const eventHolders = targetElement.querySelectorAll('.v-date-picker-table__events');
    for (const eventHolder of eventHolders) {
      this.addEventClassesToButton(eventHolder);
    }
  }

  private addEventClassesToButton(eventHolder: Element): void {
    const closestButton = eventHolder.closest('.v-btn');
    this.transferEventClassesToButton(eventHolder, 'add', closestButton as Element);
  }

  private removeEventClassesFromButton(eventHolder: Element, button: Element): void {
    this.transferEventClassesToButton(eventHolder, 'remove', button);
  }

  private transferEventClassesToButton(eventHolder: Element, addOrRemove: 'add' | 'remove', button: Element): void {
    const closestButton = button || eventHolder.closest('.v-btn');
    const activeDay = eventHolder.querySelector('.active-date');
    if (closestButton && activeDay) {
      const classesAsArray = Array.from(activeDay.classList.values());
      if (addOrRemove === 'add') {
        closestButton.classList.add(...classesAsArray);
      } else {
        closestButton.classList.remove(...classesAsArray);
      }
    } else {
      console.warn('datepicker transferEventClassesToButton one of these is null and it should not be', {
        closestButton,
        activeDay,
      });
    }
  }

  private remove(item: string | string[], event: MouseEvent) {
    if (this.readonly || this.disabled) {
      return;
    }

    event.stopPropagation();
    if (Array.isArray(item)) {
      this.dates = [];
      return;
    }
    if (Array.isArray(this.dates)) {
      const datesCopy = [...this.dates];
      for (let i = 0; i < datesCopy.length; i++) {
        const date = datesCopy[i];
        if (date === item) {
          datesCopy.splice(i, 1);
        }
      }
      this.dates = datesCopy;
      return;
    }
    this.dates = '';
  }

  private inputFieldClicked() {
    if (this.formContainerElement) {
      const datePickerBoundingRect = this.datePickerFieldRef.getBoundingClientRect();
      const containerBoundingRect = this.formContainerElement.getBoundingClientRect();
      const roomAbove = datePickerBoundingRect.y - containerBoundingRect.top;
      const roomBelow = containerBoundingRect.height + containerBoundingRect.top - datePickerBoundingRect.bottom;
      const datePickerHeight = 328;
      // If there's enough room below the input, open the datepicker below.
      // If there isn't enough room to scroll up and fit the date picker there, always show below
      // if there's enough room above, open it above.
      // If there's not enough room either side for the date picker, choose the place with the most room.
      const cantScrollUpEnoughForDatePicker = roomAbove + this.formContainerElement.scrollTop <= datePickerHeight;
      if (roomBelow > datePickerHeight || cantScrollUpEnoughForDatePicker) {
        this.openDatePickerAboveInput = false;
      } else if (roomAbove > datePickerHeight) {
        this.openDatePickerAboveInput = true;
      } else {
        this.openDatePickerAboveInput = roomAbove > roomBelow;
      }
    } else {
      this.openDatePickerAboveInput = false;
    }
  }

  private onClickOutside() {
    if (this.datePickerIsOpen) {
      this.datePickerIsOpen = false;
    }
  }

  private clickOutsideInclude() {
    return [this.comboboxRef.$el];
  }

  private comboboxOn(on: { click?: (e: MouseEvent) => void; focus?: (e: FocusEvent) => void }) {
    return {
      focus: (e: FocusEvent) => {
        this.inputFieldClicked();
        if (on.focus) {
          on.focus(e);
        }
      },
      click: (e: MouseEvent) => {
        this.inputFieldClicked();
        if (on.click) {
          on.click(e);
        }
      },
    };
  }

  // * LIFECYCLE
  private created() {
    this.componentId = `e-${uuid()}`;
  }

  private mounted(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;

    // Find the first form element and register this component to it if it is found
    // so that the validation on this component can trigger when the form.validate() function is called
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.register(this);
    }

    // this all relies on eager being set to FALSE (or not specified) on the menu
    // $el will be a div that holds the thing that becomes the actual menu
    // the thing that becomes the actual menu gets moved to a direct child of the v-app
    // and it stays there until our component goes away
    // (this is unless you specify a different target for the menu to appear in, but you won't do that)

    // there can be more than one datepicker on the page, so we have to go looking for *our* datepicker
    // which we can tell is ours because of the componentId UUID we generated furhter up
    const menuContent = this.$root.$el.querySelector('.datepicker__content.' + this.componentId);
    if (menuContent) {
      this.observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          for (const added of mutation.addedNodes) {
            const addedElement = added as Element;
            if (addedElement && addedElement.classList.contains('v-date-picker-table__events')) {
              // an event cell has been added. Transfer stuff
              this.addEventClassesToButton(addedElement);
            } else if (addedElement) {
              // something else has happened. Check for event tables just in case
              // there's to many interactions that can happen to list them individually
              // so far I've found > and < buttons, swapping to month view
              // actually, that's only two, but the month view back to day view one is tricky
              this.transferAllEventClassesToButton(addedElement);
            }
          }
          for (const removed of mutation.removedNodes) {
            const removedElement = removed as Element;
            // console.log('datepicker mutation removed', removed);
            if (removedElement && removedElement.classList.contains('v-date-picker-table__events')) {
              // console.log('  datepicker mutation removed was a table of events');
              // console.log('  datepicker mutation removed target', mutation.target);
              // an event cell has been removed. Transfer stuff
              this.removeEventClassesFromButton(removedElement, mutation.target as Element);
            }
          }
        }
      });
      this.observer.observe(menuContent, { attributes: false, childList: true, subtree: true });
    } else {
      console.error('ot-date-picker mounted: could not find the menu content');
    }
  }

  private beforeDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.unregister(this);
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
