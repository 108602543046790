



























import { Component, Vue, Prop } from 'vue-property-decorator';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { ContractDetails } from './contract-models';
import { ProjectDetails } from '../project-models';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';
import { OtAmendedStatus, OtStatusType } from '@/types/status-enums';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtContractAmendmentsDisplay from '@/areas/projects/contracts/ot-contract-amendments-display.vue';

type workflowWrapper = {
  expanded: boolean;
  workflow: ContractDetails['workflows'][number];
};

@Component({
  components: {
    OtWorkflowEngineProcessor,
    OtExpansionPanel,
    OtTag,
    OtContractAmendmentsDisplay,
  },
})
export default class OtContractWorkflows extends Vue {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop() private projectDetails!: ProjectDetails;

  // * DATA
  private workflows: workflowWrapper[] = [];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  private getTagAmendedStatus(isAmended: boolean): TagStatus {
    return { type: OtStatusType.Generic, status: isAmended ? OtAmendedStatus.Amended : OtAmendedStatus.Unamended };
  }

  // * LIFECYCLE

  private created() {
    this.workflows = this.contractDetails.workflows.map(wf => ({ expanded: false, workflow: wf }));
  }

  // private mounted() {}
}
