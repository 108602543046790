


















































import { OtSegmentResolution, segmentResolutionToString } from '@/types/status-enums';
import { durationString } from '@/utils/duration-utils';
import { CalendarTypeEnum, SegmentProcessTypeEnum } from '@/wf-components/models/data-driven-enums';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClaimResultsObject, SegmentInstanceDetails } from '../claims-models';

@Component({})
export default class OtClaimOverrideDetails extends Vue {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private resultDetails!: ClaimResultsObject;

  // * REFS

  // * DATA
  private data: unknown;

  // * COMPUTED
  private get hasSelectedOutcome() {
    return Boolean(this.resultDetails.selectedOutcome);
  }
  private get isOverride() {
    return this.hasSelectedOutcome && this.resultDetails.selectedOutcome !== this.resultDetails.recommendedOutcome;
  }
  private get isProcessTypeNotice() {
    return this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.Notice;
  }
  private get showAdjustedDateForPcMessage() {
    return this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime;
  }

  private get resultStateClass() {
    // absolute cut down version from ot-claim-recommendation.vue
    if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Indeterminate) {
      return '--indeterminate-override';
    }
    return '--standard-override';
  }

  private get recommendedGrantString() {
    const resolution = segmentResolutionToString(this.resultDetails.recommendedOutcome);
    if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Accepted) {
      return (
        resolution + ': ' + durationString(this.resultDetails.initialDuration || 0, this.resultDetails.initialCalendar)
      );
    }
    return resolution;
  }
  private get selectedGrantString() {
    if (!this.resultDetails.selectedOutcome) {
      return 'Unknown';
    }

    const resolution = segmentResolutionToString(this.resultDetails.selectedOutcome);
    if (
      this.resultDetails.selectedOutcome === OtSegmentResolution.Accepted ||
      this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime
    ) {
      return (
        resolution +
        ': ' +
        durationString(this.resultDetails.selectedDuration || 0, this.resultDetails.selectedCalendar)
      );
    }
    return resolution;
  }

  // * WATCHERS

  // * METHODS
  private outcomeToString(outcome: OtSegmentResolution) {
    return segmentResolutionToString(outcome);
  }

  // * LIFECYCLE
}
