import { v4 as uuid } from 'uuid';

export enum SnackbarTypeEnum {
  Default,
  Error,
  Primary,
  Success,
}

export interface ISnackbarItem {
  message: string | string[];
  type: SnackbarTypeEnum;
  displayTime?: number | null;
  ref?: HTMLElement | null;
}

export class SnackbarItem implements ISnackbarItem {
  public id!: string;
  public message!: string | string[];
  public isVisible!: boolean;
  public type!: SnackbarTypeEnum;
  public displayTime: number | null = 8000;
  public ref?: HTMLElement | null;

  public constructor(value: ISnackbarItem) {
    this.id = uuid();
    this.isVisible = false;
    Object.assign(this, value);
  }
}
