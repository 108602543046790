














































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtSelect from '@/components/global/ot-select.vue';
import { isOnlyNumbers } from '@/utils/validation-utils';
import { v4 as uuid } from 'uuid';
import { AustralianStates } from '@/well-known-values/states';
import { IVForm } from '@/utils/type-utils';
import { vxm } from '@/store';
import {
  GooglePlacesServiceStatus,
  IGoogleAutocompletePrediction,
  IGooglePlaceResult,
} from '@/services/google/google-types';
import { CreateErrorSnackbar } from '@/services/api.service';
import { isNumber } from '@/utils/string-utils';
import { dirtyFormClass } from '@/utils/validation-utils';
import { IAddressDisplayModel } from './common-models';
import { FormModalParams, FormModalSizeEnum } from './modal/form-modal-models';

@Component({
  components: {
    OtAutocomplete,
    OtTextField,
    OtSelect,
  },
})
export default class OtAddressField extends Vue {
  // * PROPS
  @Prop({ default: null }) private value!: IAutocompleteItem<IAddressDisplayModel> | null;
  @Prop({ type: String, default: 'Address' }) private label?: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;

  // * REFS
  @Ref('autocompleteFieldRef') private readonly autocompleteFieldRef!: OtAutocomplete;

  @Ref('manualEntryModalRef') private manualEntryModalRef!: IVForm;
  @Ref('manualEntryModalContentsRef') private manualEntryModalContentsRef!: HTMLDivElement;

  // * DATA
  private searchResults: IAutocompleteItem<IAddressDisplayModel>[] = [];
  private componentId = '';
  private postCodeRules: Array<(value: string) => string | boolean> = [
    (value: string) => !value || isOnlyNumbers(value) || 'Invalid postcode, postcodes can only contain numbers',
    (value: string) => !value || value.length === 4 || 'Invalid postcode, postcodes can only be 4 characters long',
  ];

  private statesList: string[] = AustralianStates.map(s => s.fullName);
  private manualEntryModel: IAddressDisplayModel = {
    placeId: null,
    addressLine1: '',
    addressLine2: null,
    suburb: '',
    postcode: '',
    state: '',
    country: 'Australia',
  };
  private canCloseManualEntry = false;
  private searchDebounceTimeout: number | null = null;
  private addressFieldIsLoading = false;

  // * COMPUTED

  private locationPrivate: string | null = null;
  private get location(): string | null {
    return this.locationPrivate;
    // this was an idea that did not work, pretend like the text was the label of the selected
    // return this.locationPrivate || this.value?.label || null;
  }
  private set location(val: string | null) {
    // console.log('ot-address-field -> set location -> val:  ', val);
    // make sure we are swapping first
    if (val !== this.locationPrivate) {
      // console.log('  ot-address-field -> set location -> it is different');
      this.locationPrivate = val;
      if (!val) {
        // console.log('    ot-address-field -> set location -> it is falsy');
        // this happens when they clear the input, or when they pick something from the list
        // for some reason, the @#$%ing autocomplete is determined to show us the FIRST item in results
        // and I (Dan) cannot work it out
        // So, if we have a selected item, nuke the rest
        // the selected item gets put back into the start of the list in the get of the autocomplete items
        this.searchResults = [];
      } else if (this.selectedItem?.label !== val) {
        // Don't run the search if an item from the list was selected
        this.runAutocompleteSearch();
      }
    }
  }

  private get selectedItem(): IAutocompleteItem<IAddressDisplayModel> | null {
    return this.value;
  }
  private set selectedItem(val: IAutocompleteItem<IAddressDisplayModel> | null) {
    // console.log('ot-address-field -> set selectedItem -> val:  ', { val, label: val?.label });
    if (val && val.data) {
      // console.log('  ot-address-field -> set selectedItem -> val and val.data truthy');
      if (val.data.placeId && vxm.google.sessionToken) {
        // console.log('    ot-address-field -> set selectedItem -> placeId and sesionToken truthy');
        // This emit is required since we aren't storing a private selected item value
        // and the initial value is used for the label and addressLine1 where possible.
        // That's because the getDetails api won't return an address number like 32a if it doesn't exist
        // but the getPlacePredictions will if that's what the user typed because it could be valid still.

        this.$emit('input', val);
        try {
          vxm.google.placesService?.getDetails(
            {
              placeId: val.data.placeId,
              sessionToken: vxm.google.sessionToken,
              fields: ['address_components'],
            },
            this.setAddressDetails,
          );
        } catch (error) {
          // console.error('    OtAddressField -> googleGetDetails -> error:  ', error);
        }
      } else {
        // console.log('    ot-address-field -> set selectedItem -> placeId or sessionToken not truthy');
        this.$emit('input', val);
      }
    } else {
      // console.log('  ot-address-field -> set selectedItem -> vall or val.data not truthy');
      this.$emit('input', null);
    }
  }

  private get autocompleteItemsList(): IAutocompleteItem<IAddressDisplayModel>[] {
    const list = [...this.searchResults];
    if (this.value && !list.some(x => x.label === this.value?.label)) {
      list.unshift(this.value);
    }
    return list;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  // * WATCHERS

  // @Watch('value')
  // private valueChanged(val: IAutocompleteItem<IAddressDisplayModel> | null) {
  //   if (val) {
  //     console.log('ot-address-field -> valueChanged', { label: val.label });
  //   } else {
  //     console.log('ot-address-field -> valueChanged to falsy', { val });
  //   }
  // }

  // * METHODS
  private async openManualEntry() {
    // nicked this from the create. Feels weird though
    if (this.value && this.value.data) {
      this.manualEntryModel = { ...this.value.data, country: this.value.data.country || 'Australia' };
    }

    const formModalParams = new FormModalParams({
      title: 'Manual Address Entry',
      formRef: this.manualEntryModalRef,
      cancelText: 'Cancel',
      confirmText: 'OK',
      size: FormModalSizeEnum.Regular,
    });
    const result = await vxm.modal.openFormModal(formModalParams);
    if (!result.ok) {
      return null;
    }
    // at this point, we know they have hit OK
    const item: IAutocompleteItem<IAddressDisplayModel> = {
      label: `${this.manualEntryModel.addressLine1},${
        this.manualEntryModel.addressLine2 ? ' ' + this.manualEntryModel.addressLine2 + ',' : ''
      } ${this.manualEntryModel.postcode}, ${this.manualEntryModel.suburb}, ${this.manualEntryModel.state}`,
      data: this.manualEntryModel,
    };
    this.selectedItem = item;
  }

  private setAddressDetails(placeResult: IGooglePlaceResult, status: GooglePlacesServiceStatus) {
    if (status !== 'OK' || !placeResult.address_components) {
      CreateErrorSnackbar('Unable to get address information. Please try again later or contact us');
      return;
    }
    let streetNumber = '';
    let streetName = '';
    let suburb = '';
    let postcode = '';
    let state = '';
    let country = '';
    for (const item of placeResult.address_components) {
      if (item.types.includes('street_number')) {
        streetNumber = item.long_name;
      } else if (item.types.includes('route')) {
        streetName = item.long_name;
      } else if (item.types.includes('locality')) {
        suburb = item.long_name;
      } else if (item.types.includes('administrative_area_level_1')) {
        state = item.short_name;
      } else if (item.types.includes('postal_code')) {
        postcode = item.long_name;
      } else if (item.types.includes('country')) {
        country = item.long_name;
      }
    }

    const addressItem: IAddressDisplayModel = {
      placeId: null,
      addressLine1: this.selectedItem?.data?.addressLine1 || `${streetNumber} ${streetName}`.trim(),
      addressLine2: null,
      suburb: suburb,
      postcode: postcode,
      state: state,
      country: country,
    };
    const autocompleteItem: IAutocompleteItem<IAddressDisplayModel> = {
      label:
        this.selectedItem?.label ||
        `${addressItem.addressLine1}, ${addressItem.suburb} ${addressItem.state}, ${country}`.trim(),
      data: addressItem,
    };
    this.$emit('input', autocompleteItem);
  }

  private filterPostcodePress(event: KeyboardEvent) {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    // Make sure the input key is a number
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
    // Only allow a max of 4 characters
    if (this.manualEntryModel.postcode.length >= 4) {
      event.preventDefault();
    }
  }

  private filterPostcodePaste(event: ClipboardEvent) {
    const data = event.clipboardData?.getData('text');
    if (data) {
      const textField = event.target as HTMLInputElement;
      const selectionRange = (textField.selectionEnd || 0) - (textField.selectionStart || 0);
      // The selection range check allows the user to highlight and replace some input on paste
      const newValueLength = this.manualEntryModel.postcode.length - selectionRange + data.length;
      // Only allow a max of 4 characters
      if (newValueLength > 4) {
        event.preventDefault();
      }
      // Only allow numbers
      if (data.match(/[^.0-9]/g)) {
        event.preventDefault();
      }
    }
  }

  private displaySuggestions(predictions: Array<IGoogleAutocompletePrediction>, status: GooglePlacesServiceStatus) {
    if (status !== 'OK') {
      this.searchResults = [];
      return;
    }
    this.searchResults = predictions.map(prediction => {
      return {
        label: prediction.description,
        data: {
          placeId: prediction.place_id,
          // If the search term starts with a number,
          // the first 2 terms should be the street number and the street name respectively.
          // We can set it to be empty safely if the first term isn't a number because that should be picked up in the getDetails call.
          // The get details call only seems to have trouble getting some street numbers. That's why we set it here if we have it
          addressLine1: isNumber(prediction.description[0])
            ? `${prediction.terms[0].value} ${prediction.terms[1].value}`.trim()
            : '',
          addressLine2: null,
          suburb: '',
          state: '',
          postcode: '',
          country: '',
        },
      };
    });
  }

  private autocompleteFilter() {
    // Since google is in charge of getting the items we don't need to filter it further.
    // And we aren't using the no-filter prop because that removes the text highlighting when there's a match, which we still want
    return true;
  }

  private autocompleteValueComparator(
    a: IAutocompleteItem<IAddressDisplayModel> | null,
    b: IAutocompleteItem<IAddressDisplayModel> | null,
  ): boolean {
    if (a && b) {
      return a.label === b.label;
    }
    return false;
  }

  private runAutocompleteSearch() {
    if (this.location && vxm.google.autocompleteService) {
      // Wait 500 ms before searching and reset the timer if it has not passed that threshold
      if (this.searchDebounceTimeout) {
        clearTimeout(this.searchDebounceTimeout);
        this.searchDebounceTimeout = null;
      }
      this.searchDebounceTimeout = setTimeout(async () => {
        this.addressFieldIsLoading = true;
        try {
          if (this.location) {
            vxm.google.autocompleteService?.getPlacePredictions(
              {
                input: this.location,
                types: ['address'],
                componentRestrictions: { country: 'au' },
                sessionToken: vxm.google.sessionToken || undefined,
              },
              this.displaySuggestions,
            );
          }
          this.addressFieldIsLoading = false;
        } catch (error) {
          console.error('OtAddressField -> googleAutocomplete -> error:  ', error);
          this.addressFieldIsLoading = false;
        } finally {
          this.searchDebounceTimeout = null;
        }
      }, 500);
    }
  }

  // * LIFECYCLE
  private created() {
    this.componentId = `e-${uuid()}`;
  }
}
