


















































































import { Component, Ref, Vue } from 'vue-property-decorator';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';

@Component({
  components: {
    OtInviteUserDialog,
    OtButton,
    OtTextField,
    OtCheckbox,
  },
})
export default class OtInviteUserDialogWrapper extends Vue {
  // * PROPS
  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;
  // * REFS

  // * DATA
  private isReinvite = false;
  private inviteToOrganisation = true;
  private organisationGid = '54EAC053-D709-47B2-9A6D-142B49F42DF6';
  private invitedUserGid = '';

  private project1Gid = '';
  private project2Gid = '';
  private contract1Gid = '';
  private contract2Gid = '';

  private projectUser1Gid = '';
  private projectUser2Gid = '';
  private contractUser1Gid = '';
  private contractUser2Gid = '';

  // * COMPUTED
  private get params() {
    const params = new InviteUserDialogParams({
      organisationGid: this.organisationGid,
      isReinvite: this.isReinvite,
      inviteToOrganisation: this.inviteToOrganisation,
      invitedUserGid: this.invitedUserGid,
    });
    if (this.isReinvite) {
      if (this.projectUser1Gid) {
        params.userProjects.push(this.projectUser1Gid);
      }
      if (this.projectUser2Gid) {
        params.userProjects.push(this.projectUser2Gid);
      }
      if (this.contract1Gid) {
        params.userContracts.push(this.contractUser1Gid);
      }
      if (this.contract2Gid) {
        params.userContracts.push(this.contractUser2Gid);
      }
    } else {
      if (this.project1Gid) {
        params.projects.push(this.project1Gid);
      }
      if (this.project2Gid) {
        params.projects.push(this.project2Gid);
      }
      if (this.contract1Gid) {
        params.contracts.push(this.contract1Gid);
      }
      if (this.contract2Gid) {
        params.contracts.push(this.contract2Gid);
      }
    }
    return params;
  }

  // * WATCHERS

  // * METHODS
  private async openInviteUserDialog() {
    console.log('openInviteUserDialog', this.inviteUserDialogRef);
    const params = this.params;
    console.log('openInviteUserDialog.params', params);
    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }
  }
  // * LIFECYCLE
}
