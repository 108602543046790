// Data Driven Layout Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2373091365/Data+Driven+Layout+Object

import { DataDrivenLayoutModel as ApiDataDrivenLayoutModel } from '@/services/generated/api';
import { OtDataDrivenDependsOn } from './data-driven-depends-on';
import { OtDataDrivenDialogButtons } from './data-driven-dialog-buttons';
import { LayoutTypeEnum, parsedOtLayoutTypeEnum } from './data-driven-enums';
import { OtDataDrivenSection } from './data-driven-section';
import { v4 as uuid } from 'uuid';

export interface IDataDrivenLayout {
  key: string;
  type: LayoutTypeEnum;
  stepName: string | null;
  title: string | null;
  description: string | null;
  orderIndex: number;
  dependsOn: OtDataDrivenDependsOn | null;
  sections: OtDataDrivenSection[];
  dialogButtonOverrides: OtDataDrivenDialogButtons | null;
}

export class OtDataDrivenLayout implements IDataDrivenLayout {
  public key!: string;
  public type!: LayoutTypeEnum;
  public stepName!: string | null;
  public title!: string | null;
  public description!: string | null;
  public orderIndex!: number;
  public dependsOn!: OtDataDrivenDependsOn | null;
  public sections!: OtDataDrivenSection[];
  public dialogButtonOverrides!: OtDataDrivenDialogButtons | null;

  constructor(value: IDataDrivenLayout) {
    Object.assign(this, value);
  }

  public get sectionsOrdered() {
    const sectionsOrdered = [...this.sections];
    sectionsOrdered.sort((a, b) => a.orderIndex - b.orderIndex);
    return sectionsOrdered;
  }

  public static createFromApiResponse(model: ApiDataDrivenLayoutModel) {
    return new OtDataDrivenLayout({
      key: model.key,
      type: parsedOtLayoutTypeEnum[model.type],
      stepName: model.stepName || null,
      title: model.title || null,
      description: model.description || null,
      orderIndex: model.orderIndex,
      dependsOn: model.dependsOn ? OtDataDrivenDependsOn.createFromApiResponse(model.dependsOn) : null,
      sections: model.sections.map(s => OtDataDrivenSection.createFromApiResponse(s)),
      dialogButtonOverrides: model.dialogButtonsOverride
        ? OtDataDrivenDialogButtons.createFromApiResponse(model.dialogButtonsOverride)
        : null,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenLayout>) {
    return new OtDataDrivenLayout({
      key: json.key || uuid(),
      type: json.type || LayoutTypeEnum.Dialog,
      stepName: json.stepName || null,
      title: json.title || null,
      description: json.description || null,
      orderIndex: json.orderIndex || 0,
      dependsOn: json.dependsOn ? OtDataDrivenDependsOn.createFromJson(json.dependsOn) : null,
      sections: json.sections?.map(s => OtDataDrivenSection.createFromJson(s)) || [],
      dialogButtonOverrides: json.dialogButtonOverrides
        ? OtDataDrivenDialogButtons.createFromJson(json.dialogButtonOverrides)
        : null,
    });
  }
}
