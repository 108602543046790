export const numericKeyboardEventKeys: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
export const numericAndNegativeSignKeyboardEventKeys: string[] = numericKeyboardEventKeys.concat('-');

export const numericAndDecimalKeyboardEventKeys: string[] = numericKeyboardEventKeys.concat('.');
export const numericAndDecimalAndNegativeSignKeyboardEventKeys: string[] = numericAndDecimalKeyboardEventKeys.concat(
  '-',
);

export const numericPunctuationEventKeys: string[] = numericKeyboardEventKeys.concat(['.', ',']);
export const currencyKeyboardEventKeys: string[] = numericKeyboardEventKeys.concat(['.', '-', ',']);
