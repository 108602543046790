export function getUrl(path: string) {
  const url = window.location.href;
  const arr = url.split('/');
  const result = `${arr[0]}//${arr[2]}`;

  return `${result}/${path}`;
}

export function getCurrentSubdomain() {
  const bits = window.location.hostname.split('.');
  if (bits.length > 2) {
    return bits[0];
  }
  return '';
}

// this came along for the ride from another project. It was unused. Leaving it here just in case
// export function getTennantId() {
//     return window.location.host.split(":").shift().split(".").shift();
// }

// this also came along for the ride from another project. I've made it at least compile in modern TS
// leaving it here just in case. The implementation SUCKS
// export function getParameter(parameterName: string) {
//     let result = null;
//     let tmp = [];
//     const items = location.search.substr(1).split("&");
//     for (const item of items) {
//         tmp = item.split("=");
//         if (tmp[0] === parameterName) {
//             result = decodeURIComponent(tmp[1]);
//         }
//     }
//     return result;
// }

export function isSubdomain() {
  return window.location.host.split('.').shift();
}

export function getUrlNoSubdomain() {
  return `${window.location.protocol}//${window.location.origin.replace(/.+?\./, '')}`;
}

export function getRootUrl() {
  return `${window.location.protocol}//${window.location.host}`;
}
