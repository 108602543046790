







































import { Component, Vue } from 'vue-property-decorator';
import OtSelect from '@/components/global/ot-select.vue';

@Component({
  components: {
    OtSelect,
  },
})
export default class OtSelectWrapper extends Vue {
  // * PROPS

  // * DATA
  private items = ['Field text 1', 'Field text 2', 'Field text 3', 'Field text 4', 'Field text 5'];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
