




























































































































































import OtClaimDetailsForm from '@/areas/claims/ot-claim-details-form.vue';
import OtClaimResultForm, {
  OtClaimResultsFormResponse,
  SelectedImplementationOptionEnum,
} from '@/areas/claims/ot-claim-results-form.vue';
import OtDataDrivenClaimForm from '@/areas/claims/ot-data-driven-claim-form.vue';
import OtWizardArchetype from '@/components/global/archetypes/ot-wizard-archetype.vue';
import { DangerModalParams, FormModalParams, FormModalSizeEnum } from '@/components/global/modal/form-modal-models';
import OtButton from '@/components/global/ot-button.vue';
import OtDueDateLabel from '@/components/global/ot-due-date-label.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtClaimRecommendationOverruleDialog, {
  ClaimRecommendationOverruleDialogParams,
  OverruleStateEnum,
} from '@/components/specific-modals/ot-claim-recommendation-overrule-dialog.vue';
import OtClaimSuspensionDialog from '@/components/specific-modals/ot-claim-suspension-dialog.vue';
import OtEmailContractorDialog, {
  EmailContractorDialogParams,
} from '@/components/specific-modals/ot-email-contractor-dialog.vue';
import OtStartRfiOutboundDialog from '@/components/specific-modals/ot-start-rfi-outbound-dialog.vue';
import ApiResponse from '@/services/api-models';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import {
  ChildDetailsModel,
  PostFinaliseSegmentInstanceRequestModel,
  PostSegmentInstanceRecommendationRequestModel,
  PostUpdateSegmentInstanceStatusRequestModel,
} from '@/services/generated/api';
import { vxm } from '@/store';
import { OtDataEntryRequirement } from '@/types/data-entry-requirement-enums';
import {
  OtClosedSegmentStatusSet,
  OtSegmentResolution,
  OtSegmentStatus,
  OtTrafficLightColour,
  segmentResolutionToString,
} from '@/types/status-enums';
import { IDropdownAction, IWizardContentComponent } from '@/types/wizard-types';
import { ZonelessDate } from '@/types/zoneless-date';
import { durationString } from '@/utils/duration-utils';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormsExist } from '@/utils/validation-utils';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { LayoutTypeEnum, SegmentProcessTypeEnum } from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { OtWorkflowUpdate } from '@/wf-components/models/workflow-update';
import { getSelectedKeysForDependsOn, passesDependsOn } from '@/wf-components/utils/depends-on-utils';
import WfSummaryPanel from '@/wf-components/wf-summary-panel.vue';
import { capitalize } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { ROUTE_CONTRACT_WORKFLOW_LIST } from '../projects/contracts/contract-routes';
import {
  AvailableActionObject,
  BasicSegment,
  ClaimDetailsFormExternalErrorObject,
  ClaimFinaliseSegmentInstance,
  ClaimResultsObject,
  ClaimWizardObject,
  parsedApiSegmentStatuses,
  SegmentInstanceDetails,
  SegmentInstanceDetailsPost,
} from './claims-models';
import { ROUTE_WORKFLOW, ROUTE_WORKFLOW_ADD } from './claims-routes';
interface IWizardStep {
  key: string;
  name: string;
}

@Component({
  components: {
    OtWizardArchetype,
    OtButton,
    OtClaimDetailsForm,
    OtClaimResultForm,
    OtLoadingSpinner,
    OtClaimRecommendationOverruleDialog,
    OtClaimSuspensionDialog,
    OtEmailContractorDialog,
    OtDataDrivenClaimForm,
    WfSummaryPanel,
    OtStartRfiOutboundDialog,
    OtDueDateLabel,
    OtTextField,
  },
})
export default class OtCreateClaimWizard extends Vue {
  // * PROPS

  // * REFS
  @Ref('currentStepComponentRef') private readonly currentStepComponentRef!: IWizardContentComponent;
  @Ref('claimRecommendationOverruleDialogRef')
  private readonly claimRecommendationOverruleDialogRef!: OtClaimRecommendationOverruleDialog;
  @Ref('claimSuspensionDialogRef') private readonly claimSuspensionDialogRef!: OtClaimSuspensionDialog;
  @Ref('emailContractorDialogRef') private readonly emailContractorDialogRef!: OtEmailContractorDialog;
  @Ref('startRfiOutboundDialogRef') private readonly startRfiOutboundDialogRef!: OtStartRfiOutboundDialog;
  @Ref('adjustedDateModalRef') private adjustedDateModalRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private baseWizardSteps: IWizardStep[] = [{ key: 'WorkflowDetails', name: 'Workflow Details' }];
  private saving = false;
  private isLoading = false;
  private isComponentLoading = false;
  private stepComponentProps: Record<string, unknown> = {};
  private stepComponentListeners: Record<string, unknown> = {};

  private wizardObject = ClaimWizardObject.createEmpty();
  private claimResultDetails = ClaimResultsObject.createEmpty();

  private cancelClaimActionKey = uuid();
  private saveAndContinueLaterActionKey = uuid();
  private saveBtnTextOverride: string | null = '';
  private saveBtnEnabledOverride: boolean | null = null;

  // ----- Data Driven Step Data ----- //
  private dataDrivenSteps: IWizardStep[] = [];
  private dataDrivenSegmentDefinition: OtDataDrivenDefinition | null = null;
  private dataDrivenSegmentInstance = OtDataDrivenInstance.createEmpty();
  private allInstances: OtDataDrivenInstances | null = null;
  private dataDrivenParentSegmentInstances = OtDataDrivenInstances.createEmpty();
  private evaluation = OtClientEvaluation.createEmpty();
  private dueDate: ZonelessDate | null = null;
  private dueDateTrafficLightColour: OtTrafficLightColour = OtTrafficLightColour.None;

  private parentClaimDetails: SegmentInstanceDetails | null = null;

  private lastKnownContractRowVersionFromAnUpdate: string | null = null;
  private claimDetailsExternalErrorMessages: ClaimDetailsFormExternalErrorObject | null = null;

  // * COMPUTED

  private get contractTimezone() {
    return this.wizardObject.originalClaimDetails.contract.timezone;
  }
  private get contractRowVersion() {
    // if we've stored an update, that'll be the one. Otherwise, the originally loaded contract
    return this.lastKnownContractRowVersionFromAnUpdate ?? this.wizardObject.originalClaimDetails.contract.rowVersion;
  }
  private get responseTimeAlertsEnabled() {
    return this.wizardObject.originalClaimDetails.responseTimeAlertsEnabled;
  }

  private activeStepIndexPrivate = 0;
  private get activeStepIndex() {
    return this.activeStepIndexPrivate;
  }
  private set activeStepIndex(val: number) {
    this.activeStepIndexPrivate = val;
    this.setCurrentStepComponentProps();
  }

  private get claimGid() {
    return (this.$route.query.claimGid as string) || null;
  }

  private get hasAlreadyBeenSaved() {
    return !!this.claimGid;
  }

  private get activeStep() {
    return this.effectiveWizardSteps[this.activeStepIndex];
  }

  private get activeStepName() {
    return this.activeStep.name;
  }

  private get effectiveWizardSteps() {
    let steps = this.baseWizardSteps;

    steps = steps.concat(this.dataDrivenSteps);

    if (this.hasAlreadyBeenSaved) {
      // Make sure this always is the last page
      steps.push({ key: 'WorkflowResults', name: 'Workflow Results' });
    }
    return steps;
  }

  private get effectiveWizardStepNames() {
    return this.effectiveWizardSteps.map(s => s.name);
  }

  private get wizardTitle() {
    if (this.wizardObject.claimDetails.projectName) {
      return 'Add Workflow for';
    }
    return 'Add Workflow';
  }

  private get instanceTitle() {
    if (this.wizardObject.claimDetails.projectName) {
      return this.wizardObject.claimDetails.projectName;
    }
    return '';
  }

  private get currentStepComponent() {
    if (this.activeStepIndex > this.effectiveWizardSteps.length - 1 || this.activeStepIndex < 0) {
      return null;
    }

    switch (this.activeStepIndex) {
      case 0:
        return OtClaimDetailsForm;
      case this.effectiveWizardSteps.length - 1:
        return OtClaimResultForm;
      default:
        return OtDataDrivenClaimForm;
    }
  }

  private get showBackButton() {
    return this.activeStepIndex !== 0;
  }

  private get isOnFirstWizardStep() {
    return this.activeStepIndex === 0;
  }

  private get isOnLastWizardStep() {
    return this.activeStepIndex === this.effectiveWizardSteps.length - 1;
  }

  private get showSecondaryActions() {
    return !!this.secondarySaveActions.length;
  }

  private get secondarySaveActions(): IDropdownAction[] {
    const saveAndContinueLaterAction = {
      key: this.saveAndContinueLaterActionKey,
      onClick: this.saveAndContinueLater,
      label: 'Save and continue later',
    };

    if (this.currentStepComponent !== OtClaimResultForm) {
      return [saveAndContinueLaterAction];
    } else {
      return [];
    }
  }

  private get dangerActions(): IDropdownAction[] {
    const cancelClaimAction = {
      key: this.cancelClaimActionKey,
      onClick: this.cancelClaim,
      label: 'Cancel this workflow',
    };
    return [cancelClaimAction];
  }

  private get saveBtnText() {
    if (this.saveBtnTextOverride) {
      return this.saveBtnTextOverride;
    }
    if (this.effectiveWizardSteps.length === 1 || !this.isOnLastWizardStep) {
      return 'Save & Continue';
    }
    return 'Finish Workflow & Prepare Email';
  }
  private get saveBtnEnabled() {
    if (this.saveBtnEnabledOverride !== null) {
      return this.saveBtnEnabledOverride;
    }
    return true;
  }

  private get saveBtnSubtext() {
    if (!this.isOnLastWizardStep) {
      return `To ${this.effectiveWizardSteps[this.activeStepIndex + 1].name}`;
    }
    return '';
  }

  private get showSummaryPanel() {
    return (
      !!this.wizardObject.originalClaimDetails.parentClaim?.gid && this.currentStepComponent === OtDataDrivenClaimForm
    );
  }

  // * WATCHERS

  // * METHODS
  private async setCurrentStepComponentProps() {
    // Keep in mind that the stepComponentProps will be reactive and
    // update the props on the component if you assign an object to it.
    // Modifying properties directly ( Eg. this.stepComponentProps.isEditMode = false)
    // doesn't cause the component to update the props
    this.stepComponentProps = {};
    this.stepComponentListeners = {};

    this.saveBtnTextOverride = null;
    this.saveBtnEnabledOverride = null;

    switch (this.activeStepIndex) {
      case 0:
        this.stepComponentProps = {
          isEditMode: this.hasAlreadyBeenSaved,
          errors: this.claimDetailsExternalErrorMessages,
        };
        this.stepComponentListeners = {
          'update:contractDetailsPost': this.updateDetails,
        };
        break;
      case this.effectiveWizardSteps.length - 1:
        this.isComponentLoading = true;

        await this.loadClaimRecommendation();
        this.stepComponentProps = {
          resultDetails: this.claimResultDetails,
        };
        this.stepComponentListeners = {
          setConfirmButtonText: this.overrideConfirmButtonText,
          setConfirmButtonEnabled: this.overrideConfirmButtonEnabled,
        };

        this.isComponentLoading = false;
        break;
      default:
        this.stepComponentProps = {
          definition: this.dataDrivenSegmentDefinition,
          layoutKey: this.effectiveWizardSteps[this.activeStepIndex].key,
          instance: this.dataDrivenSegmentInstance,
          instances: this.allInstances,
          parentInstances: this.dataDrivenParentSegmentInstances,
          evaluation: this.evaluation,
        };
        this.stepComponentListeners = {
          'update:instance': this.updateDataDrivenInstance,
        };
        break;
    }
  }

  private async loadClaimRecommendation() {
    const request = new PostSegmentInstanceRecommendationRequestModel({ contractRowVersion: this.contractRowVersion });
    const apiResult = await executeApi(
      () =>
        this.api
          .segmentInstances()
          .postSegmentInstanceRecommendation(this.wizardObject.originalClaimDetails.gid, undefined, request),
      'Calculate Recommendation',
    );

    if (apiResult.success && apiResult.data) {
      this.claimResultDetails = ClaimResultsObject.createFromApiResponse(apiResult.data);
    }
  }

  private updateDetails(model: SegmentInstanceDetailsPost) {
    this.wizardObject.claimDetails = model;
  }

  private overrideConfirmButtonText(text: string) {
    this.saveBtnTextOverride = text;
  }
  private overrideConfirmButtonEnabled(enabled: boolean) {
    console.log('ot-create-claim-wizard -> overrideConfirmButtonEnabled -> enabled:  ', enabled);
    this.saveBtnEnabledOverride = enabled;
  }

  private async backClicked() {
    // OTMAN-398 removes the save from here
    // we need to prompt if dirty though

    // make sure that the "there is a dirty input" has had a render cycle to get applied to the form
    // otherwise, if you type into an input and click back, it might not be dirty
    // quick testing by Dan suggests that this is not actually the case, but he is A Paranoid Android
    // and would rather we wait a beat, just to be sure to be sure
    await this.$nextTick();

    const hasDirty = dirtyFormsExist();

    if (hasDirty) {
      const result = await vxm.modal.openUnsavedChangesModal();
      if (result.ok === false) {
        return;
      }
    }

    this.activeStepIndex--;
  }

  private navigateToContractWorkflowList() {
    // ROUTE_CONTRACT_WORKFLOW_LIST
    // projectGid
    // contractGid
    this.$router.push({
      name: ROUTE_CONTRACT_WORKFLOW_LIST,
      params: {
        projectGid: this.wizardObject.originalClaimDetails.project.gid,
        contractGid: this.wizardObject.originalClaimDetails.contract.gid,
      },
    });
  }

  private navigateToWorkflowList() {
    this.$router.push({ name: ROUTE_WORKFLOW });
  }

  private tryNavigateContractWorkflowListFallbackToWorkflowList() {
    const projectGid = this.wizardObject.originalClaimDetails.project.gid;
    const contractGid = this.wizardObject.originalClaimDetails.contract.gid;
    if (projectGid && contractGid) {
      this.navigateToContractWorkflowList();
    } else {
      this.navigateToWorkflowList();
    }
  }

  private cancelWizard() {
    this.tryNavigateContractWorkflowListFallbackToWorkflowList();
  }

  private async cancelClaim() {
    this.saving = true;
    const params = new DangerModalParams({
      title: 'Are you sure you want to cancel this workflow?',
      confirmText: 'Cancel workflow',
      cancelText: 'Return to wizard',
      message: `If you cancel this workflow it will be marked as ‘Cancelled’ and won’t be considered an active workflow. Any unsaved changes won’t be saved.`,
    });
    const result = await vxm.modal.openDangerModal(params);
    if (result.ok) {
      const apiResposne = await this.setClaimStatus(OtSegmentStatus.Cancelled);
      if (apiResposne?.success) {
        this.currentStepComponentRef.setFormToCleanState();
        this.navigateToContractWorkflowList();
      }
    }
    this.saving = false;
  }

  private async setClaimStatus(status: OtSegmentStatus) {
    const requestModel = new PostUpdateSegmentInstanceStatusRequestModel({
      status: parsedApiSegmentStatuses[status],
      contractRowVersion: this.contractRowVersion,
    });
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 409,
        message: `Unable to set workflow status to ${capitalize(
          status.toLowerCase(),
        )} - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    const apiResponse = await executeApi(
      () =>
        this.api
          .segmentInstances()
          .postUpdateSegmentInstanceStatus(this.wizardObject.claimDetails.segmentGid, undefined, requestModel),
      `Set workflow status to ${status}`,
      errorOverride,
    );
    if (apiResponse && apiResponse.success) {
      this.lastKnownContractRowVersionFromAnUpdate = apiResponse.data?.rowVersion ?? null;
    }
    return apiResponse;
  }

  private async handlePrimarySaveClicked() {
    this.saving = true;
    const isValid = this.validateCurrentStep();

    if (isValid) {
      const apiResponse = await this.saveCurrentStep();
      if (apiResponse.success) {
        this.currentStepComponentRef.setFormToCleanState();
        this.goToNextNextStep();
      }
    }
    this.saving = false;
  }

  private validateCurrentStep(): boolean {
    if (!this.currentStepComponentRef.validate()) {
      const snackbarError = new SnackbarItem({
        type: SnackbarTypeEnum.Error,
        message: `There is a validation error on the form that prevented us from saving the workflow, please check the form for errors`,
      });
      vxm.snackbar.addToSnackbarQueue(snackbarError);
      return false;
    }
    return true;
  }

  private async saveAndContinueLater() {
    this.saving = true;

    // Only validate if the current page is on a data driven screen
    const shouldValidate = !this.dataDrivenSteps.map(s => s.key).includes(this.activeStep.key);

    // The only time the if statement comes out to false
    // is when the current page fails validation.
    // The first boolean check is to avoid running the validation if it's not necessary
    if (!shouldValidate || this.validateCurrentStep()) {
      const apiResponse = await this.saveCurrentStep({ validate: shouldValidate });
      if (apiResponse.success) {
        this.currentStepComponentRef.setFormToCleanState();
        this.navigateToContractWorkflowList();
      }
    }

    this.saving = false;
  }

  private goToNextNextStep() {
    if (!this.isOnLastWizardStep) {
      this.activeStepIndex++;
    }
  }

  private async saveCurrentStep(params?: { validate: boolean }): Promise<ApiResponse<unknown>> {
    if (this.isOnFirstWizardStep) {
      const claimDetailsPost = (await this.currentStepComponentRef.submit(
        params?.validate ?? true,
      )) as SegmentInstanceDetailsPost | null;
      if (claimDetailsPost) {
        if (this.hasAlreadyBeenSaved) {
          const apiResponse = await this.updateClaimDetails(claimDetailsPost);
          await this.getParentDetails();
          return apiResponse;
        } else {
          const apiResponse = await this.insertNewClaim(claimDetailsPost);
          await this.getParentDetails();
          return apiResponse;
        }
      }
    } else if (this.isOnLastWizardStep) {
      // This logic follows the claim results process flow
      // https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2355167399/F12200+-+Claim+Results#Process-Flow
      // and also the Accept Button (6) logic in
      // https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2683994113/F12200R09+-+Claim+Results
      const claimResults = (await this.currentStepComponentRef.submit(
        params?.validate ?? true,
      )) as OtClaimResultsFormResponse | null;
      if (claimResults) {
        // this probably really only belongs in the non available action side, but it can't hurt to put it here
        this.claimResultDetails.selectedDuration = claimResults.selectedDuration;
        this.claimResultDetails.selectedCalendar = claimResults.selectedCalendar;
        // they've clicked the big 'ol finish button, which has a lot of different meanings
        // first and easiest meaning is if we have a selected available action
        const action = claimResults.selectedAvailableAction;
        if (action) {
          // easy peasy run of if elseifs
          /*
           */

          if (action.outcome === OtSegmentStatus.Suspended) {
            // no need for a selected action guid here, this changes status, not finalizes
            await this.openSuspendDialog();
          } else if (action.showRfiOutboundPrompt) {
            // this one internally calls finalize if they choose yes
            await this.openRFIOutboundPromptDialog({ selectedAvailableAction: action });
          } else if (action.sendEmail) {
            // this one internally calls finalize if they choose yes
            await this.openEmailDialog({ useRecommended: true, selectedAvailableActionKey: action.key });
          } else {
            // just call finalize
            const model = this.getBaseClaimFinaliseModel({ selectedAvailableActionKey: action.key });
            await this.finalizeSegmentInstance(model, action);
          }
        } else {
          // existing logic from the Before Available Actions times
          switch (claimResults.selectedImplementation) {
            case SelectedImplementationOptionEnum.ImplementRecommendation:
              await this.openEmailDialog({ useRecommended: true, selectedAvailableActionKey: null });
              break;
            case SelectedImplementationOptionEnum.ImplementOverruleAndSetClaimAsInvalid:
            case SelectedImplementationOptionEnum.ImplementCustomOverruleRecommendation:
              await this.openEmailDialog({ useRecommended: false, selectedAvailableActionKey: null });
              break;
            case SelectedImplementationOptionEnum.DontImplementAndSuspend:
              await this.openSuspendDialog();
              break;
            case SelectedImplementationOptionEnum.OverruleAndSetAsInvalid:
              await this.openOverruleDialog(OverruleStateEnum.ValidToInvalid);
              break;
            case SelectedImplementationOptionEnum.OverruleWithDifferentExtensionTime:
              await this.openOverruleDialog(OverruleStateEnum.ValidWithDifferentTime);
              break;
            case SelectedImplementationOptionEnum.OverruleAndSetAsValid:
              await this.openOverruleDialog(OverruleStateEnum.InvalidToValid);
              break;
            case SelectedImplementationOptionEnum.RevertOverruleAndSetToOneTrackRecommendation:
              this.claimResultDetails.selectedOutcome = null;
              this.claimResultDetails.selectedDuration = null;
              break;
            case SelectedImplementationOptionEnum.NoSaveAsDraft:
              // There is no draft option yet. and there isn't much going on in this page to save it anywawy
              // So we'll just boot them back to the workflow screen
              this.navigateToContractWorkflowList();
              break;
            case SelectedImplementationOptionEnum.YesSaveAsInvalid:
              if (this.claimResultDetails.selectedOutcome === OtSegmentResolution.Rejected) {
                await this.openEmailDialog({ useRecommended: false, selectedAvailableActionKey: null });
              } else {
                await this.openOverruleDialog(OverruleStateEnum.IndeterminateToInvalid);
                this.overrideConfirmButtonText('Finish Workflow & Prepare Email');
              }
              break;
            case SelectedImplementationOptionEnum.YesSaveAsValid:
              if (this.claimResultDetails.selectedOutcome === OtSegmentResolution.Accepted) {
                await this.openEmailDialog({ useRecommended: false, selectedAvailableActionKey: null });
              } else {
                await this.openOverruleDialog(OverruleStateEnum.IndeterminateToValid);
                this.overrideConfirmButtonText('Finish Workflow & Prepare Email');
              }
              break;
          }
        }
      }
    } else {
      if (this.claimGid) {
        const instance = (await this.currentStepComponentRef.submit(
          params?.validate ?? true,
        )) as OtDataDrivenInstance | null;
        if (instance) {
          const apiResult = await this.updateSegmentInstanceResponse(this.claimGid, instance);
          return apiResult;
        }
      } else {
        console.error(
          'ot-create-claim-wizard -> saveCurrentStep -> claim Gid not found. Cannot save segment instance response',
        );
      }
    }
    return ApiResponse.CreateEmptyFailure(-1);
  }

  private async openOverruleDialog(overruleState: OverruleStateEnum) {
    const params = new ClaimRecommendationOverruleDialogParams(
      this.wizardObject.originalClaimDetails.workflowSegment.name,
      overruleState,
      this.claimResultDetails.initialDuration,
    );
    const dialogResult = await this.claimRecommendationOverruleDialogRef.open(params);
    if (dialogResult.doOverride) {
      this.claimResultDetails.overruleReason = dialogResult.overrideReason;
      switch (overruleState) {
        case OverruleStateEnum.ValidToInvalid:
          this.claimResultDetails.selectedOutcome = OtSegmentResolution.Rejected;
          break;
        case OverruleStateEnum.InvalidToValid:
          this.claimResultDetails.selectedOutcome = OtSegmentResolution.Accepted;
          break;
        case OverruleStateEnum.ValidWithDifferentTime:
          this.claimResultDetails.selectedOutcome = OtSegmentResolution.ValidWithDifferentTime;
          break;
        case OverruleStateEnum.IndeterminateToInvalid:
          this.claimResultDetails.selectedOutcome = OtSegmentResolution.Rejected;
          break;
        case OverruleStateEnum.IndeterminateToValid:
          this.claimResultDetails.selectedOutcome = OtSegmentResolution.Accepted;
          break;
      }
    }
  }

  private async openSuspendDialog() {
    const dialogResult = await this.claimSuspensionDialogRef.open();
    this.claimResultDetails.overruleReason = dialogResult.suspendReason;
    if (dialogResult.doSuspend) {
      const requestModel = new PostUpdateSegmentInstanceStatusRequestModel({
        status: parsedApiSegmentStatuses[OtSegmentStatus.Suspended],
        reason: dialogResult.suspendReason,
        contractRowVersion: this.contractRowVersion,
      });
      const errorOverride: IApiErrorOverride[] = [
        {
          status: 409,
          message: `Unable to set workflow status to Suspended - contract has been modified by someone else. Please refresh the page and try again.`,
        },
      ];

      const apiResult = await executeApi(
        () =>
          this.api
            .segmentInstances()
            .postUpdateSegmentInstanceStatus(this.wizardObject.claimDetails.segmentGid, undefined, requestModel),
        'Suspend Workflow',
        errorOverride,
      );
      if (apiResult.success) {
        this.lastKnownContractRowVersionFromAnUpdate = apiResult.data?.rowVersion ?? null;
        this.navigateToContractWorkflowList();
      }
    }
  }

  private async openEmailDialog(params: { useRecommended: boolean; selectedAvailableActionKey: string | null }) {
    // not sure this is the right spot to be putting this preamble together
    let emailPreamble = '';
    if (!params.useRecommended) {
      const outcomeFrom = this.claimResultDetails.recommendedOutcome;
      const outcomeTo = this.claimResultDetails.selectedOutcome ?? this.claimResultDetails.recommendedOutcome;
      const outcomeHasChanged = outcomeFrom !== outcomeTo;

      const durationFrom = this.claimResultDetails.initialDuration;
      const durationTo = this.claimResultDetails.selectedDuration ?? this.claimResultDetails.initialDuration;
      const calendarFrom = this.claimResultDetails.initialCalendar;
      const calendarTo = this.claimResultDetails.selectedCalendar ?? this.claimResultDetails.initialCalendar;
      const fromDurationString = durationString(durationFrom, calendarFrom);
      const toDurationString = durationString(durationTo, calendarTo);
      const grantHasChanged = durationFrom !== durationTo || calendarFrom !== calendarTo;
      const reason = this.claimResultDetails.overruleReason;
      const isClaim =
        this.wizardObject.originalClaimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.Claim;

      console.log('claim-wizard -> openEmailDialog -> stuff:  ', {
        outcomeFrom,
        outcomeTo,
        durationFrom,
        durationTo,
        calendarFrom,
        calendarTo,
      });

      emailPreamble = `
<p><strong>You have chosen not to use the OneTrack recommendation</strong></p>
<p>The email below <strong>will</strong> need editing. It includes the original OneTrack recommendation details</p>
<ul>`;
      if (outcomeHasChanged) {
        emailPreamble += `
  <li>Outcome: from "${segmentResolutionToString(outcomeFrom)}" to "${segmentResolutionToString(outcomeTo)}"</li>`;
      }
      if (isClaim && grantHasChanged) {
        emailPreamble += `
  <li>Grant: from ${fromDurationString} to ${toDurationString} </li>`;
      }
      emailPreamble += `
</ul>
<p>The reason you gave for this override was:</p>
<p>${reason}</p>
<hr />`;
    }

    const recipientEmails: string[] = this.claimResultDetails.emailDetails?.recipients || [];
    const dialogParams = new EmailContractorDialogParams({
      recipientEmails: recipientEmails,
      emailSubject: this.claimResultDetails.emailDetails?.subject,
      emailContent: emailPreamble + this.claimResultDetails.emailMessage,
      isOverride: !params.useRecommended,
    });
    const dialogResult = await this.emailContractorDialogRef.open(dialogParams);
    if (dialogResult.doSendEmail) {
      const requestModel = this.getBaseClaimFinaliseModel({
        selectedAvailableActionKey: params.selectedAvailableActionKey,
      });
      requestModel.useRecommended = params.useRecommended;
      requestModel.emailRecipients = dialogResult.recipientEmails;
      requestModel.emailSubject = dialogResult.emailSubject;
      requestModel.emailContent = dialogResult.emailContent;
      await this.finalizeSegmentInstance(requestModel, null);
    }
  }

  private async openRFIOutboundPromptDialog(params: { selectedAvailableAction: AvailableActionObject }) {
    const result = await this.startRfiOutboundDialogRef.open();
    if (result.doCreateRfiOutbound) {
      const requestModel = this.getBaseClaimFinaliseModel({
        selectedAvailableActionKey: params.selectedAvailableAction?.key,
      });
      await this.finalizeSegmentInstance(requestModel, params.selectedAvailableAction);
    }
  }

  private getBaseClaimFinaliseModel(params: { selectedAvailableActionKey: string | null }) {
    const approvedState = this.claimResultDetails.selectedOutcome || this.claimResultDetails.recommendedOutcome;
    const requestModel = new ClaimFinaliseSegmentInstance({
      availableActionKey: params.selectedAvailableActionKey,
      gid: this.wizardObject.originalClaimDetails.gid,
      useRecommended: true,
      overruleOutcome: approvedState,
      overruleGrant: this.claimResultDetails.selectedDuration,
      overruleReason: this.claimResultDetails.overruleReason,
      overruleSelectedCalendarType: this.claimResultDetails.selectedCalendar,
      emailRecipients: [],
      emailSubject: '',
      emailContent: '',
      contractRowVersion: this.contractRowVersion,
    });
    return requestModel;
  }

  private async finalizeSegmentInstance(
    requestModel: ClaimFinaliseSegmentInstance,
    selectedAvailableAction: AvailableActionObject | null | undefined,
  ) {
    console.log('ot-create-claim-wizard -> finalizeSegmentInstance', selectedAvailableAction);
    if (selectedAvailableAction?.typeOfChildSegmentInstanceToCreate) {
      return this.finalizeSegmentInstanceWithChildCreate(requestModel, selectedAvailableAction);
    } else {
      return this.finalizeSegmentInstanceWithoutChildCreate(requestModel);
    }
  }

  private childReferenceExternalError: string[] = [];
  private childReferencePrivate: string | null = null;
  private childAction: string | null = null;
  private childType: string | null = null;
  private get childReference() {
    return this.childReferencePrivate;
  }
  private set childReference(val: string | null) {
    this.childReferenceExternalError = [];
    this.childReferencePrivate = val;
  }

  private async finalizeSegmentInstanceWithChildCreate(
    requestModel: ClaimFinaliseSegmentInstance,
    selectedAvailableAction: AvailableActionObject,
  ) {
    // look up the actual segment we'll create
    // Eventually this should be in the available action. It seemed easier to do this than change the manifests
    const segmentsApiResult = await executeApi(
      () => this.api.contracts().getContractSegments(this.wizardObject.originalClaimDetails.contract.gid),
      'Load Contract Workflows',
    );
    if (!segmentsApiResult.status && !segmentsApiResult.data?.segments) {
      // nothing we can do
      console.error(
        'ot-create-claim-wizard -> finalizeSegmentInstanceWithChildCreate -> error getting segments. Bailing',
      );
      return false;
    }
    const thisSegmentRaw = segmentsApiResult.data?.segments?.find(
      x => x.manifestSegmentGid === selectedAvailableAction?.typeOfChildSegmentInstanceToCreate,
    );
    if (!thisSegmentRaw) {
      return false;
    }

    const thisSegment = BasicSegment.createFromApiResponse(thisSegmentRaw);
    this.childType = thisSegment.name;
    this.childAction = selectedAvailableAction.acceptButtonText;

    // handler the 422 reference error and get it onto the screen
    const handleError = (error: ApiResponse<unknown>): boolean => {
      if (error.status !== 422) {
        return false;
      }

      const referenceErrors = error.errors.filter(
        x => x.source.localeCompare('ChildDetails.Reference', undefined, { sensitivity: 'base' }) === 0,
      );

      const hasOtherErrors = error.errors.length !== referenceErrors.length;
      if (hasOtherErrors) {
        // there's stuff other than reference in here. Let the natural error handling handle this
        return false;
      }

      // there's only errors in here, yay
      this.childReferenceExternalError = referenceErrors.map(x => x.message);
      return true;
    };

    const formModalParams = new FormModalParams({
      title: 'New Workflow Reference',
      formRef: this.adjustedDateModalRef,
      cancelText: 'Cancel',
      confirmText: selectedAvailableAction.acceptButtonText,
      size: FormModalSizeEnum.Regular,
      onBeforeConfirmClose: async () => {
        // make the API call
        this.childReferenceExternalError = [];
        const finaliseModel = ClaimFinaliseSegmentInstance.createRequestModel(requestModel);
        finaliseModel.childDetails = new ChildDetailsModel({ reference: this.childReference ?? '' });
        const apiCallWorked = await this.tryFinalize(requestModel, finaliseModel, handleError);
        // if it works, hooray, close the form
        // if not, poop, the errors will have been caught in the onError handler and are now on screen
        return apiCallWorked;
      },
    });

    const result = await vxm.modal.openFormModal(formModalParams);
    return result.ok;
  }

  private finalizeSegmentInstanceWithoutChildCreate(requestModel: ClaimFinaliseSegmentInstance) {
    const finaliseModel = ClaimFinaliseSegmentInstance.createRequestModel(requestModel);
    return this.tryFinalize(requestModel, finaliseModel, undefined);
  }

  private async tryFinalize(
    requestModel: ClaimFinaliseSegmentInstance,
    finaliseModel: PostFinaliseSegmentInstanceRequestModel,
    handleError?: (result: ApiResponse<unknown>) => boolean,
  ) {
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 409,
        message: `Unable to finalize workflow - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    const apiResult = await executeApi(
      () => this.api.segmentInstances().postFinaliseSegmentInstance(requestModel.gid, undefined, finaliseModel),
      'Save Workflow',
      errorOverride,
      { attemptSilentLoginOn401AndRetry: true, handleError },
    );

    if (apiResult.success) {
      this.lastKnownContractRowVersionFromAnUpdate = apiResult.data?.contractRowVersion ?? null;

      // If a different gid comes back to what we are looking at, redirect to that one, skipping the create screen
      // ROUTE_WORKFLOW_ADD with step=1 and claimGid
      if (apiResult.data?.segmentInstanceGid && apiResult.data.segmentInstanceGid !== this.claimGid) {
        await this.$router.push({
          name: ROUTE_WORKFLOW_ADD,
          query: { step: '1', claimGid: apiResult.data.segmentInstanceGid },
        });
        // we don't actually react to the route changing, so we need to reload all the things and start again
        this.doLoad();
      } else {
        this.navigateToContractWorkflowList();
      }
      return true;
    }
    return false;
  }

  private async updateSegmentInstanceResponse(
    segmentGid: string,
    instance: OtDataDrivenInstance,
  ): Promise<ApiResponse<unknown>> {
    const apiResponse = await executeApi(
      () =>
        this.api
          .segmentInstances()
          .postUpdateSegmentInstanceResponse(
            segmentGid,
            instance.runGid,
            undefined,
            instance.createUpdateSegmentInstanceResponseModel(),
          ),
      'Save Segment Instance Response',
    );
    if (apiResponse.success && apiResponse.data) {
      const workflwoUpdate = OtWorkflowUpdate.createFromApiResponse(apiResponse.data);
      this.dueDate = workflwoUpdate.dueDate;
      this.dueDateTrafficLightColour = workflwoUpdate.trafficLightColour;
      this.evaluation = workflwoUpdate.evaluation;
      this.lastKnownContractRowVersionFromAnUpdate = workflwoUpdate.contractRowVersion;
      // we might have freshly hidden steps, hidden due to evaluation keys from the server. Prune the answers
      this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: true });
    }
    return apiResponse;
  }

  private handleCreateOrUpdateErrors(error: ApiResponse<unknown>): boolean {
    const referenceErrors = error.errors.filter(
      x => x.source.localeCompare('Reference', undefined, { sensitivity: 'base' }) === 0,
    );

    const hasOtherErrors = error.errors.length !== referenceErrors.length;
    if (hasOtherErrors) {
      // there's stuff other than reference in here. Let the natural error handling handle this
      return false;
    }

    // there's only errors in here, yay
    const newErrors = new ClaimDetailsFormExternalErrorObject();
    newErrors.reference = referenceErrors.map(x => x.message);
    this.claimDetailsExternalErrorMessages = newErrors;
    this.setCurrentStepComponentProps();
    return true;
  }

  private async updateClaimDetails(claim: SegmentInstanceDetailsPost) {
    const requestModel = SegmentInstanceDetailsPost.createUpdateRequestModel(
      claim,
      // I thought we had to pass a timezone here. We do not. But, if we ever need it, here's where to get it from
      // this.wizardObject.originalClaimDetails.contract.timezone,
    );
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 409,
        message: `Unable to update workflow - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    // reset the last lot of external errors
    this.claimDetailsExternalErrorMessages = null;

    const apiUpdateResponse = await executeApi(
      () => this.api.segmentInstances().postUpdateSegmentInstanceDetails(claim.segmentGid, undefined, requestModel),
      `Update Workflow Details ${claim.segmentGid}`,
      errorOverride,
      { attemptSilentLoginOn401AndRetry: true, handleError: this.handleCreateOrUpdateErrors },
    );

    if (apiUpdateResponse.success && apiUpdateResponse.data) {
      this.lastKnownContractRowVersionFromAnUpdate = apiUpdateResponse.data.contractRowVersion;

      const update = OtWorkflowUpdate.createFromApiResponse(apiUpdateResponse.data);
      this.dueDate = update.dueDate;
      this.dueDateTrafficLightColour = update.trafficLightColour;
      this.evaluation = update.evaluation;

      // this feels a bit big hammer to call load claim details again. We have everything we need in memory
      // we should be able to patch originalClaimDetails from what is in claimDetails
      // await this.loadClaimDetails();
      // it's a bit dodgy, this is really none of our business, but it'll go away when documents get redone anyway
      const source = this.wizardObject.claimDetails;
      const dest = this.wizardObject.originalClaimDetails;
      dest.reference = source.reference;
      // there's no need to do this if we're not allowed to set document details on creation
      // "will it hurt?" I hear you ask
      // Yes. It will. source somehow somewhere gets these blanked out
      // and I don't want to dig into why
      // this is a bit of a bandaid fix, all of this will go away when we get to documents anyway
      if (
        this.wizardObject.originalClaimDetails.workflowSegment.config?.documentRequiredOnCreation !==
        OtDataEntryRequirement.Forbidden
      ) {
        dest.documentName = source.documentName;
        dest.documentCreationDate = source.documentCreationDate;
        dest.documentDeemedReceivedUtc = source.documentDeemedReceivedUtc;
        dest.documentReceivalDescription = source.documentReceivalDescription;
        dest.documentReceivalType = source.documentReceivalType;
        dest.documentReceivedUtc = source.documentReceivedUtc;
      }

      // not sure if we need to prune the answers or not. We've just made the thing, there probably won't be an answers?
      this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: true });
    }

    return apiUpdateResponse;
  }

  // We aren't calling this API from the summary component, even though that's what the ENG says,
  // because it appears on multiple pages but the data won't change.
  // So it doesn't need to be called every time the page loads
  // It only needs to be called after the parent claim has been set
  private async getParentDetails() {
    const parentClaimGid = this.wizardObject.originalClaimDetails.parentClaim?.gid;
    if (parentClaimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceDetails(parentClaimGid),
        'Load Parent Workflow Details',
      );

      if (result.success && result.data) {
        this.parentClaimDetails = SegmentInstanceDetails.createFromApiResponse(result.data);
      }
    }
  }

  private async loadClaimDetails() {
    const claimGid = this.claimGid;
    if (claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceDetails(claimGid),
        'Load Workflow Details',
      );

      if (result.success && result.data) {
        this.wizardObject.originalClaimDetails = SegmentInstanceDetails.createFromApiResponse(result.data);

        if (OtClosedSegmentStatusSet.has(this.wizardObject.originalClaimDetails.status)) {
          const snackbarError = new SnackbarItem({
            type: SnackbarTypeEnum.Error,
            message: `Unable to find incomplete workflow`,
          });
          vxm.snackbar.addToSnackbarQueue(snackbarError);
          this.tryNavigateContractWorkflowListFallbackToWorkflowList();
          return;
        }

        this.wizardObject.claimDetails = this.wizardObject.originalClaimDetails.toClaimDetailsPost();
        this.dueDate = this.wizardObject.originalClaimDetails.dueDate;
        this.dueDateTrafficLightColour = this.wizardObject.originalClaimDetails.trafficLightColour;
        vxm.breadcrumbs.setClaimName({ claimName: this.wizardObject.originalClaimDetails.name });
      }
    }
  }

  private async loadSegmentDefinition() {
    const claimGid = this.claimGid;
    if (claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentDefinition(claimGid),
        'Load Segment Definition',
      );
      if (result.success && result.data) {
        // we don't need this here. We only need it so we can get the phase names, which is in the Layouts usage
        const definition = OtDataDrivenDefinition.createFromApiResponse(result.data.definition);
        this.dataDrivenSegmentDefinition = definition;

        // no need to get rid of responses from hidden steps, we've just done a load
        // and everything should be as it should be?
        // Unless there is an evaluation key of "ItIsChristmAsDay", and a special step shows up or goes away for christmas day, but let's not do that
        this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: false });
      }
    }
  }

  private async loadSegmentResponse() {
    const claimGid = this.claimGid;
    if (claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceResponse(claimGid),
        'Load Segment Responses',
      );
      if (result.success && result.data) {
        const instances = OtDataDrivenInstances.createFromApiResponse(result.data);
        const unfinishedInstance = instances.instances.find(x => x.finished === null);
        if (!unfinishedInstance) {
          // this is bad. There has to be an unfinished run, otherwise we're not supposed to be here
          const snackbarError = new SnackbarItem({
            type: SnackbarTypeEnum.Error,
            message: `Unable to find unfinished run`,
          });
          vxm.snackbar.addToSnackbarQueue(snackbarError);
          this.tryNavigateContractWorkflowListFallbackToWorkflowList();
          return;
        }

        this.allInstances = instances;
        this.dataDrivenSegmentInstance = unfinishedInstance;
      }
    }
  }

  private async loadSegmentEvaluation() {
    const claimGid = this.claimGid;
    if (claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceEvaluation(claimGid),
        'Load Segment Evaluation',
      );
      if (result.success && result.data) {
        const evaluations = result.data.map(x => OtClientEvaluation.createFromApiResponse(x));
        const evaluationForInstance = evaluations.find(x => x.runGid === this.dataDrivenSegmentInstance.runGid);
        if (!evaluationForInstance) {
          // this is bad. Can't find the evaluation for the unfinished run
          // this is bad. There has to be an unfinished run, otherwise we're not supposed to be here
          const snackbarError = new SnackbarItem({
            type: SnackbarTypeEnum.Error,
            message: `Unable to find evaluation for unfinished run`,
          });
          vxm.snackbar.addToSnackbarQueue(snackbarError);
          this.tryNavigateContractWorkflowListFallbackToWorkflowList();
          return;
        }
        this.evaluation = evaluationForInstance;
      }
    }
  }

  private updateDataDrivenInstance(instance: OtDataDrivenInstance) {
    this.dataDrivenSegmentInstance = instance;
    // prime time to prune answers from hidden layouts. Whatever we just did might have caused a layout to get hidden, so its responses can get in the bin
    this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: true });
  }

  private async insertNewClaim(claim: SegmentInstanceDetailsPost): Promise<ApiResponse<unknown>> {
    const claimGid = uuid();
    const requestModel = SegmentInstanceDetailsPost.createInsertRequestModel(
      claim,
      claimGid,
      // I thought we had to pass a timezone here. We do not. But, if we ever need it, here's where to get it from
      // this.wizardObject.originalClaimDetails.contract.timezone,
    );

    const apiResponse = await executeApi(
      () => this.api.segmentInstances().postInsertSegmentInstance(undefined, requestModel),
      'Insert New Workflow',
      undefined,
      { attemptSilentLoginOn401AndRetry: true, handleError: this.handleCreateOrUpdateErrors },
    );

    if (apiResponse.success) {
      if (apiResponse.data) {
        const workflowUpdate = OtWorkflowUpdate.createFromApiResponse(apiResponse.data);
        this.dueDate = workflowUpdate.dueDate;
        this.dueDateTrafficLightColour = workflowUpdate.trafficLightColour;
        this.evaluation = workflowUpdate.evaluation;
      }
      this.lastKnownContractRowVersionFromAnUpdate = apiResponse.data?.contractRowVersion ?? null;
      await new Promise<void>((resolve, reject) => {
        // Add the claimGid to the address bar
        this.$router.replace(
          { name: ROUTE_WORKFLOW_ADD, query: { claimGid: claimGid } },
          () => {
            // Execute this code on complete
            Promise.all([this.loadClaimDetails(), this.loadSegmentDefinition(), this.loadSegmentResponse()]).then(
              () => {
                this.loadSegmentEvaluation().then(() => {
                  this.defaultToParentClaimValues().then(() => {
                    // absolutely no idea if we need to do this or not
                    // but we do it after defaulting in the created
                    // so let's do it here too
                    this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: true });
                    resolve();
                  });
                });
              },
            );
          },
          error => {
            // Execute this code on failure
            reject(error);
          },
        );
      });
    }

    return apiResponse;
  }

  private async defaultToParentClaimValues() {
    const parentClaimGid = this.wizardObject.originalClaimDetails.parentClaim?.gid;
    // if we don't have a parent, bail
    if (!parentClaimGid) {
      return;
    }

    // load parent claim responses
    const parentResult = await executeApi(
      () => this.api.segmentInstances().getSegmentInstanceResponse(parentClaimGid),
      'Load Parent Segment Responses',
    );

    if (parentResult.success && parentResult.data) {
      const parentInstances = OtDataDrivenInstances.createFromApiResponse(parentResult.data);
      this.dataDrivenParentSegmentInstances = parentInstances;

      // if we're not supposed to do this, bail
      if (!this.wizardObject.originalClaimDetails.workflowSegment.config?.copyResponsesFromParent) {
        return;
      }

      // Insert those parent claim respones in for any questions that exist for this claim
      // get keys for all questions
      const questionKeys = this.dataDrivenSegmentDefinition?.layouts.flatMap(layout =>
        layout.sections.flatMap(layout => layout.questions.flatMap(question => question.key)),
      );
      // yeah, not a fan of sorting, then reversing, but it makes for a pretty line of code doesn't it?
      const sortedInstances = [...parentInstances.instances].sort(OtDataDrivenInstance.compareByCreated).reverse();
      for (const instance of sortedInstances) {
        const relevantResponses = instance.responses.filter(
          parentResponse =>
            // find responses that are the same from the previous claim
            questionKeys?.some(questionKey => parentResponse.questionKey === questionKey) &&
            // and don't currently have a response
            !this.dataDrivenSegmentInstance.responses.some(
              response => parentResponse.questionKey === response.questionKey,
            ),
        );
        // add reponse keys from previous claim if they don't currently have a value
        this.dataDrivenSegmentInstance.responses.push(...relevantResponses);
      }
    }
  }

  private rebuildDataDrivenSteps(props: { removeHiddenPageLayoutResponsesFromInstance: boolean }) {
    const pageLayouts = this.dataDrivenSegmentDefinition?.layouts.filter(l => l.type === LayoutTypeEnum.Page) || [];

    const selectedKeys = getSelectedKeysForDependsOn(
      this.dataDrivenSegmentInstance,
      this.dataDrivenSegmentDefinition,
      this.evaluation,
    );

    const steps: IWizardStep[] =
      pageLayouts
        .filter(layout => passesDependsOn(layout.dependsOn, selectedKeys))
        .map(layout => ({ key: layout.key, name: layout.stepName || '' })) || [];

    this.dataDrivenSteps = steps;

    if (props.removeHiddenPageLayoutResponsesFromInstance) {
      this.removeHiddenPageLayoutResponsesFromInstance();
    }
  }

  private removeHiddenPageLayoutResponsesFromInstance() {
    const visibleStepKeysLookup = new Set(this.dataDrivenSteps.map(x => x.key));
    const questionKeysFromNonVisiblePageLayoutsLookup = new Set(
      this.dataDrivenSegmentDefinition?.layouts
        .filter(layout => layout.type === LayoutTypeEnum.Page && !visibleStepKeysLookup.has(layout.key))
        .flatMap(layout => layout.sections.flatMap(layout => layout.questions.flatMap(question => question.key))) || [],
    );

    const newResponses = this.dataDrivenSegmentInstance.responses.filter(
      r => !questionKeysFromNonVisiblePageLayoutsLookup.has(r.questionKey),
    );

    if (newResponses.length !== this.dataDrivenSegmentInstance.responses.length) {
      // we must have removed some. Replace with the new array
      // not just blindly replacing the array due to reactivity
      // or, due to Dan being worried reactivity will cause an endless loop. He has not tried. He is just paranoid.
      this.dataDrivenSegmentInstance.responses = newResponses;
    }
  }

  // private get routeFullPath(): string | null {
  //   return this.$route.fullPath || null;
  // }

  // * WATCHERS

  // this was a dumb idea - we do a navigate on the first create. Which breaks everything
  // @Watch('routeFullPath')
  // private async routeFullPathChanged() {
  //   console.log('routeFullPathChanged');
  //   this.doLoad();
  // }

  private async doLoad() {
    this.isLoading = true;

    await Promise.all([this.loadClaimDetails(), this.loadSegmentDefinition(), this.loadSegmentResponse()]);
    // yes, this has to be done after the response
    // because we want to find the matching evaluation
    await this.loadSegmentEvaluation();

    await this.defaultToParentClaimValues();

    // freshly loaded, should be no need to prune responses, we won't be freshly hiding any layouts. Or, not unless there's a time sensitive key? Let's not do that.
    this.rebuildDataDrivenSteps({ removeHiddenPageLayoutResponsesFromInstance: false });

    const stepRaw = this.$route.query.step;
    if (stepRaw && typeof stepRaw === 'string') {
      const stepParsed = parseInt(stepRaw, 10);
      if (!isNaN(stepParsed)) {
        if (stepParsed < this.effectiveWizardSteps.length && stepParsed >= 0) {
          this.activeStepIndex = stepParsed;
          // normally we'd load the parent details when they click the Next button on step 1
          // but, if we're going beyond the first step, have to load them here
          // why don't we load them all the time? It's a bit of a heavyweight call to get segment instance details
          // and we don't actually need them on the first step
          await this.getParentDetails();
        } else {
          console.warn(
            'ot-create-claim-wizard -> created -> step query string parameter is outside range of active steps. Ignoring step',
            {
              stepParsed,
              stepRaw,
              steps: this.effectiveWizardSteps,
            },
          );
          this.activeStepIndex = 0;
        }
      } else {
        console.warn(
          'ot-create-claim-wizard -> created -> step query string parameter is not a number. Ignoring step',
          {
            stepRaw,
          },
        );
        this.activeStepIndex = 0;
      }
    } else if (stepRaw) {
      console.warn('ot-create-claim-wizard -> created -> step query string parameter is not a string. Ignoring step', {
        stepRaw,
      });
      this.activeStepIndex = 0;
    }

    this.setCurrentStepComponentProps();

    this.isLoading = false;
  }

  // * LIFECYCLE
  private async created() {
    this.doLoad();
  }
}
