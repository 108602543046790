




















import { Component, Ref, Vue } from 'vue-property-decorator';
import styles from '@/styles/variables.scss';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtUnsavedChangesModal from '@/components/specific-modals/ot-unsaved-changes-modal.vue';
import OtSnackbar from '@/components/global/snackbar/ot-snackbar.vue';
import OtNavigation from '@/components/global/ot-navigation.vue';
import { vxm } from '@/store';
import { dirtyFormsExist } from './utils/validation-utils';
import {
  IGoogleAutocompleteService,
  IGoogleAutocompleteSessionToken,
  IGooglePlacesService,
} from '@/services/google/google-types';
import OtDangerModal from './components/global/modal/ot-danger-modal.vue';

Component.registerHooks(['metaInfo']);

@Component({
  components: {
    OtFormModal,
    OtDangerModal,
    OtUnsavedChangesModal,
    OtSnackbar,
    OtNavigation,
  },
})
export default class App extends Vue {
  // * PROPS

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('dangerModalRef') private readonly dangerModalRef!: OtDangerModal;
  @Ref('unsavedChangesModalRef') private readonly unsavedChangesModalRef!: OtFormModal;
  @Ref('googlePlacesDiv') private readonly googlePlacesDiv!: HTMLDivElement;

  // * DATA
  private readonly styles = styles;

  // * COMPUTED
  private get currentUserName() {
    const userProfile = vxm.userProfile.userProfile;
    if (userProfile) {
      return `${userProfile.fullName}`;
    }
    return '';
  }

  private get hideMainNavigation() {
    return this.$route.meta.hideMainNavigation === true;
  }

  // * WATCHERS

  // * METHODS
  private MapsInit() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const autocompleteService = new (window as any).google.maps.places.AutocompleteService() as IGoogleAutocompleteService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const placesService = new (window as any).google.maps.places.PlacesService(
      this.googlePlacesDiv,
    ) as IGooglePlacesService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken() as IGoogleAutocompleteSessionToken;
    vxm.google.setAutocompleteService(autocompleteService);
    vxm.google.setPlacesService(placesService);
    vxm.google.setSessionToken(sessionToken);
  }

  // * LIFECYCLE
  private async mounted() {
    vxm.modal.setFormModalRef(this.formModalRef);
    vxm.modal.setDangerModal(this.dangerModalRef);
    vxm.modal.setUnsavedChangesModalRef(this.unsavedChangesModalRef);

    window.addEventListener('beforeunload', event => {
      // This is what pops up the in browser warning when you try to navigate away from the page manually
      if (dirtyFormsExist()) {
        event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    });
  }

  private beforeMount() {
    vxm.app.setInitialHistoryCount(window.history.length);
  }

  private metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit(),
        },
      ],
    };
  }
}
