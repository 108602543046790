





























































































import { Component, Ref, Vue } from 'vue-property-decorator';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import { IInputIcon } from '@/components/global/common-models';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';

interface IFieldProps {
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
  error: boolean;
}

interface IComponent {
  isCheckbox: boolean;
  content?: string;
  props?: IFieldProps;
}

@Component({
  components: {
    OtCheckbox,
    OtFieldArchetype,
  },
})
export default class OtCheckboxWrapper extends Vue {
  // REPRO STUFF
  @Ref('theCheckbox') private readonly theCheckbox!: OtCheckbox;
  private get overruleTo() {
    return 'bodged';
  }
  private get overruleCheckboxTitle() {
    return `Yes, I want to overrule the recommendation and set workflow as ${this.overruleTo}`;
  }
  private isCheckboxSelectedPrivate = false;
  private get isCheckboxSelected() {
    return this.isCheckboxSelectedPrivate;
  }
  private set isCheckboxSelected(val: boolean) {
    this.isCheckboxSelectedPrivate = val;
    // this is SUCH a messy hack
    // the insides of the checkbox are ridiculously complex. Like, why do we need SO MUCH code for a checkbox?
    // the basic problem is there's a sync'd error state property, for reasons I don't fully understand, and it never gets set to false
    // there's a comment in the code saying "if you're having problems, call resetManualErrorState"
    // So, I am. I wish there was a better solution. Throw away the custom components and work with native vuetify would be THE solution I reckon
    this.theCheckbox.resetManualErrorState();
  }

  private checkboxRules = [
    (value: boolean) => {
      return value || 'Please confirm your intention to overrule this workflow';
    },
  ];

  // * PROPS

  // * DATA
  private icon: IInputIcon = { icon: 'mdi-cursor-default-click', action: this.log, iconColor: 'black' };
  private componentsToDisplay: IComponent[] = [
    { isCheckbox: false, content: '' },
    { isCheckbox: false, content: 'Unchecked' },
    { isCheckbox: false, content: 'Indeterminate' },
    { isCheckbox: false, content: 'Checked' },
    { isCheckbox: false, content: 'Default' },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: false, disabled: false, error: false },
    },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: true, disabled: false, error: false },
    },
    {
      isCheckbox: true,
      props: { checked: true, indeterminate: false, disabled: false, error: false },
    },
    { isCheckbox: false, content: 'Disabled' },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: false, disabled: true, error: false },
    },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: true, disabled: true, error: false },
    },
    {
      isCheckbox: true,
      props: { checked: true, indeterminate: false, disabled: true, error: false },
    },
    { isCheckbox: false, content: 'Error' },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: false, disabled: false, error: true },
    },
    {
      isCheckbox: true,
      props: { checked: false, indeterminate: true, disabled: false, error: true },
    },
    {
      isCheckbox: true,
      props: { checked: true, indeterminate: false, disabled: false, error: true },
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private log() {
    console.log('Icon clicked!!');
  }

  // * LIFECYCLE
}
