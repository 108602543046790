



















































































































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import {
  PostSetContractUserStatusUserStatusModel,
  PostSetOrganisationUserStatusUserStatusModel,
  PostSetProjectUserStatusUserStatusModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { RawLocation } from 'vue-router/types/router';
import { OrganisationUser } from './models/organisation-user-models';
import { AccessType } from '../../models/shared-models';
import { ROUTE_SETTINGS_ORG_USERS } from './settings-routes';

import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { parsedApiOrgUserStatusEnums } from '@/models/user-models';
import { OtUserAction } from '@/types/action-enums';
import { UserProfile } from '../profile/models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';

interface IMenuOption {
  label: string;
  onClick: (user: OrganisationUser) => void;
}

@Component({
  components: {
    OtInviteUserDialog,
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtUserStatusTags,
  },
})
export default class OtSettingsUsersIndex extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private organisationUsers!: OrganisationUser[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private emDash = '—';
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;
  private userProfile: UserProfile = UserProfile.createEmpty();

  private tableColumns: IExtendedColumnData<OrganisationUser>[] = [
    {
      index: 0,
      label: 'Name',
      key: 'name',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: OrganisationUser.compareByName,
    },
    {
      index: 1,
      label: 'Email',
      key: 'email',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUser.compareByEmail,
    },
    {
      index: 2,
      label: 'Job Title',
      key: 'jobTitle',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUser.compareByJobTitle,
    },
    {
      index: 3,
      label: 'Org.',
      key: 'org',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 4,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 5,
      label: 'Contract',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 6,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private activeColumn = this.tableColumns[0];

  private openUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Inactive', OtUserStatus.Inactive),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
    new CheckboxGroupItem('Rejected Invite', OtUserStatus.RejectedInvitation),
  ];

  private selectedUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
  ];

  private typeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [
    new CheckboxGroupItem('Projects', AccessType.Projects),
    new CheckboxGroupItem('Contracts', AccessType.Contracts),
    new CheckboxGroupItem('Organisation', AccessType.Organisations),
  ];

  private selectedTypeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [];

  // * COMPUTED

  private get sortedBodyData() {
    const sortedOrgUsers = sortTableData(this.tableColumns, this.activeColumn, this.organisationUsers);

    return sortedOrgUsers;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedUserStatusFilters.map(s => s.value);
    const parsedAccessTypes = this.selectedTypeOfAccessFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        OrganisationUser.filterByUserStatus(parsedStatuses, row) &&
        OrganisationUser.filterByTypeOfAccess(parsedAccessTypes, row) &&
        OrganisationUser.filterByName(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  private get orgId() {
    return this.$route.params.orgId || '';
  }

  // * WATCHERS

  // * METHODS

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private getOrgUserDetailsRoute(user: OrganisationUser): RawLocation {
    return {
      name: ROUTE_SETTINGS_ORG_USERS,
      params: { orgId: this.orgId, userGid: user.gid },
    };
  }

  private handleDeactivateOrg = (user: OrganisationUser): IMenuOption => {
    return {
      label: 'Deactivate for Organisation',
      onClick: () => {
        this.setOrgUserToDeactivated(user);
      },
    };
  };

  private handleDeactivateAllProjects = (user: OrganisationUser): IMenuOption => {
    return {
      label: 'Deactivate for All Projects',
      onClick: () => {
        this.setOrgUserToDeactivatedForAllProjects(user);
      },
    };
  };

  private handleDeactivateAllContracts = (user: OrganisationUser): IMenuOption => {
    return {
      label: 'Deactivate for All Contracts',
      onClick: () => {
        this.setOrgUserToDeactivatedForAllContracts(user);
      },
    };
  };

  private handleReinviteUser = (user: OrganisationUser): IMenuOption => {
    return {
      label: 'Reinvite User',
      onClick: () => {
        this.reInviteUser(user);
      },
    };
  };

  private async setOrgUserToDeactivated(user: OrganisationUser) {
    this.rowLoadingStates[user.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to change the status of user ${user.email} to deactivated, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetOrganisationUserStatusUserStatusModel[] = user.organisations
      ?.filter(o => o.availableActions.some(a => a === OtUserAction.Deactivate))
      .map(p => {
        return new PostSetOrganisationUserStatusUserStatusModel({
          gid: p.gid,
          status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
        });
      });

    if (body.length === 0) {
      this.rowLoadingStates[user.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.organisationUser().postSetOrganisationUserStatus(body),
      `Set Organisation User ${user.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUsers', () => {
        this.rowLoadingStates[user.gid] = false;
      });
    } else {
      this.rowLoadingStates[user.gid] = false;
    }
  }

  private async setOrgUserToDeactivatedForAllProjects(user: OrganisationUser) {
    this.rowLoadingStates[user.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to change the status of user ${user.email} to deactivated, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetProjectUserStatusUserStatusModel[] = user.projects
      ?.filter(p => p.status === OtUserStatus.Active || p.status === OtUserStatus.Invited)
      .map(p => {
        return new PostSetProjectUserStatusUserStatusModel({
          gid: p.gid,
          status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
        });
      });

    if (body.length === 0) {
      this.rowLoadingStates[user.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.projectUser().postSetProjectUserStatus(body),
      `Deactivating organisation user ${user.gid} in all projects`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUsers', () => {
        this.rowLoadingStates[user.gid] = false;
      });
    } else {
      this.rowLoadingStates[user.gid] = false;
    }
  }

  private async setOrgUserToDeactivatedForAllContracts(user: OrganisationUser) {
    this.rowLoadingStates[user.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to change the status of user ${user.email} to deactivated, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetContractUserStatusUserStatusModel[] = user.contracts
      ?.filter(c => c.availableActions.some(a => a === OtUserAction.Deactivate))
      .map(p => {
        return new PostSetContractUserStatusUserStatusModel({
          gid: p.gid,
          status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
        });
      });

    if (body.length === 0) {
      this.rowLoadingStates[user.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.contractUser().postSetContractUserStatus(body),
      `Deactivating organisation user ${user.gid} in all contracts”`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUsers', () => {
        this.rowLoadingStates[user.gid] = false;
      });
    } else {
      this.rowLoadingStates[user.gid] = false;
    }
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async reInviteUser(user: OrganisationUser) {
    this.rowLoadingStates[user.gid] = true;

    const reInviteToOrg = user.organisations.find(x => x.availableActions.includes(OtUserAction.Reinvite));
    const reInviteToProjects = user.projects.filter(x => x.availableActions.includes(OtUserAction.Reinvite));
    const reinviteToContracts = user.contracts.filter(x => x.availableActions.includes(OtUserAction.Reinvite));

    const params = new InviteUserDialogParams({
      isReinvite: true,
      organisationGid: this.orgId,
      inviteToOrganisation: Boolean(reInviteToOrg),
      invitedUserGid: user.gid,
      userContracts: reinviteToContracts.map(x => x.gid),
      userProjects: reInviteToProjects.map(x => x.gid),
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }

    this.rowLoadingStates[user.gid] = false;
  }

  private getOrgUserOptionMenuItems(user: OrganisationUser): IMenuOption[] {
    const results: IMenuOption[] = [];

    // some here vs map. Some stops as soon as it finds a true value, map does the whole thing regardless.
    // might save some ticks.
    const orgUserDeactivateAction = user.organisations?.some(o =>
      o.availableActions?.includes(OtUserAction.Deactivate),
    );
    if (orgUserDeactivateAction) {
      results.push(this.handleDeactivateOrg(user));
    }

    const projectDeactivateAction = user.projects?.some(p => p.availableActions?.includes(OtUserAction.Deactivate));
    if (projectDeactivateAction) {
      results.push(this.handleDeactivateAllProjects(user));
    }

    const contractDeactivateAction = user.contracts?.some(c => c.availableActions?.includes(OtUserAction.Deactivate));
    if (contractDeactivateAction) {
      results.push(this.handleDeactivateAllContracts(user));
    }

    const reinviteAction =
      user.organisations?.some(o => o.availableActions?.includes(OtUserAction.Reinvite)) ||
      user.projects?.some(p => p.availableActions?.includes(OtUserAction.Reinvite)) ||
      user.contracts?.some(c => c.availableActions?.includes(OtUserAction.Reinvite));
    if (reinviteAction) {
      results.push(this.handleReinviteUser(user));
    }

    return results;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.organisationUsers.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  // * LIFECYCLE
  private created() {
    this.setRowLoadingStates();
  }
}
