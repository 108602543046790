














































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTextField from '@/components/global/ot-text-field.vue';

@Component({
  components: {
    OtButton,
    OtTextField,
  },
})
export default class OtButtonWrapper extends Vue {
  // * PROPS
  @Prop() private prop: unknown;

  // * DATA
  private btnSizes = Object.values(OtBtnSize);
  private btnStyles = Object.values(OtBtnStyle);
  private btnTypes = Object.values(OtBtnType);

  private areLoading = false;
  private areDisabled = false;

  private label = 'Action';
  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
