











import { Component, Prop, Vue } from 'vue-property-decorator';
import styles from '@/styles/variables.scss';

@Component({})
export default class OtLoadingSpinner extends Vue {
  // * PROPS
  @Prop({ default: '96' }) private size!: string;

  // * REFS

  // * DATA
  private styles = styles;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
