import { DataDrivenPhaseModel as ApiDataDrivenPhaseModel } from '@/services/generated/api';

export interface IDataDrivenPhase {
  key: string;
  label: string;
}

export class OtDataDrivenPhase implements IDataDrivenPhase {
  public key!: string;
  public label!: string;

  constructor(value: IDataDrivenPhase) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenPhaseModel) {
    return new OtDataDrivenPhase({
      key: model.key,
      label: model.label,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenPhase>) {
    return new OtDataDrivenPhase({
      key: json.key || '',
      label: json.label || '',
    });
  }
}
