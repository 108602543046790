
























import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { IUserStatus, UserStatus } from '@/models/user-models';
import { OtUserStatus } from '@/types/status-enums';
import { v4 as uuid } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtUserStatusTags,
  },
})
export default class OtTagWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private emptyArray: IUserStatus[] = [];
  private singleArray: IUserStatus[] = [
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Inactive,
      title: 'boss man',
    }),
  ];
  private outOfOrder: IUserStatus[] = [
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Inactive,
      title: 'boss man',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Active,
      title: 'boss baby',
    }),
  ];
  private multiples: IUserStatus[] = [
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Inactive,
      title: 'boss man',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.RejectedInvitation,
      title: 'serf',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Inactive,
      title: 'boss lady',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Inactive,
      title: 'boss boss',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Active,
      title: 'boss baby',
    }),
    new UserStatus({
      gid: uuid(),
      status: OtUserStatus.Active,
      title: 'bottle washer',
    }),
  ];

  // * LIFECYCLE
}
