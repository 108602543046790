import { render, staticRenderFns } from "./ot-project-users-table.vue?vue&type=template&id=ccc6f1b0&scoped=true&"
import script from "./ot-project-users-table.vue?vue&type=script&lang=ts&"
export * from "./ot-project-users-table.vue?vue&type=script&lang=ts&"
import style0 from "./ot-project-users-table.vue?vue&type=style&index=0&id=ccc6f1b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccc6f1b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
