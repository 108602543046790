



































import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputAction, IInputTab } from '@/components/global/common-models';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { OtSegmentStatus, OtStatusType } from '@/types/status-enums';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SegmentConfig, SegmentInstanceDetails } from './claims-models';
import {
  ROUTE_WORKFLOW_ADD,
  ROUTE_WORKFLOW_DETAILS,
  ROUTE_WORKFLOW_DETAILS_EDIT,
  ROUTE_WORKFLOW_VIEW,
} from './claims-routes';

@Component({
  components: {
    OtLoadingSpinner,
    OtStandardHeaderArchetype,
    OtTabs,
    OtTag,
    OtButton,
  },
})
export default class OtClaimLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private primaryContextBtn: IInputAction = {
    actionName: 'New Workflow',
    action: { name: ROUTE_WORKFLOW_ADD },
  };

  private viewClaimTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Workflow Details',
      tabRoute: {
        name: ROUTE_WORKFLOW_DETAILS,
      },
    },
    {
      tabId: 1,
      tabText: 'Workflow',
      tabRoute: {
        name: ROUTE_WORKFLOW_VIEW,
      },
    },
  ];
  // these are vestigial tabs - at one stage there was going to be the ability to click Edit. Then it got taken away
  private formClaimTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Workflow Details',
      tabRoute: {
        name: ROUTE_WORKFLOW_DETAILS_EDIT,
      },
    },
  ];

  // ----- Data Driven Tab Data ----- //
  private dataDrivenSegmentDefinition: OtDataDrivenDefinition | null = null;
  private dataDrivenSegmentInstances = OtDataDrivenInstances.createEmpty();
  private evaluations: OtClientEvaluation[] = [];
  private segmentConfig: SegmentConfig | null = null;

  private api = new OtApi();
  private loading = false;
  private claimDetails = SegmentInstanceDetails.createEmpty();

  // * COMPUTED
  private get sectionTitle(): string {
    if (this.claimDetails) {
      return `${this.claimDetails.reference} - ${this.claimDetails.workflowSegment.shortName} Workflow`;
    }
    return '';
  }

  private get isContextBtnDisabled(): boolean {
    return this.isInFormState;
  }

  private get isInFormState(): boolean {
    const routeName = this.$route.name || '';
    const formRouteNames = this.formClaimTabs.map(t => t.tabRoute.name || '');
    return formRouteNames.includes(routeName);
  }

  private get effectiveViewClaimTabs() {
    const claimTabs: IInputTab[] = [...this.viewClaimTabs];
    return claimTabs;
  }

  private get claimTabs(): IInputTab[] {
    if (this.isInFormState) {
      return this.formClaimTabs;
    }
    return this.effectiveViewClaimTabs;
  }

  private get claimGid(): string {
    return this.$route.params.claimGid;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.loading = true;

    // Since this function will get called any time the user selects a new tab,
    // we only need to load the data if it hasn't already been loaded
    if (this.claimDetails === null || this.claimDetails.gid !== this.claimGid) {
      const apiCalls: Promise<void>[] = [
        this.handleReloadClaimDetails(),
        this.loadSegmentDefinition(),
        this.loadSegmentResponse(),
        this.loadSegmentEvaluation(),
      ];
      await Promise.all(apiCalls);
    }

    this.loading = false;
  }

  // * METHODS
  private getTagStatus(status: OtSegmentStatus): TagStatus {
    return { type: OtStatusType.Segment, status };
  }

  private async handleReloadClaimDetails(callback?: () => void) {
    if (this.claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceDetails(this.claimGid),
        'Load Workflow Details',
      );
      if (result.success && result.data) {
        this.claimDetails = SegmentInstanceDetails.createFromApiResponse(result.data);
        vxm.breadcrumbs.setClaimName({ claimName: this.claimDetails.name });
      }
    }

    vxm.breadcrumbs.setProjectName({ projectName: this.claimDetails.project.name });
    vxm.breadcrumbs.setContractName({ contractName: this.claimDetails.contract.name });

    if (callback) {
      callback();
    }
  }

  private async loadSegmentDefinition() {
    if (this.claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentDefinition(this.claimGid),
        'Load Segment Definition',
      );
      if (result.success && result.data) {
        const definition = OtDataDrivenDefinition.createFromApiResponse(result.data.definition);
        const segmentConfig = SegmentConfig.createFromApiResponse(result.data.config);
        this.dataDrivenSegmentDefinition = definition;
        this.segmentConfig = segmentConfig;
      }
    }
  }

  private async loadSegmentResponse() {
    if (this.claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceResponse(this.claimGid),
        'Load Segment Responses',
      );
      if (result.success && result.data) {
        const instance = OtDataDrivenInstances.createFromApiResponse(result.data);
        this.dataDrivenSegmentInstances = instance;
      }
    }
  }

  private async loadSegmentEvaluation() {
    if (this.claimGid) {
      const result = await executeApi(
        () => this.api.segmentInstances().getSegmentInstanceEvaluation(this.claimGid),
        'Load Segment Evaluation',
      );
      if (result.success && result.data) {
        const evaluations = result.data.map(x => OtClientEvaluation.createFromApiResponse(x));
        this.evaluations = evaluations;
      }
    }
  }

  // * LIFECYCLE
  private async created() {
    await this.checkRoutePath();
  }
}
