// not convinced this is the correct spot for this file, but it'll do for now

import { WorkflowUpdateModel as ApiWorkflowUpdateModel } from '@/services/generated/api';
import { OtTrafficLightColour, parsedOtTrafficLightColour } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { OtClientEvaluation } from './client-evaluation';

export interface IWorkflowUpdate {
  dueDate: ZonelessDate | null;
  trafficLightColour: OtTrafficLightColour;
  evaluation: OtClientEvaluation;
  contractRowVersion: string;
}

export class OtWorkflowUpdate implements IWorkflowUpdate {
  public dueDate!: ZonelessDate | null;
  public evaluation!: OtClientEvaluation;
  public contractRowVersion!: string;
  public trafficLightColour!: OtTrafficLightColour;

  constructor(value: IWorkflowUpdate) {
    Object.assign(this, value);
  }
  public static createEmpty() {
    return new OtWorkflowUpdate({
      dueDate: null,
      evaluation: OtClientEvaluation.createEmpty(),
      contractRowVersion: '',
      trafficLightColour: OtTrafficLightColour.None,
    });
  }
  public static createFromApiResponse(model: ApiWorkflowUpdateModel) {
    return new OtWorkflowUpdate({
      dueDate: model.dueDate ? new ZonelessDate(model.dueDate) : null,
      evaluation: OtClientEvaluation.createFromApiResponse(model.evaluation),
      contractRowVersion: model.contractRowVersion,
      trafficLightColour: parsedOtTrafficLightColour[model.trafficLightColour],
    });
  }
}
