import { TrafficLightColour as ApiTrafficLightColour } from '@/services/generated/api';

export enum OtProjectStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  Complete = 'COMPLETE',
}
export const PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR = [OtProjectStatus.Complete, OtProjectStatus.Suspended];

export enum OtContractStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
  PracticalCompletion = 'PRACTICAL COMPLETION',
  FinalCompletion = 'FINAL COMPLETION',
  Suspended = 'SUSPENDED',
  Draft = 'DRAFT',
  Cancelled = 'CANCELLED',
}
export const CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR = [
  OtContractStatus.Active,
  OtContractStatus.PracticalCompletion,
  OtContractStatus.FinalCompletion,
];
export const CONTRACT_STATUSES_CAN_BE_EDITED = new Set([
  OtContractStatus.Active,
  OtContractStatus.Pending,
  OtContractStatus.PracticalCompletion,
  OtContractStatus.FinalCompletion,
  OtContractStatus.Suspended,
]);

export enum OtSegmentStatus {
  InProgress = 'IN PROGRESS',
  Suspended = 'SUSPENDED',
  Completed = 'COMPLETED',
  Disputed = 'DISPUTED',
  LegalDispute = 'LEGAL DISPUTE',
  InfoRequestedAndSuspended = 'INFO REQUESTED & SUSPENDED',
  InfoRequestedAndNotSuspended = 'INFO REQUESTED & NOT SUSPENDED',
  Withdrawn = 'WITHDRAWN',
  Cancelled = 'CANCELLED',
}

export const OtOpenSegmentStatusSet = new Set([
  OtSegmentStatus.InProgress,
  OtSegmentStatus.Suspended,
  OtSegmentStatus.InfoRequestedAndNotSuspended,
  OtSegmentStatus.InfoRequestedAndSuspended,
]);
export const OtSegmentStatusesThatCanAffectPC = new Set([
  OtSegmentStatus.Completed,
  OtSegmentStatus.LegalDispute,
  OtSegmentStatus.Disputed,
]);

export const OtClosedSegmentStatusSet = new Set([
  OtSegmentStatus.Completed,
  OtSegmentStatus.Withdrawn,
  OtSegmentStatus.Cancelled,
  OtSegmentStatus.LegalDispute,
  OtSegmentStatus.Disputed,
]);

export enum OtSegmentResolution {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Indeterminate = 'INDETERMINATE',
  ValidWithDifferentTime = 'VALIDWITHDIFFERENTTIME',
}

export function segmentResolutionToString(resolution: OtSegmentResolution) {
  switch (resolution) {
    case OtSegmentResolution.Accepted:
      return 'Valid';
    case OtSegmentResolution.Rejected:
      return 'Invalid';
    case OtSegmentResolution.Indeterminate:
      return 'Indeterminate';
    case OtSegmentResolution.ValidWithDifferentTime:
      return 'Different extension';
    default:
      break;
  }
}

export enum OtUserStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  RejectedInvitation = 'REJECTED INVITATION',
  Inactive = 'INACTIVE',
}
export const userStatusOrder: Record<OtUserStatus, number> = {
  [OtUserStatus.Active]: 0,
  [OtUserStatus.Inactive]: 1,
  [OtUserStatus.Invited]: 2,
  [OtUserStatus.RejectedInvitation]: 3,
};

export enum OtOrganisationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  Inactive = 'INACTIVE',
}

export enum OtAmendedStatus {
  Amended = 'AMENDED',
  Unamended = 'UNAMENDED',
}

export enum OtStatusType {
  Project = 'PROJECT',
  Contract = 'CONTRACT',
  Segment = 'SEGMENT',
  User = 'USER',
  Calendar = 'CALENDAR',
  Organisation = 'ORGANISATION',
  Generic = 'GENERIC,',
}

// At this point I'm wondering if we even need this.
// We'll never expose this anywhere for anything - there's no list to select from.
// Could just use the API one for programatic stuff.
// But, it's The Pattern, so here we go.
export enum OtTrafficLightColour {
  None = 'NONE',
  Green = 'GREEN',
  Amber = 'AMBER',
  Red = 'RED',
}

// only doing the api to ot conversion. Don't need the ot to api conversion for anything, we'll never post GREEN to the server
export const parsedOtTrafficLightColour: { [key in ApiTrafficLightColour]: OtTrafficLightColour } = {
  [ApiTrafficLightColour.None]: OtTrafficLightColour.None,
  [ApiTrafficLightColour.Green]: OtTrafficLightColour.Green,
  [ApiTrafficLightColour.Amber]: OtTrafficLightColour.Amber,
  [ApiTrafficLightColour.Red]: OtTrafficLightColour.Red,
};
