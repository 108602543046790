




















import { Component, Vue, Prop } from 'vue-property-decorator';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { ContractWorkflow } from './contract-models';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { OtDataDrivenInstance } from '@/wf-components/models/data-driven-instance';
import OtApi, { executeApi } from '@/services/api.service';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';

@Component({
  components: {
    OtWorkflowEngineProcessor,
    OtLoadingSpinner,
  },
})
export default class OtContractAmendmentsDisplay extends Vue {
  // * PROPS
  @Prop() private contractWorkflow!: ContractWorkflow;

  // * DATA

  private api = new OtApi();
  private loading = false;
  private currentAmendmentsDefinition: OtDataDrivenDefinition | null = null;
  private currentAmendmentsInstance: OtDataDrivenInstance | null = null;

  // * COMPUTED
  private get amendmentsLayoutKey() {
    if (this.currentAmendmentsDefinition) {
      return this.currentAmendmentsDefinition.layoutsOrdered.filter(l => l.type === LayoutTypeEnum.Page)[0].key;
    }
    return null;
  }

  // * WATCHERS

  // * METHODS

  private async loadData(contractWorkflowGid: string) {
    this.loading = true;
    await Promise.all([
      this.getAmendmentsDefinition(contractWorkflowGid),
      this.getAmendmentsInstance(contractWorkflowGid),
    ]);
    this.loading = false;
  }

  private async getAmendmentsDefinition(contractWorkflowGid: string) {
    const apiResponse = await executeApi(
      () => this.api.contractWorkflows().getContractWorkflowAmendmentsDefinition(contractWorkflowGid),
      `Get Contract Amendment Definition: ${contractWorkflowGid}`,
    );
    if (apiResponse.success && apiResponse.data) {
      this.currentAmendmentsDefinition = OtDataDrivenDefinition.createFromApiResponse(apiResponse.data);
    }
  }
  private async getAmendmentsInstance(contractWorkflowGid: string) {
    const apiResponse = await executeApi(
      () => this.api.contractWorkflows().getContractWorkflowAmendmentsResponse(contractWorkflowGid),
      `Get Contract Amendment Responses: ${contractWorkflowGid}`,
    );
    if (apiResponse.success && apiResponse.data) {
      this.currentAmendmentsInstance = OtDataDrivenInstance.createFromApiResponse(apiResponse.data);
    }
  }

  // * LIFECYCLE

  private mounted() {
    if (this.contractWorkflow) {
      this.loadData(this.contractWorkflow.gid);
    }
  }
}
