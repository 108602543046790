import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deserializeWithCamelCaseKeys(key: string, value: any) {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    const entries = Object.entries(value);
    const camelCaseEntries = entries.map(entry => [_.camelCase(entry[0]), entry[1]]);
    const camelCaseObject = Object.fromEntries(camelCaseEntries);
    return camelCaseObject;
  }
  return value;
}
