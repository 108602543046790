










































































import OtButton from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { OtStatusType } from '@/types/status-enums';
import { formatDateAndTime } from '@/utils/date-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OtOrganisationStatus, UserProfile } from './models';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtProfileDetails extends Vue {
  // * PROPS
  @Prop() private userProfile!: UserProfile;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date);
  }

  private getTagStatus(status: OtOrganisationStatus): TagStatus {
    return { type: OtStatusType.Organisation, status };
  }

  // * LIFECYCLE
}
