


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtDurationSelection, { IDurationSelectionObject } from '@/components/global/ot-duration-selection.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { OtDataDrivenDaySelection } from '../models/data-driven-result';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';

export interface IDaySelectionObject {
  value: number | null;
  selectedCalendar: string | null;
}

@Component({
  components: {
    OtDurationSelection,
  },
})
export default class WfDaySelectionEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private title: string | null = null;
  private requiredMessage: string | null = null;
  private allowNegativeDuration = false;

  // * COMPUTED
  private inputValuePrivate: IDurationSelectionObject = {
    duration: null,
    selectedCalendar: null,
  };
  private get inputValue() {
    return this.inputValuePrivate;
  }
  private set inputValue(val: IDurationSelectionObject) {
    this.inputValuePrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'DaySelectionModel') {
        return this.value.result;
      }
      console.warn(
        'wf-day-selection-edit -> result -> ResultType is incorrect. Expected DaySelectionModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.inputValuePrivate.duration = this.result?.number || null;
    this.inputValuePrivate.selectedCalendar = this.result?.calendarType || null;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenDaySelection({
      resultType: 'DaySelectionModel',
      number: this.inputValue.duration !== null ? this.inputValue.duration : null,
      calendarType: this.inputValue.selectedCalendar || null,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;
    this.allowNegativeDuration =
      this.question.configs?.some(x => x.key === 'allowNegativeDuration' && x.value === 'true') ?? false;

    this.valueChanged();
  }
}
