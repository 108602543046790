import OtApi, { executeApi } from '@/services/api.service';
import { action, createModule, mutation } from 'vuex-class-component';
import { UserProfile } from './models';
import { OrganisationUserStatus } from '@/services/generated/api';

const vuexModule = createModule({
  namespaced: 'userProfile',
  strict: false,
});

export default class UserProfileModule extends vuexModule {
  api = new OtApi();

  // * PROPS
  private _userProfile: UserProfile | null = null;
  private userLoader: Promise<UserProfile | null> | null = null;

  // * GETTERS

  public get userProfile() {
    return this._userProfile;
  }

  public get defaultOrganisation() {
    const firstActiveOrg = this._userProfile?.organisations.find(
      x => x.organisationUser.status === OrganisationUserStatus.Active,
    );
    return firstActiveOrg || null;
  }

  public get defaultOrganisationId() {
    const firstActiveOrg = this._userProfile?.organisations.find(
      x => x.organisationUser.status === OrganisationUserStatus.Active,
    );
    return firstActiveOrg?.gid || '';
  }

  // * MUTATIONS
  @mutation public setUserProfile(userProfile: UserProfile) {
    this._userProfile = userProfile;
  }

  // * ACTIONS
  // We have this special userLoader variable so that we don't accidentally call
  // api.user().getMyProfile() more than once
  @action public async getUserProfile(params?: { forceReload: boolean }): Promise<UserProfile | null> {
    if (params?.forceReload) {
      this.userLoader = null;
    }
    if (!this.userLoader) {
      console.log('user-profile-module -> getUserProfile -> Fetch User Data');
      this.userLoader = this.loadUserProfile();
    }
    console.log('user-profile-module -> getUserProfile -> User already loaded');
    return this.userLoader;
  }

  // This function is private on purpose because we should always be using the getUserProfile
  @action private async loadUserProfile(): Promise<UserProfile | null> {
    const apiResult = await executeApi(() => this.api.user().getMyProfile(), 'Load My Profile');
    console.log('user-profile-module -> loadUserProfile -> apiResult:  ', apiResult);
    if (apiResult.success && apiResult.data) {
      const user = UserProfile.createFromApiResponse(apiResult.data);
      this.setUserProfile(user);
    }
    return this._userProfile;
  }
}
