





























import { Component, Vue } from 'vue-property-decorator';
import OtTextarea from '@/components/global/ot-textarea.vue';

interface IFieldProps {
  light: boolean;
  disabled: boolean;
  required: boolean;
  labelOn: boolean;
  hintOn: boolean;
}

interface IComponent {
  isTextarea: boolean;
  content?: string;
  props?: IFieldProps;
}

@Component({
  components: {
    OtTextarea,
  },
})
export default class OtTextareaWrapper extends Vue {
  // * PROPS

  // * DATA
  private componentsToDisplay: IComponent[] = [
    { isTextarea: false, content: '' },
    { isTextarea: false, content: 'Label On / Helper Text On' },
    { isTextarea: false, content: 'Label Off / Helper Text On' },
    { isTextarea: false, content: 'Label On / Helper Text Off' },
    { isTextarea: false, content: 'Label Off / Helper Text Off' },
    { isTextarea: false, content: 'Default' },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: true, light: false, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: true, light: false, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: false, light: false, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: false, light: false, disabled: false, required: false },
    },
    { isTextarea: false, content: 'Default Light' },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: true, light: true, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: true, light: true, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: false, light: true, disabled: false, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: false, light: true, disabled: false, required: false },
    },
    { isTextarea: false, content: 'Disabled' },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: true, light: false, disabled: true, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: true, light: false, disabled: true, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: false, light: false, disabled: true, required: false },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: false, light: false, disabled: true, required: false },
    },
    { isTextarea: false, content: 'Required' },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: true, light: false, disabled: false, required: true },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: true, light: false, disabled: false, required: true },
    },
    {
      isTextarea: true,
      props: { labelOn: true, hintOn: false, light: false, disabled: false, required: true },
    },
    {
      isTextarea: true,
      props: { labelOn: false, hintOn: false, light: false, disabled: false, required: true },
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
