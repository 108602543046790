


























































import { Component, Vue } from 'vue-property-decorator';
import { IExtendedColumnData, IColumnData } from '@/components/global/table/ot-table-models';
import { sortTableData, handleHeaderClick, maxPageItemCount } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import faker from 'faker';
import { Calendar, OtCalendarStatus, parsedOtCalendarEnums } from '@/areas/settings/models/calendar-models';
import OtTag from '@/components/global/ot-tag.vue';

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtTag,
  },
})
export default class OtTableWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private page = 1;
  private searchText = '';
  private bodyData: Calendar[] = [];
  private tableColumns: IExtendedColumnData<Calendar>[] = [
    {
      index: 0,
      label: 'Calendar',
      key: 'calendar',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: Calendar.compareByName,
    },
    {
      index: 1,
      label: 'Type',
      key: 'type',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Calendar.compareByName,
    },
    {
      index: 2,
      label: 'Active',
      key: 'active',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private statusFilters: CheckboxGroupItem<OtCalendarStatus>[] = [
    new CheckboxGroupItem('Active', OtCalendarStatus.Active),
    new CheckboxGroupItem('Inactive', OtCalendarStatus.Inactive),
  ];

  private selectedFilters: CheckboxGroupItem<OtCalendarStatus>[] = [];

  private activeColumn = this.tableColumns[0];

  // * COMPUTED
  private get sortedBodyData() {
    return sortTableData(this.tableColumns, this.activeColumn, this.bodyData);
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return Calendar.filterByStatus(parsedStatuses, row) && Calendar.filterByKeyword(this.searchText, row);
    });
  }

  private get paginatedBodyData() {
    const chunkSize = maxPageItemCount;
    const pages = [];
    for (let i = 0; i < this.filteredBodyData.length; i += chunkSize) {
      pages.push(this.filteredBodyData.slice(i, i + chunkSize));
    }
    return pages;
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  // * WATCHERS

  // * METHODS
  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  // * LIFECYCLE
  private created() {
    for (let i = 0; i < 143; i++) {
      this.bodyData.push(
        new Calendar({
          gid: faker.datatype.uuid(),
          name: faker.commerce.productName(),
          status: faker.datatype.boolean() ? parsedOtCalendarEnums[1] : parsedOtCalendarEnums[2],
          hasParent: faker.datatype.boolean(),
        }),
      );
    }
  }
}
