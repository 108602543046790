















































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { numericAndNegativeSignKeyboardEventKeys, numericKeyboardEventKeys } from '@/well-known-values/key-codes';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtSelect, { ISelectItem } from '@/components/global/ot-select.vue';
import { CalendarTypeEnum, CalendarTypesMap } from '@/wf-components/models/data-driven-enums';
import { filterPaste } from '@/utils/string-utils';
import { IVForm, IValidate, asForm } from '@/utils/type-utils';

export interface IDurationSelectionObject {
  duration: number | null;
  selectedCalendar: CalendarTypeEnum | null;
}

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
    OtSelect,
  },
})
export default class OtDurationSelection extends Vue implements IValidate {
  // * PROPS
  @Prop() private value!: IDurationSelectionObject | null;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop({ default: () => [] }) private valueRules!: Array<(value: string | null) => boolean | string>;
  @Prop({ default: () => [] }) private calendarRules!: Array<(value: string | null) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: false }) private allowNegativeDuration!: boolean;
  @Prop() public placeholder?: string;

  // * REFS
  @Ref('textFieldRef') private textFieldRef!: OtTextField;
  @Ref('selectRef') private selectRef!: OtSelect;

  // * DATA
  private durationError = false;
  private calendarError = false;
  private hasBeenValidatedBefore = false;
  private errorBucket: string[] = [];

  // * COMPUTED
  private get calendarOptions(): ISelectItem<CalendarTypeEnum>[] {
    return Array.from(CalendarTypesMap.keys()).map(t => {
      return {
        label: CalendarTypesMap.get(t) || '',
        data: t,
      };
    });
  }

  private get errorMessage() {
    return this.errorBucket[0] || null;
  }

  private get effectivePlaceholder() {
    return this.placeholder ?? 'Enter number of days';
  }

  private get localValueRules() {
    const rules: Array<(value: string | null) => boolean | string> = [...this.valueRules];
    if (this.requiredMessage) {
      rules.unshift((value: string | null) => !!value || this.requiredMessage || '');
    }
    return rules;
  }

  private get localCalendarRules() {
    const rules: Array<(value: string | null) => boolean | string> = [...this.calendarRules];
    if (this.requiredMessage) {
      rules.unshift((value: string | null) => !!value || this.requiredMessage || '');
    }
    return rules;
  }

  private get effectiveDurationError() {
    return !this.readonly && !this.disabled && this.durationError;
  }

  private get effectiveCalendarError() {
    return !this.readonly && !this.disabled && this.calendarError;
  }

  private durationPrivate: string | null = null;
  private get duration() {
    return this.durationPrivate;
  }
  private set duration(val: string | null) {
    this.durationPrivate = val;
    this.emitObject();
  }

  private selectedCalendarPrivate: ISelectItem<CalendarTypeEnum> | null = null;
  private get selectedCalendar() {
    return this.selectedCalendarPrivate;
  }
  private set selectedCalendar(val: ISelectItem<CalendarTypeEnum> | null) {
    this.selectedCalendarPrivate = val;
    this.emitObject();
  }

  // * WATCHERS
  @Watch('value', { deep: true })
  private valueChanged() {
    this.durationPrivate = this.value?.duration?.toString() || null;
    this.selectedCalendarPrivate = this.calendarOptions.find(o => o.data === this.value?.selectedCalendar) || null;
  }

  @Watch('requiredMessage')
  private requiredMessageChanged() {
    if (this.hasBeenValidatedBefore) {
      this.validate();
    }
  }

  // * METHODS
  private emitObject() {
    const duration = parseInt(this.duration || '');
    const val: IDurationSelectionObject = {
      duration: Number.isNaN(duration) ? null : duration,
      selectedCalendar: this.selectedCalendar?.data || null,
    };

    if (this.hasBeenValidatedBefore) {
      this.validate();
    }
    this.$emit('input', val);
  }

  private onTextInputBlur() {
    if (!!this.selectedCalendarPrivate || this.hasBeenValidatedBefore) {
      this.validate();
    }
  }

  private onSelectInputBlur() {
    if (!!this.durationPrivate || this.hasBeenValidatedBefore) {
      this.validate();
    }
  }

  private handlePaste(event: ClipboardEvent) {
    const pasteResult = filterPaste(event, this.duration, this.allowNegativeDuration);

    if (pasteResult) {
      this.duration = pasteResult.textValue;
      const textField = event.target as HTMLInputElement;
      this.$nextTick(() => {
        textField.selectionStart = pasteResult.startPos + pasteResult.data.length;
        textField.selectionEnd = pasteResult.startPos + pasteResult.data.length;
      });
    }
  }

  private filterKeypress(event: KeyboardEvent) {
    const allowed = this.allowNegativeDuration ? numericAndNegativeSignKeyboardEventKeys : numericKeyboardEventKeys;
    if (!allowed.includes(event.key)) {
      event.preventDefault();
    }
  }

  public resetValidation() {
    this.calendarError = false;
    this.durationError = false;
    this.errorBucket = [];
    this.hasBeenValidatedBefore = false;
  }

  public reset() {
    this.resetValidation();
    this.durationPrivate = null;
    this.selectedCalendarPrivate = null;
  }

  public validate(): boolean {
    if (!this.readonly) {
      const durationErrorBucket: string[] = [];
      const calendarErrorBucket: string[] = [];

      this.hasBeenValidatedBefore = true;

      for (let index = 0; index < this.localValueRules.length; index++) {
        const rule = this.localValueRules[index];
        const valid = rule(this.durationPrivate);

        if (valid === false || typeof valid === 'string') {
          durationErrorBucket.push(valid || '');
        }
      }

      for (let index = 0; index < this.localCalendarRules.length; index++) {
        const rule = this.localCalendarRules[index];
        const valid = typeof rule === 'function' ? rule(this.selectedCalendarPrivate?.label || null) : rule;

        if (valid === false || typeof valid === 'string') {
          calendarErrorBucket.push(valid || '');
        }
      }

      this.durationError = durationErrorBucket.length !== 0;
      this.calendarError = calendarErrorBucket.length !== 0;
      this.errorBucket = durationErrorBucket.concat(calendarErrorBucket);

      return !this.effectiveDurationError && !this.effectiveCalendarError;
    }
    return true;
  }

  // * LIFECYCLE
  private created() {
    this.valueChanged();
  }

  private mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;

    // Find the first form element and register this component to it if it is found
    // so that the validation on this component can trigger when the form.validate() function is called
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.register(this);
    }
  }
  private beforeDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.unregister(this);
    }
  }
}
