






















































import { Component, Vue, Ref } from 'vue-property-decorator';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { FormModalParams } from '../global/modal/form-modal-models';
import { IVForm } from '@/utils/type-utils';

export class ClaimRecommendationOverruleDialogResult {
  public doOverride = false;
  public overrideReason = '';

  constructor(params?: Partial<ClaimRecommendationOverruleDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class ClaimRecommendationOverruleDialogParams {
  public noticeName!: string;
  public overruleState!: OverruleStateEnum;
  public daysEntitledTo!: number;

  public constructor(noticeName: string, overruleState: OverruleStateEnum, daysEntitledTo: number) {
    this.noticeName = noticeName;
    this.overruleState = overruleState;
    this.daysEntitledTo = daysEntitledTo;
  }
}

export enum OverruleStateEnum {
  ValidToInvalid = 'ValidToInvalid',
  InvalidToValid = 'InvalidToValid',
  ValidWithDifferentTime = 'ValidWithDifferentTime',
  IndeterminateToInvalid = 'IndeterminateToInvalid',
  IndeterminateToValid = 'IndeterminateToValid',
}

@Component({
  components: {
    OtFormModal,
    OtTextarea,
    OtCheckbox,
    OtFieldArchetype,
  },
})
export default class OtClaimRecommendationOverruleDialog extends Vue {
  // * PROPS

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;
  @Ref('theCheckbox') private readonly theCheckbox!: OtCheckbox;

  // * DATA

  private isCheckboxSelectedPrivate = false;
  private get isCheckboxSelected() {
    return this.isCheckboxSelectedPrivate;
  }
  private set isCheckboxSelected(val: boolean) {
    this.isCheckboxSelectedPrivate = val;
    // this is SUCH a messy hack
    // the insides of the checkbox are ridiculously complex. Like, why do we need SO MUCH code for a checkbox?
    // the basic problem is there's a sync'd error state property, for reasons I don't fully understand, and it never gets set to false
    // there's a comment in the code saying "if you're having problems, call resetManualErrorState"
    // So, I am. I wish there was a better solution. Throw away the custom components and work with native vuetify would be THE solution I reckon
    this.theCheckbox.resetManualErrorState();
  }
  private overrideReason: string | null = null;
  private checkboxRules = [(value: boolean) => !!value || 'Please confirm your intention to overrule this workflow'];

  private overruleState: OverruleStateEnum = OverruleStateEnum.InvalidToValid;
  private daysEntitledTo = 0;
  private noticeName = '';

  private dodgyHack = 0;

  // * COMPUTED
  private get warningMessages(): string[] {
    switch (this.overruleState) {
      case OverruleStateEnum.ValidToInvalid:
        return [
          `OneTrack has determined that the ${this.noticeName} is Valid.`,
          `You have decided to override this and set the ${this.noticeName} as Invalid.`,
          `This can give rise to tension with the Contractor and subsequent difficulties and disputes.`,
        ];
      case OverruleStateEnum.InvalidToValid:
        return [
          `OneTrack has determined that the ${this.noticeName} is Invalid.`,
          `You have decided to override this and set the ${this.noticeName} as Valid.`,
          `Given that this is the exercise of discretion (rather than the administration of the Contract) it may be necessary to seek confirmation from the Principal regarding this.`,
          `This can give rise to confusion as to the future administration of the Contract.`,
        ];
      case OverruleStateEnum.ValidWithDifferentTime:
        return [
          `OneTrack has determined that the Contractor is entitled to ${this.daysEntitledTo} days by way of extension of time. You have decided to grant a different number of days. Care should be taken in granting a different number of days to the number determined by OneTrack. The granting of a different number of days could result in a breach of contract or could otherwise create uncertainty as to the future application of the Contract.`,
        ];
      case OverruleStateEnum.IndeterminateToInvalid:
        return [
          `OneTrack has determined that the ${this.noticeName} is Incomplete.`,
          `You have decided to override this and set the ${this.noticeName} as Invalid.`,
          `This can give rise to tension with the Contractor and subsequent difficulties and disputes.`,
        ];
      case OverruleStateEnum.IndeterminateToValid:
        return [
          `OneTrack has determined that the ${this.noticeName} is Incomplete.`,
          `You have decided to override this and set the ${this.noticeName} as Valid.`,
          `This can give rise to tension with the Contractor and subsequent difficulties and disputes.`,
        ];
      default:
        return [];
    }
  }

  private get overruleTo() {
    switch (this.overruleState) {
      case OverruleStateEnum.ValidToInvalid:
        return 'invalid';
      case OverruleStateEnum.InvalidToValid:
        return 'valid';
      case OverruleStateEnum.ValidWithDifferentTime:
        return 'valid with different time';
      case OverruleStateEnum.IndeterminateToInvalid:
        return 'Invalid';
      case OverruleStateEnum.IndeterminateToValid:
        return 'Valid';

      default:
        return '';
    }
  }

  private get overruleTextEntryDescription() {
    if (
      this.overruleState === OverruleStateEnum.IndeterminateToInvalid ||
      this.overruleState === OverruleStateEnum.IndeterminateToValid
    ) {
      return `Be as descriptive as possible`;
    }
    return null;
  }

  private get overruleTextEntryTitle() {
    if (
      this.overruleState === OverruleStateEnum.IndeterminateToInvalid ||
      this.overruleState === OverruleStateEnum.IndeterminateToValid
    ) {
      return `Describe the reason why you are producing a result without all required information`;
    }
    return `Enter the reason to overrule`;
  }

  private get overruleCheckboxTitle() {
    return `Yes, I want to overrule the recommendation and set workflow as ${this.overruleTo}`;
  }

  private get showClockIcon() {
    return this.overruleState === OverruleStateEnum.ValidWithDifferentTime;
  }

  private get leftIcon() {
    switch (this.overruleState) {
      case OverruleStateEnum.ValidToInvalid:
        return '$coloredCheck';
      case OverruleStateEnum.InvalidToValid:
        return '$coloredCross';
      case OverruleStateEnum.ValidWithDifferentTime:
        return '$coloredCheck';
      case OverruleStateEnum.IndeterminateToValid:
      case OverruleStateEnum.IndeterminateToInvalid:
        return '$monochromeCross';
      default:
        return '';
    }
  }

  private get rightIcon() {
    switch (this.overruleState) {
      case OverruleStateEnum.ValidToInvalid:
        return '$coloredCross';
      case OverruleStateEnum.InvalidToValid:
        return '$coloredCheck';
      case OverruleStateEnum.ValidWithDifferentTime:
        return '$coloredCheck';
      case OverruleStateEnum.IndeterminateToInvalid:
        return '$coloredCross';
      case OverruleStateEnum.IndeterminateToValid:
        return '$coloredCheck';
      default:
        return '';
    }
  }

  private get overruleStateText() {
    switch (this.overruleState) {
      case OverruleStateEnum.ValidToInvalid:
        return 'Valid to Invalid';
      case OverruleStateEnum.InvalidToValid:
        return 'Invalid to Valid';
      case OverruleStateEnum.ValidWithDifferentTime:
        return 'Different Extension';
      case OverruleStateEnum.IndeterminateToInvalid:
        return 'Incomplete to Invalid';
      case OverruleStateEnum.IndeterminateToValid:
        return 'Incomplete to Valid';
      default:
        return '';
    }
  }

  // * WATCHERS

  // * METHODS
  public async open(params: ClaimRecommendationOverruleDialogParams): Promise<ClaimRecommendationOverruleDialogResult> {
    this.isCheckboxSelected = false;
    this.overrideReason = '';
    this.overruleState = params.overruleState;
    this.daysEntitledTo = params.daysEntitledTo;
    this.noticeName = params.noticeName;
    // For some reason the form REALLY DOES NOT want to drop previous validation errors
    // I don't want to run through every form component resetting its validation
    // I would have thought resetValidation on the form does it, but here we are
    // resetValidation is OK if you've tried to submit the form and gotten a validation error
    // but, if you've ticked and unticked the checkbox, resetValidation doesn't reset that, and the next time
    // you open the form you're looking at the validation error from last time
    // This dodgy hack causes Vue to recreate the form completely
    this.dodgyHack += 1;
    await this.$nextTick();
    this.modalContentRef.reset();
    this.modalContentRef.resetValidation();

    const baseParams = new FormModalParams({
      title: `Overrule Workflow Recommendation (${this.overruleStateText})`,
      formRef: this.modalContentRef,
      confirmText: `Confirm overrule workflow to ${this.overruleTo}`,
    });
    const result = await this.formModalRef.open(baseParams);

    if (result.ok) {
      return new ClaimRecommendationOverruleDialogResult({
        doOverride: true,
        overrideReason: this.overrideReason || undefined,
      });
    }
    return new ClaimRecommendationOverruleDialogResult({
      doOverride: false,
    });
  }

  // * LIFECYCLE
}
