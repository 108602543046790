


























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { vxm } from '@/store';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtApi, { executeApi } from '@/services/api.service';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtSelect from '@/components/global/ot-select.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import { IInputTab } from '@/components/global/common-models';
import { dirtyFormClass } from '@/utils/validation-utils';
import { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import { blurCombos, IVForm } from '@/utils/type-utils';
import { OtUserStatus, OtStatusType } from '@/types/status-enums';
import { ProjectUserDetails, ProjectUserDetailsFormObject } from './project-user-models';
import { ROUTE_PROJECT_USERS, ROUTE_PROJECT_USER_EDIT } from '../projects-routes';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtTag,
    OtButton,
    OtTextField,
    OtTextarea,
    OtPhoneNumberField,
    OtAddressField,
    OtSelect,
    OtComboBox,
  },
})
export default class OtEditProjectUserDetails extends Vue {
  // * PROPS

  // * REFS
  @Ref('projectUserDetailsFormRef') private readonly projectUserDetailsFormRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private formData = ProjectUserDetailsFormObject.createEmpty();
  private projectUser = ProjectUserDetails.createEmpty();
  private projectUserJobTitles: string[] = [];
  private isLoading = true;
  private originalThumbprint = '';
  private currentThumbprint = '';
  private saving = false;

  private get settingsTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'USER DETAILS',
        tabRoute: {
          params: { projectUserGid: this.projectUser.gid },
          name: ROUTE_PROJECT_USER_EDIT,
        },
      },
    ];
  }

  // Validation rules

  public validate() {
    return this.projectUserDetailsFormRef.validate();
  }

  // * COMPUTED

  private get userStatusGid(): string {
    return this.projectUser.projectUserStatus?.gid || '';
  }

  private get projectUserStatus(): OtUserStatus {
    const status = this.projectUser.projectUserStatus?.status;
    // NOTE - the default here is inactive - it's never going to hit the other side of the || ?
    return status || OtUserStatus.Inactive;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  get projectUserJobTitleItems(): IAutocompleteItem<string>[] {
    return this.projectUserJobTitles.map(t => {
      return {
        label: t,
        data: t,
      };
    });
  }

  get projectUsersRoute(): string {
    return ROUTE_PROJECT_USERS;
  }

  private get projectGid() {
    return this.$route.params.projectGid || '';
  }

  private get userGid() {
    return this.$route.params.userGid || '';
  }
  private get userName() {
    return this.projectUser.firstName + ' ' + this.projectUser.lastName || '';
  }

  // * WATCHERS

  @Watch('routePath')
  private async checkRoutePath() {
    if (!this.projectUser || this.projectUser.gid !== this.userGid) {
      this.isLoading = true;
      await this.getProjectUserDetails();
      this.isLoading = false;
    }
  }

  // watch the organisation user details and update the thumbprint
  @Watch('formData', { deep: true })
  private formDataChanged(val: ProjectUserDetailsFormObject) {
    this.currentThumbprint = this.getProjectUserDetailsThumbprint(val);
  }

  // * METHODS

  private navigateBackward() {
    // according to the ENG we literally just go back in the browser history
    return this.$router.back();
  }

  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  private setUserNameToBreadcrumb() {
    vxm.breadcrumbs.setUserName({ userName: this.userName });
  }

  private setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.projectUser.projectName });
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getProjectUserDetailsThumbprint(projectUserDetails: ProjectUserDetailsFormObject): string {
    const vals = {
      jobTitle: projectUserDetails.functionalRoleTitle || '',
    };
    return JSON.stringify(vals);
  }

  private async updateProjectUserDetails() {
    blurCombos(this.$refs.jobTitleCombo);
    this.$nextTick(() => {
      this.updateProjectUserDetailsInner();
    });
  }

  private async updateProjectUserDetailsInner() {
    const valid = this.validate();

    if (valid && this.formData) {
      this.saving = true;
      const requestModel = ProjectUserDetailsFormObject.createRequestModel(this.formData);

      const apiResponse = await executeApi(
        () =>
          this.api
            .projectUser()
            .postUpdateProjectUserDetails(this.projectUser.projectUserStatus?.gid || '', undefined, requestModel),
        'Update Project User Details',
      );
      if (apiResponse && apiResponse.success) {
        this.setFormToCleanState();
        // Even though we set the form to be clean just above,
        // the dirty modal still appears unless we reroute on the next tick
        this.$nextTick(() => {
          this.navigateBackward();
        });
      }
      this.saving = false;
    }
  }

  private async getProjectUserDetails() {
    const result = await executeApi(
      () => this.api.projects().getProjectUserDetails(this.projectGid, this.userGid),
      'Load Project User Details',
    );
    if (result.success && result.data && result.data) {
      this.projectUser = ProjectUserDetails.createFromApiResponse(result.data);
      this.formData = ProjectUserDetailsFormObject.createFromApiResponse(result.data);
      this.originalThumbprint = this.getProjectUserDetailsThumbprint(this.formData);
      this.currentThumbprint = this.originalThumbprint;
      this.setProjectNameToBreadcrumb();
      this.setUserNameToBreadcrumb();
    }
  }
  private async getProjectUserJobTitles() {
    const result = await executeApi(() => this.api.projectUser().getProjectUserJobTitles(), 'Load Job Titles');
    if (result.success && result.data && result.data) {
      this.projectUserJobTitles = result.data;
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
    this.getProjectUserJobTitles();
  }
}
