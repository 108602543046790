










































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import { formatDateAndTime } from '@/utils/date-utils';
import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { ContractUserDetails } from './contract-user-models';
import { OtUserAction } from '@/types/action-enums';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtContractUsersDetails extends Vue {
  // * PROPS
  @Prop() private contractUser!: ContractUserDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  private get jobTitle() {
    if (!this.contractUser.contractUserStatus) {
      return '';
    }
    return this.contractUser.contractUserStatus?.title;
  }

  private get hasContractUserStatus() {
    return Boolean(this.contractUser.contractUserStatus);
  }

  private get canEdit(): boolean {
    //const profile = vxm.userProfile.userProfile;
    //const isOrgUser = profile?.organisations?.some(org => org.gid === this.orgId);
    //return Boolean(isOrgUser);
    const supportsEdit = this.contractUser.contractUserStatus?.availableActions.some(a => a === OtUserAction.Edit);
    return supportsEdit ?? false;
  }

  // * WATCHERS

  // * METHODS
  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date);
  }

  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  // * LIFECYCLE
}
