














































import { Component, Prop, Vue } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import { CalendarDetails, Holiday, OtCalendarStatus } from '../models/calendar-models';
import { OtStatusType } from '@/types/status-enums';
import { formatDate } from '@/utils/date-utils';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtOrganisationCalendarDetails extends Vue {
  // * PROPS
  @Prop() private calendar!: CalendarDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private getTagStatus(status: OtCalendarStatus): TagStatus {
    return { type: OtStatusType.Calendar, status };
  }

  private getHolidayDates(holiday: Holiday): string {
    const orderedDates = holiday.dates.map(d => d.date);
    orderedDates.sort((a, b) => a.getTime() - b.getTime());
    return orderedDates.map(d => formatDate(d)).join(', ');
  }

  // * LIFECYCLE
}
