





































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { IWorkflowComponentDisplayDefinition } from '../models/workflow-component-definition';
import { sanitizer } from '@/utils/xss-utils';

@Component({})
export default class WfAlertDisplay extends Vue implements IWorkflowComponentDisplayDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get alertContents() {
    if (!this.question.description) {
      return null;
    }

    return sanitizer.process(`<div>${this.question.description}</div>`);
  }

  private get alertAttrs() {
    const configs = this.question.configs || [];
    if (!configs.length) {
      return null;
    }

    const result: { [key: string]: string | boolean } = {};
    for (const config of configs) {
      result[config.key] = config.value ?? true;
    }
    return result;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
