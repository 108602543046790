import { UserStatusWithActions } from '@/models/user-models';
import {
  GetInvitableContractsResponseModel,
  GetInvitableOrganisationsResponseModel,
  GetInvitableProjectsResponseModel,
  GetInvitableUsersResponseModel,
} from '@/services/generated/api';
export interface IInvitableUser {
  gid: string;
  firstName: string;
  lastName: string;
  email: string;
}

export class InvitableUser implements IInvitableUser {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;

  public constructor(value: IInvitableUser) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: GetInvitableUsersResponseModel): InvitableUser {
    return new InvitableUser({
      gid: model.gid || '',
      firstName: model.firstName || '',
      lastName: model.lastName || '',
      email: model.email || '',
    });
  }
}

export interface IInvitableOrganisation {
  gid: string;
  code: string;
  name: string;
  userStatus: UserStatusWithActions | null;
}

export class InvitableOrganisation implements IInvitableOrganisation {
  public gid!: string;
  public code!: string;
  public name!: string;
  public userStatus: UserStatusWithActions | null = null;

  public constructor(value: IInvitableOrganisation) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: GetInvitableOrganisationsResponseModel): InvitableOrganisation {
    return new InvitableOrganisation({
      gid: model.gid || '',
      code: model.code || '',
      name: model.name || '',
      userStatus: model.userStatus ? UserStatusWithActions.createFromApiResponse(model.userStatus) : null,
    });
  }
}

export interface IInvitableProject {
  gid: string;
  code: string;
  name: string;
  userStatus: UserStatusWithActions | null;
}

export class InvitableProject implements IInvitableProject {
  public gid!: string;
  public code!: string;
  public name!: string;
  public userStatus: UserStatusWithActions | null = null;

  public constructor(value: IInvitableProject) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: GetInvitableProjectsResponseModel): InvitableProject {
    return new InvitableProject({
      gid: model.gid || '',
      code: model.code || '',
      name: model.name || '',
      userStatus: model.userStatus ? UserStatusWithActions.createFromApiResponse(model.userStatus) : null,
    });
  }
}

export interface IInvitableContract {
  gid: string;
  name: string;
  projectCode: string;
  projectName: string;
  userStatus: UserStatusWithActions | null;
}

export class InvitableContract implements IInvitableContract {
  public gid!: string;
  public name!: string;
  public projectCode!: string;
  public projectName!: string;
  public userStatus: UserStatusWithActions | null = null;

  public constructor(value: IInvitableContract) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: GetInvitableContractsResponseModel): InvitableContract {
    return new InvitableContract({
      gid: model.gid || '',
      name: model.name || '',
      projectCode: model.projectCode || '',
      projectName: model.projectName || '',
      userStatus: model.userStatus ? UserStatusWithActions.createFromApiResponse(model.userStatus) : null,
    });
  }
}
