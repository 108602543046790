





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Holiday } from '../models/calendar-models';
import OtTable from '@/components/global/table/ot-table.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtCalendarHolidayTableRow from '@/areas/settings/calendars/ot-calendar-holiday-table-row.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import { IColumnData } from '@/components/global/table/ot-table-models';

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCalendarHolidayTableRow,
    OtLoadingSpinner,
  },
})
export default class OtCalendarHolidayTable extends Vue {
  // * PROPS
  @Prop() private holidayBodyData!: Holiday[];
  @Prop(String) private calendarGid?: string;
  @Prop(Boolean) private loading!: boolean;

  // * REFS

  // * DATA
  private holidayColumns: IColumnData[] = [
    {
      index: 0,
      label: 'Holiday',
      key: 'holiday',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 1,
      label: 'Holiday Dates',
      key: 'holidayDates',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: '',
      key: 'actionMenu',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private removeHoliday(holiday: Holiday) {
    this.$emit('removeHoliday', holiday);
  }

  private editHoliday(holiday: Holiday) {
    this.$emit('editHoliday', holiday);
  }

  // * LIFECYCLE
}
