













































































import { Component, Ref, Vue } from 'vue-property-decorator';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { IVForm } from '@/utils/type-utils';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
    OtRadioGroup,
    OtTextarea,
  },
})
export default class OtRadioGroupWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('form') private readonly form!: IVForm;

  // * DATA
  private textareaValue = 'Label field text 1\nLabel field text 2\nLabel field text 3\nLabel field text 4';
  private selectedValue: IRadioGroupOption | null = null;

  // * COMPUTED
  private get radioButtons(): IRadioGroupOption[] {
    if (this.textareaValue) {
      const options = this.textareaValue.split('\n');
      return options.map(o => {
        return { label: o, key: uuid() };
      });
    }
    return [];
  }

  // * WATCHERS

  // * METHODS
  private validate() {
    this.selectedValue = null;
    this.form.validate();
  }

  // * LIFECYCLE
}
