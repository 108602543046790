import { createModule, mutation } from 'vuex-class-component';

const vuexModule = createModule({
  namespaced: 'breadcrumb',
  strict: false,
});

export default class BreadcrumbModule extends vuexModule {
  private _projectName = '';
  private _userName = '';
  private _contractName = '';
  private _documentName = '';
  private _calendarName = '';
  private _workflowName = '';
  private _claimName = '';
  private _organisationName = '';

  // * GETTERS
  public get projectName() {
    return this._projectName;
  }
  public get organisationName() {
    return this._organisationName;
  }

  public get userName() {
    return this._userName;
  }

  public get contractName() {
    return this._contractName;
  }

  public get documentName() {
    return this._documentName;
  }

  public get calendarName() {
    return this._calendarName;
  }

  public get workflowName() {
    return this._workflowName;
  }

  public get claimName() {
    return this._claimName;
  }

  // * MUTATIONS
  @mutation public setOrganisationName(props: { organisation: string }) {
    this._organisationName = props.organisation;
  }

  @mutation public setProjectName(props: { projectName: string }) {
    this._projectName = props.projectName;
  }

  @mutation public setUserName(props: { userName: string }) {
    this._userName = props.userName;
  }

  @mutation public setContractName(props: { contractName: string }) {
    this._contractName = props.contractName;
  }

  @mutation public setDocumentName(props: { documentName: string }) {
    this._documentName = props.documentName;
  }

  @mutation public setCalendarName(props: { calendarName: string }) {
    this._calendarName = props.calendarName;
  }

  @mutation public setWorkflowName(props: { workflowName: string }) {
    this._workflowName = props.workflowName;
  }

  @mutation public setClaimName(props: { claimName: string }) {
    this._claimName = props.claimName;
  }
}
