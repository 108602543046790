












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {
  // * PROPS
  @Prop() private prop: unknown;

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
