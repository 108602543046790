


































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtButton from '@/components/global/ot-button.vue';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { IValidate } from '@/utils/type-utils';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';

interface IDynamicListTextFieldTextareaObject {
  key: string;
  title: string | null;
  description: string | null;
  selectedSubOptionKey: string | null;
}

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
    OtTextarea,
    OtRadioGroup,
    OtButton,
  },
})
export default class WfDynamicListTextFieldAndTextAreaEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS
  @Ref('checkboxRef') private checkboxRef!: IValidate;

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private placeholder: string | null = null;
  private isRequired = false;
  private showErrorMessage = false;

  private tableBodyData: IDynamicListTextFieldTextareaObject[] = [];

  private checkboxRules: Array<(value: boolean) => boolean | string> = [
    (value: boolean) => value === true || 'Required',
  ];

  // * COMPUTED
  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-dynamic-list-text-field-textarea-and-filled-radio-buttons-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get displayRadioOptions() {
    return !!this.question.subOptions?.length;
  }

  private get tableHeader1() {
    const header = this.question.columnHeadersOrdered[0]?.title || '';
    if (!header) {
      console.warn(
        'wf-dynamic-list-text-field-textarea-and-filled-radio-buttons-edit -> tableHeader1:  Column header title not found',
      );
    }
    return header;
  }

  private get tableHeader2() {
    const header = this.question.columnHeadersOrdered[1]?.title || '';
    if (!header) {
      console.warn(
        'wf-dynamic-list-text-field-textarea-and-filled-radio-buttons-edit -> tableHeader2:  Column header title not found',
      );
    }
    return header;
  }

  private get tableHeader3() {
    const header = this.question.columnHeadersOrdered[2]?.title || '';
    if (!header && this.displayRadioOptions) {
      console.warn(
        'wf-dynamic-list-text-field-textarea-and-filled-radio-buttons-edit -> tableHeader3:  Column header title not found',
      );
    }
    return header;
  }

  private get buttonText() {
    return this.question.addButtonText || '';
  }

  private get radioOptions(): IRadioGroupOption[] {
    return (
      this.question.subOptions?.map(o => {
        return {
          key: o.key,
          label: o.title,
        };
      }) || []
    );
  }

  private get controlIsValid() {
    return !!this.tableBodyData.length;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.tableBodyData =
      this.result?.values.map(v => {
        return {
          key: v.key,
          selectedSubOptionKey: v.subOptionKey || null,
          title: v.title || null,
          description: v.description || null,
        };
      }) || [];
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.tableBodyData.map(
        (i, index) =>
          new OtDataDrivenValue({
            key: i.key,
            subOptionKey: i.selectedSubOptionKey,
            title: i.title,
            description: i.description,
            orderIndex: index,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  private addNewItem() {
    this.tableBodyData.push({ key: uuid(), title: null, description: null, selectedSubOptionKey: null });
    this.onChange();
  }

  private deleteItem(item: IDynamicListTextFieldTextareaObject) {
    const foundIndex = this.tableBodyData.findIndex(i => i.key === item.key);
    if (foundIndex !== -1) {
      this.tableBodyData.splice(foundIndex, 1);
      this.onChange();
    }
  }

  private setTitleValue(item: IDynamicListTextFieldTextareaObject, value: string) {
    item.title = value;
    this.onChange();
  }

  private setDescriptionValue(item: IDynamicListTextFieldTextareaObject, value: string) {
    item.description = value;
    this.onChange();
  }

  private getSelectedOption(item: IDynamicListTextFieldTextareaObject): IRadioGroupOption | null {
    return this.radioOptions.find(o => o.key === item.selectedSubOptionKey) || null;
  }

  private setSelectedOptionValue(item: IDynamicListTextFieldTextareaObject, value: IRadioGroupOption) {
    item.selectedSubOptionKey = value.key;
    this.onChange();
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.placeholder = this.question.placeholder;
    this.isRequired = this.question.isMandatory;

    this.valueChanged();
  }

  private mounted() {
    this.$watch('checkboxRef.validationState', (state: string) => {
      this.showErrorMessage = state === 'error';
    });
  }
}
