import { render, staticRenderFns } from "./wf-option-list-with-sub-options-edit.vue?vue&type=template&id=fa74e4f8&scoped=true&"
import script from "./wf-option-list-with-sub-options-edit.vue?vue&type=script&lang=ts&"
export * from "./wf-option-list-with-sub-options-edit.vue?vue&type=script&lang=ts&"
import style0 from "./wf-option-list-with-sub-options-edit.vue?vue&type=style&index=0&id=fa74e4f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa74e4f8",
  null
  
)

export default component.exports