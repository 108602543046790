








































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';
import { blurCombos, IValidate } from '@/utils/type-utils';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtComboBox extends Vue {
  // * PROPS
  @Prop() private value!: string;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private secondaryLabel?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: String, default: 'auto' }) private hideDetails!: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: true }) private validateOnBlur!: boolean;
  @Prop({ type: String, default: '$chevronDown' }) private dropdownIcon!: string;
  // set this to false if you need the combo box to be able to "hang outside" the parent component, and be wider than the field
  // but be careful, if the parent component is scrollable, sometimes (happens in a dialog) the picker will no longer "follow" the field and it'll look dumb
  @Prop({ type: Boolean, default: true }) private attachToField!: boolean;

  // * REFS
  @Ref('vuetifyComboBoxRef') public readonly vuetifyComboBoxRef!: IValidate;

  // * DATA
  private isRequiredRule = (value: string) => !!value || this.requiredMessage || '';
  private componentId = '';
  // * COMPUTED
  private get showHint() {
    return !!this.hint || !!this.$slots.hint;
  }
  private get textValue() {
    return this.value;
  }

  private set textValue(value: string) {
    this.$emit('input', value);
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }

    return rules;
  }

  // * WATCHERS

  // * METHODS
  public validate() {
    return this.vuetifyComboBoxRef.validate();
  }
  public blur() {
    // v-combobox has a big problem where it won't update the model until you blur the field
    // this is OK when you are selecting from teh list
    // this is OK when the user is typing their own thing, and they move onto the next field
    // this becomes a real problem when the combo box is the only thing the user wants to change, then they hit the save button
    // the model hasn't been updated yet
    // https://github.com/vuetifyjs/vuetify/issues/4679#issuecomment-1363382705 was the best solution to this
    // the consuming code has to call blur, then wait for next tick, then continue with the save

    blurCombos(this.vuetifyComboBoxRef);
  }

  // * LIFECYCLE
  private created() {
    this.componentId = `e-${uuid()}`;
  }
}
