import { DataDrivenConfigModel as ApiDataDrivenConfigModel } from '@/services/generated/api';

export interface IDataDrivenConfig {
  key: string;
  value: string | undefined;
  values: string[] | undefined;
  data?: unknown | undefined;
}

export class OtDataDrivenConfig implements IDataDrivenConfig {
  public key!: string;
  public value: string | undefined;
  public values: string[] | undefined;
  public data: unknown | undefined;

  constructor(value: IDataDrivenConfig) {
    Object.assign(this, value);
  }
  // it looks massively weird to have {{Config.KeyOfAmendmentQuestion.value}} in the handlebars stuff
  // so, cheat a bit and pretend we have a Value property too.
  // we could do ALL Value all the time, but that's ugly when dealing with the actual object
  public get Value() {
    return this.value;
  }
  public get Values() {
    return this.values;
  }
  public get Data() {
    return this.data;
  }

  public static createFromApiResponse(model: ApiDataDrivenConfigModel) {
    return new OtDataDrivenConfig({
      key: model.key || '',
      value: model.value,
      values: model.values,
      data: model.data,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenConfig>) {
    return new OtDataDrivenConfig({
      key: json.key || '',
      value: json.value,
      values: json.values,
      data: json.data,
    });
  }
}
