import {
  CalendarType as ApiCalendarType,
  LayoutType as ApiLayoutType,
  Consequence as ApiConsequence,
  DurationAdjustment as ApiDurationAdjustment,
  SegmentProcessType as ApiSegmentProcessType,
} from '@/services/generated/api';

export enum LayoutTypeEnum {
  Page = 'Page',
  Dialog = 'Dialog',
  ConfirmDialog = 'ConfirmDialog',
}

export const parsedOtLayoutTypeEnum: { [key in ApiLayoutType]: LayoutTypeEnum } = {
  [ApiLayoutType.Page]: LayoutTypeEnum.Page,
  [ApiLayoutType.Dialog]: LayoutTypeEnum.Dialog,
  [ApiLayoutType.ConfirmDialog]: LayoutTypeEnum.ConfirmDialog,
};

export const parsedApiLayoutTypeEnum: { [key in LayoutTypeEnum]: ApiLayoutType } = {
  [LayoutTypeEnum.Page]: ApiLayoutType.Page,
  [LayoutTypeEnum.Dialog]: ApiLayoutType.Dialog,
  [LayoutTypeEnum.ConfirmDialog]: ApiLayoutType.ConfirmDialog,
};

export enum CalendarTypeEnum {
  Working = 'Working',
  Business = 'Business',
  CalendarDays = 'Calendar',
}

export const parsedOtCalendarTypeEnum: { [key in ApiCalendarType]: CalendarTypeEnum } = {
  [ApiCalendarType.Working]: CalendarTypeEnum.Working,
  [ApiCalendarType.Business]: CalendarTypeEnum.Business,
  [ApiCalendarType.CalendarDays]: CalendarTypeEnum.CalendarDays,
};

export const parsedApiCalendarTypeEnum: { [key in CalendarTypeEnum]: ApiCalendarType } = {
  [CalendarTypeEnum.Working]: ApiCalendarType.Working,
  [CalendarTypeEnum.Business]: ApiCalendarType.Business,
  [CalendarTypeEnum.CalendarDays]: ApiCalendarType.CalendarDays,
};

export const CalendarTypesMap = new Map<CalendarTypeEnum, string>([
  [CalendarTypeEnum.Business, 'Business Days'],
  [CalendarTypeEnum.Working, 'Working Days'],
  [CalendarTypeEnum.CalendarDays, 'Calendar Days'],
]);

export const parsedApiCalendarTypes: { [key in CalendarTypeEnum]: ApiCalendarType } = {
  [CalendarTypeEnum.Working]: ApiCalendarType.Working,
  [CalendarTypeEnum.Business]: ApiCalendarType.Business,
  [CalendarTypeEnum.CalendarDays]: ApiCalendarType.CalendarDays,
};

export enum ConsequenceEnum {
  Barred = 'Barred',
  Breach = 'Breach',
  Nil = 'Nil',
  NA = 'N/A',
}

export const parsedOtConsequenceEnum: { [key in ApiConsequence]: ConsequenceEnum } = {
  [ApiConsequence.Barred]: ConsequenceEnum.Barred,
  [ApiConsequence.Breach]: ConsequenceEnum.Breach,
  [ApiConsequence.Nil]: ConsequenceEnum.Nil,
  [ApiConsequence.NA]: ConsequenceEnum.NA,
};

export const parsedApiConsequenceEnum: { [key in ConsequenceEnum]: ApiConsequence } = {
  [ConsequenceEnum.Barred]: ApiConsequence.Barred,
  [ConsequenceEnum.Breach]: ApiConsequence.Breach,
  [ConsequenceEnum.Nil]: ApiConsequence.Nil,
  [ConsequenceEnum.NA]: ApiConsequence.NA,
};

export enum DurationAdjustmentEnum {
  Days = 'Days',
  Percentage = 'Percentage',
}

export const parsedOtDurationAdjustmentEnum: { [key in ApiDurationAdjustment]: DurationAdjustmentEnum } = {
  [ApiDurationAdjustment.Days]: DurationAdjustmentEnum.Days,
  [ApiDurationAdjustment.Percentage]: DurationAdjustmentEnum.Percentage,
};

export const parsedApiDurationAdjustmentEnum: { [key in DurationAdjustmentEnum]: ApiDurationAdjustment } = {
  [DurationAdjustmentEnum.Days]: ApiDurationAdjustment.Days,
  [DurationAdjustmentEnum.Percentage]: ApiDurationAdjustment.Percentage,
};

export const DurationAdjustmentsMap = new Map<DurationAdjustmentEnum, string>([
  [DurationAdjustmentEnum.Days, 'Days'],
  [DurationAdjustmentEnum.Percentage, 'Percentage'],
]);

export enum SegmentProcessTypeEnum {
  Notice = 'Notice',
  Claim = 'Claim',
  Direction = 'Direction',
  RFIInbound = 'RFIInbound',
  RFIOutbound = 'RFIOutbound',
  Variation = 'Variation',
  VariationProposal = 'VariationProposal',
  VariationRequest = 'VariationRequest',
}

export const OtSegmentProcessTypesThatCanAffectPC = new Set([
  SegmentProcessTypeEnum.Claim,
  SegmentProcessTypeEnum.Variation,
  SegmentProcessTypeEnum.VariationRequest,
]);

export const parsedOtSegmentProcessTypeEnum: { [key in ApiSegmentProcessType]: SegmentProcessTypeEnum } = {
  [ApiSegmentProcessType.Notice]: SegmentProcessTypeEnum.Notice,
  [ApiSegmentProcessType.Claim]: SegmentProcessTypeEnum.Claim,
  [ApiSegmentProcessType.Direction]: SegmentProcessTypeEnum.Direction,
  [ApiSegmentProcessType.RFIInbound]: SegmentProcessTypeEnum.RFIInbound,
  [ApiSegmentProcessType.RFIOutbound]: SegmentProcessTypeEnum.RFIOutbound,
  [ApiSegmentProcessType.Variation]: SegmentProcessTypeEnum.Variation,
  [ApiSegmentProcessType.VariationProposal]: SegmentProcessTypeEnum.VariationProposal,
  [ApiSegmentProcessType.VariationRequest]: SegmentProcessTypeEnum.VariationRequest,
};
