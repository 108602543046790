






















































import { IDataDrivenQuestion, OtDataDrivenQuestion } from '@/wf-components/models/data-driven-question';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import OtButton from '@/components/global/ot-button.vue';
import { IDataDrivenResponse, OtDataDrivenResponse } from '@/wf-components/models/data-driven-response';
import { AvailableDisplayModeComponents, AvailableEditModeComponents } from '@/wf-components/workflow-components';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import { IVForm } from '@/utils/type-utils';
import { OtDataDrivenColumnHeader } from '@/wf-components/models/data-driven-column-header';
import { OtDataDrivenOption } from '@/wf-components/models/data-driven-option';
import { OtDataDrivenSubOption } from '@/wf-components/models/data-driven-sub-option';
import { OtDataDrivenDateValue } from '@/wf-components/models/data-driven-result';

@Component({
  components: {
    OtButton,
    OtCheckbox,
  },
})
export default class WorkflowComponentsWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('formRef') private readonly formRef!: IVForm;

  private validate() {
    this.formRef.validate();
  }

  // * DATA
  private componentKey = uuid();
  private value = new OtDataDrivenResponse({
    questionKey: '',
    result: new OtDataDrivenDateValue({
      resultType: 'DateValueModel',
      date: null,
    }),
    systemControlled: false,
  });

  private question = new OtDataDrivenQuestion({
    key: 'EventsAfterPracticalCompletion',
    title: 'What are the events that are relevant after the Date for Practical Completion?',
    description: 'Note that only the events giving rise to the initial workflow are available for selection',
    editTemplate: 'DateSelectorEdit',
    displayTemplate: 'DateSelectorDisplay',
    indentLevel: 0,
    selectedOptionsFromQuestionKeys: null,
    ignoreSelectedOptionsFromQuestionKeys: null,
    isLabelHidden: false,
    columnHeaders: [
      new OtDataDrivenColumnHeader({
        title: 'Header 1',
        columnIndex: 0,
      }),
      new OtDataDrivenColumnHeader({
        title: 'Header 2',
        columnIndex: 1,
      }),
      new OtDataDrivenColumnHeader({
        title: 'Header 3',
        columnIndex: 2,
      }),
    ],
    placeholder: null,
    addButtonText: 'Add new "Other" Event',
    notesTitle: 'Notes',
    notesPlaceholder: 'Notes Placeholder',
    dependsOn: null,
    syncFromQuestion: null,
    isMandatory: true,
    orderIndex: 0,
    options: [
      new OtDataDrivenOption({
        key: 'c88af9ba-b1c0-4099-ab09-1167e3771c94',
        title: 'Option 1',
        orderIndex: 0,
        description: 'Description 1',
        actionKey: null,
        consequence: null,
      }),
      new OtDataDrivenOption({
        key: '9c6f8f90-fdad-4e9d-9ba1-beb23f52a3ae',
        title: 'Option 2',
        orderIndex: 1,
        description: 'Description 2',
        actionKey: null,
        consequence: null,
      }),
      new OtDataDrivenOption({
        key: 'eaceadc1-bd8a-4b2f-a189-530a4e51c267',
        title: 'Option 3',
        orderIndex: 2,
        description: 'Description 3',
        actionKey: null,
        consequence: null,
      }),
    ],
    subOptions: [
      new OtDataDrivenSubOption({
        key: 'Barred',
        title: 'Barred',
      }),
      new OtDataDrivenSubOption({
        key: 'Breach',
        title: 'Breach',
      }),
      new OtDataDrivenSubOption({
        key: 'Nil',
        title: 'Nil',
      }),
    ],
    notesDescription: null,
    configs: null,
  });

  private context2: unknown = { ContextKey: 'ContextValue' };

  private jsonEditorOptions = {
    mode: 'code',
  };

  private isInDisplayMode = false;

  // * COMPUTED
  private get component() {
    if (!this.isInDisplayMode) {
      return `Wf${this.question.editTemplate}`;
    } else {
      return `Wf${this.question.displayTemplate}`;
    }
  }

  private get availableEditComponents() {
    return AvailableEditModeComponents.map(c => c.replace(/^Wf/, ''));
  }

  private get availableDisplayComponents() {
    return AvailableDisplayModeComponents.map(c => c.replace(/^Wf/, ''));
  }

  // * WATCHERS
  @Watch('question')
  private questionChanged() {
    this.reloadComponent();
  }

  // * METHODS
  private reloadComponent() {
    this.componentKey = uuid();
  }

  private setQuestion(object: IDataDrivenQuestion) {
    this.question = OtDataDrivenQuestion.createFromJson(object);
  }
  private setContext(object: unknown) {
    this.context2 = object;
  }

  private setValue(object: IDataDrivenResponse) {
    this.value = OtDataDrivenResponse.createFromJson(object);
  }
  private copyText(text: string) {
    navigator.clipboard.writeText(text);
  }

  // * LIFECYCLE
}
