import ChevronDown from '@/assets/icons/icon-chevron-down.vue';
import ChevronLeft from '@/assets/icons/icon-chevron-left.vue';
import ChevronRight from '@/assets/icons/icon-chevron-right.vue';
import ChevronUp from '@/assets/icons/icon-chevron-up.vue';
import ChevronsLeft from '@/assets/icons/icon-chevrons-left.vue';
import ChevronsRight from '@/assets/icons/icon-chevrons-right.vue';
import EyeOff from '@/assets/icons/icon-eye-off.vue';
import Eye from '@/assets/icons/icon-eye.vue';
import CheckboxOutline from '@/assets/icons/icon-checkbox-outline.vue';
import CheckboxIndeterminate from '@/assets/icons/icon-checkbox-indeterminate.vue';
import CheckboxChecked from '@/assets/icons/icon-checkbox-checked.vue';
import RadioOff from '@/assets/icons/icon-radio-off.vue';
import RadioOn from '@/assets/icons/icon-radio-on.vue';
import Maximize from '@/assets/icons/icon-maximize.vue';
import Search from '@/assets/icons/icon-search.vue';
import MoreVertical from '@/assets/icons/icon-more-vertical.vue';
import MoreHorizontal from '@/assets/icons/icon-more-horizontal.vue';
import Send from '@/assets/icons/icon-send.vue';
import Server from '@/assets/icons/icon-server.vue';
import MapPin from '@/assets/icons/icon-map-pin.vue';
import ArrowLeft from '@/assets/icons/icon-arrow-left.vue';
import ArrowRight from '@/assets/icons/icon-arrow-right.vue';
import Calendar from '@/assets/icons/icon-calendar.vue';
import Cross from '@/assets/icons/icon-cross.vue';
import AlertTriangle from '@/assets/icons/icon-alert-triangle.vue';
import PieChart from '@/assets/icons/icon-pie-chart.vue';
import Folder from '@/assets/icons/icon-folder.vue';
import Edit from '@/assets/icons/icon-edit.vue';
import AlertOctagon from '@/assets/icons/icon-alert-octagon.vue';
import TextFile from '@/assets/icons/icon-text-file.vue';
import Settings from '@/assets/icons/icon-settings.vue';
import User from '@/assets/icons/icon-user.vue';
import DollarSign from '@/assets/icons/icon-dollar-sign.vue';
import PercentSign from '@/assets/icons/icon-percent-sign.vue';
import ColoredCross from '@/assets/icons/icon-colored-cross.vue';
import ColoredCheck from '@/assets/icons/icon-colored-check.vue';
import BlueCheck from '@/assets/icons/icon-blue-check.vue';
import Clock from '@/assets/icons/icon-clock.vue';
import Plus from '@/assets/icons/icon-plus.vue';
import Info from '@/assets/icons/icon-info.vue';
import MonochromeCross from '@/assets/icons/icon-monochrome-cross.vue';
import TriangleDown from '@/assets/icons/icon-triangle-down.vue';

export default {
  chevronDown: {
    component: ChevronDown,
    props: {
      name: 'chevronDown',
    },
  },
  chevronLeft: {
    component: ChevronLeft,
    props: {
      name: 'chevronLeft',
    },
  },
  chevronRight: {
    component: ChevronRight,
    props: {
      name: 'chevronRight',
    },
  },
  chevronUp: {
    component: ChevronUp,
    props: {
      name: 'chevronUp',
    },
  },
  chevronsLeft: {
    component: ChevronsLeft,
    props: {
      name: 'chevronsLeft',
    },
  },
  chevronsRight: {
    component: ChevronsRight,
    props: {
      name: 'chevronsRight',
    },
  },
  eyeOff: {
    component: EyeOff,
    props: {
      name: 'eyeOff',
    },
  },
  eye: {
    component: Eye,
    props: {
      name: 'eye',
    },
  },
  checkboxOutline: {
    component: CheckboxOutline,
    props: {
      name: 'checkboxOutline',
      // We can set a custom css variable in the component to override the individual colors
      // so we can have 2 overridable colors instead of just one.
      // If the variables don't exist then it'll fall back to 'customColor' and inherit from the v-icon color
      // https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties#custom_property_fallback_values
      stroke: 'var(--icon-stroke-color, currentColor)',
      fill: 'var(--icon-fill-color, none)',
    },
  },
  checkboxIndeterminate: {
    component: CheckboxIndeterminate,
    props: {
      name: 'checkboxIndeterminate',
      stroke: 'var(--icon-stroke-color, currentColor)',
      fill: 'var(--icon-fill-color, none)',
    },
  },
  checkboxChecked: {
    component: CheckboxChecked,
    props: {
      name: 'checkboxChecked',
      stroke: 'var(--icon-stroke-color, currentColor)',
      fill: 'var(--icon-fill-color, none)',
      checkStroke: 'var(--icon-check-color, currentColor)',
    },
  },
  radioOff: {
    component: RadioOff,
    props: {
      name: 'radioOff',
      stroke: 'var(--icon-stroke-color, currentColor)',
      fill: 'var(--icon-fill-color, none)',
    },
  },
  radioOn: {
    component: RadioOn,
    props: {
      name: 'radioOn',
      stroke: 'var(--icon-stroke-color, currentColor)',
      fill: 'var(--icon-fill-color, none)',
      fillInner: 'var(--icon-fill-inner-color, currentColor)',
    },
  },
  maximize: {
    component: Maximize,
    props: {
      name: 'maximize',
    },
  },
  search: {
    component: Search,
    props: {
      name: 'search',
    },
  },
  moreHorizontal: {
    component: MoreHorizontal,
    props: {
      name: 'moreHorizontal',
    },
  },
  moreVertical: {
    component: MoreVertical,
    props: {
      name: 'moreVertical',
    },
  },
  send: {
    component: Send,
    props: {
      name: 'send',
    },
  },
  server: {
    component: Server,
    props: {
      name: 'server',
    },
  },
  mapPin: {
    component: MapPin,
    props: {
      name: 'mapPin',
    },
  },
  arrowLeft: {
    component: ArrowLeft,
    props: {
      name: 'arrowLeft',
    },
  },
  arrowRight: {
    component: ArrowRight,
    props: {
      name: 'arrowRight',
    },
  },
  calendar: {
    component: Calendar,
    props: {
      name: 'calendar',
    },
  },
  cross: {
    component: Cross,
    props: {
      name: 'cross',
    },
  },
  alertTriangle: {
    component: AlertTriangle,
    props: {
      name: 'alertTriangle',
    },
  },
  pieChart: {
    component: PieChart,
    props: {
      name: 'pieChart',
    },
  },
  folder: {
    component: Folder,
    props: {
      name: 'folder',
    },
  },
  edit: {
    component: Edit,
    props: {
      name: 'edit',
    },
  },
  alertOctagon: {
    component: AlertOctagon,
    props: {
      name: 'alertOctagon',
    },
  },
  textFile: {
    component: TextFile,
    props: {
      name: 'textFile',
    },
  },
  settings: {
    component: Settings,
    props: {
      name: 'settings',
    },
  },
  user: {
    component: User,
    props: {
      name: 'user',
    },
  },
  dollarSign: {
    component: DollarSign,
    props: {
      name: 'dollarSign',
    },
  },
  percentSign: {
    component: PercentSign,
    props: {
      name: 'percentSign',
    },
  },
  coloredCross: {
    component: ColoredCross,
    props: {
      name: 'coloredCross',
    },
  },
  coloredCheck: {
    component: ColoredCheck,
    props: {
      name: 'coloredCheck',
    },
  },
  blueCheck: {
    component: BlueCheck,
    props: {
      name: 'blueCheck',
    },
  },
  clock: {
    component: Clock,
    props: {
      name: 'clock',
    },
  },
  plus: {
    component: Plus,
    props: {
      name: 'plus',
    },
  },
  info: {
    component: Info,
    props: {
      name: 'info',
    },
  },
  monochromeCross: {
    component: MonochromeCross,
    props: {
      name: 'monochromeCross',
    },
  },
  triangleDown: {
    component: TriangleDown,
    props: {
      name: 'triangleDown',
    },
  },
};
