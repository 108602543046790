// Data Driven Rule Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2384593067/Data+Driven+Rule+Object

import { DataDrivenRuleModel as ApiDataDrivenRuleModel } from '@/services/generated/api';

export interface IDataDrivenRule {
  key: string;
  title: string;
  dependsOnOptions: string[][] | null;
}

export class OtDataDrivenRule implements IDataDrivenRule {
  public key!: string;
  public title!: string;
  public dependsOnOptions!: string[][] | null;

  constructor(value: IDataDrivenRule) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenRuleModel) {
    return new OtDataDrivenRule({
      key: model.key,
      title: model.title,
      dependsOnOptions: model.dependsOnOptions || null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenRule>) {
    return new OtDataDrivenRule({
      key: model.key || '',
      title: model.title || '',
      dependsOnOptions: model.dependsOnOptions || null,
    });
  }
}
