<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" style="">
    <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" class="" style="" />

    <defs>
      <filter
        id="colored_cross_filter"
        x="0"
        y="2"
        width="94.51090240478516"
        height="94.51090240478516"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18" />
        <feGaussianBlur stdDeviation="16" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.17 0" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
    </defs>
    <g class="currentLayer" style="">
      <title>Layer 1</title>
      <rect
        x="-6.073684349656105"
        y="25.02280654758215"
        width="43.840599060058594"
        height="43.840599060058594"
        rx="21.920299530029297"
        transform="rotate(-45 -6.073684215545656,25.02280807495117) "
        fill="#E0E0E0"
        id="svg_1"
        class=""
      />
      <g filter="url(#colored_cross_filter)" id="svg_2" class="">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.60871573230028,39.36210744165182 C35.170815732300284,40.924207441651824 37.70351573230028,40.924207441651824 39.26561573230028,39.36210744165182 C40.827715732300284,37.80000744165182 40.827715732300284,35.26740744165182 39.26561573230028,33.70530744165182 L30.838615732300283,25.278207441651823 L39.26561573230028,16.85120744165182 C40.827715732300284,15.289107441651822 40.827715732300284,12.75650744165182 39.26561573230028,11.194407441651823 C37.70351573230028,9.632307441651822 35.170815732300284,9.632307441651822 33.60881573230028,11.194407441651823 L25.181715732300283,19.621407441651822 L16.754715732300284,11.194407441651823 C15.192615732300283,9.632307441651822 12.66001573230028,9.632307441651822 11.09791573230028,11.194407441651823 C9.535815732300282,12.75650744165182 9.535815732300282,15.289107441651822 11.09791573230028,16.85120744165182 L19.52491573230028,25.278207441651823 L11.09791573230028,33.70530744165182 C9.535815732300282,35.26730744165182 9.535815732300282,37.80000744165182 11.09791573230028,39.36210744165182 C12.66001573230028,40.924207441651824 15.192615732300283,40.924207441651824 16.754715732300284,39.36210744165182 L25.181715732300283,30.935107441651823 L33.60871573230028,39.36210744165182 z"
          fill="#616161"
          id="svg_3"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
