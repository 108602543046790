
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Holiday } from '../models/calendar-models';
import { formatDate } from '@/utils/date-utils';
import OtButton from '@/components/global/ot-button.vue';
import { DangerModalParams } from '@/components/global/modal/form-modal-models';
import { vxm } from '@/store';

@Component({
  components: {
    OtButton,
  },
})
export default class OtCalendarHolidayTableRow extends Vue {
  // * PROPS
  @Prop(String) private calendarGid?: string;
  @Prop() private holiday!: Holiday;

  // * REFS

  // * DATA

  // * COMPUTED
  private get isInherited() {
    return this.calendarGid && this.calendarGid !== this.holiday.calendarGid;
  }

  private get formattedDates() {
    return this.holiday.dates.map(d => formatDate(d.date)).join(', ');
  }

  // * WATCHERS

  // * METHODS
  private async removeHoliday() {
    const params = new DangerModalParams({
      title: 'Are you sure you want to remove this holiday?',
      message: `Are you sure you want to remove ${this.holiday.name}? You won't be able to restore this holiday after this calendar has been saved.`,
      confirmText: 'Remove Holiday',
    });
    const result = await vxm.modal.openDangerModal(params);
    if (result.ok) {
      this.$emit('removeHoliday', this.holiday);
    }
  }

  private editHoliday() {
    this.$emit('editHoliday', this.holiday);
  }

  // * LIFECYCLE
}
