





















































import { Component, Vue, Ref } from 'vue-property-decorator';
import { FormModalResult, FormModalParams, FormModalSizeEnum } from './form-modal-models';
import styles from '@/styles/variables.scss';
import { IVForm } from '@/utils/type-utils';

@Component({})
export default class OtFormModal extends Vue {
  // * PROPS

  // * REFS
  @Ref('modalContentRef') private modalContentRef!: HTMLDivElement;

  // * DATA
  // Default params
  private title = 'Title';
  private confirmClass = '';
  private cancelClass = '';
  private confirmText = 'Confirm';
  private cancelText = 'Cancel';
  private persistent = true;
  private hideOverlay = false;
  private size: FormModalSizeEnum = FormModalSizeEnum.Regular;
  private confirmColor = styles.otColorBlue500;
  private cancelColor = styles.otColorBlue200;
  private bodyContainerCss: { [prop: string]: string } = {};
  // this allows the parent to intercept the click of the close button
  private onBeforeConfirmClose?: () => Promise<boolean>;
  // this allows the parent to do custom validation
  public onValidate?: () => Promise<boolean>;

  // allows the parent to load data after the form is on screen. Probably to show a spinner
  private onAfterFormMounted?: () => void;
  private formRef: IVForm | null = null;

  private openParams: FormModalParams | null = null;
  private resolve!: (value: FormModalResult | PromiseLike<FormModalResult>) => void;
  private reject!: () => void;

  private showModalLocal = false;
  private isConfirming = false;

  // * COMPUTED
  private get effectiveTitle(): string {
    return this.openParams?.title ? this.openParams.title : this.title;
  }
  private get effectiveConfirmClass(): string {
    return this.openParams?.confirmClass ? this.openParams?.confirmClass : this.confirmClass;
  }
  private get effectiveCancelClass(): string {
    return this.openParams?.cancelClass ? this.openParams?.cancelClass : this.cancelClass;
  }
  private get effectiveConfirmText(): string {
    return this.openParams?.confirmText ? this.openParams?.confirmText : this.confirmText;
  }
  private get effectiveCancelText(): string {
    return this.openParams?.cancelText ? this.openParams?.cancelText : this.cancelText;
  }
  private get effectivePersistent(): boolean {
    return this.openParams?.persistent ? this.openParams?.persistent : this.persistent;
  }
  private get effectiveHideOverlay(): boolean {
    return this.openParams?.hideOverlay ? this.openParams?.hideOverlay : this.hideOverlay;
  }
  private get effectiveSize(): FormModalSizeEnum {
    return this.openParams?.size ? this.openParams?.size : this.size;
  }
  private get effectiveConfirmColor(): string {
    return this.openParams?.confirmColor ? this.openParams?.confirmColor : this.confirmColor;
  }
  private get effectiveCancelColor(): string {
    return this.openParams?.cancelColor ? this.openParams?.cancelColor : this.cancelColor;
  }
  private get effectiveBodyContainerCss(): { [prop: string]: string } {
    return this.openParams?.bodyContainerCss ? this.openParams?.bodyContainerCss : this.bodyContainerCss;
  }
  private get effectiveOnBeforeConfirmClose(): (() => Promise<boolean>) | undefined {
    return this.openParams?.onBeforeConfirmClose ? this.openParams?.onBeforeConfirmClose : this.onBeforeConfirmClose;
  }
  private get effectiveOnValidate(): (() => Promise<boolean>) | undefined {
    return this.openParams?.onValidate ? this.openParams?.onValidate : this.onValidate;
  }
  private get effectiveOnAfterFormMounted(): (() => void) | undefined {
    return this.openParams?.onAfterFormMounted ? this.openParams?.onAfterFormMounted : this.onAfterFormMounted;
  }
  private get effectiveHideCancelButton(): boolean {
    return this.openParams?.hideCancelButton ? this.openParams?.hideCancelButton : false;
  }

  private get modalWidth(): string {
    if (this.isModalDynamicWidth) {
      return '';
    }
    switch (this.effectiveSize) {
      case FormModalSizeEnum.Max:
        return '90vw';
      case FormModalSizeEnum.ExtraLarge:
        return '1140';
      case FormModalSizeEnum.Large:
        return '1024';
      case FormModalSizeEnum.Regular:
      default:
        return '768';
    }
  }

  private get isModalDynamicWidth() {
    return this.effectiveSize === FormModalSizeEnum.Auto;
  }

  // * WATCHERS

  // * METHODS
  public open(params?: FormModalParams) {
    this.$emit('open');
    this.openParams = params || null;

    // ----- Insert the v-form ref into the modal content -----
    if (this.openParams?.formRef) {
      // Keep track of the form reference so we can validate it
      this.formRef = this.openParams.formRef;
      // Clear the content from anything that is left over from previous uses.
      this.modalContentRef.innerHTML = '';
      // Add the v-form element to the content of this modal.
      this.modalContentRef.appendChild(this.openParams.formRef.$el);
      // Adding this class to the child
      this.modalContentRef.firstElementChild?.classList.add('--visible');
    }

    this.showModalLocal = true;
    if (this.effectiveOnAfterFormMounted) {
      this.effectiveOnAfterFormMounted();
    }
    return new Promise<FormModalResult>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public close(result: FormModalResult) {
    this.$emit('close', result);
    this.resolve(result);
    this.formRef = null;
    this.showModalLocal = false;
  }

  // this is when the user clicks the OK button in the dialog
  // all we want to do here is indicate that the user clicked ok
  // and resolve the promise which was started when the coder
  // called open() on us. This'll get the result of ok:true back to them
  // and their code that has been awaiting this can continue on
  private async ok() {
    // Validate the form and only proceed if it is valid

    // custom validation trumps the baked in validation
    // it's up to the implementer to call the standard form validation
    if (this.effectiveOnValidate && !(await this.effectiveOnValidate())) {
      return;
    } else if (this.formRef && !this.formRef.validate()) {
      return;
    }

    let shouldClose = true;
    if (this.effectiveOnBeforeConfirmClose) {
      this.isConfirming = true;
      shouldClose = await this.effectiveOnBeforeConfirmClose();
      this.isConfirming = false;
    }
    if (shouldClose) {
      const result = new FormModalResult({ ok: true });
      this.close(result);
    }
  }

  private cancel() {
    const result = new FormModalResult({ ok: false });
    this.close(result);
  }

  private clickedOutside() {
    if (!this.effectivePersistent) {
      const result = new FormModalResult({ ok: false });
      this.close(result);
    }
  }

  // * LIFECYCLE
}
