


































import { Component, Prop, Vue } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtPagination from '@/components/global/ot-pagination.vue';
import { IInputIcon } from '../common-models';

@Component({
  components: {
    OtTextField,
    OtPagination,
  },
})
export default class OtTable extends Vue {
  // * PROPS
  @Prop(Boolean) private pagination!: boolean;
  @Prop(String) private keywordSearchPlaceholder!: string;
  @Prop(String) private keywordSearchValue!: string;
  @Prop(Number) private maxPages!: number;
  @Prop(Number) private currentPage!: number;

  // * DATA
  private icon: IInputIcon = {
    icon: '$search',
    iconColor: 'black',
    action: null,
  };

  // * COMPUTED
  private get keywordSearchVal() {
    return this.keywordSearchValue;
  }

  private set keywordSearchVal(value: string) {
    this.$emit('update:keywordSearchValue', value);
  }

  private get showFilter() {
    return !!this.$slots.filter;
  }

  private get pageCount() {
    let currentMaxPage = this.maxPages;
    if (this.currentPage > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.maxPages - 1;
      this.$emit('updateCurrentPage', currentMaxPage);
    }
    return currentMaxPage;
  }

  private get modeledPage() {
    return this.currentPage;
  }

  private set modeledPage(value: number) {
    this.$emit('updateCurrentPage', value);
  }
  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
