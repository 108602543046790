















import { OtTrafficLightColour } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { formatDate } from '@/utils/date-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IStatusOptions {
  dotColor?: string;
  textColor?: string;
  backColor?: string;
}

@Component({})
export default class OtDueDateLabel extends Vue {
  // * PROPS
  @Prop() private dueDate!: ZonelessDate;
  // don't strictly need this since we added the traffic light colour prop. Could add a tooltip one day. Will leave it here
  @Prop() private dueDateTimezone!: string;
  @Prop() private trafficLightColour!: OtTrafficLightColour;
  @Prop({ default: true }) private colourByStatus!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get dateFormatted() {
    return formatDate(this.dueDate);
  }

  private get shouldShowDueDateBadge() {
    return this.dueDate !== null;
  }

  private get dueDateBadgeClass() {
    if (!this.shouldShowDueDateBadge) {
      return '';
    }
    if (!this.colourByStatus) {
      return '--neutral';
    }
    switch (this.trafficLightColour) {
      case OtTrafficLightColour.Green:
        return '--open';
      case OtTrafficLightColour.Amber:
        return '--deadline-approaching';
      case OtTrafficLightColour.Red:
        return '--overdue';
      default:
        return '';
    }
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
