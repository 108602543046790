import { render, staticRenderFns } from "./workflow-additional-information-dialog-wrapper.vue?vue&type=template&id=32beac80&scoped=true&"
import script from "./workflow-additional-information-dialog-wrapper.vue?vue&type=script&lang=ts&"
export * from "./workflow-additional-information-dialog-wrapper.vue?vue&type=script&lang=ts&"
import style0 from "./workflow-additional-information-dialog-wrapper.vue?vue&type=style&index=0&id=32beac80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32beac80",
  null
  
)

export default component.exports