





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IWorkflowComponentDisplayDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import WfCheckboxListWithActionEdit from '@/wf-components/edit-mode/wf-checkbox-list-with-action-edit.vue';
import { IDataDrivenOption } from '../models/data-driven-option';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    WfCheckboxListWithActionEdit,
  },
})
export default class WfCheckboxListWithActionDisplay extends Vue implements IWorkflowComponentDisplayDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private optionAction(foundOption: IDataDrivenOption) {
    this.$emit('optionAction', foundOption);
  }
  private setDialogDefaults(layout: string) {
    this.$emit('setDialogDefaults', layout);
  }
  // * LIFECYCLE
}
