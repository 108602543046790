// return a concat string for the IAutocompleteItem<IAddressDisplayModel> like this

// "12 This St, 2nd Address input, Suburb, State, Postcode"

// if the addressLine2 is empty then return it like this

// 12 This St, Suburb, State, Postcode

import { IAddressDisplayModel } from '@/components/global/common-models';
import { StreetAddressModel } from '@/models/shared-models';

export const returnAddressString = (address: IAddressDisplayModel | StreetAddressModel) => {
  const { addressLine1, addressLine2, suburb, state, postcode } = address;

  // make sure all the properties are not null or undefined
  if (!addressLine1 || !suburb || !state || !postcode) {
    console.error('returnAddressString => address is missing a property', address);
    if (!addressLine1) {
      console.error('returnAddressString => address is missing the property addressLine1');
    }
    if (!suburb) {
      console.error('returnAddressString => address is missing the property suburb');
    }
    if (!state) {
      console.error('returnAddressString => address is missing the property state');
    }
    if (!postcode) {
      console.error('returnAddressString => address is missing the property postcode');
    }
    return '';
  }

  return `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}, ${suburb}, ${state}, ${postcode}`;
};
