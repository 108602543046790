import { DataDrivenSummaryModel as ApiDataDrivenSummaryModel } from '@/services/generated/api';

export interface IDataDrivenSummary {
  key: string;
  label: string | null;
  value: string | null;
}

export class OtDataDrivenSummary implements IDataDrivenSummary {
  public key!: string;
  public label!: string | null;
  public value!: string | null;

  constructor(value: IDataDrivenSummary) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenSummaryModel) {
    return new OtDataDrivenSummary({
      key: model.key,
      label: model.label ?? null,
      value: model.value ?? null,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenSummary>) {
    return new OtDataDrivenSummary({
      key: json.key ?? '',
      label: json.label ?? null,
      value: json.value ?? null,
    });
  }
}
