import { IUserStatusWithActions, UserStatusWithActions } from '@/models/user-models';
import {
  GetOrganisationUserDetailsResponseModel,
  PostUpdateOrganisationUserDetailsRequestModel,
  UserInformationModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { LastModified } from '@/utils/type-utils';
import { AccessType, StreetAddressModel } from '@/models/shared-models';

export interface IOrganisationUser {
  gid: string;
  firstName: string;
  lastName: string;
  email: string;
  organisations: IUserStatusWithActions[];
  projects: IUserStatusWithActions[];
  contracts: IUserStatusWithActions[];
}

export class OrganisationUser implements IOrganisationUser {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public organisations!: UserStatusWithActions[];
  public projects!: UserStatusWithActions[];
  public contracts!: UserStatusWithActions[];

  public get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  public constructor(value: IOrganisationUser) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: UserInformationModel): OrganisationUser {
    return new OrganisationUser({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      organisations: model.organisations.map(org => UserStatusWithActions.createFromApiResponse(org)),
      projects: model.projects.map(project => UserStatusWithActions.createFromApiResponse(project)),
      contracts: model.contracts.map(contract => UserStatusWithActions.createFromApiResponse(contract)),
    });
  }

  public static compareByName(a: OrganisationUser, b: OrganisationUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const fullNameA = a.fullName;
    const fullNameB = b.fullName;
    return fullNameA.localeCompare(fullNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByEmail(a: OrganisationUser, b: OrganisationUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.email.localeCompare(b.email, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByJobTitle(a: OrganisationUser, b: OrganisationUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const jobTitleA = a.organisations[0].title;
    const jobTitleB = b.organisations[0].title;
    return jobTitleA.localeCompare(jobTitleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, user: OrganisationUser) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      // TODO this needs checking
      return user.firstName.match(regex) || user.lastName.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], user: OrganisationUser) {
    if (statuses.length) {
      return statuses.some(
        status =>
          user.organisations.some(org => org.status === status) ||
          user.projects.some(project => project.status === status) ||
          user.contracts.some(contract => contract.status === status),
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], user: OrganisationUser) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return user.organisations.length > 0;
          case AccessType.Projects:
            return user.projects.length > 0;
          case AccessType.Contracts:
            return user.contracts.length > 0;
          default:
            return false;
        }
      });
    }
    return true;
  }
}

export interface IOrganisationOrganisationUser {
  users: UserInformationModel;
}

export interface IOrganisationUserDetails {
  gid: string;
  firstName: string;
  lastName: string;
  businessName: string | null;
  email: string;
  streetAddress: StreetAddressModel | null;
  postalAddress: StreetAddressModel | null;
  mobile: string | null;
  phone: string | null;
  fax: string | null;
  organisationUserStatus?: UserStatusWithActions;
  organisationGid: string;
  organisationName: string;
  lastModified: LastModified;
  lastLoginUtc?: ZonelessDate | null;
  lastLoginIpAddress?: string | undefined;
}

export class OrganisationUserDetails implements IOrganisationUserDetails {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public businessName: string | null = null;
  public email!: string;
  public streetAddress: StreetAddressModel | null = null;
  public postalAddress: StreetAddressModel | null = null;
  public mobile: string | null = null;
  public phone: string | null = null;
  public fax: string | null = null;
  public organisationUserStatus?: UserStatusWithActions;
  public organisationGid!: string;
  public organisationName!: string;
  public lastModified!: LastModified;
  public lastLoginUtc?: ZonelessDate | null = null;
  public lastLoginIpAddress?: string | undefined;

  public constructor(value: IOrganisationUserDetails) {
    Object.assign(this, value);
  }

  public static createEmpty(): OrganisationUserDetails {
    return new OrganisationUserDetails({
      gid: '',
      firstName: '',
      lastName: '',
      businessName: null,
      email: '',
      streetAddress: null,
      postalAddress: null,
      mobile: null,
      phone: null,
      fax: null,
      organisationUserStatus: UserStatusWithActions.createEmpty(),
      organisationGid: '',
      organisationName: '',
      lastModified: LastModified.createEmpty(),
      lastLoginUtc: new ZonelessDate(),
      lastLoginIpAddress: '',
    });
  }

  public static createFromApiResponse(model: GetOrganisationUserDetailsResponseModel): OrganisationUserDetails {
    return new OrganisationUserDetails({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      businessName: model.businessName ?? null,
      email: model.email,
      streetAddress: model.streetAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.streetAddress) : null,
      postalAddress: model.postalAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.postalAddress) : null,
      mobile: model.mobile ?? null,
      phone: model.phone ?? null,
      fax: model.fax ?? null,
      organisationUserStatus: model.organisationUserStatus
        ? UserStatusWithActions.createFromApiResponse(model.organisationUserStatus)
        : undefined,
      organisationGid: model.organisationGid,
      organisationName: model.organisationName,
      lastModified: model.lastModified
        ? LastModified.createFromApiResponse(model.lastModified)
        : LastModified.createEmpty(),
      lastLoginUtc: model.lastLoginUtc ? new ZonelessDate(model.lastLoginUtc) : null,
      lastLoginIpAddress: model.lastLoginIpAddress,
    });
  }
}

export interface IOrganisationUserDetailsFormObject {
  jobTitle: string;
}

export class OrganisationUserDetailsFormObject implements IOrganisationUserDetailsFormObject {
  public jobTitle!: string;

  public constructor(value: IOrganisationUserDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty(): OrganisationUserDetailsFormObject {
    return new OrganisationUserDetailsFormObject({
      jobTitle: '',
    });
  }

  public static createFromApiResponse(
    model: GetOrganisationUserDetailsResponseModel,
  ): OrganisationUserDetailsFormObject {
    return new OrganisationUserDetailsFormObject({
      jobTitle: model.organisationUserStatus ? model.organisationUserStatus.title : '',
    });
  }

  public static createRequestModel(
    model: OrganisationUserDetailsFormObject,
  ): PostUpdateOrganisationUserDetailsRequestModel {
    return new PostUpdateOrganisationUserDetailsRequestModel({
      jobTitle: model.jobTitle,
    });
  }
}
