
















import { Component, Vue } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtPagination from '@/components/global/ot-pagination.vue';

@Component({
  components: {
    OtTextField,
    OtPagination,
  },
})
export default class OtPaginationWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private page1 = 1;
  private page2 = 1;
  private pageCount = 7;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
