import styles from '@/styles/variables.scss';
import icons from '@/assets/icons-index';
import { UserVuetifyPreset } from 'vuetify';

export const config: Partial<UserVuetifyPreset> = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: styles.otColorBlue500,
        secondary: styles.otColorOrange500,
        success: styles.otColorGreen300,
        warning: styles.otColorYellow300,
        error: styles.otColorDanger,
        'ot-amber': styles.otColorAmber300, //unable to override the built in amber, so this'll do, prefix with ot-
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: icons,
  },
};
