
import { CreateElement, VNode } from 'vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class OtWorkflowQuestionIndent extends Vue {
  // * PROPS
  @Prop() private indentLevel!: number;

  // * REFS

  // * DATA

  // * COMPUTED
  private get parentClassName() {
    return 'ot-workflow-question-indent';
  }

  // * WATCHERS

  // * METHODS
  private createIndents(createElement: CreateElement, currentIndentLevel: number, children: VNode[]): VNode {
    if (currentIndentLevel < this.indentLevel) {
      return this.createIndents(createElement, currentIndentLevel + 1, [
        createElement(
          'div',
          {
            class: `${this.parentClassName}__indent`,
          },
          children,
        ),
      ]);
    } else {
      return createElement(
        'div',
        {
          staticClass: this.parentClassName,
          class: {
            'is-indented': this.indentLevel > 0,
          },
        },
        children,
      );
    }
  }

  // * LIFECYCLE
  render(createElement: CreateElement): VNode {
    // Note: We cannot use a getter for the slot
    // If you use a getter than it will create some issues with the dom
    // not updating properly from prop changes on the slot components
    return this.createIndents(createElement, 0, this.$slots.default || []);
  }
}
