



















































import { Component, Vue } from 'vue-property-decorator';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { OtCalendarStatus } from '@/areas/settings/models/calendar-models';

@Component({
  components: {
    OtCheckboxGroup,
    OtTextarea,
  },
})
export default class OtCheckboxGroupWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private items: CheckboxGroupItem<OtCalendarStatus>[] = [
    new CheckboxGroupItem('Active', OtCalendarStatus.Active),
    new CheckboxGroupItem('Inactive', OtCalendarStatus.Inactive),
  ];
  private value: CheckboxGroupItem<OtCalendarStatus>[] = [];

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
