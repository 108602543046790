






















import { IVForm } from '@/utils/type-utils';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { FormModalParams } from '../global/modal/form-modal-models';
import OtFormModal from '../global/modal/ot-form-modal.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtFormattableTextarea from '@/components/global/ot-formattable-textarea.vue';

export class StartRfiOutboundDialogResult {
  public doCreateRfiOutbound = false;

  constructor(params?: Partial<StartRfiOutboundDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

@Component({
  components: {
    OtTextField,
    OtFormModal,
    OtFormattableTextarea,
  },
})
export default class OtStartRfiOutboundDialog extends Vue {
  // * PROPS

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  public async open(): Promise<StartRfiOutboundDialogResult> {
    this.modalContentRef.resetValidation();

    const baseParams = new FormModalParams({
      title: `Warning`,
      formRef: this.modalContentRef,
      confirmText: `Yes, Continue`,
      cancelText: `Dismiss`,
      hideCancelButton: false,
    });
    const result = await this.formModalRef.open(baseParams);
    return new StartRfiOutboundDialogResult({
      doCreateRfiOutbound: result.ok,
    });
  }

  // * LIFECYCLE
}
