


































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import converter from 'number-to-words';
import { capitalizeString } from '@/utils/string-utils';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { FormModalResult } from '@/components/global/modal/form-modal-models';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { IConfirmValueChangeEmitobject } from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import _ from 'lodash';

@Component({
  components: {
    OtCheckboxGroup,
  },
})
export default class WfCheckboxListWithActionEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public layoutKey!: string;

  // * REFS
  @Ref('checkboxGroupRef') private readonly checkboxSetRef!: OtCheckboxGroup;

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private requiredMessage: string | null = null;

  private optionKeyCustomisationState: { [key: string]: boolean } = {};

  // * COMPUTED
  private get items(): CheckboxGroupItem<string>[] {
    return (
      this.question.optionsOrdered.map(o => {
        let subText: string | null = null;
        if (o.actionKey && this.selectedValueKeys.includes(o.key)) {
          if (this.optionKeyCustomisationState[o.key]) {
            subText = '(Customised options)';
          } else {
            subText = '(Default options)';
          }
        }
        const foundInternalValue = this.internalValue.find(v => v.value === o.key);
        return new CheckboxGroupItem(
          o.title,
          o.key,
          o.actionKey ? this.actionButtonText : null,
          subText,
          foundInternalValue?.error || null,
        );
      }) || []
    );
  }

  private get actionButtonText() {
    return this.readonly ? 'View' : 'Edit';
  }

  private get selectedValueKeys() {
    return this.result?.values.map(o => o.key) || [];
  }

  private internalValuePrivate: CheckboxGroupItem<string>[] = [];
  private get internalValue(): CheckboxGroupItem<string>[] {
    return this.internalValuePrivate;
  }
  private set internalValue(val: CheckboxGroupItem<string>[]) {
    this.internalValuePrivate = val;
    this.onChange();
  }

  private get summary() {
    if (this.question.isLabelHidden) {
      return null;
    }

    const selectedCount = this.internalValue.length;
    const modifiedCount = Object.values(this.optionKeyCustomisationState).filter(v => v === true).length;
    const selectedCountText = capitalizeString(converter.toWords(selectedCount));
    const modifiedCountText = converter.toWords(modifiedCount);
    return `${selectedCountText} (${selectedCount}) ${
      selectedCount === 1 ? 'event has' : 'events have'
    } have been selected for this contract, ${modifiedCountText} (${modifiedCount}) of them ${
      modifiedCount === 1 ? 'has' : 'have'
    } been customised`;
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-checkbox-list-with-action-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get selectedOptions() {
    return (this.value?.result as OtDataDrivenOptionsList).values || [];
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const val = this.result?.values.map(v => this.findCheckboxItemFromKey(v.key)) || [];
    this.internalValuePrivate = val.filter(i => i !== undefined) as CheckboxGroupItem<string>[];
  }

  @Watch('disabled')
  private disabledChanged() {
    if (this.disabled) {
      this.checkboxSetRef.resetManualErrorState();
    }
  }

  // * METHODS
  private findCheckboxItemFromKey(key: string): CheckboxGroupItem<string> | undefined {
    return this.items.find(i => i.value === key);
  }

  private relatedComponentResponses(): IResponsesGroupedByLayoutKey[] {
    const relatedComponentResponses: IResponsesGroupedByLayoutKey[] = [];

    const relatedActionKeys =
      this.question.optionsOrdered?.filter(o => !!o.actionKey).flatMap(o => o.actionKey as string) || [];

    // Find all the question keys related to the layouts
    for (const actionKey of relatedActionKeys) {
      const foundLayout = this.definition.layoutsOrdered.find(l => l.key === actionKey);
      if (foundLayout) {
        const relatedQuestionKeys = foundLayout.sectionsOrdered.flatMap(s => s.questionsOrdered.map(q => q.key));
        const relatedQuestions = this.instance.responses.filter(r => relatedQuestionKeys.includes(r.questionKey));

        relatedComponentResponses.push({ layoutKey: actionKey, responses: relatedQuestions });
      } else {
        console.error(
          'ot-workflow-engine-renderer -> setRelatedComponentResponse -> actionKey not found in definition:   ',
          actionKey,
        );
      }
    }

    return relatedComponentResponses;
  }

  private getActionKeyForOption(key: string) {
    return this.question.options?.find(o => o?.key === key)?.actionKey || null;
  }

  public onChange() {
    const valueRemoved = this.result?.values.find(v => !this.internalValue.map(iv => iv.value).includes(v.key));
    if (valueRemoved) {
      // Reset the isCustomised state for the value that was removed from the list
      this.optionKeyCustomisationState[valueRemoved.key] = false;
      const actionKey = this.getActionKeyForOption(valueRemoved.key);
      if (actionKey) {
        this.$emit('resetDialogResponses', actionKey);
      }
    } else {
      const valueAdded = this.internalValue.find(v => !this.result?.values.map(v => v.key).includes(v.value));
      if (valueAdded) {
        // the option could have been deselected externally so we wanna make sure the customisation state is false when it's checked
        this.optionKeyCustomisationState[valueAdded.value] = false;

        // Set the default values for the modal
        const actionKey = this.getActionKeyForOption(valueAdded.value || '');
        if (actionKey) {
          this.$emit('setDialogDefaults', actionKey);
        }
      }
    }

    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.internalValue.map(
        (v, index) =>
          new OtDataDrivenValue({
            key: v.value,
            orderIndex: index,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  private optionDependenciesThatHaveCustomisedModals(
    optionKey: string,
    questionPool: OtDataDrivenQuestion[],
  ): string[] {
    let optionsThatHaveBeenCustomised: string[] = [];

    // TODO Stage12 Thornie I don't understand what is going on here
    // dependsOn used to be an array, so it was q.dependsOn?.options.includes(optionKey))
    // it's now an array of arrays, so we need to check in every array
    // but I don't understand what this code is actually doing or trying to do
    const questionsDependingOnOption = questionPool.filter(q =>
      q.dependsOn?.options.some(oo => oo.includes(optionKey)),
    );
    for (const question of questionsDependingOnOption) {
      // We only process items that are selected.
      // So if this question doesn't exist in the responses or if it isn't a question with the correct result type,
      // we can ignore it and continue to the next question
      const questionResponses = this.instance.responses.find(r => r.questionKey === question.key);
      if (!questionResponses || !(questionResponses.result instanceof OtDataDrivenOptionsList)) {
        continue;
      }

      for (const option of question.optionsOrdered) {
        // Only process selected item
        if (questionResponses.result.values.map(v => v.key).includes(option.key)) {
          if (option.actionKey) {
            // Compare the default dialog responses with the current dialog responses
            const defaultValuesForDialog = this.defaultValues.find(v => v.layoutKey === option.actionKey);
            const foundLayoutForDialog = this.definition.layouts.find(l => l.key === option.actionKey);
            if (foundLayoutForDialog && defaultValuesForDialog) {
              const questionKeysInDialog = foundLayoutForDialog.sections.flatMap(s => s.questions.map(q => q.key));
              const foundDialogResponses = this.instance.responses.filter(r =>
                questionKeysInDialog.includes(r.questionKey),
              );

              const defaultDialogThumbprint = JSON.stringify(defaultValuesForDialog.responses);
              const currentDialogThumbprint = JSON.stringify(foundDialogResponses);
              if (defaultDialogThumbprint !== currentDialogThumbprint) {
                optionsThatHaveBeenCustomised.push(option.title);
              }
            }
          }

          // Do the same processing for questions that depend on this question
          const childOptionsThatHaveBeenCustomised = this.optionDependenciesThatHaveCustomisedModals(
            option.key,
            questionPool,
          );
          optionsThatHaveBeenCustomised = optionsThatHaveBeenCustomised.concat(childOptionsThatHaveBeenCustomised);
        }
      }
    }

    return optionsThatHaveBeenCustomised;
  }

  private async beforeCheckboxValueSet(item: CheckboxGroupItem<string>, value: boolean): Promise<boolean> {
    if (!value) {
      // Check if there are any dialogs with customised values that depend on this item
      const currentLayout = this.definition.layouts.find(l => l.key === this.layoutKey);
      const allQuestionsDependingOnOptions =
        currentLayout?.sections.flatMap(s => s.questions.filter(q => Boolean(q.dependsOn?.options.length))) || [];

      // When deselecting a checkbox that either, has a modal that's been customised,
      // or has dependencies that have customised modals,
      // show a warning dialog before changing the value
      const thisItemHasBeenCustomised = item.actionButtonText && this.optionKeyCustomisationState[item.value];
      const optionDependenciesThatHaveBeenCustomised = this.optionDependenciesThatHaveCustomisedModals(
        item.value,
        allQuestionsDependingOnOptions,
      );
      if (thisItemHasBeenCustomised || optionDependenciesThatHaveBeenCustomised.length) {
        if (thisItemHasBeenCustomised) {
          optionDependenciesThatHaveBeenCustomised.unshift(item.label);
        }
        const doContinue = await new Promise<boolean>((resolve, reject) => {
          const params: IConfirmValueChangeEmitobject = {
            promise: { resolve, reject },
            items: optionDependenciesThatHaveBeenCustomised,
          };
          this.$emit('openConfirmValueChangeDialog', params);
        });
        return doContinue;
      }
    }
    return true;
  }

  private async actionClicked(item: CheckboxGroupItem<string>) {
    const foundOption = this.question.optionsOrdered.find(o => o.key === item.value);
    if (foundOption) {
      const selectedActionLayout = this.definition.layouts.find(l => l.key === foundOption.actionKey);
      if (selectedActionLayout) {
        // Copy dialog related data so that it can be replaced if the dialog is changed but cancelled
        const actionQuestionKeys = selectedActionLayout.sections.flatMap(s => s.questions.map(q => q.key));
        const existingDialogResponses = this.instance.responses.filter(r => actionQuestionKeys.includes(r.questionKey));
        const existingDialogResponsesClone = _.cloneDeep(existingDialogResponses);

        const modalResponse = await new Promise<FormModalResult | null>(resolve => {
          this.$emit('optionAction', { layoutKey: foundOption.actionKey, resolve });
        });

        if (modalResponse && !modalResponse.ok && !this.readonly) {
          // the dialog has been closed. Make sure the modal data gets reverted to what it was previously
          this.$emit('resetDialogResponses', foundOption.actionKey, existingDialogResponsesClone);

          // If this modal wasn't customised before it was opened,
          // and there are no customised options that deepend on this option,
          // uncheck the option
          const defaultValues = this.defaultValues.find(v => v.layoutKey === foundOption.actionKey);
          let isModalCustomised = false;

          if (defaultValues) {
            const defaultDialogThumbprint = JSON.stringify(defaultValues.responses);
            const currentDialogThumbprint = JSON.stringify(existingDialogResponsesClone);
            isModalCustomised = defaultDialogThumbprint !== currentDialogThumbprint;
          }

          if (!isModalCustomised) {
            // if there are no customised options that deepend on this option,
            const currentLayout = this.definition.layouts.find(l => l.key === this.layoutKey);
            const allQuestionsDependingOnOptions =
              currentLayout?.sections.flatMap(s => s.questions.filter(q => Boolean(q.dependsOn?.options.length))) || [];
            const optionDependenciesThatHaveBeenCustomised = this.optionDependenciesThatHaveCustomisedModals(
              foundOption.key,
              allQuestionsDependingOnOptions,
            );
            if (!optionDependenciesThatHaveBeenCustomised.length) {
              // uncheck the option
              this.internalValue = this.internalValue.filter(v => v.value !== item.value);
            }
          }
        }

        // Reset validation
        const foundInternalValue = this.internalValue.find(v => v.value === item.value);
        if (foundInternalValue) {
          foundInternalValue.error = null;
        }

        if (modalResponse?.ok && foundOption.actionKey) {
          this.optionKeyCustomisationState[foundOption.key] = this.isOptionModalCustomised(foundOption.actionKey);
        }
      }
    }
  }

  private isOptionModalCustomised(layoutKey: string) {
    const defaultValues = this.defaultValues.find(v => v.layoutKey === layoutKey);
    const foundDialogResponses = this.relatedComponentResponses().find(r => r.layoutKey === layoutKey);

    if (foundDialogResponses && defaultValues) {
      const defaultDialogThumbprint = this.getResponsesThumbprint(defaultValues.responses);
      const currentDialogThumbprint = this.getResponsesThumbprint(foundDialogResponses.responses);
      const result = defaultDialogThumbprint !== currentDialogThumbprint;
      if (result) {
        console.log('wf-checkbox-list-with-action-edit -> isOptionModalCustomised -> returning true for:  ', {
          layoutKey,
          defaultValues,
          foundDialogResponses,
          defaultDialogThumbprint,
          currentDialogThumbprint,
        });
      }
      return result;
    } else {
      return false;
    }
  }
  getResponsesThumbprint(responses: OtDataDrivenResponse[]): string {
    const clone = [...responses];
    // don't care what order the responses are in
    clone.sort((a, b) => a.questionKey.localeCompare(b.questionKey, undefined, { sensitivity: 'base' }));
    // we actually do care what order the options on each response are in. A list of "A B C" is not the same as a list "B A C".
    // It is up to the data entry components to ensure what's on screen is sorted correctly
    return JSON.stringify(clone);
  }

  private initializeOptionCustomisationState() {
    const optionKeyCustomisationStateArray: { key: string; isCustomised: boolean }[] = [];
    const selectedOptions = this.selectedOptions.map(o => o.key);

    for (const option of this.question.optionsOrdered) {
      if (option) {
        optionKeyCustomisationStateArray.push({
          key: option.key,
          // We don't want unselected options to have 'isCustomised' to true
          // because it doesn't get updated when it is selected for the first time.
          // So it might show as customised incorrectly
          isCustomised: selectedOptions.includes(option.key)
            ? this.isOptionModalCustomised(option.actionKey || '')
            : false,
        });
      }
    }

    this.optionKeyCustomisationState = Object.assign(
      {},
      ...(optionKeyCustomisationStateArray.map(o => ({ [o.key]: o.isCustomised })) || []),
    );
  }

  public setOptionErrors(optionKeys: string[]) {
    for (const key of optionKeys) {
      const foundItem = this.internalValue.find(i => i.value === key);
      if (foundItem) {
        // This message will almost certainly be changed later to come from the question/option object.
        // It is hard coded to say this for MVP
        foundItem.error =
          'This option requires a description of the relevant event. Either include a description or unselect the event.';
      }
    }
  }

  // * LIFECYCLE
  private async created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    const selectedOptions = this.selectedOptions.map(o => o.key);
    for (const optionKey of selectedOptions) {
      const option = this.question.options?.find(o => o.key === optionKey);
      if (option) {
        await new Promise<void>(resolve => {
          this.$emit('setDialogDefaults', option.actionKey);
          this.$nextTick(() => {
            resolve();
          });
        });
      }
    }

    this.initializeOptionCustomisationState();

    this.valueChanged();
  }
}
