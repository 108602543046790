





























import { Component, Vue, Prop } from 'vue-property-decorator';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtTextarea extends Vue {
  // * PROPS
  @Prop() private value!: string;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private secondaryLabel?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private description?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: true }) private validateOnBlur!: boolean;

  // * REFS

  // * DATA
  private isRequiredRule = (value: string) => !!value || this.requiredMessage || '';

  // * COMPUTED
  private get textValue() {
    return this.value;
  }

  private set textValue(value: string) {
    this.$emit('input', value);
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
