





































































































import { SegmentInstance } from '@/areas/claims/claims-models';
import { ROUTE_WORKFLOW_ADD, ROUTE_WORKFLOW_DETAILS } from '@/areas/claims/claims-routes';
import { FormModalParams, FormModalSizeEnum } from '@/components/global/modal/form-modal-models';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import { PostResetContractDateForPracticalCompletionRequestModel } from '@/services/generated/api';
import { OtOpenSegmentStatusSet, OtSegmentStatus, OtStatusType } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { formatDate } from '@/utils/date-utils';
import { IVForm } from '@/utils/type-utils';
import { OtSegmentProcessTypesThatCanAffectPC } from '@/wf-components/models/data-driven-enums';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

export interface IResetContractDateForPcDialogParams {
  contractGid: string;
  contractName: string;
  contractReference: string;
  projectedCompletionDate: ZonelessDate;
  mostRecentlyAgreedDateForPracticalCompletion: ZonelessDate | null;
  revisedCompletionDate: ZonelessDate | null;
  dateOfContract: ZonelessDate | null;
  adjustedProjectCompletionDate: ZonelessDate | null; // this should never be null. But, the types disagree and I don't want to fight with that right now
  contractRowVersion: string;
}

export class ResetContractDateForPcDialogParams implements IResetContractDateForPcDialogParams {
  public contractGid!: string;
  public contractName!: string;
  public contractReference!: string;
  public contractRowVersion!: string;
  public projectedCompletionDate!: ZonelessDate;
  public mostRecentlyAgreedDateForPracticalCompletion!: ZonelessDate | null;
  public revisedCompletionDate!: ZonelessDate | null;
  public dateOfContract!: ZonelessDate | null;
  public adjustedProjectCompletionDate!: ZonelessDate | null; // this should never be null. But, the types disagree and I don't want to fight with that right now

  constructor(params: IResetContractDateForPcDialogParams) {
    Object.assign(this, params);
  }
}

export class ResetContractDateForPcDialogResult {
  public didUpdateDate = false;
  public updatedContractRowVersion: string | null = null;

  constructor(params?: Partial<ResetContractDateForPcDialogResult>) {
    if (params) {
      Object.assign(this, params);
      this.updatedContractRowVersion = this.updatedContractRowVersion ?? null;
    }
  }
}

@Component({
  components: {
    OtFormModal,
    OtDatePicker,
    OtLoadingSpinner,
    OtTag,
  },
})
export default class OtResetContractDateForPcDialog extends Vue {
  // * PROPS
  private api = new OtApi();
  private params: ResetContractDateForPcDialogParams | null = null;
  private isLoading = false;
  private allSegmentInstancesRaw: Array<SegmentInstance> = [];
  private newDateForPc: ZonelessDate | null = null;

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;
  @Ref('modalContentContentRef') private modalContentContentRef!: HTMLDivElement;
  // * DATA

  private get workflowsToClose() {
    return this.allSegmentInstancesRaw.filter(
      x =>
        OtOpenSegmentStatusSet.has(x.status) &&
        OtSegmentProcessTypesThatCanAffectPC.has(x.workflowSegment.segmentProcessType),
    );
  }
  private get hasWorkflowsToClose() {
    return Boolean(this.workflowsToClose.length);
  }

  private get newDateForPcMessages() {
    if (this.newDateForPc && this.params?.dateOfContract && !(this.newDateForPc >= this.params.dateOfContract)) {
      return 'Must be >= Contract Date';
    }
    return undefined;
  }
  private get willAdjustProjectCompletionDate() {
    return (
      this.newDateForPc &&
      this.params?.adjustedProjectCompletionDate &&
      this.newDateForPc > this.params.adjustedProjectCompletionDate
    );
  }

  public validate() {
    return this.modalContentRef.validate();
  }

  private formatDate(val: Date) {
    return formatDate(val);
  }
  private getClaimLink(claim: SegmentInstance): RawLocation {
    // if the logic for "where to take them" changes, update the logic in ot-claim-details (OtClaimDetails) isEditEnabled
    // in theory all our claims are closed (we're filtering for that) but if it ever changes, this is one less thing to change
    // copy pasted from the ot-claims-table
    if (OtOpenSegmentStatusSet.has(claim.status)) {
      return { name: ROUTE_WORKFLOW_ADD, query: { claimGid: claim.gid } };
    } else {
      return {
        name: ROUTE_WORKFLOW_DETAILS,
        params: { projectGid: claim.project.gid, contractGid: claim.contract.gid, claimGid: claim.gid },
      };
    }
  }
  private getTagStatus(status: OtSegmentStatus): TagStatus {
    return { type: OtStatusType.Segment, status };
  }

  private async getAllContractSegmentInstances(contractGid: string) {
    this.isLoading = true;

    const apiResult = await executeApi(
      () => this.api.segmentInstances().getSegmentInstances(contractGid),
      `Load Workflows for contract (${contractGid})`,
    );
    if (apiResult.success && apiResult.data && apiResult.data.segmentInstances) {
      this.allSegmentInstancesRaw = apiResult.data.segmentInstances.map(s => SegmentInstance.createFromApiResponse(s));
    }

    this.isLoading = false;
  }

  public async open(params: ResetContractDateForPcDialogParams): Promise<ResetContractDateForPcDialogResult> {
    this.modalContentRef.reset();
    this.params = params;
    let contractRowVersionFromUpdate: null | string = null;

    const baseParams = new FormModalParams({
      title: `${params.contractReference} - Set New Agreed Date for Practical Completion`,
      formRef: this.modalContentRef,
      size: FormModalSizeEnum.Large,
      confirmText: `Change Date for Practical Completion`,
      cancelText: `Keep Date as is`,
      onAfterFormMounted: () => {
        this.getAllContractSegmentInstances(params.contractGid);
      },
      onBeforeConfirmClose: async () => {
        if (!this.newDateForPc) {
          return false;
        }

        const requestModel = new PostResetContractDateForPracticalCompletionRequestModel({
          mostRecentlyAgreedDateForPracticalCompletion: new ZonelessDate(this.newDateForPc).toZonelessDateFormat(),
          rowVersion: params.contractRowVersion,
        });
        const errorOverride: IApiErrorOverride[] = [
          {
            status: 409,
            message: `Unable to set date for practical completion - contract has been modified by someone else. Please refresh the page and try again.`,
          },
        ];

        const apiResponse = await executeApi(
          () =>
            this.api
              .contracts()
              .postResetContractDateForPracticalCompletion(params.contractGid, undefined, requestModel),
          'Set Date for Practical Completion',
          errorOverride,
        );
        if (apiResponse && apiResponse.success) {
          contractRowVersionFromUpdate = apiResponse.data?.rowVersion ?? null;
          return true;
        }
        return false;
      },
    });
    const result = await this.formModalRef.open(baseParams);
    if (result.ok) {
      return new ResetContractDateForPcDialogResult({
        didUpdateDate: true,
        updatedContractRowVersion: contractRowVersionFromUpdate,
      });
    }
    return new ResetContractDateForPcDialogResult({
      didUpdateDate: false,
    });
  }
}
