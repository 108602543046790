import { TrafficLights } from '@/components/global/common-models';
import { sortNullableNumbers } from '@/components/global/table/ot-table-utils';
import { UserStatus } from '@/models/user-models';
import {
  ListContractModel as ApiListContractModel,
  ContractStatus as ApiContractStatus,
  ContractDetailsModel as ApiContractDetails,
  ContractTypeModel as ApiContractType,
  ContractCalendarModel as ApiContractCalendar,
  ContractWorkflowModel as ApiContractWorkflow,
  BasicWorkflowModel as ApiBasicWorkflow,
  PostUpdateContractDetailsRequestModel as ApiPostUpdateContractDetailsRequestModel,
  ContractWizardModel as ApiContractWizardModel,
  BasicContractModel as ApiBasicContract,
  ContractWorkflowFormModel,
} from '@/services/generated/api';
import { OtContractStatus } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { compareAsc } from 'date-fns';

export const parsedOtContractEnums: { [key in ApiContractStatus]: OtContractStatus } = {
  [ApiContractStatus.Active]: OtContractStatus.Active,
  [ApiContractStatus.Pending]: OtContractStatus.Pending,
  [ApiContractStatus.Terminated]: OtContractStatus.Terminated,
  [ApiContractStatus.PracticalCompletion]: OtContractStatus.PracticalCompletion,
  [ApiContractStatus.FinalCompletion]: OtContractStatus.FinalCompletion,
  [ApiContractStatus.Suspended]: OtContractStatus.Suspended,
  [ApiContractStatus.Draft]: OtContractStatus.Draft,
  [ApiContractStatus.Cancelled]: OtContractStatus.Cancelled,
};

const OtContractEnumsOrder: { [key in OtContractStatus]: number } = {
  [OtContractStatus.Draft]: 1,
  [OtContractStatus.Active]: 2,
  [OtContractStatus.Pending]: 3,
  [OtContractStatus.FinalCompletion]: 4,
  [OtContractStatus.PracticalCompletion]: 5,
  [OtContractStatus.Terminated]: 6,
  [OtContractStatus.Suspended]: 7,
  [OtContractStatus.Cancelled]: 8,
};

export const parsedApiContractEnums: { [key in OtContractStatus]: ApiContractStatus } = {
  [OtContractStatus.Active]: ApiContractStatus.Active,
  [OtContractStatus.Pending]: ApiContractStatus.Pending,
  [OtContractStatus.FinalCompletion]: ApiContractStatus.FinalCompletion,
  [OtContractStatus.PracticalCompletion]: ApiContractStatus.PracticalCompletion,
  [OtContractStatus.Terminated]: ApiContractStatus.Terminated,
  [OtContractStatus.Suspended]: ApiContractStatus.Suspended,
  [OtContractStatus.Draft]: ApiContractStatus.Draft,
  [OtContractStatus.Cancelled]: ApiContractStatus.Cancelled,
};

export interface IContract {
  gid: string;
  name: string;
  reference: string;
  projectGid: string;
  typeGid: string;
  typeName: string;
  timezone: string;
  status: OtContractStatus | null;
  startDate: ZonelessDate | null;
  trafficLights: TrafficLights;
  organisationUser: UserStatus | null;
  projectUser: UserStatus | null;
  contractUser: UserStatus | null;
  projectedEndDate: ZonelessDate;
  revisedEndDate: ZonelessDate | null;
  mostRecentlyAgreedDateForPracticalCompletion: ZonelessDate | null;
  rowVersion: string;
}

export class Contract implements IContract {
  public gid!: string;
  public name!: string;
  public reference!: string;
  public projectGid!: string;
  public typeGid!: string;
  public typeName!: string;
  public timezone!: string;
  public status!: OtContractStatus | null;
  public startDate!: ZonelessDate | null;
  public trafficLights!: TrafficLights;
  public organisationUser!: UserStatus | null;
  public projectUser!: UserStatus | null;
  public contractUser!: UserStatus | null;
  public projectedEndDate!: ZonelessDate;
  public revisedEndDate!: ZonelessDate | null;
  public mostRecentlyAgreedDateForPracticalCompletion!: ZonelessDate | null;
  public rowVersion!: string;

  public get effectiveDateForPracticalCompletion() {
    // projected is create time, most recently is the update, revised is updated every claim. Setting a most recently nukes revised
    // but running a claim doesn't nuke mostrecently
    // so, this is the order to coalesce in
    const result = this.revisedEndDate ?? this.mostRecentlyAgreedDateForPracticalCompletion ?? this.projectedEndDate;
    console.log('get effectiveDateForPracticalCompletion', {
      result,
      mra: this.mostRecentlyAgreedDateForPracticalCompletion,
      r: this.revisedEndDate,
      p: this.projectedEndDate,
    });
    return result;
  }

  public constructor(value: IContract) {
    Object.assign(this, value);
  }

  // we don't need a createEmpty on this model. It's purely for the List Contracts item
  // public static createEmpty() {
  //   return new Contract({
  //     gid: '',
  //     name: '',
  //     reference: '',
  //     projectGid: '',
  //     typeGid: '',
  //     timezone: '',
  //     typeName: '',
  //     status: null,
  //     startDate: null,
  //     trafficLights: TrafficLights.createEmpty(),
  //     organisationUser: null,
  //     projectUser: null,
  //     contractUser: null,
  //     projectedEndDate: null,
  //     revisedEndDate: null,
  //     mostRecentlyAgreedDateForPracticalCompletion: null,
  //   });
  // }

  public static createFromApiResponse(model: ApiListContractModel): Contract {
    return new Contract({
      gid: model.gid || '',
      name: model.name || '',
      reference: model.reference || '',
      projectGid: model.projectGid || '',
      typeGid: model.typeGid || '',
      typeName: model.typeName || '',
      timezone: model.timezone || '',
      status: model.status ? parsedOtContractEnums[model.status] : null,
      startDate: new ZonelessDate(model.startDate),
      trafficLights: model.trafficLights
        ? TrafficLights.createFromApiResponse(model.trafficLights)
        : TrafficLights.createEmpty(),
      organisationUser: model.organisationUser ? UserStatus.createFromApiResponse(model.organisationUser) : null,
      projectUser: model.projectUser ? UserStatus.createFromApiResponse(model.projectUser) : null,
      contractUser: model.contractUser ? UserStatus.createFromApiResponse(model.contractUser) : null,
      projectedEndDate: new ZonelessDate(model.projectedEndDate),
      revisedEndDate: model.revisedEndDate ? new ZonelessDate(model.revisedEndDate) : null,
      mostRecentlyAgreedDateForPracticalCompletion: model.mostRecentlyAgreedDateForPracticalCompletion
        ? new ZonelessDate(model.mostRecentlyAgreedDateForPracticalCompletion)
        : null,
      rowVersion: model.rowVersion,
    });
  }

  public static compareByReference(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.reference.localeCompare(b.reference, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByName(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByType(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.typeName.localeCompare(b.typeName, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByDate(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return compareAsc(a.startDate || 0, b.startDate || 0) * sortModifier;
  }

  public static compareByEffectivePracticalCompletionDate(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return compareAsc(a.effectiveDateForPracticalCompletion, b.effectiveDateForPracticalCompletion) * sortModifier;
  }

  public static compareByTrafficLights(a: Contract, b: Contract, ascending: boolean) {
    return TrafficLights.defaultSorting(a.trafficLights, b.trafficLights, ascending);
  }

  public static compareByStatus(a: Contract, b: Contract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const aOrder = a.status ? OtContractEnumsOrder[a.status] : null;
    const bOrder = b.status ? OtContractEnumsOrder[b.status] : null;

    return sortNullableNumbers(aOrder, bOrder) * sortModifier;
  }

  public static filterByKeyword(keyword: string, contract: Contract) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return contract.name.match(regex);
    }
    return true;
  }

  public static filterByStatus(statuses: OtContractStatus[], contract: Contract) {
    if (statuses.length && contract.status) {
      return statuses.includes(contract.status);
    }
    return true;
  }
}

export interface IContractTypeDetails {
  gid: string;
  name: string;
  description: string;
  isGlobal: boolean;
}
export class ContractTypeDetails implements IContractTypeDetails {
  public gid!: string;
  public name!: string;
  public description!: string;
  public isGlobal!: boolean;

  public constructor(value: IContractTypeDetails) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ContractTypeDetails({
      gid: '',
      name: '',
      description: '',
      isGlobal: false,
    });
  }

  public static createFromApiResponse(model: ApiContractType) {
    return new ContractTypeDetails({
      gid: model.gid || '',
      name: model.name || '',
      description: model.description || '',
      isGlobal: model.isGlobal || false,
    });
  }
}

export interface IContractType {
  gid: string;
  name: string;
}

export class ContractType implements IContractType {
  public gid!: string;
  public name!: string;

  public constructor(value: IContractType) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ContractType({
      gid: '',
      name: '',
    });
  }

  public static createFromApiResponse(model: ApiContractType) {
    return new ContractType({
      gid: model.gid || '',
      name: model.name || '',
    });
  }
}

export interface IContractCalendar {
  gid: string;
  organisationCalendarGid: string | null;
  name: string;
}

export class ContractCalendar implements IContractCalendar {
  public gid!: string;
  public organisationCalendarGid: string | null = null;
  public name!: string;

  public constructor(value: IContractCalendar) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ContractCalendar({
      gid: '',
      organisationCalendarGid: null,
      name: '',
    });
  }

  public static createFromApiResponse(model: ApiContractCalendar) {
    return new ContractCalendar({
      gid: model.gid || '',
      organisationCalendarGid: model.organisationCalendarGid || null,
      name: model.name || '',
    });
  }
}

export interface IContractWorkflow {
  gid: string;
  workflowGid: string;
  name: string;
  shortName: string;
  isAmended: boolean;
}

export class ContractWorkflow implements IContractWorkflow {
  public gid!: string;
  public workflowGid!: string;
  public name!: string;
  public shortName!: string;
  public isAmended!: boolean;

  public constructor(value: IContractWorkflow) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ContractWorkflow({
      gid: '',
      workflowGid: '',
      name: '',
      shortName: '',
      isAmended: false,
    });
  }

  public static createFromApiResponse(model: ApiContractWorkflow) {
    return new ContractWorkflow({
      gid: model.gid || '',
      workflowGid: model.workflowGid || '',
      name: model.name || '',
      shortName: model.shortName || '',
      isAmended: model.isAmended || false,
    });
  }

  public static createRequestModel(model: ContractWorkflow[]) {
    return model.map(
      w =>
        new ContractWorkflowFormModel({
          contractWorkflowGid: w.gid,
          workflowGid: w.workflowGid,
          isAmended: w.isAmended,
        }),
    );
  }
}

export interface IContractDetails {
  gid: string;
  projectGid: string;
  organisationGid: string;
  name: string;
  reference: string;
  type: ContractType;
  contractor: string | null;
  description: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  status: OtContractStatus;
  timezone: string;
  budget: number | null;
  dateOfContract: ZonelessDate | null;
  tendersClosedDate: ZonelessDate | null;
  /**
   * This is not allowed to be null from the server.
   * We have some dumb code that uses a "blank" object for powering the create form, so it needs to start off null.
   */
  projectedCompletionDate: ZonelessDate | null;
  /**
   * This probably isn't what you think it is.
   * IF you're looking at an existing one loaded from the server, it's the effective date for PC, coalesced for you by the server.
   * Not the Revised Date column from the database
   * IF you're looking at a brand new being created contract, it's the value in the End Date field
   * Which the eagle eyed among you might be saying "but... isn't that Projected and not Revised"
   * Yes. Yes it is. For historical reasons the form was bound to revised, not projected. Maybe for Edit purposes? Dan does not know
   * Once the project is active, we won't ever make revised/projected editable, EVER. There's a whole Set New Date for PC
   * Dan is too scared to change the binding
   * */
  revisedCompletionDate: ZonelessDate | null;
  workdayCalendar: ContractCalendar;
  businessCalendar: ContractCalendar;
  workflows: Array<ContractWorkflow>;
  mostRecentlyAgreedDateForPracticalCompletion: ZonelessDate | null;
  rowVersion: string;
}

export class ContractDetails implements IContractDetails {
  public gid!: string;
  public projectGid!: string;
  public organisationGid!: string;
  public name!: string;
  public reference!: string;
  public type!: ContractType;
  public contractor: string | null = null;
  public description: string | null = null;
  public contactName: string | null = null;
  public contactEmail: string | null = null;
  public contactPhone: string | null = null;
  public status!: OtContractStatus;
  public timezone!: string;
  public budget: number | null = null;
  public dateOfContract: ZonelessDate | null = null;
  public tendersClosedDate: ZonelessDate | null = null;
  public projectedCompletionDate: ZonelessDate | null = null;
  /**
   * This probably isn't what you think it is.
   * IF you're looking at an existing one loaded from the server, it's the effective date for PC, coalesced for you by the server.
   * Not the Revised Date column from the database.
   * IF you're looking at a brand new being created contract, it's the value in the End Date field.
   * Which the eagle eyed among you might be saying "but... isn't that Projected and not Revised?"
   * Yes. Yes it is. For historical reasons the form was bound to revised, not projected. Maybe for Edit purposes? Dan does not know.
   * Once the project is active, we won't ever make revised/projected editable, EVER. There's a whole Set New Date for PC process for this.
   * Dan is too scared to change the binding.
   * */
  public revisedCompletionDate: ZonelessDate | null = null;
  public mostRecentlyAgreedDateForPracticalCompletion: ZonelessDate | null = null;
  public workdayCalendar!: ContractCalendar;
  public businessCalendar!: ContractCalendar;
  public workflows!: Array<ContractWorkflow>;
  public rowVersion!: string;

  public constructor(value: IContractDetails) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ContractDetails({
      gid: '',
      projectGid: '',
      organisationGid: '',
      name: '',
      reference: '',
      type: ContractType.createEmpty(),
      contractor: null,
      description: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      status: OtContractStatus.Draft,
      timezone: '',
      budget: null,
      dateOfContract: null,
      tendersClosedDate: null,
      projectedCompletionDate: null,
      revisedCompletionDate: null,
      workdayCalendar: ContractCalendar.createEmpty(),
      businessCalendar: ContractCalendar.createEmpty(),
      workflows: [],
      mostRecentlyAgreedDateForPracticalCompletion: null,
      rowVersion: '',
    });
  }

  public static createNew(projectGid: string, timezone: string) {
    const contract = new ContractDetails({
      projectGid: projectGid,
      organisationGid: '',
      gid: '',
      name: '',
      reference: '',
      type: ContractType.createEmpty(),
      contractor: null,
      description: null,
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      status: OtContractStatus.Draft,
      timezone: timezone,
      budget: null,
      dateOfContract: null,
      tendersClosedDate: null,
      projectedCompletionDate: null,
      revisedCompletionDate: null,
      workdayCalendar: ContractCalendar.createEmpty(),
      businessCalendar: ContractCalendar.createEmpty(),
      workflows: [],
      mostRecentlyAgreedDateForPracticalCompletion: null,
      rowVersion: '',
    });
    return contract;
  }

  public static createFromApiResponse(model: ApiContractDetails) {
    console.log('contract-models -> createFromApiResponse -> model:  ', model);
    return new ContractDetails({
      gid: model.gid,
      projectGid: model.projectGid,
      organisationGid: model.organisationGid,
      name: model.name,
      reference: model.reference,
      type: ContractType.createFromApiResponse(model.type),
      contractor: model.contractor ?? null,
      description: model.description ?? null,
      contactName: model.contactName ?? null,
      contactEmail: model.contactEmail ?? null,
      contactPhone: model.contactPhone ?? null,
      status: parsedOtContractEnums[model.status],
      timezone: model.timezone,
      budget: model.budget ?? null,
      dateOfContract: new ZonelessDate(model.dateOfContract) || null,
      tendersClosedDate: model.tendersClosedDate ? new ZonelessDate(model.tendersClosedDate) : null,
      projectedCompletionDate: new ZonelessDate(model.projectedCompletionDate),
      revisedCompletionDate: new ZonelessDate(model.revisedCompletionDate),
      workdayCalendar: ContractCalendar.createFromApiResponse(model.workdayCalendar),
      businessCalendar: ContractCalendar.createFromApiResponse(model.businessCalendar),
      workflows: model.workflows.map(w => ContractWorkflow.createFromApiResponse(w)),
      mostRecentlyAgreedDateForPracticalCompletion: model.mostRecentlyAgreedDateForPracticalCompletion
        ? new ZonelessDate(model.mostRecentlyAgreedDateForPracticalCompletion)
        : null,
      rowVersion: model.rowVersion,
    });
  }

  public static createRequestModel(model: ContractDetails, gid: string) {
    return new ApiContractWizardModel({
      contractGid: gid,
      projectGid: model.projectGid,
      typeGid: model.type.gid,
      name: model.name || '',
      reference: model.reference || '',
      contractorName: model.contractor || undefined,
      description: model.description || undefined,
      contactName: model.contactName || undefined,
      contactEmail: model.contactEmail || undefined,
      contactPhone: model.contactPhone || undefined,
      timezone: model.timezone || '',
      budget: model.budget !== null && model.budget !== undefined ? model.budget : undefined,
      contractStartDate: new ZonelessDate(model.dateOfContract).toString() || undefined,
      tendersClosedDate: model.tendersClosedDate
        ? ZonelessDate.toZonelessDateFormat(model.tendersClosedDate)
        : undefined,
      projectedEndDate: new ZonelessDate(model.projectedCompletionDate).toString() || new ZonelessDate().toString(),
      workingCalendarGid: model.workdayCalendar?.gid,
      businessCalendarGid: model.businessCalendar?.gid,
    });
  }
}

export interface IContractDetailsPost {
  gid: string;
  name: string;
  reference: string;
  typeGid: string;
  contractor?: string;
  description?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  timezone: string;
  budget?: number;
  dateOfContract: ZonelessDate;
  tendersClosedDate?: ZonelessDate;
  revisedCompletionDate: ZonelessDate;
  workingCalendarGid?: string;
  businessCalendarGid?: string;
  originalWorkingCalendarGid?: string;
  originalBusinessCalendarGid?: string;
  originalRowVersion: string;
}

export class ContractDetailsPost implements IContractDetailsPost {
  public gid!: string;
  public name!: string;
  public reference!: string;
  public typeGid!: string;
  public contractor?: string;
  public description?: string;
  public contactName?: string;
  public contactEmail?: string;
  public contactPhone?: string;
  public timezone!: string;
  public budget?: number;
  public dateOfContract!: ZonelessDate;
  public tendersClosedDate?: ZonelessDate;
  public revisedCompletionDate!: ZonelessDate;
  public workingCalendarGid?: string;
  public businessCalendarGid?: string;
  public originalWorkingCalendarGid?: string;
  public originalBusinessCalendarGid?: string;
  public originalRowVersion!: string;

  public constructor(value: IContractDetailsPost) {
    Object.assign(this, value);
  }

  public get effectiveWorkingCalendarGid() {
    return this.originalWorkingCalendarGid === this.workingCalendarGid ? undefined : this.workingCalendarGid;
  }

  public get effectiveBusinessCalendarGid() {
    return this.originalBusinessCalendarGid === this.businessCalendarGid ? undefined : this.businessCalendarGid;
  }

  public static createUpdateRequestModel(model: ContractDetailsPost) {
    return new ApiPostUpdateContractDetailsRequestModel({
      rowVersion: model.originalRowVersion,
      name: model.name,
      reference: model.reference,
      typeGid: model.typeGid,
      contractorName: model.contractor,
      description: model.description,
      contactName: model.contactName,
      contactEmail: model.contactEmail,
      contactPhone: model.contactPhone,
      timezone: model.timezone,
      budget: model.budget,
      contractStartDate: model.dateOfContract ? ZonelessDate.toZonelessDateFormat(model.dateOfContract) : undefined,
      tendersClosedDate: model.tendersClosedDate
        ? ZonelessDate.toZonelessDateFormat(model.tendersClosedDate)
        : undefined,
      projectedEndDate: model.revisedCompletionDate
        ? ZonelessDate.toZonelessDateFormat(model.revisedCompletionDate)
        : '',
      workingCalendarGid: model.effectiveWorkingCalendarGid,
      businessCalendarGid: model.effectiveBusinessCalendarGid,
    });
  }
}

export interface IBasicWorkflow {
  gid: string;
  name: string;
  shortName: string;
  contractWorkflowCannotBeDeleted: boolean;
}

export default class BasicWorkflow implements IBasicWorkflow {
  public gid!: string;
  public name!: string;
  public shortName!: string;
  public contractWorkflowCannotBeDeleted!: boolean;

  public constructor(value: IBasicWorkflow) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new BasicWorkflow({
      gid: '',
      name: '',
      shortName: '',
      contractWorkflowCannotBeDeleted: false,
    });
  }

  public static createFromApiResponse(model: ApiBasicWorkflow) {
    return new BasicWorkflow({
      gid: model.gid || '',
      name: model.name || '',
      shortName: model.shortName || '',
      contractWorkflowCannotBeDeleted: model.contractWorkflowCannotBeDeleted,
    });
  }
}

export interface IBasicContract {
  gid: string;
  name: string;
  reference: string;
  timezone: string;
  contactEmail: string | null;
  status: OtContractStatus;
  rowVersion: string;
}

export class BasicContract implements IBasicContract {
  public gid!: string;
  public name!: string;
  public reference!: string;
  public timezone!: string;
  public contactEmail!: string | null;
  public status!: OtContractStatus;
  public rowVersion!: string;

  public constructor(value: IBasicContract) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new BasicContract({
      gid: '',
      name: '',
      reference: '',
      timezone: '',
      contactEmail: null,
      status: OtContractStatus.Pending,
      rowVersion: '',
    });
  }

  public static createFromApiResponse(model: ApiBasicContract) {
    return new BasicContract({
      gid: model.gid,
      name: model.name,
      reference: model.reference,
      timezone: model.timezone,
      contactEmail: model.contactEmail || null,
      status: parsedOtContractEnums[model.status],
      rowVersion: model.rowVersion,
    });
  }
}
