import { IApiError } from '@/utils/type-utils';

interface IResponseError {
  message: string;
  source: string;
}

export class ResponseError implements IResponseError {
  public message!: string;
  public source!: string;

  public constructor(value: IResponseError) {
    Object.assign(this, value);
  }
}

interface IApiResponse<T> {
  success: boolean;
  status: number | null;
  errors: ResponseError[];
  data?: T;
}

export default class ApiResponse<T> implements IApiResponse<T> {
  public success!: boolean;
  public status!: number;
  public errors!: ResponseError[];
  public data?: T;

  public constructor(value: IApiResponse<T>) {
    Object.assign(this, value);
  }

  public static CreateFromError<T>(error: IApiError): ApiResponse<T> {
    let status = null;
    if (error.status) {
      status = error.status;
    }
    const response = this.CreateEmptyFailure<T>(status);

    if (error.errors) {
      const dictionaryKeys = Object.keys(error.errors);

      if (dictionaryKeys.length > 0) {
        for (const key of dictionaryKeys) {
          const messages: string[] = error.errors[key];
          for (const message of messages) {
            const error: ResponseError = new ResponseError({ message, source: key });
            response.errors.push(error);
          }
        }
      }
    }

    return response;
  }

  public static CreateSuccessWithData<T>(data: T): ApiResponse<T> {
    return new ApiResponse({ success: true, errors: [], data: data, status: 200 });
  }

  public static CreateEmptySuccess<T>(status: number): ApiResponse<T> {
    return new ApiResponse({ success: true, errors: [], status });
  }

  public static CreateEmptyFailure<T>(status: number | null): ApiResponse<T> {
    return new ApiResponse({ success: false, errors: [], status });
  }
}
