














































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import {
  Calendar,
  CalendarDetails,
  Holiday,
  HolidayDate,
  OtCalendarStatus,
  WorkingWeekHours,
} from '../models/calendar-models';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtDatePicker, { DatepickerTypeEnum } from '@/components/global/ot-date-picker.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtCalendarHolidayTable from '@/areas/settings/calendars/ot-calendar-holiday-table.vue';
import { v4 as uuidv4 } from 'uuid';
import { capitalizeString } from '@/utils/string-utils';
import { IVForm } from '@/utils/type-utils';
import { FormModalParams } from '@/components/global/modal/form-modal-models';
import { vxm } from '@/store';
import OtApi, { executeApi } from '@/services/api.service';
import { dirtyFormClass } from '@/utils/validation-utils';
import { ZonelessDate } from '@/types/zoneless-date';
import { ResponseError } from '@/services/api-models';

@Component({
  components: {
    OtTextField,
    OtDatePicker,
    OtAutocomplete,
    OtCheckboxGroup,
    OtButton,
    OtCalendarHolidayTable,
  },
})
export default class OtCalendarDetailsForm extends Vue {
  // * PROPS
  @Prop() private calendar?: CalendarDetails;
  @Prop() private orgGid?: string;
  @Prop(Boolean) private isSaving?: boolean;

  // * REFS
  @Ref('calendarDetailsForm') public calendarDetailsForm!: IVForm;
  @Ref('addEditHolidayForm') private addEditHolidayForm!: IVForm;
  @Ref('changeBaseCalendarForm') private changeBaseCalendarForm!: IVForm;
  @Ref('removeBaseCalendarForm') private removeBaseCalendarForm!: IVForm;

  // * DATA
  private api = new OtApi();
  private loadingHolidays = false;
  private isDuplicate = this.$route.name === 'DuplicateCalendar';
  private apiNameErrors: string[] = [];
  private editHolidayFormWrapper: HTMLElement | null = null;

  private calendarWorkingDays: CheckboxGroupItem<string>[] = [
    new CheckboxGroupItem('Monday', 'monday'),
    new CheckboxGroupItem('Tuesday', 'tuesday'),
    new CheckboxGroupItem('Wednesday', 'wednesday'),
    new CheckboxGroupItem('Thursday', 'thursday'),
    new CheckboxGroupItem('Friday', 'friday'),
    new CheckboxGroupItem('Saturday', 'saturday'),
    new CheckboxGroupItem('Sunday', 'sunday'),
  ];

  private organisationCalendars: IAutocompleteItem<Calendar>[] = [];
  private selectedBaseCalendar: IAutocompleteItem<Calendar> = {
    label: '',
    data: null,
  };

  private datePickerType = DatepickerTypeEnum.Multiple;
  private dirtyHolidayName = '';
  private dirtyHolidayDates: Date[] = [];

  private workingDaysChanged = false;

  private originalCalendarThumbprint = '';
  private currentCalendarThumbprint = '';
  private calendarLocal: CalendarDetails = CalendarDetails.createEmpty();

  // * COMPUTED
  private selectedWorkingDaysPrivate: CheckboxGroupItem<string>[] = [];
  private get selectedWorkingDays(): CheckboxGroupItem<string>[] {
    return this.selectedWorkingDaysPrivate;
  }
  private set selectedWorkingDays(val: CheckboxGroupItem<string>[]) {
    this.selectedWorkingDaysPrivate = val;
    const cleanWorkingWeekHours = new WorkingWeekHours({
      monday: val.some(day => day.value === 'monday') ? WorkingWeekHours.workingDayHours : 0,
      tuesday: val.some(day => day.value === 'tuesday') ? WorkingWeekHours.workingDayHours : 0,
      wednesday: val.some(day => day.value === 'wednesday') ? WorkingWeekHours.workingDayHours : 0,
      thursday: val.some(day => day.value === 'thursday') ? WorkingWeekHours.workingDayHours : 0,
      friday: val.some(day => day.value === 'friday') ? WorkingWeekHours.workingDayHours : 0,
      saturday: val.some(day => day.value === 'saturday') ? WorkingWeekHours.workingDayHours : 0,
      sunday: val.some(day => day.value === 'sunday') ? WorkingWeekHours.workingDayHours : 0,
    });
    this.calendarLocal.workingWeekHours = cleanWorkingWeekHours;
  }

  private get localCalendarName() {
    return this.calendarLocal.name;
  }
  private set localCalendarName(val: string) {
    this.calendarLocal.name = val;
    this.apiNameErrors = [];
  }

  private get calendarIsDirty(): boolean {
    return this.originalCalendarThumbprint !== this.currentCalendarThumbprint;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private workDaysValidPrivate = true;
  private get workingDaysValid() {
    if (this.workingDaysChanged) {
      this.workDaysValidPrivate = this.selectedWorkingDays.length > 0;
      return this.workDaysValidPrivate;
    }
    return this.workDaysValidPrivate;
  }

  private get baseCalendarHint() {
    return this.calendar?.hasChildren
      ? "This calendar has been inherited from so a base calendar can't be set"
      : 'The selected calendar will be used as a base that you can then customise for your own needs';
  }
  private get baseCalendarDisabled() {
    return this.calendar ? this.calendar.hasChildren : false;
  }

  private get sortedCalendarHolidays() {
    return this.sortCalendarHolidays(this.calendarLocal.holidays);
  }

  private sortCalendarHolidays(holidays: Holiday[]): Holiday[] {
    const array = [...holidays];
    return array.sort((a, b) => {
      return Holiday.compareByFirstDate(a, b) || Holiday.compareByParent(a, b, this.selectedBaseCalendar.data?.gid);
    });
  }

  // * WATCHERS
  @Watch('calendarLocal', { deep: true })
  private calendarLocalChanged(val: CalendarDetails) {
    this.currentCalendarThumbprint = this.getCalendarThumbprint(val);
  }

  // * METHODS
  private async baseCalendarChanged(value: IAutocompleteItem<Calendar>) {
    if (value && value.data) {
      if (this.selectedBaseCalendar.data) {
        const params = new FormModalParams({
          title: 'Are you sure you want to change your base calendar?',
          formRef: this.changeBaseCalendarForm,
          confirmText: 'Change base calendar',
        });
        const result = await vxm.modal.openFormModal(params);
        if (result.ok) {
          await this.updateCalenarInfo(value.data.gid);
          this.selectedBaseCalendar = value;
        }
      } else {
        await this.updateCalenarInfo(value.data.gid);
        this.selectedBaseCalendar = value;
      }
    } else {
      const params = new FormModalParams({
        title: 'Are you sure you want to remove your base calendar?',
        formRef: this.removeBaseCalendarForm,
        confirmText: 'Remove base calendar',
      });
      const result = await vxm.modal.openFormModal(params);
      if (result.ok) {
        await this.updateCalenarInfo();
        this.selectedBaseCalendar = {
          label: '',
          data: null,
        };
      }
    }
  }

  private async updateCalenarInfo(calendarGid?: string) {
    this.loadingHolidays = true;
    this.calendarLocal.holidays = this.calendarLocal.holidays.filter(c => c.calendarGid === this.calendar?.gid);
    this.selectedWorkingDays = [];
    if (calendarGid) {
      const newBaseCalendarResponse = await executeApi(
        () => this.api.calendar().getCalendarDetails(calendarGid),
        'Load Calendar Details',
      );
      if (
        newBaseCalendarResponse.success &&
        newBaseCalendarResponse.data &&
        newBaseCalendarResponse.data.workingWeekHours
      ) {
        newBaseCalendarResponse.data.holidays?.forEach(h => {
          this.calendarLocal.holidays.push(Holiday.createFromApiResponse(h));
        });
        this.selectedWorkingDays = this.parseWorkingDays(
          WorkingWeekHours.createFromApiResponse(newBaseCalendarResponse.data.workingWeekHours),
        );
        this.workingDaysChanged = true;
      }
    }
    this.calendarLocal.parent = calendarGid;
    this.loadingHolidays = false;
  }

  public handleApiFailure(errors: ResponseError[]) {
    this.apiNameErrors = [];

    for (const error of errors) {
      switch (error.source) {
        case 'Name':
          this.apiNameErrors.push(error.message);
          break;
      }
    }
  }

  private handleWorkingDayChange() {
    this.workingDaysChanged = true;
  }

  private removeHoliday(holiday: Holiday) {
    const foundIndex = this.calendarLocal.holidays.findIndex(h => h.gid === holiday.gid);
    if (foundIndex !== -1) {
      this.calendarLocal.holidays.splice(foundIndex, 1);
    }
  }

  private async addHoliday() {
    const params = new FormModalParams({
      title: 'Calendar Holiday',
      formRef: this.addEditHolidayForm,
      onAfterFormMounted: () => {
        this.editHolidayFormWrapper = this.addEditHolidayForm.$el.parentElement;
      },
    });
    const result = await vxm.modal.openFormModal(params);
    if (result.ok) {
      this.calendarLocal.holidays.push(
        new Holiday({
          gid: uuidv4(),
          name: this.dirtyHolidayName,
          dates: this.dirtyHolidayDates
            .map(
              date =>
                new HolidayDate({
                  gid: uuidv4(),
                  date: new ZonelessDate(date),
                }),
            )
            .sort((a, b) => HolidayDate.compareByDate(a, b)),
          calendarGid: '',
        }),
      );
    }
    this.addEditHolidayForm.reset();
  }

  private async editHoliday(holiday: Holiday) {
    this.dirtyHolidayName = holiday.name;
    this.dirtyHolidayDates = holiday.dates.map(holidayDate => holidayDate.date);
    const params = new FormModalParams({
      title: 'Calendar Holiday',
      formRef: this.addEditHolidayForm,
    });
    const result = await vxm.modal.openFormModal(params);
    if (result.ok) {
      const foundHoliday = this.calendarLocal.holidays.find(h => h.gid === holiday.gid);
      if (foundHoliday) {
        foundHoliday.name = this.dirtyHolidayName;

        const clonedDates = [...foundHoliday.dates];

        foundHoliday.dates = this.dirtyHolidayDates.map((date, index) => {
          const currentDate = clonedDates[index];
          return new HolidayDate({
            gid: currentDate ? currentDate.gid : uuidv4(),
            date: new ZonelessDate(date),
          });
        });
        foundHoliday.dates.sort((a, b) => HolidayDate.compareByDate(a, b));
      }
    }
    this.addEditHolidayForm.reset();
    this.dirtyHolidayName = '';
    this.dirtyHolidayDates = [];
  }

  public submit() {
    const formValid = this.calendarDetailsForm.validate();
    this.workDaysValidPrivate = this.calendar ? true : false;
    if (this.workingDaysValid && formValid) {
      const calendarGid = this.calendar && !this.isDuplicate ? this.calendar.gid : uuidv4();

      this.calendarLocal.holidays.forEach(holiday => {
        if (!holiday.calendarGid.length) {
          holiday.calendarGid = calendarGid;
        }
      });

      console.log('ot-calendar-details-form -> submit -> this.calendarLocal.holidays:  ', this.calendarLocal.holidays);

      let filteredCalendars = this.calendarLocal.holidays;

      if (this.calendarLocal.parent) {
        filteredCalendars = this.calendarLocal.holidays.filter(h => h.calendarGid !== this.calendarLocal.parent);
      }

      // Set the original thumbprint so the dirty form wanring doesn't appear
      // This must be on the next tick because the currentCalendarThumbprint watcher doesn't catch the
      // holiday.calendarGid assignment (that happens above) until after this line runs.
      // Setting the originalCalendarThumbprint on the next tick ensures that the watcher has had a chance
      // to run it's logic first.
      this.$nextTick(() => {
        this.originalCalendarThumbprint = this.currentCalendarThumbprint;
      });

      const cleanCalendar = new CalendarDetails({
        gid: calendarGid,
        parent: this.calendarLocal.parent,
        hasChildren: this.calendar?.hasChildren || false,
        name: this.calendarLocal.name,
        workingWeekHours: this.calendarLocal.workingWeekHours,
        holidays: filteredCalendars,
        status: this.calendar?.status || OtCalendarStatus.Active,
      });

      return cleanCalendar;
    }
  }

  private getCalendarThumbprint(calendar: CalendarDetails | undefined): string {
    const sortedHolidays = [...this.sortCalendarHolidays(calendar?.holidays || [])];
    const vals = {
      gid: calendar?.gid || '',
      name: calendar?.name || '',
      parentGid: calendar?.parent || '',
      workingWeekHours: {
        monday: (calendar?.workingWeekHours?.monday || 0).toString() || '',
        tuesday: (calendar?.workingWeekHours?.tuesday || 0).toString() || '',
        wednesday: (calendar?.workingWeekHours?.wednesday || 0).toString() || '',
        thursday: (calendar?.workingWeekHours?.thursday || 0).toString() || '',
        friday: (calendar?.workingWeekHours?.friday || 0).toString() || '',
        saturday: (calendar?.workingWeekHours?.saturday || 0).toString() || '',
        sunday: (calendar?.workingWeekHours?.sunday || 0).toString() || '',
      },
      holidays:
        sortedHolidays.map(h => {
          return {
            gid: h.gid || '',
            name: h.name || '',
            calendarGid: h.calendarGid || '',
            dates: h.dates.map(d => {
              return {
                gid: d.gid || '',
                date: d.date.toString() || '',
              };
            }),
          };
        }) || '',
    };
    return JSON.stringify(vals);
  }

  // * Converts the numberic value attatched to the calendar model to a boolean that
  // * can be consumed by the checkbox group component.
  private parseWorkingDays(weekHours: WorkingWeekHours) {
    const parsedHours: CheckboxGroupItem<string>[] = [];
    for (const [day, hours] of Object.entries(weekHours)) {
      if (hours !== 0) {
        parsedHours.push(new CheckboxGroupItem(capitalizeString(day), day));
      }
    }
    return parsedHours;
  }

  // * LIFECYCLE
  private async created() {
    const orgId = this.orgGid;

    if (orgId) {
      const orgCalendarsResponse = await executeApi(
        () => this.api.organisation().getOrganisationCalendars(orgId),
        'Load Organisation Calendars',
      );
      if (orgCalendarsResponse.success && orgCalendarsResponse.data?.calendars) {
        const parentlessCalendars = orgCalendarsResponse.data.calendars.filter(calendar => !calendar.hasParent);
        this.organisationCalendars = parentlessCalendars.map(c => {
          const parsedCalendar = Calendar.createFromApiResponse(c);
          return {
            label: parsedCalendar.name,
            data: parsedCalendar,
          };
        });
      }
    }

    // * Fill in details from supplied calendar, if we have one. Otherwise, use default empty values.
    if (this.calendar) {
      this.calendarLocal.name = this.calendar.name;
      if (this.calendar.parent) {
        const foundBaseCalendar = this.organisationCalendars.find(c => {
          if (c.data) {
            return c.data.gid === this.calendar?.parent;
          }
        });
        if (foundBaseCalendar) {
          this.selectedBaseCalendar = foundBaseCalendar;
        }
      }
      this.selectedWorkingDays = this.parseWorkingDays(this.calendar.workingWeekHours);

      this.calendarLocal.workingWeekHours = this.calendar.workingWeekHours;
      this.calendarLocal.parent = this.calendar.parent;
      this.calendarLocal.gid = this.calendar.gid;

      this.calendarLocal.holidays = this.calendar.holidays;
      if (this.isDuplicate) {
        this.calendarLocal.name += ' Copy';
      }
    }

    this.originalCalendarThumbprint = this.getCalendarThumbprint(this.calendarLocal);
    this.currentCalendarThumbprint = this.getCalendarThumbprint(this.calendarLocal);
  }
}
