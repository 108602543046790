import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';
import OtProjectsIndex from '@/areas/projects/ot-projects-index.vue';
import OtProjectsLayout from '@/areas/projects/ot-projects-layout.vue';
import OtProjectDetails from '@/areas/projects/ot-project-details.vue';
import OtEditProjectDetails from '@/areas/projects/ot-edit-project-details.vue';
import OtProjectContractsIndex from '@/areas/projects/contracts/ot-project-contracts-index.vue';
import OtProjectClaimsIndex from '@/areas/projects/claims/ot-project-claims-index.vue';
import OtProjectUsersIndex from '@/areas/projects/users/ot-project-users-index.vue';
import OtProjectUserLayout from '@/areas/projects/users/ot-project-user-layout.vue';
import OtProjectUserDetails from '@/areas/projects/users/ot-project-user-details.vue';
import OtEditProjectUserDetails from '@/areas/projects/users/ot-edit-project-user.vue';
import OtProjectUserContractsIndex from '@/areas/projects/users/ot-project-user-contracts-index.vue';

import { BreadcrumbsId } from '@/components/global/common-models';

export const ROUTE_PROJECT = 'Projects';
export const ROUTE_PROJECT_DETAILS = 'ProjectDetails';
export const ROUTE_PROJECT_USERS = 'ProjectUsers';

export const ROUTE_PROJECT_USER_LAYOUT = 'ProjectUserLayout';
export const ROUTE_PROJECT_USER_DETAILS = 'ProjectUserDetails';
export const ROUTE_PROJECT_USER_CONTRACTS = 'ProjectUserContracts';
export const ROUTE_PROJECT_USER_EDIT = 'ProjectUserEdit';

export const ROUTE_PROJECT_CONTRACTS = 'ProjectContracts';
export const ROUTE_PROJECT_WORKFLOWS = 'ProjectWorkflows';
export const ROUTE_PROJECT_DOCUMENTS = 'ProjectDocuments';
export const ROUTE_PROJECT_EDIT = 'ProjectEdit';
export const ROUTE_PROJECT_CREATE = 'ProjectCreate';

/** Use this one if you just need to link to The Project */
export const ROUTE_PROJECT_DEFAULT = ROUTE_PROJECT_CONTRACTS;

export const projectsRoutes: Array<RouteConfig> = [
  {
    path: '/projects',
    name: ROUTE_PROJECT,
    component: OtProjectsIndex,
    meta: {
      breadcrumb: 'projects',
      requiresAuth: true,
    },
  },
  {
    path: '/projects/create',
    name: ROUTE_PROJECT_CREATE,
    component: OtEditProjectDetails,
    meta: {
      requiresAuth: true,
      breadcrumb: 'Projects/Create',
    },
  },
  {
    path: '/projects/:projectGid',
    component: OtProjectsLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        // in https://redgum.atlassian.net/browse/OTMAN-497 we changed the order of the tabs
        // there is no longer a sensible default slash for a project (it used to be details)
        // so, redirect to contracts if they don't specify anything
        path: '/',
        redirect: '/projects/:projectGid/contracts',
      },
      {
        path: 'details',
        name: ROUTE_PROJECT_DETAILS,
        component: OtProjectDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Details`,
        },
      },
      {
        path: 'users',
        name: ROUTE_PROJECT_USERS,
        component: OtProjectUsersIndex,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Users`,
        },
      },
      {
        path: 'contracts',
        name: ROUTE_PROJECT_CONTRACTS,
        component: OtProjectContractsIndex,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts`,
        },
      },
      {
        path: 'workflows',
        name: ROUTE_PROJECT_WORKFLOWS,
        component: OtProjectClaimsIndex,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Workflows`,
        },
      },
      {
        path: 'documents',
        name: ROUTE_PROJECT_DOCUMENTS,
        component: OtComingSoon,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Documents`,
        },
      },
    ],
  },
  {
    path: '/projects/:projectGid/edit',
    name: ROUTE_PROJECT_EDIT,
    component: OtEditProjectDetails,
    meta: {
      requiresAuth: true,
      breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Edit`,
    },
  },
  {
    path: '/projects/:projectGid/users/:userGid/edit',
    name: ROUTE_PROJECT_USER_EDIT,
    component: OtEditProjectUserDetails,
    meta: {
      requiresAuth: true,
      breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Users/${BreadcrumbsId.UserId}/Edit`,
    },
  },
  {
    path: '/projects/:projectGid/users/:userGid',
    name: ROUTE_PROJECT_USER_LAYOUT,
    component: OtProjectUserLayout,
    meta: {
      requiresAuth: true,
      breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Users/${BreadcrumbsId.UserId}`,
    },
    children: [
      {
        path: '/',
        name: ROUTE_PROJECT_USER_DETAILS,
        component: OtProjectUserDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Users/${BreadcrumbsId.UserId}`,
        },
      },
      {
        path: 'contracts',
        name: ROUTE_PROJECT_USER_CONTRACTS,
        component: OtProjectUserContractsIndex,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Users/${BreadcrumbsId.UserId}/Contracts`,
        },
      },
    ],
  },
];
