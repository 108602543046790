


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtCheckboxGroup,
  },
})
export default class WfOutlinedCheckboxListEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private requiredMessage: string | null = null;

  // * COMPUTED
  private get items(): CheckboxGroupItem<string>[] {
    return this.question.optionsOrdered.map(o => new CheckboxGroupItem(o.title, o.key)) || [];
  }

  private localValuePrivate: CheckboxGroupItem<string>[] = [];
  private get localValue(): CheckboxGroupItem<string>[] {
    return this.localValuePrivate;
  }
  private set localValue(val: CheckboxGroupItem<string>[]) {
    this.localValuePrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-outlined-checkbox-list-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const val = this.result?.values?.map(v => this.findCheckboxItemFromKey(v.key)) || [];
    this.localValuePrivate = val.filter(i => i !== undefined) as CheckboxGroupItem<string>[];
  }

  // * METHODS
  private findCheckboxItemFromKey(key: string): CheckboxGroupItem<string> | undefined {
    return this.items.find(i => i.value === key);
  }

  public onChange() {
    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.localValue.map(
        (v, index) =>
          new OtDataDrivenValue({
            key: v.value,
            orderIndex: index,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
