











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class LogBackIn extends Vue {
  // * PROPS
  @Prop() private prop: unknown;

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
  private created() {
    this.$router.replace('/');
  }
}
