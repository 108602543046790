import { IExtendedColumnData, IColumnData } from './ot-table-models';

export const maxPageItemCount = 16;

export function sortTableData<T>(
  tableColumns: IExtendedColumnData<T>[],
  activeColumn: IExtendedColumnData<T>,
  bodyData: Array<T>,
) {
  const primaryColumn = activeColumn;
  const remainingColumns = tableColumns.filter(column => column !== primaryColumn);
  const columns = [primaryColumn, ...remainingColumns];
  const resultData = [...bodyData];
  resultData.sort((a, b) => {
    for (const column of columns) {
      if (column.sortFunction) {
        const result = column.sortFunction(a, b, column.ascending);
        if (result) {
          return result;
        }
      }
    }
    // got all the way to here, they really must be the same
    return 0;
  });
  return resultData;
}

export function handleHeaderClick(tableColumns: IColumnData[], activeColumn: IColumnData, clickedHeader: IColumnData) {
  if (activeColumn.key !== clickedHeader.key) {
    tableColumns.forEach(c => {
      c.isActive = false;
      c.ascending = true;
    });
    const foundIndex = tableColumns.findIndex(c => c.key === clickedHeader.key);
    activeColumn = tableColumns[foundIndex];
    activeColumn.isActive = true;
  } else {
    activeColumn.ascending = !activeColumn.ascending;
  }
  return {
    tableColumns,
    activeColumn,
  };
}

export function getPaginatedBodyData<T>(bodyData: Array<T>) {
  const chunkSize = maxPageItemCount;
  const pages = [];
  for (let i = 0; i < bodyData.length; i += chunkSize) {
    pages.push(bodyData.slice(i, i + chunkSize));
  }
  return pages;
}

// Sort the numbers in ascending order
// If a value is null then it is positioned at the end
export function sortNullableNumbers(a: number | null, b: number | null) {
  if (a !== null && b !== null) {
    return a - b;
  }
  if (a === null && b === null) {
    return 0;
  }
  if (a !== null && b === null) {
    return -1;
  }
  if (a === null && b !== null) {
    return 1;
  }
  return 0;
}

export function sortNullableDates(a: Date | null, b: Date | null) {
  return sortNullableNumbers(a?.getTime() || null, b?.getTime() || null);
}
