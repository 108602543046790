






























import { Component, Vue, Prop } from 'vue-property-decorator';

export enum OtBtnSize {
  Tiny = 'tiny',
  Small = 'small',
  Large = 'large',
}

export enum OtBtnStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
  Outline = 'outline',
  OutlineInverse = 'outline-inverse',
  Ghost = 'ghost',
  Destructive = 'destructive',
}

export enum OtBtnType {
  Prepend = 'prepend',
  Text = 'text',
  TextLeft = 'text-left',
  TextMiddle = 'text-middle',
  TextRight = 'text-right',
  Icon = 'icon',
  Append = 'append',
}

@Component({})
export default class OtButton extends Vue {
  // * PROPS
  @Prop({ default: OtBtnSize.Small }) private btnSize!: OtBtnSize;
  @Prop() private btnStyle?: OtBtnStyle;
  @Prop({ default: OtBtnType.Text }) private btnType?: OtBtnType;
  @Prop(String) private icon!: string;
  @Prop() private on?: Event;
  @Prop(Boolean) offsetTop?: boolean;
  @Prop(Boolean) disabled?: boolean;

  // * DATA

  // * COMPUTED
  private get className() {
    let className = `ot-button ${this.btnSize}`;

    className += ` ${this.btnType}`;
    // * Only apply style class if prop is provided.
    if (this.btnStyle) {
      className += ` ${this.btnStyle}`;
    }
    if (this.disabled) {
      className += ` --disabled`;
    }
    return className;
  }

  private get isPrepend() {
    return this.btnType === OtBtnType.Prepend;
  }

  private get isText() {
    return (
      this.btnType === OtBtnType.Text ||
      this.btnType === OtBtnType.TextLeft ||
      this.btnType === OtBtnType.TextMiddle ||
      this.btnType === OtBtnType.TextRight
    );
  }

  private get isIcon() {
    return this.btnType === OtBtnType.Icon;
  }

  private get isAppend() {
    return this.btnType === OtBtnType.Append;
  }

  private get isDestructive() {
    return this.btnStyle === OtBtnStyle.Destructive;
  }

  private get smallIcons() {
    return this.btnSize === 'tiny' || this.btnSize === 'small';
  }

  private get appendIcon() {
    return this.offsetTop ? '$chevronUp' : '$chevronDown';
  }

  private get spinnerSize() {
    return this.btnSize === 'large' ? '18' : '12';
  }

  // * WATCHERS

  // * METHODS
  private getIconSize() {
    let iconSize = '';
    switch (this.btnSize) {
      case OtBtnSize.Tiny:
        iconSize = 'small';
    }
    return iconSize;
  }

  // * LIFECYCLE
}
