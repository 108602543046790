































































import { Component, Vue } from 'vue-property-decorator';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtButton from '@/components/global/ot-button.vue';
import { InvitableUser } from '@/areas/invitations/invitation-models';
import { blurCombos } from '@/utils/type-utils';

@Component({
  components: {
    OtComboBox,
    OtFieldArchetype,
    OtButton,
  },
})
export default class OtComboBoxWrapper extends Vue {
  //   create items which is a llist of strings
  private items = [
    'Rail Project',
    'Civil Project',
    'Council Upgrade',
    'Road Upgrade',
    'Water Upgrade',
    'Sewer Upgrade',
    'Stormwater Upgrade',
    'Other',
  ];

  private defaultModelPrivate = '';
  private get defaultModel() {
    return this.defaultModelPrivate;
  }
  private set defaultModel(val: string) {
    console.log('ot-combo-box-wrapper -> defaultModel setter', { current: this.defaultModelPrivate, val });
    this.defaultModelPrivate = val;
  }

  private pretendSave() {
    console.log('ot-combo-box-wrapper -> pretendSave click', { current: this.defaultModelPrivate });
    blurCombos(this.$refs.defaultCombo);
    this.$nextTick(() => {
      // model now contains the correct data
      console.log('ot-combo-box-wrapper -> pretendSave next tick', { current: this.defaultModelPrivate });
    });
  }

  // stuff for the object combo box
  private objectItems = [
    new InvitableUser({
      gid: 'a',
      firstName: 'Bob',
      lastName: 'Smith',
      email: 'bob@smith.com',
    }),
    new InvitableUser({
      gid: 'b',
      firstName: 'Bill',
      lastName: 'Jones',
      email: 'bill@jones.com',
    }),
  ];

  // compiler 🤫
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private objectModelPrivate: any | null = null;
  private get objectModel() {
    return this.objectModelPrivate;
  }
  // compiler 🤫
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private set objectModel(val: any | null) {
    console.log('object model set', val);
    this.objectModelPrivate = val;
  }
}
