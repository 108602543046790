// Data Driven Depends On Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2372927556/Data+Driven+Depends+On+Object

import { DataDrivenDependsOnModel as ApiDataDrivenDependsOnModel } from '@/services/generated/api';

export type OtDependsOnNotActiveType = 'Disable' | 'Hide';

export interface IDataDrivenDependsOn {
  options: string[][];
  onNotActive: OtDependsOnNotActiveType | null;
  resetToParentResponseOnNotActive?: boolean;
}

export class OtDataDrivenDependsOn implements IDataDrivenDependsOn {
  public options!: string[][];
  public onNotActive!: OtDependsOnNotActiveType | null;
  public resetToParentResponseOnNotActive?: boolean;

  constructor(value: IDataDrivenDependsOn) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenDependsOnModel) {
    return new OtDataDrivenDependsOn({
      options: model.options,
      onNotActive: (model.onNotActive as OtDependsOnNotActiveType) || null,
      resetToParentResponseOnNotActive: model.resetToParentResponseOnNotActive,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenDependsOn>) {
    return new OtDataDrivenDependsOn({
      options: json.options || [],
      onNotActive: json.onNotActive || null,
    });
  }
}
