























import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { IInputTab } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';

import { ROUTE_CONTRACT_USER_DETAILS } from './contract-routes';

import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { ContractUserDetails } from './contract-user-models';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
    OtButton,
    OtTag,
  },
})
export default class OtContractUserLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private loading = false;
  private contractUser: ContractUserDetails = ContractUserDetails.createEmpty();
  private api = new OtApi();

  private get contractUserTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'User Details',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_CONTRACT_USER_DETAILS,
        },
      },
    ];
  }

  // * COMPUTED

  private get sectionTitle() {
    return this.contractUser.contractName + ' User Details: ';
  }

  private get subTitle() {
    return this.contractUser.firstName + ' ' + this.contractUser.lastName || '';
  }

  private get projectGid() {
    return this.$route.params.projectGid || '';
  }

  private get contractGid() {
    return this.$route.params.contractGid || '';
  }

  private get projectName() {
    return this.contractUser.projectName || '';
  }

  private get contractName() {
    return this.contractUser.contractName || '';
  }
  private get userName() {
    return this.contractUser.firstName + ' ' + this.contractUser.lastName || '';
  }

  private get userGid() {
    return this.$route.params.userGid || '';
  }

  private get contractUserStatus(): OtUserStatus {
    const status = this.contractUser.contractUserStatus?.status;
    // NOTE - the default here is inactive ?
    return status || OtUserStatus.Inactive;
  }

  private get hasContractUserStatus(): boolean {
    return Boolean(this.contractUser.contractUserStatus);
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    if (!this.contractUser || this.contractUser.gid !== this.userGid) {
      this.loading = true;
      await this.handleReloadContractUser();

      this.loading = false;
    }
  }

  // * METHODS
  setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
  }
  setContractNameToBreadcrumb() {
    vxm.breadcrumbs.setContractName({ contractName: this.contractName });
  }
  setUserNameToBreadcrumb() {
    vxm.breadcrumbs.setUserName({ userName: this.userName });
  }
  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  private async handleReloadContractUser(callback?: () => void) {
    const result = await executeApi(
      () => this.api.contracts().getContractUserDetails(this.contractGid, this.userGid),
      'Load Contract User',
    );
    if (result.success && result.data && result.data) {
      this.contractUser = ContractUserDetails.createFromApiResponse(result.data);
      this.setProjectNameToBreadcrumb();
      this.setContractNameToBreadcrumb();
      this.setUserNameToBreadcrumb();
    }

    if (callback) {
      callback();
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();

    if (this.projectGid) {
      vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
    }

    if (this.contractGid) {
      vxm.breadcrumbs.setContractName({ contractName: this.contractName });
    }
    if (this.userName) {
      vxm.breadcrumbs.setUserName({ userName: this.userName });
    }
  }
}
