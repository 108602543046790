import { IAddressDisplayModel } from '@/components/global/common-models';
import { AddressModel } from '@/services/generated/api';

export enum AccessType {
  Projects = 'Projects',
  Contracts = 'Contracts',
  Organisations = 'Organisations',
}

export class StreetAddressModel implements IAddressDisplayModel {
  public placeId!: string | null;
  public addressLine1!: string;
  public addressLine2!: string | null;
  public suburb!: string;
  public postcode!: string;
  public state!: string;
  public country!: string;

  public constructor(value: IAddressDisplayModel) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new StreetAddressModel({
      placeId: null,
      addressLine1: '',
      addressLine2: null,
      suburb: '',
      postcode: '',
      state: '',
      country: '',
    });
  }

  public static createFromApiResponse(response: AddressModel) {
    return new StreetAddressModel({
      placeId: null,
      addressLine1: response.line1 || '',
      addressLine2: response.line2 || null,
      suburb: response.suburb || '',
      postcode: response.postcode || '',
      state: response.state || '',
      country: response.country || '',
    });
  }
}
