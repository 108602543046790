// Data Driven Value Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2358345868/Data+Driven+Value+Object

import { DataDrivenValueModel as ApiDataDrivenValueModel } from '@/services/generated/api';

export interface IDataDrivenValue {
  key: string;
  title?: string | null;
  description?: string | null;
  subOptionKey?: string | null;
  orderIndex: number;
}

export class OtDataDrivenValue implements IDataDrivenValue {
  public key!: string;
  public title?: string | null | undefined;
  public description?: string | null | undefined;
  public subOptionKey?: string | null | undefined;
  public orderIndex!: number;

  constructor(value: IDataDrivenValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenValueModel) {
    return new OtDataDrivenValue({
      key: model.key,
      title: model.title,
      description: model.description,
      subOptionKey: model.subOptionKey,
      orderIndex: model.orderIndex,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenValue>) {
    return new OtDataDrivenValue({
      key: model.key || '',
      title: model.title || null,
      description: model.description || null,
      subOptionKey: model.subOptionKey || null,
      orderIndex: model.orderIndex ?? 0,
    });
  }

  public static createRequestModel(model: OtDataDrivenValue) {
    return new ApiDataDrivenValueModel({
      key: model.key,
      title: model.title || undefined,
      description: model.description || undefined,
      subOptionKey: model.subOptionKey || undefined,
      orderIndex: model.orderIndex,
    });
  }

  public createRequestModel() {
    return OtDataDrivenValue.createRequestModel(this);
  }
}
