











































import { Component, Ref, Vue } from 'vue-property-decorator';
import { vxm } from '@/store';
import { FormModalParams, FormModalResult } from '@/components/global/modal/form-modal-models';
import OtTextField from '@/components/global/ot-text-field.vue';
import { IVForm } from '@/utils/type-utils';

@Component({
  components: {
    OtTextField,
  },
})
export default class OtFormModalWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('formContentRef') private formContentRef!: IVForm;
  @Ref('formContentRef1') private formContentRef1!: IVForm;

  // * DATA
  private boundData1 = '';
  private boundData2 = '';
  private displayData = '';
  private result: FormModalResult | null = null;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private async openPlain() {
    const params = new FormModalParams({
      title: 'Modal Title',
      formRef: this.formContentRef,
    });
    this.result = await vxm.modal.openFormModal(params);
    if (this.result.ok) {
      this.displayData = this.boundData1;
    }
  }

  private async openRequired() {
    const params = new FormModalParams({
      title: 'Modal Title',
      formRef: this.formContentRef1,
    });
    this.result = await vxm.modal.openFormModal(params);
    if (this.result.ok) {
      this.displayData = this.boundData2;
    }
  }

  // * LIFECYCLE
}
