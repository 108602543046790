<template>
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.6 5.21326L1.34064e-06 0.786903L9.2 0.786904L4.6 5.21326Z" fill="#00008D" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
