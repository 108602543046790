


































import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { IDataDrivenModelWithKey, IDataDrivenModelWithValues } from '@/wf-components/models/data-driven-result';
import { getSelectedKeysForDependsOn, passesDependsOn } from '@/wf-components/utils/depends-on-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtWorkflowEngineProcessor,
  },
})
export default class OtClaimRuns extends Vue {
  // * PROPS
  // claim details came long for the ride when this was copy pasted from a View. We don't need it, so we'll not consume it
  // @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private definition!: OtDataDrivenDefinition;
  @Prop() private instance!: OtDataDrivenInstance;
  @Prop() private instances!: OtDataDrivenInstances;
  @Prop() private evaluation!: OtClientEvaluation;
  // * REFS

  // * DATA

  // * COMPUTED
  private get pageLayouts() {
    const pageLayouts = this.definition.layouts.filter(l => l.type === LayoutTypeEnum.Page);

    const selectedKeys = getSelectedKeysForDependsOn(this.instance, this.definition, this.evaluation);

    const layoutsThatPassDependsOn =
      pageLayouts.filter(layout => passesDependsOn(layout.dependsOn, selectedKeys)) || [];
    return layoutsThatPassDependsOn;
  }
  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
