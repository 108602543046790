
























import { IInputAction, IInputTab } from '@/components/global/common-models';
import { Component, Vue, Watch } from 'vue-property-decorator';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { CalendarDetails } from './models/calendar-models';
import { vxm } from '@/store';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
  },
})
export default class OtOrganisationCalendarLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private primaryContextBtn: IInputAction = {
    actionName: 'New Calendar',
    action: { name: 'AddCalendar' },
  };

  private api = new OtApi();
  private calendar: CalendarDetails = CalendarDetails.createEmpty();
  private loading = false;
  private viewTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Calendar Details',
      tabRoute: {
        name: 'ViewCalendar',
      },
    },
  ];

  private editTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Calendar Details',
      tabRoute: {
        name: 'EditCalendar',
      },
    },
  ];
  private addTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Calendar Details',
      tabRoute: {
        name: this.addTabRouteName,
      },
    },
  ];

  // * COMPUTED
  private get orgId() {
    return this.$route.params.orgId;
  }

  private get orgList() {
    return vxm.userProfile.userProfile?.organisations || [];
  }

  private get orgName() {
    return this.orgList.find(org => org.gid === this.orgId)?.name || '';
  }

  private get addTabRouteName() {
    if (this.currentRouteName === 'AddCalendar' || this.currentRouteName === 'DuplicateCalendar') {
      return this.currentRouteName;
    }
    return '';
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  private get calendarTabs() {
    switch (this.currentRouteName) {
      case 'ViewCalendar':
        return this.viewTabs;
      case 'EditCalendar':
        return this.editTabs;
      case 'AddCalendar':
      case 'DuplicateCalendar':
        return this.addTabs;
      default:
        return this.viewTabs;
    }
  }

  private get sectionTitle() {
    switch (this.currentRouteName) {
      case 'ViewCalendar':
        return this.calendar.name;
      case 'EditCalendar':
        return 'Edit Calendar';
      case 'AddCalendar':
      case 'DuplicateCalendar':
        return 'New Calendar';
      default:
        return this.calendar.name;
    }
  }

  private get newCalendarButtonDisabled() {
    return this.currentRouteName !== 'ViewCalendar' ? true : false;
  }

  private get calendarProp() {
    return this.currentRouteName === 'AddCalendar' ? undefined : this.calendar;
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.setOrgNameToBreadcrumb();
    const calendarGid = this.$route.params.calendarGid;
    if (calendarGid) {
      this.loading = true;
      await this.getCalendarDetails(calendarGid);
      this.loading = false;
    }
  }

  // * METHODS
  private async getCalendarDetails(calendarGid: string) {
    const apiResponse = await executeApi(
      () => this.api.calendar().getCalendarDetails(calendarGid),
      'Load Calendar Details',
    );
    if (apiResponse.data) {
      this.calendar = CalendarDetails.createFromApiResponse(apiResponse.data);
      vxm.breadcrumbs.setCalendarName({ calendarName: this.calendar.name });
    }
  }

  private setOrgNameToBreadcrumb() {
    vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
  }
}
