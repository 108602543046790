













































































































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { OtUserStatus } from '@/types/status-enums';
import { RawLocation } from 'vue-router/types/router';
import { AccessType } from '../../../models/shared-models';

import { ROUTE_PROJECT_DETAILS } from '@/areas/projects/projects-routes';
import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { parsedApiOrgUserStatusEnums } from '@/models/user-models';
import {
  PostSetContractUserStatusUserStatusModel,
  PostSetProjectUserStatusUserStatusModel,
} from '@/services/generated/api';
import { OrganisationUserProject } from '../models/organisation-user-project-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';
import { OrganisationUserDetails } from '../models/organisation-user-models';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (project: OrganisationUserProject) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtUserStatusTags,
    OtInviteUserDialog,
  },
})
export default class OtOrganisationUserProjectsTable extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private organisationUserProjects!: OrganisationUserProject[];
  @Prop() private organisationUser!: OrganisationUserDetails;
  @Prop() private organisationGid!: string;

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  private tableColumns: IExtendedColumnData<OrganisationUserProject>[] = [
    {
      index: 0,
      label: 'Code',
      key: 'code',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: OrganisationUserProject.compareByCode,
    },
    {
      index: 1,
      label: 'Projects',
      key: 'name',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUserProject.compareByName,
    },
    {
      index: 2,
      label: 'Project Role',
      key: 'title',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUserProject.compareByProjectRole,
    },
    {
      index: 3,
      label: 'Org.',
      key: 'org',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 4,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 5,
      label: 'Contracts',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 6,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private activeColumn = this.tableColumns[0];

  private openUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Inactive', OtUserStatus.Inactive),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
    new CheckboxGroupItem('Rejected Invite', OtUserStatus.RejectedInvitation),
  ];

  private selectedUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
  ];

  private typeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [
    new CheckboxGroupItem('Projects', AccessType.Projects),
    new CheckboxGroupItem('Contracts', AccessType.Contracts),
    new CheckboxGroupItem('Organisation', AccessType.Organisations),
  ];

  private selectedTypeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [];

  // * COMPUTED

  private get sortedBodyData() {
    const sortedOrgUsers = sortTableData(this.tableColumns, this.activeColumn, this.organisationUserProjects);

    return sortedOrgUsers;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedUserStatusFilters.map(s => s.value);
    const parsedAccessTypes = this.selectedTypeOfAccessFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        OrganisationUserProject.filterByUserStatus(parsedStatuses, row) &&
        OrganisationUserProject.filterByTypeOfAccess(parsedAccessTypes, row) &&
        OrganisationUserProject.filterByName(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS

  // * METHODS

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private getOrgUserProjectDetailsRoute(project: OrganisationUserProject): RawLocation {
    return {
      name: ROUTE_PROJECT_DETAILS,
      params: { projectGid: project.gid },
    };
  }

  private handleDeactivateProjectUser = (project: OrganisationUserProject): IMenuOption => {
    return {
      key: project.gid,
      label: 'Deactivate for Project User',
      onClick: () => {
        this.setProjectUserToDeactivated(project);
      },
    };
  };

  private handleDeactivateInAllContracts = (project: OrganisationUserProject): IMenuOption => {
    return {
      key: project.gid,
      label: 'Deactivate for All Contracts',
      onClick: () => {
        this.setOrgUserToDeactivatedForAllContracts(project);
      },
    };
  };

  private handleReinviteUser = (project: OrganisationUserProject): IMenuOption => {
    return {
      key: project.gid,
      label: 'Reinvite User',
      onClick: () => {
        this.reInviteUser(project);
      },
    };
  };

  private async setProjectUserToDeactivated(project: OrganisationUserProject) {
    this.rowLoadingStates[project.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to deactivate project user ${project.projectUser?.gid}, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetProjectUserStatusUserStatusModel[] = [
      new PostSetProjectUserStatusUserStatusModel({
        gid: project.projectUser?.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (!body[0].gid) {
      this.rowLoadingStates[project.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.projectUser().postSetProjectUserStatus(body),
      `Set Project User ${project.projectUser?.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUserProjects', () => {
        this.rowLoadingStates[project.gid] = false;
      });
    } else {
      this.rowLoadingStates[project.gid] = false;
    }
  }

  private async setOrgUserToDeactivatedForAllContracts(project: OrganisationUserProject) {
    this.rowLoadingStates[project.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      // NOTE which id are be to give to the warning ? should it be a n array of contract ids ?
      message: `Unable to deactivate user ${project.organisationUser?.gid} in all contracts, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetContractUserStatusUserStatusModel[] = project.contracts
      .filter(c => c.contractUser?.status === OtUserStatus.Active || c.contractUser?.status === OtUserStatus.Invited)
      .map(c => {
        return new PostSetContractUserStatusUserStatusModel({
          gid: c.contractUser?.gid || '',
          status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
        });
      });

    if (body.length === 0) {
      this.rowLoadingStates[project.gid] = false;
      return;
    }
    const postResponse = await executeApi(
      () => this.api.contractUser().postSetContractUserStatus(body),
      `Deactivating user in all contracts`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUserProjects', () => {
        this.rowLoadingStates[project.gid] = false;
      });
    } else {
      this.rowLoadingStates[project.gid] = false;
    }
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async reInviteUser(project: OrganisationUserProject) {
    this.rowLoadingStates[project.gid] = true;

    const currentUserOrganisationUser = project.currentUserOrganisationUser;
    const currentUserProjectUser = project.currentUserProjectUser;

    const isCurrentUserOrganisationUserActive = currentUserOrganisationUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectUserActive = currentUserProjectUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectOrOrganisationUserActive =
      isCurrentUserOrganisationUserActive || isCurrentUserProjectUserActive;

    const isOrgUserReinviteable =
      project.organisationUser?.status === OtUserStatus.Inactive ||
      project.organisationUser?.status === OtUserStatus.RejectedInvitation;
    const isProjectUserReinviteable =
      project.projectUser?.status === OtUserStatus.Inactive ||
      project.projectUser?.status === OtUserStatus.RejectedInvitation;

    const reInviteToOrg =
      isCurrentUserOrganisationUserActive && isOrgUserReinviteable ? project.organisationUser : null;
    const reInviteToProjects =
      // in order to check if it is reinvitable, it has to be non null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isCurrentUserOrganisationUserActive && isProjectUserReinviteable ? [project.projectUser!] : [];
    const reinviteToContracts = isCurrentUserProjectOrOrganisationUserActive
      ? project.contracts
          .filter(
            x =>
              x.contractUser?.status === OtUserStatus.Inactive ||
              x.contractUser?.status === OtUserStatus.RejectedInvitation,
          )
          // in order to check if it is reinvitable, it has to be non null
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map(x => x.contractUser!)
      : [];

    const params = new InviteUserDialogParams({
      isReinvite: true,
      organisationGid: this.organisationGid,
      inviteToOrganisation: Boolean(reInviteToOrg),
      invitedUserGid: this.organisationUser.gid, // this is the user gid, not the org user gid
      userContracts: reinviteToContracts.map(x => x.gid),
      userProjects: reInviteToProjects.map(x => x.gid),
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }

    this.rowLoadingStates[project.gid] = false;
  }

  private getOrgUserProjectOptionMenuItems(project: OrganisationUserProject): IMenuOption[] {
    const results: IMenuOption[] = [];

    const currentUserOrgUser = project.currentUserOrganisationUser;
    const currentUserProjUser = project.currentUserProjectUser;
    const projectUser = project.projectUser;

    const isCurrentUserOrgUserActive = currentUserOrgUser?.status === OtUserStatus.Active;
    const isCurrentUserProjUserActive = currentUserProjUser?.status === OtUserStatus.Active;
    const isCurrentUserProjOrOrgUserActive = isCurrentUserOrgUserActive || isCurrentUserProjUserActive;
    const isProjectUserDeactivatable =
      projectUser?.status === OtUserStatus.Active || projectUser?.status === OtUserStatus.Invited;
    const hasDeactivatableContractUsers = project.contracts.some(
      c => c.contractUser?.status === OtUserStatus.Active || c.contractUser?.status === OtUserStatus.Invited,
    );
    const isProjectUserReinviteable =
      projectUser?.status === OtUserStatus.Inactive || projectUser?.status === OtUserStatus.RejectedInvitation;
    const hasReinviteableContractUsers = project.contracts.some(
      c =>
        c.contractUser?.status === OtUserStatus.Inactive || c.contractUser?.status === OtUserStatus.RejectedInvitation,
    );
    const isOrgUserReinviteable =
      project.organisationUser?.status === OtUserStatus.Inactive ||
      project.organisationUser?.status === OtUserStatus.RejectedInvitation;

    const canDeactivateProjectUser = isCurrentUserOrgUserActive && isProjectUserDeactivatable;

    if (canDeactivateProjectUser) {
      results.push(this.handleDeactivateProjectUser(project));
    }

    const canDeactivateInAllContracts = isCurrentUserProjOrOrgUserActive && hasDeactivatableContractUsers;

    if (canDeactivateInAllContracts) {
      results.push(this.handleDeactivateInAllContracts(project));
    }

    const canReinvite =
      (isCurrentUserOrgUserActive && isOrgUserReinviteable) ||
      (isCurrentUserOrgUserActive && isProjectUserReinviteable) ||
      (isCurrentUserProjOrOrgUserActive && hasReinviteableContractUsers);

    if (canReinvite) {
      results.push(this.handleReinviteUser(project));
    }

    return results;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.organisationUserProjects.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  // * LIFECYCLE
  private created() {
    this.setRowLoadingStates();
  }
}
