// Data Driven Button Details Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2441314632/Data+Driven+Button+Details+Object

import { ButtonDetailsModel } from '@/services/generated/api';

export interface IDataDrivenButtonDetails {
  buttonText: string | null;
  command: string | null;
  isHidden: boolean;
}

export class OtDataDrivenButtonDetails implements IDataDrivenButtonDetails {
  public buttonText!: string | null;
  public command!: string | null;
  public isHidden!: boolean;

  constructor(value: IDataDrivenButtonDetails) {
    Object.assign(this, value);
  }

  public static createFromJson(json: Partial<OtDataDrivenButtonDetails>) {
    return new OtDataDrivenButtonDetails({
      buttonText: json.buttonText || null,
      command: json.command || null,
      isHidden: json.isHidden || false,
    });
  }

  public static createFromApiResponse(model: ButtonDetailsModel) {
    return new OtDataDrivenButtonDetails({
      buttonText: model.buttonText || null,
      command: model.command || null,
      isHidden: model.isHidden || false,
    });
  }
}
