

















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import { INavigation } from './common-models';
import styles from '@/styles/variables.scss';
import { vxm } from '@/store';
import { ROUTE_WORKFLOW } from '@/areas/claims/claims-routes';

@Component({
  components: {
    OtButton,
  },
})
export default class OtNavigation extends Vue {
  // * PROPS
  @Prop({ default: 'username' }) private username!: string;
  @Prop() private overrideNavigationData?: INavigation[];
  @Prop({ default: true }) private isPartOfLayout!: boolean;
  // * REFS

  // * DATA
  private get defaultOrgId(): string {
    return vxm.userProfile.defaultOrganisationId;
  }

  private get defaultNavigationData(): INavigation[] {
    const results: INavigation[] = [
      {
        title: 'Dashboard',
        location: {
          name: 'Dashboard',
        },
        icon: '$pieChart',
        // This is disabled for MVP
        isDisabled: true,
      },
      {
        title: 'Projects',
        location: {
          name: 'Projects',
        },
        icon: '$folder',
      },
      {
        title: 'Workflows',
        location: {
          name: ROUTE_WORKFLOW,
        },
        icon: '$edit',
      },
      {
        title: 'Issues',
        location: {
          name: 'Issues',
        },
        icon: '$alertOctagon',
        // This is disabled for MVP
        isDisabled: true,
      },
      {
        title: 'Documents',
        location: {
          name: 'Documents',
        },
        icon: '$textFile',
        // This is disabled for MVP
        isDisabled: true,
      },
    ];

    // ideally we'd have this here and disabled, but unless it has an org id it
    // manages to be The Default route, so it's highlighted. Or something weird is going on
    // So, i'll hide it instead
    if (this.defaultOrgId) {
      results.push({
        title: 'Settings',
        location: {
          params: { orgId: this.defaultOrgId },
          name: 'Settings',
        },
        icon: '$settings',
        isDisabled: !this.defaultOrgId,
      });
    }

    return results;
  }

  private userProfilePath = '/user-profile';
  private userDropdown: INavigation[] = [
    {
      title: 'User Account',
      location: {
        name: 'UserProfile',
      },
    },
    {
      title: 'Logout',
      location: {
        name: 'Logout',
        path: '/account/logout',
      },
    },
  ];
  private isUserDropDownOpen = false;

  // Buttons
  private btnSize: OtBtnSize = OtBtnSize.Large;
  private btnType: OtBtnType = OtBtnType.Text;
  private btnStylePrimary = OtBtnStyle.Primary;
  private btnStyleGhost = OtBtnStyle.Ghost;
  private btnStyleDefault = OtBtnStyle.Default;
  private colourBlue = styles.otColorBlue500;
  private colourWhite = styles.otColorWhite;

  // * COMPUTED
  private get navigationData() {
    if (this.overrideNavigationData && this.overrideNavigationData.length > 0) {
      return this.overrideNavigationData;
    }
    return this.defaultNavigationData;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
