import { UserStatusWithActions } from '@/models/user-models';
import { ContractModel, GetProjectUserContractsResponseModel } from '@/services/generated/api';
import { OtContractStatus, OtProjectStatus, OtUserStatus } from '@/types/status-enums';
import { parsedOtProjectStatusEnums } from '../project-models';
import { parsedOtContractEnums } from '../contracts/contract-models';
import { AccessType } from '@/models/shared-models';

export interface IProjectUserContract {
  gid: string;
  reference: string;
  name: string;
  status: OtContractStatus;
  organisationGid: string;
  projectGid: string;
  projectCode: string;
  projectName: string;
  projectStatus: OtProjectStatus;
  organisationUser: UserStatusWithActions | null;
  projectUser: UserStatusWithActions | null;
  contractUser: UserStatusWithActions | null;
}

export class ProjectUserContract implements IProjectUserContract {
  public gid!: string;
  public reference!: string;
  public name!: string;
  public status!: OtContractStatus;
  public organisationGid!: string;
  public projectGid!: string;
  public projectCode!: string;
  public projectName!: string;
  public projectStatus!: OtProjectStatus;
  public organisationUser: UserStatusWithActions | null = null;
  public projectUser: UserStatusWithActions | null = null;
  public contractUser: UserStatusWithActions | null = null;

  public constructor(value: IProjectUserContract) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProjectUserContract({
      gid: '',
      reference: '',
      name: '',
      status: OtContractStatus.Active,
      organisationGid: '',
      projectGid: '',
      projectCode: '',
      projectName: '',
      projectStatus: OtProjectStatus.Active,
      organisationUser: null,
      projectUser: null,
      contractUser: null,
    });
  }

  public static createFromApiResponse(model: ContractModel): ProjectUserContract {
    return new ProjectUserContract({
      gid: model.gid,
      reference: model.reference,
      name: model.name,
      status: model.status ? parsedOtContractEnums[model.status] : OtContractStatus.Active,
      organisationGid: model.organisationGid,
      projectGid: model.projectGid,
      projectCode: model.projectCode,
      projectName: model.projectName,
      projectStatus: model.projectStatus ? parsedOtProjectStatusEnums[model.projectStatus] : OtProjectStatus.Active,
      organisationUser:
        model.organisations?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.organisations?.[0]) : null,
      projectUser: model.projects?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.projects?.[0]) : null,
      contractUser:
        model.contracts?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.contracts?.[0]) : null,
    });
  }

  public static compareByName(a: ProjectUserContract, b: ProjectUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const contractNameA = a.name;
    const contractNameB = b.name;
    return contractNameA.localeCompare(contractNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByReference(a: ProjectUserContract, b: ProjectUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const referenceA = a.reference;
    const referenceB = b.reference;
    return referenceA.localeCompare(referenceB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByContractRole(a: ProjectUserContract, b: ProjectUserContract, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const titleA = a.contractUser?.title || '';
    const titleB = b.contractUser?.title || '';
    // if they are both undefined, then we want to sort them as equal - otherwise if one is undefined,
    // we want to sort it as greater than the other one
    // logically that seems ok to me but again, you might have a better idea.
    // Treat empty strings as greater than any non-empty value
    if (!titleA && !titleB) {
      return 0;
    } else if (!titleA && titleB) {
      return 1 * sortModifier;
    } else if (titleA && !titleB) {
      return -1 * sortModifier;
    }

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, contract: ProjectUserContract) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return contract.name.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], contract: ProjectUserContract) {
    if (statuses.length) {
      return statuses.some(
        status =>
          contract.organisationUser?.status === status ||
          contract.projectUser?.status === status ||
          contract.contractUser?.status === status,
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], contract: ProjectUserContract) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return Boolean(contract.organisationUser);
          case AccessType.Projects:
            return Boolean(contract.projectUser);
          case AccessType.Contracts:
            return Boolean(contract.contractUser);
          default:
            return false;
        }
      });
    }
    return true;
  }
}
