












































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenModifyingFactor } from '../models/data-driven-result';
import OtFactorSelection, { IFactorSelectionObject } from '@/components/global/ot-factor-selection.vue';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtRadioGroup,
    OtTextarea,
    OtFactorSelection,
  },
})
export default class WfYesNoFactor extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private label: string | null = null;
  private description: string | null = null;
  private placeholder: string | null = null;
  private requiredMessage: string | null = null;
  private notesRquiredMessage = 'This field is mandatory';

  private noOption: IRadioGroupOption = {
    key: 'no',
    label: 'No',
  };
  private yesOption: IRadioGroupOption = {
    key: 'yes',
    label: 'Yes',
  };
  private radioOptions: IRadioGroupOption[] = [this.yesOption, this.noOption];

  // * COMPUTED
  private selectedValuePrivate: IRadioGroupOption | null = this.yesOption;
  private get selectedValue(): IRadioGroupOption | null {
    return this.selectedValuePrivate;
  }
  private set selectedValue(val: IRadioGroupOption | null) {
    this.selectedValuePrivate = val;
    this.onChange();
  }

  private factorObjectPrivate: IFactorSelectionObject = {
    factor: null,
    selectedType: null,
  };
  private get factorObject() {
    return this.factorObjectPrivate;
  }
  private set factorObject(val: IFactorSelectionObject) {
    this.factorObjectPrivate = val;
    this.onChange();
  }

  private notesValuePrivate: string | null = null;
  private get notesValue() {
    return this.notesValuePrivate;
  }
  private set notesValue(val: string | null) {
    this.notesValuePrivate = val;
    this.onChange();
  }

  private get durationLabel() {
    return this.question.columnHeadersOrdered[0]?.title || null;
  }
  private get durationSecondaryLabel() {
    return this.question.columnHeadersOrdered[1]?.title || null;
  }

  private get notesTitle() {
    return this.question.notesTitle;
  }

  private get notesPlaceholder() {
    return this.question.notesPlaceholder;
  }

  private get disableFactorControl() {
    return this.selectedValue !== this.yesOption;
  }

  private get areNotesRequired() {
    return this.selectedValue === this.yesOption;
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'ModifyingFactorModel') {
        return this.value.result;
      }
      console.warn(
        'wf-yes-no-factor-edit -> result -> ResultType is incorrect. Expected ModifyingFactorModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.selectedValuePrivate =
      this.result?.enabled === true ? this.yesOption : this.result?.enabled === false ? this.noOption : null;
    this.factorObjectPrivate.factor = this.result?.number || null;
    this.factorObjectPrivate.selectedType = this.result?.adjustment || null;
    this.notesValuePrivate = this.result?.text || null;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenModifyingFactor({
      resultType: 'ModifyingFactorModel',
      enabled: this.selectedValue === this.yesOption ? true : false,
      number: this.factorObject.factor,
      adjustment: this.factorObject.selectedType,
      text: this.notesValue || '',
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.description = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
