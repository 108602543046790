
























import { Component, Vue } from 'vue-property-decorator';
import OtPasswordField from '@/components/global/ot-password-field.vue';

@Component({
  components: {
    OtPasswordField,
  },
})
export default class OtPasswordWrapper extends Vue {
  // * PROPS

  // * DATA
  private data: unknown;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
