

















































import OtButton from '@/components/global/ot-button.vue';
import OtDurationSelection, { IDurationSelectionObject } from '@/components/global/ot-duration-selection.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtEmailContractorDialog from '@/components/specific-modals/ot-email-contractor-dialog.vue';
import {
  CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR,
  OtSegmentResolution,
  OtStatusType,
  PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR,
  segmentResolutionToString,
} from '@/types/status-enums';
import { IWizardContentComponent } from '@/types/wizard-types';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass } from '@/utils/validation-utils';
import { CalendarTypeEnum, SegmentProcessTypeEnum } from '@/wf-components/models/data-driven-enums';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import OtClaimRecommendation from './claim-workflow-and-outcomes/ot-claim-recommendation.vue';
import { AvailableActionObject, ClaimResultsObject, SegmentInstanceDetails } from './claims-models';

export enum SelectedImplementationOptionEnum {
  ImplementRecommendation = 'ImplementRecommendation',
  DontImplementAndSuspend = 'DontImplementAndSuspend',
  OverruleAndSetAsInvalid = 'OverruleAndSetAsInvalid',
  OverruleWithDifferentExtensionTime = 'OverruleWithDifferentExtensionTime',
  OverruleAndSetAsValid = 'OverruleAndSetAsValid',
  RevertOverruleAndSetToOneTrackRecommendation = 'RevertOverruleAndSetToOneTrackRecommendation',
  ImplementOverruleAndSetClaimAsInvalid = 'ImplementOverruleAndSetClaimAsInvalid',
  ImplementCustomOverruleRecommendation = 'ImplementCustomOverruleRecommendation',
  NoSaveAsDraft = 'NoSaveAsDraft',
  YesSaveAsInvalid = 'YesSaveAsInvalid',
  YesSaveAsValid = 'YesSaveAsValid',
}

export class OtClaimResultsFormResponse {
  public selectedImplementation!: SelectedImplementationOptionEnum | null;
  public selectedDuration!: number | null;
  public selectedCalendar!: CalendarTypeEnum | null;
  public selectedAvailableAction!: AvailableActionObject | null;

  constructor(vals: Required<OtClaimResultsFormResponse>) {
    Object.assign(this, vals);
  }
}

@Component({
  components: {
    OtRadioGroup,
    OtDurationSelection,
    OtButton,
    OtEmailContractorDialog,
    OtTextarea,
    OtTag,
    OtClaimRecommendation,
  },
})
export default class OtClaimResultForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private isSaving!: boolean;
  @Prop() private resultDetails!: ClaimResultsObject;

  // * REFS
  @Ref('claimResultsFormRef') private readonly claimResultsFormRef!: IVForm;

  // * DATA
  private showOverruleGrantComponent = false;
  private resultImplementationOptions: IRadioGroupOption[] = [];
  private originalThumbprint = '';
  private currentThumbprint = '';

  private radioOptionsLabel = '';
  private formTitle = '';

  // ----- Radio Options START ----- //

  // Callout 4.1 in ENG
  // Implement Recommendation option
  private implementRecommendation: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.ImplementRecommendation,
    label: 'Yes, implement recommendation.',
  };

  // as above, but for send response
  private proceedWithResponse: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.ImplementRecommendation,
    label: 'Yes, proceed with response.',
  };

  // Callout 4.2 in ENG
  // Suspend workflow option
  private dontImplementAndSuspend: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.DontImplementAndSuspend,
    label: `No, don't implement and suspend this workflow.`,
    hint: `This will save this workflow with a Suspended status and won't generate any correspondence`,
  };

  // Callout 4.3 in ENG
  // Overrule to Invalid option
  private overruleAndSetAsInvalid: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.OverruleAndSetAsInvalid,
    label: 'Overrule recommendation <u>and set notice as not valid</u>.',
    hint: `This will allow you to implement your own result, and change the notice result recommendation to not valid`,
    useHtmlInLabel: true,
  };

  // Callout 4.4 in ENG
  // Overrule to change extension duration
  private overruleWithDifferentExtensionTime: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.OverruleWithDifferentExtensionTime,
    label: 'Overrule recommendation, <u>keeping the notice as valid with different days extension</u>.',
    hint: `This will allow you to implement your own result, and change the notice result recommendation length`,
    useHtmlInLabel: true,
  };

  // Callout 4.5 in ENG
  // Overrule to Valid option
  private overruleAndSetAsValid: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.OverruleAndSetAsValid,
    label: 'Overrule recommendation <u>and set notice as valid</u>.',
    hint: `This will allow you to implement your own result, and change the notice result recommendation to valid`,
    useHtmlInLabel: true,
  };

  // Callout 4.7 in ENG
  // Revert to calculated result option
  private revertOverruleAndSetToOneTrackRecommendation: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.RevertOverruleAndSetToOneTrackRecommendation,
    label: `No, revert overrule and re-set workflow to OneTrack's recommendation.`,
  };

  // Callout 4.8 in ENG
  // Accept overrule to Invalid option
  private implementOverruleAndSetClaimAsInvalid: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.ImplementOverruleAndSetClaimAsInvalid,
    label: `Yes, implement overrule and <u>set workflow to invalid</u>.`,
    useHtmlInLabel: true,
  };

  // Callout 4.9 in ENG
  // Accept overrule to Valid option
  private implementCustomOverruleRecommendation: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.ImplementCustomOverruleRecommendation,
    label: `Yes, implement custom overrule recommendation.`,
  };

  // Callout 4.11 in ENG
  // Save as Draft option
  private noSaveAsDraft: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.NoSaveAsDraft,
    label: `No, save the workflow as a draft to finish up once all information has been provided.`,
  };

  // Callout 4.12 in ENG
  // Accept Incomplete Claim as Invalid option
  private yesSaveAsInvalid: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.YesSaveAsInvalid,
    label: `Yes, mark the incomplete workflow as <u>Invalid</u>.`,
    useHtmlInLabel: true,
  };

  // Callout 4.13 in ENG
  // Accept Incomplete Claim as Valid option
  private yesSaveAsValid: IRadioGroupOption = {
    key: SelectedImplementationOptionEnum.YesSaveAsValid,
    label: `Yes, mark the incomplete workflow as <u>Valid</u>.`,
    useHtmlInLabel: true,
  };

  // ----- Radio Options END ----- //

  // * COMPUTED
  private selectedAvailableAction: AvailableActionObject | null = null;
  private selectedOptionValuePrivate: IRadioGroupOption | null = null;
  private get selectedOptionValue() {
    return this.selectedOptionValuePrivate;
  }
  private set selectedOptionValue(val: IRadioGroupOption | null) {
    this.selectedOptionValuePrivate = val;
    // this is a bit dodgy. Normally the key of the radio groups is a well known string, like "yes save as valid"
    // but, it's just a string
    // so, we have stuffed the available action guid through it
    this.selectedAvailableAction =
      this.resultDetails.availableActions.find(
        x => this.getResultImplementationKeyForAvailableAction(x) === val?.key,
      ) || null;
    this.currentThumbprint = this.getClaimThumbprint();
    this.selectedValueChanged();
  }

  private selectedDurationPrivate: IDurationSelectionObject | null = null;
  private get selectedDuration() {
    return this.selectedDurationPrivate;
  }
  private set selectedDuration(val: IDurationSelectionObject | null) {
    this.selectedDurationPrivate = val;
    this.currentThumbprint = this.getClaimThumbprint();
  }

  private get selectedOption(): SelectedImplementationOptionEnum | null {
    return this.selectedOptionValue?.key as SelectedImplementationOptionEnum | null;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  private get enableOverruleGrantComponent() {
    return this.selectedOption === SelectedImplementationOptionEnum.ImplementCustomOverruleRecommendation;
  }

  private get isProcessTypeNotice() {
    return this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.Notice;
  }
  private get shouldShowResponseText() {
    return (
      this.claimDetails.workflowSegment.segmentProcessType !== SegmentProcessTypeEnum.Notice &&
      this.claimDetails.workflowSegment.segmentProcessType !== SegmentProcessTypeEnum.RFIOutbound &&
      this.claimDetails.workflowSegment.segmentProcessType !== SegmentProcessTypeEnum.Variation &&
      this.claimDetails.workflowSegment.segmentProcessType !== SegmentProcessTypeEnum.VariationProposal
    );
  }

  private get canFinalizeClaim() {
    return this.canFinalizeBasedOnContract && this.canFinalizeBasedOnProject;
  }
  private get canFinalizeBasedOnContract() {
    return CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR.includes(this.claimDetails.contract.status);
  }
  private get canFinalizeBasedOnProject() {
    return !PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR.includes(this.claimDetails.project.status);
  }

  // * WATCHERS
  @Watch('resultDetails', { deep: true })
  private resultDetailsChanged() {
    this.setImplementationOptions();
  }
  @Watch('claimDetails', { deep: true })
  private claimDetailsChanged() {
    this.enableConfirmButton();
  }

  // * METHODS
  public validate() {
    return this.claimResultsFormRef.validate();
  }

  public async submit(validate: boolean): Promise<OtClaimResultsFormResponse | null> {
    if ((!validate || this.validate()) && this.selectedOption) {
      this.setFormToCleanState();

      return new OtClaimResultsFormResponse({
        selectedImplementation: this.selectedOption,
        selectedDuration: this.selectedDuration?.duration || null,
        selectedCalendar: this.selectedDuration?.selectedCalendar || null,
        selectedAvailableAction: this.selectedAvailableAction,
      });
    }
    return null;
  }

  private selectedValueChanged() {
    // console.log('ot-claim-results-form -> selectedValueChanged -> this.selectedOption:  ', this.selectedOption);
    if (this.selectedAvailableAction) {
      // simplest thing. Send out the text, we're done
      this.$emit('setConfirmButtonText', this.selectedAvailableAction.acceptButtonText);
      return;
    }
    switch (this.selectedOption) {
      case SelectedImplementationOptionEnum.ImplementRecommendation:
      case SelectedImplementationOptionEnum.ImplementOverruleAndSetClaimAsInvalid:
      case SelectedImplementationOptionEnum.ImplementCustomOverruleRecommendation:
        this.$emit('setConfirmButtonText', 'Finish Workflow & Prepare Email');
        break;
      case SelectedImplementationOptionEnum.DontImplementAndSuspend:
        this.$emit('setConfirmButtonText', 'Suspend Workflow');
        break;
      case SelectedImplementationOptionEnum.OverruleAndSetAsInvalid:
      case SelectedImplementationOptionEnum.OverruleWithDifferentExtensionTime:
      case SelectedImplementationOptionEnum.OverruleAndSetAsValid:
        this.$emit('setConfirmButtonText', 'Confirm Recommendation Overrule');
        break;
      case SelectedImplementationOptionEnum.RevertOverruleAndSetToOneTrackRecommendation:
        this.$emit('setConfirmButtonText', 'Revert to Calculated Result');
        break;
      case SelectedImplementationOptionEnum.NoSaveAsDraft:
        this.$emit('setConfirmButtonText', 'Save as Draft');
        break;
      case SelectedImplementationOptionEnum.YesSaveAsInvalid:
        this.$emit('setConfirmButtonText', 'Confirm Outcome Override');
        break;
      case SelectedImplementationOptionEnum.YesSaveAsValid:
        this.$emit('setConfirmButtonText', 'Confirm Outcome Override');
        break;
      default:
        this.$emit('setConfirmButtonText', 'Finish Workflow & Prepare Email');
        break;
    }
  }

  private enableConfirmButton() {
    console.log('ot-claim-results-form -> enableConfirmButton -> canFinalizeClaim:  ', this.canFinalizeClaim);
    this.$emit('setConfirmButtonEnabled', this.canFinalizeClaim);
  }

  private getResultImplementationKeyForAvailableAction(aa: AvailableActionObject) {
    // for a little while, we had a guid. That was guaranteed not to overlap with the SelectedImplementationOptionEnum enum values, which are strings
    // now that we have a key, there's a small chance that we'll accidentally make an available action ImplementRecommendation or something else like that
    // so, stick a little something extra onto the key, just for total paranoia
    return `AA_${aa.key}`;
  }

  private setImplementationOptions() {
    console.log('ot-claim-results-form -> setImplementationOptions -> selections  ', {
      resultDetails: this.resultDetails,
    });

    let newResultImplementationOptions: IRadioGroupOption[] = [];

    // Map the available actions into radio options. These will get overridden in the specific cases
    const resultImplementationOptionsFromServer = this.resultDetails.availableActions.map(x => ({
      // this is a bit dodgy. Normally the key is a well known string, like "yes save as valid"
      // but, it's just a string, so we can stuff a different string into it. We'll fall through the "is it this, is it that"
      // checks, and we'll also use this key to maintain a Selected Available Action
      key: this.getResultImplementationKeyForAvailableAction(x),
      label: x.optionText,
      hint: x.optionSubText || undefined,
      useHtmlInLabel: true,
    }));

    if (resultImplementationOptionsFromServer.length) {
      this.formTitle = 'Confirmation';
      this.radioOptionsLabel = 'Do you want to proceed?';
    }

    if (
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.RFIOutbound ||
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.Variation ||
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.VariationProposal ||
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.VariationRequest
    ) {
      this.showOverruleGrantComponent = false;
      // RFI Outbound and variations use available actions, so the default form title and radio title is desired
      // In OTMAN-467 we added available actions to RFI Inbound, so we don't need newResultImplementationOptions.
      // We'll keep the custom title here though
    } else if (this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.RFIInbound) {
      if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Rejected) {
        // You can't choose to override an rfi inbound response, you can only send or suspend
        this.formTitle = `Result implementation`;
        this.radioOptionsLabel = `Do you want to proceed with your response?`;
        this.showOverruleGrantComponent = false;
      } else {
        // You can't choose to override an rfi inbound response, you can only send or suspend
        this.formTitle = `Result implementation`;
        this.radioOptionsLabel = `Do you want to proceed with your response?`;
        this.showOverruleGrantComponent = false;
      }
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Accepted) {
      if (this.resultDetails.selectedOutcome === OtSegmentResolution.Rejected) {
        this.showOverruleGrantComponent = false;
        this.radioOptionsLabel = `Do you want to implement custom overrule recommendation?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.revertOverruleAndSetToOneTrackRecommendation,
          this.implementOverruleAndSetClaimAsInvalid,
        ];
      } else if (this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime) {
        this.showOverruleGrantComponent = true;
        this.radioOptionsLabel = `Do you want to implement custom overrule recommendation?`;
        this.formTitle = `Adjusting the outcome`;
        newResultImplementationOptions = [
          this.revertOverruleAndSetToOneTrackRecommendation,
          this.implementCustomOverruleRecommendation,
        ];
      } else {
        this.showOverruleGrantComponent = false;
        this.radioOptionsLabel = `Do you want to implement OneTrack's recommendation?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.implementRecommendation,
          this.dontImplementAndSuspend,
          this.overruleAndSetAsInvalid,
        ];
        if (!this.isProcessTypeNotice) {
          newResultImplementationOptions.push(this.overruleWithDifferentExtensionTime);
        }
      }
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Rejected) {
      if (this.resultDetails.selectedOutcome === OtSegmentResolution.Accepted) {
        this.showOverruleGrantComponent = true;
        this.radioOptionsLabel = `Do you want to implement custom overrule recommendation?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.revertOverruleAndSetToOneTrackRecommendation,
          this.implementCustomOverruleRecommendation,
        ];
      } else if (this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime) {
        console.error(
          'ot-claim-results -> setImplementationOptions -> You cannot set an invalid workflow to valid with a different time',
        );
      } else {
        this.showOverruleGrantComponent = false;
        this.radioOptionsLabel = `Do you want to implement OneTrack's recommendation?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.implementRecommendation,
          this.dontImplementAndSuspend,
          this.overruleAndSetAsValid,
        ];
      }
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Indeterminate) {
      if (this.resultDetails.selectedOutcome === OtSegmentResolution.Accepted) {
        this.showOverruleGrantComponent = true;
        this.radioOptionsLabel = `Do you want to produce a result even without all the required information?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.revertOverruleAndSetToOneTrackRecommendation,
          this.implementCustomOverruleRecommendation,
        ];
      } else if (this.resultDetails.selectedOutcome === OtSegmentResolution.Rejected) {
        this.showOverruleGrantComponent = false;
        this.radioOptionsLabel = `Do you want to produce a result even without all the required information?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [
          this.revertOverruleAndSetToOneTrackRecommendation,
          this.implementCustomOverruleRecommendation,
        ];
      } else {
        this.showOverruleGrantComponent = false;
        this.radioOptionsLabel = `Do you want to produce a result even without all the required information?`;
        this.formTitle = `Result implementation`;
        newResultImplementationOptions = [this.noSaveAsDraft, this.yesSaveAsInvalid, this.yesSaveAsValid];
      }
    } else {
      console.error(
        'ot-claim-results -> setImplementationOptions -> recommendedOutcome has an unkonwn value  ',
        this.resultDetails.recommendedOutcome,
      );
    }

    // got to the bottom
    // if "revert" is not visible, add the options in from the server
    // this'll cover the case where we haven't actually added anything in the big if elseif above
    // and also the case where we have, but we're not in an override situtation
    if (!newResultImplementationOptions.includes(this.revertOverruleAndSetToOneTrackRecommendation)) {
      newResultImplementationOptions = newResultImplementationOptions.concat(resultImplementationOptionsFromServer);
    }
    this.resultImplementationOptions = newResultImplementationOptions;

    if (this.claimResultsFormRef) {
      this.claimResultsFormRef.resetValidation();
    }
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getClaimThumbprint(): string {
    const vals = {
      selectedOption: this.selectedOptionValue?.key || '',
      selectedDurationValue: this.selectedDuration?.duration || '',
      selectedDurationCalendar: this.selectedDuration?.selectedCalendar || '',
    };
    return JSON.stringify(vals);
  }

  private outcomeToString(outcome: OtSegmentResolution) {
    return segmentResolutionToString(outcome);
  }

  private get projectTagStatus(): TagStatus | undefined {
    return {
      type: OtStatusType.Project,
      status: this.claimDetails.project.status,
    };
  }
  private get contractTagStatus(): TagStatus | undefined {
    return {
      type: OtStatusType.Contract,
      status: this.claimDetails.contract.status,
    };
  }

  // * LIFECYCLE
  private created() {
    this.setImplementationOptions();
    this.enableConfirmButton();
    this.originalThumbprint = this.getClaimThumbprint();
    this.currentThumbprint = this.getClaimThumbprint();
  }
}
