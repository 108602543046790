































































































import { SegmentInstance } from '@/areas/claims/claims-models';
import { ROUTE_WORKFLOW_ADD, ROUTE_WORKFLOW_DETAILS } from '@/areas/claims/claims-routes';
import { ContractDetails } from '@/areas/projects/contracts/contract-models';
import OtContractDetailsForm from '@/areas/projects/contracts/ot-contract-details-form.vue';
import { ProjectDetails } from '@/areas/projects/project-models';
import { ROUTE_PROJECT_DEFAULT } from '@/areas/projects/projects-routes';
import { DangerModalParams, FormModalParams, FormModalSizeEnum } from '@/components/global/modal/form-modal-models';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import { PostResetContractDateForPracticalCompletionRequestModel } from '@/services/generated/api';
import { vxm } from '@/store';
import { OtOpenSegmentStatusSet, OtProjectStatus, OtSegmentStatus, OtStatusType } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { formatDate } from '@/utils/date-utils';
import { IVForm } from '@/utils/type-utils';
import { OtSegmentProcessTypesThatCanAffectPC } from '@/wf-components/models/data-driven-enums';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import OtTextarea from '../global/ot-textarea.vue';
import { SnackbarItem, SnackbarTypeEnum } from '../global/snackbar/snackbar-models';

export interface IRequestContractDialogParams {
  projectGid: string;
}

export class RequestContractDialogParams implements IRequestContractDialogParams {
  public projectGid!: string;

  constructor(params: IRequestContractDialogParams) {
    Object.assign(this, params);
  }
}

export class RequestContractDialogResult {
  public didRequest = false;

  constructor(params?: Partial<RequestContractDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

@Component({
  components: {
    OtFormModal,
    OtDatePicker,
    OtLoadingSpinner,
    OtTag,
    OtContractDetailsForm,
    OtTextarea,
  },
})
export default class OtRequestContractDialog extends Vue {
  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;
  @Ref('requestContentModalContentsRef') private requestContentModalContentsRef!: HTMLDivElement;
  @Ref('contractDetailsFormRef') private readonly contractDetailsFormRef!: OtContractDetailsForm;

  // * DATA
  private api = new OtApi();
  private params: RequestContractDialogParams | null = null;
  private isLoading = false;
  private projectDetails: ProjectDetails | null = null;
  private contractDetails: ContractDetails | null = null;
  private comments = '';
  private filesPrivate: File[] = [];

  private get files() {
    return this.filesPrivate;
  }
  private set files(val: File[]) {
    // this setter gets called when we blur. Which doubles everything up. A Set sorts out the dupes
    this.filesPrivate = Array.from(new Set([...this.filesPrivate, ...val]));
  }
  private clearFiles() {
    this.filesPrivate = [];
  }
  private removeFile(index: number) {
    this.files.splice(index, 1);
  }

  public validate() {
    const isValid = this.modalContentRef.validate() && this.contractDetailsFormRef.validate();

    if (!isValid) {
      this.$nextTick().then(() => {
        const firstErrorElement = this.requestContentModalContentsRef.querySelector('.error--text');
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView();
        }
      });
    }

    return isValid;
  }

  private dodgyHack = 0;

  public async open(params: RequestContractDialogParams): Promise<RequestContractDialogResult> {
    this.isLoading = true;

    // For some reason the form REALLY DOES NOT want to drop previous validation errors
    // I don't want to run through every form component resetting its validation
    // I would have thought resetValidation on the form does it, but here we are
    // resetValidation is OK if you've tried to submit the form and gotten a validation error
    // but, if you've ticked and unticked the checkbox, resetValidation doesn't reset that, and the next time
    // you open the form you're looking at the validation error from last time
    // This dodgy hack causes Vue to recreate the form completely

    this.dodgyHack += 1;
    await this.$nextTick();
    this.modalContentRef.reset();

    this.params = params;

    const baseParams = new FormModalParams({
      title: `Request New Contract`,
      formRef: this.modalContentRef,
      size: FormModalSizeEnum.ExtraLarge,
      confirmText: `Request New Contract`,
      cancelText: `Cancel`,
      onValidate: () => Promise.resolve(this.validate()),
      onAfterFormMounted: async () => {
        // we need a blank contract details object, the form DOES NOT deal with having a null one
        this.contractDetails = ContractDetails.createNew(params.projectGid, '');
        // need to wait for the project details to load
        await this.getProjectDetails();
        // before we can mark ourselves as not loading
        this.isLoading = false;
        // wait for everything to flush to screen
        await this.$nextTick();
        // reset the fields that are not the contract details form. Again!
        this.modalContentRef.reset();
        // give the contract details form a GOOD HARD REFRESH.
        // Unsure if this is actually needed. It was needed for the outer form, so I'm continuing it here
        // quick testing suggests maybe this is overkill, but meh
        this.contractDetailsFormRef.reset();
        // after all of that, once everything is on screen, make sure we're scrolled to the top
        // Otherwise you create one, hit submit, open the form again and you're scrolled to the bottom of an empty form
        this.requestContentModalContentsRef.scrollTop = 0;
      },
      onBeforeConfirmClose: async () => {
        const contract = await this.contractDetailsFormRef.submit();
        if (contract instanceof ContractDetails) {
          const apiResult = await executeApi(
            () =>
              this.api.contracts().postRequestContract(
                /* projectGid?: string | undefined$ */
                params.projectGid,
                /* name?: string | undefined$ */
                contract?.name,
                /* reference?: string | undefined$ */
                contract?.reference,
                /* description?: string | undefined$ */
                contract?.description ?? undefined,
                /* contractorName?: string | undefined$ */
                contract?.contractor ?? undefined,
                /* contactName?: string | undefined$ */
                contract?.contactName ?? undefined,
                /* contactEmail?: string | undefined$ */
                contract?.contactEmail ?? undefined,
                /* contactPhone?: string | undefined$ */
                contract?.contactPhone ?? undefined,
                /* timezone?: string | undefined$ */
                contract?.timezone,
                /* budget?: number | undefined$ */
                contract?.budget ?? undefined,
                /* contractStartDate?: string | undefined$ */
                contract?.dateOfContract ? ZonelessDate.toZonelessDateFormat(contract.dateOfContract) : undefined,
                /* projectedEndDate?: string | undefined$ */
                contract?.revisedCompletionDate
                  ? ZonelessDate.toZonelessDateFormat(contract.revisedCompletionDate)
                  : undefined,
                /* tendersClosedDate?: string | undefined$ */
                contract?.tendersClosedDate ? ZonelessDate.toZonelessDateFormat(contract.tendersClosedDate) : undefined,
                /* workingCalendarGid?: string | undefined$ */
                // yes, we want to turn empty string gid into undefined here
                contract?.workdayCalendar.gid || undefined,
                /* businessCalendarGid?: string | undefined$ */
                contract?.businessCalendar.gid || undefined,
                /* comments?: string | undefined$ */
                this.comments,
                /* files?: FileParameter[] | undefined$ */
                // https://github.com/RicoSuter/NSwag/issues/2662#issuecomment-676693871 for how to convert a File to a FileParameter
                this.files.map(x => ({ data: x, fileName: x.name })),
                /* cancelToken?: CancelToken$ */
              ),
            'Request Contract',
          );
          if (apiResult.success) {
            vxm.snackbar.addToSnackbarQueue(
              new SnackbarItem({
                type: SnackbarTypeEnum.Success,
                message: 'Contract request sent',
              }),
            );

            return true;
          }
        }
        return false;
      },
    });
    const result = await this.formModalRef.open(baseParams);
    if (result.ok) {
      return new RequestContractDialogResult({
        didRequest: true,
      });
    }
    return new RequestContractDialogResult({
      didRequest: false,
    });
  }

  private async getProjectDetails() {
    const projectGid = this.params?.projectGid;
    if (projectGid) {
      const result = await executeApi(() => this.api.projects().getProjectDetails(projectGid), 'Load Project Details');
      if (result.success && result.data) {
        this.projectDetails = ProjectDetails.createFromApiResponse(result.data);
        if (
          this.projectDetails.status === OtProjectStatus.Suspended ||
          this.projectDetails.status === OtProjectStatus.Complete
        ) {
          console.log('Project is suspended or complete');
          {
            const modalParams = new DangerModalParams({
              title: 'Cannot create contract',
              message: `You cannot add a Contract to a ${this.projectDetails.status} Project`,
              confirmText: 'Ok',
              hideCancelButton: true,
            });

            const modalResult = await vxm.modal.openDangerModal(modalParams);
            if (modalResult.ok) {
              this.$router.push({
                name: ROUTE_PROJECT_DEFAULT,
                params: { projectGid: projectGid },
              });
            }
            return;
          }
        }
      }
    }
  }
  private onDropFile(e: DragEvent) {
    if (e.dataTransfer?.files) {
      this.files = this.files.concat(...Array.from(e.dataTransfer.files));
    }
  }
  private fileRules: Array<(value: File[]) => boolean | string> = [
    (value: File[]) => {
      const totalSize = value.reduce((result, item) => result + item.size, 0);
      return totalSize < 10 * 1024 * 1024 || 'Total file size must be less than 10MB';
    },
  ];
}
