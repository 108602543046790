

























import { SegmentInstance } from '@/areas/claims/claims-models';
import { ROUTE_WORKFLOW } from '@/areas/claims/claims-routes';
import OtClaimsTable from '@/areas/claims/ot-claims-table.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';
import { ContractDetails } from './contract-models';

@Component({
  components: {
    OtLoadingSpinner,
    OtClaimsTable,
    OtButton,
  },
})
export default class OtContractDashboard extends Vue {
  // * PROPS
  @Prop({ default: () => null }) contractDetails!: ContractDetails | null;
  @Prop({ default: () => null }) projectDetails!: ProjectDetails | null;

  // * REFS

  // * DATA
  private loading = false;
  private api = new OtApi();
  private segmentInstances: SegmentInstance[] = [];

  // * COMPUTED
  private get contractGid() {
    return this.$route.params.contractGid;
  }
  private get workflowRoute() {
    return ROUTE_WORKFLOW;
  }
  private get baseExportFileName() {
    return this.projectDetails?.name + '_' + this.contractDetails?.name ?? 'contract';
  }

  // * WATCHERS

  // * METHODS
  private async handleReloadSegments(callback?: () => void) {
    const apiResponse = await executeApi(
      () => this.api.segmentInstances().getSegmentInstances(this.contractGid),
      'Load Workflows',
    );
    if (apiResponse.success && apiResponse.data?.segmentInstances) {
      this.segmentInstances = apiResponse.data.segmentInstances.map(s => SegmentInstance.createFromApiResponse(s));
    }

    if (callback) {
      callback();
    }
  }

  // this has an argument, but we don't care
  // if you need to get hold of the segment instance that changed
  // { segmentInstance }: {segmentInstance: SegmentInstance}
  private async handleSegmentInstanceStatusChanged() {
    this.$emit('reloadContractDetails');
  }

  // * LIFECYCLE
  private async created() {
    this.loading = true;

    await this.handleReloadSegments();

    this.loading = false;
  }
}
