
















































































import { InvitableProject } from '@/areas/invitations/invitation-models';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { UserStatus, UserStatusWithActions } from '@/models/user-models';
import { OtUserAction } from '@/types/action-enums';
import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtAutocomplete,
    OtTag,
    OtButton,
  },
})
export default class OtAutocompleteWrapper extends Vue {
  // * PROPS

  // * DATA
  private items: IAutocompleteItem<string>[] = [
    {
      label: 'Field Text 1',
      data: 'Field Data 1',
    },
    {
      label: 'Field Text 2',
      data: 'Field Data 2',
    },
    {
      label: 'Field Text 3',
      data: 'Field Data 3',
    },
    {
      label: 'Field Text 4',
      data: 'Field Data 4',
    },
    {
      label: 'Field Text 5',
      data: 'Field Data 5',
    },
    {
      label: 'Field Text 6',
      data: 'Field Data 6',
    },
  ];

  private objectItems: IAutocompleteItem<InvitableProject>[] = [
    {
      data: new InvitableProject({
        code: 'one',
        gid: '1',
        name: 'project one',
        userStatus: null,
      }),
      label: 'project one for the money, two for the show',
      disabled: false,
    },
    {
      data: new InvitableProject({
        code: 'two',
        gid: '2',
        name: 'project two',
        userStatus: new UserStatusWithActions({
          availableActions: [OtUserAction.Reinvite],
          gid: '1',
          status: OtUserStatus.Inactive,
          title: 'odd jobber',
        }),
      }),
      label: 'project two',
      disabled: true,
    },
  ];

  private valuePrivate: unknown | null = null;
  private get value() {
    return this.valuePrivate;
  }
  private set value(val: unknown | null) {
    console.log('ot-autocomplete-wrapper -> set value', val);
    this.valuePrivate = val;
  }

  private objectValuePrivate: unknown | null = null;
  private get objectValue() {
    return this.objectValuePrivate;
  }
  private set objectValue(val: unknown | null) {
    console.log('ot-autocomplete-wrapper -> set objectValue', val);
    this.objectValuePrivate = val;
  }

  private getTagStatusFromUserStatus(item: UserStatus | null): TagStatus | undefined {
    if (!item) {
      return undefined;
    }
    return {
      type: OtStatusType.User,
      status: item.status,
    };
  }

  private toggleDisabled() {
    for (const item of this.objectItems) {
      item.disabled = !item.disabled;
    }
  }

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
