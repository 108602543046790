





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import { IInputIcon } from '@/components/global/common-models';
import { currencyKeyboardEventKeys } from '@/well-known-values/key-codes';

@Component({
  components: {
    OtTextField,
  },
})
export default class OtCurrencyField extends Vue {
  // * PROPS
  @Prop() private value?: number | null;
  @Prop() private prependIcon?: IInputIcon;
  @Prop({ default: 2 }) private decimalPlaces!: number;
  @Prop() public placeholder?: string;

  // * REFS

  // * DATA

  private currencyRules: Array<(value: string | null) => string | boolean> = [
    (value: string | null) =>
      !value || !/(\..*){2,}/.test(value) || 'Invalid number, you can’t have more than 1 decimal point',
  ];

  // * COMPUTED

  private displayValue: string | null = null;

  private get fieldIcon(): IInputIcon {
    return this.prependIcon ? this.prependIcon : { icon: '$dollarSign', iconColor: 'black', action: null };
  }
  private get effectivePlaceholder() {
    return this.placeholder ?? 'E.g.: 2,000,000.00';
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const numberFormatter = new Intl.NumberFormat('en-AU', {
      minimumFractionDigits: this.decimalPlaces,
      maximumFractionDigits: this.decimalPlaces,
    });

    const stringValue = this.value === null || this.value === undefined ? null : numberFormatter.format(this.value);
    this.displayValue = stringValue;
  }

  // * METHODS

  private filterKeypress(event: KeyboardEvent) {
    if (!currencyKeyboardEventKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  private stripUnnecessaryCharacters(text: string): string {
    // Remove anything that isn't a number, comma, dash or period
    let cleanText = text.replaceAll(/[^0-9-,.]/g, '');

    const isNegativeNumber = cleanText[0] === '-';

    cleanText = cleanText.replaceAll(/[-]/g, '');

    if (isNegativeNumber) {
      cleanText = `-${cleanText}`;
    }
    return cleanText;
  }

  private onPaste(event: ClipboardEvent) {
    const rawData = event.clipboardData?.getData('text') || '';
    const data = this.stripUnnecessaryCharacters(rawData);

    if (data) {
      event.preventDefault();
      const textField = event.target as HTMLInputElement;
      const startPos = textField.selectionStart || 0;
      const endPos = textField.selectionEnd || 0;

      const stringStart = this.displayValue?.slice(0, startPos) || '';
      const stringEnd = this.displayValue?.slice(endPos || 0, this.displayValue?.length || 0) || '';
      this.displayValue = stringStart + data + stringEnd;

      // move cursor to correct position after paste
      this.$nextTick(() => {
        textField.selectionStart = startPos + data.length;
        textField.selectionEnd = startPos + data.length;
      });
    }
  }

  private resolveInput() {
    // The vuetify rule checking comes after this function so we need to check it manually
    if (this.displayValue && !this.currencyRules.some(v => v(this.displayValue) !== true)) {
      const strippedValue = this.stripUnnecessaryCharacters(this.displayValue).replaceAll(',', '');

      // Get the correct amount of decimal places
      // We aren't rounding, just cutting them off
      const splitValues = strippedValue.split('.');
      const decimals = splitValues[1]?.substring(0, this.decimalPlaces) || undefined;

      const valueConverted = Number.parseFloat(
        decimals !== undefined ? `${splitValues[0]}.${decimals}` : splitValues[0],
      );

      if (this.value === valueConverted) {
        // Even though this get's executed by the watcher 99% of the time.
        // If the user just added extra 0's to the end then the watcher won't fire and the 0's won't get cut off
        this.valueChanged();
      }

      this.$emit('input', valueConverted);
    }
  }

  // * LIFECYCLE
  private created() {
    this.valueChanged();
  }
}
