import { DataDrivenSyncFromQuestionModel as ApiDataDrivenSyncFromQuestionModel } from '@/services/generated/api';

export interface IDataDrivenSyncWithQuestion {
  questionKey: string;
  checkedByDefault: boolean;
}

export class OTDataDrivenSyncFromQuestion implements IDataDrivenSyncWithQuestion {
  questionKey!: string;
  checkedByDefault!: boolean;

  constructor(value: IDataDrivenSyncWithQuestion) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenSyncFromQuestionModel) {
    return new OTDataDrivenSyncFromQuestion({
      questionKey: model.questionKey || '',
      checkedByDefault: model.checkedByDefault || false,
    });
  }

  public static createFromJson(json: Partial<OTDataDrivenSyncFromQuestion>) {
    return new OTDataDrivenSyncFromQuestion({
      questionKey: json.questionKey || '',
      checkedByDefault: json.checkedByDefault || false,
    });
  }
}
