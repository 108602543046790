
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { config } from '@/plugins/vuetify-config';

interface IHasName {
  name: string;
}

@Component({})
export default class IconsWrapper extends Vue {
  // * PROPS
  @Prop() private prop: unknown;

  // * DATA
  private icons: string[] = [];
  public grey = false;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
  protected mounted() {
    if (config.icons?.values) {
      const icons = config.icons.values;
      const iconKeys = Object.keys(icons);

      for (const iconKey of iconKeys) {
        const iconConfig = icons[iconKey];
        if (iconConfig && typeof iconConfig !== 'string' && iconConfig.props) {
          const iconProps = iconConfig.props as IHasName;
          if (iconProps.name) {
            this.icons.push('$' + iconProps.name);
          }
        }
      }
      this.icons.sort((a, b) => a.localeCompare(b));
    }
  }
}
