


















import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTextField from './ot-text-field.vue';
import { IInputIcon } from './common-models';

@Component({
  components: {
    OtTextField,
  },
})
export default class OtPasswordField extends Vue {
  // * PROPS
  @Prop() private value!: string;
  @Prop({ type: String }) private hint?: string;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: true }) private includeDefaultRules!: boolean;

  // * REFS

  // * DATA
  private showPassword = false;
  private defaultPasswordRules: Array<(value: string) => boolean | string> = [
    (value: string) => (!!value && value.length >= 8) || 'Passwords need to be at least 8 characters long',
    (value: string) => /(?=.*[A-Z])/.test(value) || 'Passwords need to include at least 1 capital letter',
    (value: string) => /(?=.*[a-z])/.test(value) || 'Passwords need to include at least 1 lowercase letter',
    (value: string) => /(?=.*[0-9])/.test(value) || 'Passwords need to include at least 1 number',
    (value: string) => /(?=.*\W)/.test(value) || 'Passwords need to include at least 1 symbol',
  ];

  // * COMPUTED
  private get textValue() {
    return this.value;
  }

  private set textValue(value: string) {
    this.$emit('input', value);
  }

  private get showPasswordIcon(): IInputIcon {
    if (this.showPassword) {
      return { icon: '$eye', action: this.toggleShowPassword, iconColor: 'black' };
    }
    return { icon: '$eyeOff', action: this.toggleShowPassword, iconColor: 'black' };
  }

  private get passwordHint(): string | undefined {
    if (this.hint) {
      return this.hint;
    }
    if (this.includeDefaultRules) {
      return 'Hint: 8 characters min, 1 uppercase min, 1 lowercase min, 1 number min, 1 symbol min';
    }
    return undefined;
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    let rules = this.rules || [];
    if (this.includeDefaultRules) {
      rules = rules.concat(this.defaultPasswordRules);
    }
    return rules;
  }

  // * WATCHERS

  // * METHODS
  private toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  // * LIFECYCLE
}
