var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ot-claims-table",class:{ '--hide-contracts': !_vm.showContractColumn, '--hide-projects': !_vm.showProjectColumn },attrs:{"data-deliver-part":"V10000R03"}},[_c('ot-table',{attrs:{"keywordSearchPlaceholder":"Search workflows...","keywordSearchValue":_vm.searchText,"currentPage":_vm.page,"maxPages":_vm.paginatedBodyData.length,"pagination":""},on:{"update:keywordSearchValue":function($event){_vm.searchText=$event},"update:keyword-search-value":function($event){_vm.searchText=$event},"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableColumns),function(column){return _c('ot-table-header',{key:column.key,attrs:{"data":column},on:{"headerClick":function($event){return _vm.headerClick.apply(void 0, arguments)}}})}),1)]),_c('tbody',[(_vm.filteredBodyData.length)?_vm._l((_vm.currentBodyData),function(claim){return _c('tr',{key:claim.gid},[_c('td',[(claim.name)?_c('router-link',{attrs:{"to":_vm.getClaimLink(claim)}},[_vm._v(" "+_vm._s(claim.name)+" ")]):_vm._e(),(!claim.name)?_c('span',[_vm._v("—")]):_vm._e()],1),(_vm.showContractColumn)?_c('td',[(claim.contract.name)?_c('router-link',{attrs:{"to":{
                  name: _vm.contractRoute,
                  params: { projectGid: claim.project.gid, contractGid: claim.contract.gid },
                }}},[_vm._v(" "+_vm._s(claim.contract.name)+" ")]):_vm._e(),(!claim.contract.name)?_c('span',[_vm._v("—")]):_vm._e()],1):_vm._e(),(_vm.showProjectColumn)?_c('td',[(claim.project.name)?_c('router-link',{attrs:{"to":{
                  name: _vm.projectRoute,
                  params: { projectGid: claim.project.gid },
                }}},[_vm._v(" "+_vm._s(claim.project.name)+" ")]):_vm._e(),(!claim.project.name)?_c('span',[_vm._v("—")]):_vm._e()],1):_vm._e(),_c('td',{staticClass:"--ignore-min-width"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(claim.created)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(claim.createdFull)}})])],1),_c('td',{staticClass:"--ignore-min-width"},[_vm._v(_vm._s(claim.workflowSegment.shortName || '—'))]),_c('td',[(_vm.showGrantedTime(claim))?_c('span',[_vm._v(_vm._s(claim.grantedTimeFormatted)+" ")]):_vm._e(),(!_vm.showGrantedTime(claim))?_c('span',[_vm._v("—")]):_vm._e()]),_c('td',{staticClass:"--ignore-min-width"},[(claim.dueDate)?_c('ot-due-date-tag',{attrs:{"date":claim.dueDate,"dueDateStatus":claim.dueDateStatus,"colourByStatus":claim.responseTimeAlertsEnabled}}):_vm._e(),(!claim.dueDate)?_c('span',[_vm._v("—")]):_vm._e()],1),_c('td',{staticClass:"--status-cell"},[_c('ot-tag',{staticClass:"my-2",attrs:{"status":_vm.getTagStatus(claim.status),"is-in-table":""}}),(claim.resolutionChanged)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticClass:"ot-claims-table__resolution-changed"},on),[_vm._v("*")])]}}],null,true)},[_c('span',[_vm._v("The resolution for this workflow has been changed from its original resolution")])]):_vm._e()],1),_c('td',{staticClass:"--ignore-min-width"},[_c('v-menu',{attrs:{"content-class":"ot-menu","offset-y":"","left":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('ot-button',_vm._g({staticClass:"mx-1",attrs:{"btnSize":_vm.buttonSize,"btnStyle":_vm.buttonStyle,"btnType":_vm.buttonType,"icon":"$moreHorizontal","depressed":"","loading":_vm.rowLoadingStates[claim.gid]}},on))]}}],null,true)},[_c('div',{staticClass:"ot-menu__wrapper"},_vm._l((_vm.getClaimOptions(claim)),function(option){return _c('div',{key:option.label,staticClass:"ot-menu__item",on:{"click":function($event){return option.onClick(claim)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)])],1)])}):_vm._e()],2)]},proxy:true},{key:"empty",fn:function(){return [(!_vm.filteredBodyData.length)?_c('div',[_vm._v("No workflows match the selected filters")]):_vm._e()]},proxy:true},{key:"filter",fn:function(){return [_c('div',[_c('ot-checkbox-group',{attrs:{"vertical":"","items":_vm.statusFilters,"label":"Filter By Status"},model:{value:(_vm.selectedStatusFilters),callback:function ($$v) {_vm.selectedStatusFilters=$$v},expression:"selectedStatusFilters"}}),_c('ot-checkbox-group',{attrs:{"vertical":"","items":_vm.dueDateFilters,"label":"Filter By Due Date"},model:{value:(_vm.selectedDueDateFilters),callback:function ($$v) {_vm.selectedDueDateFilters=$$v},expression:"selectedDueDateFilters"}}),_c('ot-checkbox-group',{attrs:{"vertical":"","items":_vm.typeFilters,"label":"Filter By Type"},model:{value:(_vm.selectedTypeFilters),callback:function ($$v) {_vm.selectedTypeFilters=$$v},expression:"selectedTypeFilters"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }