// Data Driven Dialog Buttons Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2441478411/Data+Driven+Dialog+Buttons+Object

import { DialogButtonsOverrideModel } from '@/services/generated/api';
import { OtDataDrivenButtonDetails } from './data-driven-button-details';

export interface IDataDrivenDialogButtons {
  okButton: OtDataDrivenButtonDetails | null;
  cancelButton: OtDataDrivenButtonDetails | null;
  viewButton: OtDataDrivenButtonDetails | null;
}

export class OtDataDrivenDialogButtons implements IDataDrivenDialogButtons {
  public okButton!: OtDataDrivenButtonDetails | null;
  public cancelButton!: OtDataDrivenButtonDetails | null;
  public viewButton!: OtDataDrivenButtonDetails | null;

  constructor(value: IDataDrivenDialogButtons) {
    Object.assign(this, value);
  }

  public static createFromJson(json: Partial<OtDataDrivenDialogButtons>) {
    return new OtDataDrivenDialogButtons({
      okButton: json.okButton ? OtDataDrivenButtonDetails.createFromJson(json.okButton) : null,
      cancelButton: json.cancelButton ? OtDataDrivenButtonDetails.createFromJson(json.cancelButton) : null,
      viewButton: json.viewButton ? OtDataDrivenButtonDetails.createFromJson(json.viewButton) : null,
    });
  }

  public static createFromApiResponse(model: DialogButtonsOverrideModel) {
    return new OtDataDrivenDialogButtons({
      okButton: model.okButton ? OtDataDrivenButtonDetails.createFromApiResponse(model.okButton) : null,
      cancelButton: model.cancelButton ? OtDataDrivenButtonDetails.createFromApiResponse(model.cancelButton) : null,
      viewButton: model.viewButton ? OtDataDrivenButtonDetails.createFromApiResponse(model.viewButton) : null,
    });
  }
}
