<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" style="">
    <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" class="" style="" />

    <defs>
      <filter
        id="colored_check_filter"
        x="0.02929689921438694"
        y="6.780270099639893"
        width="86.3333969116211"
        height="76.9751968383789"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.069375 0 0 0 0 0.230625 0 0 0 0 0.163835 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
    </defs>
    <g class="currentLayer" style="">
      <title>Layer 1</title>
      <rect
        x="-6.214892864227295"
        y="25.01008242368698"
        width="43.840599060058594"
        height="43.840599060058594"
        rx="21.920299530029297"
        transform="rotate(-45 -6.214891910552982,25.010082244873043) "
        fill="#99A2D1"
        id="svg_1"
        class=""
      />
      <g filter="url(#colored_check_filter)" id="svg_2" class="">
        <path
          d="M44.14780747104883,10.790381738233567 L23.29360747104883,31.765581738233564 L13.814407471048831,22.231381738233566 "
          stroke="#00008D"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
          id="svg_3"
        />
      </g>
    </g>
  </svg>
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="87" height="84" fill="none">
    <rect x="8" y="31" width="43.8406" height="43.8406" rx="21.9203" transform="rotate(-45 8 31)" fill="#99A2D1" />
    <g filter="url(#filter0_dd_10798_4896)">
      <path
        d="M58.3617 16.7793L37.5075 37.7545L28.0283 28.2203"
        stroke="#00008D"
        stroke-width="8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_10798_4896"
        x="0.0283203"
        y="6.7793"
        width="86.333"
        height="76.9746"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="18" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.069375 0 0 0 0 0.230625 0 0 0 0 0.163835 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10798_4896" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_10798_4896" result="effect2_dropShadow_10798_4896" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_10798_4896" result="shape" />
      </filter>
    </defs>
  </svg> -->
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
