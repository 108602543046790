

























import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import OtButton from '@/components/global/ot-button.vue';
import OtClaimDetailsForm from '@/areas/claims/ot-claim-details-form.vue';
import { SegmentInstanceDetails, SegmentInstanceDetailsPost } from './claims-models';
import { OtOpenSegmentStatusSet } from '@/types/status-enums';
import { vxm } from '@/store';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import { ROUTE_WORKFLOW_ADD, ROUTE_WORKFLOW_DETAILS } from './claims-routes';

@Component({
  components: {
    OtButton,
    OtClaimDetailsForm,
  },
})
export default class OtEditClaimDetails extends Vue {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;

  // * REFS
  @Ref('claimDetailsForm') private claimDetailsForm!: OtClaimDetailsForm;

  // * DATA
  private api = new OtApi();
  private saving = false;

  // * COMPUTED
  private get projectGid() {
    return this.$route.params.projectGid;
  }
  private get routeWorkflowDetails() {
    return ROUTE_WORKFLOW_DETAILS;
  }

  // * WATCHERS

  // * METHODS
  private async updateClaim() {
    this.saving = true;

    const claim = await this.claimDetailsForm.submit();

    if (claim) {
      const requestModel = SegmentInstanceDetailsPost.createUpdateRequestModel(
        claim,
        // I thought we had to pass a timezone here. We do not. But, if we ever need it, here's where to get it from
        //this.claimDetails.contract.timezone,
      );
      const errorOverride: IApiErrorOverride[] = [
        {
          status: 409,
          message: `Unable to update workflow - contract has been modified by someone else. Please refresh the page and try again.`,
        },
      ];

      const apiUpdateResponse = await executeApi(
        () => this.api.segmentInstances().postUpdateSegmentInstanceDetails(claim.segmentGid, undefined, requestModel),
        `Update Workflow Details (${claim.segmentGid})`,
        errorOverride,
      );

      if (apiUpdateResponse.success) {
        // we can drop rowVersion on the floor here, we reload which gets us the new version
        this.$emit('reloadClaimDetails');
        this.$router.push({
          name: ROUTE_WORKFLOW_DETAILS,
          params: { projectGid: this.projectGid, claimGid: this.claimDetails.gid },
        });
      }
    }

    this.saving = false;
  }

  // * LIFECYCLE
  private created() {
    if (OtOpenSegmentStatusSet.has(this.claimDetails.status)) {
      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Error,
          message: 'You aren’t allowed to edit an open workflow',
        }),
      );
      this.$router.push({ name: ROUTE_WORKFLOW_ADD, query: { claimGid: this.claimDetails.gid } });
    }
  }
}
