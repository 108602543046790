<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="6" :fill="fill" :stroke="stroke" stroke-width="2" />
    <path
      d="M18 7.5L9.75 15.75L6 12"
      :stroke="checkStroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
    checkStroke: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
