






























import OtButton from '@/components/global/ot-button.vue';
import OtClaimRecommendationOverruleDialog, {
  ClaimRecommendationOverruleDialogParams,
  OverruleStateEnum,
} from '@/components/specific-modals/ot-claim-recommendation-overrule-dialog.vue';
import OtClaimSuspensionDialog from '@/components/specific-modals/ot-claim-suspension-dialog.vue';
import OtEmailContractorDialog, {
  EmailContractorDialogParams,
} from '@/components/specific-modals/ot-email-contractor-dialog.vue';
import OtResetContractDateForPcDialog from '@/components/specific-modals/ot-reset-contract-date-for-pc-dialog.vue';
import OtRequestContractDialog from '@/components/specific-modals/ot-request-contract-dialog.vue';
import OtStartRfiOutboundDialog from '@/components/specific-modals/ot-start-rfi-outbound-dialog.vue';
import { vxm } from '@/store';
import { ZonelessDate } from '@/types/zoneless-date';
import { Component, Ref, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtButton,
    OtClaimSuspensionDialog,
    OtClaimRecommendationOverruleDialog,
    OtEmailContractorDialog,
    OtStartRfiOutboundDialog,
    OtResetContractDateForPcDialog,
    OtRequestContractDialog,
  },
})
export default class OtSpecificModalsWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('claimSuspensionDialogRef') private readonly claimSuspensionDialogRef!: OtClaimSuspensionDialog;
  @Ref('emailContractorDialogRef') private readonly emailContractorDialogRef!: OtEmailContractorDialog;
  @Ref('claimRecommendationOverruleDialogRef')
  private readonly claimRecommendationOverruleDialogRef!: OtClaimRecommendationOverruleDialog;
  @Ref('startRfiOutboundDialogRef') private readonly startRfiOutboundDialogRef!: OtStartRfiOutboundDialog;
  @Ref('resetContractDateForPcDialogRef')
  private readonly resetContractDateForPcDialogRef!: OtResetContractDateForPcDialog;
  @Ref('requestContractDialogRef')
  private readonly requestContractDialogRef!: OtRequestContractDialog;

  // * DATA
  private result: unknown = null;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private async openClaimSuspensionDialog() {
    this.result = await this.claimSuspensionDialogRef.open();
  }

  private async openClaimRecommendationOverruleDialog() {
    const params = new ClaimRecommendationOverruleDialogParams('Notice Name', OverruleStateEnum.ValidToInvalid, 2);
    this.result = await this.claimRecommendationOverruleDialogRef.open(params);
  }

  private async openEmailContractorDialog() {
    const profile = vxm.userProfile.userProfile;
    const params = new EmailContractorDialogParams();
    params.emailContent = 'this is the <b>body</b>';
    params.emailSubject = 'this is the subject';
    params.readonly = false;
    params.recipientEmails = [profile?.email || 'profile-has-no-email-address@mailinator.org'];
    this.result = await this.emailContractorDialogRef.open(params);
  }

  private async openStartRfiOutboundDialog() {
    this.result = await this.startRfiOutboundDialogRef.open();
  }

  private async openResetContractDateForPcDialog() {
    this.result = await this.resetContractDateForPcDialogRef.open({
      contractGid: '6',
      contractReference: 'C42x',
      contractName: 'Some Contract Name',
      dateOfContract: new ZonelessDate('2023-01-01'),
      mostRecentlyAgreedDateForPracticalCompletion: null,
      projectedCompletionDate: new ZonelessDate('2023-12-31'),
      revisedCompletionDate: null,
      adjustedProjectCompletionDate: new ZonelessDate('2024-01-02'),
      contractRowVersion: '42',
    });
  }

  private async openRequestContractDialog() {
    this.result = await this.requestContractDialogRef.open({
      projectGid: '91deea01-97ac-4d66-b68e-03b7bc86e630', // metro tunnel from SC100
    });
  }

  // * LIFECYCLE
}
