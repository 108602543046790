




























































































import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputTab } from '@/components/global/common-models';
import OtButton from '@/components/global/ot-button.vue';
import OtSwitch from '@/components/global/ot-switch.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IExtendedColumnData } from '@/components/global/table/ot-table-models';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi } from '@/services/api.service';
import AuthService from '@/services/auth.service';
import { vxm } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ROUTE_INVITATION_DETAILS, ROUTE_PROFILE_DETAILS } from '../profile-routes';
import { AcceptInviationFormObject, AcceptInvitationComponent } from './invitation-models';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtFieldArchetype,
    OtTabs,
    OtButton,
    OtTableHeader,
    OtTable,
    OtSwitch,
  },
})
export default class OtInvitationAccept extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private api = new OtApi();
  private formData = AcceptInviationFormObject.createEmpty();
  private isLoading = false;
  private isSaving = false;
  private get invitationTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Invitation',
        tabRoute: {
          name: ROUTE_INVITATION_DETAILS,
        },
      },
    ];
  }
  private tableColumns: IExtendedColumnData<AcceptInvitationComponent>[] = [
    {
      index: 0,
      label: 'Invitation',
      key: 'invite',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 1,
      label: 'Role',
      key: 'role',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: 'Accept/Reject Invitation',
      key: 'acceptreject',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  // * COMPUTED
  private get invitationId() {
    return this.$route.params.invitationId || '';
  }
  private get inviterFirstName() {
    return this.formData.inviterFirstName;
  }
  private get organisationName() {
    return this.formData.organisationName;
  }
  private get invitedToRoles() {
    return this.formData.organisations.concat(this.formData.projects).concat(this.formData.contracts);
  }
  private get canAcceptAtLeastOneRole() {
    return this.invitedToRoles.length > 0;
  }
  private get hasCompleted() {
    return Boolean(this.formData.completedDateUtc);
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    if (this.invitationId && this.invitationId != this.formData.gid) {
      this.getInvitationDetails();
    }
  }

  // * METHODS
  private async getInvitationDetails() {
    this.isLoading = true;

    const result = await executeApi(
      () => this.api.invitations().getInvitationDetails(this.invitationId),
      'Load Invitation Details',
    );

    if (result.success && result.data) {
      this.formData = AcceptInviationFormObject.createFromApiResponse(result.data);
      // this.originalThumbprint = this.getProfileDetailsThumbprint(this.formData);
      // this.currentThumbprint = this.originalThumbprint;
      this.isLoading = false;
    }
  }

  private async acceptInvitation() {
    this.isSaving = true;
    const requestModel = AcceptInviationFormObject.createRequestModel(this.formData);
    const apiResponse = await executeApi(
      () => this.api.invitations().postAcceptInvitation(this.invitationId, undefined, requestModel),
      'Accepting Invitation',
    );
    if (apiResponse && apiResponse.success) {
      // eng doesn't say where to go, so I reckon profile sounds good
      const routeName = ROUTE_PROFILE_DETAILS;

      // make sure we have an accurate profile for the user now
      // specifically, the org access might have changed, but anything could have changed really
      await vxm.userProfile.getUserProfile({ forceReload: true });

      // probably should show some toast too
      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Success,
          message: 'Successfully accepted invitation',
          displayTime: 5000,
        }),
      );

      this.$nextTick(() => {
        this.$router.push({ name: routeName });
      });
    }
    this.isSaving = false;
  }

  private async checkThings() {
    this.isLoading = true;
    // we're already logged in as the correct user in the IDP, but we might not be in the UI
    // so, for this specific page, we want to make sure the local storage and vuex modules
    // agree with what the IDP thinks we are
    // This way, we can actually retrieve the invitation and not get a 404 because we're the old user, not the new user
    const auth = new AuthService();
    await auth.silentLogin();
    await vxm.userProfile.getUserProfile({ forceReload: true });

    this.checkRoutePath();
  }

  // * LIFECYCLE
  private created() {
    this.checkThings();
  }
}
