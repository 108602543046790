

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { IDataDrivenValue, OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '../models/data-driven-instance';
import { OtDataDrivenOption } from '../models/data-driven-option';

interface IOptionListWithSubOptionsObject {
  key: string;
  title: string;
  selectedSubOptionKey: string | null;
}

@Component({
  components: {
    OtFieldArchetype,
    OtRadioGroup,
  },
})
export default class WfOptionListWithSubOptionsEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop() public readonly instances!: OtDataDrivenInstances;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private isRequired = false;

  private orderTitleAscending = true;
  private isListSorted = false;

  private tableBodyData: IOptionListWithSubOptionsObject[] = [];

  // * COMPUTED
  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-option-list-with-sub-options-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get tableBodyDataOrdered(): IOptionListWithSubOptionsObject[] {
    const orderedData = [...this.tableBodyData];
    if (this.isListSorted) {
      const modifier = this.orderTitleAscending ? 1 : -1;
      orderedData.sort((a, b) => a.title.localeCompare(b.title) * modifier);
    }
    return orderedData;
  }

  private get tableHeader1() {
    const header = this.question.columnHeadersOrdered[0]?.title || '';
    if (!header) {
      console.warn('wf-option-list-with-sub-options-edit -> tableHeader1:  Column header title not found');
    }
    return header;
  }

  private get tableHeader2() {
    const header = this.question.columnHeadersOrdered[1]?.title || '';
    if (!header) {
      console.warn('wf-option-list-with-sub-options-edit -> tableHeader2:  Column header title not found');
    }
    return header;
  }

  private get radioOptions(): IRadioGroupOption[] {
    return (
      this.question.subOptions?.map(o => {
        return {
          key: o.key,
          label: o.title,
        };
      }) || []
    );
  }

  private get titleUpActive() {
    return this.orderTitleAscending && this.isListSorted;
  }

  private get titleDownActive() {
    return !this.orderTitleAscending && this.isListSorted;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    // default to the options on the question. Seems logical.
    let optionsForThisQuestion = this.question.options ?? [];

    // One day this could be put into a helper. Next time we need this probably
    // Not today though
    // TODO consider adding config keys so we can show only the selected Barred ones from the other question
    if (this.question.selectedOptionsFromQuestionKeys?.length) {
      // Completely ignore the options configured for the question
      optionsForThisQuestion = [];
      // we can be configured to only take selected options that have certain sub option keys
      // this is so we can have a list of "here's some conditions" and then another list of "here's the yes conditions"
      const includeSelectionsWithSubOptionKey =
        this.question.configs
          ?.filter(x => x.key === 'includeOptionsWithSupOptionKeyEquals')
          .map(x => x.value?.toLowerCase()) ?? [];
      // similarly, we can be configured to exclude certain ones. Defaulting to NA
      const excludeSelectionsWithSubOptionKey = this.question.configs
        ?.filter(x => x.key === 'excludeOptionsWithSupOptionKeyEquals')
        .map(x => x.value?.toLowerCase()) ?? ['na'];

      // go off and get the questions from the definition
      // we need these so we can get the text/description of the item. Results often (rightly so) just have a key.
      // sometimes (for the dynamic lists) the result has the title/description, and the source question has nothing
      // but we'll deal with that problem when we get there further down
      const definitionQuestions = this.definition.layoutsOrdered
        .flatMap(l => l.sectionsOrdered.flatMap(s => s.questionsOrdered))
        .filter(q => this.question.selectedOptionsFromQuestionKeys?.includes(q.key));

      // yeah, not a fan of sorting, then reversing, but it makes for a pretty line of code doesn't it?
      const sortedInstances = [...this.instances.instances].sort(OtDataDrivenInstance.compareByCreated).reverse();

      for (const definitionQuestion of definitionQuestions) {
        let mostRecentResponse: OtDataDrivenResponse | null = null;
        for (const instance of sortedInstances) {
          const thisResponse = instance.responses.find(r => r.questionKey == definitionQuestion.key);
          if (thisResponse) {
            mostRecentResponse = thisResponse;
            break;
          }
        }

        if (mostRecentResponse) {
          let selectedValues: IDataDrivenValue[] = [];
          if (mostRecentResponse.result.resultType === 'OptionsListModel') {
            selectedValues = mostRecentResponse.result.values.map(x => ({ ...x }));
          } else if (mostRecentResponse.result.resultType === 'OptionsListWithOtherModel') {
            selectedValues = mostRecentResponse.result.values.map(x => ({ ...x }));
            if (mostRecentResponse.result.otherText) {
              // we know if the otherText is filled in, then the last item in the list has the text Other, and it needs changing
              selectedValues[selectedValues.length - 1].title = mostRecentResponse.result.otherText;
            }
          }

          // yes, could do these together, but it's ugly
          // if it turns out that this is a performance pain point, combine these two filter operations
          if (includeSelectionsWithSubOptionKey.length) {
            selectedValues = selectedValues.filter(
              x => x.subOptionKey && includeSelectionsWithSubOptionKey.includes(x.subOptionKey.toLowerCase()),
            );
          }
          if (excludeSelectionsWithSubOptionKey.length) {
            selectedValues = selectedValues.filter(
              x => !x.subOptionKey || !excludeSelectionsWithSubOptionKey.includes(x.subOptionKey.toLowerCase()),
            );
          }

          // now that we have the values that are selected in the other question, join them up with the source question items
          for (const selectedValue of selectedValues) {
            // find the option in the original question that matches the selection
            const questionOption = definitionQuestion.options?.find(x => x.key === selectedValue.key);
            // add it to the options we're building up for this question,
            // favouring result properties over source option properties
            // ie: if the result has a title, use it, otherwise use the definition question option title
            // This is so we retain any user entered values from the Dynamic Lists
            optionsForThisQuestion.push(
              new OtDataDrivenOption({
                key: selectedValue.key,
                title: selectedValue.title ?? questionOption?.title ?? '',
                description: selectedValue.description ?? questionOption?.description ?? null,
                actionKey: null,
                consequence: questionOption?.consequence ?? null,
                orderIndex: selectedValue.orderIndex ?? questionOption?.orderIndex ?? 0,
              }),
            );
          }
        }
      }
    }

    this.tableBodyData =
      optionsForThisQuestion.map(v => {
        const subOptionKey = this.result?.values.find(rv => rv.key === v.key)?.subOptionKey || null;
        return {
          key: v.key,
          selectedSubOptionKey: subOptionKey || null,
          title: v.title,
        };
      }) || [];
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.tableBodyData.map(
        (i, index) =>
          new OtDataDrivenValue({
            key: i.key,
            subOptionKey: i.selectedSubOptionKey,
            orderIndex: index,
            title: i.title,
          }),
      ),
    });
    console.log('onChange', { tableBodyData: this.tableBodyData, result });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  private getSelectedOption(item: IOptionListWithSubOptionsObject): IRadioGroupOption | null {
    return this.radioOptions.find(o => o.key === item.selectedSubOptionKey) || null;
  }

  private setSelectedOptionValue(item: IOptionListWithSubOptionsObject, value: IRadioGroupOption) {
    item.selectedSubOptionKey = value.key;
    this.onChange();
  }

  private header1Clicked() {
    // Cycle through the sorting options in this order:
    // Enable ordering and order by ascending,
    // Order by descending
    // disable ordering
    // (repeat)
    if (!this.isListSorted) {
      this.isListSorted = true;
      this.orderTitleAscending = true;
    } else {
      if (this.orderTitleAscending) {
        this.orderTitleAscending = false;
      } else {
        this.isListSorted = false;
      }
    }
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.isRequired = this.question.isMandatory;

    this.valueChanged();
  }
}
