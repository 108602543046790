




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { TrafficLights } from '@/components/global/common-models';

@Component({})
export default class OtTrafficLights extends Vue {
  // * PROPS
  @Prop() private value!: TrafficLights;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
