import { ZonelessDate } from '@/types/zoneless-date';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function formatDate(date: Date | ZonelessDate) {
  return format(date, 'dd/LL/yyyy');
}

export function formatDateAndTime(
  date: Date | ZonelessDate,
  optionalParams?: { overrideJoin?: string; excludeTime?: boolean },
): string {
  const datePart = formatDate(date);
  if (optionalParams?.excludeTime) {
    return datePart;
  }

  return `${datePart} ${optionalParams?.overrideJoin ? optionalParams.overrideJoin : 'at'} ${format(date, 'hh:mmaaa')}`;
}

export function convertToTimezone(date: Date, timezone: string) {
  return utcToZonedTime(date.getTime(), timezone);
}

export function formatToTimezone(date: Date, timezone: string) {
  const convertedDate = convertToTimezone(date, timezone);
  return formatDate(convertedDate);
}

export function convertAndFormatDateAndTime(
  date: Date,
  timezone: string,
  optionalParams?: { overrideJoin?: string; excludeTime?: boolean },
) {
  const convertedDate = convertToTimezone(date, timezone);
  return formatDateAndTime(convertedDate, optionalParams);
}

export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// with help from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#List
// look for things that are Link, that's tzdb speak for The Same
// Some of these things are not links, but they are actually equivalent, like Melb/Syd/Hobart
export const equivalentTimeZones = [
  new Set(['australia/lhi', 'australia/lord_howe']),
  new Set([
    'antarctica/macquarie',
    'australia/sydney',
    'australia/hobart',
    'australia/melbourne',
    'australia/act',
    'australia/canberra',
    'australia/currie',
    'australia/nsw',
    'australia/tasmania',
    'australia/victoria',
  ]),
  new Set(['australia/brisbane', 'australia/lindeman', 'australia/queensland']),
  new Set(['australia/broken_hill', 'australia/adelaide', 'australia/south', 'australia/yancowinna']),
  new Set(['australia/darwin', 'australia/north']),
  new Set(['australia/perth', 'australia/west']),
];

export function areEquivalentTimeZones(left: string, right: string) {
  const leftLookup = left.toLowerCase();
  const rightLookup = right.toLowerCase();
  return equivalentTimeZones.some(x => x.has(leftLookup) && x.has(rightLookup));
}

export function compareToBrowserTimeZone(source: string) {
  const browser = getBrowserTimezone();
  const areExactlySame = source === browser;
  const areEquivalent = areEquivalentTimeZones(source, browser);
  const areSame = areExactlySame || areEquivalent;
  return {
    exactMatch: areExactlySame,
    equivalentMatch: areEquivalent,
    match: areSame,
    sourceTimeZone: source,
    browserTimeZone: browser,
  };
}
