
































import { Component, Vue, Prop } from 'vue-property-decorator';
import styles from '@/styles/variables.scss';
import { OtBasicSummary, SegmentInstanceDetails } from '@/areas/claims/claims-models';

type SummaryItem = {
  key: string;
  label: string;
  value: string;
};

@Component({})
export default class WfSummaryPanel extends Vue {
  // * PROPS
  @Prop({ default: () => null }) private currentDetails!: SegmentInstanceDetails | null;
  @Prop({ default: () => null }) private parentDetails!: SegmentInstanceDetails | null;

  // * REFS

  // * DATA
  private isPanelOpen = true;

  // * COMPUTED
  private get openPanels(): number | undefined {
    if (this.isPanelOpen) {
      return 0;
    }
    return undefined;
  }
  private set openPanels(val: number | undefined) {
    this.isPanelOpen = val === 0;
  }

  private get workflowParentType() {
    if (!this.parentDetails) return '';
    return this.parentDetails.workflowSegment.name;
  }

  private get workflowParentRef() {
    if (!this.parentDetails) return '';

    return this.parentDetails.reference;
  }

  private get summaryItems() {
    if (!this.parentDetails?.summaries) {
      return [];
    }
    const results = new Map<string, SummaryItem>();

    // Get them in the order they were finished. Oldest first
    const sortedSummaries = [...this.parentDetails.summaries].sort(OtBasicSummary.sortByFinished);
    // feels like this could be some kind of filter then map going into a set constructor
    // but that'd also be Too Fancy. This is readable
    for (const runSummary of sortedSummaries) {
      for (const summary of runSummary.summary) {
        if (summary.label && summary.value) {
          // yeah, nah, I tried doing a {...summary} here,
          // but typescript was fixated on how label or value could theoretically be of type null.
          // So I gave up, here's the fully verbose manual spread 🖕
          // "what about duplicate keys?" I hear you ask
          // the set method will update an entry with the same key. Rockin 🤘
          results.set(summary.key, { key: summary.key, label: summary.label, value: summary.value });
        }
      }
    }
    return Array.from(results.values());
  }

  private get iconColor() {
    return styles.otColorBlue500;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
