


















import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import LibPhoneNumber from 'google-libphonenumber';

@Component({
  components: {
    OtTextField,
  },
})
export default class OtPhoneNumberField extends Vue {
  // * PROPS
  @Prop() private value!: string | null;
  @Prop({ default: 'AU' }) private countryCode!: string;

  // * REFS

  // * DATA
  private phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
  private phoneRules: Array<(value: string) => string | boolean> = [
    (value: string | null) =>
      !value ||
      (this.phoneUtil.isPossibleNumberString(value, this.countryCode) &&
        this.phoneUtil.isValidNumber(this.phoneUtil.parse(value, this.countryCode))) ||
      'Invalid phone number',
  ];

  // * COMPUTED
  private get inputValue(): string | null {
    return this.value;
  }
  private set inputValue(val: string | null) {
    this.$emit('input', val);
  }

  // * WATCHERS

  // * METHODS
  private formatValue() {
    if (this.inputValue && this.phoneUtil.isPossibleNumberString(this.inputValue, this.countryCode)) {
      const number = this.phoneUtil.parse(this.inputValue, this.countryCode);
      if (this.phoneUtil.isValidNumber(number)) {
        this.inputValue = this.phoneUtil.format(number, LibPhoneNumber.PhoneNumberFormat.INTERNATIONAL);
      }
    }
  }

  // * LIFECYCLE
}
