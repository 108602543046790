





















































































































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { OtUserAction } from '@/types/action-enums';
import {
  PostSetContractUserStatusUserStatusModel,
  PostSetOrganisationUserStatusUserStatusModel,
  PostSetProjectUserStatusUserStatusModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { RawLocation } from 'vue-router/types/router';
import { AccessType } from '@/models/shared-models';
import { parsedApiOrgUserStatusEnums } from '@/models/user-models';
import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { ContractUser } from './contract-user-models';
import { ContractDetails } from './contract-models';
import { ROUTE_CONTRACT_USER_DETAILS } from './contract-routes';
import { UserStatusWithActions } from '@/models/user-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (contract: ContractUser) => void;
}

@Component({
  components: {
    OtInviteUserDialog,
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtUserStatusTags,
  },
})
export default class OtContractUsersTable extends Vue {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop({ default: () => [] }) private contractUsers!: ContractUser[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  private tableColumns: IExtendedColumnData<ContractUser>[] = [
    {
      index: 0,
      label: 'Name',
      key: 'name',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: ContractUser.compareByName,
    },
    {
      index: 1,
      label: 'Email',
      key: 'email',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: ContractUser.compareByEmail,
    },
    {
      index: 2,
      label: 'Contract Role',
      key: 'title',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: ContractUser.compareByContractRole,
    },
    {
      index: 3,
      label: 'Org.',
      key: 'org',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 4,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 5,
      label: 'Contract',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 6,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private activeColumn = this.tableColumns[0];

  private openUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Inactive', OtUserStatus.Inactive),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
    new CheckboxGroupItem('Rejected Invite', OtUserStatus.RejectedInvitation),
  ];

  private selectedUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
  ];

  private typeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [
    new CheckboxGroupItem('Project', AccessType.Projects),
    new CheckboxGroupItem('Contract', AccessType.Contracts),
    new CheckboxGroupItem('Organisation', AccessType.Organisations),
  ];

  private selectedTypeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [];

  // * COMPUTED

  private get sortedBodyData() {
    const sortedContractUsers = sortTableData(this.tableColumns, this.activeColumn, this.contractUsers);

    return sortedContractUsers;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedUserStatusFilters.map(s => s.value);
    const parsedAccessTypes = this.selectedTypeOfAccessFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        ContractUser.filterByUserStatus(parsedStatuses, row) &&
        ContractUser.filterByTypeOfAccess(parsedAccessTypes, row) &&
        ContractUser.filterByName(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS

  // * METHODS

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private getContractUserDetailsRoute(contractUser: ContractUser): RawLocation {
    return {
      name: ROUTE_CONTRACT_USER_DETAILS,
      params: {
        userGid: contractUser.gid,
        contractGid: this.contractDetails.gid,
        projectGid: this.contractDetails.projectGid,
      },
    };
  }

  private handleDeactivateForContract = (contractUser: UserStatusWithActions | null): IMenuOption => {
    return {
      key: contractUser ? contractUser.gid : '',
      label: 'Deactivate for Contract',
      onClick: () => {
        if (contractUser) {
          this.setContractUserToDeactivatedForContract(contractUser);
        }
      },
    };
  };

  private handleDeactivateForProject = (projectUser: UserStatusWithActions | null): IMenuOption => {
    return {
      key: projectUser ? projectUser.gid : '',
      label: 'Deactivate for Project',
      onClick: () => {
        if (projectUser) {
          this.setContractUserToDeactivatedForProject(projectUser);
        }
      },
    };
  };

  private handleDeactivateForOrganisation = (organisationUser: UserStatusWithActions | null): IMenuOption => {
    return {
      key: organisationUser ? organisationUser.gid : '',
      label: 'Deactivate for Organisation',
      onClick: () => {
        if (organisationUser) {
          this.setContractUserToDeactivatedForOrganisation(organisationUser);
        }
      },
    };
  };

  private handleReinviteUser = (contractUser: ContractUser): IMenuOption => {
    return {
      key: contractUser.gid, // This is the user gid
      label: 'Reinvite User',
      onClick: () => {
        this.reInviteUser(contractUser);
      },
    };
  };
  private async setContractUserToDeactivatedForContract(contractUser: UserStatusWithActions) {
    this.rowLoadingStates[contractUser.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to deactivate contract user ${contractUser.gid}, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetContractUserStatusUserStatusModel[] = [
      new PostSetContractUserStatusUserStatusModel({
        gid: contractUser.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (!body[0].gid) {
      this.rowLoadingStates[contractUser.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.contractUser().postSetContractUserStatus(body),
      `Set Contract User ${contractUser.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadContractUsers', () => {
        this.rowLoadingStates[contractUser.gid] = false;
      });
    } else {
      this.rowLoadingStates[contractUser.gid] = false;
    }
  }

  private async setContractUserToDeactivatedForProject(contractUser: UserStatusWithActions) {
    this.rowLoadingStates[contractUser.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to deactivate project user ${contractUser.gid}, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetProjectUserStatusUserStatusModel[] = [
      new PostSetProjectUserStatusUserStatusModel({
        gid: contractUser.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (!body[0].gid) {
      this.rowLoadingStates[contractUser.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.projectUser().postSetProjectUserStatus(body),
      `Set Project User ${contractUser.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadContractUsers', () => {
        this.rowLoadingStates[contractUser.gid] = false;
      });
    } else {
      this.rowLoadingStates[contractUser.gid] = false;
    }
  }

  private async setContractUserToDeactivatedForOrganisation(contractUser: UserStatusWithActions) {
    this.rowLoadingStates[contractUser.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to deactivate organisation user ${contractUser.gid}, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetOrganisationUserStatusUserStatusModel[] = [
      new PostSetOrganisationUserStatusUserStatusModel({
        gid: contractUser.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (!body[0].gid) {
      this.rowLoadingStates[contractUser.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.organisationUser().postSetOrganisationUserStatus(body),
      `Set Organisation User ${contractUser.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadContractUsers', () => {
        this.rowLoadingStates[contractUser.gid] = false;
      });
    } else {
      this.rowLoadingStates[contractUser.gid] = false;
    }
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async reInviteUser(contractUser: ContractUser) {
    this.rowLoadingStates[contractUser.gid] = true;

    const reInviteToOrg = contractUser.organisationUserStatus?.availableActions.includes(OtUserAction.Reinvite)
      ? contractUser.organisationUserStatus
      : null;
    const reInviteToProjects = contractUser.projectUserStatus?.availableActions.includes(OtUserAction.Reinvite)
      ? [contractUser.projectUserStatus]
      : [];
    const reinviteToContracts = contractUser.contractUserStatus?.availableActions.includes(OtUserAction.Reinvite)
      ? [contractUser.contractUserStatus]
      : [];

    console.log('ot-contract-users-table -> reinviteUser -> contractDetails:  ', { ...this.contractDetails });

    const params = new InviteUserDialogParams({
      isReinvite: true,
      organisationGid: this.contractDetails.organisationGid,
      inviteToOrganisation: Boolean(reInviteToOrg),
      invitedUserGid: contractUser.gid,
      userContracts: reinviteToContracts.map(x => x.gid),
      userProjects: reInviteToProjects.map(x => x.gid),
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }

    this.rowLoadingStates[contractUser.gid] = false;
  }

  private getContractUserOptionMenuItems(contractUser: ContractUser): IMenuOption[] {
    const results: IMenuOption[] = [];

    const organisationDeactivateAction = contractUser.organisationUserStatus?.availableActions?.includes(
      OtUserAction.Deactivate,
    );

    if (organisationDeactivateAction) {
      results.push(this.handleDeactivateForOrganisation(contractUser.organisationUserStatus));
    }

    const projectDeactivateAction = contractUser.projectUserStatus?.availableActions?.includes(OtUserAction.Deactivate);

    if (projectDeactivateAction) {
      results.push(this.handleDeactivateForProject(contractUser.projectUserStatus));
    }

    const contractDeactivateAction = contractUser.contractUserStatus?.availableActions?.includes(
      OtUserAction.Deactivate,
    );
    if (contractDeactivateAction) {
      results.push(this.handleDeactivateForContract(contractUser.contractUserStatus));
    }

    const reinviteAction =
      contractUser.organisationUserStatus?.availableActions?.includes(OtUserAction.Reinvite) ||
      contractUser.projectUserStatus?.availableActions?.includes(OtUserAction.Reinvite) ||
      contractUser.contractUserStatus?.availableActions?.includes(OtUserAction.Reinvite);
    if (reinviteAction) {
      results.push(this.handleReinviteUser(contractUser));
    }

    return results;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.contractUsers.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  // * LIFECYCLE
  private created() {
    this.setRowLoadingStates();
  }
}
