


































































import { Component, Vue } from 'vue-property-decorator';
import OtTabs from '@/components/global/ot-tabs.vue';
import { IInputTab } from '@/components/global/common-models';

@Component({
  components: {
    OtTabs,
  },
})
export default class OtTabsWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private tabsData1: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Active Tab',
      tabRoute: {
        name: 'Playground',
        params: { component: 'OtTabsWrapper' },
      },
    },
    {
      tabId: 1,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 2,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 3,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 4,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
  ];

  private tabsData2: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 1,
      tabText: 'Active Tab',
      tabRoute: {
        name: 'Playground',
        params: { component: 'OtTabsWrapper' },
      },
    },
    {
      tabId: 2,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 3,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 4,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
  ];

  private tabsData3: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 1,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 2,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 3,
      tabText: 'Inactive',
      tabRoute: {
        name: 'About',
        params: {
          testParams: 'test',
        },
      },
    },
    {
      tabId: 4,
      tabText: 'Active Tab',
      tabRoute: {
        name: 'Playground',
        params: { component: 'OtTabsWrapper' },
      },
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
