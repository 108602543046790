





































































































import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtAutocomplete from '@/components/global/ot-autocomplete.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import OtCurrencyField from '@/components/global/ot-currency-field.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtSelect from '@/components/global/ot-select.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { returnAddressString } from '@/utils/address-utils';
import { EMPTY_HELPER_TEXT_STRING } from '@/utils/constants';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass, isEmail } from '@/utils/validation-utils';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { IAddressDisplayModel, IInputTab } from '../../components/global/common-models';
import { IAutocompleteItem } from '../../components/global/ot-autocomplete.vue';
import { ProfileAddressModel, ProfileDetailsFormObject } from './models';
import OtProfileChangePasswordDialog from './ot-change-password-dialog.vue';
import { ROUTE_PROFILE_DETAILS, ROUTE_PROFILE_EDIT } from './profile-routes';

@Component({
  components: {
    OtProfileChangePasswordDialog,
    OtStandardHeaderArchetype,
    OtFieldArchetype,
    OtCurrencyField,
    OtTabs,
    OtTag,
    OtButton,
    OtTextField,
    OtTextarea,
    OtPhoneNumberField,
    OtAddressField,
    OtAutocomplete,
    OtSelect,
    OtDatePicker,
    OtComboBox,
  },
})
export default class OtEditProfileDetails extends Vue {
  // * PROPS

  // * REFS
  @Ref('profileDetailsFormRef') private readonly profileDetailsFormRef!: IVForm;
  @Ref('changeProfilePasswordDialogRef')
  private readonly changeProfilePasswordDialogRef!: OtProfileChangePasswordDialog;

  // * DATA
  private api = new OtApi();
  private formData = ProfileDetailsFormObject.createEmpty();
  private isLoading = true;
  private originalThumbprint = '';
  private currentThumbprint = '';
  private saving = false;
  private isEditMode = true;

  private get profileTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'User Profile ',
        tabRoute: {
          name: ROUTE_PROFILE_EDIT,
        },
      },
    ];
  }

  // Validation rules

  private profileEmailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];

  public validate() {
    return this.profileDetailsFormRef.validate();
  }

  // * COMPUTED

  private get routeOnCancel(): object {
    return { name: this.profileDetailsRoute };
  }

  get profileDetailsRoute(): string {
    return ROUTE_PROFILE_DETAILS;
  }

  private get emptyHelperTextString(): string {
    return EMPTY_HELPER_TEXT_STRING;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  //  --------------- STREET ADDRESS AUTOCOMPLETE -----------------

  get streetAddress(): IAutocompleteItem<IAddressDisplayModel> | null {
    if (!this.formData?.streetAddress) return null;
    return {
      label: returnAddressString(this.formData.streetAddress),
      data: this.formData.streetAddress,
    };
  }

  set streetAddress(value: IAutocompleteItem<IAddressDisplayModel> | null) {
    if (this.formData) {
      this.formData.streetAddress = value?.data || null;
    }
  }

  //  --------------- POSTAL ADDRESS AUTOCOMPLETE -----------------

  get postalAddress(): IAutocompleteItem<IAddressDisplayModel> | null {
    if (!this.formData?.postalAddress) return null;
    return {
      label: returnAddressString(this.formData.postalAddress),
      data: this.formData.postalAddress,
    };
  }

  set postalAddress(value: IAutocompleteItem<IAddressDisplayModel> | null) {
    if (this.formData) {
      this.formData.postalAddress = value?.data || null;
    }
  }

  // * WATCHERS

  @Watch('routePath')
  private async checkRoutePath() {
    this.isLoading = true;
    this.isEditMode = true;
    await Promise.all([this.getProfileDetails()]);
    this.isLoading = false;
  }

  // watch the organisation details and update the thumbprint
  @Watch('formData', { deep: true })
  private formDataChanged(val: ProfileDetailsFormObject) {
    this.currentThumbprint = this.getProfileDetailsThumbprint(val);
  }

  // * METHODS

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getAddressThumbprint(address: ProfileAddressModel): string {
    const vals = {
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      suburb: address.suburb || '',
      postcode: address.postcode || '',
      state: address.state || '',
      country: address.country || '',
    };
    return JSON.stringify(vals);
  }

  private getProfileDetailsThumbprint(profileDetails: ProfileDetailsFormObject): string {
    const vals = {
      firstName: profileDetails.firstName || '',
      lastName: profileDetails.lastName || '',
      businessName: profileDetails.businessName || '',
      mobile: profileDetails.mobile || '',
      email: profileDetails.email || '',
      phone: profileDetails.phone || '',
      fax: profileDetails.fax || '',
      streetAddress: profileDetails.streetAddress ? this.getAddressThumbprint(profileDetails.streetAddress) : '',
      profileAddress: profileDetails.postalAddress ? this.getAddressThumbprint(profileDetails.postalAddress) : '',
    };
    return JSON.stringify(vals);
  }

  private async updateProfile() {
    const valid = this.validate();
    if (valid && this.formData) {
      this.saving = true;
      const requestModel = ProfileDetailsFormObject.createRequestModel(this.formData);
      const apiResponse = await executeApi(
        () => this.api.user().postEditMyUserProfile(requestModel),
        'Update Profile Details',
      );
      if (apiResponse && apiResponse.success) {
        // Refresh the stored profile data
        await vxm.userProfile.getUserProfile({ forceReload: true });

        // Even though we set the form to be clean just above,
        // the dirty modal still appears unless we reroute on the next tick
        this.setFormToCleanState();
        const routeName = ROUTE_PROFILE_DETAILS;

        this.$nextTick(() => {
          this.$router.push({ name: routeName });
        });
      }
      this.saving = false;
    }
  }

  private async getProfileDetails() {
    this.isLoading = true;

    const result = await executeApi(() => this.api.user().getMyProfile(), 'Load Profile Details');

    if (result.success && result.data) {
      this.formData = ProfileDetailsFormObject.createFromApiResponse(result.data);
      this.originalThumbprint = this.getProfileDetailsThumbprint(this.formData);
      this.currentThumbprint = this.originalThumbprint;
      this.isLoading = false;
    }
  }

  private async openChangePasswordDialog() {
    const dialogResult = await this.changeProfilePasswordDialogRef.open();
    if (dialogResult.passwordChanged) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }
  }
  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
  }
}
