





























import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { IInputTab } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';

import { ROUTE_PROJECT_USER_DETAILS, ROUTE_PROJECT_USER_CONTRACTS } from '../projects-routes';

import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { ProjectUserDetails } from './project-user-models';
import { ProjectUserContract } from './project-user-contract-models';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
    OtButton,
    OtTag,
  },
})
export default class OtProjectUserLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private loading = false;
  private projectUser: ProjectUserDetails = ProjectUserDetails.createEmpty();
  private projectUserContracts: ProjectUserContract[] = [];
  private api = new OtApi();

  private get projectUserTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'User Details',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_PROJECT_USER_DETAILS,
        },
      },
      {
        tabId: 1,
        tabText: 'User Project Contracts',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_PROJECT_USER_CONTRACTS,
        },
      },
    ];
  }

  // * COMPUTED

  private get sectionTitle() {
    return this.projectUser.firstName + ' ' + this.projectUser.lastName || '';
  }

  private get projectGid() {
    return this.$route.params.projectGid || '';
  }

  private get projectName() {
    return this.projectUser.projectName || '';
  }
  private get userName() {
    return this.projectUser.firstName + ' ' + this.projectUser.lastName || '';
  }

  private get userGid() {
    return this.$route.params.userGid || '';
  }

  private get projectUserStatus(): OtUserStatus {
    const status = this.projectUser.projectUserStatus?.status;
    // NOTE - the default here is inactive ?
    return status || OtUserStatus.Inactive;
  }

  private get hasProjectUserStatus(): boolean {
    return Boolean(this.projectUser.projectUserStatus);
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    if (!this.projectUser || this.projectUser.gid !== this.userGid) {
      this.loading = true;
      await this.handleReloadProjectUser();
      this.setProjectNameToBreadcrumb();
      this.loading = false;
    }
    if (this.$route.name === ROUTE_PROJECT_USER_CONTRACTS && !this.projectUserContracts.length) {
      this.loading = true;
      await this.handleReloadProjectUserContracts();
      this.loading = false;
    }
  }

  // * METHODS

  setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
  }
  setUserNameToBreadcrumb() {
    vxm.breadcrumbs.setUserName({ userName: this.userName });
  }
  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  private async handleReloadProjectUser(callback?: () => void) {
    const result = await executeApi(
      () => this.api.projects().getProjectUserDetails(this.projectGid, this.userGid),
      'Load Project User',
    );
    if (result.success && result.data && result.data) {
      this.projectUser = ProjectUserDetails.createFromApiResponse(result.data);
      this.setUserNameToBreadcrumb();
    }

    if (callback) {
      callback();
    }
  }

  private async handleReloadProjectUserContracts(callback?: () => void) {
    const result = await executeApi(
      () => this.api.projects().getProjectUserContracts(this.projectGid, this.userGid),
      'Load Project User Contracts',
    );
    if (result.success && result.data) {
      this.projectUserContracts = result.data?.contracts?.map(contract =>
        ProjectUserContract.createFromApiResponse(contract),
      );
    }

    if (callback) {
      callback();
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();

    if (this.projectGid) {
      vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
    }
    if (this.userName) {
      vxm.breadcrumbs.setUserName({ userName: this.userName });
    }
  }
}
