



































import { ROUTE_WORKFLOW_ADD } from '@/areas/claims/claims-routes';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputAction, IInputTab } from '@/components/global/common-models';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import {
  CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR,
  OtContractStatus,
  OtStatusType,
  PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR,
} from '@/types/status-enums';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';
import { ROUTE_PROJECT_DEFAULT } from '../projects-routes';
import { ContractDetails } from './contract-models';
import { ROUTE_CONTRACT_DASHBOARD, ROUTE_CONTRACT_USERS, ROUTE_CONTRACT_WORKFLOWTYPES } from './contract-routes';
import { ContractUser } from './contract-user-models';

@Component({
  components: {
    OtLoadingSpinner,
    OtStandardHeaderArchetype,
    OtTabs,
    OtTag,
    OtInviteUserDialog,
  },
})
export default class OtContractsLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private newClaimButton: IInputAction = {
    actionName: 'New Workflow',
    action: { name: ROUTE_WORKFLOW_ADD, query: { project: this.projectGid, contract: this.contractGid } },
  };
  private newUserButton: IInputAction = {
    actionName: 'New User',
    action: () => this.createNewUser(),
  };

  private get currentRouteName() {
    return this.$route.name;
  }

  private get primaryContextBtn() {
    switch (this.currentRouteName) {
      case ROUTE_CONTRACT_USERS:
        return this.newUserButton;
      default:
        return this.newClaimButton;
    }
  }

  private viewContractTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Workflows',
      tabRoute: {
        name: ROUTE_CONTRACT_DASHBOARD,
      },
    },
    {
      tabId: 1,
      tabText: 'Contract Details',
      tabRoute: {
        name: 'ContractDetails',
      },
    },
    {
      tabId: 2,
      tabText: 'Workflow Types',
      tabRoute: {
        name: ROUTE_CONTRACT_WORKFLOWTYPES,
      },
    },
    {
      tabId: 3,
      tabText: 'Users',
      tabRoute: {
        name: 'ContractUsers',
      },
    },
    {
      tabId: 4,
      tabText: 'Documents',
      tabRoute: {
        name: 'ContractDocuments',
      },
    },
  ];

  private baseFormContractTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'Contract Details',
      tabRoute: {
        name: 'EditContractDetails',
      },
    },
  ];

  private api = new OtApi();
  private loading = false;

  private contractDetails: ContractDetails | null = null;
  private projectDetails: ProjectDetails | null = null;
  private contractUsers: ContractUser[] = [];

  // * COMPUTED
  private get routePath(): string | null {
    return this.$route.path || null;
  }

  private get contractName(): string {
    return this.contractDetails?.name || '';
  }

  private get isContextBtnDisabled(): boolean {
    // never possible in form state. Whatever that is.
    if (this.isInFormState) {
      return true;
    }
    // no project or contract, no primary button for you
    if (!this.contractDetails || !this.projectDetails) {
      return true;
    }
    // special case the user tab
    if (this.currentRouteName === ROUTE_CONTRACT_USERS) {
      // this ideally should be disabled if the current user is not an org user or a project user
      // but it's not in the spec
      return false;
    }

    // precision of language would be good. We specc'd contrac statuses you can create for, but project you cannot create for. D'oh
    return (
      !CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR.includes(this.contractDetails.status) ||
      PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR.includes(this.projectDetails.status)
    );
  }

  private get isInFormState(): boolean {
    const routeName = this.currentRouteName || '';
    const formRouteNames = this.formContractTabs.map(t => t.tabRoute.name || '');
    return formRouteNames.includes(routeName);
  }

  private get formContractTabs(): IInputTab[] {
    return [...this.baseFormContractTabs];
  }

  private get contractTabs(): IInputTab[] {
    if (this.isInFormState) {
      return this.formContractTabs;
    }
    return this.viewContractTabs;
  }

  private get projectGid(): string {
    return this.$route.params.projectGid;
  }

  private get contractGid(): string {
    return this.$route.params.contractGid;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.loading = true;
    const apiCalls: Promise<void>[] = [];

    // Since this function will get called any time the user selects a new tab,
    // we only need to load the data if it hasn't already been loaded
    if (this.contractDetails === null || this.contractDetails.gid !== this.contractGid) {
      apiCalls.push(this.handleReloadContractDetails());
    }
    if (this.projectDetails === null || this.projectDetails.gid !== this.projectGid) {
      apiCalls.push(this.getProjectDetails());
    }

    await Promise.all(apiCalls);

    // They cannot come to anything in this layout for DRAFT contracts
    // We'll put the "cannot edit it" check inside the form itself
    if (this.contractDetails?.status === OtContractStatus.Draft) {
      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Error,
          message: 'You cannot view a draft contract using this view',
        }),
      );
      this.$router.push({
        name: ROUTE_PROJECT_DEFAULT,
        params: { projectGid: this.projectGid },
      });
      this.loading = false;
      return;
    }

    if (this.currentRouteName === ROUTE_CONTRACT_USERS && !this.contractUsers?.length) {
      this.loading = true;
      await this.handleReloadContractUsers();
      this.loading = false;
    }

    this.loading = false;
  }

  // * METHODS
  private async handleReloadContractDetails(callback?: () => void) {
    if (this.contractGid) {
      const result = await executeApi(
        () => this.api.contracts().getContractDetails(this.contractGid),
        'Load Contract Details',
      );
      if (result.success && result.data && result.data.contract) {
        this.contractDetails = ContractDetails.createFromApiResponse(result.data.contract);
        vxm.breadcrumbs.setContractName({ contractName: this.contractDetails.name });
      }
    }

    if (callback) {
      callback();
    }
  }

  private async handleReloadContractUsers(callback?: () => void) {
    const result = await executeApi(
      () => this.api.contracts().getContractUsers(this.contractGid),
      'Load Contract Users',
    );
    if (result.success && result.data) {
      this.contractUsers = result.data?.users?.map(user => ContractUser.createFromApiResponse(user));
    }
    if (callback) {
      callback();
    }
  }

  private async getProjectDetails() {
    if (this.projectGid) {
      const result = await executeApi(
        () => this.api.projects().getProjectDetails(this.projectGid),
        'Load Project Details',
      );
      if (result.success && result.data) {
        this.projectDetails = ProjectDetails.createFromApiResponse(result.data);
        vxm.breadcrumbs.setProjectName({ projectName: this.projectDetails.name });
      }
    }
  }

  private getTagStatus(status: OtContractStatus): TagStatus {
    return { type: OtStatusType.Contract, status };
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async createNewUser() {
    if (!this.contractDetails) return;

    const params = new InviteUserDialogParams({
      isReinvite: false,
      organisationGid: this.contractDetails.organisationGid,
      inviteToOrganisation: false,
      contracts: [this.contractDetails.gid],
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      this.handleReloadContractUsers();
    }
  }

  // * LIFECYCLE
  private async created() {
    await this.checkRoutePath();
  }
}
