



























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class OtFieldArchetype extends Vue {
  // * PROPS
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private secondaryLabel?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: Boolean, default: false }) private required!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private light!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get showHint() {
    return !!this.hint || !!this.$slots.hint;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
