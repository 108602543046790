import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/views/home.vue';
import LogBackIn from '@/views/log-back-in.vue';
import Playground from '@/devui/Index.vue';

import AuthService from '@/services/auth.service';
import { vxm } from '@/store';
import { dirtyFormsExist } from '@/utils/validation-utils';
import { settingsRoutes } from '@/areas/settings/settings-routes';
import { profileRoutes } from '@/areas/profile/profile-routes';
import { dashboardRoutes } from '@/areas/dashboard/dashboard-routes';
import { projectsRoutes } from '@/areas/projects/projects-routes';
import { claimsRoutes } from '@/areas/claims/claims-routes';
import { issuesRoutes } from '@/areas/issues/issues-routes';
import { documentsRoutes } from '@/areas/documents/documents-routes';
import { contractRoutes } from './areas/projects/contracts/contract-routes';

Vue.use(VueRouter);

const auth = new AuthService();

const DEFAULT_TITLE = 'OneTrack';

let routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      requiresAuth: true,
    },
  },
  // NOTE: in the future, this logout route might be the child of 'account'
  {
    path: '/account/logout',
    name: 'Logout',
    component: {
      beforeRouteEnter() {
        const auth = new AuthService();
        auth.logout();
      },
    },
  },
  {
    path: '/log-back-in',
    name: 'LogBackIn',
    component: LogBackIn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

routes.push(
  ...dashboardRoutes,
  ...contractRoutes,
  ...projectsRoutes,
  ...claimsRoutes,
  ...issuesRoutes,
  ...documentsRoutes,
  ...settingsRoutes,
  ...profileRoutes,
);

const devuiRoutes: Array<RouteConfig> = [
  {
    path: '/playground',
    name: 'PlaygroundHome',
    component: Playground,
    meta: {
      requiresAuth: true,
      hideMainNavigation: true,
    },
  },
  {
    path: '/playground/:component?',
    name: 'Playground',
    component: Playground,
    props: route => {
      const component = route.params.component;
      return { component };
    },
    meta: {
      requiresAuth: true,
      title: 'Component Playground',
      hideMainNavigation: true,
    },
  },
];

// TODO look at how we did Mode. We don't want these on in the actual production release
// but we need them on in the testing/staging releases, which should be production builds
//if (process.env.NODE_ENV !== 'production') {
routes = routes.concat(devuiRoutes);
//}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Check for dirty forms in app and show the modal if they exist
  if (from.matched[0] && from.matched[0].instances.default && dirtyFormsExist()) {
    const result = await vxm.modal.openUnsavedChangesModal();
    if (result.ok === false) {
      next(false);
      return;
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    auth.getUser().then(async user => {
      if (user !== null && !user.expired) {
        if (!vxm.userProfile.userProfile) {
          await vxm.userProfile.getUserProfile();
          // logged in
        }
        next();
      } else {
        // not logged in
        // store the route so that we can send the user onto where they wanted to go previously
        window.localStorage.setItem('authpage', window.location.href);
        auth.login();
      }
    });
  } else {
    // doesn't require auth
    next(); // make sure to always call next()!
  }
});

// This callback runs before every route change, including on page load.
// lifted from https://alligator.io/vuejs/vue-router-modify-head/
// and modified to work around https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
router.afterEach(to => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched
  //   .slice()
  //   .reverse()
  //   .find((r) => r.meta && r.meta.metaTags);

  Vue.nextTick(() => {
    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
      document.title = `${DEFAULT_TITLE} - ${nearestWithTitle.meta.title}`;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(
      el => el.parentNode && el.parentNode.removeChild(el),
    );

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return;

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map((tagDef: { [x: string]: string }) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
      .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));
  });
});

export default router;
