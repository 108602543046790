import Vue from 'vue';
import Vuex from 'vuex';
import { extractVuexModule, createProxy } from 'vuex-class-component';
import UserProfileModule from '@/areas/profile/user-profile-module';
import BreadcrumbModule from '@/modules/breadcrumbs-module';
import GoogleModule from '@/services/google/google-module';
import ModalModule from '@/components/global/modal/modal-module';
import SnackbarModule from '@/components/global/snackbar/snackbar-module';
import AppModule from '@/modules/app-module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(UserProfileModule),
    ...extractVuexModule(BreadcrumbModule),
    ...extractVuexModule(ModalModule),
    ...extractVuexModule(SnackbarModule),
    ...extractVuexModule(GoogleModule),
    ...extractVuexModule(AppModule),
  },
});

export const vxm = {
  userProfile: createProxy(store, UserProfileModule),
  breadcrumbs: createProxy(store, BreadcrumbModule),
  modal: createProxy(store, ModalModule),
  snackbar: createProxy(store, SnackbarModule),
  google: createProxy(store, GoogleModule),
  app: createProxy(store, AppModule),
};
