
































import { Component, Vue } from 'vue-property-decorator';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';

@Component({
  components: {
    OtExpansionPanel,
  },
})
export default class OtExpansionPanelWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private isOpen = true;
  private isOpen2 = true;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
