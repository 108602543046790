





























import { Component, Vue, Ref } from 'vue-property-decorator';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtPasswordField from '@/components/global/ot-password-field.vue';
import { FormModalParams } from '@/components/global/modal/form-modal-models';
import { IVForm } from '@/utils/type-utils';
import { PostChangeMyPasswordRequestModel } from '@/services/generated/api';
import OtApi, { executeApi } from '@/services/api.service';

export class ProfileChangePasswordDialogResult {
  public passwordChanged = false;

  constructor(params?: Partial<ProfileChangePasswordDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

@Component({
  components: {
    OtFormModal,
    OtPasswordField,
  },
})
export default class OtProfileChangePasswordDialog extends Vue {
  // * PROPS
  private api = new OtApi();
  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;

  // * DATA
  private oldPassword: string | null = null;
  private newPassword: string | null = null;
  private newPasswordConfirm: string | null = null;

  private get confirmPasswordRules(): Array<(value: string | null) => boolean | string> {
    return [
      (value: string | null) => !value || value === this.newPassword || `Confirm Password and New Password must match`,
    ];
  }

  public validate() {
    return this.modalContentRef.validate();
  }

  public async open(): Promise<ProfileChangePasswordDialogResult> {
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.modalContentRef.reset();

    const baseParams = new FormModalParams({
      title: `Update Current Password`,
      formRef: this.modalContentRef,
      confirmText: `Update Your Password`,
      onBeforeConfirmClose: async () => {
        // Update the password
        const requestModel = new PostChangeMyPasswordRequestModel({
          currentPassword: this.oldPassword || '',
          newPassword: this.newPassword || '',
        });
        const apiResponse = await executeApi(
          () => this.api.user().postChangeMyPassword(requestModel),
          'Change Password',
        );
        if (apiResponse && apiResponse.success) {
          return true;
        }
        return false;
      },
    });
    const result = await this.formModalRef.open(baseParams);
    if (result.ok) {
      return new ProfileChangePasswordDialogResult({
        passwordChanged: true,
      });
    }
    return new ProfileChangePasswordDialogResult({
      passwordChanged: false,
    });
  }
}
