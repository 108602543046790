import { mutation, createModule } from 'vuex-class-component';
import { SnackbarItem } from '@/components/global/snackbar/snackbar-models';

const vuexModule = createModule({
  namespaced: 'snackbar',
  strict: false,
});

export default class SnackbarModule extends vuexModule {
  private _snackbarAlertsQueue: SnackbarItem[] = [];

  public get snackbarAlertsQueue() {
    return this._snackbarAlertsQueue;
  }

  @mutation
  public addToSnackbarQueue(value: SnackbarItem) {
    this._snackbarAlertsQueue.push(value);
  }

  @mutation
  public removeFromSnackbar(itemId: string) {
    const index = this._snackbarAlertsQueue.findIndex(item => item.id === itemId);
    this._snackbarAlertsQueue.splice(index, 1);
  }

  @mutation
  public popFirstFromSnackbarQueue() {
    this._snackbarAlertsQueue.splice(0, 1);
  }
}
