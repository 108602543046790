var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":_vm.isPartOfLayout,"width":"280"}},[_c('div',{staticClass:"ot-navigation",attrs:{"data-deliver-part":"VC01018"}},[_c('div',{staticClass:"ot-navigation__top-area"},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_c('div',{staticClass:"ot-navigation__top-area__item --logo"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":""}})])]),_vm._l((_vm.navigationData),function(nav){return _c('div',{key:nav.title,staticClass:"ot-navigation__top-area__item"},[_c('router-link',{attrs:{"to":nav.location,"custom":"","disabled":nav.isDisabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('ot-button',{attrs:{"btnSize":_vm.btnSize,"btnStyle":isActive ? _vm.btnStylePrimary : _vm.btnStyleGhost,"btnType":_vm.btnType,"to":href,"depressed":"","disabled":nav.isDisabled}},[_c('div',{staticClass:"ot-navigation__top-area__item__btn-content btn-content"},[_c('v-icon',{attrs:{"color":isActive ? _vm.colourWhite : _vm.colourBlue}},[_vm._v(" "+_vm._s(nav.icon)+" ")]),_c('span',[_vm._v(_vm._s(nav.title))])],1)])]}}],null,true)})],1)})],2),_c('div',{staticClass:"ot-navigation__bottom-area"},[_c('v-menu',{attrs:{"content-class":"ot-navigation__bottom-area__item ot-menu","nudge-top":"12","offset-y":"","top":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":_vm.userDropdown[0].location,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [_c('ot-button',_vm._g({attrs:{"btnSize":_vm.btnSize,"btnStyle":isActive || _vm.isUserDropDownOpen ? _vm.btnStylePrimary : _vm.btnStyleDefault,"depressed":"","btnType":"text","label":"menu"}},on),[_c('div',{staticClass:"ot-navigation__bottom-area__item__btn-content btn-content"},[_c('v-icon',[_vm._v(" "+_vm._s('$user')+" ")]),_c('span',{},[_vm._v(_vm._s(_vm.username))]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(isActive || _vm.isUserDropDownOpen ? '$chevronRight' : '$chevronUp')+" ")])],1)])]}}],null,true)})]}}]),model:{value:(_vm.isUserDropDownOpen),callback:function ($$v) {_vm.isUserDropDownOpen=$$v},expression:"isUserDropDownOpen"}},[_c('div',{staticClass:"ot-menu-wrapper"},_vm._l((_vm.userDropdown),function(item){return _c('router-link',{key:item.title,staticClass:"ot-menu__item",attrs:{"to":item.location}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }