














































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtButton from '@/components/global/ot-button.vue';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { IValidate } from '@/utils/type-utils';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

interface IDynamicListTextareaObject {
  key: string;
  description: string | null;
}

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
    OtTextarea,
    OtButton,
  },
})
export default class WfDynamicListTextareaEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS
  @Ref('checkboxRef') private checkboxRef!: IValidate;

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private placeholder: string | null = null;
  private displayPlaceholder = '';
  private isRequired = false;
  private showErrorMessage = false;
  private setItemSubOptionKeyTo: string | null = null;

  private tableBodyData: IDynamicListTextareaObject[] = [];

  private checkboxRules: Array<(value: boolean) => boolean | string> = [
    (value: boolean) => value === true || 'Required',
  ];

  // * COMPUTED
  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-dynamic-list-textarea-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get tableHeader1() {
    const header = this.question.columnHeadersOrdered[0]?.title || '';
    if (!header) {
      console.warn('wf-dynamic-list-textarea-edit -> tableHeader1:  Column header title not found');
    }
    return header;
  }

  private get buttonText() {
    return this.question.addButtonText || '';
  }

  private get controlIsValid() {
    return !!this.tableBodyData.length;
  }

  private get shouldShowDisplayPlaceholder() {
    return this.readonly && this.tableBodyData.length == 0 && Boolean(this.displayPlaceholder);
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.tableBodyData =
      this.result?.values.map(v => {
        return {
          key: v.key,
          description: v.description || null,
        };
      }) || [];
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.tableBodyData.map(
        (i, index) =>
          new OtDataDrivenValue({
            key: i.key,
            description: i.description,
            orderIndex: index,
            subOptionKey: this.setItemSubOptionKeyTo,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  private addNewItem() {
    this.tableBodyData.push({ key: uuid(), description: null });
    this.onChange();
  }

  private deleteItem(item: IDynamicListTextareaObject) {
    const foundIndex = this.tableBodyData.findIndex(i => i.key === item.key);
    if (foundIndex !== -1) {
      this.tableBodyData.splice(foundIndex, 1);
      this.onChange();
    }
  }

  private setDescriptionValue(item: IDynamicListTextareaObject, value: string) {
    item.description = value;
    this.onChange();
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.placeholder = this.question.placeholder;
    this.displayPlaceholder = this.question.configs?.find(x => x.key === 'displayPlaceholder')?.value ?? '';
    this.isRequired = this.question.isMandatory;
    const matcher = /^SetItemSubOptionKeyTo$/i;
    this.setItemSubOptionKeyTo = this.question.configs?.find(x => matcher.test(x.key))?.value ?? null;

    this.valueChanged();
  }

  private mounted() {
    this.$watch('checkboxRef.validationState', (state: string) => {
      this.showErrorMessage = state === 'error';
    });
  }
}
