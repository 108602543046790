






























import { Component, Vue } from 'vue-property-decorator';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import { getTimeZones } from '@vvo/tzdb';
import { convertToTimezone, formatDateAndTime } from '@/utils/date-utils';

@Component({
  components: {
    OtDatePicker,
    OtAutocomplete,
    OtTextField,
  },
})
export default class TimezoneTestingWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private textDate: string | null = null;
  private selectedDate: Date | null = null;
  private selectedTimezone: IAutocompleteItem<string> | null = null;

  private allTimezones = getTimeZones()
    .filter(t => t.countryCode === 'AU')
    .flatMap(t => t.group)
    .sort((a, b) => a.localeCompare(b));

  // * COMPUTED
  private get allTimezoneItems(): IAutocompleteItem<string>[] {
    const timezoneNames: IAutocompleteItem<string>[] = this.allTimezones.map(t => {
      return { label: t, data: t };
    });
    return timezoneNames;
  }

  private get currentDate() {
    return new Date();
  }

  private get currentDateInTimezone() {
    if (this.selectedTimezone?.data) {
      return convertToTimezone(this.currentDate, this.selectedTimezone.data);
    }
    return null;
  }

  private get selectedDateInTimezone() {
    if (this.selectedTimezone?.data && this.selectedDate) {
      return convertToTimezone(this.selectedDate, this.selectedTimezone.data);
    }
    return null;
  }

  private get convertedTextDate() {
    if (this.textDate) {
      return new Date(this.textDate);
    }
    return null;
  }

  private get convertedTextDateInTimezone() {
    if (this.convertedTextDate && this.selectedTimezone?.data) {
      return convertToTimezone(this.convertedTextDate, this.selectedTimezone.data);
    }
    return null;
  }

  // * WATCHERS

  // * METHODS
  private formatDate(date: Date | null) {
    if (date) {
      return formatDateAndTime(date);
    }
    return '';
  }

  // * LIFECYCLE
}
