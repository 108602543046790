


































import { Component, Vue } from 'vue-property-decorator';
import OtNavigation from '@/components/global/ot-navigation.vue';
import { INavigation } from '@/components/global/common-models';
import { ROUTE_WORKFLOW } from '@/areas/claims/claims-routes';
import { ROUTE_PROJECT } from '@/areas/projects/projects-routes';

@Component({
  components: {
    OtNavigation,
  },
})
export default class OtNavigationWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private exampleNavigationData: INavigation[] = [
    {
      title: 'Dashboard',
      location: {
        name: 'Playground',
        path: '/playground',
      },
      icon: '$pieChart',
    },
    {
      title: 'Projects',
      location: {
        name: ROUTE_PROJECT,
        path: '/projects',
      },
      icon: '$folder',
    },
    {
      title: 'Workflows',
      location: {
        name: ROUTE_WORKFLOW,
        path: '/workflows',
      },
      icon: '$edit',
    },
    {
      title: 'Issues',
      location: {
        name: 'Issues',
        path: '/issues',
      },
      icon: '$alertOctagon',
    },
    {
      title: 'Documents',
      location: {
        name: 'Documents',
        path: '/documents',
      },
      icon: '$textFile',
    },
    {
      title: 'Settings',
      location: {
        name: 'Settings',
        path: '/settings',
      },
      icon: '$settings',
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
