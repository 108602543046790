



























































































import { Component, Vue } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import { IInputIcon } from '@/components/global/common-models';

interface IFieldProps {
  appendIcon: IInputIcon | null;
  prependIcon: IInputIcon | null;
  light: boolean;
  disabled: boolean;
  required: boolean;
}

interface IComponent {
  isTextField: boolean;
  content?: string;
  props?: IFieldProps;
}

@Component({
  components: {
    OtTextField,
  },
})
export default class OtTextFieldWrapper extends Vue {
  // * PROPS

  // * DATA
  private icon: IInputIcon = { icon: 'mdi-cursor-default-click', action: this.log, iconColor: 'black' };
  private componentsToDisplay: IComponent[] = [
    { isTextField: false, content: '' },
    { isTextField: false, content: 'Text Only' },
    { isTextField: false, content: 'Right Icon' },
    { isTextField: false, content: 'Left Icon' },
    { isTextField: false, content: 'Right and Left Icon' },
    { isTextField: false, content: 'Default' },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: null, light: false, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: null, light: false, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: this.icon, light: false, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: this.icon, light: false, disabled: false, required: false },
    },
    { isTextField: false, content: 'Default Light' },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: null, light: true, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: null, light: true, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: this.icon, light: true, disabled: false, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: this.icon, light: true, disabled: false, required: false },
    },
    { isTextField: false, content: 'Disabled' },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: null, light: false, disabled: true, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: null, light: false, disabled: true, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: this.icon, light: false, disabled: true, required: false },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: this.icon, light: false, disabled: true, required: false },
    },
    { isTextField: false, content: 'Required' },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: null, light: false, disabled: false, required: true },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: null, light: false, disabled: false, required: true },
    },
    {
      isTextField: true,
      props: { appendIcon: null, prependIcon: this.icon, light: false, disabled: false, required: true },
    },
    {
      isTextField: true,
      props: { appendIcon: this.icon, prependIcon: this.icon, light: false, disabled: false, required: true },
    },
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private log() {
    console.log('Icon clicked!!');
  }

  // * LIFECYCLE
}
