/* eslint-disable @typescript-eslint/camelcase */

import { UserManager, WebStorageStateStore, User, UserManagerSettings } from 'oidc-client';
import { getUrl } from '@/utils/url-utils';

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    console.log('aut.service.ts -> constructor -> process.env.NODE_ENV:  ', process.env.NODE_ENV);
    const STS_DOMAIN: string = process.env.VUE_APP_IDP_URL;
    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: getUrl('callback.html'),
      automaticSilentRenew: true,
      silent_redirect_uri: getUrl('silent-renew.html'),
      response_type: 'code',
      scope: 'openid profile onetrackApi', // <- have to match the UI client setting in IDP
      // TODO consider where we should send the user when they logout
      post_logout_redirect_uri: getUrl('log-back-in'),
      filterProtocolClaims: true,
    };
    console.log('auth.service -> ctor -> settings:  ', settings);
    // console.log(STS_DOMAIN);
    this.userManager = new UserManager(settings);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }
  public silentLogin() {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public getAccessToken(): Promise<string> {
    return this.userManager.getUser().then(data => {
      if (data) {
        return data.access_token;
      }
      return '';
    });
  }

  public getIdToken() {
    return this.userManager.getUser().then(data => {
      if (data) {
        return data.id_token;
      }
      return '';
    });
  }

  public async isLoggedIn(): Promise<boolean> {
    const user: User | null = await this.getUser();
    return user !== null && !user.expired;
  }
}
