



































































































































































































































































import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IAddressDisplayModel, IInputTab } from '@/components/global/common-models';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import OtCurrencyField from '@/components/global/ot-currency-field.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtSelect, { ISelectItem } from '@/components/global/ot-select.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { OtProjectStatus, OtStatusType } from '@/types/status-enums';
import { returnAddressString } from '@/utils/address-utils';
import { EMPTY_HELPER_TEXT_STRING } from '@/utils/constants';
import { filterPaste } from '@/utils/string-utils';
import { blurCombos, IVForm } from '@/utils/type-utils';
import { dirtyFormClass, isEmail } from '@/utils/validation-utils';
import { numericKeyboardEventKeys, numericPunctuationEventKeys } from '@/well-known-values/key-codes';
import { getTimeZones } from '@vvo/tzdb';
import { v4 as uuid } from 'uuid';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { ProfileOrganisationModel } from '../profile/models';
import { OtProjectStatusMap, ProjectDetailsFormObject, ProjectType } from './project-models';
import { ROUTE_PROJECT, ROUTE_PROJECT_CREATE, ROUTE_PROJECT_DETAILS, ROUTE_PROJECT_EDIT } from './projects-routes';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtCurrencyField,
    OtTabs,
    OtTag,
    OtButton,
    OtTextField,
    OtTextarea,
    OtPhoneNumberField,
    OtAddressField,
    OtAutocomplete,
    OtSelect,
    OtDatePicker,
    OtComboBox,
  },
})
export default class OtEditProjectDetails extends Vue {
  // * PROPS

  // * REFS
  @Ref('projectDetailsFormRef') private readonly projectDetailsFormRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private formData = ProjectDetailsFormObject.createEmpty();
  private isLoading = true;
  private originalThumbprint = '';
  private currentThumbprint = '';
  private saving = false;
  private userOrganisations: ProfileOrganisationModel[] = [];
  private projectTypesData: ProjectType[] = [];
  private projectOwnersData: string[] = [];
  private projectManagerOrgNameData: string[] = [];
  private lastLoadedProjectTypeForOrg: string | null = null;
  private createdProjectGid = '';
  private isEditMode = true;

  private allTimezones = getTimeZones()
    .filter(t => t.countryCode === 'AU')
    .flatMap(t => t.group)
    .sort((a, b) => a.localeCompare(b));

  private get projectDetailsTab(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Project Details',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_EDIT,
        },
      },
    ];
  }
  private get createProjectDetailsTab(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Project Details',
        tabRoute: {
          params: { projectGid: '' },
          name: ROUTE_PROJECT_CREATE,
        },
      },
    ];
  }

  private get projectDetailsTabs(): IInputTab[] {
    if (this.$route.name === ROUTE_PROJECT_CREATE) {
      return this.createProjectDetailsTab;
    }
    return this.projectDetailsTab;
  }

  // Validation rules

  private projectOwnerEmailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];

  // projectManagerEmailRules
  private projectManagerEmailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];

  public validate() {
    return this.projectDetailsFormRef.validate();
  }

  // * COMPUTED

  private get routeOnCancel(): object {
    // in edit mode  :to="{ name: projectDetailsRoute, params: { projectGid: projectGid } }"
    //  not edit mode to :to="{ name: projectRoute }"
    return this.isEditMode
      ? { name: this.projectDetailsRoute, params: { projectGid: this.projectGid } }
      : { name: ROUTE_PROJECT };
  }

  private get sectionTitle(): string {
    return this.isEditMode ? 'Project Details: ' : 'Create Project';
  }

  private get hasOrganisation(): boolean {
    return Boolean(this.formData.organisationGid);
  }

  get editRoute(): string {
    return ROUTE_PROJECT_EDIT;
  }

  get projectDetailsRoute(): string {
    return ROUTE_PROJECT_DETAILS;
  }

  private get projectGid() {
    //  if edit mode read form the route params, otherwise read it from the createdProjectGid
    return this.isEditMode ? this.$route.params.projectGid : this.createdProjectGid;
  }

  private get projectName() {
    return this.formData.name || '';
  }

  private get emptyHelperTextString(): string {
    return EMPTY_HELPER_TEXT_STRING;
  }

  private get subTitle(): string {
    return this.isEditMode ? this.formData.name || '' : '';
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  //  -------------- 	ASSOCIATED ORGANISATION SELECT -----------------

  private get userOrganisationItems(): ISelectItem<string>[] {
    return this.userOrganisations.map(t => {
      return {
        label: t.name,
        data: t.gid,
      };
    });
  }

  private get userOrganisation(): ISelectItem<string> | null {
    return this.userOrganisationItems.find(t => t.data === this.formData.organisationGid) || null;
  }

  private set userOrganisation(value: ISelectItem<string> | null) {
    if (value?.data) {
      this.formData.organisationGid = value.data;
    }
  }

  //  --------- PROJECT STATUS SELECT -----------------

  private get projectStatusItems(): ISelectItem<OtProjectStatus>[] {
    return Array.from(OtProjectStatusMap.keys()).map(t => {
      return {
        label: OtProjectStatusMap.get(t) || '',
        data: t,
      };
    });
  }

  private get projectStatus(): ISelectItem<OtProjectStatus> | null {
    return this.projectStatusItems.find(t => t.data === this.formData?.projectStatus) || null;
  }

  private set projectStatus(value: ISelectItem<OtProjectStatus> | null) {
    if (!value?.data) return;

    if (this.formData?.projectStatus) {
      this.formData.projectStatus = value.data;
    }
  }

  // ----------------- TIMEZONE AUTOCOMPLETE -----------------

  private get selectedTimezone(): IAutocompleteItem<string> {
    const timezone = this.formData.timezone;
    if (timezone) {
      return { label: timezone, data: timezone };
    }
    return { label: '', data: '' };
  }
  private set selectedTimezone(val: IAutocompleteItem<string>) {
    if (val?.data) {
      this.formData.timezone = val.data;
    } else {
      this.formData.timezone = null;
    }
  }

  private get allTimezoneItems(): IAutocompleteItem<string>[] {
    const timezoneNames: IAutocompleteItem<string>[] = this.allTimezones.map(t => {
      return { label: t, data: t };
    });
    return timezoneNames;
  }

  private timezoneRules: Array<(value: IAutocompleteItem<string> | null) => boolean | string> = [
    (value: IAutocompleteItem<string> | null) => !value || !!value.data || `Project Timezone is required`,
  ];

  //  --------------- PROJECT OWNER COMBOBOX -----------------

  get projectOwnerItems(): IAutocompleteItem<string>[] {
    return this.projectOwnersData.map(t => {
      return {
        label: t,
        data: t,
      };
    });
  }

  //----------------- PROJECT MANAGER ORG NAME COMBOBOX -----------------

  get projectManagerOrgNameItems(): IAutocompleteItem<string>[] {
    return this.projectManagerOrgNameData.map(t => {
      return {
        label: t,
        data: t,
      };
    });
  }

  //  --------------- PROJECT ADDRESS AUTOCOMPLETE -----------------

  get projectAddress(): IAutocompleteItem<IAddressDisplayModel> | null {
    if (!this.formData?.address) return null;
    return {
      label: returnAddressString(this.formData.address),
      data: this.formData.address,
    };
  }

  set projectAddress(value: IAutocompleteItem<IAddressDisplayModel> | null) {
    if (this.formData) {
      this.formData.address = value?.data || null;
    }
  }

  private projectAddressRules: Array<(value: IAutocompleteItem<IAddressDisplayModel> | null) => boolean | string> = [
    (value: IAutocompleteItem<IAddressDisplayModel> | null) => {
      // console.log('ot-edit-project-details validating address', value);
      return !value || !!value.data?.addressLine1 || `Project address is required`;
    },
  ];

  //  --------------- PROJECT TYPE COMBOBOX -----------------

  get projectTypeItems(): string[] {
    return this.projectTypesData.map(t => t.name);
  }

  // * WATCHERS

  @Watch('routePath')
  private async checkRoutePath() {
    this.setProjectNameToBreadcrumb();
    if (this.$route.name === ROUTE_PROJECT_EDIT && this.projectGid) {
      this.isLoading = true;
      this.isEditMode = true;
      await Promise.all([
        this.getProjectDetails(),
        this.getProjectTypes(),
        this.getProjectOwners(),
        this.getProjectManagerOrganisations(),
        this.getUserProfile(),
      ]);
      this.isLoading = false;
    }
    // if the route matches the create route and the projectGid is not set then we need to create the gid but not set it to the route
    else if (this.$route.name === ROUTE_PROJECT_CREATE && !this.projectGid) {
      this.isLoading = true;
      this.isEditMode = false;
      await Promise.all([this.getProjectOwners(), this.getProjectManagerOrganisations(), this.getUserProfile()]);
      if (!this.isEditMode && !this.projectGid) {
        this.createdProjectGid = uuid();
      }
      this.getProjectDetailsThumbprint(this.formData);
      this.isLoading = false;
    }
  }

  // watch the organisation details and update the thumbprint
  @Watch('formData', { deep: true })
  private formDataChanged(val: ProjectDetailsFormObject) {
    if (
      !this.isEditMode &&
      this.formData.organisationGid &&
      this.lastLoadedProjectTypeForOrg !== this.formData.organisationGid
    ) {
      this.getProjectTypes();
    }
    this.currentThumbprint = this.getProjectDetailsThumbprint(val);
  }

  // * METHODS
  private filterKeypress(event: KeyboardEvent) {
    if (!numericKeyboardEventKeys.includes(event.key) || !numericPunctuationEventKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  private handlePaste(event: ClipboardEvent) {
    console.log('handlePaste');
    const pasteResult = filterPaste(event, this.formData.timeContingencyDays + '');

    if (pasteResult) {
      this.formData.timeContingencyDays = parseInt(pasteResult.textValue, 10);
      const textField = event.target as HTMLInputElement;
      this.$nextTick(() => {
        textField.selectionStart = pasteResult.startPos + pasteResult.data.length;
        textField.selectionEnd = pasteResult.startPos + pasteResult.data.length;
      });
    }
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getStreetAddressThumbprint(address: IAddressDisplayModel): string {
    const vals = {
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      suburb: address.suburb || '',
      postcode: address.postcode || '',
      state: address.state || '',
    };
    return JSON.stringify(vals);
  }

  private getProjectDetailsThumbprint(projectDetails: ProjectDetailsFormObject): string {
    const vals = {
      organisationGid: projectDetails.organisationGid || '',
      organisationName: projectDetails.organisationName || '',
      name: projectDetails.name || '',
      status: projectDetails.projectStatus || '',
      projectTypeGid: projectDetails.projectTypeGid || '',
      projectCode: projectDetails.projectCode || '',
      projectTypeName: projectDetails.projectTypeName || '',
      address: projectDetails.address ? this.getStreetAddressThumbprint(projectDetails.address) : '',
      ownerOrganisationName: projectDetails.ownerOrganisationName || '',
      ownerName: projectDetails.ownerName || '',
      ownerJobTitle: projectDetails.ownerJobTitle || '',
      ownerMobile: projectDetails.ownerMobile || '',
      ownerPhone: projectDetails.ownerPhone || '',
      ownerEmail: projectDetails.ownerEmail || '',
      ownerFax: projectDetails.ownerFax || '',
      managerOrganisationName: projectDetails.managerOrganisationName || '',
      managerName: projectDetails.managerName || '',
      managerJobTitle: projectDetails.managerJobTitle || '',
      managerMobile: projectDetails.managerMobile || '',
      managerPhone: projectDetails.managerPhone || '',
      managerEmail: projectDetails.managerEmail || '',
      managerFax: projectDetails.managerFax || '',
      budgetAmount: projectDetails.budgetAmount?.toString() || '',
      costContingency: projectDetails.costContingency?.toString() || '',
      projectEndDate: projectDetails.projectEndDate?.toISOString() || '',
      adjustedProjectCompletionDate: projectDetails.adjustedProjectCompletionDate?.toISOString() || '',
      timeContingencyDays: projectDetails.timeContingencyDays?.toString() || '',
      timezone: projectDetails.timezone || '',
    };
    return JSON.stringify(vals);
  }

  private async updateProject() {
    blurCombos(
      this.$refs.ownerOrganisationNameCombo,
      this.$refs.projectTypeNameCombo,
      this.$refs.managerOrganisationNameCombo,
    );
    this.$nextTick(() => {
      this.updateProjectInner();
    });
  }

  private async updateProjectInner() {
    const valid = this.validate();
    if (valid && this.formData) {
      this.saving = true;
      const requestModel = ProjectDetailsFormObject.createRequestModel(this.formData);
      const apiResponse = await executeApi(
        () => this.api.projects().postUpdateProjectDetails(this.projectGid, requestModel),
        'Update Project Details',
      );
      if (apiResponse && apiResponse.success) {
        this.setFormToCleanState();
        const routeName = this.isEditMode ? ROUTE_PROJECT_DETAILS : ROUTE_PROJECT;
        const routeParams = this.isEditMode ? { projectGid: this.projectGid } : {};
        // Even though we set the form to be clean just above,
        // the dirty modal still appears unless we reroute on the next tick
        this.$nextTick(() => {
          routeParams && routeParams.projectGid
            ? this.$router.push({ name: routeName, params: routeParams })
            : this.$router.push({ name: routeName });
        });
      }
      this.saving = false;
    }
  }

  private setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
  }

  private async getProjectDetails() {
    this.isLoading = true;

    const result = await executeApi(
      () => this.api.projects().getProjectDetails(this.projectGid),
      'Load Project Details',
    );

    if (result.success && result.data) {
      this.formData = ProjectDetailsFormObject.createFromApiResponse(result.data);
      this.originalThumbprint = this.getProjectDetailsThumbprint(this.formData);
      this.setProjectNameToBreadcrumb();
      this.currentThumbprint = this.originalThumbprint;
      this.isLoading = false;
    }
  }

  private async getProjectTypes(options?: { forceReload: boolean }) {
    if (
      options?.forceReload ||
      (this.formData.organisationGid && this.lastLoadedProjectTypeForOrg !== this.formData.organisationGid)
    ) {
      this.lastLoadedProjectTypeForOrg = this.formData.organisationGid;
      // make sure we have an organisation gid for the compiler
      const orgGid = this.formData.organisationGid;
      if (!orgGid) return;
      const result = await executeApi(() => this.api.organisation().getProjectTypes(orgGid), 'Load Project Types');

      if (result.success && result.data) {
        this.projectTypesData = result.data.projectTypes;
      }
    }
  }

  private async getProjectOwners() {
    const result = await executeApi(() => this.api.projects().getProjectOwners(), 'Load Project Owners');
    if (result.success && result.data) {
      this.projectOwnersData = result.data;
    }
  }

  private async getProjectManagerOrganisations() {
    const result = await executeApi(
      () => this.api.projects().getProjectManagerOrganisations(),
      'Load Project Manager Organisations',
    );

    if (result.success && result.data) {
      this.projectManagerOrgNameData = result.data;
    } else {
      this.projectManagerOrgNameData = [];
    }
  }

  private getTagProjectStatus(status: OtProjectStatus): TagStatus {
    return { type: OtStatusType.Project, status };
  }

  private async getUserProfile() {
    const results = await vxm.userProfile.getUserProfile();
    if (results) {
      results.organisations.forEach(org => {
        this.userOrganisations.push(org);
      });
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
  }
}
