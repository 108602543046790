































import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtSelect from '@/components/global/ot-select.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtApi, { executeApi } from '@/services/api.service';
import AuthService from '@/services/auth.service';
import { ScenarioInformationModel } from '@/services/generated/api';
import { vxm } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import MarkdownIt from 'markdown-it';

@Component({
  components: {
    OtSelect,
    OtButton,
    OtAutocomplete,
  },
})
export default class ScenariosWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private auth = new AuthService();
  private api = new OtApi();
  private dataScenarios: IAutocompleteItem<ScenarioInformationModel>[] = [];
  private selectedScenario: IAutocompleteItem<ScenarioInformationModel> | null = null;
  private searchInput = '';
  private markdown = new MarkdownIt();

  private settingScenario = false;

  // * COMPUTED
  private get hasSelectedScenario() {
    return this.selectedScenario !== null;
  }
  private get renderedSelectedScenarioDescription() {
    if (!this.selectedScenario?.data?.description) {
      return '';
    }
    return this.markdown.render(this.selectedScenario.data.description);
  }

  // * WATCHERS

  // * METHODS
  private async setScenario() {
    this.settingScenario = true;
    if (this.hasSelectedScenario) {
      await executeApi(() => this.api.scenario().postApply(this.selectedScenario?.data?.key), 'Apply Data Scenario');

      this.settingScenario = false;

      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Success,
          message: `Applied scenario ${this.selectedScenario?.label} `,
        }),
      );
    }
    this.settingScenario = false;
  }

  private logout() {
    this.auth.logout();
  }

  // * LIFECYCLE
  private async created() {
    const apiResponse = await executeApi(() => this.api.scenario().getList(), 'Load Data Scenarios');
    if (apiResponse.data) {
      this.dataScenarios = apiResponse.data.map(d => {
        return { label: d.displayName || d.key || '', data: d };
      });
    }
    const key = this.$route.query.key;

    if (typeof key === 'string') {
      this.selectedScenario = this.dataScenarios.find(x => x.data?.key === key) ?? null;
    }
    // this.searchInput = '200';
  }
}
