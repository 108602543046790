import { IAddressDisplayModel } from '@/components/global/common-models';
import {
  AddressModel,
  OrganisationStatus as ApiOrganisationStatus,
  GetMyProfileOrganisationModel,
  GetMyProfileResponseModel,
  PostEditMyUserProfileRequestModel,
  UserStatusModel,
} from '@/services/generated/api';
import { LastModified } from '@/utils/type-utils';

export enum OtOrganisationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  Inactive = 'INACTIVE',
}

export const parsedOtOrganisationEnums: { [key in ApiOrganisationStatus]: OtOrganisationStatus } = {
  [ApiOrganisationStatus.Active]: OtOrganisationStatus.Active,
  [ApiOrganisationStatus.Pending]: OtOrganisationStatus.Pending,
  [ApiOrganisationStatus.Suspended]: OtOrganisationStatus.Suspended,
  [ApiOrganisationStatus.Inactive]: OtOrganisationStatus.Inactive,
};

export const parsedApiOrganisationEnums: { [key in OtOrganisationStatus]: ApiOrganisationStatus } = {
  [OtOrganisationStatus.Active]: ApiOrganisationStatus.Active,
  [OtOrganisationStatus.Pending]: ApiOrganisationStatus.Pending,
  [OtOrganisationStatus.Suspended]: ApiOrganisationStatus.Suspended,
  [OtOrganisationStatus.Inactive]: ApiOrganisationStatus.Inactive,
};

export class ProfileAddressModel implements IAddressDisplayModel {
  public placeId!: string | null;
  public addressLine1!: string;
  public addressLine2!: string | null;
  public suburb!: string;
  public postcode!: string;
  public state!: string;
  public country!: string;

  public constructor(value: IAddressDisplayModel) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProfileAddressModel({
      placeId: null,
      addressLine1: '',
      addressLine2: null,
      suburb: '',
      postcode: '',
      state: '',
      country: '',
    });
  }

  public static createFromApiResponse(response: AddressModel) {
    return new ProfileAddressModel({
      placeId: null,
      addressLine1: response.line1 || '',
      addressLine2: response.line2 || null,
      suburb: response.suburb || '',
      postcode: response.postcode || '',
      state: response.state || '',
      country: response.country || '',
    });
  }
}

export interface IProfileOrganisationModel {
  gid: string;
  name: string;
  code: string;
  status: OtOrganisationStatus;
  phone: string;
  address: ProfileAddressModel;
  organisationUser: UserStatusModel;
}

export class ProfileOrganisationModel {
  public gid!: string;
  public name!: string;
  public code!: string;
  public status!: OtOrganisationStatus;
  public phone!: string;
  public address!: ProfileAddressModel;
  public organisationUser!: UserStatusModel;

  public constructor(value: IProfileOrganisationModel) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProfileOrganisationModel({
      gid: '',
      name: '',
      code: '',
      status: OtOrganisationStatus.Active,
      phone: '',
      address: ProfileAddressModel.createEmpty(),
      organisationUser: new UserStatusModel(),
    });
  }

  public static createFromApiResponse(response: GetMyProfileOrganisationModel) {
    return new ProfileOrganisationModel({
      gid: response.gid || '',
      name: response.name || '',
      code: response.code || '',
      status: parsedOtOrganisationEnums[response.status || ApiOrganisationStatus.Active],
      phone: response.phone || '',
      address: response.address
        ? ProfileAddressModel.createFromApiResponse(response.address)
        : ProfileAddressModel.createEmpty(),
      organisationUser: response.organisationUser || new UserStatusModel(),
    });
  }
}

export interface IUserProfile {
  gid: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string | null;
  phone: string | null;
  fax: string | null;
  businessName: string | null;
  streetAddress: ProfileAddressModel | null;
  postalAddress: ProfileAddressModel | null;
  organisations: ProfileOrganisationModel[];
  lastModified: LastModified;
  lastLoginUtc: Date;
  lastLoginIpAddress: string;
  canCreateContract: boolean;
}

export class UserProfile implements IUserProfile {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public mobile: string | null = null;
  public phone: string | null = null;
  public fax: string | null = null;
  public businessName: string | null = null;
  public streetAddress: ProfileAddressModel | null = null;
  public postalAddress: ProfileAddressModel | null = null;
  public organisations!: ProfileOrganisationModel[];
  public lastModified!: LastModified;
  public lastLoginUtc!: Date;
  public lastLoginIpAddress!: string;
  public canCreateContract!: boolean;

  public constructor(value: IUserProfile) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new UserProfile({
      gid: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: null,
      organisations: [],
      lastModified: LastModified.createEmpty(),
      lastLoginUtc: new Date(),
      lastLoginIpAddress: '',
      businessName: null,
      fax: null,
      phone: null,
      postalAddress: null,
      streetAddress: null,
      canCreateContract: false,
    });
  }

  public static createFromApiResponse(response: GetMyProfileResponseModel) {
    return new UserProfile({
      gid: response.gid || '',
      firstName: response.firstName || '',
      lastName: response.lastName || '',
      email: response.email || '',
      mobile: response.mobile || '',
      phone: response.phone ?? null,
      fax: response.fax ?? null,
      businessName: response.businessName ?? null,
      streetAddress: response.streetAddress ? ProfileAddressModel.createFromApiResponse(response.streetAddress) : null,
      postalAddress: response.postalAddress ? ProfileAddressModel.createFromApiResponse(response.postalAddress) : null,
      organisations: response.organisations
        ? response.organisations.map(organisation => ProfileOrganisationModel.createFromApiResponse(organisation))
        : [],
      lastModified: response.lastModified
        ? LastModified.createFromApiResponse(response.lastModified)
        : LastModified.createEmpty(),
      lastLoginUtc: response.lastLoginUtc || new Date(),
      lastLoginIpAddress: response.lastLoginIpAddress || '',
      canCreateContract: response.canCreateContract,
    });
  }

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export interface IProfileDetailsFormObject {
  firstName: string | null;
  lastName: string | null;
  businessName: string | null;
  streetAddress: ProfileAddressModel | null;
  postalAddress: ProfileAddressModel | null;
  mobile: string | null;
  phone: string | null;
  email: string | null;
  fax: string | null;
}
export class ProfileDetailsFormObject implements IProfileDetailsFormObject {
  firstName: string | null = null;
  lastName: string | null = null;
  businessName: string | null = null;
  streetAddress: ProfileAddressModel | null = null;
  postalAddress: ProfileAddressModel | null = null;
  mobile: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  fax: string | null = null;

  public constructor(value: IProfileDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProfileDetailsFormObject({
      firstName: '',
      lastName: '',
      businessName: null,
      streetAddress: null,
      postalAddress: null,
      mobile: null,
      phone: null,
      email: '',
      fax: null,
    });
  }

  public static createFromApiResponse(model: GetMyProfileResponseModel): ProfileDetailsFormObject {
    return new ProfileDetailsFormObject({
      firstName: model.firstName,
      lastName: model.lastName,
      businessName: model.businessName || null,
      streetAddress: model.streetAddress?.line1 ? ProfileAddressModel.createFromApiResponse(model.streetAddress) : null,
      postalAddress: model.postalAddress?.line1 ? ProfileAddressModel.createFromApiResponse(model.postalAddress) : null,
      mobile: model.mobile ?? null,
      phone: model.phone ?? null,
      email: model.email,
      fax: model.fax ?? null,
    });
  }

  public static createRequestModel(model: ProfileDetailsFormObject): PostEditMyUserProfileRequestModel {
    return new PostEditMyUserProfileRequestModel({
      firstName: model.firstName || '',
      lastName: model.lastName || '',
      businessName: model.businessName || undefined,
      streetAddress: model.streetAddress?.addressLine1
        ? new AddressModel({
          line1: model.streetAddress.addressLine1 || '',
          line2: model.streetAddress.addressLine2 || undefined,
          suburb: model.streetAddress.suburb || '',
          postcode: model.streetAddress.postcode || '',
          state: model.streetAddress.state || '',
          country: model.streetAddress.country || '',
        })
        : undefined,
      postalAddress: model.postalAddress?.addressLine1
        ? new AddressModel({
          line1: model.postalAddress.addressLine1 || '',
          line2: model.postalAddress.addressLine2 || undefined,
          suburb: model.postalAddress.suburb || '',
          postcode: model.postalAddress.postcode || '',
          state: model.postalAddress.state || '',
          country: model.postalAddress.country || '',
        })
        : undefined,
      mobile: model.mobile || undefined,
      phone: model.phone || undefined,
      email: model.email || '',
      fax: model.fax || '',
    });
  }
}
