

















import { Component, Vue } from 'vue-property-decorator';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';

@Component({})
export default class OtServerErrorHandlerWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private api = new OtApi();

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private async badRequest() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(400),
      'Test Api',
    );
  }

  private async unauthenticated() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(401),
      'Test Api',
      undefined,
      { attemptSilentLoginOn401AndRetry: false },
    );
  }

  private async forbidden() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(403),
      'Test Api',
    );
  }

  private async notFound() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(404),
      'Test Api',
    );
  }

  private async unprocessableEntity() {
    const override: IApiErrorOverride = {
      status: 422,
      message: `${ExecuteApiValue.ApiName} returned a validation error: Test Error Message}`,
    };
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(422),
      'Test Api',
      override,
    );
  }

  private async serverError() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(500),
      'Test Api',
    );
  }

  private async unknownError() {
    await executeApi(
      () => this.api.failureTest().getGetStatusCodeOfYourChoiceValidateAndProcessResultNullValue(405),
      'Test Api',
    );
  }

  // * LIFECYCLE
}
