



































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenOptionValueWithOther } from '../models/data-driven-result';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtRadioGroup,
    OtTextarea,
  },
})
export default class WfRadioButtonListWithOtherEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private label: string | null = null;
  private description: string | null = null;
  private placeholder: string | null = null;
  private requiredMessage: string | null = null;

  // * COMPUTED
  private get radioOptions(): IRadioGroupOption[] {
    return this.question.optionsOrdered.map(o => {
      return { label: o.title, key: o.key };
    });
  }

  private selectedValuePrivate: IRadioGroupOption | null = null;
  private get selectedValue(): IRadioGroupOption | null {
    return this.selectedValuePrivate;
  }
  private set selectedValue(val: IRadioGroupOption | null) {
    this.selectedValuePrivate = val;
    this.onChange();
  }

  private otherDescriptionPrivate: string | null = null;
  private get otherDescription() {
    return this.otherDescriptionPrivate;
  }
  private set otherDescription(val: string | null) {
    this.otherDescriptionPrivate = val;
    this.onChange();
  }

  private get isOtherSelected() {
    return this.selectedValue === this.radioOptions[this.radioOptions.length - 1];
  }

  private get disableTextarea() {
    return !this.isOtherSelected;
  }

  private get otherRequiredMessage() {
    if (this.isOtherSelected) {
      return 'This field is required';
    }
    return null;
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsValueWithOtherModel') {
        return this.value.result;
      }
      console.warn(
        'wf-radio-button-list-with-other-edit -> result -> ResultType is incorrect. Expected OptionsValueWithOtherModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const val = this.radioOptions.find(o => o.key === this.result?.key) || null;
    this.selectedValuePrivate = val;

    this.otherDescriptionPrivate = this.result?.text || null;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionValueWithOther({
      resultType: 'OptionsValueWithOtherModel',
      key: this.selectedValue?.key || '',
      text: this.otherDescription,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.description = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
