
































































import { escapeHTML } from '@/utils/string-utils';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';
import { v4 as uuid } from 'uuid';
import { IVForm } from '@/utils/type-utils';

export interface IAutocompleteItem<T> {
  label: string;
  data: T | null;
  disabled?: boolean;
}

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtAutocomplete extends Vue {
  // * PROPS
  @Prop() private value!: IAutocompleteItem<unknown> | IAutocompleteItem<unknown>[];
  @Prop() private items!: IAutocompleteItem<unknown>[];
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private secondaryLabel?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private searchInput?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: String }) private placeholder?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private disableIconRotation!: boolean;
  @Prop({ type: Boolean, default: false }) private validateOnBlur!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: String, default: '$chevronDown' }) private dropdownIcon!: string;
  // set this to false if you need the autocomplete to be able to "hang outside" the parent component, and be wider than the field
  // but be careful, if the parent component is scrollable, sometimes (happens in a dialog) the picker will no longer "follow" the field and it'll look dumb
  @Prop({ type: Boolean, default: true }) private attachToField!: boolean;

  // * REFS
  @Ref('autocompleteRef') private autocompleteRef!: IVForm;

  // * DATA
  private isRequiredRule = (value: string) => !!value || this.requiredMessage || '';
  private componentId = '';

  // * COMPUTED
  private get showHint() {
    return !!this.hint || !!this.$slots.hint;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private get selectedItem(): IAutocompleteItem<any> | IAutocompleteItem<any>[] {
    return this.value;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private set selectedItem(value: IAutocompleteItem<any> | IAutocompleteItem<any>[]) {
    this.$emit('input', value);
    if (!this.value) {
      this.disableTabbingToClearIcon();
    }
  }

  // private searchInputLocal: string | null = null;
  private get searchInputPrivate(): string | null | undefined {
    return this.searchInput;
  }
  private set searchInputPrivate(value: string | null | undefined) {
    // this.searchInputLocal = value;
    this.$emit('update:searchInput', value);
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  // * WATCHERS

  // * METHODS
  // This filter highlighting is the same as how vuetify automatically does it.
  // But vuetify can't do it when you use the item slot.
  // So by copying the code from https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VSelect/VSelectList.ts#L113
  // we can use the item slot while keeping the filter highlighting.
  private generateFilteredText(text: string): string {
    if (!this.searchInput) return escapeHTML(text);

    const { start, middle, end } = this.getMaskedCharacters(text);

    return `${escapeHTML(start)}${this.genHighlight(middle)}${escapeHTML(end)}`;
  }

  private genHighlight(text: string): string {
    return `<span class="v-list-item__mask">${escapeHTML(text)}</span>`;
  }

  private getMaskedCharacters(text: string): { start: string; middle: string; end: string } {
    const searchInput = (this.searchInput || '').toString().toLocaleLowerCase();
    const index = text.toLocaleLowerCase().indexOf(searchInput);

    if (index < 0) return { start: text, middle: '', end: '' };

    const start = text.slice(0, index);
    const middle = text.slice(index, index + searchInput.length);
    const end = text.slice(index + searchInput.length);
    return { start, middle, end };
  }

  private disableTabbingToClearIcon() {
    const element = this.autocompleteRef.$el.getElementsByClassName('v-icon mdi-close').item(0);
    if (element) {
      element?.setAttribute('tabindex', '-1');
    }
  }

  // * LIFECYCLE
  private created() {
    this.componentId = `e-${uuid()}`;
  }

  private mounted() {
    this.disableTabbingToClearIcon();
  }
}
