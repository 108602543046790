// idea lifted from
// https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/master/src/components/_globals.js
// and
// https://vuejs.org/v2/guide/components-registration.html
// TODO consider subfolders
// TODO consider numbers for sorting so that 00-buttons and 01-abc come out buttons then abc
import Vue from 'vue';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  './component-wrappers',
  // Do not look in subdirectories
  false,
  // Only include "_playground-" prefixed .vue files
  /^.*-wrapper*\.vue$/,
);

export const PLAYGROUND_COMPONENTS = requireComponent.keys().map(fileName => {
  // Get the PascalCase version of the component name
  const componentName = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    // .replace('-wrapper', '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
    // Split up kebabs
    .split('-')
    // Upper case
    .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join('');

  // could get fancier here and allow for things like _playground-buttons--standard
  // and/or if we ever allow for subfolders (hint: we should) then they could be used for the name too
  const componentDescription = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, '')
    .replace('-wrapper', '')
    // Remove the file extension from the end
    .replace(/\.\w+$/, '')
    // Split up kebabs
    .split('-')
    // Upper case
    .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    // Concatenated
    .join(' ');

  return {
    fileName: fileName,
    componentName: componentName,
    description: componentDescription,
  };
});

// For each matching file name...
PLAYGROUND_COMPONENTS.forEach(component => {
  // Get the component config
  const componentConfig = requireComponent(component.fileName);

  // Globally register the component
  Vue.component(component.componentName, componentConfig.default || componentConfig);
});
