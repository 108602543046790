












































































































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { OtUserAction } from '@/types/action-enums';
import {
  PostSetContractUserStatusUserStatusModel,
  PostSetProjectUserStatusUserStatusModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { RawLocation } from 'vue-router/types/router';
import { AccessType } from '@/models/shared-models';
import { parsedApiOrgUserStatusEnums } from '@/models/user-models';
import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { ProjectUser } from './project-user-models';
import { ProjectDetails } from '../project-models';
import { ROUTE_PROJECT_USER_DETAILS } from '../projects-routes';
import { UserStatusWithActions } from '@/models/user-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (project: ProjectUser) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtUserStatusTags,
    OtInviteUserDialog,
  },
})
export default class OtProjectUsersTable extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private project!: ProjectDetails;
  @Prop({ default: () => [] }) private projectUsers!: ProjectUser[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  private tableColumns: IExtendedColumnData<ProjectUser>[] = [
    {
      index: 0,
      label: 'Name',
      key: 'name',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: ProjectUser.compareByName,
    },
    {
      index: 1,
      label: 'Email',
      key: 'email',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: ProjectUser.compareByEmail,
    },
    {
      index: 2,
      label: 'Project Role',
      key: 'title',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: ProjectUser.compareByProjectRole,
    },
    {
      index: 3,
      label: 'Org.',
      key: 'org',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 4,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 5,
      label: 'Contracts',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 6,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private activeColumn = this.tableColumns[0];

  private openUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Inactive', OtUserStatus.Inactive),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
    new CheckboxGroupItem('Rejected Invite', OtUserStatus.RejectedInvitation),
  ];

  private selectedUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
  ];

  private typeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [
    new CheckboxGroupItem('Projects', AccessType.Projects),
    new CheckboxGroupItem('Contracts', AccessType.Contracts),
    new CheckboxGroupItem('Organisation', AccessType.Organisations),
  ];

  private selectedTypeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [];

  // * COMPUTED

  private get sortedBodyData() {
    const sortedProjectUsers = sortTableData(this.tableColumns, this.activeColumn, this.projectUsers);

    return sortedProjectUsers;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedUserStatusFilters.map(s => s.value);
    const parsedAccessTypes = this.selectedTypeOfAccessFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        ProjectUser.filterByUserStatus(parsedStatuses, row) &&
        ProjectUser.filterByTypeOfAccess(parsedAccessTypes, row) &&
        ProjectUser.filterByName(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS

  // * METHODS

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }
  // TODO: Recheck and fix
  private getProjectUserDetailsRoute(projectUser: ProjectUser): RawLocation {
    return {
      name: ROUTE_PROJECT_USER_DETAILS,
      params: { userGid: projectUser.gid, projectGid: this.project.gid },
    };
  }
  // TODO: Recheck and fix
  private handleDeactivateProjectUser = (projectUser: UserStatusWithActions | null): IMenuOption => {
    return {
      key: projectUser ? projectUser.gid : '',
      label: 'Deactivate for Project',
      onClick: () => {
        if (projectUser) {
          this.setProjectUserToDeactivatedForProject(projectUser);
        }
      },
    };
  };

  private handleDeactivateInAllContracts = (projectUser: ProjectUser): IMenuOption => {
    return {
      key: projectUser.gid,
      label: 'Deactivate for All Contracts',
      onClick: () => {
        this.setProjectUserToDeactivatedForAllContracts(projectUser);
      },
    };
  };

  private handleReinviteUser = (projectUser: ProjectUser): IMenuOption => {
    return {
      key: projectUser.gid, // This is the user gid
      label: 'Reinvite User',
      onClick: () => {
        this.reInviteUser(projectUser);
      },
    };
  };
  private async setProjectUserToDeactivatedForProject(projectUser: UserStatusWithActions) {
    this.rowLoadingStates[projectUser.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to deactivate project user ${projectUser.gid}, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetProjectUserStatusUserStatusModel[] = [
      new PostSetProjectUserStatusUserStatusModel({
        gid: projectUser.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (!body[0].gid) {
      this.rowLoadingStates[projectUser.gid] = false;
      return;
    }

    const postResponse = await executeApi(
      () => this.api.projectUser().postSetProjectUserStatus(body),
      `Set Project User ${projectUser.gid} Status to Deactivated`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadProjectUsers', () => {
        this.rowLoadingStates[projectUser.gid] = false;
      });
    } else {
      this.rowLoadingStates[projectUser.gid] = false;
    }
  }

  private async setProjectUserToDeactivatedForAllContracts(projectUser: ProjectUser) {
    this.rowLoadingStates[projectUser.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      // NOTE which id are be to give to the warning ? should it be a n array of contract ids ?
      message: `Unable to deactivate user ${projectUser.gid} in all contracts, ${ExecuteApiValue.ValidationError}`,
    };

    const body: PostSetContractUserStatusUserStatusModel[] = projectUser.contracts
      .filter(o => o.availableActions.some(a => a === OtUserAction.Deactivate))
      .map(p => {
        return new PostSetContractUserStatusUserStatusModel({
          gid: p.gid,
          status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
        });
      });

    if (body.length === 0) {
      this.rowLoadingStates[projectUser.gid] = false;
      return;
    }
    const postResponse = await executeApi(
      () => this.api.contractUser().postSetContractUserStatus(body),
      `Deactivating user in all contracts`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadProjectUsers', () => {
        this.rowLoadingStates[projectUser.gid] = false;
      });
    } else {
      this.rowLoadingStates[projectUser.gid] = false;
    }
    this.rowLoadingStates[projectUser.gid] = false;
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async reInviteUser(projectUser: ProjectUser) {
    this.rowLoadingStates[projectUser.gid] = true;

    const reInviteToOrg = projectUser.organisation?.availableActions.includes(OtUserAction.Reinvite)
      ? projectUser.organisation
      : null;
    const reInviteToProjects = projectUser.project?.availableActions.includes(OtUserAction.Reinvite)
      ? [projectUser.project]
      : [];
    const reinviteToContracts = projectUser.contracts.filter(x => x.availableActions.includes(OtUserAction.Reinvite));

    const params = new InviteUserDialogParams({
      isReinvite: true,
      organisationGid: this.project.organisationGid,
      inviteToOrganisation: Boolean(reInviteToOrg),
      invitedUserGid: projectUser.gid, // this is the user gid, not the project user gid
      userContracts: reinviteToContracts.map(x => x.gid),
      userProjects: reInviteToProjects.map(x => x.gid),
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }

    this.rowLoadingStates[projectUser.gid] = false;
  }

  private getProjectUserOptionMenuItems(projectUser: ProjectUser): IMenuOption[] {
    const results: IMenuOption[] = [];

    const projectDeactivateAction = projectUser.project?.availableActions?.includes(OtUserAction.Deactivate);

    if (projectDeactivateAction) {
      results.push(this.handleDeactivateProjectUser(projectUser.project));
    }

    const contractDeactivateAction = projectUser.contracts.some(c =>
      c.availableActions?.includes(OtUserAction.Deactivate),
    );
    if (contractDeactivateAction) {
      results.push(this.handleDeactivateInAllContracts(projectUser));
    }

    const reinviteAction =
      projectUser.organisation?.availableActions?.includes(OtUserAction.Reinvite) ||
      projectUser.project?.availableActions?.includes(OtUserAction.Reinvite) ||
      projectUser.contracts.some(c => c.availableActions?.includes(OtUserAction.Reinvite));
    if (reinviteAction) {
      results.push(this.handleReinviteUser(projectUser));
    }

    return results;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.projectUsers.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  // * LIFECYCLE
  private created() {
    this.setRowLoadingStates();
  }
}
