import { parsedOtContractEnums } from '@/areas/projects/contracts/contract-models';
import { UserStatus } from '@/models/user-models';
import {
  GetOrganisationUserProjectsContractModel,
  GetOrganisationUserProjectsProjectModel,
  ProjectStatus,
} from '@/services/generated/api';
import { OtContractStatus, OtUserStatus } from '@/types/status-enums';
import { AccessType } from '@/models/shared-models';

// create the interface for GetOrganisationUserProjectsContractModel
export interface IOrganisationUserProjectsContract {
  gid: string;
  name: string;
  status: OtContractStatus;
  contractUser?: UserStatus;
  currentUserContractUser?: UserStatus;
}

export class OrganisationUserProjectsContract implements IOrganisationUserProjectsContract {
  public gid!: string;
  public name!: string;
  public status!: OtContractStatus;
  public contractUser?: UserStatus;
  public currentUserContractUser?: UserStatus;

  public constructor(value: IOrganisationUserProjectsContract) {
    Object.assign(this, value);
  }

  public static createEmpty(): OrganisationUserProjectsContract {
    return new OrganisationUserProjectsContract({
      gid: '',
      name: '',
      status: OtContractStatus.Active,
      contractUser: undefined,
      currentUserContractUser: undefined,
    });
  }

  public static createFromApiResponse(
    model: GetOrganisationUserProjectsContractModel,
  ): OrganisationUserProjectsContract {
    return new OrganisationUserProjectsContract({
      gid: model.gid,
      name: model.name,
      status: parsedOtContractEnums[model.status],
      contractUser: model.contractUser ? UserStatus.createFromApiResponse(model.contractUser) : undefined,
      currentUserContractUser: model.currentUserContractUser
        ? UserStatus.createFromApiResponse(model.currentUserContractUser)
        : undefined,
    });
  }
}

export interface IOrganisationUserProject {
  gid: string;
  code: string;
  name: string;
  status: ProjectStatus;
  contracts: OrganisationUserProjectsContract[];
  organisationUser?: UserStatus;
  projectUser?: UserStatus;
  currentUserOrganisationUser?: UserStatus;
  currentUserProjectUser?: UserStatus;
}

export class OrganisationUserProject implements IOrganisationUserProject {
  public gid!: string;
  public code!: string;
  public name!: string;
  public status!: ProjectStatus;
  public contracts!: OrganisationUserProjectsContract[];
  public organisationUser?: UserStatus;
  public projectUser?: UserStatus;
  public currentUserOrganisationUser?: UserStatus;
  public currentUserProjectUser?: UserStatus;

  public constructor(value: IOrganisationUserProject) {
    Object.assign(this, value);
  }

  public static createEmpty(): OrganisationUserProject {
    return new OrganisationUserProject({
      gid: '',
      code: '',
      name: '',
      status: ProjectStatus.Active,
      contracts: [],
      organisationUser: undefined,
      projectUser: undefined,
      currentUserOrganisationUser: undefined,
      currentUserProjectUser: undefined,
    });
  }

  public static createFromApiResponse(model: GetOrganisationUserProjectsProjectModel): OrganisationUserProject {
    return new OrganisationUserProject({
      gid: model.gid,
      code: model.code,
      name: model.name,
      status: model.status,
      contracts: model.contracts.map(contract => OrganisationUserProjectsContract.createFromApiResponse(contract)),
      organisationUser: model.organisationUser ? UserStatus.createFromApiResponse(model.organisationUser) : undefined,
      projectUser: model.projectUser ? UserStatus.createFromApiResponse(model.projectUser) : undefined,
      currentUserOrganisationUser: model.currentUserOrganisationUser
        ? UserStatus.createFromApiResponse(model.currentUserOrganisationUser)
        : undefined,
      currentUserProjectUser: model.currentUserProjectUser
        ? UserStatus.createFromApiResponse(model.currentUserProjectUser)
        : undefined,
    });
  }

  public static compareByName(a: OrganisationUserProject, b: OrganisationUserProject, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const projectNameA = a.name;
    const projectNameB = b.name;
    return projectNameA.localeCompare(projectNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByCode(a: OrganisationUserProject, b: OrganisationUserProject, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const codeA = a.code;
    const codeB = b.code;
    return codeA.localeCompare(codeB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByProjectRole(a: OrganisationUserProject, b: OrganisationUserProject, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const titleA = a.projectUser?.title || '';
    const titleB = b.projectUser?.title || '';
    // im adding this but Dan, you might think of a better way to handle this ( or if its even required at all )
    // if they are both undefined, then we want to sort them as equal - otherwise if one is undefined,
    // we want to sort it as greater than the other one
    // logically that seems ok to me but again, you might have a better idea.
    // Treat empty strings as greater than any non-empty value
    if (!titleA && !titleB) {
      return 0;
    } else if (!titleA && titleB) {
      return 1 * sortModifier;
    } else if (titleA && !titleB) {
      return -1 * sortModifier;
    }

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, project: OrganisationUserProject) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return project.name.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], project: OrganisationUserProject) {
    if (statuses.length) {
      return statuses.some(
        status =>
          project.organisationUser?.status === status ||
          project.projectUser?.status === status ||
          project.contracts.some(contract => contract.contractUser?.status === status),
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], project: OrganisationUserProject) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return Boolean(project.organisationUser);
          case AccessType.Projects:
            return Boolean(project.projectUser);
          case AccessType.Contracts:
            return project.contracts.length > 0;
          default:
            return false;
        }
      });
    }
    return true;
  }
}

export interface IOrganisationUserProjectsProjects {
  project: GetOrganisationUserProjectsProjectModel;
}
