











































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';
import { IInputIcon } from '@/components/global/common-models';
import { IValidate } from '@/utils/type-utils';

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtTextField extends Vue {
  // * PROPS
  @Prop() private value!: string;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop() private appendIcon?: IInputIcon;
  @Prop() private prependIcon?: IInputIcon;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: String, default: 'auto' }) private hideDetails!: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: true }) private validateOnBlur!: boolean;
  @Prop({ type: String }) private secondaryLabel?: string;

  // * REFS
  @Ref('vuetifyTextFieldRef') public readonly vuetifyTextFieldRef!: IValidate;

  // * DATA
  private isRequiredRule = (value: string | null | undefined) =>
    Boolean((value ?? '').toString().trim().length) || this.requiredMessage || '';

  // * COMPUTED
  private get textValue() {
    return this.value;
  }

  private set textValue(value: string) {
    this.$emit('input', value);
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }

    return rules;
  }

  // * WATCHERS

  // * METHODS
  public validate() {
    return this.vuetifyTextFieldRef.validate();
  }

  // * LIFECYCLE
}
