
































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import {
  CheckboxGroupItem,
  CheckboxGroupSet,
  CheckboxGroupType,
} from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import { IVuetifyCheckbox } from '@/utils/type-utils';

@Component({
  name: 'ot-checkbox-group-set',
  components: {
    OtCheckbox,
  },
})
export default class OtCheckboxGroupSet extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private value!: CheckboxGroupItem<unknown>[];
  @Prop({ default: () => [] }) private item!: CheckboxGroupType<unknown>;
  @Prop({ type: Boolean, default: true }) private isChildSetAndParentIsChecked!: boolean;
  @Prop({ default: 'true' }) private hideDetails!: string;
  @Prop() private rules?: Array<(value: boolean) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private hasError!: boolean;
  @Prop({ type: Boolean, default: false }) private box!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop() private beforeCheckboxValueSet?: (item: CheckboxGroupItem<unknown>, value: boolean) => Promise<boolean>;

  // * REFS
  @Ref('soloCheckboxRef') private readonly soloCheckboxRef!: OtCheckbox | null;
  @Ref('parentCheckboxRef') private readonly parentCheckboxRef!: OtCheckbox | null;
  @Ref('childCheckboxRef') private readonly childCheckboxRef!: OtCheckboxGroupSet | OtCheckboxGroupSet[] | null;

  // * DATA
  private isChildParentChecked = true;
  private isParentIndeterminate = false;

  // * COMPUTED
  private get hasChildren() {
    return this.item instanceof CheckboxGroupSet;
  }

  private localValuePrivate = this.value;
  private get localValue() {
    return this.localValuePrivate;
  }
  private set localValue(val: CheckboxGroupItem<unknown>[]) {
    this.localValuePrivate = val;
    this.$emit('input', val);
  }

  private get isParentChecked() {
    if (this.item instanceof CheckboxGroupSet) {
      if (this.item.items instanceof Array) {
        const itemLabels = (
          this.item.items.filter(i => i instanceof CheckboxGroupItem) as CheckboxGroupItem<unknown>[]
        ).map(i => i.label);
        const selecteditemLabels = this.localValue.map(i => i.label);

        const isChecked = itemLabels.every(i => selecteditemLabels.includes(i)) && this.isChildParentChecked;

        const isIndeterminate = itemLabels.some(i => {
          return selecteditemLabels.includes(i);
        });
        this.isParentIndeterminate = isIndeterminate && !isChecked;

        this.$emit('update:isChildSetAndParentIsChecked', isChecked);
        return isChecked;
      }
    }
    this.$emit('update:isChildSetAndParentIsChecked', false);
    return false;
  }
  private set isParentChecked(value: boolean) {
    this.isParentIndeterminate = false;

    if (this.item instanceof CheckboxGroupSet) {
      this.updateCheckboxSetItems(this.item.items, value);
    }
  }

  private get localHasError() {
    return this.hasError;
  }
  private set localHasError(val: boolean) {
    this.$emit('update:hasError', val);
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.localValuePrivate = this.value;
  }

  // * METHODS
  private updateCheckboxSetItems(
    set: CheckboxGroupSet<unknown> | Array<CheckboxGroupItem<unknown> | CheckboxGroupSet<unknown>>,
    value: boolean,
  ) {
    if (set instanceof Array) {
      for (const item of set) {
        if (item instanceof CheckboxGroupSet) {
          this.updateCheckboxSetItems(item, value);
        } else if (item instanceof CheckboxGroupItem) {
          this.updateCheckboxItem(item, value);
        }
      }
    } else {
      this.updateCheckboxSetItems(set.items, value);
    }
  }

  public resetManualErrorState() {
    this.soloCheckboxRef?.resetManualErrorState();
    this.parentCheckboxRef?.resetManualErrorState();
    if (Array.isArray(this.childCheckboxRef)) {
      this.childCheckboxRef?.forEach(c => c.resetManualErrorState());
    } else {
      this.childCheckboxRef?.resetManualErrorState();
    }
  }

  private checkboxItemGetter(item: CheckboxGroupItem<unknown>) {
    return this.localValue.some(i => i.value === item.value);
  }

  private async updateCheckboxItem(item: CheckboxGroupItem<unknown>, value: boolean) {
    const itemChecked = this.checkboxItemGetter(item);
    if (itemChecked === value) {
      return;
    }

    if (this.beforeCheckboxValueSet) {
      if (this.soloCheckboxRef) {
        (this.soloCheckboxRef.checkboxRef as IVuetifyCheckbox).lazyValue = !value;
        const response = await this.beforeCheckboxValueSet(item, value);
        if (!response) {
          return;
        } else {
          (this.soloCheckboxRef.checkboxRef as IVuetifyCheckbox).lazyValue = value;
        }
      }
    }

    const newValue = [...this.localValue];
    if (value) {
      newValue.push(item);
    } else {
      const foundIndex = newValue.findIndex(i => i.value === item.value);
      newValue.splice(foundIndex, 1);
    }

    this.localValue = newValue;
  }

  private actionClicked(item: CheckboxGroupItem<unknown>) {
    this.$emit('actionClicked', item);
  }

  // * LIFECYCLE
  private created() {
    this.localValuePrivate = this.value;
  }
}
