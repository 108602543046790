
























































































import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtWorkflowQuestionIndent from '@/components/workflow-engine/ot-workflow-question-indent.vue';
import { IWizardContentComponent } from '@/types/wizard-types';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass } from '@/utils/validation-utils';
import WfCheckboxListWithActionEdit from '@/wf-components/edit-mode/wf-checkbox-list-with-action-edit.vue';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';
import { ISyncableQuestionClient } from '@/wf-components/models/data-driven-client-interfaces';
import { OtDataDrivenConfig } from '@/wf-components/models/data-driven-config';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { OtDataDrivenLayout } from '@/wf-components/models/data-driven-layout';
import { OtDataDrivenQuestion } from '@/wf-components/models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '@/wf-components/models/data-driven-response';
import { OtDataDrivenResults } from '@/wf-components/models/data-driven-result';
import { IDataDrivenSection, OtDataDrivenSection } from '@/wf-components/models/data-driven-section';
import { getSelectedKeysForDependsOn, passesDependsOn } from '@/wf-components/utils/depends-on-utils';
import { getSelectedKeysFromResponses } from '@/wf-components/utils/response-key-utils';
import _ from 'lodash';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { FormModalParams, FormModalResult, FormModalSizeEnum } from '../global/modal/form-modal-models';
import { IConfirmValueChangeEmitobject, IExecuteDialogCommandEmitobject } from './ot-workflow-engine-processor.vue';

class OtDataDrivenSectionsWithGroupedQuestions extends OtDataDrivenSection {
  groupedQuestions: OtQuestionsGroupedByIndentLevel[];

  constructor(params: { sectionObject: IDataDrivenSection; groupedQuestions: OtQuestionsGroupedByIndentLevel[] }) {
    super(params.sectionObject);
    this.groupedQuestions = params.groupedQuestions;
  }
}

class OtQuestionsGroupedByIndentLevel {
  public key: string;
  public indentLevel: number;
  public questions: OtDataDrivenQuestion[];

  constructor(params: { key: string; indentLevel: number; questions: OtDataDrivenQuestion[] }) {
    this.key = params.key;
    this.indentLevel = params.indentLevel;
    this.questions = params.questions;
  }
}

type TemplateContextObject = {
  Context2: { [key: string]: unknown };
  Config: { [key: string]: OtDataDrivenConfig };
  Response: { [key: string]: OtDataDrivenResults };
  OtherResponses: { [key: string]: OtDataDrivenResults };
  Keys: { [key: string]: true };
  ConfigKeys: { [key: string]: true };
  ResponseKeys: { [key: string]: true };
  AllKeys: { [key: string]: true };
};

function getBlankTemplateObject(): TemplateContextObject {
  return {
    Context2: {},
    Config: {},
    Response: {},
    OtherResponses: {},
    Keys: {},
    ConfigKeys: {},
    ResponseKeys: {},
    AllKeys: {},
  };
}

@Component({
  components: {
    OtFormModal,
    OtWorkflowQuestionIndent,
  },
})
export default class OtWorkflowEngineRenderer extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() definition!: OtDataDrivenDefinition;
  @Prop() layoutKey!: string | null;
  @Prop() instance!: OtDataDrivenInstance;
  @Prop() evaluation?: OtClientEvaluation;
  @Prop() parentInstances?: OtDataDrivenInstances;
  @Prop() instances!: OtDataDrivenInstances;
  @Prop({ default: false }) displayMode!: boolean;
  @Prop() defaultValuesGroupedByLayout!: IResponsesGroupedByLayoutKey;

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('formContentRef') private readonly formContentRef!: IVForm;
  @Ref('workflowEngineRef') private readonly workflowEngineRef?: HTMLDivElement;

  // * DATA
  private responseDictionary: { [key: string]: OtDataDrivenResponse | null } = {};
  private disabledComponentsDictionary: { [key: string]: boolean } = {};
  private hiddenComponentsDictionary: { [key: string]: boolean } = {};
  private dialogIsVisible = false;
  private formContainerElement: Element | null = null;

  private originalThumbprint = '';
  private currentThumbprint = '';

  // * COMPUTED
  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  private get selectedLayout(): OtDataDrivenLayout | null {
    return this.definition.layoutsOrdered.find(l => l.key === this.layoutKey) || null;
  }

  private get layoutSections(): OtDataDrivenSectionsWithGroupedQuestions[] {
    if (this.selectedLayout) {
      // Ignore every layout where all the questions are hidden
      // as well as ignoring all layouts that have no questions
      const sectionsWithVisibleQuestions = this.selectedLayout.sectionsOrdered.filter(
        s => !s.questions.every(q => this.hiddenComponentsDictionary[q.key] === true),
      );
      const sectionsWithGroupedQuestions: OtDataDrivenSectionsWithGroupedQuestions[] = sectionsWithVisibleQuestions.map(
        s =>
          new OtDataDrivenSectionsWithGroupedQuestions({
            sectionObject: {
              key: s.key,
              title: s.title,
              orderIndex: s.orderIndex,
              description: s.description,
              questions: s.questions,
            },
            groupedQuestions: this.groupQuestionsByIndentLevel(s.questionsOrdered),
          }),
      );
      return sectionsWithGroupedQuestions;
    }
    return [];
  }

  private get displayAsDialog() {
    return this.selectedLayout?.type === LayoutTypeEnum.Dialog;
  }
  private get context2() {
    return this.evaluation?.context2 || {};
  }

  // * WATCHERS
  @Watch('instance', { deep: true })
  private instanceChanged() {
    // This looks like it's being doubled up in the onChange event
    // but it is needed here too otherwise a modal using the secondary
    // workflow engine doesn't get updated properly.
    // It's also still needed in the onChange event as this doesn't pickup every change
    // even though it's got deep set to true
    this.setResponseDictionaryValues();
    // we might need this one. It's not a live typing changing an answer thing
    // the comments above sound like it's for modal dialogs, which we only really do in amendments
    // this.setupTemplateContextObject();
  }

  @Watch('definition')
  private definitionChanged() {
    this.initializeResponseDictionary();
    this.setResponseDictionaryValues();
    this.setupTemplateContextObject();
    this.processDependsOnComponents();
  }

  @Watch('layoutKey')
  private layoutKeyChanged() {
    this.processDependsOnComponents(true);
    // I think this is probably not needed - every time we next/prev, evaluation will change
    // this.setupTemplateContextObject();
    this.runStringTemplates();
  }

  @Watch('evaluation', { deep: true })
  private evaluationChanged() {
    this.setupTemplateContextObject();
    this.runStringTemplates();
  }

  // * METHODS
  private getTemplateName(question: OtDataDrivenQuestion) {
    if (this.displayMode) {
      return `Wf${question.displayTemplate}`;
    }
    return `Wf${question.editTemplate}`;
  }
  private isSystemControlled(questionKey: string) {
    const result = this.responseDictionary[questionKey];
    return Boolean(result?.systemControlled);
  }

  private groupQuestionsByIndentLevel(questions: OtDataDrivenQuestion[]): OtQuestionsGroupedByIndentLevel[] {
    const groupedQuestions: OtQuestionsGroupedByIndentLevel[] = [];
    if (questions.length) {
      groupedQuestions.push({
        key: `${questions[0].key}-indent-group-${questions[0].indentLevel}`,
        indentLevel: questions[0].indentLevel,
        questions: [questions[0]],
      });
      if (questions.length > 1) {
        for (let i = 1; i < questions.length; i++) {
          const currentQuestion = questions[i];
          const lastGroupedQuestion = groupedQuestions[groupedQuestions.length - 1];
          if (currentQuestion.indentLevel === lastGroupedQuestion.indentLevel) {
            lastGroupedQuestion.questions.push(currentQuestion);
          } else {
            groupedQuestions.push({
              key: `${currentQuestion.key}-indent-group-${currentQuestion.indentLevel}`,
              indentLevel: currentQuestion.indentLevel,
              questions: [currentQuestion],
            });
          }
        }
      }
    }
    return groupedQuestions;
  }

  public processDependsOnComponents(resetQuestions = false) {
    if (this.selectedLayout) {
      // nah, this wasn't needed, the problem with validation thinking there was a validation error after
      // something got dependsOn'd out was actually the custom components not getting unregistered from the form
      // // take a copy of what was hidden before we started
      // // we'll use this to optinally reset validation if something new gets hidden
      // const previouslyHidden = { ...this.hiddenComponentsDictionary };

      this.resetDisabledAndHiddenComponents();

      const selectedKeys = getSelectedKeysForDependsOn(this.instance, this.definition, this.evaluation);

      // console.log('renderer -> processDependsOnComponents, selectedKeys', Array.from(selectedKeys));

      for (const section of this.selectedLayout?.sectionsOrdered) {
        for (const question of section.questionsOrdered) {
          // if (question.dependsOn?.options?.length) {
          //   console.log('  renderer -> processDependsOnComponents, question has dependsOn', {
          //     key: question.key,
          //     options: question.dependsOn.options,
          //   });
          // }
          if (!passesDependsOn(question.dependsOn, selectedKeys)) {
            if (resetQuestions) {
              // If the question has resetToParentResponseOnNotActive set to true we need to reset the value back to the parent claim
              if (question.dependsOn?.resetToParentResponseOnNotActive && this.parentInstances?.instances.length) {
                // ONETRACK-1883 FC11031R04 - Data Driven Edit UI Engine
                // find the most recently answered parent Response for this question
                // yeah, not a fan of sorting, then reversing, but it makes for a pretty line of code doesn't it?
                // feels horribly innefficient to go sorting this all the time as well. But we do a lot of "damn this feels dumb"
                // that could be smoothed over with some @watch and created() work
                const sortedInstances = [...this.parentInstances.instances]
                  .sort(OtDataDrivenInstance.compareByCreated)
                  .reverse();
                let parentResponse: OtDataDrivenResponse | null = null;
                for (const instance of sortedInstances) {
                  const thisResponse = instance.responses.find(r => r.questionKey == question.key);
                  if (thisResponse) {
                    parentResponse = thisResponse;
                    break;
                  }
                }
                // ensure the parent acutally had an answer
                if (parentResponse) {
                  // find index of current response
                  const currentResponseIndex = this.instance.responses.findIndex(r => r.questionKey === question.key);
                  if (currentResponseIndex !== -1) {
                    // If index found replace that object with the parent claim's
                    this.instance.responses[currentResponseIndex] = parentResponse;
                  } else {
                    // In theory it shouldn't be possible to get here since we've already added the parent claim response.
                    // added it just incase there is a user input that can acutally cause a response to go undefined somehow.
                    this.instance.responses.push(parentResponse);
                  }
                  this.$emit('update:instance', this.instance);
                } else {
                  // If there is no parent response, we probably do want to be resetting the question
                  this.resetQuestion(question);
                }
              } else {
                this.resetQuestion(question);
              }
            }

            if (question.dependsOn?.onNotActive === 'Hide') {
              // if the component already has validation errors on screen, and then we hide it, the form doesn't recover and still thinks there is validation errors
              // I do not know why
              // So, let's call resetValidation on the component, in the hopes that it is actually resettable
              // actually, nope, that requires implementing ivalidate in every single ot-wf-*. That sounds not fun.
              this.hiddenComponentsDictionary[question.key] = true;
            } else if (question.dependsOn?.onNotActive === 'Disable') {
              this.disabledComponentsDictionary[question.key] = true;
            } else {
              console.warn('ot-workflow-engine-renderer uknown onNotActive for question. Defaulting to hide', {
                questionKey: question.key,
                onNotActive: question.dependsOn?.onNotActive,
              });
              this.hiddenComponentsDictionary[question.key] = true;
            }
          }
        }
      }
      // Actually, I was barking up the wrong tree here. Still might be some use in this code later, but it did not
      // actually fix the root cause of the validation going wonky
      // const currentlyHidden = { ...this.hiddenComponentsDictionary };
      // // check if there is anything newly hidden. If so, reset validation on the form
      // // bit hammer, but it'll work
      // // this is just a simple look through currently hidden. If it was previously hidden, and is now unhidden, we don't care
      // // we only care about it being true in currently hidden and false or not present in previously hidden. That's a new hiding
      // const keysOfCurrentlyHidden = Object.keys(currentlyHidden);
      // const shouldReset = keysOfCurrentlyHidden.some(key => currentlyHidden[key] && !previouslyHidden[key]);
      // if (shouldReset) {
      //   this.resetValidation();
      // }
    }
  }

  private getQuestionRef(questionKey: string): string {
    return `${questionKey}-Ref`;
  }

  // The incoming questions object should look like a regular dictionary with key value pairs
  public setCheckboxWithActionErrors(
    questions: Record<
      string,
      [
        {
          questionKey: string;
          key: string;
          actionKey: string;
        },
        ...{
          questionKey: string;
          key: string;
          actionKey: string;
        }[],
      ]
    >,
  ) {
    for (const questionKey of Object.keys(questions)) {
      (this.$refs[this.getQuestionRef(questionKey)] as WfCheckboxListWithActionEdit[])[0].setOptionErrors(
        questions[questionKey].map(o => o.key),
      );
    }
  }

  private async resetQuestion(question: OtDataDrivenQuestion) {
    // Remove all responses with the question.key
    this.instance.responses = this.instance.responses.filter(r => r.questionKey !== question.key);
    this.$emit('update:instance', this.instance);

    // Check for any dialogs related to any option in this question and reset those
    // if any dialogs have been customised, a single confirmation dialog should appear.
    // That logic is in the checkbox-list-with-action-edit component though
    const layoutKeysToReset = question.options?.filter(o => o.actionKey).map(o => o.actionKey as string) || [];
    for (const layoutKey of layoutKeysToReset) {
      this.resetDialogResponses(layoutKey);
    }
  }

  public async openModal(): Promise<FormModalResult | null> {
    if (this.displayAsDialog) {
      this.dialogIsVisible = true;
      const params = new FormModalParams({
        formRef: this.formContentRef,
        size: FormModalSizeEnum.Large,
        title: this.selectedLayout?.title || undefined,
        confirmText: this.selectedLayout?.dialogButtonOverrides?.okButton?.buttonText || 'Save Options',
        cancelText: this.selectedLayout?.dialogButtonOverrides?.cancelButton?.buttonText || undefined,
        onBeforeConfirmClose: this.onBeforeModalConfirmClose,
        onAfterFormMounted: () => {
          this.formContainerElement = this.formContentRef.$el.parentElement;
        },
      });
      return await this.formModalRef.open(params);
    }
    return null;
  }

  public get onBeforeModalConfirmClose(): (() => Promise<boolean>) | undefined {
    const confirmCommand = this.selectedLayout?.dialogButtonOverrides?.okButton?.command;
    if (confirmCommand) {
      return this.executeOnConfirmDialogCommand;
    }
    return undefined;
  }

  private openConfirmValueChangeDialog(params: IConfirmValueChangeEmitobject) {
    this.$emit('openConfirmValueChangeDialog', params);
  }

  public async executeOnConfirmDialogCommand(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const confirmCommand = this.selectedLayout?.dialogButtonOverrides?.okButton?.command || '';
      const emitObject: IExecuteDialogCommandEmitobject = {
        command: confirmCommand,
        promise: { resolve, reject },
      };
      this.$emit('executeOnConfirmDialogCommand', emitObject);
    });
  }

  private setResponseDictionaryValues() {
    this.responseDictionary = {};
    for (const response of this.instance.responses) {
      this.responseDictionary[response.questionKey] = response;
    }
  }

  private onChange(model: OtDataDrivenResponse) {
    // if a badly behaved component raises an onChange for something system controlled, drop it on the floor
    // absolutely positively NO CHANGES allowed to SystemControlled results
    if (!model.systemControlled) {
      this.emitResponse(model);
      this.processDependsOnComponents(true);
      this.setResponseDictionaryValues();
      // decided that we don't need LIVE responses showing up
      // as in, don't need the answers entered in a layout showing up in labels elsewhere in the same layout
      // this.setupTemplateContextObject();

      // We'll could make an exception for radio selections if we want that live. We won't, but we could
      // if (model.result.resultType === 'OptionsValueModel') {
      //   this.setupTemplateContextObject();
      //   this.runStringTemplates();
      // }
    }
  }

  private emitResponse(model: OtDataDrivenResponse) {
    const foundResponseIndex = this.instance.responses.findIndex(r => r.questionKey === model.questionKey);
    if (foundResponseIndex !== -1) {
      this.instance.responses.splice(foundResponseIndex, 1, model);
    } else {
      this.instance.responses.push(model);
    }
    this.processSyncedQuestion(model);
    this.currentThumbprint = this.getThumbprint(this.instance);
    this.$emit('update:instance', this.instance);
  }

  private processSyncedQuestion(model: OtDataDrivenResponse) {
    // Check if this question should have its answer synced with another question.
    // This only really matters if the user has just ticked the sync ON.
    // Currently we don't know when it was first told to sync so that's why we need to do the check every time.
    // Later on, it could be possible by checking if the question already has an answer and if that answer has client.syncChecked as false
    const client = model.client as ISyncableQuestionClient | undefined;
    if (client?.syncChecked && client.syncingWithQuestion) {
      const syncedQuestionResponseIndex = this.instance.responses.findIndex(
        r => r.questionKey === client.syncingWithQuestion,
      );
      const currentQuestionResponseIndex = this.instance.responses.findIndex(r => r.questionKey === model.questionKey);
      if (syncedQuestionResponseIndex !== -1) {
        const syncedQuestionResponseClone = _.clone(this.instance.responses[syncedQuestionResponseIndex]);
        syncedQuestionResponseClone.questionKey = model.questionKey;
        syncedQuestionResponseClone.client = model.client;

        if (currentQuestionResponseIndex !== -1) {
          this.instance.responses.splice(currentQuestionResponseIndex, 1, syncedQuestionResponseClone);
        } else {
          this.instance.responses.push(syncedQuestionResponseClone);
        }
      }
    }

    // Look for any questions that are synced with this one that was changed and update those synced question responses
    const questionsSyncedWithCurrent = this.definition.layouts
      .flatMap(l => l.sections.flatMap(s => s.questions))
      .filter(q => q.syncFromQuestion?.questionKey === model.questionKey);

    for (const question of questionsSyncedWithCurrent) {
      const responseForQuestionIndex = this.instance.responses.findIndex(r => r.questionKey === question.key);
      // if we have a response already, and that response has a syncChecked property, then that's what isSynced is set to
      // otherwise, it'll be whatever the checkedByDefault is

      const questionClient =
        responseForQuestionIndex !== -1
          ? (this.instance.responses[responseForQuestionIndex].client as ISyncableQuestionClient)
          : undefined;
      const hasSyncProperty = questionClient ? `syncChecked` in questionClient : false;
      const isSynced = hasSyncProperty
        ? questionClient?.syncChecked || false //shut the compiler up - we know it'll have the property, we've checked for the property
        : question.syncFromQuestion?.checkedByDefault || false;

      if (isSynced) {
        // question's response should be the same as our response
        const thisResponseClone = _.clone(model);
        thisResponseClone.questionKey = question.key;
        thisResponseClone.client = questionClient;
        if (responseForQuestionIndex != -1) {
          // splice it
          this.instance.responses.splice(responseForQuestionIndex, 1, thisResponseClone);
        } else {
          // push it
          this.instance.responses.push(thisResponseClone);
        }
      }
    }
  }

  private initializeResponseDictionary() {
    const allQuestions = this.selectedLayout?.sectionsOrdered.flatMap(s => s.questionsOrdered) || [];
    this.responseDictionary = Object.assign({}, ...allQuestions.map(x => ({ [x.key]: null })));
    this.setResponseDictionaryValues();
  }

  private resetDisabledAndHiddenComponents() {
    const allQuestions = this.selectedLayout?.sectionsOrdered.flatMap(s => s.questionsOrdered) || [];
    this.disabledComponentsDictionary = Object.assign({}, ...allQuestions.map(x => ({ [x.key]: false })));
    this.hiddenComponentsDictionary = Object.assign({}, ...allQuestions.map(x => ({ [x.key]: false })));
  }

  private actionButtonClicked(params: {
    layoutKey: string;
    resolve: (value: FormModalResult | PromiseLike<FormModalResult | null> | null) => void;
  }) {
    this.$emit('openActionModal', params);
  }

  private getThumbprint(model: OtDataDrivenInstance): string {
    const questionKeysForLayout =
      this.definition.layouts.find(l => l.key === this.layoutKey)?.sections.flatMap(s => s.questions.map(q => q.key)) ||
      [];
    const responsesForLayout = model.responses
      .filter(r => questionKeysForLayout.includes(r.questionKey))
      .sort((a, b) => a.questionKey.localeCompare(b.questionKey));

    return JSON.stringify(responsesForLayout);
  }

  private resetDialogResponses(layout: string, responsesToResetTo?: OtDataDrivenResponse[]) {
    this.$emit('resetDialogResponses', layout, responsesToResetTo);
  }

  private setDialogDefaults(layout: string) {
    this.$emit('setDialogDefaults', layout);
  }

  public validate() {
    return this.formContentRef.validate();
  }

  public resetValidation() {
    this.formContentRef.resetValidation();
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  public async submit(validate = true): Promise<OtDataDrivenInstance | null> {
    if (!validate || this.validate()) {
      this.setFormToCleanState();
      return this.instance;
    }
    return null;
  }

  private templateContextObject: TemplateContextObject = getBlankTemplateObject();

  private setupTemplateContextObject() {
    const result = getBlankTemplateObject();

    result.Context2 = this.evaluation?.context2 ?? {};

    if (this.definition.evaluation?.configs) {
      for (const config of this.definition.evaluation.configs) {
        result.Config[config.key] = config;
      }
    }
    for (const response of this.instance.responses) {
      result.Response[response.questionKey] = response.result;
    }
    for (const instance of this.instances.instances) {
      for (const response of instance.responses) {
        result.OtherResponses[response.questionKey] = response.result;
      }
    }

    if (this.evaluation?.keys) {
      for (const key of this.evaluation.keys) {
        result.Keys[key] = true;
        result.AllKeys[key] = true;
      }
    }
    if (this.definition.evaluation?.configKeys) {
      for (const key of this.definition.evaluation.configKeys) {
        result.ConfigKeys[key] = true;
        result.AllKeys[key] = true;
      }
    }
    const resultKeys = getSelectedKeysFromResponses(this.instance);
    for (const key of resultKeys) {
      result.ResponseKeys[key] = true;
      result.AllKeys[key] = true;
    }

    this.templateContextObject = result;
  }

  private runStringTemplates() {
    if (!this.selectedLayout || !this.templateContextObject) {
      return;
    }

    for (const section of this.selectedLayout.sections) {
      section.runTemplates(this.templateContextObject);
      for (const question of section.questions) {
        question.runTemplates(this.templateContextObject);
      }
    }
  }

  // * LIFECYCLE
  private created() {
    this.initializeResponseDictionary();
    this.processDependsOnComponents(true);
    this.setupTemplateContextObject();
    this.runStringTemplates();
    this.originalThumbprint = this.getThumbprint(this.instance);
    this.currentThumbprint = this.getThumbprint(this.instance);
  }

  private mounted() {
    this.formContainerElement = this.formContentRef.$el.parentElement;
  }
}
