import Vue from 'vue';

export const AvailableEditModeComponents: string[] = [];
export const AvailableDisplayModeComponents: string[] = [];

export const editModeComponents = require.context(
  // look for files in the current directory
  './edit-mode',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /^.\/wf-.*\.vue$/,
);

export const displayModeComponents = require.context(
  // look for files in the current directory
  './display-mode',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /^.\/wf-.*\.vue$/,
);

function getComponentName(fileName: string) {
  return (
    fileName
      // Remove the "./" from the beginning
      .replace(/^\.\//, '')
      // Remove the file extension from the end
      .replace(/\.\w+$/, '')
      // Split up kebabs
      .split('-')
      // Upper case
      .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      // Concatenated
      .join('')
  );
}

editModeComponents.keys().forEach(fileName => {
  // Get component config
  const componentConfig = editModeComponents(fileName);

  const componentName = getComponentName(fileName);

  AvailableEditModeComponents.push(componentName);

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});

displayModeComponents.keys().forEach(fileName => {
  // Get component config
  const componentConfig = displayModeComponents(fileName);

  const componentName = getComponentName(fileName);

  AvailableDisplayModeComponents.push(componentName);

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});
