






















































































































































































import { ContractDetails } from '@/areas/projects/contracts/contract-models';
import { ProjectDetails } from '@/areas/projects/project-models';
import OtButton from '@/components/global/ot-button.vue';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtResetContractDateForPcDialog from '@/components/specific-modals/ot-reset-contract-date-for-pc-dialog.vue';
import { vxm } from '@/store';
import { CONTRACT_STATUSES_CAN_BE_EDITED, OtAmendedStatus, OtContractStatus, OtStatusType } from '@/types/status-enums';
import { formatDate } from '@/utils/date-utils';
import LibPhoneNumber from 'google-libphonenumber';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtButton,
    OtTag,
    OtExpansionPanel,
    OtResetContractDateForPcDialog,
  },
})
export default class OtContractDetails extends Vue {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop() private projectDetails!: ProjectDetails;

  // * REFS
  @Ref('resetContractDateForPcDialogRef')
  private readonly resetContractDateForPcDialogRef!: OtResetContractDateForPcDialog;

  // * DATA
  private phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();

  // * COMPUTED

  private get isEditDisabled() {
    return !CONTRACT_STATUSES_CAN_BE_EDITED.has(this.contractDetails.status);
  }

  private get countryCode() {
    // This is only hard coded for MVP
    return 'AU';
  }

  // * WATCHERS

  // * METHODS
  private formatBudget(val: number) {
    return val.toLocaleString();
  }

  private formatDate(val: Date) {
    return formatDate(val);
  }

  private formatPhoneNumber(val: string): string {
    if (this.phoneUtil.isPossibleNumberString(val, this.countryCode)) {
      const phoneNumber = this.phoneUtil.parse(val, this.countryCode);
      return this.phoneUtil.format(phoneNumber, LibPhoneNumber.PhoneNumberFormat.NATIONAL);
    }
    return val;
  }

  private getTagContractStatus(status: OtContractStatus): TagStatus {
    return { type: OtStatusType.Contract, status };
  }

  private getTagAmendedStatus(isAmended: boolean): TagStatus {
    return { type: OtStatusType.Generic, status: isAmended ? OtAmendedStatus.Amended : OtAmendedStatus.Unamended };
  }

  private async resetDateForPc() {
    const dialogResult = await this.resetContractDateForPcDialogRef.open({
      contractGid: this.contractDetails.gid,
      contractReference: this.contractDetails.reference,
      contractName: this.contractDetails.name,
      dateOfContract: this.contractDetails.dateOfContract,
      mostRecentlyAgreedDateForPracticalCompletion: this.contractDetails.mostRecentlyAgreedDateForPracticalCompletion,
      // Projected completion date WILL NOT be null here
      // Yes, typescript thinks it can be, but that's because we foolishly reuse this object for creating a new contract as well
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      projectedCompletionDate: this.contractDetails.projectedCompletionDate!,
      revisedCompletionDate: this.contractDetails.revisedCompletionDate,
      adjustedProjectCompletionDate: this.projectDetails.adjustedProjectCompletionDate,
      contractRowVersion: this.contractDetails.rowVersion,
    });

    if (dialogResult.didUpdateDate) {
      // we can throw away the version that comes back from the update, we'll reload and get a whole new contract
      this.$emit('reloadContractDetails');
      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Success,
          message: 'Successfully updated date for practical completion',
          displayTime: 5000,
        }),
      );
    }
  }

  // * LIFECYCLE
}
