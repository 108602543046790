




















































import { Component, Vue } from 'vue-property-decorator';
import OtButton from '@/components/global/ot-button.vue';

@Component({
  components: {
    OtButton,
  },
})
export default class OtButtonDropdownWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private items = [{ title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me 2' }];

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
