
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContractUser } from './contract-user-models';
import { ContractDetails } from './contract-models';

import OtContractUsersTable from './ot-contract-users-table.vue';

@Component({
  components: {
    OtContractUsersTable,
  },
})
export default class OtContractUsers extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private contractUsers!: ContractUser[];
  @Prop() private contractDetails!: ContractDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
