




















import {
  OtSegmentStatus,
  OtContractStatus,
  OtOrganisationStatus,
  OtProjectStatus,
  OtStatusType,
  OtUserStatus,
} from '@/types/status-enums';
import { OtCalendarStatus } from '@/areas/settings/models/calendar-models';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtTag,
  },
})
export default class OtTagWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private projectStatuses = Object.values(OtProjectStatus);
  private contractStatuses = Object.values(OtContractStatus);
  private segmentStatuses = Object.values(OtSegmentStatus);
  private userStatuses = Object.values(OtUserStatus);
  private calendarStatuses = Object.values(OtCalendarStatus);
  private organisationStatuses = Object.values(OtOrganisationStatus);

  private statusTypes = [
    this.projectStatuses,
    this.contractStatuses,
    this.segmentStatuses,
    this.userStatuses,
    this.calendarStatuses,
    this.organisationStatuses,
  ];

  private statusLabels = [
    OtStatusType.Project,
    OtStatusType.Contract,
    OtStatusType.Segment,
    OtStatusType.User,
    OtStatusType.Calendar,
    OtStatusType.Organisation,
  ];

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private getTagStatus(type: OtStatusType, status: never): TagStatus {
    return { type, status };
  }

  // * LIFECYCLE
}
