









































import { IValidate } from '@/utils/type-utils';
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import OtButton from '@/components/global/ot-button.vue';

@Component({
  components: {
    OtButton,
  },
})
export default class OtCheckbox extends Vue {
  // * PROPS
  @Prop({ type: String }) private label!: string;
  @Prop({ type: Boolean, default: false }) private value!: boolean;
  @Prop({ type: Boolean, default: false }) private box!: boolean;
  @Prop({ type: Boolean, default: false }) private hasError!: boolean;
  @Prop({ type: String }) private errorMessage!: string;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ default: 'true' }) private hideDetails!: string;
  @Prop({ type: String }) private actionButtonText?: string;
  @Prop({ type: String }) private subText?: string;
  @Prop() private rules?: Array<(value: boolean) => boolean | string>;

  // * REFS
  @Ref('checkboxRef') public readonly checkboxRef!: IValidate;

  // * DATA

  // * COMPUTED
  private get isCheckedValue() {
    return this.value;
  }

  private set isCheckedValue(value: boolean) {
    this.$emit('input', value);
  }

  private errorStatePrivate = false;
  private get errorState() {
    return this.errorStatePrivate || !!this.errorMessage;
  }
  // ! ----- Important message if you have trouble with checkbox validation -----
  // When errorState is true, this function won't get called.
  // That's because vuetify will only emit update:error if the state has changed
  // but when we manually set the error prop to true, it will never change unless
  // we manually set it to false.
  // So we need to make sure to manually set the errorState to false any time the validation
  // gets checked.
  // Use the resetManualErrorState public method when the validation gets checked
  private set errorState(val: boolean) {
    if (!this.errorMessage) {
      this.errorStatePrivate = val;
      this.$emit('update:hasError', val);
    }
  }

  // * WATCHERS
  @Watch('hasError')
  private hasErrorChanged(val: boolean) {
    this.errorStatePrivate = val;
  }

  // * METHODS
  public resetManualErrorState() {
    this.errorStatePrivate = false;
    this.checkboxRef.validate();
  }

  private checkboxClicked(val: PointerEvent) {
    // We focus the checkbox if the label is clicked to apply the correct styling
    if ((val.target as HTMLElement).localName === 'label') {
      ((val.target as HTMLLabelElement).control as HTMLInputElement).focus();
    }
  }

  private actionButtonClicked() {
    this.$emit('actionClicked');
  }

  // * LIFECYCLE
  private mounted() {
    this.errorStatePrivate = this.hasError;
  }
}
