import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';
import OtContractDetails from '@/areas/projects/contracts/ot-contract-details.vue';
import OtEditContractDetails from '@/areas/projects/contracts/ot-edit-contract-details.vue';
import OtContractDashboard from '@/areas/projects/contracts/ot-contract-dashboard.vue';
import { BreadcrumbsId } from '@/components/global/common-models';
import OtContractsLayout from '@/areas/projects/contracts/ot-contracts-layout.vue';
import OtCreateContractWizard from '@/areas/projects/contracts/ot-create-contract-wizard.vue';
import OtContractWorkflows from '@/areas/projects/contracts/ot-contract-workflows.vue';
import OtContractUsers from '@/areas/projects/contracts/ot-contract-users-index.vue';
import OtContractUserLayout from '@/areas/projects/contracts/ot-contract-user-layout.vue';
import OtContractUserDetails from '@/areas/projects/contracts/ot-contract-user-details.vue';
import OtEditContractUser from '@/areas/projects/contracts/ot-edit-contract-user.vue';

export const ROUTE_CONTRACT_DASHBOARD = 'ContractDashboard';
export const ROUTE_CONTRACT_WORKFLOW_LIST = ROUTE_CONTRACT_DASHBOARD; // I always struggle to remember the dashboard is the list
/** Use this one if you just need to link to The Contract, but don't know where */
export const ROUTE_CONTRACT_DEFAULT = ROUTE_CONTRACT_DASHBOARD;
export const ROUTE_CONTRACT_ADD = 'AddContract';
export const ROUTE_CONTRACT_DETAILS = 'ContractDetails';
export const ROUTE_CONTRACT_DETAILS_EDIT = 'EditContractDetails';
export const ROUTE_CONTRACT_WORKFLOWTYPES = 'ContractWorkflowTypes';
export const ROUTE_CONTRACT_USERS = 'ContractUsers';
export const ROUTE_CONTRACT_DOCUMENTS = 'ContractDocuments';
export const ROUTE_CONTRACT_USER_LAYOUT = 'ContractUserLayout';
export const ROUTE_CONTRACT_USER_DETAILS = 'ContractUserDetails';
export const ROUTE_CONTRACT_USER_EDIT = 'EditContractUser';

export const contractRoutes: Array<RouteConfig> = [
  {
    path: '/projects/:projectGid/contracts/add',
    name: ROUTE_CONTRACT_ADD,
    component: OtCreateContractWizard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects/:projectGid/contracts/:contractGid',
    component: OtContractsLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: ROUTE_CONTRACT_DASHBOARD,
        component: OtContractDashboard,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}`,
        },
      },
      {
        path: 'details',
        name: ROUTE_CONTRACT_DETAILS,
        component: OtContractDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Details`,
        },
      },
      {
        path: 'details/edit',
        name: ROUTE_CONTRACT_DETAILS_EDIT,
        component: OtEditContractDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Details/Edit`,
        },
      },
      {
        path: 'workflows',
        redirect: './',
      },
      {
        path: 'workflow-types',
        name: ROUTE_CONTRACT_WORKFLOWTYPES,
        component: OtContractWorkflows,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Workflow Types`,
        },
      },
      {
        path: 'users',
        name: ROUTE_CONTRACT_USERS,
        component: OtContractUsers,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Users`,
        },
      },
      {
        path: 'documents',
        name: ROUTE_CONTRACT_DOCUMENTS,
        component: OtComingSoon,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Documents`,
        },
      },
    ],
  },
  {
    path: '/projects/:projectGid/contracts/:contractGid/Users/:userGid',
    name: ROUTE_CONTRACT_USER_LAYOUT,
    component: OtContractUserLayout,
    meta: {
      requiresAuth: true,
      breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Users`,
    },

    children: [
      {
        path: '/',
        name: ROUTE_CONTRACT_USER_DETAILS,
        component: OtContractUserDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Users/${BreadcrumbsId.UserId}`,
        },
      },
    ],
  },
  {
    path: '/projects/:projectGid/contracts/:contractGid/Users/:userGid/edit',
    name: ROUTE_CONTRACT_USER_EDIT,
    component: OtEditContractUser,
    meta: {
      breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/Contracts/${BreadcrumbsId.ContractId}/Users/${BreadcrumbsId.UserId}/Edit`,
    },
  },
];
