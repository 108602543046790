export function isOnlyNumbers(text: string): boolean {
  return /^[0-9]+$/.test(text);
}

export function isEmail(text: string): boolean {
  // Regex pattern taken from: http://emailregex.com/
  // It follows the RFC 5322 Official Standard
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    text,
  );
}

export const dirtyFormClass = '--dirty-form';

export function dirtyFormsExist(): boolean {
  const dirtyForms = document.querySelectorAll(`.${dirtyFormClass}`);
  return dirtyForms.length > 0;
}
