import { TrafficLightsModel } from '@/services/generated/api';
import { Location } from 'vue-router';

export interface IInputIcon {
  icon: string;
  iconColor: string;
  action: (() => void) | null;
}

export interface IInputBreadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}

export enum BreadcrumbsId {
  ProjectId = 'projectId',
  UserId = 'userId',
  ContractId = 'contractId',
  DocumentId = 'documentId',
  CalendarId = 'calendarId',
  WorkflowId = 'workflowId',
  ClaimId = 'claimId',
  OrganisationId = 'organisationId',
}

export interface INavigation {
  location: Location;
  title: string;
  icon?: string;
  isDisabled?: boolean;
}
export interface IInputTab {
  tabId: number;
  tabText: string;
  tabRoute: Location;
}
export interface IInputAction {
  actionName: string;
  action: Location | (() => void);
}

export interface ITrafficLights {
  green: number;
  yellow: number;
  red: number;
}

export class TrafficLights implements ITrafficLights {
  public green!: number;
  public yellow!: number;
  public red!: number;

  public constructor(value: ITrafficLights) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new TrafficLights({
      green: 0,
      yellow: 0,
      red: 0,
    });
  }

  public static createFromApiResponse(model: TrafficLightsModel) {
    return new TrafficLights({
      green: model.green || 0,
      yellow: model.yellow || 0,
      red: model.red || 0,
    });
  }

  public static defaultSorting(a: TrafficLights, b: TrafficLights, ascending: boolean): number {
    const sortModifier = ascending ? 1 : -1;
    const red = (a.red - b.red) * sortModifier;
    const yellow = (a.yellow - b.yellow) * sortModifier;
    const green = (a.green - b.green) * sortModifier;
    // Sort by red first, If they're the same sort by yellow, if they're also the same, sort by green
    return red || yellow || green;
  }
}
export interface IAddressDisplayModel {
  placeId: string | null;
  addressLine1: string;
  addressLine2: string | null;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
}
