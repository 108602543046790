














































import OtButton from '@/components/global/ot-button.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import { ContractStatus, PostUpdateContractStatusRequestModel } from '@/services/generated/api';
import { vxm } from '@/store';
import { CONTRACT_STATUSES_CAN_BE_EDITED, OtContractStatus, OtProjectStatus } from '@/types/status-enums';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';
import { ROUTE_PROJECT_DEFAULT } from '../projects-routes';
import { ContractDetails, ContractDetailsPost, parsedOtContractEnums } from './contract-models';
import OtContractDetailsForm from './ot-contract-details-form.vue';

@Component({
  components: {
    OtButton,
    OtContractDetailsForm,
  },
})
export default class OtEditContractDetails extends Vue {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop() private projectDetails!: ProjectDetails;

  // * REFS
  @Ref('contractDetailsForm') private contractDetailsForm!: OtContractDetailsForm;

  // * DATA
  private api = new OtApi();
  private saving = false;
  private lastContractRowVersionFromUpdates: string | null = null;

  // * COMPUTED

  private get contractRowVersion() {
    return this.lastContractRowVersionFromUpdates ?? this.contractDetails.rowVersion;
  }
  private get canSetActive() {
    return (
      this.contractDetails.status === OtContractStatus.Pending && this.projectDetails.status === OtProjectStatus.Active
    );
  }

  // * WATCHERS

  // * METHODS
  private async updateContract() {
    await this.postUpdate();
  }

  private async updateContractAndSetActive() {
    await this.postUpdate(ContractStatus.Active);
  }

  private async postUpdate(setStatus?: ContractStatus) {
    this.saving = true;

    const contract = await this.contractDetailsForm.submit();

    if (contract && contract instanceof ContractDetailsPost) {
      const requestModel = ContractDetailsPost.createUpdateRequestModel(contract);
      const errorOverride: IApiErrorOverride[] = [
        {
          status: 409,
          message: `Unable to update contract details - contract has been modified by someone else. Please refresh the page and try again.`,
        },
      ];

      const apiUpdateResponse = await executeApi(
        () => this.api.contracts().postUpdateContractDetails(contract.gid, undefined, requestModel),
        `Update Contract Details (${contract.gid})`,
        errorOverride,
      );

      if (apiUpdateResponse.success) {
        this.lastContractRowVersionFromUpdates = apiUpdateResponse.data?.rowVersion ?? null;

        if (setStatus) {
          const errorOverride: IApiErrorOverride[] = [
            {
              status: 409,
              message: `Unable to change the status of contract to Active - contract has been modified by someone else. Please refresh the page and try again.`,
            },
          ];

          const apiStatusResponse = await executeApi(
            () =>
              this.api.contracts().postUpdateContractStatus(
                contract.gid,
                new PostUpdateContractStatusRequestModel({
                  contractStatus: ContractStatus.Active,
                  rowVersion: this.contractRowVersion,
                }),
              ),
            `Set Contract ${contract.gid} Status to ${parsedOtContractEnums[ContractStatus.Active]}`,
            errorOverride,
          );

          if (apiStatusResponse.success) {
            this.lastContractRowVersionFromUpdates = apiStatusResponse.data?.rowVersion ?? null;
            this.navigateToContractDetails(this.projectDetails.gid, contract.gid);
          }
        } else {
          this.navigateToContractDetails(this.projectDetails.gid, contract.gid);
        }
      } else {
        this.contractDetailsForm.handleApiFailure(apiUpdateResponse.errors);
      }
    } else {
      this.showValidationSnackbarError();
    }

    this.saving = false;
  }

  private showValidationSnackbarError() {
    this.createSnackbarError(
      'There is a validation error on the form that prevented us from saving the contract, please check the form for errors',
    );
  }

  private createSnackbarError(message: string) {
    const snackbarError = new SnackbarItem({
      type: SnackbarTypeEnum.Error,
      message: message,
    });
    vxm.snackbar.addToSnackbarQueue(snackbarError);
  }

  private navigateToContractDetails(projectGid: string, contractGid: string) {
    this.$emit('reloadContractDetails');
    this.$router.push({
      name: 'ContractDetails',
      params: { projectGid: projectGid, contractGid: contractGid },
    });
  }

  // * LIFECYCLE

  private async created() {
    if (!CONTRACT_STATUSES_CAN_BE_EDITED.has(this.contractDetails.status)) {
      vxm.snackbar.addToSnackbarQueue(
        new SnackbarItem({
          type: SnackbarTypeEnum.Error,
          message: 'Contract cannot be edited',
        }),
      );
      this.$router.push({
        name: ROUTE_PROJECT_DEFAULT,
        params: { projectGid: this.contractDetails.projectGid },
      });
    }
  }
}
