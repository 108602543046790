
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrganisationUserContract } from '../models/organisation-user-contract-models';
import OtOrganisationUserContractsTable from './ot-organisation-user-contracts-table.vue';

@Component({
  components: {
    OtOrganisationUserContractsTable,
  },
})
export default class OtOrganisationUserContractsIndex extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private organisationUserContracts!: OrganisationUserContract[];

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
