













































































































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

import { OtUserStatus } from '@/types/status-enums';
import { RawLocation } from 'vue-router/types/router';
import { AccessType } from '../../../models/shared-models';

import { ROUTE_CONTRACT_DETAILS } from '@/areas/projects/contracts/contract-routes';
import OtUserStatusTags from '@/components/global/ot-user-status-tags.vue';
import { parsedApiOrgUserStatusEnums } from '@/models/user-models';
import { PostSetContractUserStatusUserStatusModel } from '@/services/generated/api';
import { OrganisationUserContract } from '../models/organisation-user-contract-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';
import { OrganisationUserDetails } from '../models/organisation-user-models';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (contract: OrganisationUserContract) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtUserStatusTags,
    OtInviteUserDialog,
  },
})
export default class OtOrganisationUserContractsTable extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private organisationUserContracts!: OrganisationUserContract[];
  @Prop() private organisationUser!: OrganisationUserDetails;

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  private tableColumns: IExtendedColumnData<OrganisationUserContract>[] = [
    {
      index: 0,
      label: 'Reference',
      key: 'reference',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: OrganisationUserContract.compareByReference,
    },
    {
      index: 1,
      label: 'Contracts',
      key: 'name',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUserContract.compareByName,
    },
    {
      index: 2,
      label: 'Contract Role',
      key: 'title',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: OrganisationUserContract.compareByContractRole,
    },
    {
      index: 3,
      label: 'Org.',
      key: 'org',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 4,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 5,
      label: 'Contracts',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 6,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private activeColumn = this.tableColumns[0];

  private openUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Inactive', OtUserStatus.Inactive),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
    new CheckboxGroupItem('Rejected Invite', OtUserStatus.RejectedInvitation),
  ];

  private selectedUserStatusFilters: CheckboxGroupItem<OtUserStatus>[] = [
    new CheckboxGroupItem('Active', OtUserStatus.Active),
    new CheckboxGroupItem('Invited', OtUserStatus.Invited),
  ];

  private typeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [
    new CheckboxGroupItem('Projects', AccessType.Projects),
    new CheckboxGroupItem('Contracts', AccessType.Contracts),
    new CheckboxGroupItem('Organisation', AccessType.Organisations),
  ];

  private selectedTypeOfAccessFilters: CheckboxGroupItem<AccessType>[] = [];

  // * COMPUTED

  private get sortedBodyData() {
    const sortedOrgUsers = sortTableData(this.tableColumns, this.activeColumn, this.organisationUserContracts);

    return sortedOrgUsers;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedUserStatusFilters.map(s => s.value);
    const parsedAccessTypes = this.selectedTypeOfAccessFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        OrganisationUserContract.filterByUserStatus(parsedStatuses, row) &&
        OrganisationUserContract.filterByTypeOfAccess(parsedAccessTypes, row) &&
        OrganisationUserContract.filterByName(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS

  // * METHODS

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private getOrgUserContractDetailsRoute(contract: OrganisationUserContract): RawLocation {
    return {
      name: ROUTE_CONTRACT_DETAILS,
      params: { projectGid: contract.projectGid, contractGid: contract.gid },
    };
  }

  private handleDeactivateForContract = (contract: OrganisationUserContract): IMenuOption => {
    return {
      key: contract.gid,
      label: 'Deactivate for Contract',
      onClick: () => {
        this.setOrgUserToDeactivateForContract(contract);
      },
    };
  };

  private handleReinviteUser = (contract: OrganisationUserContract): IMenuOption => {
    return {
      key: contract.gid,
      label: 'Reinvite User',
      onClick: () => {
        this.reInviteUser(contract);
      },
    };
  };

  private async setOrgUserToDeactivateForContract(contract: OrganisationUserContract) {
    this.rowLoadingStates[contract.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      // NOTE which id are be to give to the warning ? should it be a n array of contract ids ?
      message: `Unable to deactivate user ${contract.organisationUser?.gid} for contract, ${ExecuteApiValue.ValidationError}`,
    };
    const body: PostSetContractUserStatusUserStatusModel[] = [
      new PostSetContractUserStatusUserStatusModel({
        gid: contract.contractUser?.gid || '',
        status: parsedApiOrgUserStatusEnums[OtUserStatus.Inactive],
      }),
    ];

    if (body.length === 0) {
      this.rowLoadingStates[contract.gid] = false;
      return;
    }
    const postResponse = await executeApi(
      () => this.api.contractUser().postSetContractUserStatus(body),
      `Deactivating user in contract`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadOrganisationUserContracts', () => {
        this.rowLoadingStates[contract.gid] = false;
      });
    } else {
      this.rowLoadingStates[contract.gid] = false;
    }
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async reInviteUser(contract: OrganisationUserContract) {
    this.rowLoadingStates[contract.gid] = true;

    const currentUserOrganisationUser = contract.currentUserOrganisationUser;
    const currentUserProjectUser = contract.currentUserProjectUser;

    const isCurrentUserOrganisationUserActive = currentUserOrganisationUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectUserActive = currentUserProjectUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectOrOrganisationUserActive =
      isCurrentUserOrganisationUserActive || isCurrentUserProjectUserActive;

    const isOrgUserReinviteable =
      contract.organisationUser?.status === OtUserStatus.Inactive ||
      contract.organisationUser?.status === OtUserStatus.RejectedInvitation;
    const isProjectUserReinviteable =
      contract.projectUser?.status === OtUserStatus.Inactive ||
      contract.projectUser?.status === OtUserStatus.RejectedInvitation;
    const isContractUserReinviteable =
      contract.contractUser?.status === OtUserStatus.Inactive ||
      contract.contractUser?.status === OtUserStatus.RejectedInvitation;

    const reInviteToOrg =
      isCurrentUserOrganisationUserActive && isOrgUserReinviteable ? contract.organisationUser : null;
    const reInviteToProjects =
      // in order to check if it is reinvitable, it has to be non null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isCurrentUserOrganisationUserActive && isProjectUserReinviteable ? [contract.projectUser!] : [];
    const reinviteToContracts =
      // in order to check if it is reinvitable, it has to be non null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isCurrentUserProjectOrOrganisationUserActive && isContractUserReinviteable ? [contract.contractUser!] : [];

    const params = new InviteUserDialogParams({
      isReinvite: true,
      organisationGid: contract.organisationGid,
      inviteToOrganisation: Boolean(reInviteToOrg),
      invitedUserGid: this.organisationUser.gid, // this is the user gid, not the org user gid
      userContracts: reinviteToContracts.map(x => x.gid),
      userProjects: reInviteToProjects.map(x => x.gid),
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      // Do nothing at this stage as per spec. But may need a snack bar later on
    }

    this.rowLoadingStates[contract.gid] = false;
  }

  private getOrgUserContractOptionMenuItems(contract: OrganisationUserContract): IMenuOption[] {
    const results: IMenuOption[] = [];

    const currentUserOrganisationUser = contract.currentUserOrganisationUser;
    const currentUserProjectUser = contract.currentUserProjectUser;
    const contractUser = contract.contractUser;

    const isCurrentUserOrganisationUserActive = currentUserOrganisationUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectUserActive = currentUserProjectUser?.status === OtUserStatus.Active;
    const isCurrentUserProjectOrOrganisationUserActive =
      isCurrentUserOrganisationUserActive || isCurrentUserProjectUserActive;

    const isContractUserDeactivateable =
      contractUser?.status === OtUserStatus.Active || contractUser?.status === OtUserStatus.Invited;

    const isProjectUserReinviteable =
      contractUser?.status === OtUserStatus.Inactive || contractUser?.status === OtUserStatus.RejectedInvitation;
    const isContractUserReinviteable =
      contractUser?.status === OtUserStatus.Inactive || contractUser?.status === OtUserStatus.RejectedInvitation;

    const isOrgUserReinviteable =
      contract.organisationUser?.status === OtUserStatus.Inactive ||
      contract.organisationUser?.status === OtUserStatus.RejectedInvitation;

    const canDeactivateForContract = isCurrentUserProjectOrOrganisationUserActive && isContractUserDeactivateable;

    if (canDeactivateForContract) {
      results.push(this.handleDeactivateForContract(contract));
    }

    const canReinvite =
      (isCurrentUserOrganisationUserActive && isOrgUserReinviteable) ||
      (isCurrentUserOrganisationUserActive && isProjectUserReinviteable) ||
      (isCurrentUserProjectOrOrganisationUserActive && isContractUserReinviteable);

    if (canReinvite) {
      results.push(this.handleReinviteUser(contract));
    }

    return results;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.organisationUserContracts.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  // * LIFECYCLE
  private created() {
    this.setRowLoadingStates();
  }
}
