import { DocumentReceivalType as ApiDocumentReceivalType } from '@/services/generated/api';

export enum OtDocumentReceivalTypeEnum {
  AusPost = 'AUSPOST',
  Courier = 'COURIER',
  Email = 'EMAIL',
  Aconex = 'ACONEX',
  Fax = 'FAX',
  Other = 'OTHER',
}

export class OtDocumentReceivalType {
  public name!: string;
  public value!: OtDocumentReceivalTypeEnum;

  public constructor(value: OtDocumentReceivalTypeEnum, name: string) {
    this.value = value;
    this.name = name;
  }

  public static readonly AusPost = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.AusPost, 'AusPost');
  public static readonly Courier = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.Courier, 'Courier');
  public static readonly Email = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.Email, 'Email');
  public static readonly Aconex = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.Aconex, 'Aconex');
  public static readonly Fax = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.Fax, 'Fax');
  public static readonly Other = new OtDocumentReceivalType(OtDocumentReceivalTypeEnum.Other, 'Other');
}

export const documentReceivalTypes: OtDocumentReceivalType[] = [
  OtDocumentReceivalType.AusPost,
  OtDocumentReceivalType.Courier,
  OtDocumentReceivalType.Email,
  OtDocumentReceivalType.Aconex,
  OtDocumentReceivalType.Fax,
  OtDocumentReceivalType.Other,
];

export const documentReceivalTypesMap: Map<string, OtDocumentReceivalType> = new Map(
  documentReceivalTypes.map(x => [x.value, x]),
);

export const parsedOtDocumentReceivalType: { [key in ApiDocumentReceivalType]: OtDocumentReceivalType } = {
  [ApiDocumentReceivalType.AusPost]: OtDocumentReceivalType.AusPost,
  [ApiDocumentReceivalType.Courier]: OtDocumentReceivalType.Courier,
  [ApiDocumentReceivalType.Email]: OtDocumentReceivalType.Email,
  [ApiDocumentReceivalType.Aconex]: OtDocumentReceivalType.Aconex,
  [ApiDocumentReceivalType.Fax]: OtDocumentReceivalType.Fax,
  [ApiDocumentReceivalType.Other]: OtDocumentReceivalType.Other,
};

export const parsedApiDocumentReceivalType: { [key in OtDocumentReceivalTypeEnum]: ApiDocumentReceivalType } = {
  [OtDocumentReceivalTypeEnum.AusPost]: ApiDocumentReceivalType.AusPost,
  [OtDocumentReceivalTypeEnum.Courier]: ApiDocumentReceivalType.Courier,
  [OtDocumentReceivalTypeEnum.Email]: ApiDocumentReceivalType.Email,
  [OtDocumentReceivalTypeEnum.Aconex]: ApiDocumentReceivalType.Aconex,
  [OtDocumentReceivalTypeEnum.Fax]: ApiDocumentReceivalType.Fax,
  [OtDocumentReceivalTypeEnum.Other]: ApiDocumentReceivalType.Other,
};
