
































import { OtSegmentResolution } from '@/types/status-enums';
import { SegmentProcessTypeEnum } from '@/wf-components/models/data-driven-enums';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClaimResultsObject, SegmentInstanceDetails } from '../claims-models';
import { sanitizer } from '@/utils/xss-utils';

@Component({})
export default class OtClaimRecommendation extends Vue {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private resultDetails!: ClaimResultsObject;

  // * REFS

  // * DATA
  private resultStateTitle = '';
  private resultStateIcon = '';
  private resultStateClass = '';

  // * COMPUTED
  private get workflowTypeName() {
    return this.claimDetails.workflowSegment.name;
  }

  // * WATCHERS

  // * METHODS
  private sanitizeHtml(text: string) {
    return sanitizer.process(text);
  }

  private setImplementationOptions() {
    console.log('ot-claim-recommendation -> setImplementationOptions -> selections  ', {
      resultDetails: this.resultDetails,
    });

    if (this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.RFIOutbound) {
      this.resultStateTitle = `Outbound Request for Information (ORFI)`;
      this.resultStateIcon = `$blueCheck`;
      this.resultStateClass = `--primary`;
    } else if (this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.RFIInbound) {
      if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Rejected) {
        // show the orange "there will be no response", which is the formal response only
        // RFI Inbound only has Accept and suspend, that's it
        // You can't choose to override an rfi inbound response, you can only send or suspend
        this.resultStateTitle = `There will not be a response to the RFI`;
        this.resultStateIcon = `$coloredCross`;
        this.resultStateClass = `--invalid`;
      } else {
        // RFI Inbound only has Accept and suspend, that's it
        // You can't choose to override an rfi inbound response, you can only send or suspend
        this.resultStateTitle = `There will be a response to the RFI`;
        this.resultStateIcon = `$coloredCheck`;
        this.resultStateClass = `--valid`;
      }
    } else if (
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.Variation ||
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.VariationProposal ||
      this.claimDetails.workflowSegment.segmentProcessType === SegmentProcessTypeEnum.VariationRequest
    ) {
      // variations are all blue
      // feels like a proposed variation should get some red/green, but we're not going to approve/reject. I don't think?
      // well, we won't recommend rejection. We might reject some things as part of an action. Which means they should show up red when looking at the finished claim
      // Figma does not have this
      this.resultStateTitle = `Result: ${this.workflowTypeName}`;
      this.resultStateIcon = `$blueCheck`;
      this.resultStateClass = `--primary`;
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Accepted) {
      if (this.resultDetails.selectedOutcome === OtSegmentResolution.Rejected) {
        this.resultStateTitle = `${this.workflowTypeName} has been <u>overruled</u> to be Invalid`;
        this.resultStateIcon = `$coloredCross`;
        this.resultStateClass = `--overruled-invalid`;
      } else if (this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime) {
        this.resultStateTitle = `${this.workflowTypeName} has been <u>overruled</u> to <u>keep valid with different outcome</u>`;
        this.resultStateIcon = `$coloredCheck`;
        this.resultStateClass = `--overruled-valid`;
      } else {
        this.resultStateTitle = `${this.workflowTypeName} is Valid`;
        this.resultStateIcon = `$coloredCheck`;
        this.resultStateClass = `--valid`;
      }
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Rejected) {
      if (
        this.resultDetails.selectedOutcome === OtSegmentResolution.Accepted ||
        this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime
      ) {
        this.resultStateTitle = `${this.workflowTypeName} has been <u>overruled</u> to be Valid`;
        this.resultStateIcon = `$coloredCheck`;
        this.resultStateClass = `--overruled-valid`;
      } else {
        this.resultStateTitle = `${this.workflowTypeName} is Invalid`;
        this.resultStateIcon = `$coloredCross`;
        this.resultStateClass = `--invalid`;
      }
    } else if (this.resultDetails.recommendedOutcome === OtSegmentResolution.Indeterminate) {
      if (
        this.resultDetails.selectedOutcome === OtSegmentResolution.Accepted ||
        this.resultDetails.selectedOutcome === OtSegmentResolution.ValidWithDifferentTime
      ) {
        this.resultStateTitle = `Incomplete Workflow, manually marked as Valid`;
        this.resultStateIcon = `$coloredCheck`;
        this.resultStateClass = `--indeterminate-valid`;
      } else if (this.resultDetails.selectedOutcome === OtSegmentResolution.Rejected) {
        this.resultStateTitle = `Incomplete Workflow, manually marked as Invalid`;
        this.resultStateIcon = `$coloredCross`;
        this.resultStateClass = `--indeterminate-invalid`;
      } else {
        this.resultStateTitle = `Incomplete Workflow, select outcome below`;
        this.resultStateIcon = `$monochromeCross`;
        this.resultStateClass = `--indeterminate`;
      }
    } else {
      console.error(
        'ot-claim-recommendation -> setImplementationOptions -> recommendedOutcome has an unkonwn value  ',
        this.resultDetails.recommendedOutcome,
      );
    }
  }

  // * LIFECYCLE
  private created() {
    this.setImplementationOptions();
  }
}
