


















































































































































































import { Calendar } from '@/areas/settings/models/calendar-models';
import { FormModalParams, FormModalSizeEnum } from '@/components/global/modal/form-modal-models';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtCurrencyField from '@/components/global/ot-currency-field.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { ResponseError } from '@/services/api-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { OtContractStatus } from '@/types/status-enums';
import { IWizardContentComponent } from '@/types/wizard-types';
import { ZonelessDate } from '@/types/zoneless-date';
import { EMPTY_HELPER_TEXT_STRING } from '@/utils/constants';
import { formatDateAndTime } from '@/utils/date-utils';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass, isEmail } from '@/utils/validation-utils';
import { getTimeZones } from '@vvo/tzdb';
import { v4 as uuid } from 'uuid';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';
import {
  ContractCalendar,
  ContractDetails,
  ContractDetailsPost,
  ContractType,
  ContractTypeDetails,
} from './contract-models';

class ContractDetailsDisplayModel {
  public name: string | null = null;
  public reference: string | null = null;
  public typeGid: string | null = null;
  public contractor: string | null = null;
  public description: string | null = null;
  public contactName: string | null = null;
  public contactEmail: string | null = null;
  public contactPhone: string | null = null;
  public timezone: string | null = null;
  public budget: number | null = null;
  public dateOfContract: ZonelessDate | null = null;
  public tendersClosedDate: ZonelessDate | null = null;
  public completionDate: ZonelessDate | null = null;
  public mostRecentlyAgreedDateForPracticalCompletion: ZonelessDate | null = null;
  public workingCalendarGid: string | null = null;
  public businessCalendarGid: string | null = null;
  public originalWorkingCalendarGid: string | null = null;
  public originalBusinessCalendarGid: string | null = null;
  public originalRowVersion = '';

  public toContractDetailsPost(gid: string) {
    const result = new ContractDetailsPost({
      gid: gid,
      name: this.name || '',
      reference: this.reference || '',
      typeGid: this.typeGid || '',
      contractor: this.contractor || undefined,
      description: this.description || undefined,
      contactName: this.contactName || undefined,
      contactEmail: this.contactEmail || undefined,
      contactPhone: this.contactPhone || undefined,
      timezone: this.timezone || '',
      budget: this.budget || undefined,
      dateOfContract: new ZonelessDate(this.dateOfContract) || new ZonelessDate(),
      tendersClosedDate: this.tendersClosedDate ? new ZonelessDate(this.tendersClosedDate) : undefined,
      // uh, why are we posting revised? Shouldn't we be posting projected?
      revisedCompletionDate: new ZonelessDate(this.completionDate) || new ZonelessDate(),
      workingCalendarGid: this.workingCalendarGid || undefined,
      businessCalendarGid: this.businessCalendarGid || undefined,
      originalWorkingCalendarGid: this.originalWorkingCalendarGid || undefined,
      originalBusinessCalendarGid: this.originalBusinessCalendarGid || undefined,
      originalRowVersion: this.originalRowVersion,
    });
    console.log('ot-contract-details-form -> toContractDetailsPost -> result:  ', result);
    return result;
  }
}

@Component({
  components: {
    OtTextField,
    OtTextarea,
    OtAutocomplete,
    OtDatePicker,
    OtPhoneNumberField,
    OtCurrencyField,
  },
})
export default class OtContractDetailsForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop() private projectDetails!: ProjectDetails;
  @Prop({ default: false }) private isSaving!: boolean;
  @Prop({ default: false }) private isRequestMode!: boolean;

  // * REFS
  @Ref('contractDetailsForm') private readonly contractDetailsForm!: IVForm;
  @Ref('contractDetailsFormWrapper') private readonly contractDetailsFormWrapper!: HTMLDivElement;

  // * REFS
  @Ref('adjustedDateModalRef') private adjustedDateModalRef!: IVForm;
  @Ref('adjustedDateModalContentsRef') private adjustedDateModalContentsRef!: HTMLDivElement;

  // * DATA
  private api = new OtApi();
  private localContractDetails = new ContractDetailsDisplayModel();
  private originalContractThumbprint = '';
  private currentContractThumbprint = '';
  private allTimezones = getTimeZones()
    .filter(t => t.countryCode === 'AU')
    .flatMap(t => t.group)
    .sort((a, b) => a.localeCompare(b));
  private apiReferenceErrors: string[] = [];
  private organisationCalendars: Calendar[] = [];
  private contractTypes: ContractTypeDetails[] = [];
  private isLoading = false;
  private referenceRules: Array<(val: string | null) => string | boolean> = [
    (val: string | null) => (!!val && val.length <= 200) || 'Reference can’t be more than 200 characters long',
  ];
  private emailRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => !value || isEmail(value) || `Invalid Email`,
  ];
  private get tendersClosedErrorMessages() {
    if (
      this.localContractDetails.tendersClosedDate &&
      this.localContractDetails.dateOfContract &&
      !(this.localContractDetails.tendersClosedDate <= this.localContractDetails.dateOfContract)
    ) {
      return 'Must be <= Contract Date';
    }
    return undefined;
  }
  private get completionDateMessages() {
    if (
      this.localContractDetails.completionDate &&
      this.localContractDetails.dateOfContract &&
      !(this.localContractDetails.completionDate >= this.localContractDetails.dateOfContract)
    ) {
      return 'Must be >= Contract Date';
    }
    return undefined;
  }

  private workingDayCalendarItems: IAutocompleteItem<string>[] = [];
  private businessCalendarItems: IAutocompleteItem<string>[] = [];

  // * COMPUTED

  private get emptyHelperTextString(): string {
    return EMPTY_HELPER_TEXT_STRING;
  }

  private get newAdjustedProjectCompletionDate(): ZonelessDate | null {
    if (this.localContractDetails?.completionDate && this.projectDetails?.adjustedProjectCompletionDate) {
      return this.localContractDetails?.completionDate > this.projectDetails?.adjustedProjectCompletionDate
        ? new ZonelessDate(this.localContractDetails.completionDate)
        : this.projectDetails.adjustedProjectCompletionDate;
    } else {
      return null;
    }
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get contractIsDirty(): boolean {
    return this.originalContractThumbprint !== this.currentContractThumbprint;
  }

  private get referenceValue(): string | null {
    return this.localContractDetails.reference;
  }
  private set referenceValue(val: string | null) {
    this.localContractDetails.reference = val;
    this.apiReferenceErrors = [];
  }

  private get contractTypeItems(): IAutocompleteItem<string>[] {
    return this.contractTypes.map(t => {
      return { label: t.name + (t.isGlobal ? ' (SYSTEM)' : ''), data: t.gid };
    });
  }

  private get selectedContractType(): IAutocompleteItem<string> | null {
    const localTypeGid = this.localContractDetails.typeGid;
    if (localTypeGid) {
      const foundContract = this.contractTypeItems.find(c => c.data?.toLowerCase() === localTypeGid.toLowerCase());
      if (foundContract) {
        return { label: foundContract.label, data: localTypeGid };
      }
    }
    return null;
  }
  private set selectedContractType(val: IAutocompleteItem<string> | null) {
    if (val?.data) {
      this.localContractDetails.typeGid = val.data;
    } else {
      this.localContractDetails.typeGid = null;
    }
  }

  private get selectedTimezone(): IAutocompleteItem<string> | null {
    const timezone = this.localContractDetails.timezone;
    if (timezone) {
      return { label: timezone, data: timezone };
    }
    return null;
  }
  private set selectedTimezone(val: IAutocompleteItem<string> | null) {
    if (val?.data) {
      this.localContractDetails.timezone = val.data;
    } else {
      this.localContractDetails.timezone = null;
    }
  }

  private get selectedWorkingCalendar(): IAutocompleteItem<string> | null {
    const localWorkingCalendarGid = this.localContractDetails.workingCalendarGid;
    if (localWorkingCalendarGid) {
      const foundCalendar = this.workingDayCalendarItems.find(
        c => c.data?.toLowerCase() === localWorkingCalendarGid.toLowerCase(),
      );
      if (foundCalendar) {
        return { label: foundCalendar.label, data: localWorkingCalendarGid };
      }
    }
    return null;
  }
  private set selectedWorkingCalendar(val: IAutocompleteItem<string> | null) {
    if (val?.data) {
      this.localContractDetails.workingCalendarGid = val.data;
    } else {
      this.localContractDetails.workingCalendarGid = null;
    }
  }

  private get selectedBusinessCalendar(): IAutocompleteItem<string> | null {
    const localBusinessCalendarGid = this.localContractDetails.businessCalendarGid?.toLowerCase();
    if (localBusinessCalendarGid) {
      const foundCalendar = this.businessCalendarItems.find(c => c.data?.toLowerCase() === localBusinessCalendarGid);
      if (foundCalendar) {
        return { label: foundCalendar.label, data: localBusinessCalendarGid };
      }
    }
    return null;
  }
  private set selectedBusinessCalendar(val: IAutocompleteItem<string> | null) {
    if (val?.data) {
      this.localContractDetails.businessCalendarGid = val.data;
    } else {
      this.localContractDetails.businessCalendarGid = null;
    }
  }

  private get isContractDraftOrPending() {
    return (
      this.contractDetails.status === OtContractStatus.Draft || this.contractDetails.status === OtContractStatus.Pending
    );
  }
  private get isContractDraft() {
    return this.contractDetails.status === OtContractStatus.Draft;
  }

  private get allTimezoneItems(): IAutocompleteItem<string>[] {
    const timezoneNames: IAutocompleteItem<string>[] = this.allTimezones.map(t => {
      return { label: t, data: t };
    });
    return timezoneNames;
  }

  // * WATCHERS

  // * METHODS
  private formatDateAndTime(date: ZonelessDate) {
    return formatDateAndTime(date, { excludeTime: true });
  }

  private getContractThumbprint(contract: ContractDetailsDisplayModel): string {
    const vals = {
      name: contract.name || '',
      reference: contract.reference || '',
      description: contract.description || '',
      contractTypeGid: contract.typeGid || '',
      dateOfContract: contract.dateOfContract?.toString() || '',
      contractor: contract.contractor || '',
      contactName: contract.contactName || '',
      contactPhone: contract.contactPhone || '',
      contactEmail: contract.contactEmail || '',
      contractTimezone: contract.timezone || '',
      budget: contract.budget?.toString() || '',
      completionDate: contract.completionDate?.toString() || '',
      workingCalendarGid: contract.workingCalendarGid || '',
      businessCalendarGid: contract.businessCalendarGid || '',
    };
    return JSON.stringify(vals);
  }

  public validate() {
    return this.contractDetailsForm.validate();
  }
  public reset() {
    return this.contractDetailsForm.reset();
  }

  public setFormToCleanState() {
    this.originalContractThumbprint = this.currentContractThumbprint;
  }

  public async submit(validate = true): Promise<ContractDetailsPost | ContractDetails | null> {
    if (!validate || this.validate()) {
      // we are intercepting the process to run a check on the completion date of the contract as it may run past the current project end date
      //, we compare localContractDetails.completionDate to projectDetails.adjustedProjectCompletionDate
      const completionDate = this.localContractDetails?.completionDate;
      const adjustedProjectCompletionDate = this.projectDetails?.adjustedProjectCompletionDate;
      if (completionDate && adjustedProjectCompletionDate) {
        if (completionDate > adjustedProjectCompletionDate) {
          const formModalParams = new FormModalParams({
            title: 'Contract Completion Date',
            formRef: this.adjustedDateModalRef,
            cancelText: 'Cancel',
            confirmText: 'Save and Continue',
            size: FormModalSizeEnum.Large,
          });
          const result = await vxm.modal.openFormModal(formModalParams);
          if (!result.ok) {
            return null;
          }
        }
      }

      if (this.contractDetails.gid) {
        this.setFormToCleanState();
        const contractGid = this.contractDetails.gid || uuid();
        const result = this.localContractDetails.toContractDetailsPost(contractGid);
        console.log('ot-contract-details-form -> submit -> we have a gid, result is:  ', result);
        return result;
      } else {
        this.setFormToCleanState();
        const result = new ContractDetails({
          projectGid: this.contractDetails.projectGid,
          organisationGid: this.contractDetails.organisationGid,
          gid: this.contractDetails.gid || '',
          name: this.localContractDetails.name || this.contractDetails.name,
          reference: this.localContractDetails.reference || this.contractDetails.reference,
          type: this.localContractDetails.typeGid
            ? new ContractType({ gid: this.localContractDetails.typeGid, name: '' })
            : this.contractDetails.type,
          contractor: this.localContractDetails.contractor || this.contractDetails.contractor,
          description: this.localContractDetails.description,
          contactName: this.localContractDetails.contactName,
          contactEmail: this.localContractDetails.contactEmail,
          contactPhone: this.localContractDetails.contactPhone,
          status: OtContractStatus.Draft,
          timezone: this.localContractDetails.timezone || this.contractDetails.timezone,
          budget: this.localContractDetails.budget,
          dateOfContract: this.localContractDetails.dateOfContract,
          tendersClosedDate: this.localContractDetails.tendersClosedDate,
          projectedCompletionDate:
            this.localContractDetails.completionDate ||
            this.contractDetails.projectedCompletionDate ||
            new ZonelessDate(), // completion Date is basically mandatory, so the rest of this is for completeness as far as I can tell
          revisedCompletionDate: this.localContractDetails.completionDate || this.contractDetails.revisedCompletionDate,
          mostRecentlyAgreedDateForPracticalCompletion:
            this.localContractDetails.mostRecentlyAgreedDateForPracticalCompletion ||
            this.contractDetails.mostRecentlyAgreedDateForPracticalCompletion,
          workdayCalendar: this.localContractDetails.workingCalendarGid
            ? new ContractCalendar({
                gid: this.localContractDetails.workingCalendarGid,
                organisationCalendarGid: '',
                name: '',
              })
            : this.contractDetails.workdayCalendar,
          businessCalendar: this.localContractDetails.businessCalendarGid
            ? new ContractCalendar({
                gid: this.localContractDetails.businessCalendarGid,
                organisationCalendarGid: '',
                name: '',
              })
            : this.contractDetails.businessCalendar,
          workflows: [],
          // at this point I don't remember what localBlah vs non local is
          rowVersion: this.localContractDetails.originalRowVersion || this.contractDetails.rowVersion,
        });
        console.log('ot-contract-details-form -> submit -> we have no gid, result is:  ', result);
        return result;
      }
    }
    return null;
  }

  public handleApiFailure(errors: ResponseError[]) {
    this.apiReferenceErrors = [];

    for (const error of errors) {
      switch (error.source) {
        case 'Reference':
          this.apiReferenceErrors.push(error.message);
          break;
      }
    }
  }

  private async getCalendars() {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationCalendars(this.projectDetails.organisationGid),
      'Load Calendars',
    );
    if (result.success && result.data && result.data.calendars) {
      this.organisationCalendars = result.data.calendars.map(c => Calendar.createFromApiResponse(c));
    }
  }

  private async getContractTypes() {
    const result = await executeApi(
      () => this.api.contractTypes().getContractTypes(this.projectDetails.organisationGid),
      'Load Contract Types',
    );
    if (result.success && result.data && result.data.contractTypes) {
      this.contractTypes = result.data.contractTypes.map(t => ContractTypeDetails.createFromApiResponse(t));
    }
  }

  // * LIFECYCLE
  private async created() {
    this.isLoading = true;

    await Promise.all([this.getCalendars(), this.getContractTypes()]);

    this.businessCalendarItems = this.organisationCalendars.map(c => {
      return { label: c.name, data: c.gid };
    });
    this.workingDayCalendarItems = this.organisationCalendars.map(c => {
      return { label: c.name, data: c.gid };
    });

    if (this.contractDetails.businessCalendar.gid) {
      this.businessCalendarItems.unshift({
        label: this.contractDetails.businessCalendar.name,
        data: this.contractDetails.businessCalendar.gid,
      });
    }

    if (this.contractDetails.workdayCalendar.gid) {
      this.workingDayCalendarItems.unshift({
        label: this.contractDetails.workdayCalendar.name,
        data: this.contractDetails.workdayCalendar.gid,
      });
    }

    if (this.contractDetails) {
      this.localContractDetails.name = this.contractDetails.name;
      this.localContractDetails.reference = this.contractDetails.reference;
      this.localContractDetails.typeGid = this.contractDetails.type.gid;
      this.localContractDetails.contractor = this.contractDetails.contractor;
      this.localContractDetails.description = this.contractDetails.description;
      this.localContractDetails.contactName = this.contractDetails.contactName;
      this.localContractDetails.contactEmail = this.contractDetails.contactEmail;
      this.localContractDetails.contactPhone = this.contractDetails.contactPhone;
      this.localContractDetails.timezone = this.contractDetails.timezone
        ? this.contractDetails.timezone
        : this.projectDetails.timezone;
      this.localContractDetails.budget = this.contractDetails.budget;
      this.localContractDetails.dateOfContract = this.contractDetails.dateOfContract;
      this.localContractDetails.tendersClosedDate = this.contractDetails.tendersClosedDate;
      this.localContractDetails.completionDate = this.contractDetails.projectedCompletionDate;
      this.localContractDetails.workingCalendarGid = this.contractDetails.workdayCalendar.gid;
      this.localContractDetails.businessCalendarGid = this.contractDetails.businessCalendar.gid;
      this.localContractDetails.originalWorkingCalendarGid = this.contractDetails.workdayCalendar.gid;
      this.localContractDetails.originalBusinessCalendarGid = this.contractDetails.businessCalendar.gid;
      this.localContractDetails.originalRowVersion = this.contractDetails.rowVersion;
      console.log('ot-contract-details-form -> created -> we turned contract details into local contract details', {
        contractDetails: this.contractDetails,
        localContractDetails: this.localContractDetails,
      });
    }

    this.originalContractThumbprint = this.getContractThumbprint(this.localContractDetails);
    this.currentContractThumbprint = this.getContractThumbprint(this.localContractDetails);
    this.isLoading = false;
  }
}
