import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';

export const dashboardRoutes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: '/projects', // This is temporary until we have an actual dashboard
    component: OtComingSoon,
    meta: {
      requiresAuth: true,
    },
  },
];
