
















































import Styles from '@/styles/variables.scss';
import { Component, Vue } from 'vue-property-decorator';
import { DangerModalParams, DangerModalResult } from '@/components/global/modal/form-modal-models';

@Component({})
export default class OtDangerModal extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private resolve!: (value: DangerModalResult | PromiseLike<DangerModalResult>) => void;
  private reject!: () => void;
  private showModalLocal = false;
  private openParams: DangerModalParams | null = null;

  private confirmButtonColor = Styles.otColorRed300;
  private cancelButtonColor = Styles.otColorBlue500;

  // * COMPUTED
  private get effectiveConfirmText(): string {
    return this.openParams?.confirmText ? this.openParams?.confirmText : 'Confirm';
  }
  private get effectiveCancelText(): string {
    return this.openParams?.cancelText ? this.openParams?.cancelText : 'Cancel';
  }
  private get effectiveHideConfirmButton(): boolean {
    return this.openParams?.hideConfirmButton ? this.openParams?.hideConfirmButton : false;
  }
  private get effectiveHideCancelButton(): boolean {
    return this.openParams?.hideCancelButton ? this.openParams?.hideCancelButton : false;
  }
  private get effectiveTitle(): string {
    return this.openParams?.title ? this.openParams.title : 'Title';
  }
  private get effectiveTitleIcon(): string | null {
    return this.openParams?.titleIcon ? this.openParams.titleIcon : null;
  }
  private get effectiveMessage(): string {
    return this.openParams?.message ? this.openParams.message : 'Message';
  }
  private get effectiveUseHtmlInMessage(): boolean {
    return this.openParams?.useHtmlInMessage ? this.openParams.useHtmlInMessage : false;
  }

  // * WATCHERS

  // * METHODS
  public open(params?: DangerModalParams) {
    this.$emit('open');
    this.openParams = params || null;

    this.showModalLocal = true;
    return new Promise<DangerModalResult>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public close(result: DangerModalResult) {
    this.$emit('close', result);
    this.resolve(result);
    this.showModalLocal = false;
  }

  // this is when the user clicks the OK button in the dialog
  // all we want to do here is indicate that the user clicked ok
  // and resolve the promise which was started when the coder
  // called open() on us. This'll get the result of ok:true back to them
  // and their code that has been awaiting this can continue on
  private async ok() {
    const result = new DangerModalResult({ ok: true });
    this.close(result);
  }

  private cancel() {
    const result = new DangerModalResult({ ok: false });
    this.close(result);
  }

  private clickedOutside() {
    this.cancel();
  }

  // * LIFECYCLE
}
