// Data Driven Result Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2358607974/Data+Driven+Result+Object

import {
  DataDrivenDateValueModel as ApiDataDrivenDateValueModel,
  DataDrivenDaySelectionModel as ApiDataDrivenDaySelectionModel,
  DataDrivenDaysOrDateModel as ApiDataDrivenDaysOrDateModel,
  DataDrivenDocumentDetailsModel as ApiDataDrivenDocumentDetailsModel,
  DataDrivenModifyingFactorModel as ApiDataDrivenModifyingFactorModel,
  DataDrivenOptionValueModel as ApiDataDrivenOptionValueModel,
  DataDrivenOptionValueWithOtherModel as ApiDataDrivenOptionValueWithOtherModel,
  DataDrivenOptionsListModel as ApiDataDrivenOptionsListModel,
  DataDrivenOptionsListWithOtherModel as ApiDataDrivenOptionsListWithOtherModel,
  DataDrivenOverridableDateValueModel as ApiDataDrivenOverridableDateValueModel,
  DataDrivenOverridableDurationModel as ApiDataDrivenOverridableDurationModel,
  DataDrivenOverridableKeyValueModel as ApiDataDrivenOverridableKeyValueModel,
  DataDrivenResultBaseModel as ApiDataDrivenResultBaseModel,
  DataDrivenTextValueModel as ApiDataDrivenTextValueModel,
  DocumentReceivalType,
} from '@/services/generated/api';
import {
  OtDocumentReceivalTypeEnum,
  parsedApiDocumentReceivalType,
  parsedOtDocumentReceivalType,
} from '@/types/document-receival-type';
import { ZonelessDate } from '@/types/zoneless-date';
import {
  CalendarTypeEnum,
  DurationAdjustmentEnum,
  parsedApiCalendarTypeEnum,
  parsedApiDurationAdjustmentEnum,
  parsedOtCalendarTypeEnum,
  parsedOtDurationAdjustmentEnum,
} from './data-driven-enums';
import { OtDataDrivenValue } from './data-driven-value';

// These values should match the ones in the generated api code
export type OtDataDrivenResultType =
  | 'TextValueModel'
  | 'OptionsValueModel'
  | 'OptionsValueWithOtherModel'
  | 'OptionsListWithOtherModel'
  | 'OptionsListModel'
  | 'DaySelectionModel'
  | 'DateValueModel'
  | 'DaysOrDateModel'
  | 'DocumentDetailsModel'
  | 'OverridableKeyValueModel'
  | 'OverridableDateValueModel'
  | 'OverridableDurationModel'
  | 'ModifyingFactorModel';

export type OtDataDrivenResultBaseType = {
  resultType: OtDataDrivenResultType;
};

export interface IDataDrivenModelWithKey extends OtDataDrivenResultBaseType {
  key: string;
}

export interface IDataDrivenModelWithValues extends OtDataDrivenResultBaseType {
  values: OtDataDrivenValue[];
}

// OT Text Value
// FC11001 - OT WF Text Field
// FC11002 - OT WF Text Area
// FC11023 - OT WF Numerical Entry Field
export interface IDataDrivenTextValue extends OtDataDrivenResultBaseType {
  resultType: 'TextValueModel';
  text: string; // User entered text value
}

export class OtDataDrivenTextValue implements IDataDrivenTextValue {
  public resultType!: 'TextValueModel';
  public text!: string;

  constructor(value: IDataDrivenTextValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenTextValueModel) {
    return new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: model.text,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenTextValue>) {
    return new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: model.text || '',
    });
  }

  public static createEmpty() {
    return new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: '',
    });
  }

  public static createRequestModel(model: OtDataDrivenTextValue) {
    return new ApiDataDrivenTextValueModel({
      text: model.text,
    });
  }

  public createRequestModel() {
    return OtDataDrivenTextValue.createRequestModel(this);
  }
}

// OT Option Value
// FC11009 - OT WF Parallel Radio Buttons
// FC11014 - OT WF Radio Button List
// FC11010 - OT WF Filled Parallel Radio
// FC11021 - OT WF Dropdown Select
export interface IDataDrivenOptionValue extends IDataDrivenModelWithKey {
  resultType: 'OptionsValueModel';
  key: string; // Selected option where question has single valued response
}

export class OtDataDrivenOptionValue implements IDataDrivenOptionValue {
  public resultType!: 'OptionsValueModel';
  public key!: string;

  constructor(value: IDataDrivenOptionValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOptionValueModel) {
    return new OtDataDrivenOptionValue({
      resultType: 'OptionsValueModel',
      key: model.key,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOptionValue>) {
    return new OtDataDrivenOptionValue({
      resultType: 'OptionsValueModel',
      key: model.key || '',
    });
  }

  public static createRequestModel(model: OtDataDrivenOptionValue) {
    return new ApiDataDrivenOptionValueModel({
      key: model.key,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOptionValue.createRequestModel(this);
  }
}

// OT Option Value with Other
// FC11022 - OT WF Radio Button List with Other Option
export interface IDataDrivenOptionValueWithOther extends IDataDrivenModelWithKey {
  resultType: 'OptionsValueWithOtherModel';
  key: string; // Selected option where question has single valued response
  text?: string | null; // User entered text value
}

export class OtDataDrivenOptionValueWithOther implements IDataDrivenOptionValueWithOther {
  public resultType!: 'OptionsValueWithOtherModel';
  public key!: string;
  public text?: string | null;

  constructor(value: IDataDrivenOptionValueWithOther) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOptionValueWithOtherModel) {
    return new OtDataDrivenOptionValueWithOther({
      resultType: 'OptionsValueWithOtherModel',
      key: model.key,
      text: model.title,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOptionValueWithOther>) {
    return new OtDataDrivenOptionValueWithOther({
      resultType: 'OptionsValueWithOtherModel',
      key: model.key || '',
      text: model.text || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenOptionValueWithOther) {
    return new ApiDataDrivenOptionValueWithOtherModel({
      key: model.key,
      title: model.text || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOptionValueWithOther.createRequestModel(this);
  }
}

export interface IDataDrivenOptionsListWithOther extends IDataDrivenModelWithValues {
  resultType: 'OptionsListWithOtherModel';
  otherText: string | null;
}

export class OtDataDrivenOptionsListWithOther implements IDataDrivenOptionsListWithOther {
  public resultType!: 'OptionsListWithOtherModel';
  public values!: OtDataDrivenValue[];
  public otherText!: string | null;

  constructor(value: IDataDrivenOptionsListWithOther) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOptionsListWithOtherModel) {
    return new OtDataDrivenOptionsListWithOther({
      resultType: 'OptionsListWithOtherModel',
      values: model.values.map(v => OtDataDrivenValue.createFromApiResponse(v)),
      otherText: model.otherText ?? null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOptionsListWithOther>) {
    return new OtDataDrivenOptionsListWithOther({
      resultType: 'OptionsListWithOtherModel',
      values: model.values?.map(v => OtDataDrivenValue.createFromJson(v)) || [],
      otherText: model.otherText ?? null,
    });
  }

  public static createRequestModel(model: OtDataDrivenOptionsListWithOther) {
    return new ApiDataDrivenOptionsListWithOtherModel({
      values: model.values.map(v => v.createRequestModel()),
      otherText: model.otherText ?? undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOptionsListWithOther.createRequestModel(this);
  }
}

// OT Options List
// FC11011 - OT Checkbox List With Action
// FC11012 - OT WF Outlined Checkbox List
// FC11013 - OT WF Checkbox List
// FC11011 - OT Checkbox List With Action
// FC11017 - OT WF Dynamic List - Text Field, Textarea and Filled Radio Buttons
// FC11018 - OT WF Dynamic List - Textarea and Filled Radio Buttons
export interface IDataDrivenOptionsList extends IDataDrivenModelWithValues {
  resultType: 'OptionsListModel';
  values: OtDataDrivenValue[];
}

export class OtDataDrivenOptionsList implements IDataDrivenOptionsList {
  public resultType!: 'OptionsListModel';
  public values!: OtDataDrivenValue[];

  constructor(value: IDataDrivenOptionsList) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOptionsListModel) {
    return new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: model.values.map(v => OtDataDrivenValue.createFromApiResponse(v)),
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOptionsList>) {
    return new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: model.values?.map(v => OtDataDrivenValue.createFromJson(v)) || [],
    });
  }

  public static createRequestModel(model: OtDataDrivenOptionsList) {
    return new ApiDataDrivenOptionsListModel({
      values: model.values.map(v => v.createRequestModel()),
    });
  }

  public createRequestModel() {
    return OtDataDrivenOptionsList.createRequestModel(this);
  }
}

// OT Day Selection
// FC11015 - OT WF Day Selection
export interface IDataDrivenDaySelection extends OtDataDrivenResultBaseType {
  resultType: 'DaySelectionModel';
  number: number | null; // Numerical value result
  calendarType: CalendarTypeEnum | null; // Reference to relevant calendar
}

export class OtDataDrivenDaySelection implements IDataDrivenDaySelection {
  public resultType!: 'DaySelectionModel';
  public number!: number | null;
  public calendarType!: CalendarTypeEnum | null;

  constructor(value: IDataDrivenDaySelection) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenDaySelectionModel) {
    return new OtDataDrivenDaySelection({
      resultType: 'DaySelectionModel',
      number: model.number,
      calendarType: parsedOtCalendarTypeEnum[model.calendarType],
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenDaySelection>) {
    return new OtDataDrivenDaySelection({
      resultType: 'DaySelectionModel',
      number: model.number || null,
      calendarType: model.calendarType || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenDaySelection) {
    return new ApiDataDrivenDaySelectionModel({
      number: model.number || 0,
      calendarType: parsedApiCalendarTypeEnum[model.calendarType || CalendarTypeEnum.Working],
    });
  }

  public createRequestModel() {
    return OtDataDrivenDaySelection.createRequestModel(this);
  }
}

// Ot Date Value
// VC11044 - OT WF Date Selector
export interface IDataDrivenDateValue extends OtDataDrivenResultBaseType {
  resultType: 'DateValueModel';
  date: ZonelessDate | null;
}

export class OtDataDrivenDateValue implements IDataDrivenDateValue {
  public resultType!: 'DateValueModel';
  public date!: ZonelessDate | null;

  constructor(value: IDataDrivenDateValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenDateValueModel) {
    return new OtDataDrivenDateValue({
      resultType: 'DateValueModel',
      date: ZonelessDate.fromZonelessDateFormat(model.date),
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenDateValue>) {
    return new OtDataDrivenDateValue({
      resultType: 'DateValueModel',
      date: model.date || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenDateValue) {
    return new ApiDataDrivenDateValueModel({
      date: model.date ? ZonelessDate.toZonelessDateFormat(model.date) : '',
    });
  }

  public createRequestModel() {
    return OtDataDrivenDateValue.createRequestModel(this);
  }
}

// Ot Days Or Date Value
// FC12057 - OT WF Days Or Date
export interface IDataDrivenDaysOrDate extends OtDataDrivenResultBaseType {
  resultType: 'DaysOrDateModel';
  date: ZonelessDate | null;
  number: number | null; // Numerical value result
  calendarType: CalendarTypeEnum | null; // Reference to relevant calendar
}

export class OtDataDrivenDaysOrDate implements IDataDrivenDaysOrDate {
  public resultType!: 'DaysOrDateModel';
  public date!: ZonelessDate | null;
  public number!: number | null;
  public calendarType!: CalendarTypeEnum | null;

  constructor(value: IDataDrivenDaysOrDate) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenDaysOrDateModel) {
    return new OtDataDrivenDaysOrDate({
      resultType: 'DaysOrDateModel',
      date: ZonelessDate.fromZonelessDateFormat(model.date),
      number: model.number !== undefined ? model.number : null,
      calendarType: model.calendarType ? parsedOtCalendarTypeEnum[model.calendarType] : null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenDaysOrDate>) {
    return new OtDataDrivenDaysOrDate({
      resultType: 'DaysOrDateModel',
      date: model.date || null,
      number: model.number !== undefined ? model.number : null,
      calendarType: model.calendarType || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenDaysOrDate) {
    return new ApiDataDrivenDaysOrDateModel({
      date: model.date ? ZonelessDate.toZonelessDateFormat(model.date) : undefined,
      number: model.number !== null ? model.number : undefined,
      calendarType: model.calendarType ? parsedApiCalendarTypeEnum[model.calendarType] : undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenDaysOrDate.createRequestModel(this);
  }
}

// OT Document Details Model
// FC12056 - OT WF Document Details
export interface IDataDrivenDocumentDetails extends OtDataDrivenResultBaseType {
  resultType: 'DocumentDetailsModel';
  documentName: string | null;
  documentReceivalType: OtDocumentReceivalTypeEnum | null;
  documentReceivalDescription: string | null;
  documentCreationDate: ZonelessDate | null;
  documentReceivedUtc: Date | null;
  documentDeemedReceivedUtc: Date | null;
}

export class OtDataDrivenDocumentDetails implements IDataDrivenDocumentDetails {
  public resultType!: 'DocumentDetailsModel';
  public documentName!: string | null;
  public documentReceivalType!: OtDocumentReceivalTypeEnum | null;
  public documentReceivalDescription!: string | null;
  public documentCreationDate!: ZonelessDate | null;
  public documentReceivedUtc!: Date | null;
  public documentDeemedReceivedUtc!: Date | null;

  constructor(value: IDataDrivenDocumentDetails) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenDocumentDetailsModel) {
    return new OtDataDrivenDocumentDetails({
      resultType: 'DocumentDetailsModel',
      documentName: model.documentName,
      documentReceivalType: parsedOtDocumentReceivalType[model.documentReceivalType].value,
      documentReceivalDescription: model.documentReceivalDescription || null,
      documentCreationDate: new ZonelessDate(model.documentCreationDate),
      documentReceivedUtc: model.documentReceivedUtc || null,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenDocumentDetails>) {
    return new OtDataDrivenDocumentDetails({
      resultType: 'DocumentDetailsModel',
      documentName: model.documentName || null,
      documentReceivalType: model.documentReceivalType || null,
      documentReceivalDescription: model.documentReceivalDescription || null,
      documentCreationDate: model.documentCreationDate ? new ZonelessDate(model.documentCreationDate) : null,
      documentReceivedUtc: model.documentReceivedUtc || null,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenDocumentDetails) {
    return new ApiDataDrivenDocumentDetailsModel({
      documentName: model.documentName || '',
      documentReceivalType: model.documentReceivalType
        ? parsedApiDocumentReceivalType[model.documentReceivalType]
        : DocumentReceivalType.Email,
      documentReceivalDescription: model.documentReceivalDescription || undefined,
      // by the time we get to calling this, document creation date will be filled in
      // the dummy value is just to please the compiler
      // it will also be easy to find in the code should someone report "it said the date of the document was 1976"
      documentCreationDate: model.documentCreationDate
        ? model.documentCreationDate.toZonelessDateFormat()
        : '1976-06-16',
      documentReceivedUtc: model.documentReceivedUtc || undefined,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenDocumentDetails.createRequestModel(this);
  }
}

// OT Overridable Key Value
// FC11003 - OT Overridable Select
export interface IDataDrivenOverridableKeyValue extends IDataDrivenModelWithKey {
  resultType: 'OverridableKeyValueModel';
  adminOverride: boolean; // Override selected
  contractorValue?: string | null; // Contractor supplied value (date, option value)
  adminValue?: string | null; // Administrator supplied value (date, option value)
  key: string; // Effective value of the contractor and admin values
  text?: string | null; // Optional text associated with response
}

export class OtDataDrivenOverridableKeyValue implements IDataDrivenOverridableKeyValue {
  public resultType!: 'OverridableKeyValueModel';
  public adminOverride!: boolean;
  public contractorValue?: string | null;
  public adminValue?: string | null;
  public key!: string;
  public text?: string | null;

  constructor(value: IDataDrivenOverridableKeyValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOverridableKeyValueModel) {
    return new OtDataDrivenOverridableKeyValue({
      resultType: 'OverridableKeyValueModel',
      adminOverride: model.adminOverride,
      contractorValue: model.contractorValue,
      adminValue: model.adminValue,
      key: model.key,
      text: model.text,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOverridableKeyValue>) {
    return new OtDataDrivenOverridableKeyValue({
      resultType: 'OverridableKeyValueModel',
      adminOverride: model.adminOverride || false,
      contractorValue: model.contractorValue || null,
      adminValue: model.adminValue || null,
      key: model.key || '',
      text: model.text || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenOverridableKeyValue) {
    return new ApiDataDrivenOverridableKeyValueModel({
      adminOverride: model.adminOverride,
      contractorValue: model.contractorValue || undefined,
      adminValue: model.adminValue || undefined,
      key: model.key,
      text: model.text || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOverridableKeyValue.createRequestModel(this);
  }
}

export enum OverridableDateOptionKeyEnum {
  NotStated = 'NotStated',
  DateSubmittedByContractor = 'DateSubmittedByContractor',
  DateSubmittedByAdministrator = 'DateSubmittedByAdministrator',
}

export enum OverridableDurationOptionKeyEnum {
  NotStated = 'NotStated',
  DurationSubmittedByContractor = 'DurationSubmittedByContractor',
  DurationSubmittedByAdministrator = 'DurationSubmittedByAdministrator',
}

// OT Overridable Date Value
// FC11005 - OT Overridable Optional Date
export interface IDataDrivenOverridableDateValue extends OtDataDrivenResultBaseType {
  resultType: 'OverridableDateValueModel';
  adminOverride: boolean; // Override selected
  contractorValue?: string | null; // Contractor supplied value (date, option value)
  adminValue?: string | null; // Administrator supplied value (date, option value)
  date?: ZonelessDate | null; // Effective value of the contractor and admin values
  text?: string | null; // Optional text associated with response
  selectedContractorOptionKey: string | null; // This is never given to the API. It's just used for the syncQuestion functionality
  selectedAdminOptionKey: string | null; // This is never given to the API. It's just used for the syncQuestion functionality
}

export class OtDataDrivenOverridableDateValue implements IDataDrivenOverridableDateValue {
  public resultType!: 'OverridableDateValueModel';
  public adminOverride!: boolean;
  public contractorValue?: string | null;
  public adminValue?: string | null;
  public date?: ZonelessDate | null;
  public text?: string | null;
  public selectedContractorOptionKey!: string | null;
  public selectedAdminOptionKey!: string | null;

  constructor(value: IDataDrivenOverridableDateValue) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOverridableDateValueModel) {
    return new OtDataDrivenOverridableDateValue({
      resultType: 'OverridableDateValueModel',
      adminOverride: model.adminOverride,
      contractorValue: model.contractorValue,
      adminValue: model.adminValue,
      date: ZonelessDate.fromZonelessDateFormat(model.date),
      text: model.text,
      selectedContractorOptionKey: model.contractorValue
        ? OverridableDateOptionKeyEnum.DateSubmittedByContractor
        : OverridableDateOptionKeyEnum.NotStated,
      selectedAdminOptionKey: model.adminValue
        ? OverridableDateOptionKeyEnum.DateSubmittedByAdministrator
        : OverridableDateOptionKeyEnum.NotStated,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOverridableDateValue>) {
    return new OtDataDrivenOverridableDateValue({
      resultType: 'OverridableDateValueModel',
      adminOverride: model.adminOverride || false,
      contractorValue: model.contractorValue || null,
      adminValue: model.adminValue || null,
      date: model.date || null,
      text: model.text || null,
      selectedContractorOptionKey: model.contractorValue
        ? OverridableDateOptionKeyEnum.DateSubmittedByContractor
        : OverridableDateOptionKeyEnum.NotStated,
      selectedAdminOptionKey: model.adminValue
        ? OverridableDateOptionKeyEnum.DateSubmittedByAdministrator
        : OverridableDateOptionKeyEnum.NotStated,
    });
  }

  public static createRequestModel(model: OtDataDrivenOverridableDateValue) {
    return new ApiDataDrivenOverridableDateValueModel({
      adminOverride: model.adminOverride,
      adminValue: model.adminValue || undefined,
      contractorValue: model.contractorValue || undefined,
      date: model.date ? ZonelessDate.toZonelessDateFormat(model.date) : undefined,
      text: model.text || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOverridableDateValue.createRequestModel(this);
  }
}

// OT Overridable Duration
// FC11007 - OT Overridable Optional Duration
export interface IDataDrivenOverridableDuration extends OtDataDrivenResultBaseType {
  resultType: 'OverridableDurationModel';
  adminOverride: boolean; // Override selected
  contractorValue: number | null; // Contractor supplied number
  contractorCalendarType: CalendarTypeEnum | null; // Contractor selected calendar
  adminValue: number | null; // Administrator supplied number
  adminCalendarType: CalendarTypeEnum | null; // Administrator selected calendar
  number: number | null; // Effective value of the contractor and admin values
  calendarType: CalendarTypeEnum | null; // Effective calendarType of the contractor and admin values
  text: string | null; // Optional text associated with response
  selectedContractorOptionKey: string | null; // This is never given to the API. It's just used for the syncQuestion functionality
  selectedAdminOptionKey: string | null; // This is never given to the API. It's just used for the syncQuestion functionality
}

export class OtDataDrivenOverridableDuration implements IDataDrivenOverridableDuration {
  public resultType!: 'OverridableDurationModel';
  public adminOverride!: boolean;
  public contractorValue!: number | null;
  public contractorCalendarType!: CalendarTypeEnum | null;
  public adminValue!: number | null;
  public adminCalendarType!: CalendarTypeEnum | null;
  public number!: number | null;
  public calendarType!: CalendarTypeEnum | null;
  public text!: string | null;
  public selectedContractorOptionKey!: string | null;
  public selectedAdminOptionKey!: string | null;

  constructor(value: IDataDrivenOverridableDuration) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOverridableDurationModel) {
    return new OtDataDrivenOverridableDuration({
      resultType: 'OverridableDurationModel',
      adminOverride: model.adminOverride,
      contractorValue: model.contractorValue ?? null,
      contractorCalendarType: model.contractorCalendarType
        ? parsedOtCalendarTypeEnum[model.contractorCalendarType]
        : null,
      adminValue: model.adminValue ?? null,
      adminCalendarType: model.adminCalendarType ? parsedOtCalendarTypeEnum[model.adminCalendarType] : null,
      number: model.number ?? null,
      calendarType: model.calendarType ? parsedOtCalendarTypeEnum[model.calendarType] : null,
      text: model.text ?? null,
      selectedContractorOptionKey: model.contractorValue
        ? OverridableDurationOptionKeyEnum.DurationSubmittedByContractor
        : OverridableDurationOptionKeyEnum.NotStated,
      selectedAdminOptionKey: model.adminValue
        ? OverridableDurationOptionKeyEnum.DurationSubmittedByAdministrator
        : OverridableDurationOptionKeyEnum.NotStated,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenOverridableDuration>) {
    return new OtDataDrivenOverridableDuration({
      resultType: 'OverridableDurationModel',
      adminOverride: model.adminOverride || false,
      contractorValue: model.contractorValue || null,
      contractorCalendarType: model.contractorCalendarType || null,
      adminValue: model.adminValue || null,
      adminCalendarType: model.adminCalendarType || null,
      number: model.number ?? null,
      calendarType: model.calendarType || null,
      text: model.text || null,
      selectedContractorOptionKey: model.contractorValue
        ? OverridableDurationOptionKeyEnum.DurationSubmittedByContractor
        : OverridableDurationOptionKeyEnum.NotStated,
      selectedAdminOptionKey: model.adminValue
        ? OverridableDurationOptionKeyEnum.DurationSubmittedByAdministrator
        : OverridableDurationOptionKeyEnum.NotStated,
    });
  }

  public static createRequestModel(model: OtDataDrivenOverridableDuration) {
    return new ApiDataDrivenOverridableDurationModel({
      adminOverride: model.adminOverride,
      contractorValue: model.contractorValue || undefined,
      contractorCalendarType: model.contractorCalendarType
        ? parsedApiCalendarTypeEnum[model.contractorCalendarType]
        : undefined,
      adminValue: model.adminValue || undefined,
      adminCalendarType: model.adminCalendarType ? parsedApiCalendarTypeEnum[model.adminCalendarType] : undefined,
      number: model.number ?? undefined,
      calendarType: model.calendarType ? parsedApiCalendarTypeEnum[model.calendarType] : undefined,
      text: model.text || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenOverridableDuration.createRequestModel(this);
  }
}

// OT Modifying Factor
// FC11024 - OT WF Yes No with Factor Control
export interface IDataDrivenModifyingFactor extends OtDataDrivenResultBaseType {
  resultType: 'ModifyingFactorModel';
  enabled: boolean;
  number?: number | null; // Administrator supplied value
  adjustment?: DurationAdjustmentEnum | null; // Contractor specified Days or Percent
  text?: string | null; // Optional text associated with response
}

export class OtDataDrivenModifyingFactor implements IDataDrivenModifyingFactor {
  public resultType!: 'ModifyingFactorModel';
  public enabled!: boolean;
  public number?: number | null;
  public adjustment?: DurationAdjustmentEnum | null;
  public text?: string | null;

  constructor(value: IDataDrivenModifyingFactor) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenModifyingFactorModel) {
    return new OtDataDrivenModifyingFactor({
      resultType: 'ModifyingFactorModel',
      enabled: model.enabled,
      number: model.number,
      adjustment: model.adjustment ? parsedOtDurationAdjustmentEnum[model.adjustment] : null,
      text: model.text,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenModifyingFactor>) {
    return new OtDataDrivenModifyingFactor({
      resultType: 'ModifyingFactorModel',
      enabled: model.enabled || false,
      number: model.number || null,
      adjustment: model.adjustment || null,
      text: model.text || null,
    });
  }

  public static createRequestModel(model: OtDataDrivenModifyingFactor) {
    return new ApiDataDrivenModifyingFactorModel({
      enabled: model.enabled,
      number: model.number ?? undefined,
      adjustment: model.adjustment ? parsedApiDurationAdjustmentEnum[model.adjustment] : undefined,
      text: model.text || undefined,
    });
  }

  public createRequestModel() {
    return OtDataDrivenModifyingFactor.createRequestModel(this);
  }
}

export type IDataDrivenResultInterfaces =
  | IDataDrivenTextValue
  | IDataDrivenOptionValue
  | IDataDrivenOptionValueWithOther
  | IDataDrivenOptionsList
  | IDataDrivenDaySelection
  | IDataDrivenDateValue
  | IDataDrivenDaysOrDate
  | IDataDrivenDocumentDetails
  | IDataDrivenOverridableKeyValue
  | IDataDrivenOverridableDateValue
  | IDataDrivenOverridableDuration
  | IDataDrivenModifyingFactor;

export type OtDataDrivenResults =
  | OtDataDrivenTextValue
  | OtDataDrivenOptionValue
  | OtDataDrivenOptionValueWithOther
  | OtDataDrivenOptionsList
  | OtDataDrivenOptionsListWithOther
  | OtDataDrivenDaySelection
  | OtDataDrivenDaysOrDate
  | OtDataDrivenDocumentDetails
  | OtDataDrivenDateValue
  | OtDataDrivenOverridableKeyValue
  | OtDataDrivenOverridableDateValue
  | OtDataDrivenOverridableDuration
  | OtDataDrivenModifyingFactor;

export class OtDataDrivenResultBase {
  public resultType!: OtDataDrivenResultType;

  public static createFromApiResponse(model: ApiDataDrivenResultBaseModel): OtDataDrivenResults {
    if (model instanceof ApiDataDrivenTextValueModel) {
      return OtDataDrivenTextValue.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOptionValueModel) {
      return OtDataDrivenOptionValue.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOptionValueWithOtherModel) {
      return OtDataDrivenOptionValueWithOther.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOptionsListModel) {
      return OtDataDrivenOptionsList.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOptionsListWithOtherModel) {
      return OtDataDrivenOptionsListWithOther.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenDaySelectionModel) {
      return OtDataDrivenDaySelection.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenDateValueModel) {
      return OtDataDrivenDateValue.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOverridableKeyValueModel) {
      return OtDataDrivenOverridableKeyValue.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOverridableDateValueModel) {
      return OtDataDrivenOverridableDateValue.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenOverridableDurationModel) {
      return OtDataDrivenOverridableDuration.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenModifyingFactorModel) {
      return OtDataDrivenModifyingFactor.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenDaysOrDateModel) {
      return OtDataDrivenDaysOrDate.createFromApiResponse(model);
    } else if (model instanceof ApiDataDrivenDocumentDetailsModel) {
      return OtDataDrivenDocumentDetails.createFromApiResponse(model);
    } else {
      return OtDataDrivenTextValue.createEmpty();
    }
  }

  public static createRequestModel(model: OtDataDrivenResultBaseType): ApiDataDrivenResultBaseModel {
    switch (model.resultType) {
      case 'TextValueModel':
        return OtDataDrivenTextValue.createRequestModel(model as OtDataDrivenTextValue);
      case 'OptionsValueModel':
        return OtDataDrivenOptionValue.createRequestModel(model as OtDataDrivenOptionValue);
      case 'OptionsValueWithOtherModel':
        return OtDataDrivenOptionValueWithOther.createRequestModel(model as OtDataDrivenOptionValueWithOther);
      case 'OptionsListModel':
        return OtDataDrivenOptionsList.createRequestModel(model as OtDataDrivenOptionsList);
      case 'OptionsListWithOtherModel':
        return OtDataDrivenOptionsListWithOther.createRequestModel(model as OtDataDrivenOptionsListWithOther);
      case 'DaySelectionModel':
        return OtDataDrivenDaySelection.createRequestModel(model as OtDataDrivenDaySelection);
      case 'DateValueModel':
        return OtDataDrivenDateValue.createRequestModel(model as OtDataDrivenDateValue);
      case 'DaysOrDateModel':
        return OtDataDrivenDaysOrDate.createRequestModel(model as OtDataDrivenDaysOrDate);
      case 'DocumentDetailsModel':
        return OtDataDrivenDocumentDetails.createRequestModel(model as OtDataDrivenDocumentDetails);
      case 'OverridableKeyValueModel':
        return OtDataDrivenOverridableKeyValue.createRequestModel(model as OtDataDrivenOverridableKeyValue);
      case 'OverridableDateValueModel':
        return OtDataDrivenOverridableDateValue.createRequestModel(model as OtDataDrivenOverridableDateValue);
      case 'OverridableDurationModel':
        return OtDataDrivenOverridableDuration.createRequestModel(model as OtDataDrivenOverridableDuration);
      case 'ModifyingFactorModel':
        return OtDataDrivenModifyingFactor.createRequestModel(model as OtDataDrivenModifyingFactor);
    }
  }

  public static createFromJson(model: OtDataDrivenResultBaseType | undefined): OtDataDrivenResults {
    switch (model?.resultType) {
      case 'TextValueModel':
        return OtDataDrivenTextValue.createFromJson(model as OtDataDrivenTextValue);
      case 'OptionsValueModel':
        return OtDataDrivenOptionValue.createFromJson(model as OtDataDrivenOptionValue);
      case 'OptionsValueWithOtherModel':
        return OtDataDrivenOptionValueWithOther.createFromJson(model as OtDataDrivenOptionValueWithOther);
      case 'OptionsListModel':
        return OtDataDrivenOptionsList.createFromJson(model as OtDataDrivenOptionsList);
      case 'OptionsListWithOtherModel':
        return OtDataDrivenOptionsListWithOther.createFromJson(model as OtDataDrivenOptionsListWithOther);
      case 'DaySelectionModel':
        return OtDataDrivenDaySelection.createFromJson(model as OtDataDrivenDaySelection);
      case 'DateValueModel':
        return OtDataDrivenDateValue.createFromJson(model as OtDataDrivenDateValue);
      case 'DaysOrDateModel':
        return OtDataDrivenDaysOrDate.createFromJson(model as OtDataDrivenDaysOrDate);
      case 'DocumentDetailsModel':
        return OtDataDrivenDocumentDetails.createFromJson(model as OtDataDrivenDocumentDetails);
      case 'OverridableKeyValueModel':
        return OtDataDrivenOverridableKeyValue.createFromJson(model as OtDataDrivenOverridableKeyValue);
      case 'OverridableDateValueModel':
        return OtDataDrivenOverridableDateValue.createFromJson(model as OtDataDrivenOverridableDateValue);
      case 'OverridableDurationModel':
        return OtDataDrivenOverridableDuration.createFromJson(model as OtDataDrivenOverridableDuration);
      case 'ModifyingFactorModel':
        return OtDataDrivenModifyingFactor.createFromJson(model as OtDataDrivenModifyingFactor);
      default:
        return OtDataDrivenTextValue.createEmpty();
    }
  }
}
