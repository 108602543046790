import { RouteConfig } from 'vue-router';
import OtProfileDetails from '@/areas/profile/ot-profile-details.vue';
import OtInvitationAccept from '@/areas/profile/invitations/ot-invitation-accept.vue';
import OtProfileLayout from '@/areas/profile/ot-profile-layout.vue';
import OtEditProfileDetails from '@/areas/profile/ot-edit-profile-details.vue';

export const ROUTE_PROFILE_EDIT = 'EditProfile';
export const ROUTE_PROFILE_DETAILS = 'UserProfile';
export const ROUTE_INVITATION_DETAILS = 'InvitationDetail';

export const profileRoutes: Array<RouteConfig> = [
  {
    path: '/user-profile',
    component: OtProfileLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: ROUTE_PROFILE_DETAILS,
        component: OtProfileDetails,
        meta: {
          breadcrumb: 'Profile',
        },
      },
    ],
  },
  {
    path: '/user-profile/edit',
    name: ROUTE_PROFILE_EDIT,
    component: OtEditProfileDetails,
    meta: {
      breadcrumb: 'Profile/Edit',
      requiresAuth: true,
    },
  },
  {
    path: '/user-profile/invitations/:invitationId',
    name: ROUTE_INVITATION_DETAILS,
    component: OtInvitationAccept,
    meta: {
      breadcrumb: 'Profile/Accept Invitation',
      requiresAuth: true,
    },
  },
];
