import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';
import OtDocumentsLayout from '@/areas/documents/ot-documents-layout.vue';

export const documentsRoutes: Array<RouteConfig> = [
  {
    path: '/documents',
    component: OtDocumentsLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'Documents',
        component: OtComingSoon,
        meta: {
          breadcrumb: 'Documents',
        },
      },
    ],
  },
];
