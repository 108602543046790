








































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtSelect, { ISelectItem } from '@/components/global/ot-select.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { OtDataDrivenOverridableKeyValue } from '../models/data-driven-result';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';

@Component({
  components: {
    OtSelect,
    OtCheckbox,
    OtTextarea,
  },
})
export default class WfOverridableSelectEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private title: string | null = null;
  private fieldIsRequired = false;
  private description: string | null = null;

  // * COMPUTED
  private get selectItemOptions(): ISelectItem<string>[] {
    return this.question.optionsOrdered.map(o => {
      return { label: o.title, data: o.key };
    });
  }

  private overrideSelectedPrivate = false;
  private get overrideSelected() {
    return this.overrideSelectedPrivate;
  }
  private set overrideSelected(val: boolean) {
    this.overrideSelectedPrivate = val;
    if (!val) {
      this.adminValue = null;
    }
    this.onChange();
  }

  private adminValuePrivate: ISelectItem<string> | null = null;
  private get adminValue() {
    return this.adminValuePrivate;
  }
  private set adminValue(val: ISelectItem<string> | null) {
    this.adminValuePrivate = val;
    this.onChange();
  }

  private contractorValuePrivate: ISelectItem<string> | null = null;
  private get contractorValue() {
    return this.contractorValuePrivate;
  }
  private set contractorValue(val: ISelectItem<string> | null) {
    this.contractorValuePrivate = val;
    this.onChange();
  }

  private notesPrivate: string | null = null;
  private get notes() {
    return this.notesPrivate;
  }
  private set notes(val: string | null) {
    this.notesPrivate = val;
    this.onChange();
  }

  private get contractorRequiredMessage() {
    if (this.fieldIsRequired) {
      return `Contractor's selection is required`;
    }
    return undefined;
  }

  private get administratorRequiredMessage() {
    if (this.overrideSelected) {
      return `Administrator's selection is required`;
    }
    return undefined;
  }

  private get notesRequiredMessage() {
    if (this.overrideSelected) {
      return `Notes are required`;
    }
    return undefined;
  }

  private get secondaryLabel() {
    return this.overrideSelected ? 'Mandatory text' : 'Optional text';
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OverridableKeyValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-overridable-select-edit -> result -> ResultType is incorrect. Expected OverridableKeyValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get notesDescription() {
    return this.question.notesDescription;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.overrideSelectedPrivate = this.result?.adminOverride || false;
    this.adminValuePrivate = this.selectItemOptions.find(o => o.data === this.result?.adminValue) || null;
    this.contractorValuePrivate = this.selectItemOptions.find(o => o.data === this.result?.contractorValue) || null;
    this.notesPrivate = this.result?.text || null;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOverridableKeyValue({
      resultType: 'OverridableKeyValueModel',
      contractorValue: this.contractorValue?.data || null,
      adminValue: this.adminValue?.data || null,
      adminOverride: this.overrideSelected,
      key: this.overrideSelected ? this.adminValue?.data || '' : this.contractorValue?.data || '',
      text: this.notes,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.fieldIsRequired = this.question.isMandatory;

    this.valueChanged();
  }
}
