


























































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';
import { numericKeyboardEventKeys } from '@/well-known-values/key-codes';
import { getTimezoneOffset } from 'date-fns-tz';

@Component({
  components: {
    OtDatePicker,
    OtTextField,
    OtRadioGroup,
    OtFieldArchetype,
  },
})
export default class OtDateTimeField extends Vue {
  // * PROPS
  @Prop() private dateTitle!: string;
  @Prop() private timeTitle!: string;
  @Prop() private timezone?: string;
  @Prop() private value!: Date | null;
  @Prop({ type: String }) private dateRequiredMessage?: string;
  @Prop({ type: String }) private timeRequiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ default: null }) private formContainerElement!: HTMLElement | null;
  @Prop() private errorMessages?: Array<string>;

  // * REFS
  @Ref('timeFieldRef') private readonly timeFieldRef!: OtTextField;

  // * DATA
  private defaultTimeRules: Array<(value: string) => boolean | string> = [
    (value: string | null) => {
      const colonPos = value?.indexOf(':') || -1;
      const hoursString = value?.slice(0, colonPos) || '0';

      if (parseInt(hoursString, 10) > 12) {
        return 'Invalid hour value';
      }

      return true;
    },
    (value: string | null) => {
      const colonPos = value?.indexOf(':') || -1;
      const minutesString = value?.slice(colonPos + 1, value.length) || '0';

      const minutesNumber = parseInt(minutesString, 10);

      if (minutesNumber < 0 || minutesNumber > 59) {
        return 'Minutes must be between 00 and 59';
      }

      return true;
    },
  ];

  private amOption: IRadioGroupOption = { label: 'am', key: 'AM' };
  private pmOption: IRadioGroupOption = { label: 'pm', key: 'PM' };
  private amPmOptions: IRadioGroupOption[] = [this.amOption, this.pmOption];

  private dontUpdateTimeFlag = false;

  // * COMPUTED
  private get timeRules(): Array<(value: string) => boolean | string> {
    if (this.disabled) {
      return [];
    }
    return this.defaultTimeRules;
  }

  private selectedAmPmValuePrivate: IRadioGroupOption = this.amOption;
  private get selectedAmPmValue() {
    return this.selectedAmPmValuePrivate;
  }
  private set selectedAmPmValue(val: IRadioGroupOption) {
    this.selectedAmPmValuePrivate = val;
    this.setTimeValue(true);
  }

  private get dateValue(): Date | null {
    return this.value;
  }
  private set dateValue(val: Date | null) {
    if (!this.value && val) {
      if (this.timeValue) {
        // This handles the case where a time is picked first, then a date after
        const { hoursNumber, minutesNumber } = this.getTimeValuesFromString();
        const newValue = new Date(val);
        newValue.setHours(hoursNumber);
        newValue.setMinutes(minutesNumber);
        this.$emit('input', newValue);
      } else {
        // This handles the case where a date is being set but there's no time value
        this.dontUpdateTimeFlag = true;
        this.$emit('input', val);
      }
    } else {
      this.$emit('input', val);
    }
  }

  private timeValue: string | null = null;

  private get selectedTimezoneOffset() {
    if (this.timezone) {
      return getTimezoneOffset(this.timezone);
    }
    return null;
  }

  private get clientTimezoneOffset() {
    return getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    if (this.value) {
      if (!this.dontUpdateTimeFlag) {
        const time = `${this.value.getHours()}:${this.value.getMinutes()}`;
        this.timeValue = this.formatTime(time);
        this.dontUpdateTimeFlag = false;
      }
    } else {
      this.timeValue = null;
    }
  }

  private getTimeValuesFromString(): { hoursNumber: number; minutesNumber: number } {
    if (this.timeValue) {
      const colonPos = this.timeValue.indexOf(':');
      const hoursString = this.timeValue.slice(0, colonPos) || '0';
      const minutesString = this.timeValue.slice(colonPos + 1, this.timeValue.length) || '0';
      const hoursNumber = parseInt(hoursString, 10);
      const minutesNumber = parseInt(minutesString, 10);

      return { hoursNumber, minutesNumber };
    }
    return { hoursNumber: 0, minutesNumber: 0 };
  }

  // * METHODS
  private setTimeValue(radioOptionChanged = false) {
    if (this.validate()) {
      if (this.value && this.timeValue) {
        const combinedValue = new Date(this.value);

        // eslint-disable-next-line prefer-const
        let { hoursNumber, minutesNumber } = this.getTimeValuesFromString();

        if (radioOptionChanged) {
          if (this.selectedAmPmValue === this.pmOption) {
            hoursNumber += 12;
            if (hoursNumber === 24) {
              // Here means it is 12 pm. So we want to set hours to 12
              hoursNumber = 12;
            }
          } else if (hoursNumber === 12) {
            // Here means it is 12 am. So we want to set hours to 0
            hoursNumber = 0;
          }
        }

        combinedValue.setHours(hoursNumber);
        combinedValue.setMinutes(minutesNumber);
        this.$emit('input', combinedValue);
      }
    }
  }

  private validate() {
    const vuetifyTextField = this.timeFieldRef.vuetifyTextFieldRef;
    return vuetifyTextField.validate();
  }

  private formatMinutes(val: number): string {
    if (val.toString().length < 2) {
      return `0${val}`;
    }
    return val.toString();
  }

  private onBlur() {
    if (this.timeValue) {
      this.timeValue = this.formatTime(this.timeValue);
    }

    this.setTimeValue();

    this.$nextTick(() => {
      // Show any error messages
      this.validate();
    });
  }

  private formatTime(val: string): string {
    if (!val.includes(':')) {
      const hours = val.slice(0, 2);
      const minutes = val.slice(2, 4);
      val = `${hours}:${minutes}`;
    }
    const colonPos = val.indexOf(':');
    const hoursString = val.slice(0, colonPos) || '0';
    const minutesString = val.slice(colonPos + 1, val.length) || '0';
    const hoursNumber = parseInt(hoursString, 10);
    const minutesNumber = parseInt(minutesString, 10);

    let newHours = 0;

    if (hoursNumber === 0 || hoursNumber === 24) {
      newHours = 12;
      this.selectedAmPmValuePrivate = this.amOption;
    } else if (hoursNumber > 12 && hoursNumber <= 23) {
      newHours = hoursNumber - 12;
      this.selectedAmPmValuePrivate = this.pmOption;
    } else {
      newHours = hoursNumber;
    }

    return `${newHours}:${this.formatMinutes(minutesNumber)}`;
  }

  private stripUnnecessaryCharacters(text: string): string {
    // Remove anything that isn't a number, or a colon
    let cleanText = text.replaceAll(/[^0-9:]/g, '');

    // Remove repeated colons
    cleanText = cleanText.replaceAll(/(:)(?=:*\1)/g, '');
    return cleanText;
  }

  private filterPaste(event: ClipboardEvent) {
    const rawData = event.clipboardData?.getData('text') || '';
    let data = this.stripUnnecessaryCharacters(rawData);

    event.preventDefault();

    if (data) {
      const textField = event.target as HTMLInputElement;
      const startPos = textField.selectionStart || 0;
      const endPos = textField.selectionEnd || 0;

      const selectionRange = endPos - startPos;
      const newValueRange = this.timeValue?.length || 0 - selectionRange;
      const selectionText = this.timeValue?.slice(startPos, endPos) || '';
      const maxLength = (selectionText.includes(':') && data.includes(':')) || data.includes(':') ? 5 : 4;

      if (newValueRange + data.length >= maxLength) {
        const extraOverhang = data.length + newValueRange - maxLength;
        const newDataLength = data.length - extraOverhang;
        // Cut the copied data so that it doesn't exceed the max length of the input field
        data = data.slice(0, newDataLength);
      }

      const stringStart = this.timeValue?.slice(0, startPos) || '';
      const stringEnd = this.timeValue?.slice(endPos || 0, this.timeValue.length || 0) || '';
      this.timeValue = stringStart + data + stringEnd;

      // move cursor to correct position after paste
      this.$nextTick(() => {
        textField.selectionStart = startPos + data.length;
        textField.selectionEnd = startPos + data.length;
      });
    }
  }

  private filterKeypress(event: KeyboardEvent) {
    const textField = event.target as HTMLInputElement;
    // The selection range check allows the user to highlight and replace some text
    const selectionStart = textField.selectionStart || 0;
    const selectionEnd = textField.selectionEnd || 0;
    const selectionRange = selectionEnd - selectionStart;

    const timeContainsColon = this.timeValue?.includes(':') || false;
    if (timeContainsColon) {
      const newValueLength = this.timeValue?.replace(':', '').length || 0 - selectionRange;
      const colonPosition = this.timeValue?.indexOf(':') || -1;

      if (event.key === ':') {
        // Only allow 1 colon as long as the current colon isn't highlighted to be replaced
        const colonIsHighlighted =
          selectionRange !== 0 && selectionEnd > colonPosition && selectionStart <= colonPosition;
        if (!colonIsHighlighted) {
          event.preventDefault();
        }
      } else if (!numericKeyboardEventKeys.includes(event.key) || newValueLength > 3) {
        // Only allow a max of 4 numbers
        event.preventDefault();
      }
    } else {
      const newValueLength = this.timeValue?.length || 0 - selectionRange;
      if (event.key !== ':' && (!numericKeyboardEventKeys.includes(event.key) || newValueLength > 3)) {
        // Only allow a max of 4 numbers
        // but a colon is allowed
        event.preventDefault();
      }
    }
  }

  // * LIFECYCLE
  private created() {
    this.valueChanged();
  }
}
