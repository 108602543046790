import {
  CalendarStatus as ApiCalendarStatus,
  GetCalendarDetailsHolidayDateModel,
  GetCalendarDetailsHolidayModel,
  GetCalendarDetailsResponseModel,
  GetCalendarDetailsWorkingWeekHoursModel,
  GetOrganisationCalendarsCalendarModel,
  PostCalendarDateModel,
  PostCalendarHolidayModel,
  PostCalendarUpsertRequestModel,
} from '@/services/generated/api';
import { ZonelessDate } from '@/types/zoneless-date';
import { capitalizeString } from '@/utils/string-utils';
import { LastModified } from '@/utils/type-utils';
import { compareAsc } from 'date-fns';

export enum OtCalendarStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const parsedOtCalendarEnums: { [key in ApiCalendarStatus]: OtCalendarStatus } = {
  [ApiCalendarStatus.Active]: OtCalendarStatus.Active,
  [ApiCalendarStatus.Inactive]: OtCalendarStatus.Inactive,
};

export const parsedApiCalendarEnums: { [key in OtCalendarStatus]: ApiCalendarStatus } = {
  [OtCalendarStatus.Active]: ApiCalendarStatus.Active,
  [OtCalendarStatus.Inactive]: ApiCalendarStatus.Inactive,
};

export interface ICalendar {
  gid: string;
  name: string;
  status: OtCalendarStatus;
  hasParent: boolean;
}

export class Calendar implements ICalendar {
  public gid!: string;
  public name!: string;
  public status!: OtCalendarStatus;
  public hasParent!: boolean;

  public constructor(value: ICalendar) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new Calendar({
      gid: '',
      name: '',
      status: OtCalendarStatus.Active,
      hasParent: false,
    });
  }

  public static createFromApiResponse(model: GetOrganisationCalendarsCalendarModel): Calendar {
    return new Calendar({
      gid: model.calendarGid || '',
      name: model.calendarName || '',
      status: model.calendarStatus ? parsedOtCalendarEnums[model.calendarStatus] : OtCalendarStatus.Active,
      hasParent: model.hasParent || false,
    });
  }

  public static compareByName(a: Calendar, b: Calendar, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByKeyword(keyword: string, calendar: Calendar) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return calendar.name.match(regex);
    }
    return true;
  }

  public static filterByStatus(statuses: OtCalendarStatus[], calendar: Calendar) {
    if (statuses.length) {
      return statuses.includes(calendar.status);
    }
    return true;
  }
}

// * CALENDAR DETAILS
export interface IWorkingWeekHours {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export class WorkingWeekHours implements IWorkingWeekHours {
  public monday!: number;
  public tuesday!: number;
  public wednesday!: number;
  public thursday!: number;
  public friday!: number;
  public saturday!: number;
  public sunday!: number;

  public constructor(value: IWorkingWeekHours) {
    Object.assign(this, value);
  }

  public static readonly workingDayHours = 8;

  public static createEmpty() {
    return new WorkingWeekHours({
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    });
  }

  public static createFromApiResponse(response: GetCalendarDetailsWorkingWeekHoursModel) {
    const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = response;
    return new WorkingWeekHours({
      monday: monday || 0,
      tuesday: tuesday || 0,
      wednesday: wednesday || 0,
      thursday: thursday || 0,
      friday: friday || 0,
      saturday: saturday || 0,
      sunday: sunday || 0,
    });
  }

  public static createRequestModel(workingWeekHours: WorkingWeekHours) {
    return new GetCalendarDetailsWorkingWeekHoursModel(workingWeekHours);
  }
}

export interface IHolidayDate {
  gid: string;
  date: ZonelessDate;
}

export class HolidayDate implements IHolidayDate {
  public gid!: string;
  public date!: ZonelessDate;

  public constructor(value: IHolidayDate) {
    Object.assign(this, value);
  }

  public static compareByDate(a: HolidayDate, b: HolidayDate) {
    return compareAsc(a.date, b.date);
  }

  public static createFromApiResponse(response: GetCalendarDetailsHolidayDateModel) {
    return new HolidayDate({
      gid: response.gid || '',
      date: new ZonelessDate(response.date) || new ZonelessDate(),
    });
  }

  public static createRequestModel(holidayDate: HolidayDate) {
    return new PostCalendarDateModel({
      gid: holidayDate.gid,
      date: ZonelessDate.toZonelessDateFormat(holidayDate.date),
    });
  }
}

export interface IHoliday {
  gid: string;
  name: string;
  dates: Array<HolidayDate>;
  calendarGid: string;
}

export class Holiday implements IHoliday {
  public gid!: string;
  public name!: string;
  public dates!: Array<HolidayDate>;
  public calendarGid!: string;

  public constructor(value: IHoliday) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new Holiday({
      gid: '',
      name: '',
      dates: [],
      calendarGid: '',
    });
  }

  public static compareByParent(a: Holiday, b: Holiday, parentGid?: string) {
    if (parentGid) {
      const aInherited = a.calendarGid !== parentGid;
      const bInherited = b.calendarGid !== parentGid;

      if (aInherited) return 1;
      if (bInherited) return -1;
    }
    return 0;
  }

  public static compareByFirstDate(a: Holiday, b: Holiday) {
    return compareAsc(a.dates[0].date, b.dates[0].date);
  }

  public static createFromApiResponse(response: GetCalendarDetailsHolidayModel) {
    return new Holiday({
      gid: response.gid || '',
      name: response.name || '',
      dates: response.dates
        ? response.dates
            .map(date => HolidayDate.createFromApiResponse(date))
            .sort((a, b) => HolidayDate.compareByDate(a, b))
        : [],
      calendarGid: response.calendarGid || '',
    });
  }

  public static createRequestModel(holiday: Holiday) {
    return new PostCalendarHolidayModel({
      gid: holiday.gid,
      name: holiday.name,
      dates: holiday.dates.map(date => HolidayDate.createRequestModel(date)),
    });
  }
}
export interface ICalendarDetails {
  gid: string;
  parent?: string;
  hasChildren: boolean;
  name: string;
  workingWeekHours: WorkingWeekHours;
  holidays: Array<Holiday>;
  status: OtCalendarStatus;
  lastModified?: LastModified;
}

export class CalendarDetails implements ICalendarDetails {
  public gid!: string;
  public parent?: string;
  public hasChildren!: boolean;
  public name!: string;
  public workingWeekHours!: WorkingWeekHours;
  public holidays!: Array<Holiday>;
  public status!: OtCalendarStatus;
  public lastModified?: LastModified;

  public constructor(value: ICalendarDetails) {
    Object.assign(this, value);
  }

  public get activeDays() {
    const activeDays: string[] = [];
    for (const [day, hours] of Object.entries(this.workingWeekHours)) {
      if (hours !== 0) {
        activeDays.push(capitalizeString(day));
      }
    }
    return activeDays;
  }

  public static createEmpty() {
    return new CalendarDetails({
      gid: '',
      hasChildren: false,
      name: '',
      workingWeekHours: WorkingWeekHours.createEmpty(),
      holidays: [],
      status: OtCalendarStatus.Active,
      lastModified: LastModified.createEmpty(),
    });
  }

  public static createFromApiResponse(response: GetCalendarDetailsResponseModel) {
    return new CalendarDetails({
      gid: response.gid || '',
      parent: response.parentGid,
      hasChildren: response.hasChildren || false,
      name: response.name || '',
      workingWeekHours: response.workingWeekHours
        ? WorkingWeekHours.createFromApiResponse(response.workingWeekHours)
        : WorkingWeekHours.createEmpty(),
      holidays: response.holidays ? response.holidays.map(holiday => Holiday.createFromApiResponse(holiday)) : [],
      status: response.status ? parsedOtCalendarEnums[response.status] : OtCalendarStatus.Active,
      lastModified: response.lastModified
        ? LastModified.createFromApiResponse(response.lastModified)
        : LastModified.createEmpty(),
    });
  }

  public static createRequestModel(calendar: CalendarDetails, organisationGid: string) {
    return new PostCalendarUpsertRequestModel({
      organisationGid: organisationGid,
      parentCalendarGid: calendar.parent,
      name: calendar.name,
      workingWeekHours: WorkingWeekHours.createRequestModel(calendar.workingWeekHours),
      holidays: calendar.holidays.map(holiday => Holiday.createRequestModel(holiday)),
      status: parsedApiCalendarEnums[calendar.status],
    });
  }
}
