




















































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { numericKeyboardEventKeys } from '@/well-known-values/key-codes';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtSelect, { ISelectItem } from '@/components/global/ot-select.vue';
import { DurationAdjustmentEnum, DurationAdjustmentsMap } from '@/wf-components/models/data-driven-enums';
import { filterPaste } from '@/utils/string-utils';

export interface IFactorSelectionObject {
  factor: number | null;
  selectedType: DurationAdjustmentEnum | null;
}

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
    OtSelect,
  },
})
export default class OtFactorSelection extends Vue {
  // * PROPS
  @Prop() private value!: IFactorSelectionObject | null;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: String }) private secondaryLabel?: string;

  // * REFS
  @Ref('textFieldRef') private textFieldRef!: OtTextField;
  @Ref('selectRef') private selectRef!: OtSelect;

  // * DATA
  private factorValueError = false;
  private factorTypeError = false;

  // * COMPUTED
  private get factorTypeOptions(): ISelectItem<DurationAdjustmentEnum>[] {
    return Array.from(DurationAdjustmentsMap.keys()).map(t => {
      return {
        label: DurationAdjustmentsMap.get(t) || '',
        data: t,
      };
    });
  }

  private factorValuePrivate: string | null = null;
  private get factorValue() {
    return this.factorValuePrivate;
  }
  private set factorValue(val: string | null) {
    this.factorValuePrivate = val;
    this.emitObject();
  }

  private selectedFactorTypePrivate: ISelectItem<DurationAdjustmentEnum> | null = null;
  private get selectedFactorType() {
    return this.selectedFactorTypePrivate;
  }
  private set selectedFactorType(val: ISelectItem<DurationAdjustmentEnum> | null) {
    this.selectedFactorTypePrivate = val;
    this.clampValuesIfPercentageIsSelected();
    this.emitObject();
  }

  // * WATCHERS
  @Watch('value', { deep: true })
  private valueChanged() {
    this.factorValuePrivate = this.value?.factor?.toString() || null;
    this.selectedFactorTypePrivate = this.factorTypeOptions.find(o => o.data === this.value?.selectedType) || null;
  }

  // * METHODS
  private emitObject() {
    const factorValue = parseInt(this.factorValue || '');
    const val: IFactorSelectionObject = {
      factor: Number.isNaN(factorValue) ? null : factorValue,
      selectedType: this.selectedFactorType?.data || null,
    };

    if (val.factor && val.selectedType) {
      // This check is here to turn off the error state of the fields
      // The check to turn it on is in the onTextInputBlur & onSelectInputBlur events
      this.resetManualErrorState();
    }
    this.$emit('input', val);
  }

  private clampValuesIfPercentageIsSelected() {
    if (this.selectedFactorType?.data === DurationAdjustmentEnum.Percentage) {
      const factorValue = parseInt(this.factorValue || '');
      if (!Number.isNaN(factorValue)) {
        if (factorValue > 100) {
          this.factorValue = '100';
        }
      }
    }
  }

  private onTextInputBlur() {
    if (!this.factorValue) {
      this.resetManualErrorState();
    } else {
      this.clampValuesIfPercentageIsSelected();
    }
  }

  private onSelectInputBlur() {
    if (!this.selectedFactorType) {
      this.resetManualErrorState();
    }
  }

  public resetManualErrorState() {
    this.factorTypeError = !this.selectRef.validate();
    this.factorValueError = !this.textFieldRef.validate();
  }

  private handlePaste(event: ClipboardEvent) {
    const pasteResult = filterPaste(event, this.factorValue);

    if (pasteResult) {
      this.factorValue = pasteResult.textValue;
      const textField = event.target as HTMLInputElement;
      this.$nextTick(() => {
        textField.selectionStart = pasteResult.startPos + pasteResult.data.length;
        textField.selectionEnd = pasteResult.startPos + pasteResult.data.length;
      });
    }
  }

  private filterKeypress(event: KeyboardEvent) {
    if (!numericKeyboardEventKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  // * LIFECYCLE
  private created() {
    this.valueChanged();
  }
}
