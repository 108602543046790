
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrganisationUserProject } from '../models/organisation-user-project-models';

import OtOrganisationUserProjectsTable from './ot-organisation-user-projects-table.vue';

@Component({
  components: {
    OtOrganisationUserProjectsTable,
  },
})
export default class OtOrganisationUserProjectsIndex extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private organisationUserProjects!: OrganisationUserProject[];

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
