

































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import { v4 as uuid } from 'uuid';
import { IVForm } from '@/utils/type-utils';

export interface ISelectItem<T> {
  label: string;
  data: T | null;
}

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtSelect extends Vue {
  // * PROPS
  @Prop() private value!: ISelectItem<unknown> | string;
  @Prop() private items!: ISelectItem<unknown>[] | string[];
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String, default: 'auto' }) private hideDetails?: string;
  @Prop({ type: String, default: 'auto' }) private menuHeight?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop() private rules?: Array<(value: string) => boolean | string>;
  // set this to false if you need the datepicker to be able to "hang outside" the parent component (like in a dialog)
  // but be careful, if the parent component is scrollable, the picker will no longer "follow" the field and it'll look dumb
  @Prop({ type: Boolean, default: true }) private attachToField!: boolean;

  // * REFS
  @Ref('selectRef') private selectRef!: IVForm;

  // * DATA
  private isRequiredRule = (value: string) => !!value || this.requiredMessage || '';
  private componentId = '';

  // * COMPUTED
  private get selectedValue() {
    return this.value;
  }

  private set selectedValue(value: ISelectItem<unknown> | string) {
    this.$emit('input', value);
    if (!this.value) {
      this.disableTabbingToClearIcon();
    }
  }

  private get inputRules(): Array<(value: string) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }
  // * WATCHERS

  // * METHODS
  public validate() {
    return this.selectRef.validate();
  }

  private disableTabbingToClearIcon() {
    const element = this.selectRef.$el.getElementsByClassName('v-icon mdi-close').item(0);
    if (element) {
      element?.setAttribute('tabindex', '-1');
    }
  }

  // * LIFECYCLE
  private created() {
    this.componentId = `e-${uuid()}`;
  }

  private mounted() {
    this.disableTabbingToClearIcon();
  }
}
