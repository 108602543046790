
















import { Component, Ref, Vue } from 'vue-property-decorator';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputAction } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import { SegmentInstance } from '@/areas/claims/claims-models';
import OtClaimsTable from '@/areas/claims/ot-claims-table.vue';
import { ROUTE_WORKFLOW_ADD } from './claims-routes';
import OtButton from '@/components/global/ot-button.vue';

@Component({
  components: {
    OtLoadingSpinner,
    OtStandardHeaderArchetype,
    OtClaimsTable,
    OtButton,
  },
})
export default class OtClaimsIndex extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private primaryContextBtn: IInputAction = {
    actionName: 'New Workflow',
    action: { name: ROUTE_WORKFLOW_ADD },
  };
  private loading = false;
  private api = new OtApi();
  private segmentInstances: SegmentInstance[] = [];

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private async handleReloadSegments(callback?: () => void) {
    const apiResponse = await executeApi(() => this.api.segmentInstances().getSegmentInstances(), 'Load Workflows');
    if (apiResponse.success && apiResponse.data?.segmentInstances) {
      this.segmentInstances = apiResponse.data.segmentInstances.map(s => SegmentInstance.createFromApiResponse(s));
    }

    if (callback) {
      callback();
    }
  }

  // * LIFECYCLE
  private async created() {
    this.loading = true;

    await this.handleReloadSegments();

    this.loading = false;
  }
}
