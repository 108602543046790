


























































































































































import { OtDueDateStatus, parsedOtSegmentStatuses, SegmentInstance } from '@/areas/claims/claims-models';
import OtCheckboxGroup, {
  CheckboxGroupItem,
  CheckboxGroupSet,
  CheckboxGroupType,
} from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import { DangerModalParams } from '@/components/global/modal/form-modal-models';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtDueDateTag from '@/components/global/ot-due-date-tag.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, IApiErrorOverride } from '@/services/api.service';
import {
  PostUpdateSegmentInstanceStatusRequestModel as ApiPostUpdateSegmentInstanceStatusRequestModel,
  SegmentInstanceStatus as APISegmentStatus,
} from '@/services/generated/api';
import { vxm } from '@/store';
import {
  OtContractStatus,
  OtOpenSegmentStatusSet,
  OtProjectStatus,
  OtSegmentResolution,
  OtSegmentStatus,
  OtSegmentStatusesThatCanAffectPC,
  OtStatusType,
} from '@/types/status-enums';
import { OtSegmentProcessTypesThatCanAffectPC } from '@/wf-components/models/data-driven-enums';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { ROUTE_CONTRACT_DEFAULT } from '../projects/contracts/contract-routes';
import { ROUTE_PROJECT_DEFAULT } from '../projects/projects-routes';
import { ROUTE_WORKFLOW_ADD, ROUTE_WORKFLOW_DETAILS } from './claims-routes';
import { Workbook } from 'exceljs';
// dang it, the @types/file-saver has the wrong exported thing :/
import { saveAs } from 'file-saver';
import { convertToTimezone } from '@/utils/date-utils';
import styles from '@/styles/variables.scss';
import { autoFitColumn, cleanStringForFilename } from '@/utils/excel-utils';
import { format } from 'date-fns';

interface IClaimOptionModel {
  label: string;
  onClick: (segment: SegmentInstance) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtCheckbox,
    OtTag,
    OtButton,
    OtDueDateTag,
  },
})
export default class OtClaimsTable extends Vue {
  // * PROPS
  @Prop({ default: true }) private showContractColumn!: boolean;
  @Prop({ default: true }) private showProjectColumn!: boolean;
  @Prop({ default: '' }) private baseExportFileName!: string;
  @Prop() private segmentInstances!: SegmentInstance[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private page = 1;
  private searchText = '';
  private rowLoadingStates: { [key: string]: boolean } = {};
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  private openStatusFilters: CheckboxGroupItem<OtSegmentStatus>[] = [
    new CheckboxGroupItem('In Progress', OtSegmentStatus.InProgress),
    new CheckboxGroupItem('Suspended', OtSegmentStatus.Suspended),
    new CheckboxGroupItem('Info Requested & Not Suspended', OtSegmentStatus.InfoRequestedAndNotSuspended),
    new CheckboxGroupItem('Info Requested & Suspended', OtSegmentStatus.InfoRequestedAndSuspended),
  ];

  private statusFilters: CheckboxGroupType<OtSegmentStatus, OtSegmentResolution>[] = [
    new CheckboxGroupSet<OtSegmentStatus, OtSegmentResolution>('Open', this.openStatusFilters),
    new CheckboxGroupSet('Closed', [
      new CheckboxGroupSet<OtSegmentStatus, OtSegmentResolution>('Complete', [
        new CheckboxGroupItem('Accepted', OtSegmentResolution.Accepted),
        new CheckboxGroupItem('Rejected', OtSegmentResolution.Rejected),
      ]),
      new CheckboxGroupItem('Withdrawn', OtSegmentStatus.Withdrawn),
      new CheckboxGroupItem('Cancelled', OtSegmentStatus.Cancelled),
      new CheckboxGroupItem('Legal Dispute', OtSegmentStatus.LegalDispute),
      new CheckboxGroupItem('Disputed', OtSegmentStatus.Disputed),
    ]),
  ];
  private selectedStatusFilters: CheckboxGroupItem<OtSegmentStatus>[] = [];

  private dueDateFilters: CheckboxGroupType<OtDueDateStatus>[] = [
    new CheckboxGroupSet('Open', [
      new CheckboxGroupItem('Current', OtDueDateStatus.Open),
      new CheckboxGroupItem('Deadline Approaching', OtDueDateStatus.DeadlineApproaching),
      new CheckboxGroupItem('Overdue', OtDueDateStatus.Overdue),
    ]),
    new CheckboxGroupItem('Closed', OtDueDateStatus.Closed),
  ];
  private selectedDueDateFilters: CheckboxGroupItem<OtDueDateStatus>[] = [];

  private typeFilters: CheckboxGroupType<string>[] = [];
  private selectedTypeFilters: CheckboxGroupItem<string>[] = [];

  private tableColumnsBase: IExtendedColumnData<SegmentInstance>[] = [
    {
      index: 0,
      label: 'Ref',
      key: 'claim',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: SegmentInstance.compareByName,
    },
    {
      index: 1,
      label: 'Contract',
      key: 'contract',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByContract,
    },
    {
      index: 2,
      label: 'Project',
      key: 'project',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByProject,
    },
    {
      index: 3,
      label: 'Created',
      key: 'created',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByCreated,
    },
    {
      index: 4,
      label: 'Type',
      key: 'type',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByType,
    },
    {
      index: 5,
      label: 'Granted Time',
      key: 'grantedTime',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByGrantedTime,
    },
    {
      index: 6,
      label: 'Due Date',
      key: 'dueDate',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByDueDate,
    },
    {
      index: 7,
      label: 'Status',
      key: 'status',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: SegmentInstance.compareByStatus,
    },
    {
      index: 8,
      label: '',
      key: 'actionMenu',
      isActive: false,
      ascending: false,
      sortable: false,
      menu: [
        {
          label: 'Export to Excel',
          onClick: () => this.exportExcel(this.baseExportFileName),
          disabled: true,
        },
      ],
    },
  ];
  private activeColumn = this.tableColumnsBase[0];

  // * COMPUTED
  private get contractRoute() {
    return ROUTE_CONTRACT_DEFAULT;
  }
  private get projectRoute() {
    return ROUTE_PROJECT_DEFAULT;
  }

  private get tableColumns() {
    if (this.showContractColumn && this.showProjectColumn) {
      return this.tableColumnsBase;
    }
    const filteredColumns = this.tableColumnsBase.filter(
      c => (this.showContractColumn || c.key !== 'contract') && (this.showProjectColumn || c.key !== 'project'),
    );
    return filteredColumns;
  }
  private set tableColumns(val: IExtendedColumnData<SegmentInstance>[]) {
    this.tableColumnsBase = val;
  }

  private get sortedBodyData() {
    const sortedClaims = sortTableData(this.tableColumns, this.activeColumn, this.segmentInstances);
    return sortedClaims;
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedStatusFilters.map(s => s.value);
    const parsedDueDates = this.selectedDueDateFilters.map(s => s.value);
    const parsedTypeNames = this.selectedTypeFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return (
        SegmentInstance.filterByStatus(parsedStatuses, row) &&
        SegmentInstance.filterByDueDate(parsedDueDates, row) &&
        SegmentInstance.filterByTypeName(parsedTypeNames, row) &&
        SegmentInstance.filterByKeyword(this.searchText, row)
      );
    });
  }

  @Watch('filteredBodyData')
  private onFilteredBodyDataChange() {
    this.raiseCanExport();
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS
  @Watch('contracts', { deep: true })
  private contractsChanged() {
    this.setRowLoadingStates();
  }

  // * METHODS
  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.segmentInstances.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  private getTagStatus(status: OtSegmentStatus): TagStatus {
    return { type: OtStatusType.Segment, status };
  }

  private getClaimLink(claim: SegmentInstance): RawLocation {
    // if the logic for "where to take them" changes, also update the logic in ot-claim-details (OtClaimDetails) isEditEnabled
    if (OtOpenSegmentStatusSet.has(claim.status)) {
      return { name: ROUTE_WORKFLOW_ADD, query: { claimGid: claim.gid } };
    } else {
      return {
        name: ROUTE_WORKFLOW_DETAILS,
        params: { projectGid: claim.project.gid, contractGid: claim.contract.gid, claimGid: claim.gid },
      };
    }
  }

  private getClaimOptions(claim: SegmentInstance): IClaimOptionModel[] {
    const { status, resolution } = claim;
    const contractStatus = claim.contract.status;
    const projectStatus = claim.project.status;
    const projectStatusesToRestrict = new Set([OtProjectStatus.Complete, OtProjectStatus.Suspended]);
    const contractStatusesToNotRestrict = new Set([
      OtContractStatus.Active,
      OtContractStatus.FinalCompletion,
      OtContractStatus.PracticalCompletion,
    ]);
    const restrictBasedOnProjectOrContractStatus =
      projectStatusesToRestrict.has(projectStatus) || !contractStatusesToNotRestrict.has(contractStatus);

    switch (status) {
      case OtSegmentStatus.InProgress:
        // all of these are permissable at all times
        return [
          this.setToInfoRequestedNotSuspendedOption,
          this.setToInfoRequestedAndSuspendedOption,
          this.withdrawOption,
          this.cancelClaimOption,
          this.setSuspendedOption,
        ];
      case OtSegmentStatus.Suspended:
        // all of these are permissable at all times
        return [
          this.liftSuspensionOption,
          this.withdrawOption,
          this.cancelClaimOption,
          this.setToInfoRequestedNotSuspendedOption,
          this.setToInfoRequestedAndSuspendedOption,
        ];
      case OtSegmentStatus.Completed:
        if (restrictBasedOnProjectOrContractStatus) {
          // only thing we can do is set it to disputed. Disputed still counts towards PC
          // I suspect this will change in the future
          return [this.setToLegalDisputeOption, this.setToDisputedOption];
        }
        return [
          this.reopenClaimOption,
          this.setToLegalDisputeOption,
          this.setToDisputedOption,
          this.withdrawOption,
          this.cancelClaimOption,
        ];
      case OtSegmentStatus.Disputed: {
        if (restrictBasedOnProjectOrContractStatus) {
          const options = [this.setToLegalDisputeOption];
          if (resolution) {
            options.push(this.setToResolvedWithoutChangeOption);
          }
          return options;
        }
        const options = [
          this.reopenClaimOption,
          this.setToLegalDisputeOption,
          this.withdrawOption,
          this.cancelClaimOption,
        ];
        if (resolution) {
          options.push(this.setToResolvedWithoutChangeOption);
        }
        return options;
      }
      case OtSegmentStatus.LegalDispute: {
        if (restrictBasedOnProjectOrContractStatus) {
          const options = [this.setToDisputedOption];
          if (resolution) {
            options.push(this.setToResolvedWithoutChangeOption);
          }
          return options;
        }
        const options: IClaimOptionModel[] = [
          this.reopenClaimOption,
          this.setToDisputedOption,
          this.withdrawOption,
          this.cancelClaimOption,
        ];
        if (resolution) {
          options.push(this.setToResolvedWithoutChangeOption);
        }
        return options;
      }
      case OtSegmentStatus.InfoRequestedAndSuspended:
        // no restrictions
        return [this.infoReceivedOption, this.liftSuspensionOption, this.withdrawOption, this.cancelClaimOption];
      case OtSegmentStatus.InfoRequestedAndNotSuspended:
        // no restrictions
        return [
          this.infoReceivedOption,
          this.withdrawOption,
          this.cancelClaimOption,
          this.setSuspendedOption,
          this.setToInfoRequestedAndSuspendedOption,
        ];
      case OtSegmentStatus.Withdrawn:
        if (restrictBasedOnProjectOrContractStatus) {
          return [this.cancelClaimOption];
        }
        return [this.reopenClaimOption, this.setToLegalDisputeOption, this.setToDisputedOption, this.cancelClaimOption];
      case OtSegmentStatus.Cancelled:
        if (restrictBasedOnProjectOrContractStatus) {
          return [this.withdrawOption];
        }
        return [this.reopenClaimOption, this.setToLegalDisputeOption, this.setToDisputedOption, this.withdrawOption];
    }
  }

  /** Returns true if should perform the action. Either because we didn't prompt, or because we did prompt and they said go ahead */
  private async shouldPerformActionThatMightCauseSegmentToNoLongerAffectPC(
    segment: SegmentInstance,
    action = 'Reopening',
  ) {
    if (
      Boolean(segment.grantedTime) &&
      OtSegmentStatusesThatCanAffectPC.has(segment.status) &&
      OtSegmentProcessTypesThatCanAffectPC.has(segment.workflowSegment.segmentProcessType) &&
      !segment.noLongerAffectingPC &&
      (segment.resolution === OtSegmentResolution.Accepted ||
        segment.resolution === OtSegmentResolution.ValidWithDifferentTime)
    ) {
      const modalParams = new DangerModalParams({
        title: 'Warning: Extension of Time will be reverted',
        message: `${action} this workflow will result in the extension of time no longer affecting the date for practical completion in the system. Do you wish to continue?`,
        confirmText: 'Yes',
        cancelText: 'No',
      });
      const modalResult = await vxm.modal.openDangerModal(modalParams);
      return modalResult.ok;
    }
    // no prompt, no problem, do the action
    return true;
  }

  private reopenClaimOption: IClaimOptionModel = {
    label: 'Reopen',
    onClick: async (segment: SegmentInstance) => {
      const shouldChangeStatus = await this.shouldPerformActionThatMightCauseSegmentToNoLongerAffectPC(
        segment,
        'Reopening',
      );
      if (!shouldChangeStatus) {
        // bail
        return;
      }

      const setStatusResult = await this.setSegmentStatus(segment, APISegmentStatus.InProgress);

      if (setStatusResult) {
        const modalParams = new DangerModalParams({
          title: 'Do you want to amend the workflow now?',
          message: `Clicking 'Yes' will bring you to the edit screen for ${segment.name}.`,
          confirmText: 'Yes',
          cancelText: 'No',
        });
        const modalResult = await vxm.modal.openDangerModal(modalParams);
        if (modalResult.ok) {
          this.$router.push({
            name: ROUTE_WORKFLOW_ADD,
            query: { claimGid: segment.gid },
          });
        }
      }
    },
  };
  private liftSuspensionOption: IClaimOptionModel = {
    label: 'Lift Suspension',
    onClick: (segment: SegmentInstance) => {
      if (segment.status === OtSegmentStatus.InfoRequestedAndSuspended) {
        this.setSegmentStatus(segment, APISegmentStatus.InfoRequestedAndNotSuspended);
      } else {
        this.setSegmentStatus(segment, APISegmentStatus.InProgress);
      }
    },
  };
  private setToInfoRequestedNotSuspendedOption: IClaimOptionModel = {
    label: 'Set to Info Requested & Not Suspended',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.InfoRequestedAndNotSuspended);
    },
  };
  private setToInfoRequestedAndSuspendedOption: IClaimOptionModel = {
    label: 'Set to Info Requested & Suspended',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.InfoRequestedAndSuspended);
    },
  };
  private setToLegalDisputeOption: IClaimOptionModel = {
    label: 'Set to Legal Dispute',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.LegalDispute);
    },
  };
  private setSuspendedOption: IClaimOptionModel = {
    label: 'Suspend',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.Suspended);
    },
  };
  private setToDisputedOption: IClaimOptionModel = {
    label: 'Set to Disputed',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.Disputed);
    },
  };
  private withdrawOption: IClaimOptionModel = {
    label: 'Withdraw',
    onClick: async (segment: SegmentInstance) => {
      const shouldChangeStatus = await this.shouldPerformActionThatMightCauseSegmentToNoLongerAffectPC(
        segment,
        'Withdrawing',
      );
      if (!shouldChangeStatus) {
        // bail
        return;
      }

      this.setSegmentStatus(segment, APISegmentStatus.Withdrawn);
    },
  };
  private cancelClaimOption: IClaimOptionModel = {
    label: 'Cancel this workflow',
    onClick: async (segment: SegmentInstance) => {
      // by the time I get to here, I think "maybe this should have been in set status"
      // but, meh, this is pretty flexible
      // feels like we should chuck a from and a to status into the mix, but I don't think we need it
      const shouldChangeStatus = await this.shouldPerformActionThatMightCauseSegmentToNoLongerAffectPC(
        segment,
        'Cancelling',
      );
      if (!shouldChangeStatus) {
        // bail
        return;
      }

      this.setSegmentStatus(segment, APISegmentStatus.Cancelled);
    },
  };
  private infoReceivedOption: IClaimOptionModel = {
    label: 'Info Received',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.InProgress);
    },
  };
  private setToResolvedWithoutChangeOption: IClaimOptionModel = {
    label: 'Set to Resolved Without Change',
    onClick: (segment: SegmentInstance) => {
      this.setSegmentStatus(segment, APISegmentStatus.Complete);
    },
  };

  private async setSegmentStatus(segment: SegmentInstance, status: APISegmentStatus) {
    const { gid, noLongerAffectingPC } = segment;

    if (noLongerAffectingPC) {
      const modalParams = new DangerModalParams({
        title: 'Warning: Workflow is locked',
        message: `This workflow has been previously locked due to a change in the Contract date for practical completion. Your action may affect the date for practical completion, and that might be contrary to the agreement.<br/><br/>Do you still wish to proceed?`,
        useHtmlInMessage: true,
        confirmText: 'Yes',
        cancelText: 'No',
      });
      const modalResult = await vxm.modal.openDangerModal(modalParams);
      if (!modalResult.ok) {
        return false;
      }
    }
    this.rowLoadingStates[gid] = true;

    const model = new ApiPostUpdateSegmentInstanceStatusRequestModel({
      status,
      contractRowVersion: segment.contract.rowVersion,
    });
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 409,
        message: `Unable to set workflow status to ${parsedOtSegmentStatuses[status]} - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    const apiResponse = await executeApi(
      () => this.api.segmentInstances().postUpdateSegmentInstanceStatus(gid, undefined, model),
      `Set Workflow Status to ${parsedOtSegmentStatuses[status]} (${gid})`,
      errorOverride,
    );

    if (apiResponse.success) {
      // we don't wait for this one before continuing. Could add extra stuff to the args. Don't care
      this.$emit('segmentInstanceStatusChanged', { segmentInstance: segment });
      // need to wait until we've reloaded the segments before we keep going
      // not entirely sure we NEED to, but we will anyway, feels like the right thing to do
      await new Promise<boolean>(resolve => {
        this.$emit('reloadSegments', () => {
          this.rowLoadingStates[gid] = false;
          resolve(true);
        });
      });
    } else {
      this.rowLoadingStates[gid] = false;
    }

    return true;
  }

  private showGrantedTime(claim: SegmentInstance) {
    return (
      !!claim.grantedTime &&
      OtSegmentStatusesThatCanAffectPC.has(claim.status) &&
      OtSegmentProcessTypesThatCanAffectPC.has(claim.workflowSegment.segmentProcessType) &&
      (claim.resolution === OtSegmentResolution.Accepted ||
        claim.resolution === OtSegmentResolution.ValidWithDifferentTime)
    );
  }

  public async exportExcel(baseFileName: string) {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Workflows');
    sheet.columns = [
      { header: 'Ref', key: 'ref', width: 10 },
      { header: 'Contract', key: 'contract', width: 32 },
      { header: 'Project', key: 'project', width: 10 },
      { header: 'Created', key: 'created', width: 10, style: { numFmt: 'dd/mm/yyyy' } },
      { header: 'Type', key: 'type', width: 10 },
      { header: 'Granted Time', key: 'granted', width: 10 },
      { header: 'Due Date', key: 'due', width: 10, style: { numFmt: 'dd/mm/yyyy' } },
      { header: 'Status', key: 'status', width: 10 },
    ];

    // TODO consider using a colour conversion library to ensure we are in hex format first
    const colors = {
      green: styles.otColorGreen300.replace('#', '').toUpperCase(),
      amber: styles.otColorAmber300.replace('#', '').toUpperCase(),
      red: styles.otColorRed300.replace('#', '').toUpperCase(),
      white: 'FFFFFF',
      grey: styles.otColorGrey200.replace('#', '').toUpperCase(),
    };

    for (const workflow of this.filteredBodyData) {
      // created will be sort of in the local timezone, sort of in utc, not realy any use for anyone. Convert it to the contract timezone
      const createdInContractTimezone = convertToTimezone(workflow.createdDateUtc, workflow.contract.timezone);
      // Then do some incantations to get it in a format that excel likes. This too altogether FAR TOO LONG to work out
      // https://github.com/exceljs/exceljs/issues/486#issuecomment-432557582 was the anser
      const createdInContractTimezoneButBackToUtcAgainBecauseExcelIsWeird = new Date(
        Date.UTC(
          createdInContractTimezone.getFullYear(),
          createdInContractTimezone.getMonth(),
          createdInContractTimezone.getDate(),
          createdInContractTimezone.getHours(),
          createdInContractTimezone.getMinutes(),
          createdInContractTimezone.getSeconds(),
        ),
      );

      // this one is already a zoneless date. There's no time component. The year month and day should be accurate. Do the same weird UTC thing to make excel stop being dumb
      const dueDateExcelStopBeingWeird = workflow.dueDate
        ? new Date(Date.UTC(workflow.dueDate.getFullYear(), workflow.dueDate.getMonth(), workflow.dueDate.getDate()))
        : undefined;
      const rowData = {
        ref: workflow.reference,
        contract: workflow.contract.name,
        project: workflow.project.name,
        created: createdInContractTimezoneButBackToUtcAgainBecauseExcelIsWeird, // we want a date here. Probably should turn it into the contract timezone though
        type: workflow.workflowSegment.shortName,
        granted: this.showGrantedTime(workflow) ? workflow.grantedTimeFormatted : '',
        due: dueDateExcelStopBeingWeird,
        status: workflow.status,
      };
      const newRow = sheet.addRow(rowData);
      if (workflow.responseTimeAlertsEnabled) {
        const dueCell = newRow.getCell('due');

        switch (workflow.dueDateStatus) {
          case OtDueDateStatus.Open:
            dueCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              // Yes. fgColor. Not bgColor. If you set bgColor you get a black cell
              fgColor: { argb: colors.green },
            };
            break;
          case OtDueDateStatus.DeadlineApproaching:
            dueCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colors.amber },
            };
            break;
          case OtDueDateStatus.Overdue:
            dueCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colors.red },
            };
            // This is how you set a foreground color. Not on the fill fgColor. Dang that cost me hours
            dueCell.font = { color: { argb: colors.white } };
            break;
          case OtDueDateStatus.Closed:
            dueCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: colors.grey },
            };
            break;
        }
      }
    }

    // Dunno if we need to do this first or last. Let's do it last
    sheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

    for (const col of sheet.columns) {
      autoFitColumn(col);
    }

    // don't like the A to H here, it'll do
    sheet.autoFilter = 'A1:H1';

    const buffer = await workbook.xlsx.writeBuffer();

    const now = new Date();
    const fileName =
      (baseFileName ? cleanStringForFilename(baseFileName) + '_' : '') +
      'Workflows_' +
      format(now, 'yyyy-MM-dd_HH-mm') +
      '.xlsx';
    saveAs(new Blob([buffer]), fileName);
  }

  private raiseCanExport() {
    const hasFilteredRows = Boolean(this.filteredBodyData?.length);

    const actionColumn = this.tableColumnsBase.find(x => x.key === 'actionMenu');
    if (actionColumn?.menu?.length) {
      // the action column should have a menu, but typescript doesn't know that, so an if to shut the compiler up
      actionColumn.menu[0].disabled = !hasFilteredRows;
    }

    this.$emit('can-export', hasFilteredRows);
  }

  // * LIFECYCLE
  private created() {
    // Filter out unique segment types
    const segementTypes = new Map(
      this.segmentInstances.map(si => [
        si.workflowSegment.shortName,
        new CheckboxGroupItem(si.workflowSegment.shortName, si.workflowSegment.name),
      ]),
    );
    this.typeFilters = [...segementTypes.values()];
    this.raiseCanExport();
  }
}
