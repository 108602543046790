



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import OtTable from '@/components/global/table/ot-table.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtCheckboxGroup, {
  CheckboxGroupItem,
  CheckboxGroupSet,
  CheckboxGroupType,
} from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTrafficLights from '@/components/global/ot-traffic-lights.vue';
import {
  OtProjectStatus,
  OtSegmentResolution,
  OtSegmentStatus,
  OtStatusType,
  OtUserStatus,
} from '@/types/status-enums';
import { PermissionLevel, PostUpdateProjectStatusRequestModel } from '@/services/generated/api';
import { capitalizeString } from '@/utils/string-utils';
import { formatDate } from '@/utils/date-utils';
import { RawLocation } from 'vue-router';

import { parsedApiProjectStatusEnums, Project } from './project-models';
import { ROUTE_PROJECT_DEFAULT } from './projects-routes';
import AuthService from '@/services/auth.service';
import { vxm } from '@/store';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (contract: Project) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtTrafficLights,
  },
})
export default class OtProjectsTable extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private projects!: Project[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;
  private page = 1;
  private searchText = '';

  private tableColumns: IExtendedColumnData<Project>[] = [
    {
      index: 0,
      label: 'Project Name',
      key: 'name',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: Project.compareByName,
    },
    {
      index: 1,
      label: 'Project Health',
      key: 'trafficLights',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Project.compareByTrafficLights,
    },
    {
      index: 2,
      label: 'Contracts',
      key: 'contracts',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Project.compareByContracts,
    },
    {
      index: 3,
      label: 'PC Date',
      key: 'adjustedProjectCompletionDate',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Project.compareByDate,
    },
    {
      index: 4,
      label: 'Status',
      key: 'status',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Project.compareByStatus,
    },
    {
      index: 5,
      label: '',
      key: 'actionMenu',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];
  private rowLoadingStates: { [key: string]: boolean } = {};

  private statusFilters: CheckboxGroupItem<OtProjectStatus>[] = [
    new CheckboxGroupItem('Active', OtProjectStatus.Active),
    new CheckboxGroupItem('Pending', OtProjectStatus.Pending),
    new CheckboxGroupItem('Suspended', OtProjectStatus.Suspended),
    new CheckboxGroupItem('Complete', OtProjectStatus.Complete),
  ];

  private openStatusFilters: CheckboxGroupItem<OtSegmentStatus>[] = [
    new CheckboxGroupItem('In Progress', OtSegmentStatus.InProgress),
    new CheckboxGroupItem('Suspended', OtSegmentStatus.Suspended),
    new CheckboxGroupItem('Info Requested & Not Suspended', OtSegmentStatus.InfoRequestedAndNotSuspended),
    new CheckboxGroupItem('Info Requested & Suspended', OtSegmentStatus.InfoRequestedAndSuspended),
  ];

  private claimStatusFilters: CheckboxGroupType<OtSegmentStatus, OtSegmentResolution>[] = [
    new CheckboxGroupSet<OtSegmentStatus, OtSegmentResolution>('Open', this.openStatusFilters),
    new CheckboxGroupSet('Closed', [
      new CheckboxGroupSet<OtSegmentStatus, OtSegmentResolution>('Complete', [
        new CheckboxGroupItem('Accepted', OtSegmentResolution.Accepted),
        new CheckboxGroupItem('Rejected', OtSegmentResolution.Rejected),
      ]),
      new CheckboxGroupItem('Withdrawn', OtSegmentStatus.Withdrawn),
      new CheckboxGroupItem('Cancelled', OtSegmentStatus.Cancelled),
      new CheckboxGroupItem('Legal Dispute', OtSegmentStatus.LegalDispute),
      new CheckboxGroupItem('Disputed', OtSegmentStatus.Disputed),
    ]),
  ];

  private selectedStatusFilters: CheckboxGroupItem<OtProjectStatus>[] = [];
  private selectedClaimStatusFilters: CheckboxGroupItem<OtSegmentStatus>[] = [];

  private activeColumn = this.tableColumns[0];

  // * COMPUTED

  private get sortedBodyData() {
    return sortTableData(this.tableColumns, this.activeColumn, this.projects);
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedStatusFilters.map(s => s.value);
    const parsedClaimStatuses = this.selectedClaimStatusFilters.map(s => s.value);

    return this.sortedBodyData.filter(row => {
      return (
        Project.filterByClaimStatus(parsedClaimStatuses, row) &&
        Project.filterByStatus(parsedStatuses, row) &&
        Project.filterByKeyword(this.searchText, row)
      );
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS
  @Watch('projects', { deep: true })
  private projectsChanged() {
    this.setRowLoadingStates();
  }

  // * METHODS

  private handleSetActive = (project: Project): IMenuOption => {
    return { key: project.gid, label: 'Set to active', onClick: this.setProjectToActive };
  };

  private handleSetPending = (project: Project): IMenuOption => {
    return { key: project.gid, label: 'Set to pending', onClick: this.setProjectToPending };
  };

  private handleSetComplete = (project: Project): IMenuOption => {
    return { key: project.gid, label: 'Set to complete', onClick: this.setProjectToComplete };
  };

  private handleSetSuspended = (project: Project): IMenuOption => {
    return { key: project.gid, label: 'Set to suspended', onClick: this.setProjectToSuspended };
  };

  private getProjectOptionMenuItems(project: Project): IMenuOption[] {
    const results: IMenuOption[] = [];
    // parse the org user status and the project user status
    const orgUserIsActive = project.organisationUser?.status === OtUserStatus.Active;
    const projectUserIsActive = project.projectUser?.status === OtUserStatus.Active;

    // If the current user does not have an Active OrganisationUser or Active ProjectUser entry then
    // return an empty array because they don;t have access to this project
    if (!(orgUserIsActive || projectUserIsActive)) {
      return results;
    }

    if (project.status !== OtProjectStatus.Active) {
      results.push(this.handleSetActive(project));
    }
    if (project.status !== OtProjectStatus.Pending) {
      results.push(this.handleSetPending(project));
    }
    if (project.status !== OtProjectStatus.Complete) {
      results.push(this.handleSetComplete(project));
    }
    if (project.status !== OtProjectStatus.Suspended) {
      results.push(this.handleSetSuspended(project));
    }

    return results;
  }

  private getTagStatus(status: OtProjectStatus): TagStatus {
    return { type: OtStatusType.Project, status };
  }

  private getProjectsRoute(project: Project): RawLocation {
    return {
      name: ROUTE_PROJECT_DEFAULT,
      params: { projectGid: project.gid },
    };
  }

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private setProjectToActive(project: Project) {
    if (project.status !== OtProjectStatus.Active) {
      this.setProjectStatus(project, OtProjectStatus.Active);
    }
  }

  private setProjectToSuspended(project: Project) {
    if (project.status !== OtProjectStatus.Suspended) {
      this.setProjectStatus(project, OtProjectStatus.Suspended);
    }
  }

  private setProjectToComplete(project: Project) {
    if (project.status !== OtProjectStatus.Complete) {
      this.setProjectStatus(project, OtProjectStatus.Complete);
    }
  }

  private setProjectToPending(project: Project) {
    if (project.status !== OtProjectStatus.Pending) {
      this.setProjectStatus(project, OtProjectStatus.Pending);
    }
  }
  private setProjectStatus(project: Project, status: OtProjectStatus) {
    this.rowLoadingStates[project.gid] = true;
    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable to change the status of project ${project.name} to ${capitalizeString(
        status.toString().toLowerCase(),
      )}, ${ExecuteApiValue.ValidationError}`,
    };

    const postResponse = executeApi(
      () =>
        this.api
          .projects()
          .postUpdateProjectStatus(
            project.gid,
            new PostUpdateProjectStatusRequestModel({ status: parsedApiProjectStatusEnums[status] }),
          ),
      `Set Project ${project.gid} Status to ${status}`,
      errorOverride,
    );

    postResponse.then(response => {
      if (response.success) {
        this.$emit('reloadProjects', () => {
          this.rowLoadingStates[project.gid] = false;
        });
      } else {
        this.rowLoadingStates[project.gid] = false;
      }
    });
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.projects.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  private formatDate(date: Date | null): string {
    if (date) {
      return formatDate(date);
    }
    return '';
  }

  // * LIFECYCLE
  private created() {
    // This initial dictionary population is required otherwise the
    // value doesn't always bind correctly and the loading could get stuck
    this.setRowLoadingStates();
  }
}
