import { render, staticRenderFns } from "./ot-checkbox.vue?vue&type=template&id=3b783405&scoped=true&"
import script from "./ot-checkbox.vue?vue&type=script&lang=ts&"
export * from "./ot-checkbox.vue?vue&type=script&lang=ts&"
import style0 from "./ot-checkbox.vue?vue&type=style&index=0&id=3b783405&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b783405",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
