



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtSelect from '@/components/global/ot-select.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtSwitch from '@/components/global/ot-switch.vue';
import { OtCalendarStatus } from '@/areas/settings/models/calendar-models';
import { formatISO } from 'date-fns';
import { dirtyFormClass } from '@/utils/validation-utils';
import { IAddressDisplayModel } from '@/components/global/common-models';

interface IExampleObject {
  datepicker1Value: Date | null;
  datepicker2Value: Array<Date>;
  datepicker3Value: Array<Date>;
  selectValue: string | null;
  autocompleteValue: IAutocompleteItem<null> | null;
  addressFieldValue: IAutocompleteItem<IAddressDisplayModel> | null;
  checkboxGroupValue: Array<CheckboxGroupItem<OtCalendarStatus>>;
  radioGroupValue: IRadioGroupOption | null;
  switchValue: boolean;
  textFieldValue: string | null;
  textAreaValue: string | null;
}

class ExampleObject implements IExampleObject {
  public datepicker1Value: Date | null = null;
  public datepicker2Value: Array<Date> = [];
  public datepicker3Value: Array<Date> = [];
  public selectValue: string | null = null;
  public autocompleteValue: IAutocompleteItem<null> | null = null;
  public addressFieldValue: IAutocompleteItem<IAddressDisplayModel> | null = null;
  public checkboxGroupValue: Array<CheckboxGroupItem<OtCalendarStatus>> = [];
  public radioGroupValue: IRadioGroupOption | null = null;
  public switchValue = false;
  public textFieldValue: string | null = null;
  public textAreaValue: string | null = null;

  public constructor(value?: IExampleObject) {
    if (value) {
      Object.assign(this, value);
    }
  }
}

@Component({
  components: {
    OtDatePicker,
    OtSelect,
    OtAutocomplete,
    OtAddressField,
    OtCheckboxGroup,
    OtRadioGroup,
    OtTextField,
    OtTextarea,
    OtSwitch,
  },
})
export default class OtDirtyFormCheckWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private blankOriginalExampleObjectThumbprint = '';
  private blankCurrentExampleObjectThumbprint = '';
  private prefilledOriginalExampleObjectThumbprint = '';
  private prefilledCurrentExampleObjectThumbprint = '';

  private blankExampleObjects = new ExampleObject();
  private prefilledExampleObjects = new ExampleObject({
    datepicker1Value: new Date(),
    datepicker2Value: [new Date(), new Date(), new Date()],
    datepicker3Value: [new Date(), new Date()],
    selectValue: 'Option 2',
    autocompleteValue: { label: 'Option 1', data: null },
    addressFieldValue: {
      label: '66 Brunswick Rd, Unit 2, Brunswick, VIC',
      data: {
        placeId: null,
        addressLine1: '66 Brunswick Rd',
        addressLine2: 'Unit 2',
        suburb: 'Brunswick',
        state: 'Victoria',
        postcode: '3000',
        country: 'Australia',
      },
    },
    checkboxGroupValue: [new CheckboxGroupItem('Active', OtCalendarStatus.Active)],
    radioGroupValue: { label: 'Option 3', key: 'Three' },
    switchValue: true,
    textFieldValue: 'Example Text',
    textAreaValue: 'Example Text',
  });

  private stringOptions: IRadioGroupOption[] = [
    { label: 'Option 1', key: 'One' },
    { label: 'Option 2', key: 'Two' },
    { label: 'Option 3', key: 'Three' },
    { label: 'Option 4', key: 'Four' },
  ];
  private autocompleteOptions: IAutocompleteItem<null>[] = [
    { label: 'Option 1', data: null },
    { label: 'Option 2', data: null },
    { label: 'Option 3', data: null },
    { label: 'Option 4', data: null },
  ];
  private checkboxGroupItems: CheckboxGroupItem<OtCalendarStatus>[] = [
    new CheckboxGroupItem('Active', OtCalendarStatus.Active),
    new CheckboxGroupItem('Inactive', OtCalendarStatus.Inactive),
  ];

  // * COMPUTED
  private get blankFormIsDirty(): boolean {
    return this.blankOriginalExampleObjectThumbprint !== this.blankCurrentExampleObjectThumbprint;
  }

  private get prefilledFormIsDirty(): boolean {
    return this.prefilledOriginalExampleObjectThumbprint !== this.prefilledCurrentExampleObjectThumbprint;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  // * WATCHERS
  @Watch('blankExampleObjects', { deep: true })
  private blankExampleObjectsChanged(val: ExampleObject) {
    this.blankCurrentExampleObjectThumbprint = this.getExampleObjectFingerprint(val);
  }

  @Watch('prefilledExampleObjects', { deep: true })
  private prefilledExampleObjectsChanged(val: ExampleObject) {
    this.prefilledCurrentExampleObjectThumbprint = this.getExampleObjectFingerprint(val);
  }

  // * METHODS
  private getExampleObjectFingerprint(exampleObject: IExampleObject) {
    // take a stringish copy of all the things they can change
    // and be very careful that we always end up with a consistent value regardless of empty string, null, undefined
    const vals = {
      datepicker1Value: exampleObject.datepicker1Value ? formatISO(exampleObject.datepicker1Value) : '',
      datepicker2Value: exampleObject.datepicker2Value.length
        ? exampleObject.datepicker2Value.map(date => formatISO(date)).join(',')
        : '',
      datepicker3Value: exampleObject.datepicker3Value.length
        ? exampleObject.datepicker3Value.map(date => formatISO(date)).join(',')
        : '',
      selectValue: exampleObject.selectValue || '',
      autocompleteValue: exampleObject.autocompleteValue ? exampleObject.autocompleteValue.label : '',
      addressFieldValue: exampleObject.addressFieldValue ? exampleObject.addressFieldValue.label : '',
      checkboxGroupValue: exampleObject.checkboxGroupValue.length
        ? exampleObject.checkboxGroupValue.map(v => `${v.label}:${v.value}`).join(',')
        : '',
      radioGroupValue: exampleObject.radioGroupValue?.toString() || '',
      switchValue: exampleObject.switchValue.toString() || '',
      textFieldValue: exampleObject.textFieldValue || '',
      textAreaValue: exampleObject.textAreaValue || '',
    };
    return JSON.stringify(vals);
  }

  // * LIFECYCLE
  private created() {
    this.blankOriginalExampleObjectThumbprint = this.getExampleObjectFingerprint(this.blankExampleObjects);
    this.blankCurrentExampleObjectThumbprint = this.getExampleObjectFingerprint(this.blankExampleObjects);
    this.prefilledOriginalExampleObjectThumbprint = this.getExampleObjectFingerprint(this.prefilledExampleObjects);
    this.prefilledCurrentExampleObjectThumbprint = this.getExampleObjectFingerprint(this.prefilledExampleObjects);
  }
}
