









































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import { formatDateAndTime } from '@/utils/date-utils';
import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { ProjectUserDetails } from './project-user-models';
import { OtUserAction } from '@/types/action-enums';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtProjectUsersDetails extends Vue {
  // * PROPS
  @Prop() private projectUser!: ProjectUserDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  private get jobTitle() {
    if (!this.projectUser.projectUserStatus) {
      return '';
    }
    return this.projectUser.projectUserStatus?.title;
  }

  private get hasProjectUserStatus() {
    return Boolean(this.projectUser.projectUserStatus);
  }

  private get canEdit(): boolean {
    //const profile = vxm.userProfile.userProfile;
    //const isOrgUser = profile?.organisations?.some(org => org.gid === this.orgId);
    //return Boolean(isOrgUser);
    const supportsEdit = this.projectUser.projectUserStatus?.availableActions.some(a => a === OtUserAction.Edit);
    return supportsEdit ?? false;
  }

  // * WATCHERS

  // * METHODS
  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date);
  }

  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  // * LIFECYCLE
}
