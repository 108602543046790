



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtFormatTextarea from '@/components/global/ot-formattable-textarea.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenTextValue } from '../models/data-driven-result';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtFormatTextarea,
  },
})
export default class WfFormatTextAreaEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * DATA
  private label: string | null = null;
  private hint: string | null = null;
  private placeholder: string | null = null;
  private requiredMessage: string | null = null;

  // * COMPUTED
  private textValuePrivate: string | null = null;
  private get textValue() {
    return this.textValuePrivate;
  }
  private set textValue(val: string | null) {
    this.textValuePrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'TextValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-format-text-field-edit -> result -> ResultType is incorrect. Expected TextValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.textValuePrivate = this.result?.text || null;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: this.textValue || '',
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.hint = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
