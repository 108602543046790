











import { Component, Vue } from 'vue-property-decorator';
import OtCurrencyField from '@/components/global/ot-currency-field.vue';

@Component({
  components: {
    OtCurrencyField,
  },
})
export default class OtCurrencyFieldWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private data = null;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
