import OtClaimWorkflowAndOutcomesIndex from '@/areas/claims/claim-workflow-and-outcomes/index.vue';
import OtClaimDetails from '@/areas/claims/ot-claim-details.vue';
import OtClaimLayout from '@/areas/claims/ot-claim-layout.vue';
import OtClaimsIndex from '@/areas/claims/ot-claims-index.vue';
import OtCreateClaimWizard from '@/areas/claims/ot-create-claim-wizard.vue';
import OtEditClaimDetails from '@/areas/claims/ot-edit-claim-details.vue';
import { BreadcrumbsId } from '@/components/global/common-models';
import { RouteConfig } from 'vue-router';

export const ROUTE_WORKFLOW = 'Workflows';
export const ROUTE_WORKFLOW_DETAILS = 'WorkflowDetails';
export const ROUTE_WORKFLOW_DETAILS_EDIT = 'EditWorkflowDetails';
export const ROUTE_WORKFLOW_ADD = 'AddWorkflow';
export const ROUTE_WORKFLOW_VIEW = 'WorkflowViewWorkflow';

export const claimsRoutes: Array<RouteConfig> = [
  {
    path: '/projects/:projectGid/contracts/:contractGid/workflows/:claimGid',
    component: OtClaimLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: '/projects/:projectGid/contracts/:contractGid/workflows',
      },
      {
        path: 'details',
        name: ROUTE_WORKFLOW_DETAILS,
        component: OtClaimDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/contracts/${BreadcrumbsId.ContractId}/workflows/${BreadcrumbsId.ClaimId}/details`,
        },
      },
      {
        path: 'edit',
        name: ROUTE_WORKFLOW_DETAILS_EDIT,
        component: OtEditClaimDetails,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/contracts/${BreadcrumbsId.ContractId}/workflows/${BreadcrumbsId.ClaimId}/edit`,
        },
      },
      {
        path: 'workflow',
        name: ROUTE_WORKFLOW_VIEW,
        component: OtClaimWorkflowAndOutcomesIndex,
        meta: {
          breadcrumb: `Projects/${BreadcrumbsId.ProjectId}/contracts/${BreadcrumbsId.ContractId}/workflows/${BreadcrumbsId.ClaimId}/workflow`,
        },
      },
    ],
  },
  {
    path: '/workflows',
    name: ROUTE_WORKFLOW,
    component: OtClaimsIndex,
    meta: {
      breadcrumb: 'Workflows',
      requiresAuth: true,
    },
  },
  {
    path: '/workflows/add',
    name: ROUTE_WORKFLOW_ADD,
    component: OtCreateClaimWizard,
    meta: {
      requiresAuth: true,
    },
  },
];
