












import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectUser } from './project-user-models';
import { ProjectDetails } from '../project-models';

import OtProjectUsersTable from './ot-project-users-table.vue';

@Component({
  components: {
    OtProjectUsersTable,
  },
})
export default class OtProjectUsersIndex extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private projectUsers!: ProjectUser[];
  @Prop() private project!: ProjectDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
