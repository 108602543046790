
























import { IWizardContentComponent } from '@/types/wizard-types';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { SegmentInstanceDetails } from './claims-models';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';

@Component({
  components: {
    OtWorkflowEngineProcessor,
  },
})
export default class OtDataDrivenClaimForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;
  @Prop() private definition!: OtDataDrivenDefinition;
  @Prop() private instance!: OtDataDrivenInstance;
  @Prop() private evaluation!: OtClientEvaluation;
  @Prop() private parentInstances?: OtDataDrivenInstances;
  @Prop() private instances?: OtDataDrivenInstances;
  @Prop() private layoutKey!: string;
  @Prop({ default: false }) private isSaving!: boolean;

  // * REFS
  @Ref('workflowEngineProcessorRef') private readonly workflowEngineProcessorRef!: OtWorkflowEngineProcessor;

  // * DATA

  // * COMPUTED
  private get localInstance() {
    return this.instance;
  }
  private set localInstance(val: OtDataDrivenInstance) {
    this.$emit('update:instance', val);
  }

  // * WATCHERS

  // * METHODS
  public validate() {
    return this.workflowEngineProcessorRef.validate();
  }

  public setFormToCleanState() {
    this.workflowEngineProcessorRef.setFormToCleanState();
  }

  public async submit(validate = true): Promise<OtDataDrivenInstance | null> {
    return await this.workflowEngineProcessorRef.submit(validate);
  }

  // * LIFECYCLE
}
