


























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import OtButton from '@/components/global/ot-button.vue';
import { AvailableDisplayModeComponents, AvailableEditModeComponents } from '@/wf-components/workflow-components';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { IDataDrivenDefinition, OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { IDataDrivenInstance, OtDataDrivenInstance } from '@/wf-components/models/data-driven-instance';
import ContrackWorkflowDefinition from '@/components/workflow-engine/example-data/contract-workflow-definition';
import _ from 'lodash';

@Component({
  components: {
    OtButton,
    OtCheckbox,
    OtWorkflowEngineProcessor,
  },
})
export default class WorkflowEngineWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private instanceJsonKey = uuid();
  private componentKey = uuid();
  private jsonEditorOptions = {
    mode: 'code',
  };
  private isInDisplayMode = false;

  private definition = ContrackWorkflowDefinition;

  private instance = _.cloneDeep(this.definition.defaultResponses);

  // * COMPUTED
  private get availableEditComponents() {
    return AvailableEditModeComponents.map(c => c.replace(/^Wf/, ''));
  }

  private get availableDisplayComponents() {
    return AvailableDisplayModeComponents.map(c => c.replace(/^Wf/, ''));
  }

  private get layoutKey() {
    if (this.definition.layoutsOrdered[0]) {
      return this.definition.layoutsOrdered[0].key;
    }
    console.warn('workflow-engine-wrapper -> layoutKey:  No page layouts found');
    return '';
  }

  // * WATCHERS
  @Watch('question')
  private questionChanged() {
    this.reloadComponent();
  }

  // * METHODS
  private reloadComponent() {
    this.componentKey = uuid();
  }

  private updateJsonOutput() {
    this.instanceJsonKey = uuid();
  }

  private setDefinition(object: IDataDrivenDefinition) {
    this.definition = OtDataDrivenDefinition.createFromJson(object);
  }

  private setInstance(object: IDataDrivenInstance) {
    this.instance = OtDataDrivenInstance.createFromJson(object);
  }

  // * LIFECYCLE
}
