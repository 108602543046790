import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import {
  FailureTestClient,
  UserClient,
  CalendarClient,
  OneTrackDbContextScenarioClient,
  OrganisationClient,
  ContractsClient,
  ProjectsClient,
  ProblemDetails,
  TemporaryStorageClient,
  ContractTypesClient,
  SegmentInstancesClient,
  ContractWorkflowsClient,
  OrganisationUsersClient,
  ContractUsersClient,
  ProjectUsersClient,
  InvitationsClient,
} from '@/services/generated/api';
import ApiResponse from './api-models';
import { vxm } from '@/store';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import AuthService from './auth.service';

export default class OtApi {
  public calendar() {
    return new CalendarClient(process.env.VUE_APP_API_URL, axios);
  }
  public contracts() {
    return new ContractsClient(process.env.VUE_APP_API_URL, axios);
  }
  public contractTypes() {
    return new ContractTypesClient(process.env.VUE_APP_API_URL, axios);
  }
  public contractUser() {
    return new ContractUsersClient(process.env.VUE_APP_API_URL, axios);
  }
  public contractWorkflows() {
    return new ContractWorkflowsClient(process.env.VUE_APP_API_URL, axios);
  }
  public failureTest() {
    return new FailureTestClient(process.env.VUE_APP_API_URL, axios);
  }
  public invitations() {
    return new InvitationsClient(process.env.VUE_APP_API_URL, axios);
  }
  public organisation() {
    return new OrganisationClient(process.env.VUE_APP_API_URL, axios);
  }
  public organisationUser() {
    return new OrganisationUsersClient(process.env.VUE_APP_API_URL, axios);
  }
  public projects() {
    return new ProjectsClient(process.env.VUE_APP_API_URL, axios);
  }
  public projectUser() {
    return new ProjectUsersClient(process.env.VUE_APP_API_URL, axios);
  }
  public scenario() {
    return new OneTrackDbContextScenarioClient(process.env.VUE_APP_API_URL, axios);
  }
  public segmentInstances() {
    return new SegmentInstancesClient(process.env.VUE_APP_API_URL, axios);
  }
  public temporaryStorage() {
    return new TemporaryStorageClient(process.env.VUE_APP_API_URL, axios);
  }
  public user() {
    return new UserClient(process.env.VUE_APP_API_URL, axios);
  }
}

export function CreateErrorSnackbar(message: string | string[]) {
  vxm.snackbar.addToSnackbarQueue(
    new SnackbarItem({
      type: SnackbarTypeEnum.Error,
      message,
      displayTime: 16000,
    }),
  );
}

export interface IApiErrorOverride {
  status: number;
  message: string | string[];
}

export enum ExecuteApiValue {
  ValidationError = '_VALIDATIONERROR_',
  ApiName = '_APINAME_',
}

function insertApiValues(value: string | string[], apiName: string, validationMessages: string[]): string | string[] {
  const regex = new RegExp(`(${ExecuteApiValue.ValidationError})|(${ExecuteApiValue.ApiName})`, 'g');

  const replaceText = (text: string) => {
    return text.replace(regex, c => {
      switch (c) {
        case ExecuteApiValue.ApiName:
          return apiName;
        case ExecuteApiValue.ValidationError:
          return validationMessages.join(', ');
        default:
          return c;
      }
    });
  };

  let message: string | string[] = '';

  if (Array.isArray(value)) {
    message = value.map(v => replaceText(v));
  } else {
    message = replaceText(value);
  }
  return message;
}

export async function executeApi<T>(
  call: () => Promise<T>,
  apiName: string,
  snackbarOverrides?: IApiErrorOverride | IApiErrorOverride[],
  options: { attemptSilentLoginOn401AndRetry?: boolean; handleError?: (error: ApiResponse<T>) => boolean } = {
    attemptSilentLoginOn401AndRetry: true,
    handleError: () => false,
  },
): Promise<ApiResponse<T>> {
  // nested try blocks so we can have a second attempt at the api call
  try {
    try {
      const result = await call();
      return ApiResponse.CreateSuccessWithData(result);
    } catch (error) {
      console.error(`api.service -> executeApi -> ${apiName} -> Outer Error:  `, error);
      // on the inner, we'll look for certain errors and retry on them
      // we really don't know what type the error is. Something won't let me type it as :any, so we'll do it this way
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apiResponse = ApiResponse.CreateFromError<T>(error as any);

      if (apiResponse.status === 401 && options.attemptSilentLoginOn401AndRetry !== false) {
        // this one is one we want to retry after a silent login attempt
        const auth = new AuthService();
        const user = await auth.silentLogin();
        if (user) {
          // The silent login probably worked. Give it another red hot go
          // if this one fails, we'll get to the outer catch
          const result = await call();
          return ApiResponse.CreateSuccessWithData(result);
        } else {
          // probably didn't work. Piff the first error out to the outer catch for proper handling
          throw error;
        }
      } else {
        // piff it back out to the outer catch for proper proper handling
        throw error;
      }
    }
  } catch (error) {
    console.error(`api.service -> executeApi -> ${apiName} -> Inner error:  `, error);
    // we really don't know what type the error is. Something won't let me type it as :any, so we'll do it this way
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiResponse = ApiResponse.CreateFromError<T>(error as any);

    if (options.handleError) {
      const wasHandled = options.handleError(apiResponse);
      if (wasHandled) {
        // we're done here. The caller has said they'll deal with it
        return apiResponse;
      }
    }

    const errorMessages: string[] = apiResponse.errors.map(e => e.message);

    if (snackbarOverrides) {
      if (Array.isArray(snackbarOverrides)) {
        for (const override of snackbarOverrides) {
          if (apiResponse.status && apiResponse.status === override.status) {
            const message = insertApiValues(override.message, apiName, errorMessages);
            CreateErrorSnackbar(message);
            return apiResponse;
          }
        }
      } else {
        if (apiResponse.status && apiResponse.status === snackbarOverrides.status) {
          const message = insertApiValues(snackbarOverrides.message, apiName, errorMessages);
          CreateErrorSnackbar(message);
          return apiResponse;
        }
      }
    }

    if (apiResponse.status) {
      switch (apiResponse.status) {
        case 400:
          CreateErrorSnackbar(`${apiName} Failed, If this is a reoccurring error please contact us`);
          break;
        case 401:
          CreateErrorSnackbar(`Unable to authorise request, Please refresh the page and try again`);
          break;
        case 403:
          CreateErrorSnackbar(`Unable to ${apiName}, if you think this is a mistake please contact us`);
          break;
        case 404:
          CreateErrorSnackbar(
            `${apiName} is unable to find what you are looking for, if you think this is a mistake please contact us`,
          );
          break;
        case 409:
          CreateErrorSnackbar(`Unable to ${apiName}. Please refresh the page and try again`);
          break;
        case 422:
          CreateErrorSnackbar(`${apiName} returned a validation error: ${errorMessages.join(', ')}`);
          break;
        case 500:
          CreateErrorSnackbar(`Server is unable to ${apiName}, if this is a reoccurring issue please contact us`);
          break;
        default:
          CreateErrorSnackbar(`${apiName} returned an unexpected server error, please try again later`);
          break;
      }
    } else {
      // Usually means it's a 500 error
      if (error instanceof ProblemDetails) {
        CreateErrorSnackbar(`Server is unable to ${apiName}, please try again later`);
      } else {
        CreateErrorSnackbar(
          `Unable to communicate to the server, please check your internet connection and try again later`,
        );
      }
    }

    return apiResponse;
  }
}
