import { render, staticRenderFns } from "./ot-user-status-tags.vue?vue&type=template&id=7dab4f9f&scoped=true&"
import script from "./ot-user-status-tags.vue?vue&type=script&lang=ts&"
export * from "./ot-user-status-tags.vue?vue&type=script&lang=ts&"
import style0 from "./ot-user-status-tags.vue?vue&type=style&index=0&id=7dab4f9f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dab4f9f",
  null
  
)

export default component.exports