// Data Driven Question Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2373091380/Data+Driven+Question+Object

import { DataDrivenQuestionModel as ApiDataDrivenQuestionModel } from '@/services/generated/api';
import Handlebars from 'handlebars';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenColumnHeader } from './data-driven-column-header';
import { OtDataDrivenConfig } from './data-driven-config';
import { OtDataDrivenDependsOn } from './data-driven-depends-on';
import { OtDataDrivenOption } from './data-driven-option';
import { OtDataDrivenSubOption } from './data-driven-sub-option';
import { OTDataDrivenSyncFromQuestion } from './data-driven-sync-with-question';

export interface IDataDrivenQuestion {
  key: string;
  orderIndex: number;
  readonly title: string | null;
  readonly description: string | null;
  editTemplate: string;
  displayTemplate: string;
  columnHeaders: OtDataDrivenColumnHeader[] | null;
  isMandatory: boolean;
  dependsOn: OtDataDrivenDependsOn | null;
  syncFromQuestion: OTDataDrivenSyncFromQuestion | null;
  placeholder: string | null;
  addButtonText: string | null;
  notesTitle: string | null;
  notesPlaceholder: string | null;
  indentLevel: number;
  options: Array<OtDataDrivenOption> | null;
  subOptions: Array<OtDataDrivenSubOption> | null;
  selectedOptionsFromQuestionKeys: string[] | null;
  ignoreSelectedOptionsFromQuestionKeys: string[] | null;
  isLabelHidden: boolean;
  notesDescription: string | null;
  configs: Array<OtDataDrivenConfig> | null;
}

export class OtDataDrivenQuestion implements IDataDrivenQuestion {
  public key!: string;
  public orderIndex!: number;
  public editTemplate!: string;
  public displayTemplate!: string;
  public columnHeaders!: OtDataDrivenColumnHeader[] | null;
  public isMandatory!: boolean;
  public dependsOn!: OtDataDrivenDependsOn | null;
  public syncFromQuestion!: OTDataDrivenSyncFromQuestion | null;
  public placeholder!: string | null;
  public addButtonText!: string | null;
  public notesTitle!: string | null;
  public notesPlaceholder!: string | null;
  public indentLevel!: number;
  public options!: OtDataDrivenOption[] | null;
  public subOptions!: OtDataDrivenSubOption[] | null;
  public selectedOptionsFromQuestionKeys!: string[] | null;
  public ignoreSelectedOptionsFromQuestionKeys!: string[] | null;
  public isLabelHidden!: boolean;
  public notesDescription!: string | null;
  public configs!: OtDataDrivenConfig[] | null;

  // TODO consider some kind of somethingsomething that we can reuse to do this. One for title, one for description
  // do the setup, run teh template, etc. For now, I'm going to copy paste and move on
  private titleOriginal: string | null = null;
  private titleTemplate: HandlebarsTemplateDelegate | null = null;
  private titlePrivate: string | null = null;
  public get title() {
    return this.titlePrivate;
  }
  // well, this sucks. Typescript 3 does not allow this. vs code doesn't know this
  // private set title(val: string | null) {
  //   this.titlePrivate = val;
  // }

  private descriptionOriginal: string | null = null;
  private descriptionTemplate: HandlebarsTemplateDelegate | null = null;
  private descriptionPrivate: string | null = null;
  public get description() {
    return this.descriptionPrivate;
  }
  // private set description(val: string | null) {
  //   this.descriptionPrivate = val;
  // }

  constructor(value: IDataDrivenQuestion) {
    // oh come on now, this is getting silly
    // due to being able to not have a private setter, we cannot even try and object.assign. GAH.
    const { title, description, ...rest } = { ...value };

    Object.assign(this, rest);
    // due to being able to not have a private setter, we have to manually copy these over. What a hassle
    this.titlePrivate = title;
    this.descriptionPrivate = description;

    this.titleOriginal = title;
    this.descriptionOriginal = description;

    // Pretty simple approach to seeing "does it smell like a template"
    if (this.titleOriginal?.includes('{{')) {
      this.titleTemplate = Handlebars.compile(this.titleOriginal);
    }
    if (this.descriptionOriginal?.includes('{{')) {
      this.descriptionTemplate = Handlebars.compile(this.descriptionOriginal);
    }
  }

  public runTemplates(contextObject: unknown) {
    if (this.titleTemplate) {
      this.titlePrivate = this.titleTemplate(contextObject);
    }
    if (this.descriptionTemplate) {
      this.descriptionPrivate = this.descriptionTemplate(contextObject);
    }
  }

  public get columnHeadersOrdered() {
    const columnHeadersOrdered = [...(this.columnHeaders || [])];
    columnHeadersOrdered.sort((a, b) => a.columnIndex - b.columnIndex);
    return columnHeadersOrdered;
  }

  public get optionsOrdered() {
    const optionsOrdered = [...(this.options || [])];
    optionsOrdered.sort((a, b) => a.orderIndex - b.orderIndex);
    return optionsOrdered;
  }

  public static createFromApiResponse(model: ApiDataDrivenQuestionModel) {
    return new OtDataDrivenQuestion({
      key: model.key,
      orderIndex: model.orderIndex,
      title: model.title || null,
      description: model.description || null,
      editTemplate: model.editTemplate,
      displayTemplate: model.displayTemplate,
      columnHeaders: model.columnHeaders
        ? model.columnHeaders.map(ch => OtDataDrivenColumnHeader.createFromApiResponse(ch))
        : null,
      isMandatory: model.isMandatory,
      dependsOn: model.dependsOn ? OtDataDrivenDependsOn.createFromApiResponse(model.dependsOn) : null,
      syncFromQuestion: model.syncFromQuestion
        ? OTDataDrivenSyncFromQuestion.createFromApiResponse(model.syncFromQuestion)
        : null,
      placeholder: model.placeholder || null,
      addButtonText: model.addButtonText || null,
      notesTitle: model.notesTitle || null,
      notesPlaceholder: model.notesPlaceholder || null,
      indentLevel: model.indentLevel,
      options: model.options?.map(o => OtDataDrivenOption.createFromApiResponse(o)) || null,
      subOptions: model.subOptions?.map(o => OtDataDrivenSubOption.createFromApiResponse(o)) || null,
      selectedOptionsFromQuestionKeys: model.selectedOptionsFromQuestionKeys || null,
      ignoreSelectedOptionsFromQuestionKeys: model.ignoreSelectedOptionsFromQuestionKeys || null,
      isLabelHidden: model.isLabelHidden || false,
      notesDescription: model.notesDescription || null,
      configs: model.configs?.map(o => OtDataDrivenConfig.createFromApiResponse(o)) || null,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenQuestion>) {
    return new OtDataDrivenQuestion({
      key: json.key || uuid(),
      orderIndex: json.orderIndex || 0,
      title: json.title || null,
      description: json.description || null,
      editTemplate: json.editTemplate || '',
      displayTemplate: json.displayTemplate || '',
      columnHeaders: json.columnHeaders
        ? json.columnHeaders.map(ch => OtDataDrivenColumnHeader.createFromJson(ch))
        : null,
      isMandatory: json.isMandatory || false,
      dependsOn: json.dependsOn ? OtDataDrivenDependsOn.createFromJson(json.dependsOn) : null,
      syncFromQuestion: json.syncFromQuestion
        ? OTDataDrivenSyncFromQuestion.createFromJson(json.syncFromQuestion)
        : null,
      placeholder: json.placeholder || null,
      addButtonText: json.addButtonText || null,
      notesTitle: json.notesTitle || null,
      notesPlaceholder: json.notesPlaceholder || null,
      indentLevel: json.indentLevel || 0,
      options: json.options?.map(o => OtDataDrivenOption.createFromJson(o)) || null,
      subOptions: json.subOptions?.map(o => OtDataDrivenSubOption.createFromJson(o)) || null,
      selectedOptionsFromQuestionKeys: json.selectedOptionsFromQuestionKeys || null,
      ignoreSelectedOptionsFromQuestionKeys: json.ignoreSelectedOptionsFromQuestionKeys || null,
      isLabelHidden: json.isLabelHidden || false,
      notesDescription: json.notesDescription || null,
      configs: json.configs?.map(o => OtDataDrivenConfig.createFromJson(o)) || null,
    });
  }
}
