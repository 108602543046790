import { parsedOtOrganisationEnums } from '@/areas/profile/models';
import { StreetAddressModel } from '@/models/shared-models';
import {
  AddressModel,
  GetOrganisationDetailsResponseModel,
  OrganisationType as ApiOrganisationType,
  PostUpdateOrganisationDetailsRequestModel,
} from '@/services/generated/api';
import { OtOrganisationType } from '@/types/organisation-enums';
import { OtOrganisationStatus } from '@/types/status-enums';

export const parsedOtOrganisationTypeEnums: { [key in ApiOrganisationType]: OtOrganisationType } = {
  [ApiOrganisationType.NotSet]: OtOrganisationType.NotSet,
  [ApiOrganisationType.Principal]: OtOrganisationType.Principal,
  [ApiOrganisationType.HeadContractor]: OtOrganisationType.HeadContractor,
  [ApiOrganisationType.ProjectManager]: OtOrganisationType.ProjectManager,
  [ApiOrganisationType.Subcontractor]: OtOrganisationType.Subcontractor,
  [ApiOrganisationType.DevelopmentManagementConsultancy]: OtOrganisationType.DevelopmentManagementConsultancy,
};

export const parsedApiOrganisationTypeEnums: { [key in OtOrganisationType]: ApiOrganisationType } = {
  [OtOrganisationType.NotSet]: ApiOrganisationType.NotSet,
  [OtOrganisationType.Principal]: ApiOrganisationType.Principal,
  [OtOrganisationType.HeadContractor]: ApiOrganisationType.HeadContractor,
  [OtOrganisationType.ProjectManager]: ApiOrganisationType.ProjectManager,
  [OtOrganisationType.Subcontractor]: ApiOrganisationType.Subcontractor,
  [OtOrganisationType.DevelopmentManagementConsultancy]: ApiOrganisationType.DevelopmentManagementConsultancy,
};

export const OtOrganisationTypesMap = new Map<OtOrganisationType, string>([
  [OtOrganisationType.NotSet, 'Not Set'],
  [OtOrganisationType.Principal, 'Principal'],
  [OtOrganisationType.HeadContractor, 'Head Contractor'],
  [OtOrganisationType.ProjectManager, 'Project Manager'],
  [OtOrganisationType.Subcontractor, 'Subcontractor'],
  [OtOrganisationType.DevelopmentManagementConsultancy, 'Development Management Consultancy'],
]);

export interface IOrganisation {
  gid: string;
  name: string;
  code: string;
  abn: string;
  acn: string | null;
  arbn: string | null;
  streetAddress: StreetAddressModel;
  postalAddress: StreetAddressModel;
  organisationType: OtOrganisationType;
  organisationStatus: OtOrganisationStatus;
  contactName: string;
  contactJobTitle: string;
  contactMobile: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  phone: string;
  fax: string | undefined;
  email: string;
  activeUserCount: number;
  timezone: string;
}

export class Organisation implements IOrganisation {
  gid!: string;
  name!: string;
  code!: string;
  abn!: string;
  acn: string | null = null;
  arbn: string | null = null;
  streetAddress!: StreetAddressModel;
  postalAddress!: StreetAddressModel;
  organisationType!: OtOrganisationType;
  organisationStatus!: OtOrganisationStatus;
  contactName!: string;
  contactJobTitle!: string;
  contactMobile!: string;
  contactPhone!: string;
  contactEmail!: string;
  contactFax!: string;
  phone!: string;
  fax!: string;
  email!: string;
  activeUserCount!: number;
  timezone!: string;

  public constructor(value: IOrganisation) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(response: GetOrganisationDetailsResponseModel): Organisation {
    return new Organisation({
      gid: response.gid || '',
      name: response.name || '',
      code: response.code || '',
      abn: response.abn || '',
      acn: response.acn ?? null,
      arbn: response.arbn ?? null,
      streetAddress: StreetAddressModel.createFromApiResponse(response.streetAddress),
      postalAddress: StreetAddressModel.createFromApiResponse(response.postalAddress),
      contactName: response.contactName || '',
      organisationType: response.organisationType
        ? parsedOtOrganisationTypeEnums[response.organisationType]
        : OtOrganisationType.NotSet,
      organisationStatus: response.organisationStatus
        ? parsedOtOrganisationEnums[response.organisationStatus]
        : OtOrganisationStatus.Active,
      contactJobTitle: response.contactJobTitle || '',
      contactMobile: response.contactMobile || '',
      contactPhone: response.contactPhone || '',
      contactEmail: response.contactEmail || '',
      contactFax: response.contactFax || '',
      phone: response.phone || '',
      fax: response.fax || '',
      email: response.email || '',
      activeUserCount: response.activeUserCount,
      timezone: response.timezone,
    });
  }
}

export interface IOrganisationDetailsFormObject {
  name: string | null;
  code: string | null;
  abn: string | null;
  acn: string | null;
  arbn: string | null;
  streetAddress: StreetAddressModel | null;
  postalAddress: StreetAddressModel | null;
  organisationType: OtOrganisationType | null;
  contactName: string | null;
  contactJobTitle: string | null;
  contactMobile: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
  contactFax: string | null;
  phone: string | null;
  fax: string | null;
  email: string | null;
  timezone: string | null;
}

export class OrganisationDetailsFormObject implements IOrganisationDetailsFormObject {
  public name!: string;
  public code!: string;
  public abn!: string;
  public acn: string | null = null;
  public arbn: string | null = null;
  public streetAddress!: StreetAddressModel;
  public postalAddress!: StreetAddressModel;
  public organisationType!: OtOrganisationType;
  public contactName!: string;
  public contactJobTitle!: string;
  public contactMobile!: string;
  public contactPhone!: string;
  public contactEmail!: string;
  public contactFax!: string;
  public phone!: string;
  public fax!: string;
  public email!: string;
  public timezone!: string | null;

  public constructor(value: IOrganisationDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OrganisationDetailsFormObject({
      name: '',
      code: '',
      abn: '',
      acn: null,
      arbn: null,
      streetAddress: StreetAddressModel.createEmpty(),
      postalAddress: StreetAddressModel.createEmpty(),
      organisationType: OtOrganisationType.NotSet,
      contactName: '',
      contactJobTitle: '',
      contactMobile: '',
      contactPhone: '',
      contactEmail: '',
      contactFax: '',
      phone: '',
      fax: '',
      email: '',
      timezone: null,
    });
  }
  public static createFromApiResponse(response: GetOrganisationDetailsResponseModel): OrganisationDetailsFormObject {
    return new OrganisationDetailsFormObject({
      name: response.name || '',
      code: response.code || '',
      abn: response.abn || '',
      acn: response.acn ?? null,
      arbn: response.arbn ?? null,
      streetAddress: StreetAddressModel.createFromApiResponse(response.streetAddress),
      postalAddress: StreetAddressModel.createFromApiResponse(response.postalAddress),
      contactName: response.contactName || '',
      organisationType: response.organisationType
        ? parsedOtOrganisationTypeEnums[response.organisationType]
        : OtOrganisationType.NotSet,
      contactJobTitle: response.contactJobTitle || '',
      contactMobile: response.contactMobile || '',
      contactPhone: response.contactPhone || null,
      contactEmail: response.contactEmail || '',
      contactFax: response.contactFax || '',
      phone: response.phone || '',
      fax: response.fax || '',
      email: response.email || '',
      timezone: response.timezone || null,
    });
  }

  public static createRequestModel(model: OrganisationDetailsFormObject): PostUpdateOrganisationDetailsRequestModel {
    return new PostUpdateOrganisationDetailsRequestModel({
      name: model.name,
      code: model.code,
      abn: model.abn,
      acn: model.acn ?? undefined,
      arbn: model.arbn ?? undefined,
      streetAddress: new AddressModel({
        line1: model.streetAddress.addressLine1,
        line2: model.streetAddress.addressLine2 || undefined,
        suburb: model.streetAddress.suburb,
        postcode: model.streetAddress.postcode,
        state: model.streetAddress.state,
        country: model.streetAddress.country,
      }),
      postalAddress: new AddressModel({
        line1: model.postalAddress.addressLine1,
        line2: model.postalAddress.addressLine2 || undefined,
        suburb: model.postalAddress.suburb,
        postcode: model.postalAddress.postcode,
        state: model.postalAddress.state,
        country: model.postalAddress.country,
      }),
      organisationType: model.organisationType
        ? parsedApiOrganisationTypeEnums[model.organisationType]
        : ApiOrganisationType.NotSet,
      contactName: model.contactName,
      contactJobTitle: model.contactJobTitle,
      contactMobile: model.contactMobile,
      contactPhone: model.contactPhone || undefined,
      contactEmail: model.contactEmail,
      contactFax: model.contactFax || undefined,
      phone: model.phone,
      fax: model.fax || undefined,
      email: model.email,
      timezone: model.timezone || undefined,
    });
  }
}
