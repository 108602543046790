import {
  ApplicationInsights,
  DistributedTracingModes,
  IConfig,
  IConfiguration,
  Util,
} from '@microsoft/applicationinsights-web';
import router from '@/router';
import _Vue from 'vue';

export function AppInsightsPlugin(
  vue: typeof _Vue & { appInsights?: ApplicationInsights },
  options: { router?: typeof router; config: IConfiguration & IConfig },
): void {
  // seems this didn't work. Or didn't make us a new trace operation id. Or something. I'm turning it off.
  options.config.enableAutoRouteTracking = false;
  // if we ever need to turn off the settings then we should do some better "if property === undefined"
  // for now, I'm just following https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation
  options.config.disableFetchTracking = false;
  options.config.enableCorsCorrelation = true;
  options.config.enableRequestHeaderTracking = true;
  options.config.enableResponseHeaderTracking = true;
  options.config.correlationHeaderExcludedDomains = ['*.queue.core.windows.net'];
  // https://docs.microsoft.com/en-us/azure/azure-monitor/app/correlation#enable-w3c-distributed-tracing-support-for-web-apps
  options.config.distributedTracingMode = DistributedTracingModes.W3C;

  const appInsights = new ApplicationInsights({ config: options?.config });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

  // tuck it away so we can get to it later. https://github.com/latelierco/vue-application-insights/issues/8 has a lot of words about how
  // we can tuck it away as a $appInsights. I'm not going to do that.
  vue.appInsights = appInsights;

  // as vue handles any js errors itself we need to fire errors to appInsights ourselves
  vue.config.errorHandler = err => {
    // this handler can also return vue specific info
    // this is mainly lifecycle hook info which isn't extremely helpful so I didn't add it to the error
    appInsights.trackException({
      exception: err,
    });
  };

  // router handling inspired by https://github.com/latelierco/vue-application-insights/blob/master/src/index.js
  const baseName = 'OneTrack.UI';

  if (router) {
    router.beforeEach((route, from, next) => {
      const name = baseName + ' / ' + route.name;
      appInsights.context.telemetryTrace.traceID = Util.generateW3CId();
      appInsights.context.telemetryTrace.name = route.name ?? undefined;
      appInsights.startTrackPage(name);
      next();
    });

    router.afterEach(route => {
      const name = baseName + ' / ' + route.name;
      const url = location.protocol + '//' + location.host + route.fullPath;
      appInsights.stopTrackPage(name, url);
      appInsights.flush();
    });
  }

  // we can also try tracking unhandled promises, I wasn't sure of the best way of logging that so I didn't add it.
  // window.addEventListener('unhandledrejection', event => {
  //   appInsights.trackException({
  //     exception: err,
  //   });
  // });
}
