























import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtButton from '@/components/global/ot-button.vue';
import OtCalendarDetailsForm from '@/areas/settings/calendars/ot-calendar-details-form.vue';
import { CalendarDetails } from '../models/calendar-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { ROUTE_SETTINGS_CALENDARS } from '../settings-routes';

@Component({
  components: {
    OtButton,
    OtCalendarDetailsForm,
  },
})
export default class OtAddEditOrganisationCalendar extends Vue {
  // * PROPS
  @Prop() private calendar?: CalendarDetails;
  @Prop() private orgGid!: string;

  // * REFS
  @Ref('addEditCalendarDetailsForm') private addEditCalendarDetailsForm!: OtCalendarDetailsForm;

  // * DATA
  private api = new OtApi();
  private saving = false;

  // * COMPUTED
  private get cancelRoute() {
    return this.calendar
      ? { name: 'ViewCalendar', params: { calendarGid: this.calendar.gid } }
      : { name: ROUTE_SETTINGS_CALENDARS };
  }

  // * WATCHERS

  // * METHODS
  private async saveCalendar() {
    this.saving = true;
    const validatedCalendar = this.addEditCalendarDetailsForm.submit();
    const organisationGid = this.orgGid;

    if (validatedCalendar) {
      const requestModel = CalendarDetails.createRequestModel(validatedCalendar, organisationGid);

      const apiResponse = await executeApi(
        () => this.api.calendar().postCalendarUpsert(validatedCalendar.gid, undefined, requestModel),
        'Save Calendar',
      );

      if (apiResponse.success) {
        this.$router.push({ name: 'ViewCalendar', params: { calendarGid: validatedCalendar.gid } });
      } else {
        this.addEditCalendarDetailsForm.handleApiFailure(apiResponse.errors);
      }
    }
    this.saving = false;
  }

  // * LIFECYCLE
}
