// Data Driven Column Header Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2417393820/Data+Driven+Column+Header+Object

import { DataDrivenColumnHeaderModel as ApiDataDrivenColumnHeaderModel } from '@/services/generated/api';

export interface IDataDrivenColumnHeader {
  title: string; // Title for Column
  columnIndex: number; // Index of column (zero based)
}

export class OtDataDrivenColumnHeader implements IDataDrivenColumnHeader {
  public title!: string;
  public columnIndex!: number;

  constructor(value: IDataDrivenColumnHeader) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenColumnHeaderModel) {
    return new OtDataDrivenColumnHeader({
      title: model.title,
      columnIndex: model.columnIndex,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenColumnHeader>) {
    return new OtDataDrivenColumnHeader({
      title: json.title || '',
      columnIndex: json.columnIndex || 0,
    });
  }
}
