




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils/date-utils';
import styles from '@/styles/variables.scss';
import { OtDueDateStatus } from '@/areas/claims/claims-models';

export interface IStatusOptions {
  dotColor?: string;
  textColor?: string;
  backColor?: string;
}

@Component({})
export default class OtDateTag extends Vue {
  // * PROPS
  @Prop() private date!: Date;
  @Prop() private dueDateStatus!: OtDueDateStatus;
  @Prop() private closedStatusOverrides?: IStatusOptions;
  @Prop() private openStatusOverrides?: IStatusOptions;
  @Prop() private deadlineApproachingStatusOverrides?: IStatusOptions;
  @Prop() private overdueStatusOverrides?: IStatusOptions;
  @Prop({ default: true }) private colourByStatus!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get dateFormatted() {
    return formatDate(this.date);
  }

  private get tagColor(): IStatusOptions {
    if (!this.colourByStatus) {
      return {
        dotColor: styles.otColorTransparent,
        textColor: styles.otColorBlack,
        backColor: styles.otColorTransparent,
      };
    }

    switch (this.dueDateStatus) {
      case OtDueDateStatus.Open:
        return {
          dotColor: this.openStatusOverrides?.dotColor || styles.otColorGreen400,
          textColor: this.openStatusOverrides?.textColor || styles.otColorBlack,
          backColor: this.openStatusOverrides?.backColor || styles.otColorGreen300,
        };
      case OtDueDateStatus.DeadlineApproaching:
        return {
          dotColor: this.deadlineApproachingStatusOverrides?.dotColor || styles.otColorAmber400,
          textColor: this.deadlineApproachingStatusOverrides?.textColor || styles.otColorBlack,
          backColor: this.deadlineApproachingStatusOverrides?.backColor || styles.otColorAmber300,
        };
      case OtDueDateStatus.Overdue:
        return {
          dotColor: this.overdueStatusOverrides?.dotColor || styles.otColorRed400,
          textColor: this.overdueStatusOverrides?.textColor || styles.otColorWhite,
          backColor: this.overdueStatusOverrides?.backColor || styles.otColorRed300,
        };
      case OtDueDateStatus.Closed:
        return {
          dotColor: this.closedStatusOverrides?.dotColor || styles.otColorGrey500,
          textColor: this.closedStatusOverrides?.textColor || styles.otColorBlack,
          backColor: this.closedStatusOverrides?.backColor || styles.otColorGrey200,
        };
    }
    return {};
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
