
























































import { Component, Ref, Vue } from 'vue-property-decorator';
import OtButton from '@/components/global/ot-button.vue';
import OtDurationSelection, { IDurationSelectionObject } from '@/components/global/ot-duration-selection.vue';
import { IVForm } from '@/utils/type-utils';

@Component({
  components: {
    OtButton,
    OtDurationSelection,
  },
})
export default class OtDurationSelectionWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('formRef') private readonly formRef!: IVForm;

  // * DATA
  private items = [{ title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me 2' }];

  private inputValue1: IDurationSelectionObject | null = null;
  private inputValue2: IDurationSelectionObject | null = null;
  private inputValue3: IDurationSelectionObject | null = null;
  private inputValue4: IDurationSelectionObject | null = null;
  private inputValue5: IDurationSelectionObject | null = null;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private validate() {
    this.formRef.validate();
  }

  // * LIFECYCLE
}
