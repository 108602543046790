// Data Driven Response Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2370928957/Data+Driven+Response+Object

import { DataDrivenResponseModel as ApiDataDrivenResponseModel } from '@/services/generated/api';
import { OtDataDrivenResultBase, OtDataDrivenResults } from './data-driven-result';

export interface IDataDrivenResponse {
  questionKey: string;
  client?: unknown; // A place for the UI to put loose data that it needs round tripped.
  result: OtDataDrivenResults; // Result object can be one of several definitions
  systemControlled: boolean;
}

export class OtDataDrivenResponse implements IDataDrivenResponse {
  public questionKey!: string;
  public client?: unknown;
  public result!: OtDataDrivenResults;
  public systemControlled!: boolean;

  constructor(value: IDataDrivenResponse) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenResponseModel) {
    return new OtDataDrivenResponse({
      questionKey: model.questionKey,
      client: model.client,
      result: OtDataDrivenResultBase.createFromApiResponse(model.result),
      systemControlled: model.systemControlled ?? false,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenResponse>) {
    return new OtDataDrivenResponse({
      questionKey: model.questionKey || '',
      client: model.client,
      result: OtDataDrivenResultBase.createFromJson(model.result),
      systemControlled: model.systemControlled ?? false,
    });
  }

  public static createRequestModel(model: OtDataDrivenResponse) {
    return new ApiDataDrivenResponseModel({
      questionKey: model.questionKey,
      client: model.client || undefined,
      result: model.result.createRequestModel(),
    });
  }

  public createRequestModel() {
    return OtDataDrivenResponse.createRequestModel(this);
  }
}

export interface IResponsesGroupedByLayoutKey {
  layoutKey: string;
  responses: OtDataDrivenResponse[];
}
