
































import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

import { IInputTab } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';

import {
  ROUTE_SETTINGS_ORG_USERS,
  ROUTE_SETTINGS_ORG_USERS_CONTRACTS,
  ROUTE_SETTINGS_ORG_USERS_PROJECTS,
} from '../settings-routes';

import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { OrganisationUserDetails } from '../models/organisation-user-models';
import { OrganisationUserProject } from '../models/organisation-user-project-models';
import { OrganisationUserContract } from '../models/organisation-user-contract-models';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
    OtButton,
    OtTag,
  },
})
export default class OtOrganisationUsersLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private loading = false;
  private organisationUser: OrganisationUserDetails = OrganisationUserDetails.createEmpty();
  private organisationUserProjects: OrganisationUserProject[] = [];
  private organisationUserContracts: OrganisationUserContract[] = [];
  private api = new OtApi();

  private get orgUsersTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'User Profile',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_SETTINGS_ORG_USERS,
        },
      },
      {
        tabId: 1,
        tabText: 'User Projects',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_SETTINGS_ORG_USERS_PROJECTS,
        },
      },
      {
        tabId: 2,
        tabText: 'User Contracts',
        tabRoute: {
          params: { userGid: this.userGid },
          name: ROUTE_SETTINGS_ORG_USERS_CONTRACTS,
        },
      },
    ];
  }

  // * COMPUTED

  private get sectionTitle() {
    return this.organisationUser.firstName + ' ' + this.organisationUser.lastName || '';
  }

  private get orgId() {
    return this.$route.params.orgId || '';
  }

  private get orgName() {
    return this.organisationUser.organisationName || '';
  }
  private get userName() {
    return this.organisationUser.firstName + ' ' + this.organisationUser.lastName || '';
  }

  private get userGid() {
    return this.$route.params.userGid || '';
  }

  private get orgStatus(): OtUserStatus {
    const status = this.organisationUser.organisationUserStatus?.status;
    // NOTE - the default here is inactive ?
    return status || OtUserStatus.Inactive;
  }

  private get hasOrgStatus(): boolean {
    return Boolean(this.organisationUser.organisationUserStatus);
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    if (!this.organisationUser || this.organisationUser.gid !== this.userGid) {
      this.loading = true;
      await this.handleReloadOrganisationUser();
      this.setOrgNameToBreadcrumb();
      this.loading = false;
    }
    // if (
    //   this.$route.name === ROUTE_SETTINGS_CALENDARS &&
    //   this.allCalendars.length &&
    //   this.orgId !== this.lastLoadedCalendarsForOrg
    // ) {
    //   this.lastLoadedCalendarsForOrg = this.orgId;
    //   this.loading = true;
    //   await this.handleReloadCalendars();
    //   this.loading = false;
    // }
    // if (this.$route.name === ROUTE_SETTINGS && !this.organisation) {
    //   this.loading = true;
    //   await this.handleReloadOrganisationDetails();
    //   this.loading = false;
    // }
    // if (this.$route.name === ROUTE_SETTINGS && this.organisation && this.organisation.gid !== this.orgId) {
    //   this.loading = true;
    //   await this.handleReloadOrganisationDetails();
    //   this.loading = false;
    // }
    if (this.$route.name === ROUTE_SETTINGS_ORG_USERS_PROJECTS && !this.organisationUserProjects.length) {
      this.loading = true;
      await this.handleReloadOrganisationUserProjects();
      this.loading = false;
    }
    if (this.$route.name === ROUTE_SETTINGS_ORG_USERS_CONTRACTS && !this.organisationUserContracts.length) {
      this.loading = true;
      await this.handleReloadOrganisationUserContracts();
      this.loading = false;
    }
  }

  // * METHODS

  setOrgNameToBreadcrumb() {
    vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
  }
  setUserNameToBreadcrumb() {
    vxm.breadcrumbs.setUserName({ userName: this.userName });
  }
  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  private async handleReloadOrganisationUser(callback?: () => void) {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationUserDetails(this.orgId, this.userGid),
      'Load Organisation User',
    );
    if (result.success && result.data && result.data) {
      this.organisationUser = OrganisationUserDetails.createFromApiResponse(result.data);
      this.setUserNameToBreadcrumb();
    }

    if (callback) {
      callback();
    }
  }

  private async handleReloadOrganisationUserProjects(callback?: () => void) {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationUserProjects(this.orgId, this.userGid),
      'Load Organisation User Projects',
    );
    if (result.success && result.data) {
      this.organisationUserProjects = result.data?.projects?.map(project =>
        OrganisationUserProject.createFromApiResponse(project),
      );
    }

    if (callback) {
      callback();
    }
  }
  private async handleReloadOrganisationUserContracts(callback?: () => void) {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationUserContracts(this.orgId, this.userGid),
      'Load Organisation User Contracts',
    );
    if (result.success && result.data) {
      this.organisationUserContracts = result.data?.contracts?.map(contract =>
        OrganisationUserContract.createFromApiResponse(contract),
      );
    }

    if (callback) {
      callback();
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();

    if (this.orgId) {
      vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
    }
    if (this.userName) {
      vxm.breadcrumbs.setUserName({ userName: this.userName });
    }
  }
}
