














































































































































































import { Project } from '@/areas/projects/project-models';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtDateTimeField from '@/components/global/ot-date-time-field.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { OtDataEntryRequirement } from '@/types/data-entry-requirement-enums';
import { documentReceivalTypes, OtDocumentReceivalType } from '@/types/document-receival-type';
import { OtSegmentInstanceStatusRequirement } from '@/types/segment-instance-status-requirement';
import {
  CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR,
  OtClosedSegmentStatusSet,
  OtOpenSegmentStatusSet,
  OtStatusType,
  PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR,
} from '@/types/status-enums';
import { IWizardContentComponent } from '@/types/wizard-types';
import { ZonelessDate } from '@/types/zoneless-date';
import { compareToBrowserTimeZone, getBrowserTimezone } from '@/utils/date-utils';
import { escapeHTML } from '@/utils/string-utils';
import { IVForm } from '@/utils/type-utils';
import { dirtyFormClass } from '@/utils/validation-utils';
import { utcToZonedTime } from 'date-fns-tz';
import isFuture from 'date-fns/isFuture';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Contract } from '../projects/contracts/contract-models';
import {
  BasicSegment,
  ClaimDetailsFormExternalErrorObject,
  ClaimDetailsFormObject,
  SegmentInstance,
  SegmentInstanceDetails,
  SegmentInstanceDetailsPost,
} from './claims-models';
import { ROUTE_WORKFLOW_ADD } from './claims-routes';

interface IClaimTypeObject {
  workflowName: string;
  workflowGid: string;
  segmentName: string;
  segmentShortName: string;
  segmentContext: string | null;
  segmentGid: string;
  tag: string | null;
  parentRequired: OtDataEntryRequirement;
  validParentCodes: string[];
}

type IClaimTypeAutocompleteItem<T> = IAutocompleteItem<T> & IClaimTypeObject;

interface IWorkflowObject {
  workflowName: string;
  workflowGid: string;
}

type IWorkflowAutocompleteItem<T> = IAutocompleteItem<T> & IWorkflowObject;

enum GenerateClaimTypeLabelOptions {
  includeHtmlElements = 0,
}

interface IParentClaimObject {
  gid: string;
  reference: string;
  segmentCode: string;
}

@Component({
  components: {
    OtAutocomplete,
    OtCheckbox,
    OtTextField,
    OtRadioGroup,
    OtDatePicker,
    OtDateTimeField,
    OtTag,
  },
})
export default class OtClaimDetailsForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private claimDetails?: SegmentInstanceDetails;
  @Prop({ default: false }) private isSaving!: boolean;
  @Prop({ default: false }) private isEditMode!: boolean;
  @Prop() private errors!: ClaimDetailsFormExternalErrorObject;

  // * REFS
  @Ref('claimDetailsFormRef') private readonly claimDetailsFormRef!: IVForm;
  @Ref('claimDetailsFormWrapperRef') private readonly claimDetailsFormWrapperRef!: HTMLDivElement;

  // * DATA
  private formData = ClaimDetailsFormObject.createEmpty();
  private allProjects: Array<IAutocompleteItem<Project>> = [];
  private allContractSegmentInstances: Array<IAutocompleteItem<IParentClaimObject>> = [];
  private allSegmentInstancesRaw: Array<SegmentInstance> = [];
  private allContractWorkflowSegments: Array<IClaimTypeAutocompleteItem<string>> = [];
  private allBasicSegmentsRaw: Array<BasicSegment> = [];
  private allContractWorkflows: Array<IWorkflowAutocompleteItem<string>> = [];
  private allContractsRaw: Array<Contract> = [];
  private api = new OtApi();
  private originalThumbprint = '';
  private currentThumbprint = '';
  private isLoading = false;
  private claimTypeSearchInput: string | null = null;

  private referenceExternalErrorMessages: string[] = [];

  // * COMPUTED

  private get reference() {
    return this.formData.reference;
  }
  private set reference(val: string | null) {
    this.formData.reference = val;
    this.referenceExternalErrorMessages = [];
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  private get documentReceivalTypeOptions(): IRadioGroupOption[] {
    return documentReceivalTypes.map(t => {
      return {
        label: t.name,
        key: t.value,
      };
    });
  }

  private get selectedProject() {
    if (this.formData.projectGid) {
      const foundProject = this.allProjects.find(p => p.data?.gid === this.formData.projectGid);
      return foundProject || null;
    }
    return null;
  }
  private set selectedProject(val: IAutocompleteItem<Project> | null) {
    if (val && val.data) {
      // Reset the selected contract if the selected project changes
      if (val.data?.gid !== this.formData.projectGid) {
        this.selectedContract = null;
      }

      this.formData.projectGid = val.data.gid;
    } else {
      this.formData.projectGid = null;
      this.formData.contractGid = null;
    }
  }

  private get allContractsFiltered(): Array<IAutocompleteItem<Contract>> {
    if (this.selectedProject) {
      return this.allContractsRaw
        .filter(c => c.projectGid === this.selectedProject?.data?.gid && c.status)
        .map(c => {
          return {
            label: c.reference + ': ' + c.name,
            data: c,
            // we've verified on the line above that status is something
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            disabled: !CONTRACT_STATUSES_CAN_CREATE_CLAIMS_FOR.includes(c.status!),
          };
        });
    }
    return [];
  }

  private get selectedContract() {
    if (this.formData.contractGid) {
      const foundContract = this.allContractsFiltered.find(c => c.data?.gid === this.formData.contractGid);
      return foundContract || null;
    }
    return null;
  }
  private set selectedContract(val: IAutocompleteItem<Contract> | null) {
    if (val && val.data) {
      this.formData.contractGid = val.data.gid;
      this.selectedClaimType = null;
      this.getAllContractSegmentInstances(this.formData.contractGid);
      this.getAllContractWorkflowSegments(this.formData.contractGid);
    } else {
      this.formData.contractGid = null;
      this.selectedClaimType = null;
    }
  }

  private get selectedContractRaw() {
    if (this.formData.contractGid) {
      const foundContract = this.allContractsRaw.find(c => c.gid === this.formData.contractGid);
      return foundContract;
    }
    return null;
  }

  private get selectedWorkflowType() {
    if (this.formData.workflowGid) {
      const foundSegment = this.allContractWorkflows.find(s => s.data === this.formData.workflowGid) || null;
      return foundSegment;
    }
    return null;
  }
  private set selectedWorkflowType(val: IWorkflowAutocompleteItem<string> | null) {
    if (val) {
      this.formData.workflowGid = val.data;
    } else {
      this.formData.workflowGid = null;
    }
    // when the workflow changes, clear the claim type
    this.formData.workflowSegmentGid = null;
  }

  private get selectedClaimType() {
    if (this.formData.workflowSegmentGid) {
      return this.allContractWorkflowSegments.find(s => s.data === this.formData.workflowSegmentGid) || null;
    }
    return null;
  }
  private set selectedClaimType(val: IClaimTypeAutocompleteItem<string> | null) {
    if (val) {
      this.formData.workflowSegmentGid = val.data;
      const url = this.$router.resolve({
        name: ROUTE_WORKFLOW_ADD,
        query: { project: this.formData.projectGid, contract: this.formData.contractGid, segment: val.data },
      });
      console.log('ot-claim-details-form -> selectedClaimType -> deep link url', url);
      if (!val.parentRequired) {
        this.selectedParentClaim = null;
      }
    } else {
      this.formData.workflowSegmentGid = null;
      this.selectedParentClaim = null;
    }
  }
  private get selectedClaimTypeRaw() {
    if (this.formData.workflowSegmentGid) {
      return this.allBasicSegmentsRaw.find(x => x.gid === this.formData.workflowSegmentGid) || null;
    }
    return null;
  }

  private get parentClaimRequired() {
    return this.selectedClaimTypeRaw?.config?.parentRequired === OtDataEntryRequirement.Mandatory;
  }
  private get parentClaimForbidden() {
    return this.selectedClaimTypeRaw?.config?.parentRequired === OtDataEntryRequirement.Forbidden;
  }
  private get disableParentClaimEntry() {
    const config = this.selectedClaimTypeRaw?.config;
    if (!config) {
      // don't know what the requirement is, so disable everything
      return true;
    }
    // we know what the requirement is, make sure it's not forbidden
    return config.parentRequired === OtDataEntryRequirement.Forbidden;
  }

  private get parentClaimRequiredMessage() {
    return this.parentClaimRequired ? 'A parent workflow is required for this workflow sub type' : null;
  }

  private get filteredParentClaimItems() {
    // console.log('ot-claim-details-form -> get filteredParentClaimItems', {
    //   config: { ...this.selectedClaimTypeRaw?.config },
    // });
    const statusFilter =
      this.selectedClaimTypeRaw?.config?.parentSegmentInstanceStatusRequirement ===
      OtSegmentInstanceStatusRequirement.MustBeClosed
        ? (s: SegmentInstance) => OtClosedSegmentStatusSet.has(s.status)
        : this.selectedClaimTypeRaw?.config?.parentSegmentInstanceStatusRequirement ===
          OtSegmentInstanceStatusRequirement.MustBeOpen
        ? (s: SegmentInstance) => OtOpenSegmentStatusSet.has(s.status)
        : () => true;

    const codeFilter = this.selectedClaimTypeRaw?.config?.validParentCodes.length
      ? (s: SegmentInstance) =>
          this.selectedClaimTypeRaw?.config?.validParentCodes.includes(s.workflowSegment.shortName)
      : () => true;

    const results = this.allSegmentInstancesRaw
      .filter(statusFilter)
      .filter(codeFilter)
      // This sorts the claims by when they where created, this means if there is ever a case where there are two claims with the same reference and type the more recent one is used.
      .sort((a, b) => a.createdDateUtc.getUTCSeconds() - b.createdDateUtc.getUTCSeconds())
      .map(s => {
        return {
          label: `${s.reference} ${s.workflowSegment.shortName}`,
          data: { gid: s.gid, reference: s.reference, segmentCode: s.workflowSegment.shortName },
        };
      });
    // console.log('ot-claim-details-form -> get filteredParentClaimItems -> after filtering', {
    //   allSegmentInstancesRaw: this.allSegmentInstancesRaw,
    //   allContractSegmentInstances: this.allContractSegmentInstances,
    // });

    return results;
  }

  private get selectedParentClaim() {
    if (this.formData.parentClaimGid) {
      const foundClaim = this.filteredParentClaimItems.find(c => c.data?.gid === this.formData.parentClaimGid);
      return { label: foundClaim?.label || '', data: foundClaim?.data || null };
    }
    return null;
  }
  private set selectedParentClaim(val: IAutocompleteItem<IParentClaimObject> | null) {
    if (val) {
      this.formData.parentClaimGid = val.data?.gid || '';
      // TODO this was handy, the automatic usage of the parent reference in the child. Consider
      // adding maybe the selected claim type and the current date in YYYY-MM-DD form to it
      // or, whatever we're going to do for automatic reference generation
      // for now... it has to be unique, the pre-filling has to go, it's not pit of success material
      // this.formData.reference = val.data?.reference || '';
    } else {
      this.formData.parentClaimGid = null;
    }
  }

  private get documentRequired() {
    return this.selectedClaimTypeRaw?.config?.documentRequiredOnCreation === OtDataEntryRequirement.Mandatory;
  }
  private get documentForbidden() {
    return this.selectedClaimTypeRaw?.config?.documentRequiredOnCreation === OtDataEntryRequirement.Forbidden;
  }
  private get disableDocumentEntry() {
    const config = this.selectedClaimTypeRaw?.config;
    if (!config) {
      // don't know what the requirement is, so disable everything
      return true;
    }
    // we know what the requirement is, make sure it's not forbidden
    return config.documentRequiredOnCreation === OtDataEntryRequirement.Forbidden;
  }

  private get selectedReceivalType() {
    if (this.formData.documentReceivalType) {
      return this.documentReceivalTypeOptions.find(t => t.key === this.formData.documentReceivalType?.value) || null;
    }
    return null;
  }
  private set selectedReceivalType(val: IRadioGroupOption | null) {
    if (val !== null) {
      this.formData.documentReceivalType = documentReceivalTypes.find(t => t.value === val.key) || null;
    } else {
      this.formData.documentReceivalType = null;
    }

    if (this.formData.documentReceivalType !== OtDocumentReceivalType.Other) {
      this.formData.documentReceivalDescription = null;
    }
  }

  private get isReceivalDescriptionDisabled() {
    return this.disableDocumentEntry || this.formData.documentReceivalType !== OtDocumentReceivalType.Other;
  }

  private get selectedDocumentDate() {
    return this.formData.documentDate;
  }
  private set selectedDocumentDate(val: ZonelessDate | null) {
    // val is actually a date but because the getter is a ZonelessDate,
    // typescript expects the setter to be a ZonelessDate too
    if (val) {
      this.formData.documentDate = new ZonelessDate(val);
    } else {
      this.formData.documentDate = null;
    }
  }

  private get documentDateErrorMessages() {
    if (this.selectedDocumentDate && isFuture(this.selectedDocumentDate)) {
      return 'Cannot be in the future';
    }
    return undefined;
  }

  private get documentRecievedErrorMessages() {
    if (this.formData.documentReceivedLocalBrowser && isFuture(this.formData.documentReceivedLocalBrowser)) {
      return 'Cannot be in the future';
    }

    if (
      this.selectedDocumentDate &&
      this.formData.documentReceivedLocalBrowser &&
      !(this.formData.documentReceivedLocalBrowser >= this.selectedDocumentDate)
    ) {
      return 'Must be >= Document Date';
    }
    return undefined;
  }

  private get documentDeemedRecievedErrorMessages() {
    if (
      this.formData.documentDeemedReceivedLocalBrowser &&
      isFuture(this.formData.documentDeemedReceivedLocalBrowser)
    ) {
      return 'Cannot be in the future';
    }

    if (
      this.selectedDocumentDate &&
      this.formData.documentDeemedReceivedLocalBrowser &&
      !(this.formData.documentDeemedReceivedLocalBrowser >= this.selectedDocumentDate)
    ) {
      return 'Must be >= Document Date';
    }
    return undefined;
  }

  private get selectedContractTimeZone() {
    return this.selectedContractRaw?.timezone;
  }
  private get selectedContractRowVersion() {
    return this.selectedContractRaw?.rowVersion;
  }
  private get browserTimezone() {
    return getBrowserTimezone();
  }

  private get timeZoneComparison() {
    const timezone = this.selectedContractTimeZone;
    if (!timezone) {
      // we don't have a selection. Pretend like things match
      return {
        match: true,
      };
    }
    return compareToBrowserTimeZone(timezone);
  }

  @Watch('errors')
  private errorsChanged() {
    // property has changed, update the stuff
    this.referenceExternalErrorMessages = this.errors?.reference || [];
  }

  // * WATCHERS
  @Watch('formData', { deep: true })
  private formDataChanged(val: ClaimDetailsFormObject) {
    this.currentThumbprint = this.getClaimThumbprint(val);
    const claimGid = val.segmentInstanceGid || uuid();
    let timezone = this.selectedContractTimeZone;
    if (!timezone) {
      console.warn(
        'ot-claim-details-form -> formDataChanged -> selectedContractRaw.timzeone is falsy. Defaulting to browser timezone. selectedContractRaw:  ',
        this.selectedContractRaw,
      );
      timezone = getBrowserTimezone();
    }
    let rowVersion = this.selectedContractRowVersion;
    if (!rowVersion) {
      console.warn(
        'ot-claim-details-form -> formDataChanged -> selectedContractRowVersion is falsy. Defaulting to an empty string. Any API calls that use this empty string will probably return a 409.',
      );
      rowVersion = '';
    }
    this.$emit(
      'update:contractDetailsPost',
      val.toClaimDetailsPost({
        claimGid,
        projectName: this.selectedProject?.label || null,
        contractTimeZone: timezone,
        contractRowVersion: rowVersion,
        includeDocumentDetails:
          this.selectedClaimTypeRaw?.config?.documentRequiredOnCreation !== OtDataEntryRequirement.Forbidden,
      }),
    );
    this.checkIfContractsNeedLoading();
  }

  // * METHODS
  private async getProjects() {
    const apiResult = await executeApi(() => this.api.projects().getProjects(), 'Load Projects');
    if (apiResult.success && apiResult.data && apiResult.data.projects) {
      this.allProjects = apiResult.data.projects
        .map(p => Project.createFromApiResponse(p))
        .map(p => {
          return {
            label: p.code + ': ' + p.name,
            data: p,
            disabled: PROJECT_STATUSES_CANNOT_CREATE_CLAIMS_FOR.includes(p.status),
          };
        });
    }
  }
  private getTagStatusFromProject(item: Project | null): TagStatus | undefined {
    if (!item) {
      return undefined;
    }
    return {
      type: OtStatusType.Project,
      status: item.status,
    };
  }

  private lastLoadedContractsForProjectGid: string | null = null;
  private checkIfContractsNeedLoading() {
    if (this.formData.projectGid && this.formData.projectGid !== this.lastLoadedContractsForProjectGid) {
      this.getContracts();
    }
  }

  private async getContracts() {
    this.lastLoadedContractsForProjectGid = this.formData.projectGid;
    const projectGid = this.formData.projectGid;
    if (!projectGid) {
      return;
    }
    const apiResult = await executeApi(() => this.api.contracts().getContracts(projectGid), 'Load Contracts');
    if (apiResult.success && apiResult.data && apiResult.data.contracts) {
      this.allContractsRaw = apiResult.data.contracts.map(c => Contract.createFromApiResponse(c));
    }
  }
  private getTagStatusFromContract(item: Contract | null): TagStatus | undefined {
    if (!item?.status) {
      return undefined;
    }
    return {
      type: OtStatusType.Contract,
      status: item.status,
    };
  }

  private async getAllContractSegmentInstances(contractGid: string) {
    this.isLoading = true;

    const apiResult = await executeApi(
      () => this.api.segmentInstances().getSegmentInstances(contractGid),
      `Load Workflows for contract (${contractGid})`,
    );
    if (apiResult.success && apiResult.data && apiResult.data.segmentInstances) {
      this.allSegmentInstancesRaw = apiResult.data.segmentInstances.map(s => SegmentInstance.createFromApiResponse(s));
    }

    this.isLoading = false;
  }

  private async getAllContractWorkflowSegments(contractGid: string) {
    this.isLoading = true;

    const apiResult = await executeApi(
      () => this.api.contracts().getContractSegments(contractGid),
      'Load Contract Workflows',
    );

    if (apiResult.success && apiResult.data && apiResult.data.segments) {
      this.allBasicSegmentsRaw = apiResult.data.segments.map(s => BasicSegment.createFromApiResponse(s));
      this.allContractWorkflowSegments = this.allBasicSegmentsRaw.map(s => {
        return {
          label: this.generateClaimTypeAutocompleteLabel({
            workflowName: s.workflow.name,
            workflowGid: s.workflow.gid,
            segmentGid: s.gid,
            segmentName: s.name,
            segmentShortName: s.shortName,
            segmentContext: s.context,
            tag: null, // The tag isn't used in the search so we don't need to pass it
            parentRequired: OtDataEntryRequirement.Mandatory, // parentRequired isn't used in the search so we don't need to set it's actual value
            validParentCodes: [], // validParentCodes isn't used in the search so we don't need to set it's actual value
          }), // The label value is still used to search, even though it's not directly displayed
          data: s.gid,
          // I do not think tag is needed. We have no UI for setting this, at the moment it comes straight out of the manifest
          // Thus, it cannot vary on a case by case basis
          // If we ever add a UI for "this segment is optional" then we can put this back. Or we could put it as an actual flippin DB column. Sheesh.
          // The formerly loosey goosey "put any properties you want here" was a reasonable idea, if we had an actual need for rando properties on the segment config. And a UI for editing them. We don't, and we don't.
          // tag: s.config?.contractuallyRequired === false ? `${s.shortName} is not contractually required` : null,
          tag: null,
          parentRequired: s.config?.parentRequired || OtDataEntryRequirement.Mandatory,
          validParentCodes: s.config?.validParentCodes || [],
          workflowName: s.workflow.name,
          workflowGid: s.workflow.gid,
          workflowShortName: s.workflow.shortName,
          segmentGid: s.gid,
          segmentName: s.name,
          segmentShortName: s.shortName,
          segmentContext: s.context,
          disabled: !s.config?.canBeCreatedFromScratch,
        };
      });
      // Get the unique workflows form the segments,
      // then map them to the format needed for the autocomplete
      const uniques = Array.from(new Set(this.allContractWorkflowSegments.map(x => x.workflowGid)));

      this.allContractWorkflows = uniques.map(u => {
        const found = this.allContractWorkflowSegments.find(x => x.workflowGid === u);
        return {
          label: found?.workflowName || '',
          data: found?.workflowGid || '',
          workflowName: found?.workflowName || '',
          workflowGid: found?.workflowGid || '',
        };
      });
    }

    this.isLoading = false;
  }

  private get currentContractWorkflowSegments() {
    if (!this.selectedWorkflowType) {
      return [];
    }
    return this.allContractWorkflowSegments.filter(s => s.workflowGid === this.selectedWorkflowType?.workflowGid);
  }

  private generateSelectedText(item: IClaimTypeAutocompleteItem<string>) {
    return this.generateClaimTypeAutocompleteLabel(item, GenerateClaimTypeLabelOptions.includeHtmlElements);
  }

  private generateFilteredText(item: IClaimTypeAutocompleteItem<string>) {
    if (!this.claimTypeSearchInput)
      return this.generateClaimTypeAutocompleteLabel(item, GenerateClaimTypeLabelOptions.includeHtmlElements);
    const text = this.generateClaimTypeAutocompleteLabel(item, GenerateClaimTypeLabelOptions.includeHtmlElements);

    const { start, middle, end } = this.getMaskedCharacters(text);

    return `${start}${this.genHighlight(middle)}${end}`;
  }

  private genHighlight(text: string): string {
    return `<span class="v-list-item__mask">${escapeHTML(text)}</span>`;
  }

  private getMaskedCharacters(text: string): { start: string; middle: string; end: string } {
    const searchInput = (this.claimTypeSearchInput || '').toString().toLocaleLowerCase();
    const index = text.toLocaleLowerCase().indexOf(searchInput);

    if (index < 0) return { start: text, middle: '', end: '' };

    const start = text.slice(0, index);
    const middle = text.slice(index, index + searchInput.length);
    const end = text.slice(index + searchInput.length);
    return { start, middle, end };
  }

  private generateClaimTypeAutocompleteLabel(
    segment: IClaimTypeObject,
    options?: GenerateClaimTypeLabelOptions,
  ): string {
    const addHtmlElements = options === GenerateClaimTypeLabelOptions.includeHtmlElements;
    return `${addHtmlElements ? '<span class="--bold">' : ''}${escapeHTML(segment.segmentName)}${
      addHtmlElements ? '</span>' : ''
    } (${escapeHTML(segment.segmentShortName)}${
      segment.segmentContext ? ' - ' + escapeHTML(segment.segmentContext) : ''
    })`;
  }

  private getClaimThumbprint(claim: ClaimDetailsFormObject): string {
    const vals = {
      segmentInstanceGid: claim.segmentInstanceGid || '',
      projectGid: claim.projectGid || '',
      contractGid: claim.contractGid || '',
      workflowSegmentGid: claim.workflowSegmentGid || '',
      parentClaimGid: claim.parentClaimGid || '',
      reference: claim.reference || '',
      documentName: claim.documentName || '',
      documentReceivalType: claim.documentReceivalType?.value || '',
      documentReceivalDescription: claim.documentReceivalDescription || '',
      documentDate: claim.documentDate?.toISOString() || '',
      documentReceivedUtc: claim.documentReceivedLocalBrowser?.toISOString() || '',
      documentDeemedReceivedUtc: claim.documentDeemedReceivedLocalBrowser?.toISOString() || '',
    };
    return JSON.stringify(vals);
  }

  public validate() {
    return this.claimDetailsFormRef.validate();
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  public async submit(validate = true): Promise<SegmentInstanceDetailsPost | null> {
    if (!validate || this.validate()) {
      const claimGid = this.formData.segmentInstanceGid ? this.formData.segmentInstanceGid : uuid();
      let timezone = this.selectedContractTimeZone;
      if (!timezone) {
        console.warn(
          'ot-claim-details-form -> submit -> selectedContractRaw.timzeone is falsy. Defaulting to browser timezone:  ',
          this.selectedContractRaw,
        );
        timezone = getBrowserTimezone();
      }
      let rowVersion = this.selectedContractRowVersion;
      if (!rowVersion) {
        console.warn(
          'ot-claim-details-form -> submit -> selectedContractRowVersion is falsy. Defaulting to an empty string. Any API calls that use this empty string will probably return a 409.',
        );
        rowVersion = '';
      }

      this.setFormToCleanState();

      const cleanContract = this.formData.toClaimDetailsPost({
        claimGid,
        projectName: this.selectedProject?.label || null,
        contractTimeZone: timezone,
        contractRowVersion: rowVersion,
        includeDocumentDetails:
          this.selectedClaimTypeRaw?.config?.documentRequiredOnCreation !== OtDataEntryRequirement.Forbidden,
      });

      return cleanContract;
    }
    return null;
  }

  // * LIFECYCLE
  private async created() {
    // there's a ton of ways to exit the load sequence. We always want to do this at the tail end of ALL of them
    // so, make a little method we can call when we're done
    // it means further down we can have a run of "if (bail condition) finishedLoading() return;"
    const finishedLoading = () => {
      this.originalThumbprint = this.getClaimThumbprint(this.formData);
      this.currentThumbprint = this.getClaimThumbprint(this.formData);

      this.isLoading = false;
    };

    this.isLoading = true;

    // always want to load the projects and the contracts
    // if we're in edit mode, we don't striclty need this
    // but we don't have a single getproject that we could use
    // we should consider a lightweight getprojects and getcontracts that JUST gets the names
    // and that also has an option to load just one of them
    await Promise.all([this.getProjects(), this.getContracts()]);

    /*
    possible ways we can be called: 

    completely brand new, no starting point
    claim filled in. We shouldn't have to do anything else here
    project filled in
    project, contract filled in
    project, contract, segment filled in
    project, contract, segment, parent filled in
    */

    // gather these querystring bits first, we need them both for "do we have it" as well as "we have it but won't use it"
    const { project: projectGid, contract: contractGid, segment: segmentGid, parent: parentGid } = this.$route.query;

    if (this.claimDetails) {
      // copy over all the "default" properties. Probably just the enum ones
      this.formData.documentReceivalType = this.claimDetails.documentReceivalType;
    }

    if (this.claimDetails?.gid) {
      // we've been given something with a guid to edit
      // we always get given a claimDetails object. Which I think is a bit dumb
      this.formData.segmentInstanceGid = this.claimDetails.gid;
      this.formData.projectGid = this.claimDetails.project.gid;
      this.formData.contractGid = this.claimDetails.contract.gid;
      this.formData.workflowSegmentGid = this.claimDetails.workflowSegment.gid;
      this.formData.parentClaimGid = this.claimDetails.parentClaim?.gid || null;
      this.formData.reference = this.claimDetails.reference;
      this.formData.documentName = this.claimDetails.documentName;
      this.formData.documentReceivalDescription = this.claimDetails.documentReceivalDescription;
      this.formData.documentDate = this.claimDetails.documentCreationDate;
      if (this.claimDetails.contract.timezone) {
        // We have a timezone. This is good. We can do the conversions
        // server to client - convert these.
        // See https://github.com/marnusw/date-fns-tz#time-zone-offset-helpers for a worked example of why we are doing this conversion
        this.formData.documentReceivedLocalBrowser = this.claimDetails.documentReceivedUtc
          ? utcToZonedTime(this.claimDetails.documentReceivedUtc, this.claimDetails.contract.timezone)
          : null;
        this.formData.documentDeemedReceivedLocalBrowser = this.claimDetails.documentDeemedReceivedUtc
          ? utcToZonedTime(this.claimDetails.documentDeemedReceivedUtc, this.claimDetails.contract.timezone)
          : null;
      } else {
        // we have no timezone, this is bad. We'll have to use the values raw from the server
        console.warn(
          'ot-claim-details-form -> created -> there is no timezone for the contract on the claim details. The document received and deemed received will not be converted. This will lead to problems if the browser is in a different timezone to the client. Claim Details',
          _.cloneDeep(this.claimDetails),
        );
        this.formData.documentReceivedLocalBrowser = this.claimDetails.documentReceivedUtc || null;
        this.formData.documentDeemedReceivedLocalBrowser = this.claimDetails.documentDeemedReceivedUtc || null;
      }

      if (this.formData.contractGid) {
        await Promise.all([
          this.getAllContractSegmentInstances(this.formData.contractGid),
          this.getAllContractWorkflowSegments(this.formData.contractGid),
        ]);
        if (this.formData.workflowSegmentGid) {
          const located = this.allContractWorkflowSegments.find(
            segment => segment.data === this.formData.workflowSegmentGid,
          );
          if (located) {
            this.formData.workflowGid = located.workflowGid;
          }
        }
      }
      // bit of simple warning about ignored parameters. This one is quite easy
      if (projectGid)
        console.warn('ot-claim-details-form -> created -> project on query string is ignored in edit mode');
      if (contractGid)
        console.warn('ot-claim-details-form -> created -> contract on query string is ignored in edit mode');
      if (segmentGid)
        console.warn('ot-claim-details-form -> created -> segment on query string is ignored in edit mode');
      if (parentGid) console.warn('ot-claim-details-form -> created -> parent on query string is ignored in edit mode');

      // and we're done
      finishedLoading();
      return;
    }

    // we're in create mode
    // ton of "did they ask us to do a dumb thing?" checks
    if (contractGid && !projectGid) {
      console.warn(
        'ot-claim-details-form -> created -> contract on query string without project on query string, contract will be ignored',
      );
    }
    if (segmentGid && !(projectGid && contractGid)) {
      console.warn(
        'ot-claim-details-form -> created -> segment on query string without project and contract on query string, segment will be ignored',
      );
    }
    if (parentGid && !(projectGid && contractGid && segmentGid)) {
      console.warn(
        'ot-claim-details-form -> created -> parent on query string without project and contract and segment on query string, parent will be ignored',
      );
    }

    // First check for project
    if (!projectGid) {
      // no project, we're done
      finishedLoading();
      return;
    }
    if (typeof projectGid === 'string') {
      // probably should check if it matches a loaded project? The original code (before R06) didn't :/
      this.formData.projectGid = projectGid;
    } else {
      console.warn(
        'ot-claim-details-form -> created -> project on query string was an array not a single item. Ignoring Project and exiting load sequence.',
        { projectGid },
      );
      finishedLoading();
      return;
    }

    // Then, check for contract
    if (!contractGid) {
      // no contract, we're done
      finishedLoading();
      return;
    }

    if (typeof contractGid !== 'string') {
      console.warn(
        'ot-claim-details-form -> created -> contract on query string was an array not a single item. Ignoring contract and exiting load sequence.',
        { contractGid },
      );
      finishedLoading();
      return;
    }
    this.formData.contractGid = contractGid;
    await Promise.all([
      this.getAllContractSegmentInstances(contractGid),
      this.getAllContractWorkflowSegments(contractGid),
    ]);

    // next up, look for segment (aka workflow subtype on screen)
    if (!segmentGid) {
      // no segment, we're done
      finishedLoading();
      return;
    }
    if (typeof segmentGid !== 'string') {
      console.warn(
        'ot-claim-details-form -> created -> segment on query string was an array not a single item. Ignoring segment and exiting load sequence.',
        { segmentGid },
      );
      finishedLoading();
      return;
    }

    const foundSegment = this.allBasicSegmentsRaw.find(x => x.gid === segmentGid);
    if (!foundSegment) {
      console.warn(
        'ot-claim-details-form -> created -> segment on query string not found in segments for contract. Exiting load sequence.',
      );
      finishedLoading();
      return;
    }

    // Update Form Data to select the workflow type and the segment
    this.formData.workflowGid = foundSegment.workflow.gid;
    this.formData.workflowSegmentGid = foundSegment.gid;

    // Next, look to see if there's a parent
    if (!parentGid) {
      // no parent, we're done
      finishedLoading();
      return;
    }

    if (typeof parentGid !== 'string') {
      console.warn(
        'ot-claim-details-form -> created -> parent on query string was an array not a single item. Ignoring parent and exiting load sequence.',
        { parentGid },
      );
      finishedLoading();
      return;
    }

    const foundParent = this.allSegmentInstancesRaw.find(x => x.gid === parentGid);
    if (!foundParent) {
      console.warn(
        'ot-claim-details-form -> created -> parent on querystring not found in Contract Segment Instances. Exiting load sequence',
      );
      finishedLoading();
      return;
    }

    // not much we can do without the segment having loaded config. This is more a paranoia check
    if (!foundSegment.config) {
      console.warn(
        `ot-claim-details-form -> created -> segment ${foundSegment.name} does have any config. Exiting load sequence`,
        {
          foundSegment,
        },
      );
      finishedLoading();
      return;
    }

    // make sure segment supports children, and supports this type of child
    if (foundSegment.config.parentRequired === OtDataEntryRequirement.Forbidden) {
      console.warn(
        `ot-claim-details-form -> created -> segment ${foundSegment.name} does not allow children. Exiting load sequence`,
        foundSegment,
      );
      finishedLoading();
      return;
    }

    if (
      foundSegment.config?.validParentCodes !== undefined &&
      !foundSegment.config.validParentCodes.includes(foundParent.workflowSegment.shortName)
    ) {
      console.warn(
        `ot-claim-details-form -> created -> segment ${foundSegment.name} does not allow children of type ${foundParent.workflowSegment.shortName}`,
        { foundSegment, foundParent },
      );
      finishedLoading();
      return;
    }

    // set this as the selected parent and reference
    this.formData.parentClaimGid = foundParent.gid;
    this.formData.reference = foundParent.reference;
    finishedLoading();

    // have a little lie down, we're done
  }
}
