import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';
import OtIssuesLayout from '@/areas/issues/ot-issues-layout.vue';

export const issuesRoutes: Array<RouteConfig> = [
  {
    path: '/issues',
    component: OtIssuesLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'Issues',
        component: OtComingSoon,
        meta: {
          breadcrumb: 'Issues',
        },
      },
    ],
  },
];
