

















































































































































































































































































































































import { FormModalParams, FormModalSizeEnum } from '@/components/global/modal/form-modal-models';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtSelect from '@/components/global/ot-select.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IExtendedColumnData } from '@/components/global/table/ot-table-models';
import OtTable from '@/components/global/table/ot-table.vue';
import { isInvitableOrReinvitable, UserStatus, UserStatusWithActions } from '@/models/user-models';
import OtApi, { executeApi } from '@/services/api.service';
import { PostCreateInvitationRequestModel, PostCreateInvitationUserContextModel } from '@/services/generated/api';
import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { blurCombos, IVForm } from '@/utils/type-utils';
import { isEmail } from '@/utils/validation-utils';
import { uniqueId } from 'lodash';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { InvitableContract, InvitableOrganisation, InvitableProject, InvitableUser } from './invitation-models';

export class InviteUserDialogResult {
  constructor(params?: Partial<InviteUserDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
export class InviteUserDialogParams {
  // the organisation we are operating under
  public organisationGid!: string;

  public isReinvite!: boolean;

  // used for both invite and reinvite
  public inviteToOrganisation = false; // If true, auto select the invite to organisation option

  // Invite properties
  public projects: string[] = []; // guids of projects
  public contracts: string[] = []; // guids of contracts

  // Reinvite properties
  public invitedUserGid!: string; // guild of the user to reinvite. Will match a guid from API15001 - GET Invitable Users
  public userProjects: string[] = []; // guid of projectusers
  public userContracts: string[] = []; // guid of contractusers

  constructor(params?: Partial<InviteUserDialogParams>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class InviteToProject {
  public key: string = uniqueId();
  public project: IAutocompleteItem<InvitableProject> | null = null;
  public projectRoleTitle = '';
  public errorMessages: string[] = [];
  constructor(params?: Partial<InviteToProject>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
export class InviteToContract {
  public key: string = uniqueId();
  public contract: IAutocompleteItem<InvitableContract> | null = null;
  public contractRoleTitle = '';
  public errorMessages: string[] = [];
  constructor(params?: Partial<InviteToContract>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

const INVITE_ORGANISATION_NO: IRadioGroupOption = {
  key: '1',
  label: 'No, do not invite to the Organisation',
};
const INVITE_ORGANISATION_YES: IRadioGroupOption = {
  key: '2',
  label: 'Yes, invite to the Organisation',
};
const INVITE_PROJECT_NO: IRadioGroupOption = {
  key: '1',
  label: 'No, do not invite to any project',
};
const INVITE_PROJECT_YES: IRadioGroupOption = {
  key: '2',
  label: 'Yes, invite to project(s)',
};
const INVITE_CONTRACT_NO: IRadioGroupOption = {
  key: '1',
  label: 'No, do not invite to any contract',
};
const INVITE_CONTRACT_YES: IRadioGroupOption = {
  key: '2',
  label: 'Yes, invite to contract(s)',
};

@Component({
  components: {
    OtFormModal,
    OtButton,
    OtTextField,
    OtTextarea,
    OtSelect,
    OtAutocomplete,
    OtDatePicker,
    OtComboBox,
    OtRadioGroup,
    OtTable,
    OtTableHeader,
    OtTag,
    OtLoadingSpinner,
  },
})
export default class OtInviteUserDialog extends Vue {
  // * PROPS
  private api = new OtApi();
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;

  // * DATA
  private isReinviteMode = false;
  private organisationGid = '';
  private firstName: string | null = null;
  private lastName: string | null = null;

  private invitableUsers: InvitableUser[] = [];

  private availableOrganisationJobTitles: string[] = [];
  private availableProjectJobTitles: string[] = [];
  private availableContractJobTitles: string[] = [];
  private organisationJobTitle: string | null = null;

  private selectedOrganisationOption: IRadioGroupOption | null = null;
  private selectedProjectOption: IRadioGroupOption | null = null;
  private selectedContractOption: IRadioGroupOption | null = null;

  private invitableOrganisations: InvitableOrganisation[] = [];
  private invitableProjects: InvitableProject[] = [];
  private invitableContracts: InvitableContract[] = [];

  // Normally these would be a getter, but we need this to be stable
  // When we load new invitableProjects, we'll replace the .data on the autocomplete item with the newly loaded project
  // and same for contracts
  private availableProjects: IAutocompleteItem<InvitableProject>[] = [];
  private availableContracts: IAutocompleteItem<InvitableContract>[] = [];

  private invitedProjects: InviteToProject[] = [];
  private invitedContracts: InviteToContract[] = [];

  private showMustSelectSomethingError = false;

  // Disable states for all the pieces
  private disableNameEntry = false;
  private hasValidEmailAddress = false;
  private disableOrganisationInviteAction = false;
  private disableProjectInviteAction = false;
  private disableContractInviteAction = false;

  // TODO consider replacing this existingUserStatus with a getter off invitableOrgs
  private existingUserStatus: UserStatusWithActions | null = null;

  private emailAddressPrivate: string | null = null;
  private get emailAddress() {
    return this.emailAddressPrivate;
  }
  private set emailAddress(val: string | null) {
    this.emailAddressPrivate = val;
    this.onEmailAddressChanged();
  }

  private onEmailAddressChanged() {
    console.log('onEmailAddressChanged', this.emailAddress);
    if (this.emailAddress) {
      // Locate the email from the list of invitable users
      const isValidEmail = isEmail(this.emailAddress);
      if (isValidEmail) {
        const located = this.invitableUsers.find(user => user.email === this.emailAddress);
        if (located) {
          // found an existing user, copy the name over and disable editing of the name
          // we might have found it from them selecting from the list, or we might have found it from them typing
          // but, we found it, that's the main point here
          this.firstName = located.firstName;
          this.lastName = located.lastName;
          this.disableNameEntry = true;
        } else {
          // not in the list, let them enter their own email address
          this.disableNameEntry = false;
          // reset anything they might have typed in before. Feels a bit rough if they were just correcting a typo in the email address
          // but it's safer
          this.firstName = '';
          this.lastName = '';
        }
        this.hasValidEmailAddress = true;
        // the email is valid, so we can get the Organisations,
        // Projects and Contracts for the invitation and match stuff up to anything that's already been selected
        this.onUpdateEmailAddress(this.organisationGid, this.emailAddress);
      } else {
        // the email is invalid. Disable all the things until the enter a valid one
        // as we can't let them pick anything from any list until they fix this
        this.hasValidEmailAddress = false;
        this.onUpdateEmailAddress(this.organisationGid, null);
      }
    } else {
      // the email box has been cleared out
      this.disableNameEntry = false;
      this.firstName = '';
      this.lastName = '';
      this.hasValidEmailAddress = false;
      this.existingUserStatus = null;
      // disable the lower sections
      this.onUpdateEmailAddress(this.organisationGid, this.emailAddress);
    }
  }
  private get autocompleteInvitableUsers(): string[] {
    return this.invitableUsers.map(user => user.email);
  }

  private get canInviteToOrganisations() {
    return this.invitableOrganisations.length > 0;
  }
  private get canInviteToProjects() {
    return this.invitableProjects.length > 0;
  }
  private get canInviteToContracts() {
    return this.invitableContracts.length > 0;
  }
  private get canAddOrEditOrganisation() {
    return this.canInviteToOrganisations && this.hasValidEmailAddress && this.isInviteToOrganisationYesSelected;
  }
  private get canAddOrEditProjects() {
    return this.canInviteToProjects && this.hasValidEmailAddress && this.isInviteToProjectsYesSelected;
  }
  private get canAddOrEditContracts() {
    return this.canInviteToContracts && this.hasValidEmailAddress && this.isInviteToContractsYesSelected;
  }

  private get organisationInviteText() {
    return `Do you want ${this.displayFirstName} to have access to the Organisation?`;
  }
  private get projectInviteText() {
    return `Do you want to invite ${this.displayFirstName} to any specific Projects?`;
  }
  private get contractInviteText() {
    return `Do you want to invite ${this.displayFirstName} to any specific Contracts?`;
  }

  private get organisationOptions(): IRadioGroupOption[] {
    return [INVITE_ORGANISATION_NO, INVITE_ORGANISATION_YES];
  }

  private get projectOptions(): IRadioGroupOption[] {
    return [INVITE_PROJECT_NO, INVITE_PROJECT_YES];
  }

  private get contractOptions(): IRadioGroupOption[] {
    return [INVITE_CONTRACT_NO, INVITE_CONTRACT_YES];
  }

  private get isInviteToOrganisationNoSelected() {
    return this.selectedOrganisationOption?.key === INVITE_ORGANISATION_NO.key;
  }
  private get isInviteToOrganisationYesSelected() {
    return this.selectedOrganisationOption?.key === INVITE_ORGANISATION_YES.key;
  }
  private get isInviteToProjectsNoSelected() {
    return this.selectedProjectOption?.key === INVITE_PROJECT_NO.key;
  }
  private get isInviteToProjectsYesSelected() {
    return this.selectedProjectOption?.key === INVITE_PROJECT_YES.key;
  }
  private get isInviteToContractsNoSelected() {
    return this.selectedContractOption?.key === INVITE_CONTRACT_NO.key;
  }
  private get isInviteToContractsYesSelected() {
    return this.selectedContractOption?.key === INVITE_CONTRACT_YES.key;
  }

  private projectTableColumns: IExtendedColumnData<InviteToProject>[] = [
    {
      index: 0,
      label: 'Project',
      key: 'project',
      isActive: true,
      ascending: false,
      sortable: false,
    },
    {
      index: 1,
      label: 'Project Role Title',
      key: 'role',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  private contractTableColumns: IExtendedColumnData<InviteToContract>[] = [
    {
      index: 0,
      label: 'Contract',
      key: 'contract',
      isActive: true,
      ascending: false,
      sortable: false,
    },
    {
      index: 1,
      label: 'Contract Role Title',
      key: 'role',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: 'Actions',
      key: 'actions',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  public async validate() {
    console.log('ot-invite-user-dialog -> validate');

    blurCombos(
      this.$refs.emailAddressCombo,
      this.$refs.organisationJobTitleCombo,
      this.$refs.projectRoleTitleCombo,
      this.$refs.contractRoleTitleCombo,
    );

    // wait for a re-render so we absotively posilutely have the values where they should be
    await this.$nextTick();

    for (const project of this.invitedProjects) {
      // reset the errors from last time
      project.errorMessages = [];
    }
    for (const contract of this.invitedContracts) {
      // reset the errors from last time
      contract.errorMessages = [];
    }

    // run the standard validation first
    let isValid = this.modalContentRef.validate();
    console.log('ot-invite-user-dialog -> validate -> after form validation', isValid);

    // then run our special validation next

    // only validate projects if they've said they want to validate projects
    // we don't care if they've said yes, picked a bunch of things that are bad, then said no
    if (this.isInviteToProjectsYesSelected) {
      // make sure they've picked at least one project?
      // Do we really care if they've said yes, but then decided to not pick projects?
      // if they say yes to all three but don't pick anything, we'll tell them off further down

      // make sure that each project is unique
      // make sure they don't have any projects that the user cannot be invited to
      const projectCountByGid = this.invitedProjects.reduce((results, item) => {
        const gid = item.project?.data?.gid;
        if (gid) {
          results.set(gid, (results.get(gid) || 0) + 1);
        }
        return results;
      }, new Map<string, number>());
      for (const project of this.invitedProjects) {
        // we're only interested in real projects
        if (project.project?.data) {
          if (!isInvitableOrReinvitable(project.project.data.userStatus)) {
            project.errorMessages.push('Cannot invite to this project');
            isValid = false;
          } else if ((projectCountByGid.get(project.project.data.gid) || 0) > 1) {
            project.errorMessages.push('Project must be unique');
            isValid = false;
          }
        }
      }
    }
    console.log('ot-invite-user-dialog -> validate -> after project validation', isValid);

    // only validate contracts if they've said they want to validate contracts
    // we don't care if they've said yes, picked a bunch of things that are bad, then said no
    if (this.isInviteToContractsYesSelected) {
      // make sure they've picked at least one contract?
      // Do we really care if they've said yes, but then decided to not pick contracts?
      // if they say yes to all three but don't pick anything, we'll tell them off further down

      // make sure that each contract is unique
      // make sure they don't have any contracts that the user cannot be invited to
      const contractCountByGid = this.invitedContracts.reduce((results, item) => {
        const gid = item.contract?.data?.gid;
        if (gid) {
          results.set(gid, (results.get(gid) || 0) + 1);
        }
        return results;
      }, new Map<string, number>());
      for (const contract of this.invitedContracts) {
        // we're only interested in real contracts
        if (contract.contract?.data) {
          if (!isInvitableOrReinvitable(contract.contract.data.userStatus)) {
            contract.errorMessages.push('Cannot invite to this contract');
            isValid = false;
          } else if ((contractCountByGid.get(contract.contract.data.gid) || 0) > 1) {
            contract.errorMessages.push('Contract must be unique');
            isValid = false;
          }
        }
      }
    }
    console.log('ot-invite-user-dialog -> validate -> after contract validation', isValid);

    // make sure that each contract is unique
    // make sure they don't have any contract that the user cannot be invited to

    // make sure they've picked at least the org, a project or a contract
    // the org is troubling me
    // all of this is troubling me
    // ultimately, they need either the user for the org, or a project, or a contract.
    // they might not have permission to select any of them though
    // but that's probably a messaging concern, not a logic concern?

    // we can tell if they will not submitting any projects easily
    //   Not allowed to do it
    //   "No" radio option selected
    //   Nothing selected in the list
    //   we have to look at No selected because if they pick no, it doesn't matter what is in the list, nothing is being submitted
    const willNotBeSubmittingProjects =
      !this.canInviteToProjects || this.isInviteToProjectsNoSelected || this.invitedProjects.length === 0;

    // same for contracts
    const willNotBeSubmittingContracts =
      !this.canInviteToContracts || this.isInviteToContractsNoSelected || this.invitedContracts.length === 0;

    // we can tell if they will not be submitting an organisation if
    //   they're not allowed to
    //   they've picked the "no" radio option
    //   the user they've entered can't be submitted
    const willNotBeSubmittingAnOrganisation =
      !this.canInviteToOrganisations || this.isInviteToOrganisationNoSelected || !this.isInvitableToOrganisation;

    this.showMustSelectSomethingError =
      this.hasValidEmailAddress &&
      willNotBeSubmittingAnOrganisation &&
      willNotBeSubmittingProjects &&
      willNotBeSubmittingContracts;
    isValid = isValid && !this.showMustSelectSomethingError;

    console.log('ot-invite-user-dialog -> validateExtras -> isValid', isValid);
    return isValid;
  }

  public addProjectToInvite() {
    this.invitedProjects.push(new InviteToProject());
  }
  public addContractToInvite() {
    this.invitedContracts.push(new InviteToContract());
  }

  private get isActiveInOrganisation() {
    return this.existingUserStatus?.status === OtUserStatus.Active || false;
  }

  private get isInvitedToOrganisation() {
    return this.existingUserStatus?.status === OtUserStatus.Invited || false;
  }

  private get isInvitableToOrganisation() {
    return isInvitableOrReinvitable(this.existingUserStatus);
  }

  private get organisationInfoBoxMainText() {
    if (this.isInvitableToOrganisation) {
      return null;
    }
    if (this.isActiveInOrganisation) {
      return `${this.displayFirstName} already has access to the Organisation as a `;
    }
    if (this.isInvitedToOrganisation) {
      return `${this.displayFirstName} already has a pending invitation to the Organisation as a `;
    }
    return `${this.displayFirstName} cannot be invited to the organisation`;
  }

  private get showOrganisationAlert() {
    return this.canInviteToOrganisations && !this.isInvitableToOrganisation;
  }
  private get showOrganisationInviteSection() {
    return this.canInviteToOrganisations && this.isInvitableToOrganisation;
  }

  private get displayFirstName() {
    return this.firstName || 'the user';
  }

  private get organisationInfoBoxSecondaryText() {
    return this.existingUserStatus?.title;
  }

  private getTagStatusFromUserStatus(item: UserStatus | null): TagStatus | undefined {
    if (!item) {
      return undefined;
    }
    return {
      type: OtStatusType.User,
      status: item.status,
    };
  }

  private async onUpdateEmailAddress(organisationGid: string, emailAddress: string | null) {
    // email address has changed
    // reload all the invitables
    this.invitableOrganisations = await this.getOrganisationsForInvitation(organisationGid, emailAddress);
    this.invitableProjects = await this.getProjectsForInvitation(organisationGid, emailAddress);
    this.invitableContracts = await this.getContractsForInvitation(organisationGid, emailAddress);

    // match the autocomplete items up with the projects we just loaded
    // we're never replacing the autocomplete list, just replacing the data in the list
    // this is so that we can hold onto the selected items in the invited project
    for (const availableProject of this.availableProjects) {
      const matchingProject = this.invitableProjects.find(x => availableProject.data?.gid === x.gid) || null;
      availableProject.data = matchingProject;
      availableProject.disabled = !isInvitableOrReinvitable(matchingProject?.userStatus);
    }
    // same for contracts
    for (const availableContract of this.availableContracts) {
      const matchingContract = this.invitableContracts.find(x => availableContract.data?.gid === x.gid) || null;
      availableContract.data = matchingContract;
      availableContract.disabled = !isInvitableOrReinvitable(matchingContract?.userStatus);
    }

    this.existingUserStatus = this.invitableOrganisations[0].userStatus || null;
  }

  private async getOrganisationInvitableUsers(organisationGid: string) {
    const apiResponse = await executeApi(
      () => this.api.invitations().getInvitations(organisationGid),
      'Get Invitable Users',
    );
    if (apiResponse) {
      return apiResponse.data?.map(iu => InvitableUser.createFromApiResponse(iu));
    }
    return [];
  }

  private handleRemoveProject(project: InviteToProject) {
    this.invitedProjects = this.invitedProjects.filter(x => x !== project);
  }
  private handleRemoveContract(contract: InviteToContract) {
    this.invitedContracts = this.invitedContracts.filter(x => x !== contract);
  }

  private async getOrganisationUserJobTitles() {
    const apiResponse = await executeApi(
      () => this.api.organisationUser().getOrganisationUserJobTitles(),
      'Get Organisation Job Titles',
    );
    if (apiResponse) {
      return apiResponse.data;
    }
    return [];
  }

  private async getProjectUserJobTitles() {
    const apiResponse = await executeApi(
      () => this.api.projectUser().getProjectUserJobTitles(),
      'Get Project Job Titles',
    );
    if (apiResponse) {
      return apiResponse.data;
    }
    return [];
  }

  private async getContractUserJobTitles() {
    const apiResponse = await executeApi(
      () => this.api.contractUser().getContractUserFunctionalRoleTitles(),
      'Get Contract Job Titles',
    );
    if (apiResponse) {
      return apiResponse.data;
    }
    return [];
  }

  private async getOrganisationsForInvitation(
    organisationGid: string,
    email: string | null,
  ): Promise<InvitableOrganisation[]> {
    const apiResponse = await executeApi(
      () => this.api.invitations().getInvitableOrganisations(organisationGid, email || undefined),
      'Get Organisations For Invitation',
    );
    if (apiResponse) {
      return apiResponse.data?.map(iu => InvitableOrganisation.createFromApiResponse(iu)) || [];
    }
    return [];
  }

  private async getProjectsForInvitation(organisationGid: string, email: string | null): Promise<InvitableProject[]> {
    const apiResponse = await executeApi(
      () => this.api.invitations().getInvitableProjects(organisationGid, email || undefined),
      'Get Projects For Invitation',
    );
    if (apiResponse) {
      return apiResponse.data?.map(iu => InvitableProject.createFromApiResponse(iu)) || [];
    }
    return [];
  }

  private async getContractsForInvitation(organisationGid: string, email: string | null): Promise<InvitableContract[]> {
    const apiResponse = await executeApi(
      () => this.api.invitations().getInvitableContracts(organisationGid, email || undefined),
      'Get Contracts For Invitation',
    );
    if (apiResponse) {
      return apiResponse.data?.map(iu => InvitableContract.createFromApiResponse(iu)) || [];
    }
    return [];
  }

  private isLoading = false;
  private async initialise(params: InviteUserDialogParams) {
    this.isLoading = true;

    // reset a bunch of things
    this.isReinviteMode = params.isReinvite;
    this.organisationGid = params.organisationGid;
    this.firstName = '';
    this.lastName = '';
    this.emailAddress = '';
    this.invitedProjects = [];
    this.invitedContracts = [];
    this.modalContentRef.reset();

    this.invitableUsers = (await this.getOrganisationInvitableUsers(this.organisationGid)) || [];
    this.availableOrganisationJobTitles = (await this.getOrganisationUserJobTitles()) || [];
    this.availableProjectJobTitles = (await this.getProjectUserJobTitles()) || [];
    this.availableContractJobTitles = (await this.getContractUserJobTitles()) || [];

    if (this.isReinviteMode) {
      if (params.invitedUserGid) {
        // go looking for the user in the list of users, pre-fill their details
        const matchingUser = this.invitableUsers.find(x => x.gid === params.invitedUserGid);
        if (matchingUser) {
          // set the email address and things. Set using the private properties, we'll control the load sequence here
          this.emailAddressPrivate = matchingUser.email;
          this.firstName = matchingUser.firstName;
          this.lastName = matchingUser.lastName;
          this.hasValidEmailAddress = true;
          this.disableNameEntry = true;
        } else {
          // it's not ideal that we were asked to re-invite someone, but couldn't
          // this probably means that there's some UI somewhere that thinks we can reinvite,
          // but the get invitable users thinks we cannot
          console.warn('ot-invite-user-dialog -> open -> unable to find user to reinvite', {
            gid: params.invitedUserGid,
            users: this.invitableUsers,
          });
        }
      } else {
        console.warn('ot-invite-user-dialog -> open -> invitedUserGid should be filled in if reinvite mode is true');
      }
    }

    // now that we've pre-filled the user (or, not), go off and load the projects/contracts
    // doing this AFTER we've prefilled the user, so that we only do one load to start off with, with the pre-filled email address
    // or with a blank email address if we're not prefilling
    this.invitableOrganisations = await this.getOrganisationsForInvitation(this.organisationGid, this.emailAddress);
    this.invitableProjects = await this.getProjectsForInvitation(this.organisationGid, this.emailAddress);
    this.availableProjects = this.invitableProjects.map(x => ({
      data: x,
      label: x.name,
      disabled: !isInvitableOrReinvitable(x.userStatus),
    }));

    this.invitableContracts = await this.getContractsForInvitation(this.organisationGid, this.emailAddress);
    this.availableContracts = this.invitableContracts.map(x => ({
      data: x,
      label: x.projectName + ': ' + x.name,
      disabled: !isInvitableOrReinvitable(x.userStatus),
    }));

    // tuck away the user status (if any) for the user we're iviting. This'll only be filled in if we have an email address
    // and if that email address is already a user for the org
    this.existingUserStatus = this.invitableOrganisations[0].userStatus || null;
    this.organisationJobTitle = this.existingUserStatus?.title || '';

    if (this.isReinviteMode) {
      // look into the projects, looking for projects where the user matches a passed in userProject
      const matchingProjects = this.availableProjects.filter(
        x =>
          params.userProjects.includes(x.data?.userStatus?.gid || '') && isInvitableOrReinvitable(x.data?.userStatus),
      );

      this.invitedProjects = matchingProjects.map(
        x =>
          new InviteToProject({
            key: x.data?.gid,
            project: x,
            projectRoleTitle: x.data?.userStatus?.title,
          }),
      );

      // look into the contracts, looking for contracts where the user matches a passed in userContract
      const matchingContracts = this.availableContracts.filter(
        x =>
          params.userContracts.includes(x.data?.userStatus?.gid || '') && isInvitableOrReinvitable(x.data?.userStatus),
      );

      this.invitedContracts = matchingContracts.map(
        x =>
          new InviteToContract({
            key: x.data?.gid,
            contract: x,
            contractRoleTitle: x.data?.userStatus?.title,
          }),
      );
    } else {
      // look into the projects, looking for projects where the project matches a passed in project
      const matchingProjects = this.availableProjects.filter(
        x => params.projects.includes(x.data?.gid || '') && isInvitableOrReinvitable(x.data?.userStatus),
      );
      this.invitedProjects = matchingProjects.map(
        x =>
          new InviteToProject({
            key: x.data?.gid,
            project: x,
            projectRoleTitle: x.data?.userStatus?.title,
          }),
      );
      // look into the contracts, looking for contracts where the contract matches a passed in contract
      const matchingContracts = this.availableContracts.filter(
        x => params.contracts.includes(x.data?.gid || '') && isInvitableOrReinvitable(x.data?.userStatus),
      );
      this.invitedContracts = matchingContracts.map(
        x =>
          new InviteToContract({
            key: x.data?.gid,
            contract: x,
            contractRoleTitle: x.data?.userStatus?.title,
          }),
      );
    }

    // Set the defaults options
    if (params.inviteToOrganisation) {
      this.selectedOrganisationOption = INVITE_ORGANISATION_YES;
    } else {
      this.selectedOrganisationOption = INVITE_ORGANISATION_NO;
    }

    // if we found anything to invite or reinvite them to, select the Yes
    if (this.invitedProjects.length > 0) {
      this.selectedProjectOption = INVITE_PROJECT_YES;
    } else {
      this.selectedProjectOption = INVITE_PROJECT_NO;
      // empty item in the list is better when the user selects Yes
      this.invitedProjects = [new InviteToProject({ key: uniqueId() })];
    }

    // if we found anything to invite or reinvite them to, select the Yes
    if (this.invitedContracts.length > 0) {
      this.selectedContractOption = INVITE_CONTRACT_YES;
    } else {
      this.selectedContractOption = INVITE_CONTRACT_NO;
      // empty item in the list is better when the user selects Yes
      this.invitedContracts = [new InviteToContract({ key: uniqueId() })];
    }

    this.isLoading = false;
  }

  private getInviteRequestModel(): PostCreateInvitationRequestModel {
    const result = new PostCreateInvitationRequestModel({
      firstName: this.firstName || '',
      lastName: this.lastName || '',
      email: this.emailAddress || '',
      organisations: [],
      projects: [],
      contracts: [],
    });
    if (this.canInviteToOrganisations && this.isInviteToOrganisationYesSelected && this.isInvitableToOrganisation) {
      result.organisations.push(
        new PostCreateInvitationUserContextModel({
          parentGid: this.organisationGid,
          title: this.organisationJobTitle || '',
        }),
      );
    }
    if (this.canInviteToProjects && this.isInviteToProjectsYesSelected) {
      result.projects = this.invitedProjects.map(
        x =>
          new PostCreateInvitationUserContextModel({
            parentGid: x.project?.data?.gid || '',
            title: x.projectRoleTitle || '',
          }),
      );
    }
    if (this.canInviteToContracts && this.isInviteToContractsYesSelected) {
      result.contracts = this.invitedContracts.map(
        x =>
          new PostCreateInvitationUserContextModel({
            parentGid: x.contract?.data?.gid || '',
            title: x.contractRoleTitle || '',
          }),
      );
    }
    return result;
  }

  public async open(params: InviteUserDialogParams): Promise<InviteUserDialogResult> {
    const baseParams = new FormModalParams({
      title: `Invite New User`,
      formRef: this.modalContentRef,
      confirmText: 'Invite User',
      cancelText: 'Dismiss',
      size: FormModalSizeEnum.ExtraLarge,
      onValidate: this.validate,
      onAfterFormMounted: () => this.initialise(params),
      onBeforeConfirmClose: async () => {
        // Post the data to the Invite User Api
        const requestModel = this.getInviteRequestModel();
        const apiResponse = await executeApi(
          () => this.api.invitations().postCreateInvitation(undefined, requestModel),
          'Invite User',
        );
        if (apiResponse && apiResponse.success) {
          return true;
        }
        return false;
      },
    });
    const result = await this.formModalRef.open(baseParams);
    if (result.ok) {
      return new InviteUserDialogResult({});
    }
    return new InviteUserDialogResult({});
  }
}
