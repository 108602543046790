



















import OtCurrencyField from '@/components/global/ot-currency-field.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenTextValue } from '../models/data-driven-result';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';

@Component({
  components: {
    OtCurrencyField,
  },
})
export default class WfCurrencyEntryEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private label?: string | null = null;
  private hint?: string | null = null;
  private placeholder?: string | null = null;
  private requiredMessage?: string | null = null;

  // * COMPUTED
  private numberValuePrivate: number | null = null;
  private get numberValue() {
    return this.numberValuePrivate;
  }
  private set numberValue(value: number | null) {
    this.numberValuePrivate = value;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'TextValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-currency-entry-edit -> result -> ResultType is incorrect. Expected TextValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const text = this.result?.text ?? null;
    if (text === null) {
      this.numberValuePrivate = null;
      return;
    }
    const parsed = parseFloat(text);
    if (isNaN(parsed)) {
      console.warn(`wf-currency-entry-edit -> valueChanged -> cannot parse "{text}" as a float. Ignoring value`);
      this.numberValuePrivate = null;
      return;
    }
    this.numberValuePrivate = parsed;
  }

  // * METHODS

  public onChange() {
    const result = new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: this.numberValue === null ? '' : this.numberValue.toString(),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.hint = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : undefined;

    this.valueChanged();
  }
}
