














































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import { formatDateAndTime } from '@/utils/date-utils';
import { OtStatusType, OtUserStatus } from '@/types/status-enums';
import { OrganisationUserDetails } from '../models/organisation-user-models';
import { vxm } from '@/store';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtOrganisationUsersDetails extends Vue {
  // * PROPS
  @Prop() private organisationUser!: OrganisationUserDetails;

  // * REFS

  // * DATA

  // * COMPUTED

  private get orgId() {
    return this.$route.params.orgId || '';
  }

  private get jobTitle() {
    if (!this.organisationUser.organisationUserStatus) {
      return '';
    }
    return this.organisationUser.organisationUserStatus?.title;
  }

  private get hasOrgStatus() {
    return Boolean(this.organisationUser.organisationUserStatus);
  }

  private get canEdit(): boolean {
    const profile = vxm.userProfile.userProfile;
    const isOrgUser = profile?.organisations?.some(org => org.gid === this.orgId);
    return Boolean(isOrgUser);
  }

  // * WATCHERS

  // * METHODS
  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date);
  }

  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  // * LIFECYCLE
}
