








import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import OtDangerModal from '@/components/global/modal/ot-danger-modal.vue';
import { DangerModalParams } from '../global/modal/form-modal-models';
import { IDataDrivenLayout } from '@/wf-components/models/data-driven-layout';
import { sanitizer } from '@/utils/xss-utils';
import { IWorkflowConfirmDialog } from '@/wf-components/models/workflow-component-definition';

@Component({
  components: {
    OtDangerModal,
  },
})
export default class OtWorkflowConfirmDialog extends Vue implements IWorkflowConfirmDialog {
  // * PROPS
  @Prop() private layout!: IDataDrivenLayout;

  // * REFS
  @Ref('dangerModalRef') private readonly dangerModalRef!: OtDangerModal;

  // * DATA

  // * COMPUTED
  private get bodyContent() {
    return sanitizer.process(this.layout.description || '');
  }

  // * WATCHERS

  // * METHODS
  public async open() {
    const params = new DangerModalParams({
      title: this.layout.title || undefined,
      titleIcon: '$alertTriangle',
      confirmText: this.layout.dialogButtonOverrides?.okButton?.buttonText || undefined,
      cancelText: this.layout.dialogButtonOverrides?.cancelButton?.buttonText || undefined,
      hideConfirmButton: this.layout.dialogButtonOverrides?.okButton?.isHidden || false,
      hideCancelButton: this.layout.dialogButtonOverrides?.cancelButton?.isHidden || false,
    });
    return await this.dangerModalRef.open(params);
  }

  // * LIFECYCLE
}
