import {
  ProjectStatus as ApiProjectStatus,
  GetProjectsProjectModel,
  GetProjectDetailsResponseModel,
  BasicProjectModel,
  GetProjectsContractModel,
  ProjectStatus,
  GetProjectsSegmentInstanceModel,
  AddressModel,
  PostUpsertProjectDetailsRequestModel,
} from '@/services/generated/api';
import { TrafficLights } from '@/components/global/common-models';
import { OtContractStatus, OtProjectStatus, OtSegmentResolution, OtSegmentStatus } from '@/types/status-enums';
import { compareAsc } from 'date-fns';
import { ZonelessDate } from '@/types/zoneless-date';
import { sortNullableNumbers } from '@/components/global/table/ot-table-utils';
import {
  IFilterableSegmentInstance,
  parsedOtSegmentResolutions,
  parsedOtSegmentStatuses,
  SegmentInstance,
} from '../claims/claims-models';
import { parsedOtContractEnums } from './contracts/contract-models';
import { UserStatus } from '@/models/user-models';
import { StreetAddressModel } from '@/models/shared-models';

export const parsedOtProjectStatusEnums: { [key in ApiProjectStatus]: OtProjectStatus } = {
  [ApiProjectStatus.Active]: OtProjectStatus.Active,
  [ApiProjectStatus.Complete]: OtProjectStatus.Complete,
  [ApiProjectStatus.Pending]: OtProjectStatus.Pending,
  [ApiProjectStatus.Suspended]: OtProjectStatus.Suspended,
};

export const parsedApiProjectStatusEnums: { [key in OtProjectStatus]: ApiProjectStatus } = {
  [OtProjectStatus.Active]: ApiProjectStatus.Active,
  [OtProjectStatus.Complete]: ApiProjectStatus.Complete,
  [OtProjectStatus.Pending]: ApiProjectStatus.Pending,
  [OtProjectStatus.Suspended]: ApiProjectStatus.Suspended,
};

// create the order for the status of the project
const OtProjectEnumsOrder: { [key in OtProjectStatus]: number } = {
  [OtProjectStatus.Active]: 1,
  [OtProjectStatus.Pending]: 2,
  [OtProjectStatus.Suspended]: 3,
  [OtProjectStatus.Complete]: 4,
};

//  map the project statuses
export const OtProjectStatusMap = new Map<OtProjectStatus, string>([
  [OtProjectStatus.Active, 'Active'],
  [OtProjectStatus.Pending, 'Pending'],
  [OtProjectStatus.Suspended, 'Suspended'],
  [OtProjectStatus.Complete, 'Complete'],
]);

export interface IProjectType {
  gid: string;
  name: string;
}

export class ProjectType implements IProjectType {
  public gid!: string;
  public name!: string;

  public constructor(value: IProjectType) {
    Object.assign(this, value);
  }
}

export interface IProjectProjectType {
  projectType: IProjectType;
}

export class ProjectProjectType implements IProjectProjectType {
  public projectType!: ProjectType;
}

export interface IProjectSegmentInstance extends IFilterableSegmentInstance {
  gid: string;
  reference: string;
  dueDate: ZonelessDate | null;
}

export class ProjectSegmentInstance implements IProjectSegmentInstance {
  public gid!: string;
  public reference!: string;
  public dueDate!: ZonelessDate | null;
  public status!: OtSegmentStatus;
  public resolution!: OtSegmentResolution | null;

  public constructor(value: IProjectSegmentInstance) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProjectSegmentInstance({
      gid: '',
      reference: '',
      dueDate: null,
      status: OtSegmentStatus.InProgress,
      resolution: null,
    });
  }

  public static createFromApiResponse(value: GetProjectsSegmentInstanceModel): ProjectSegmentInstance {
    return new ProjectSegmentInstance({
      gid: value.gid,
      reference: value.reference,
      dueDate: value.dueDate ? new ZonelessDate(value.dueDate) : null,
      status: parsedOtSegmentStatuses[value.status],
      resolution: value.resolution ? parsedOtSegmentResolutions[value.resolution] : null,
    });
  }
}

export interface IProjectContract {
  gid: string;
  name: string;
  status: OtContractStatus;
  segmentInstances: ProjectSegmentInstance[];
  contractUser: UserStatus | null;
}

export class OtProjectContract implements IProjectContract {
  public gid!: string;
  public name!: string;
  public status!: OtContractStatus;
  public segmentInstances!: ProjectSegmentInstance[];
  public contractUser!: UserStatus | null;

  public constructor(value: IProjectContract) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OtProjectContract({
      gid: '',
      name: '',
      status: OtContractStatus.Active,
      segmentInstances: [],
      contractUser: null,
    });
  }

  public static createFromApiResponse(value: GetProjectsContractModel): OtProjectContract {
    return new OtProjectContract({
      gid: value.gid,
      name: value.name,
      status: parsedOtContractEnums[value.status],
      segmentInstances: value.segmentInstances.map(segmentInstance =>
        ProjectSegmentInstance.createFromApiResponse(segmentInstance),
      ),
      contractUser: value.contractUser ? UserStatus.createFromApiResponse(value.contractUser) : null,
    });
  }
}

export interface IProjectsProjectModel {
  gid: string;
  name: string;
  organisationGid: string;
  organisationName: string;
}

export interface IProject {
  gid: string;
  name: string;
  code: string;
  organisationGid: string;
  organisationName: string;
  status: OtProjectStatus;
  trafficLights: TrafficLights;
  adjustedProjectCompletionDate: ZonelessDate;
  contracts: OtProjectContract[];
  organisationUser: UserStatus | null;
  projectUser: UserStatus | null;
}

export class Project implements IProject {
  public gid!: string;
  public name!: string;
  public code!: string;
  public organisationGid!: string;
  public organisationName!: string;
  public status!: OtProjectStatus;
  public trafficLights!: TrafficLights;
  public adjustedProjectCompletionDate!: ZonelessDate;
  public contracts!: OtProjectContract[];
  public organisationUser!: UserStatus | null;
  public projectUser!: UserStatus | null;

  public constructor(value: IProject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new Project({
      gid: '',
      name: '',
      code: '',
      organisationGid: '',
      organisationName: '',
      status: OtProjectStatus.Active,
      trafficLights: {
        red: 0,
        yellow: 0,
        green: 0,
      },
      adjustedProjectCompletionDate: new ZonelessDate(),
      contracts: [],
      organisationUser: null,
      projectUser: null,
    });
  }

  public static createFromApiResponse(model: GetProjectsProjectModel): Project {
    return new Project({
      gid: model.gid || '',
      name: model.name,
      code: model.code,
      organisationGid: model.organisationGid || '',
      organisationName: model.organisationName || '',
      status: model.status
        ? parsedOtProjectStatusEnums[model.status]
        : parsedOtProjectStatusEnums[ProjectStatus.Pending],
      trafficLights: TrafficLights.createFromApiResponse(model.trafficLights) || TrafficLights.createEmpty(),
      adjustedProjectCompletionDate: new ZonelessDate(model.adjustedProjectCompletionDate),
      contracts: model.contracts.map(contract => OtProjectContract.createFromApiResponse(contract)),
      organisationUser: model.organisationUser ? UserStatus.createFromApiResponse(model.organisationUser) : null,
      projectUser: model.projectUser ? UserStatus.createFromApiResponse(model.projectUser) : null,
    });
  }

  public static compareByGid(a: Project, b: Project, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.gid.localeCompare(b.gid, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByName(a: Project, b: Project, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByTrafficLights(a: Project, b: Project, ascending: boolean) {
    return TrafficLights.defaultSorting(a.trafficLights, b.trafficLights, ascending);
  }

  public static compareByDate(a: Project, b: Project, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return compareAsc(a.adjustedProjectCompletionDate || 0, b.adjustedProjectCompletionDate || 0) * sortModifier;
  }

  public static compareByContracts(a: Project, b: Project, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return (a.contracts.length - b.contracts.length) * sortModifier;
  }

  public static compareByStatus(a: Project, b: Project, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const aOrder = a.status ? OtProjectEnumsOrder[a.status] : null;
    const bOrder = b.status ? OtProjectEnumsOrder[b.status] : null;

    return sortNullableNumbers(aOrder, bOrder) * sortModifier;
  }

  public static filterByKeyword(keyword: string, contract: Project) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return contract.name.match(regex);
    }
    return true;
  }

  public static filterByStatus(statuses: OtProjectStatus[], project: Project) {
    if (statuses.length && project.status) {
      return statuses.includes(project.status);
    }
    return true;
  }

  public static filterByClaimStatus(statuses: Array<OtSegmentStatus | OtSegmentResolution>, project: Project) {
    if (statuses.length === 0) {
      // they don't want to filter by claim status at all, so this project matches
      return true;
    } else if (project.contracts.length === 0) {
      // they do want to filter by claim status, but we have no contracts, so we can't possibly have claims
      // matching the status
      return false;
    } else {
      return project.contracts.some(contract =>
        contract.segmentInstances.some(segment => SegmentInstance.filterByStatus(statuses, segment)),
      );
    }
  }
}
export interface IProjectDetails {
  gid: string;
  organisationGid: string;
  organisationName: string;
  name: string;
  description: string | undefined;
  status: OtProjectStatus;
  projectTypeGid: string;
  projectCode: string;
  projectTypeName: string;
  address: StreetAddressModel;
  ownerOrganisationName: string;
  ownerName: string;
  ownerJobTitle: string | undefined;
  ownerMobile: string;
  ownerPhone: string | undefined;
  ownerEmail: string;
  ownerFax: string | undefined;
  managerOrganisationName: string;
  managerName: string;
  managerJobTitle: string | undefined;
  managerMobile: string;
  managerPhone: string | undefined;
  managerEmail: string;
  managerFax: string | undefined;
  budgetAmount: number;
  costContingency: number | undefined;
  projectEndDate: ZonelessDate | null;
  adjustedProjectCompletionDate: ZonelessDate | null;
  timeContingencyDays: number | undefined;
  timezone: string;
  totalActiveUserCount: number;
  organisationUser: UserStatus | null;
  projectUser: UserStatus | null;
  contractUsers: UserStatus[];
}

export class ProjectDetails implements IProjectDetails {
  public gid!: string;
  public organisationGid!: string;
  public organisationName!: string;
  public name!: string;
  public description!: string;
  public status!: OtProjectStatus;
  public projectTypeGid!: string;
  public projectCode!: string;
  public projectTypeName!: string;
  public address!: StreetAddressModel;
  public ownerOrganisationName!: string;
  public ownerName!: string;
  public ownerJobTitle!: string | undefined;
  public ownerMobile!: string;
  public ownerPhone: string | undefined;
  public ownerEmail!: string;
  public ownerFax: string | undefined;
  public managerOrganisationName!: string;
  public managerName!: string;
  public managerJobTitle!: string | undefined;
  public managerMobile!: string;
  public managerPhone: string | undefined;
  public managerEmail!: string;
  public managerFax!: string | undefined;
  public budgetAmount!: number;
  public costContingency!: number | undefined;
  public projectEndDate: ZonelessDate | null = null;
  public adjustedProjectCompletionDate: ZonelessDate | null = null;
  public timeContingencyDays!: number | undefined;
  public timezone!: string;
  public totalActiveUserCount!: number;
  public organisationUser!: UserStatus | null;
  public projectUser!: UserStatus | null;
  public contractUsers!: UserStatus[];

  public constructor(value: IProjectDetails) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProjectDetails({
      gid: '',
      organisationGid: '',
      organisationName: '',
      name: '',
      description: '',
      status: OtProjectStatus.Active,
      projectTypeGid: '',
      projectCode: '',
      projectTypeName: '',
      address: StreetAddressModel.createEmpty(),
      ownerOrganisationName: '',
      ownerName: '',
      ownerJobTitle: '',
      ownerMobile: '',
      ownerPhone: '',
      ownerEmail: '',
      ownerFax: '',
      managerOrganisationName: '',
      managerName: '',
      managerJobTitle: '',
      managerMobile: '',
      managerPhone: '',
      managerEmail: '',
      managerFax: '',
      budgetAmount: 0,
      costContingency: 0,
      projectEndDate: new ZonelessDate(),
      adjustedProjectCompletionDate: new ZonelessDate(),
      timeContingencyDays: 0,
      timezone: '',
      totalActiveUserCount: 0,
      organisationUser: null,
      projectUser: null,
      contractUsers: [],
    });
  }
  // STEREOTYPE: TRANSFORM API RESPONSE TO ONETRACK MODEL
  public static createFromApiResponse(model: GetProjectDetailsResponseModel): ProjectDetails {
    return new ProjectDetails({
      gid: model.gid,
      organisationGid: model.organisationGid,
      organisationName: model.organisationName,
      name: model.name,
      description: model.description,
      status: model.status ? parsedOtProjectStatusEnums[model.status] : OtProjectStatus.Active,
      projectTypeGid: model.projectTypeGid,
      projectCode: model.code,
      projectTypeName: model.projectTypeName,
      address: StreetAddressModel.createFromApiResponse(model.address),
      ownerOrganisationName: model.ownerOrganisationName,
      ownerName: model.ownerName,
      ownerJobTitle: model.ownerJobTitle || '',
      ownerMobile: model.ownerMobile,
      ownerPhone: model.ownerPhone || '',
      ownerEmail: model.ownerEmail,
      ownerFax: model.ownerFax || '',
      managerOrganisationName: model.managerOrganisationName,
      managerName: model.managerName,
      managerJobTitle: model.managerJobTitle || '',
      managerMobile: model.managerMobile,
      managerPhone: model.managerPhone || '',
      managerEmail: model.managerEmail,
      managerFax: model.managerFax || '',
      budgetAmount: model.budgetAmount,
      costContingency: model.costContingency || 0,
      projectEndDate: new ZonelessDate(model.projectEndDate),
      adjustedProjectCompletionDate: new ZonelessDate(model.adjustedProjectCompletionDate),
      timeContingencyDays: model.timeContingencyDays || 0,
      timezone: model.timezone,
      totalActiveUserCount: model.totalActiveUserCount,
      organisationUser: model.organisationUser ? UserStatus.createFromApiResponse(model.organisationUser) : null,
      projectUser: model.projectUser ? UserStatus.createFromApiResponse(model.projectUser) : null,
      contractUsers: model.contractUsers.map(user => UserStatus.createFromApiResponse(user)),
    });
  }
}

export interface IProjectDetailsFormObject {
  organisationGid: string | null;
  organisationName: string | null;
  name: string | null;
  description: string | null;
  projectStatus: OtProjectStatus | null;
  projectCode: string | null;
  projectTypeName: string | null;
  address: StreetAddressModel | null;
  ownerOrganisationName: string | null;
  ownerName: string | null;
  ownerJobTitle: string | null;
  ownerMobile: string | null;
  ownerPhone: string | null;
  ownerEmail: string | null;
  ownerFax: string | null;
  managerOrganisationName: string | null;
  managerName: string | null;
  managerJobTitle: string | null;
  managerMobile: string | null;
  managerPhone: string | null;
  managerEmail: string | null;
  managerFax: string | null;
  budgetAmount: number | null;
  costContingency: number | null;
  projectEndDate: ZonelessDate | null;
  adjustedProjectCompletionDate: ZonelessDate | null;
  timeContingencyDays: number | null;
  timezone: string | null;
}

export class ProjectDetailsFormObject implements IProjectDetailsFormObject {
  public organisationGid: string | null = null;
  public organisationName: string | null = null;
  public name: string | null = null;
  public description: string | null = null;
  public projectStatus: OtProjectStatus | null = null;
  public projectTypeGid: string | null = null;
  public projectCode: string | null = null;
  public projectTypeName: string | null = null;
  public address: StreetAddressModel | null = null;
  public ownerOrganisationName: string | null = null;
  public ownerName: string | null = null;
  public ownerJobTitle: string | null = null;
  public ownerMobile: string | null = null;
  public ownerPhone: string | null = null;
  public ownerEmail: string | null = null;
  public ownerFax: string | null = null;
  public managerOrganisationName: string | null = null;
  public managerName: string | null = null;
  public managerJobTitle: string | null = null;
  public managerMobile: string | null = null;
  public managerPhone: string | null = null;
  public managerEmail: string | null = null;
  public managerFax: string | null = null;
  public budgetAmount: number | null = null;
  public costContingency: number | null = null;
  public projectEndDate: ZonelessDate | null = null;
  public adjustedProjectCompletionDate: ZonelessDate | null = null;
  public timeContingencyDays: number | null = null;
  public timezone: string | null = null;

  public constructor(value: IProjectDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProjectDetailsFormObject({
      organisationGid: '',
      organisationName: '',
      name: '',
      description: '',
      projectStatus: OtProjectStatus.Pending,
      projectCode: '',
      projectTypeName: '',
      address: StreetAddressModel.createEmpty(),
      ownerOrganisationName: '',
      ownerName: '',
      ownerJobTitle: '',
      ownerMobile: '',
      ownerPhone: '',
      ownerEmail: '',
      ownerFax: '',
      managerOrganisationName: '',
      managerName: '',
      managerJobTitle: '',
      managerMobile: '',
      managerPhone: '',
      managerEmail: '',
      managerFax: '',
      budgetAmount: 0,
      costContingency: 0,
      projectEndDate: new ZonelessDate(),
      adjustedProjectCompletionDate: new ZonelessDate(),
      timeContingencyDays: 0,
      timezone: '',
    });
  }

  public static createFromApiResponse(model: GetProjectDetailsResponseModel): ProjectDetailsFormObject {
    return new ProjectDetailsFormObject({
      organisationGid: model.organisationGid,
      organisationName: model.organisationName,
      name: model.name,
      description: model.description,
      projectStatus: model.status ? parsedOtProjectStatusEnums[model.status] : null,
      projectCode: model.code,
      projectTypeName: model.projectTypeName,
      address: model.address ? StreetAddressModel.createFromApiResponse(model.address) : null,
      ownerOrganisationName: model.ownerOrganisationName,
      ownerName: model.ownerName,
      ownerJobTitle: model.ownerJobTitle !== undefined ? model.ownerJobTitle : null,
      ownerMobile: model.ownerMobile,
      ownerPhone: model.ownerPhone !== undefined ? model.ownerPhone : null,
      ownerEmail: model.ownerEmail,
      ownerFax: model.ownerFax !== undefined ? model.ownerFax : null,
      managerOrganisationName: model.managerOrganisationName || '',
      managerName: model.managerName,
      managerJobTitle: model.managerJobTitle !== undefined ? model.managerJobTitle : null,
      managerMobile: model.managerMobile,
      managerPhone: model.managerPhone !== undefined ? model.managerPhone : null,
      managerEmail: model.managerEmail,
      managerFax: model.managerFax !== undefined ? model.managerFax : null,
      budgetAmount: model.budgetAmount,
      costContingency: model.costContingency !== undefined ? model.costContingency : null,
      projectEndDate: model.projectEndDate ? new ZonelessDate(model.projectEndDate) : null,
      adjustedProjectCompletionDate: model.adjustedProjectCompletionDate
        ? new ZonelessDate(model.adjustedProjectCompletionDate)
        : null,
      timeContingencyDays: model.timeContingencyDays !== undefined ? model.timeContingencyDays : null,
      timezone: model.timezone,
    });
  }

  public static createRequestModel(model: ProjectDetailsFormObject): PostUpsertProjectDetailsRequestModel {
    // yes, this is dumb. But the my formatters are fighting over this if it is in any kind of ternary
    // eslint reckons it should be formatted a certain way, but the formatter is not formatting it that way
    // so I'm declaring the dumb address here, then only using it in a ternary down below
    // ugh
    const addressFromModel = new AddressModel({
      line1: model.address?.addressLine1 || '',
      line2: model.address?.addressLine2 || undefined,
      suburb: model.address?.suburb || '',
      postcode: model.address?.postcode || '',
      state: model.address?.state || '',
      country: model.address?.country || '',
    });

    return new PostUpsertProjectDetailsRequestModel({
      organisationGid: model.organisationGid || '',
      code: model.projectCode || '',
      name: model.name || '',
      description: model.description || '',
      projectStatus: model.projectStatus ? parsedApiProjectStatusEnums[model.projectStatus] : ApiProjectStatus.Active,
      projectTypeName: model.projectTypeName || '',
      address: model.address ? addressFromModel : new AddressModel(),
      ownerOrganisationName: model.ownerOrganisationName || '',
      ownerName: model.ownerName || '',
      ownerJobTitle: model.ownerJobTitle || '',
      ownerMobile: model.ownerMobile || '',
      ownerPhone: model.ownerPhone || '',
      ownerEmail: model.ownerEmail || '',
      ownerFax: model.ownerFax || '',
      managerOrganisationName: model.managerOrganisationName || '',
      managerName: model.managerName || '',
      managerJobTitle: model.managerJobTitle || '',
      managerMobile: model.managerMobile || '',
      managerPhone: model.managerPhone || '',
      managerEmail: model.managerEmail || '',
      managerFax: model.managerFax || '',
      budgetAmount: model.budgetAmount || 0,
      costContingency: model.costContingency || 0,
      projectEndDate: model.projectEndDate ? ZonelessDate.toZonelessDateFormat(model.projectEndDate) : ' ',
      timeContingencyDays: model.timeContingencyDays || 0,
      timezone: model.timezone || '',
    });
  }
}

export interface IProjectBasic {
  gid: string;
  organisationGid: string;
  name: string;
  timezone: string;
  status: OtProjectStatus;
}

export class ProjectBasic implements IProjectBasic {
  public gid!: string;
  public organisationGid!: string;
  public name!: string;
  public timezone!: string;
  public status!: OtProjectStatus;

  public constructor(value: IProjectBasic) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ProjectBasic({
      gid: '',
      organisationGid: '',
      name: '',
      timezone: '',
      status: OtProjectStatus.Pending,
    });
  }

  public static createFromApiResponse(model: BasicProjectModel) {
    return new ProjectBasic({
      gid: model.gid || '',
      organisationGid: model.organisationGid || '',
      name: model.name || '',
      timezone: model.timezone || '',
      // not sure how I feel about a default. This will never be null, but the API thinks it can be
      status: model.status ? parsedOtProjectStatusEnums[model.status] : OtProjectStatus.Active,
    });
  }
}

export interface IProjectsProjects {
  project: GetProjectsProjectModel;
}
