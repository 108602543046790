













































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTable from '@/components/global/table/ot-table.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtSwitch from '@/components/global/ot-switch.vue';
import { dirtyFormClass } from '@/utils/validation-utils';
import { IExtendedColumnData } from '@/components/global/table/ot-table-models';
import BasicWorkflow, { ContractWorkflow } from './contract-models';
import OtApi, { executeApi } from '@/services/api.service';
import { IWizardContentComponent } from '@/types/wizard-types';
import { v4 as uuid } from 'uuid';

class WorkflowTableItem {
  public gid = '';
  public workflowGid = '';
  public name = '';

  public isAvailable = false;
  public requiresAmendments = true;
  public contractWorkflowCannotBeDeleted = false;
}

@Component({
  components: {
    OtRadioGroup,
    OtTable,
    OtTableHeader,
    OtSwitch,
  },
})
export default class OtContractSetupForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private typeGid!: string;
  @Prop() private contractWorkflows!: ContractWorkflow[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private originalThumbprint = '';
  private currentThumbprint = '';
  private availableWorkflows: BasicWorkflow[] = [];
  private isLoading = false;
  private showErrorMessage = false;

  private noOption: IRadioGroupOption = {
    key: uuid(),
    label: 'No, keep the default workflow selections',
  };

  private yesOption: IRadioGroupOption = {
    key: uuid(),
    label: 'Yes, this contract requires amendments to the default workflows',
  };
  private amendmentOptions: IRadioGroupOption[] = [this.noOption, this.yesOption];

  private tableColumns: IExtendedColumnData<unknown>[] = [
    {
      index: 0,
      label: 'Workflow',
      key: 'workflow',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 1,
      label: 'Available for this contract?',
      key: 'isAvailable',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: 'Requires Amendments',
      key: 'requiresAmendments',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];

  // * COMPUTED
  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  private get localContractWorkflows(): ContractWorkflow[] {
    return this.contractWorkflows;
  }
  private set localContractWorkflows(val: ContractWorkflow[]) {
    this.$emit('update:contractWorkflows', val);
  }

  private tableBodyData: WorkflowTableItem[] = [];

  private get disableRows() {
    return this.applyAmendmentsOption === this.noOption;
  }

  private applyAmendmentsOptionPrivate: IRadioGroupOption = this.noOption;
  private get applyAmendmentsOption(): IRadioGroupOption {
    return this.applyAmendmentsOptionPrivate;
  }
  private set applyAmendmentsOption(val: IRadioGroupOption) {
    this.applyAmendmentsOptionPrivate = val;

    if (val === this.noOption) {
      // Set all the switches to their default state
      for (const workflow of this.tableBodyData) {
        workflow.isAvailable = true;
        workflow.requiresAmendments = false;
      }
      this.updateLocalContractWorkflows();
    }
  }

  private get errorMessage() {
    return 'At least one workflow must be selected.';
  }

  // * WATCHERS

  // * METHODS

  private async getWorkflows() {
    const apiResponse = await executeApi(
      () => this.api.contractTypes().getAvailableWorkflows(this.typeGid),
      'Load Available Workflows',
    );
    if (apiResponse.success && apiResponse.data) {
      this.availableWorkflows = apiResponse.data.map(w => BasicWorkflow.createFromApiResponse(w));
    }
  }

  private getWorkflowThumbprint(workflows: ContractWorkflow[]): string {
    const vals = workflows.map(w => {
      return {
        workflowGid: w.workflowGid,
        isAmended: w.isAmended,
      };
    });
    return JSON.stringify(vals);
  }

  private isAvailableChanged(value: boolean, workflow: WorkflowTableItem) {
    workflow.isAvailable = value;
    if (!value) {
      workflow.requiresAmendments = false;
    } else {
      this.showErrorMessage = false;
    }
    this.updateLocalContractWorkflows();
  }

  private requiresAmendmentsChanged(value: boolean, workflow: WorkflowTableItem) {
    workflow.requiresAmendments = value;
    this.updateLocalContractWorkflows();
  }

  private updateLocalContractWorkflows() {
    const workflows = this.tableBodyData
      .filter(e => e.isAvailable)
      .map(e => {
        return new ContractWorkflow({
          gid: e.gid || uuid(),
          workflowGid: e.workflowGid.toLowerCase(),
          isAmended: e.requiresAmendments,
          name: e.name,
          shortName: '',
        });
      });

    this.localContractWorkflows = workflows;
    this.currentThumbprint = this.getWorkflowThumbprint(workflows);
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  public validate() {
    if (!this.contractWorkflows.length) {
      this.showErrorMessage = true;
      return false;
    }
    return true;
  }

  public async submit(validate = true): Promise<ContractWorkflow[] | null> {
    if (!validate || this.validate()) {
      this.setFormToCleanState();
      return this.contractWorkflows;
    }
    return null;
  }

  private buildTableBodyData() {
    const hasInitialWorkflows = Boolean(this.contractWorkflows?.length);
    this.tableBodyData = this.availableWorkflows.map(w => {
      const workflow = this.contractWorkflows?.find(iw => iw.workflowGid?.toLowerCase() === w.gid?.toLowerCase());
      const result: WorkflowTableItem = {
        gid: workflow?.gid || uuid(),
        workflowGid: w.gid.toLowerCase(),
        name: w.name,
        isAvailable: hasInitialWorkflows ? Boolean(workflow) : true,
        requiresAmendments: hasInitialWorkflows ? Boolean(workflow?.isAmended) : false,
        contractWorkflowCannotBeDeleted: w.contractWorkflowCannotBeDeleted,
      };
      return result;
    });
  }

  // * LIFECYCLE
  private async created() {
    this.isLoading = true;

    await this.getWorkflows();

    this.buildTableBodyData();

    if (this.contractWorkflows) {
      this.updateLocalContractWorkflows();
      this.originalThumbprint = this.currentThumbprint;

      // Mark the radio option as 'Yes' if any of the workflows aren't marked as available
      this.applyAmendmentsOption = this.tableBodyData.some(w => !w.isAvailable || w.requiresAmendments)
        ? this.yesOption
        : this.noOption;
    }

    this.isLoading = false;
  }
}
