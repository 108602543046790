
















































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtCurrencyField from '@/components/global/ot-currency-field.vue';
import OtButton from '@/components/global/ot-button.vue';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenOptionsList } from '../models/data-driven-result';
import { IValidate } from '@/utils/type-utils';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import {
  numericAndDecimalAndNegativeSignKeyboardEventKeys,
  numericAndDecimalKeyboardEventKeys,
  numericAndNegativeSignKeyboardEventKeys,
  numericKeyboardEventKeys,
} from '@/well-known-values/key-codes';
import { IInputIcon } from '@/components/global/common-models';

interface IDynamicNumberAndNameObject {
  key: string;
  number: number | null;
  numberAsString: string | null;
  name: string | null;
}

type NumberMode = 'percent' | 'currency' | 'general';
// yeah this looks dumb. It allows us to be certain that the string we have is valid for the type
// I don't like it, but it's only 3 values, it'll do
const modeLookup = new Map<string, NumberMode>([
  ['percent', 'percent'],
  ['currency', 'currency'],
  ['general', 'general'],
]);

@Component({
  components: {
    OtFieldArchetype,
    OtTextField,
    OtCurrencyField,
    OtButton,
  },
})
export default class WfDynamicListNumberAndNameEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS
  @Ref('checkboxRef') private checkboxRef!: IValidate;

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private placeholder: string | null = null;
  private displayPlaceholder = '';
  private isRequired = false;
  private showErrorMessage = false;
  private allowNegativeNumber = false;
  private mode: NumberMode = 'general';

  private tableBodyData: IDynamicNumberAndNameObject[] = [];

  private checkboxRules: Array<(value: boolean) => boolean | string> = [
    (value: boolean) => value === true || 'Required',
  ];

  // * COMPUTED
  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListModel') {
        return this.value.result;
      }
      console.warn(
        'wf-dynamic-list-text-field-number-and-name-edit -> result -> ResultType is incorrect. Expected OptionsListModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get tableHeader1() {
    const header = this.question.columnHeadersOrdered[0]?.title || '';
    if (!header) {
      console.warn('wf-dynamic-list-text-field-number-and-name-edit -> tableHeader1:  Column header title not found');
    }
    return header;
  }

  private get tableHeader2() {
    const header = this.question.columnHeadersOrdered[1]?.title || '';
    if (!header) {
      console.warn('wf-dynamic-list-text-field-number-and-name-edit -> tableHeader2:  Column header title not found');
    }
    return header;
  }

  private get buttonText() {
    return this.question.addButtonText || '';
  }

  private get controlIsValid() {
    // can't edit it, cannot be invalid
    if (this.readonly || this.disabled) {
      return true;
    }
    return !!this.tableBodyData.length;
  }

  private get isModeGeneral() {
    return this.mode === 'general';
  }
  private get isModePercent() {
    return this.mode === 'percent';
  }
  private get isModeCurrency() {
    return this.mode === 'currency';
  }
  private get appendIcon(): IInputIcon | undefined {
    return this.isModePercent ? { icon: '$percentSign', iconColor: 'black', action: null } : undefined;
  }

  private get shouldShowDisplayPlaceholder() {
    return this.readonly && this.tableBodyData.length == 0 && Boolean(this.displayPlaceholder);
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.tableBodyData =
      this.result?.values.map(v => {
        const parsed = parseFloat(v.title ?? '');
        return {
          key: v.key,
          numberAsString: v.title || null,
          number: isNaN(parsed) ? null : parsed,
          name: v.description || null,
        };
      }) || [];
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionsList({
      resultType: 'OptionsListModel',
      values: this.tableBodyData.map(
        (i, index) =>
          new OtDataDrivenValue({
            key: i.key,
            title: this.getTitleFromitem(i),
            description: i.name,
            orderIndex: index,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  private addNewItem() {
    this.tableBodyData.push({ key: uuid(), number: null, numberAsString: null, name: null });
    this.onChange();
  }

  private getTitleFromitem(item: IDynamicNumberAndNameObject) {
    if (item.numberAsString !== null) {
      return item.numberAsString;
    }
    if (item.number === null) {
      return null;
    }
    return item.number.toString();
  }

  private deleteItem(item: IDynamicNumberAndNameObject) {
    const foundIndex = this.tableBodyData.findIndex(i => i.key === item.key);
    if (foundIndex !== -1) {
      this.tableBodyData.splice(foundIndex, 1);
      this.onChange();
    }
  }

  private setNumberAsStringValue(item: IDynamicNumberAndNameObject, value: string) {
    item.numberAsString = value;
    item.number = null;

    // have a crack at parsing it. If it is a valid number, get the value out to the outside world. Otherwise, nah, sit on it
    if (value === '') {
      this.onChange();
    }
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
      // not good. Hold onto it
    }
    // must be a Good Number
    this.onChange();
  }
  private setNumberValue(item: IDynamicNumberAndNameObject, value: number) {
    item.number = value;
    item.numberAsString = null;
    this.onChange();
  }
  private setNameValue(item: IDynamicNumberAndNameObject, value: string) {
    item.name = value;
    this.onChange();
  }
  private filterNumberKeypress(event: KeyboardEvent) {
    const targetInput = event.target as HTMLInputElement;
    if (targetInput) {
      const inputValue = targetInput.value;
      if (inputValue.includes('.') && event.key === '.') {
        event.preventDefault();
      } else if (inputValue.includes('-') && event.key === '-') {
        event.preventDefault();
      }
      // I had an idea to only allow - at the start, but to do that properly involves looking at selection start and honestly, meh
    }
    const allowed = this.allowNegativeNumber
      ? numericAndDecimalAndNegativeSignKeyboardEventKeys
      : numericAndDecimalKeyboardEventKeys;
    if (!allowed.includes(event.key)) {
      event.preventDefault();
    }
  }
  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.placeholder = this.question.placeholder;
    this.isRequired = this.question.isMandatory;
    this.allowNegativeNumber =
      this.question.configs?.some(x => x.key === 'allowNegativeNumber' && x.value === 'true') ?? false;
    // bit of a dance
    // the config might exist, or it might have an invalid value of "wombat"
    // so, we get the raw, look it up in the handy dandy Map, and coalesce to the default if we didn't find it in the lookup
    const rawMode = this.question.configs?.find(x => x.key === 'mode')?.value;
    const translatedMode = modeLookup.get(rawMode || '');
    this.mode = translatedMode ?? 'general';
    this.displayPlaceholder = this.question.configs?.find(x => x.key === 'displayPlaceholder')?.value ?? '';

    this.valueChanged();
  }

  private mounted() {
    this.$watch('checkboxRef.validationState', (state: string) => {
      this.showErrorMessage = state === 'error';
    });
  }
}
