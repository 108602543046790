







































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';
import { OtProjectStatus, OtStatusType, OtUserStatus } from '@/types/status-enums';
import { formatDateAndTime } from '@/utils/date-utils';
import { formatCurrency } from '@/utils/currency-format-utils';
import { ProjectDetails } from './project-models';
import { ROUTE_PROJECT_EDIT } from './projects-routes';
import { returnAddressString } from '@/utils/address-utils';

@Component({
  components: {
    OtTag,
    OtButton,
    OtExpansionPanel,
  },
})
export default class OtProjectDetails extends Vue {
  // * PROPS

  @Prop() private project!: ProjectDetails;

  // * REFS

  // * DATA

  // * COMPUTED
  private get isOrganisationUser(): boolean {
    return this.project.organisationUser?.status === OtUserStatus.Active;
  }
  private get isProjectUser(): boolean {
    return this.project.projectUser?.status === OtUserStatus.Active;
  }

  private get projectAddress(): string {
    return returnAddressString(this.project.address);
  }

  private formatCurrency(amount: number | undefined): string {
    if (amount === undefined) {
      return '';
    }
    return formatCurrency(amount);
  }

  private get roundedTimeContingencyDays(): number {
    if (this.project.timeContingencyDays === undefined) {
      return 0;
    }
    // im making sure that there is never any decimals in the time contingency days
    //  as per the ENG "Digits only, no decimals"
    return Math.round(this.project.timeContingencyDays);
  }

  get editRoute(): string {
    return ROUTE_PROJECT_EDIT;
  }

  // * WATCHERS

  // * METHODS

  // following the naming convention from contract details
  private getTagProjectStatus(status: OtProjectStatus): TagStatus {
    return { type: OtStatusType.Project, status };
  }

  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date, { excludeTime: true });
  }
}

// * LIFECYCLE
