import BasicWorkflow, { BasicContract } from '@/areas/projects/contracts/contract-models';
import { sortNullableDates, sortNullableNumbers } from '@/components/global/table/ot-table-utils';
import {
  ActionOverrideModel,
  BasicClaimModel as ApiBasicClaim,
  BasicSegmentModel as ApiBasicSegment,
  BasicSummaryModel as ApiBasicSummary,
  DataDrivenSegmentConfigModel as ApiDataDrivenSegmentConfigModel,
  DataEntryRequirement as ApiDataEntryRequirement,
  GetSegmentInstanceRecommendationEmailModel as ApiGetSegmentInstanceRecommendationEmailModel,
  SegmentInstanceEmailModel as ApiSegmentInstanceEmailModel,
  SegmentInstanceStatusRequirement as ApiSegmentInstanceStatusRequirement,
  SegmentInstanceResolution as ApiSegmentResolution,
  SegmentInstanceStatus as ApiSegmentStatus,
  DataDrivenAvailableActionModel,
  EmailDetailsModel,
  EmailPropertiesModel,
  GetSegmentInstanceDetailsResponseModel,
  GetSegmentInstanceRecommendationRecommendationModel,
  GetSegmentInstanceRecommendationResponseModel,
  ListSegmentInstanceModel,
  PostFinaliseSegmentInstanceRequestModel,
  PostInsertSegmentInstanceRequestModel,
  PostSegmentInstanceRecommendationResponseModel,
  PostUpdateSegmentInstanceDetailsRequestModel,
} from '@/services/generated/api';
import { OtDataEntryRequirement } from '@/types/data-entry-requirement-enums';
import {
  OtDocumentReceivalType,
  parsedApiDocumentReceivalType,
  parsedOtDocumentReceivalType,
} from '@/types/document-receival-type';
import { OtSegmentInstanceStatusRequirement } from '@/types/segment-instance-status-requirement';
import {
  OtClosedSegmentStatusSet,
  OtSegmentResolution,
  OtSegmentStatus,
  OtTrafficLightColour,
  parsedOtTrafficLightColour,
} from '@/types/status-enums';
import { OtTimePeriodUnit, parsedOtTimePeriodUnit } from '@/types/unit-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { convertAndFormatDateAndTime, formatToTimezone } from '@/utils/date-utils';
import { durationString } from '@/utils/duration-utils';
import { deserializeWithCamelCaseKeys } from '@/utils/json-utils';
import {
  CalendarTypeEnum,
  SegmentProcessTypeEnum,
  parsedApiCalendarTypes,
  parsedOtSegmentProcessTypeEnum,
} from '@/wf-components/models/data-driven-enums';
import { OtDataDrivenModified } from '@/wf-components/models/data-driven-modified';
import { OtDataDrivenPhase } from '@/wf-components/models/data-driven-phase';
import { OtDataDrivenSummary } from '@/wf-components/models/data-driven-summary';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ProjectBasic, ProjectDetails } from '../projects/project-models';
import { parsedOtCalendarTypeEnum } from './../../wf-components/models/data-driven-enums';

export const parsedOtSegmentStatuses: { [key in ApiSegmentStatus]: OtSegmentStatus } = {
  [ApiSegmentStatus.InProgress]: OtSegmentStatus.InProgress,
  [ApiSegmentStatus.Suspended]: OtSegmentStatus.Suspended,
  [ApiSegmentStatus.InfoRequestedAndNotSuspended]: OtSegmentStatus.InfoRequestedAndNotSuspended,
  [ApiSegmentStatus.InfoRequestedAndSuspended]: OtSegmentStatus.InfoRequestedAndSuspended,
  [ApiSegmentStatus.Disputed]: OtSegmentStatus.Disputed,
  [ApiSegmentStatus.LegalDispute]: OtSegmentStatus.LegalDispute,
  [ApiSegmentStatus.Complete]: OtSegmentStatus.Completed,
  [ApiSegmentStatus.Withdrawn]: OtSegmentStatus.Withdrawn,
  [ApiSegmentStatus.Cancelled]: OtSegmentStatus.Cancelled,
};

const OtSegmentEnumsOrder: { [key in OtSegmentStatus]: number } = {
  [OtSegmentStatus.InProgress]: 1,
  [OtSegmentStatus.Suspended]: 2,
  [OtSegmentStatus.InfoRequestedAndNotSuspended]: 3,
  [OtSegmentStatus.InfoRequestedAndSuspended]: 4,
  [OtSegmentStatus.Disputed]: 5,
  [OtSegmentStatus.LegalDispute]: 6,
  [OtSegmentStatus.Completed]: 7,
  [OtSegmentStatus.Withdrawn]: 8,
  [OtSegmentStatus.Cancelled]: 9,
};

export const parsedApiSegmentStatuses: { [key in OtSegmentStatus]: ApiSegmentStatus } = {
  [OtSegmentStatus.InProgress]: ApiSegmentStatus.InProgress,
  [OtSegmentStatus.Suspended]: ApiSegmentStatus.Suspended,
  [OtSegmentStatus.InfoRequestedAndNotSuspended]: ApiSegmentStatus.InfoRequestedAndNotSuspended,
  [OtSegmentStatus.InfoRequestedAndSuspended]: ApiSegmentStatus.InfoRequestedAndSuspended,
  [OtSegmentStatus.Disputed]: ApiSegmentStatus.Disputed,
  [OtSegmentStatus.LegalDispute]: ApiSegmentStatus.LegalDispute,
  [OtSegmentStatus.Completed]: ApiSegmentStatus.Complete,
  [OtSegmentStatus.Withdrawn]: ApiSegmentStatus.Withdrawn,
  [OtSegmentStatus.Cancelled]: ApiSegmentStatus.Cancelled,
};

export const parsedOtSegmentResolutions: { [key in ApiSegmentResolution]: OtSegmentResolution } = {
  [ApiSegmentResolution.Accepted]: OtSegmentResolution.Accepted,
  [ApiSegmentResolution.Rejected]: OtSegmentResolution.Rejected,
  [ApiSegmentResolution.Indeterminate]: OtSegmentResolution.Indeterminate,
};

export const parsedApiSegmentResolutions: { [key in OtSegmentResolution]: ApiSegmentResolution } = {
  [OtSegmentResolution.Accepted]: ApiSegmentResolution.Accepted,
  [OtSegmentResolution.Rejected]: ApiSegmentResolution.Rejected,
  [OtSegmentResolution.Indeterminate]: ApiSegmentResolution.Indeterminate,
  [OtSegmentResolution.ValidWithDifferentTime]: ApiSegmentResolution.Accepted,
};

export const parsedOtDataEntryRequirement: { [key in ApiDataEntryRequirement]: OtDataEntryRequirement } = {
  [ApiDataEntryRequirement.Mandatory]: OtDataEntryRequirement.Mandatory,
  [ApiDataEntryRequirement.Optional]: OtDataEntryRequirement.Optional,
  [ApiDataEntryRequirement.Forbidden]: OtDataEntryRequirement.Forbidden,
};

export const parsedOtSegmentInstanceStatusRequirement: {
  [key in ApiSegmentInstanceStatusRequirement]: OtSegmentInstanceStatusRequirement;
} = {
  [ApiSegmentInstanceStatusRequirement.MustBeOpen]: OtSegmentInstanceStatusRequirement.MustBeOpen,
  [ApiSegmentInstanceStatusRequirement.CanBeOpenOrClosed]: OtSegmentInstanceStatusRequirement.CanBeOpenOrClosed,
  [ApiSegmentInstanceStatusRequirement.MustBeClosed]: OtSegmentInstanceStatusRequirement.MustBeClosed,
};

// this augments traffic light colour enum
// this is for the UI to filter on
export enum OtDueDateStatus {
  Open = 'OPEN',
  DeadlineApproaching = 'DEADLINE APPROACHING',
  Overdue = 'OVERDUE',
  Closed = 'CLOSED',
}

export interface ISegmentConfig {
  contractuallyRequired: boolean;
  parentRequired: OtDataEntryRequirement;
  validParentCodes: string[];
  documentRequiredOnCreation: OtDataEntryRequirement;
  phases: OtDataDrivenPhase[];
  parentSegmentInstanceStatusRequirement: OtSegmentInstanceStatusRequirement;
  // rfiConfig: OtSegmentRfiConfigModel | undefined;
  // defaultPhaseKey: string | undefined; don't care about default phase key
  copyResponsesFromParent: boolean;
  canBeCreatedFromScratch: boolean;
}

export class SegmentConfig implements ISegmentConfig {
  public contractuallyRequired!: boolean;
  public parentRequired!: OtDataEntryRequirement;
  public validParentCodes!: string[];
  public documentRequiredOnCreation!: OtDataEntryRequirement;
  public phases!: OtDataDrivenPhase[];
  public parentSegmentInstanceStatusRequirement!: OtSegmentInstanceStatusRequirement;
  public copyResponsesFromParent!: boolean;
  public canBeCreatedFromScratch!: boolean;

  constructor(value: ISegmentConfig) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenSegmentConfigModel) {
    return new SegmentConfig({
      contractuallyRequired: model.contractuallyRequired || false,
      parentRequired: parsedOtDataEntryRequirement[model.parentRequired],
      validParentCodes: model.validParentCodes || [],
      documentRequiredOnCreation: model.documentRequiredOnCreation
        ? parsedOtDataEntryRequirement[model.documentRequiredOnCreation]
        : OtDataEntryRequirement.Mandatory,
      phases: model.phases?.map(x => OtDataDrivenPhase.createFromApiResponse(x)) || [],
      parentSegmentInstanceStatusRequirement: model.parentSegmentInstanceStatusRequirement
        ? parsedOtSegmentInstanceStatusRequirement[model.parentSegmentInstanceStatusRequirement]
        : OtSegmentInstanceStatusRequirement.CanBeOpenOrClosed,
      copyResponsesFromParent: model.copyResponsesFromParent ?? true,
      canBeCreatedFromScratch: model.canBeCreatedFromScratch === undefined ? true : model.canBeCreatedFromScratch,
    });
  }

  public static createFromJson(json: string) {
    const object = JSON.parse(json, deserializeWithCamelCaseKeys) as ISegmentConfig;
    return new SegmentConfig({
      contractuallyRequired: object.contractuallyRequired || false,
      parentRequired: object.parentRequired || OtDataEntryRequirement.Forbidden,
      validParentCodes: object.validParentCodes || [],
      documentRequiredOnCreation: object.documentRequiredOnCreation || OtDataEntryRequirement.Mandatory,
      phases: object.phases || [],
      parentSegmentInstanceStatusRequirement:
        object.parentSegmentInstanceStatusRequirement || OtSegmentInstanceStatusRequirement.CanBeOpenOrClosed,
      copyResponsesFromParent: object.copyResponsesFromParent ?? true,
      canBeCreatedFromScratch: object.canBeCreatedFromScratch === undefined ? true : object.canBeCreatedFromScratch,
    });
  }
}

export interface IBasicSummmary {
  runGid: string;
  summary: OtDataDrivenSummary[];
  created: OtDataDrivenModified | null;
  finished: OtDataDrivenModified | null;
}

export class OtBasicSummary implements IBasicSummmary {
  runGid!: string;
  summary!: OtDataDrivenSummary[];
  created!: OtDataDrivenModified | null;
  finished!: OtDataDrivenModified | null;

  constructor(value: IBasicSummmary) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OtBasicSummary({
      runGid: '',
      summary: [],
      created: null,
      finished: null,
    });
  }

  public static createFromApiResponse(model: ApiBasicSummary) {
    return new OtBasicSummary({
      runGid: model.runGid,
      summary: model.summary.map(x => OtDataDrivenSummary.createFromApiResponse(x)),
      created: model.created ? OtDataDrivenModified.createFromApiResponse(model.created) : null,
      finished: model.finished ? OtDataDrivenModified.createFromApiResponse(model.finished) : null,
    });
  }
  public static sortByFinished(left: OtBasicSummary, right: OtBasicSummary) {
    if (left.finished === null && right.finished === null) {
      return 0;
    } else if (left.finished === null && right.finished !== null) {
      return 1;
    } else if (left.finished !== null && right.finished === null) {
      return -1;
    } else if (left.finished !== null && right.finished !== null) {
      // yeah, I know I don't need this 4th check. We've covered all the possibilities. Neither are null.
      // But typescript seems to think they might be, otherwise it complains "finished might be null"
      return left.finished.utc.valueOf() - right.finished.utc.valueOf();
    }
    // absolutely positively CANNOT hit this line. But, again, typescript thinks we can return undefined
    // so an extra return statement here to teach it what is what
    return 0;
  }
}

export interface IBasicSegment {
  gid: string;
  manifestSegmentGid: string;
  name: string;
  shortName: string;
  context: string | null;
  workflow: BasicWorkflow;
  config: SegmentConfig | null;
  segmentProcessType: SegmentProcessTypeEnum;
}

export class BasicSegment implements IBasicSegment {
  public gid!: string;
  public manifestSegmentGid!: string;
  public name!: string;
  public shortName!: string;
  public context!: string | null;
  public workflow!: BasicWorkflow;
  public config!: SegmentConfig | null;
  public segmentProcessType!: SegmentProcessTypeEnum;

  constructor(value: IBasicSegment) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new BasicSegment({
      gid: '',
      manifestSegmentGid: '',
      name: '',
      shortName: '',
      context: null,
      workflow: BasicWorkflow.createEmpty(),
      config: null,
      segmentProcessType: SegmentProcessTypeEnum.Claim,
    });
  }

  public static createFromApiResponse(model: ApiBasicSegment) {
    return new BasicSegment({
      gid: model.gid || '',
      manifestSegmentGid: model.manifestSegmentGid,
      name: model.name || '',
      shortName: model.shortName || '',
      context: model.context || null,
      workflow: model.workflow ? BasicWorkflow.createFromApiResponse(model.workflow) : BasicWorkflow.createEmpty(),
      config: model.config ? SegmentConfig.createFromApiResponse(model.config) : null,
      segmentProcessType: parsedOtSegmentProcessTypeEnum[model.segmentProcessType],
    });
  }
}

export interface ISegmentInstanceDetailsPost {
  segmentGid: string;
  projectGid: string;
  projectName: string;
  contractGid: string;
  workflowSegmentGid: string;
  parentClaimGid: string | null;
  reference: string;
  documentName: string | null;
  documentReceivalType: OtDocumentReceivalType | null;
  documentReceivalDescription: string | null;
  documentCreationDate: ZonelessDate | null;
  documentReceivedUtc: Date | null;
  documentDeemedReceivedUtc: Date | null;
  contractRowVersion: string;
}

export class SegmentInstanceDetailsPost implements ISegmentInstanceDetailsPost {
  public segmentGid!: string;
  public projectGid!: string;
  public projectName!: string;
  public contractGid!: string;
  public workflowSegmentGid!: string;
  public parentClaimGid!: string | null;
  public reference!: string;
  public documentName!: string | null;
  public documentReceivalType!: OtDocumentReceivalType | null;
  public documentReceivalDescription!: string | null;
  public documentCreationDate!: ZonelessDate | null;
  public documentReceivedUtc!: Date | null;
  public documentDeemedReceivedUtc!: Date | null;
  public contractRowVersion!: string;

  constructor(value: ISegmentInstanceDetailsPost) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new SegmentInstanceDetailsPost({
      segmentGid: '',
      projectGid: '',
      projectName: '',
      contractGid: '',
      workflowSegmentGid: '',
      parentClaimGid: null,
      reference: '',
      documentName: null,
      documentReceivalType: null,
      documentReceivalDescription: null,
      documentCreationDate: null,
      documentReceivedUtc: null,
      documentDeemedReceivedUtc: null,
      contractRowVersion: '',
    });
  }

  public static createUpdateRequestModel(model: SegmentInstanceDetailsPost) {
    return new PostUpdateSegmentInstanceDetailsRequestModel({
      reference: model.reference,
      documentName: model.documentName || undefined,
      // this one is a little weird
      // there might be a selected value on screen as a default
      // but it'll be disabled. We don't actually carry enough info in the model for "document is disabled" (sad panda dot gif)
      // so we can do a dodgy on "if we have a name"
      documentReceivalType:
        model.documentName && model.documentReceivalType
          ? parsedApiDocumentReceivalType[model.documentReceivalType.value]
          : undefined,
      documentReceivalDescription: model.documentReceivalDescription || undefined,
      documentCreationDate: model.documentCreationDate ? model.documentCreationDate.toZonelessDateFormat() : undefined,
      documentReceivedUtc: model.documentReceivedUtc || undefined,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || undefined,
      contractRowVersion: model.contractRowVersion,
    });
  }

  public static createInsertRequestModel(model: SegmentInstanceDetailsPost, gid: string) {
    console.log('SegmentInstanceDetailsPost.createInsertRequestModel model', model);
    return new PostInsertSegmentInstanceRequestModel({
      reference: model.reference,
      documentName: model.documentName || undefined,
      // this one is a little weird
      // there might be a selected value on screen as a default
      // but it'll be disabled. We don't actually carry enough info in the model for "document is disabled" (sad panda dot gif)
      // so we can do a dodgy on "if we have a name"
      documentReceivalType:
        model.documentName && model.documentReceivalType
          ? parsedApiDocumentReceivalType[model.documentReceivalType.value]
          : undefined,
      documentReceivalDescription: model.documentReceivalDescription || undefined,
      documentCreationDate: model.documentCreationDate ? model.documentCreationDate.toZonelessDateFormat() : undefined,
      documentReceivedUtc: model.documentReceivedUtc || undefined,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || undefined,
      segmentInstanceGid: gid,
      segmentGid: model.workflowSegmentGid,
      parentSegmentInstanceGid: model.parentClaimGid || undefined,
    });
  }
}

export interface ISegmentInstance extends IFilterableSegmentInstance {
  gid: string;
  reference: string;
  createdDateUtc: Date;
  dueDate: ZonelessDate | null;
  status: OtSegmentStatus;
  grantedTime: number | null;
  grantedTimeUnit: OtTimePeriodUnit | null;
  grantedTimeCalendarType: CalendarTypeEnum | null;
  resolution: OtSegmentResolution | null;
  resolutionChanged: boolean;
  contract: BasicContract;
  workflowSegment: BasicSegment;
  project: ProjectBasic;
  responseTimeAlertsEnabled: boolean;
  noLongerAffectingPC: boolean;
  trafficLightColour: OtTrafficLightColour;
}
export interface IFilterableSegmentInstance {
  status: OtSegmentStatus;
  resolution: OtSegmentResolution | null;
}

export class SegmentInstance implements ISegmentInstance {
  public gid!: string;
  public reference!: string;
  public createdDateUtc!: Date;
  public dueDate!: ZonelessDate | null;
  public status!: OtSegmentStatus;
  public grantedTime!: number | null;
  public grantedTimeUnit!: OtTimePeriodUnit | null;
  public grantedTimeCalendarType!: CalendarTypeEnum | null;
  public resolution!: OtSegmentResolution | null;
  public resolutionChanged!: boolean;
  public contract!: BasicContract;
  public workflowSegment!: BasicSegment;
  public project!: ProjectBasic;
  public responseTimeAlertsEnabled!: boolean;
  public noLongerAffectingPC!: boolean;
  public trafficLightColour!: OtTrafficLightColour;

  constructor(value: ISegmentInstance) {
    Object.assign(this, value);
  }

  public get name() {
    return this.reference;
  }

  public get created() {
    return formatToTimezone(this.createdDateUtc, this.contract.timezone);
  }
  public get createdFull() {
    const inContractTimezone = convertAndFormatDateAndTime(this.createdDateUtc, this.contract.timezone);

    return inContractTimezone + '<br/>' + this.contract.timezone;
    // One day, it'd be nice to work this logic in too, and give them a more detailed tooltip if the contract
    // timezone isn't equivalent to their local timezone. One day. Not today.
    // const doesMatch = compareToBrowserTimeZone(this.contract.timezone);
  }

  public get grantedTimeInHours() {
    if (this.grantedTime) {
      switch (this.grantedTimeUnit) {
        case OtTimePeriodUnit.Hours:
          return this.grantedTime;
        case OtTimePeriodUnit.Days:
          return this.grantedTime * 8;
        case OtTimePeriodUnit.Weeks:
          return this.grantedTime * 40;
      }
    }
    return null;
  }

  public get grantedTimeFormatted() {
    return durationString(this.grantedTime, this.grantedTimeCalendarType, this.grantedTimeUnit) ?? '';
  }

  public get dueDateStatus() {
    if (this.dueDate) {
      if (OtClosedSegmentStatusSet.has(this.status)) {
        return OtDueDateStatus.Closed;
      } else if (this.trafficLightColour === OtTrafficLightColour.None) {
        // we don't really have an analogue to this
        // filtering has red/amber/green/closed (more or less)
        // this method used to calculate red/amber/green without checking if the traffic lights were enabled
        // thus, we used to filter irregardless of the traffic light being enabled.
        // This might be a problem. The words on the radios are Overdue. Not Red. You could argue that Overdue is a different concept to Red
        // Or, you could argue that we are now correctly matching the numbers that would be seen in the actual traffic lights
        // elsewhere, and that the old behavior was a bug.
        // If you come to the claim list and filter for overdue,
        // you'll get the 3 claims that were in the little red 3 in the contract/project list,
        // and not a few extras that happen to be overdue but traffic lights are disabled
        return null;
      } else if (this.trafficLightColour === OtTrafficLightColour.Amber) {
        return OtDueDateStatus.DeadlineApproaching;
      } else if (this.trafficLightColour === OtTrafficLightColour.Red) {
        return OtDueDateStatus.Overdue;
      } else if (this.trafficLightColour === OtTrafficLightColour.Green) {
        return OtDueDateStatus.Open;
      } else {
        // the universe is broken
        console.warn('SegmentInstance -> get dueDateStatus -> unknown case, returning null', this);
        return null;
      }
    }
    return null;
  }

  public static createFromApiResponse(model: ListSegmentInstanceModel) {
    return new SegmentInstance({
      gid: model.gid || '',
      reference: model.reference || '',
      createdDateUtc: model.createdUtc || new Date(),
      dueDate: model.dueDate ? new ZonelessDate(model.dueDate) : null,
      status: model.status ? parsedOtSegmentStatuses[model.status] : OtSegmentStatus.InProgress,
      grantedTime: model.grantedTime !== undefined ? model.grantedTime : null,
      grantedTimeUnit: model.grantedTimeUnit ? parsedOtTimePeriodUnit[model.grantedTimeUnit] : null,
      grantedTimeCalendarType: model.grantedTimeCalendarType
        ? parsedOtCalendarTypeEnum[model.grantedTimeCalendarType]
        : null,
      resolution: model.resolution ? parsedOtSegmentResolutions[model.resolution] : null,
      resolutionChanged: model.resolutionChanged || false,
      contract: model.contract ? BasicContract.createFromApiResponse(model.contract) : BasicContract.createEmpty(),
      workflowSegment: model.workflowSegment
        ? BasicSegment.createFromApiResponse(model.workflowSegment)
        : BasicSegment.createEmpty(),
      project: model.project ? ProjectBasic.createFromApiResponse(model.project) : ProjectBasic.createEmpty(),
      responseTimeAlertsEnabled: model.responseTimeAlertsEnabled,
      noLongerAffectingPC: model.noLongerAffectingPC,
      trafficLightColour: parsedOtTrafficLightColour[model.trafficLightColour],
    });
  }

  public static compareByReference(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.reference.localeCompare(b.reference, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByName(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByContract(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.contract.name.localeCompare(b.contract.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }
  public static compareByProject(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return a.project.name.localeCompare(b.project.name, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByType(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return (
      a.workflowSegment.shortName.localeCompare(b.workflowSegment.shortName, undefined, { sensitivity: 'base' }) *
      sortModifier
    );
  }

  public static compareByGrantedTime(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return sortNullableNumbers(a.grantedTimeInHours, b.grantedTimeInHours) * sortModifier;
  }

  public static compareByStatus(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const aOrder = a.status ? OtSegmentEnumsOrder[a.status] : null;
    const bOrder = b.status ? OtSegmentEnumsOrder[b.status] : null;

    return sortNullableNumbers(aOrder, bOrder) * sortModifier;
  }

  public static compareByDueDate(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    return sortNullableDates(a.dueDate, b.dueDate) * sortModifier;
  }

  public static compareByCreated(a: SegmentInstance, b: SegmentInstance, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    // they'll never actually be null, but this is a handy method
    return sortNullableDates(a.createdDateUtc, b.createdDateUtc) * sortModifier;
  }

  public static filterByKeyword(keyword: string, segment: SegmentInstance) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return (
        segment.name.match(regex) || segment.contract.name.match(regex) || segment.grantedTimeFormatted.match(regex)
      );
    }
    return true;
  }

  public static filterByStatus(
    statuses: Array<OtSegmentStatus | OtSegmentResolution>,
    segment: IFilterableSegmentInstance,
  ) {
    if (statuses.length && segment.status) {
      const resolutionStatuses = statuses.filter(s =>
        Object.values(OtSegmentResolution).includes(s as OtSegmentResolution),
      );
      let segmentMatchesResolutionStatus = false;
      if (resolutionStatuses.length) {
        segmentMatchesResolutionStatus =
          segment.status === OtSegmentStatus.Completed &&
          !!segment.resolution &&
          resolutionStatuses.includes(segment.resolution);
      }

      return segmentMatchesResolutionStatus || statuses.includes(segment.status);
    }
    return true;
  }

  public static filterByDueDate(dueDateStatuses: OtDueDateStatus[], segment: SegmentInstance) {
    if (dueDateStatuses.length) {
      if (segment.dueDateStatus) {
        return dueDateStatuses.includes(segment.dueDateStatus);
      }
      return false;
    }
    return true;
  }

  public static filterByTypeName(typeNames: string[], segment: SegmentInstance) {
    if (typeNames.length) {
      return typeNames.includes(segment.workflowSegment.name);
    }
    return true;
  }
}

export interface IBasicClaim {
  gid: string;
  reference: string;
  createdUtc: Date;
  status: OtSegmentStatus;
}

export class BasicClaim implements IBasicClaim {
  public gid!: string;
  public reference!: string;
  public createdUtc!: Date;
  public status!: OtSegmentStatus;

  constructor(value: IBasicClaim) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new BasicClaim({
      gid: '',
      reference: '',
      createdUtc: new Date(),
      status: OtSegmentStatus.InProgress,
    });
  }

  public static createFromApiResponse(model: ApiBasicClaim) {
    return new BasicClaim({
      gid: model.gid || '',
      reference: model.reference || '',
      createdUtc: model.createdUtc || new Date(),
      status: model.status ? parsedOtSegmentStatuses[model.status] : OtSegmentStatus.InProgress,
    });
  }
}

export interface ISegmentInstanceDetails extends ISegmentInstance {
  parentClaim: BasicClaim | null;
  documentName: string | null;
  documentReceivalType: OtDocumentReceivalType | null;
  documentReceivalDescription: string | null;
  documentCreationDate: ZonelessDate | null;
  documentReceivedUtc: Date | null;
  documentDeemedReceivedUtc: Date | null;
  emails: OtSegmentInstanceEmail[];
  responseTimeAlertsEnabled: boolean;
  summaries: OtBasicSummary[];
}

export class SegmentInstanceDetails extends SegmentInstance implements ISegmentInstanceDetails {
  public parentClaim!: BasicClaim | null;
  public documentName!: string | null;
  public documentReceivalType!: OtDocumentReceivalType | null;
  public documentReceivalDescription!: string | null;
  public documentCreationDate!: ZonelessDate | null;
  public documentReceivedUtc!: Date | null;
  public documentDeemedReceivedUtc!: Date | null;
  public emails!: OtSegmentInstanceEmail[];
  public summaries!: OtBasicSummary[];

  constructor(value: ISegmentInstanceDetails) {
    super(value);
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new SegmentInstanceDetails({
      gid: '',
      reference: '',
      createdDateUtc: new Date(),
      dueDate: null,
      status: OtSegmentStatus.InProgress,
      grantedTime: null,
      grantedTimeUnit: null,
      grantedTimeCalendarType: null,
      resolution: null,
      resolutionChanged: false,
      contract: BasicContract.createEmpty(),
      workflowSegment: BasicSegment.createEmpty(),
      project: ProjectDetails.createEmpty(),
      parentClaim: null,
      documentName: null,
      documentReceivalType: null,
      documentReceivalDescription: null,
      documentCreationDate: null,
      documentReceivedUtc: null,
      documentDeemedReceivedUtc: null,
      emails: [],
      summaries: [],
      responseTimeAlertsEnabled: false,
      noLongerAffectingPC: false,
      trafficLightColour: OtTrafficLightColour.None,
    });
  }

  public static createFromApiResponse(model: GetSegmentInstanceDetailsResponseModel) {
    if (!model.contract?.timezone) {
      console.warn(
        'SegmentInstanceDetails.createFromApiResponse: model does not contain a contract timezone. No timezone conversion will be done for the document times. This might yield incorrect results',
      );
    }
    return new SegmentInstanceDetails({
      gid: model.gid || '',
      reference: model.reference || '',
      createdDateUtc: model.createdUtc || new Date(),
      dueDate: model.dueDate ? new ZonelessDate(model.dueDate) : null,
      status: model.status ? parsedOtSegmentStatuses[model.status] : OtSegmentStatus.InProgress,
      grantedTime: model.grantedTime !== undefined ? model.grantedTime : null,
      grantedTimeUnit: model.grantedTimeUnit ? parsedOtTimePeriodUnit[model.grantedTimeUnit] : null,
      grantedTimeCalendarType: model.grantedTimeCalendarType
        ? parsedOtCalendarTypeEnum[model.grantedTimeCalendarType]
        : null,
      resolution: model.resolution ? parsedOtSegmentResolutions[model.resolution] : null,
      resolutionChanged: model.resolutionChanged || false,
      contract: model.contract ? BasicContract.createFromApiResponse(model.contract) : BasicContract.createEmpty(),
      workflowSegment: model.workflowSegment
        ? BasicSegment.createFromApiResponse(model.workflowSegment)
        : BasicSegment.createEmpty(),
      project: model.project ? ProjectBasic.createFromApiResponse(model.project) : ProjectBasic.createEmpty(),
      parentClaim: model.parentClaim ? BasicClaim.createFromApiResponse(model.parentClaim) : null,
      documentName: model.documentName || null,
      documentReceivalType: model.documentReceivalType
        ? parsedOtDocumentReceivalType[model.documentReceivalType]
        : null,
      documentReceivalDescription: model.documentReceivalDescription || null,
      documentCreationDate: model.documentCreationDate ? new ZonelessDate(model.documentCreationDate) : null,
      documentReceivedUtc: model.documentReceivedUtc || null,
      documentDeemedReceivedUtc: model.documentDeemedReceivedUtc || null,
      emails: model.emails,
      summaries: model.summaries.map(x => OtBasicSummary.createFromApiResponse(x)),
      responseTimeAlertsEnabled: model.responseTimeAlertsEnabled,
      noLongerAffectingPC: model.noLongerAffectingPC,
      trafficLightColour: parsedOtTrafficLightColour[model.trafficLightColour],
    });
  }

  public toClaimDetailsPost() {
    return new SegmentInstanceDetailsPost({
      segmentGid: this.gid,
      projectGid: this.project.gid,
      projectName: this.project.name,
      contractGid: this.contract.gid,
      workflowSegmentGid: this.workflowSegment.gid,
      parentClaimGid: this.parentClaim?.gid || null,
      reference: this.reference,
      documentName: this.documentName,
      documentReceivalType: this.documentReceivalType,
      documentReceivalDescription: this.documentReceivalDescription,
      documentCreationDate: this.documentCreationDate,
      documentReceivedUtc: this.documentReceivedUtc,
      documentDeemedReceivedUtc: this.documentDeemedReceivedUtc,
      contractRowVersion: this.contract.rowVersion,
    });
  }
}

export class ClaimDetailsFormExternalErrorObject {
  public reference: string[] | null = null;
}

export interface IClaimDetailsFormObject {
  segmentInstanceGid: string | null;
  projectGid: string | null;
  contractGid: string | null;
  workflowGid: string | null;
  workflowSegmentGid: string | null;
  parentClaimGid: string | null;
  reference: string | null;
  documentName: string | null;
  documentReceivalType: OtDocumentReceivalType | null;
  documentReceivalDescription: string | null;
  documentDate: ZonelessDate | null;
  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  documentReceivedLocalBrowser: Date | null;
  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  documentDeemedReceivedLocalBrowser: Date | null;
}

export class ClaimDetailsFormObject implements IClaimDetailsFormObject {
  public segmentInstanceGid: string | null = null;
  public projectGid: string | null = null;
  public contractGid: string | null = null;
  public workflowGid: string | null = null;
  public workflowSegmentGid: string | null = null;
  public parentClaimGid: string | null = null;
  public reference: string | null = null;
  public documentName: string | null = null;
  public documentReceivalType: OtDocumentReceivalType | null = null;
  public documentReceivalDescription: string | null = null;
  public documentDate: ZonelessDate | null = null;
  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  public documentReceivedLocalBrowser: Date | null = null;
  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  public documentDeemedReceivedLocalBrowser: Date | null = null;

  constructor(value: IClaimDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ClaimDetailsFormObject({
      segmentInstanceGid: null,
      projectGid: null,
      contractGid: null,
      workflowGid: null,
      workflowSegmentGid: null,
      parentClaimGid: null,
      reference: null,
      documentName: null,
      documentReceivalType: null,
      documentReceivalDescription: null,
      documentDate: null,
      documentReceivedLocalBrowser: null,
      documentDeemedReceivedLocalBrowser: null,
    });
  }

  public toClaimDetailsPost(params: {
    claimGid: string;
    projectName: string | null;
    contractTimeZone: string;
    contractRowVersion: string;
    includeDocumentDetails: boolean;
  }) {
    return new SegmentInstanceDetailsPost({
      segmentGid: params.claimGid,
      projectGid: this.projectGid || '',
      projectName: params.projectName || '',
      contractGid: this.contractGid || '',
      workflowSegmentGid: this.workflowSegmentGid || '',
      parentClaimGid: this.parentClaimGid || '',
      reference: this.reference || '',
      documentName: params.includeDocumentDetails ? this.documentName : null,
      documentReceivalType: params.includeDocumentDetails ? this.documentReceivalType : null,
      documentReceivalDescription: params.includeDocumentDetails ? this.documentReceivalDescription : null,
      documentCreationDate: params.includeDocumentDetails ? this.documentDate : null,
      // client to server - convert these from local timezone to contract timezone using https://github.com/marnusw/date-fns-tz#zonedtimetoutc
      // See https://github.com/marnusw/date-fns-tz#time-zone-offset-helpers for a worked example of why we are doing this conversion
      documentReceivedUtc:
        params.includeDocumentDetails && this.documentReceivedLocalBrowser
          ? zonedTimeToUtc(this.documentReceivedLocalBrowser, params.contractTimeZone)
          : null,
      documentDeemedReceivedUtc:
        params.includeDocumentDetails && this.documentDeemedReceivedLocalBrowser
          ? zonedTimeToUtc(this.documentDeemedReceivedLocalBrowser, params.contractTimeZone)
          : null,
      contractRowVersion: params.contractRowVersion,
    });
  }
}

export interface IClaimWizardObject {
  originalClaimDetails: SegmentInstanceDetails;
  claimDetails: SegmentInstanceDetailsPost;
}

export class ClaimWizardObject implements IClaimWizardObject {
  public originalClaimDetails!: SegmentInstanceDetails;
  public claimDetails!: SegmentInstanceDetailsPost;

  constructor(value: IClaimWizardObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ClaimWizardObject({
      originalClaimDetails: SegmentInstanceDetails.createEmpty(),
      claimDetails: SegmentInstanceDetailsPost.createEmpty(),
    });
  }
}

export interface IClaimEmailProperties {
  senderUserGid: string;
  senderUserFirstName: string;
  senderUserLastName: string;
  recipients: string[];
  subject: string;
}

export class ClaimEmailProperties implements IClaimEmailProperties {
  senderUserGid!: string;
  senderUserFirstName!: string;
  senderUserLastName!: string;
  recipients!: string[];
  subject!: string;

  constructor(value: IClaimEmailProperties) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ClaimEmailProperties({
      senderUserGid: '',
      senderUserFirstName: '',
      senderUserLastName: '',
      recipients: [],
      subject: '',
    });
  }

  public static createFromApiResponse(model: EmailPropertiesModel) {
    return new ClaimEmailProperties({
      senderUserGid: model.senderUserGid,
      senderUserFirstName: model.senderUserFirstName,
      senderUserLastName: model.senderUserLastName,
      recipients: model.recipients,
      subject: model.subject,
    });
  }
}

export interface IAvailableActionObject {
  key: string;
  sendEmail: boolean;
  optionText: string;
  optionSubText: string | null;
  acceptButtonText: string;
  showRfiOutboundPrompt: boolean;
  outcome: OtSegmentStatus | null;
  typeOfChildSegmentInstanceToCreate: string | null;
  // these all exist on IDataDrivenAvailableActionModel from the server
  // but we make no use of them on the client yet
  // nextPhaseKey: string | undefined;
  // copyCurrentRunResponsesToNewRun: boolean | undefined;
  // resolution: OtSegmentResolution | undefined;
}

export class AvailableActionObject implements IAvailableActionObject {
  public key!: string;
  public sendEmail!: boolean;
  public optionText!: string;
  public optionSubText!: string | null;
  public acceptButtonText!: string;
  public showRfiOutboundPrompt!: boolean;
  public outcome!: OtSegmentStatus | null;
  /** The SegmentManifestGid to be used to locate the Segment when creating a Child SegmentInstance **/
  public typeOfChildSegmentInstanceToCreate!: string | null;

  constructor(value: IAvailableActionObject) {
    Object.assign(this, value);
  }
  public static createFromApiResponse(model: DataDrivenAvailableActionModel) {
    return new AvailableActionObject({
      key: model.key,
      sendEmail: model.sendEmail || false,
      optionText: model.optionText,
      optionSubText: model.optionSubText || null,
      acceptButtonText: model.acceptButtonText,
      showRfiOutboundPrompt: model.showRfiOutboundPrompt || false,
      typeOfChildSegmentInstanceToCreate: model.typeOfChildSegmentInstanceToCreate ?? null,
      outcome: model.outcome ? parsedOtSegmentStatuses[model.outcome] : null,
    });
  }
}

export interface IClaimEmailObject {
  runGid: string;
  content: string | null;
  emailDetails: ClaimEmailProperties | null;
  sentDateTimeUTC: Date | null;
}

export class ClaimEmailObject implements IClaimEmailObject {
  //
  public runGid!: string;
  public content!: string | null;
  public emailDetails!: ClaimEmailProperties | null;
  public sentDateTimeUTC!: Date | null;

  constructor(value: IClaimEmailObject) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiGetSegmentInstanceRecommendationEmailModel) {
    if (!model.runGid) {
      console.warn(
        'ClaimEmailObject -> createFromApiResponse, runGid is falsy. RunGid should be filled in. Will default to an empty string, but this might cause rendering oddities.',
        { model },
      );
    }
    return new ClaimEmailObject({
      runGid: model.runGid || '',
      content: model.content || null,
      emailDetails: model.emailDetails ? ClaimEmailProperties.createFromApiResponse(model.emailDetails) : null,
      sentDateTimeUTC: model.sentDateTimeUTC || null,
    });
  }
}
export interface IClaimResultsObject {
  runGid: string | null;
  recommendedOutcome: OtSegmentResolution;
  selectedOutcome: OtSegmentResolution | null;
  initialDuration: number;
  selectedDuration: number | null;
  initialCalendar: CalendarTypeEnum;
  selectedCalendar: CalendarTypeEnum | null;
  recommendationMessages: string[];
  responseText: string;
  overruleReason: string | null;
  emailMessage: string | null;
  emailDetails: ClaimEmailProperties | null;
  sentDateTimeUTC: Date | null;
  availableActions: Array<AvailableActionObject>;
}

export class ClaimResultsObject implements IClaimResultsObject {
  public runGid!: string | null;
  public recommendedOutcome!: OtSegmentResolution;
  public selectedOutcome!: OtSegmentResolution | null;
  public initialDuration!: number;
  public selectedDuration!: number | null;
  public initialCalendar!: CalendarTypeEnum;
  public selectedCalendar!: CalendarTypeEnum | null;
  public recommendationMessages!: string[];
  public responseText!: string;
  public overruleReason!: string | null;
  public emailMessage!: string | null;
  public emailDetails!: ClaimEmailProperties | null;
  public sentDateTimeUTC!: Date | null;
  public availableActions!: Array<AvailableActionObject>;

  constructor(value: IClaimResultsObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ClaimResultsObject({
      runGid: null,
      recommendedOutcome: OtSegmentResolution.Accepted,
      selectedOutcome: null,
      initialDuration: 0,
      selectedDuration: null,
      initialCalendar: CalendarTypeEnum.Working,
      selectedCalendar: null,
      recommendationMessages: [],
      responseText: '',
      overruleReason: null,
      emailMessage: null,
      emailDetails: null,
      sentDateTimeUTC: null,
      availableActions: [],
    });
  }

  // we probably need two of these. One for POST, one for GET. The object models have diverged a lot
  public static createFromApiResponse(
    model: GetSegmentInstanceRecommendationRecommendationModel | PostSegmentInstanceRecommendationResponseModel,
  ) {
    let claimResult: ClaimResultsObject;
    if (model instanceof GetSegmentInstanceRecommendationRecommendationModel) {
      // This is the one that comes back for a finished claim, this is the "what was the recommendation".
      // As such, it has the selectedX properties, overrule properties, sent email details
      claimResult = new ClaimResultsObject({
        runGid: model.runGid || null,
        recommendedOutcome: model.recommendationOutcome
          ? parsedOtSegmentResolutions[model.recommendationOutcome]
          : OtSegmentResolution.Rejected,
        selectedOutcome: model.resultOutcome ? parsedOtSegmentResolutions[model.resultOutcome] : null,
        initialDuration: model.recommendationDuration || 0,
        selectedDuration: model.resultDuration || null,
        initialCalendar: model.recommendationCalendar
          ? parsedOtCalendarTypeEnum[model.recommendationCalendar]
          : CalendarTypeEnum.Working,
        selectedCalendar: model.resultCalendar ? parsedOtCalendarTypeEnum[model.resultCalendar] : null,
        recommendationMessages: model.recommendationMessages || [],
        responseText: '', // doesn't exist on the GET any more. Hooray!
        overruleReason: model.resultDescription || '',
        emailMessage: null,
        emailDetails: null,
        sentDateTimeUTC: null,
        // we'll never have available actions. Really feels like the dual usage of this class has come to an end
        // GET and POST have diverged hugely
        availableActions: [],
      });
    } else {
      // This is the one that comes back from an unfinished claim, this is the "what is the recommendation" call
      // It doesn't have any selected overrides, but it does have available actions the user can choose
      claimResult = new ClaimResultsObject({
        runGid: null,
        recommendedOutcome: parsedOtSegmentResolutions[model.recommendationOutcome],
        initialDuration: model.recommendationDuration || 0,
        initialCalendar: parsedOtCalendarTypeEnum[model.recommendationCalendar],
        recommendationMessages: model.recommendationMessages,
        responseText: model.responseText || '',
        selectedOutcome: null,
        selectedDuration: null,
        selectedCalendar: null,
        overruleReason: null,
        sentDateTimeUTC: null,
        emailMessage: model.emailMessage,
        emailDetails: ClaimEmailProperties.createFromApiResponse(model.emailDetails),
        availableActions: model.availableActions.map(x => AvailableActionObject.createFromApiResponse(x)),
      });
    }

    if (
      claimResult.initialDuration &&
      claimResult.initialCalendar &&
      claimResult.selectedDuration &&
      claimResult.selectedCalendar &&
      (claimResult.initialDuration !== claimResult.selectedDuration ||
        claimResult.initialCalendar !== claimResult.selectedCalendar)
    ) {
      // ValidWithDifferentTime isn't defined in the API.
      // We invented it in the UI to make things a little easier
      claimResult.selectedOutcome = OtSegmentResolution.ValidWithDifferentTime;
    }
    return claimResult;
  }
}

export interface IFinishedClaimRecommendationsObject {
  recommendations: IClaimResultsObject[];
  emails: IClaimEmailObject[];
}

export class FinishedClaimRecommendationsObject implements IFinishedClaimRecommendationsObject {
  public recommendations!: ClaimResultsObject[];
  public emails!: ClaimEmailObject[];

  constructor(value: IFinishedClaimRecommendationsObject) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: GetSegmentInstanceRecommendationResponseModel) {
    return new FinishedClaimRecommendationsObject({
      recommendations: model.recommendations?.map(x => ClaimResultsObject.createFromApiResponse(x)) || [],
      emails: model.emails?.map(x => ClaimEmailObject.createFromApiResponse(x)) || [],
    });
  }
}

export interface IClaimFinaliseSegmentInstance {
  gid: string;
  availableActionKey: string | null;
  useRecommended: boolean;
  overruleOutcome: OtSegmentResolution | null;
  overruleGrant: number | null;
  overruleReason: string | null;
  overruleSelectedCalendarType: CalendarTypeEnum | null;
  emailRecipients: string[];
  emailSubject: string;
  emailContent: string;
  contractRowVersion: string;
}

export class ClaimFinaliseSegmentInstance implements IClaimFinaliseSegmentInstance {
  public gid!: string;
  public availableActionKey!: string | null;
  public useRecommended!: boolean;
  public overruleOutcome!: OtSegmentResolution | null;
  public overruleGrant!: number | null;
  public overruleReason!: string | null;
  public overruleSelectedCalendarType!: CalendarTypeEnum | null;
  public emailRecipients!: string[];
  public emailSubject!: string;
  public emailContent!: string;
  public contractRowVersion!: string;

  constructor(value: IClaimFinaliseSegmentInstance) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new ClaimFinaliseSegmentInstance({
      gid: '',
      availableActionKey: null,
      useRecommended: false,
      overruleOutcome: null,
      overruleGrant: null,
      overruleReason: null,
      overruleSelectedCalendarType: null,
      emailRecipients: [],
      emailSubject: '',
      emailContent: '',
      contractRowVersion: '',
    });
  }

  public static createRequestModel(model: ClaimFinaliseSegmentInstance): PostFinaliseSegmentInstanceRequestModel {
    const result = new PostFinaliseSegmentInstanceRequestModel({
      availableActionKey: model.availableActionKey || undefined,
      contractRowVersion: model.contractRowVersion,
    });
    if (!model.availableActionKey) {
      result.actionOverride = new ActionOverrideModel({
        useRecommended: model.useRecommended,
        overruleOutcome: model.overruleOutcome ? parsedApiSegmentResolutions[model.overruleOutcome] : undefined,
        overruleGrant: model.overruleGrant || undefined,
        overruleReason: model.overruleReason || undefined,
        overruleSelectedCalendarType: model.overruleSelectedCalendarType
          ? parsedApiCalendarTypes[model.overruleSelectedCalendarType]
          : undefined,
      });
    }
    if (model.emailContent) {
      result.emailDetails = new EmailDetailsModel({
        subject: model.emailSubject,
        content: model.emailContent,
      });
    }
    return result;
  }
}

interface ISegmentInstanceEmail {
  senderUserGid: string;
  senderUserFirstName: string;
  senderUserLastName: string;
  recipients: string[];
  subject: string;
  content: string;
  sentDateTimeUTC: Date;
}

export class OtSegmentInstanceEmail implements ISegmentInstanceEmail {
  public senderUserGid!: string;
  public senderUserFirstName!: string;
  public senderUserLastName!: string;
  public recipients!: string[];
  public subject!: string;
  public content!: string;
  public sentDateTimeUTC!: Date;

  constructor(value: ISegmentInstanceEmail) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiSegmentInstanceEmailModel) {
    return new OtSegmentInstanceEmail({
      senderUserGid: model.senderUserGid,
      senderUserFirstName: model.senderUserFirstName,
      senderUserLastName: model.senderUserLastName,
      recipients: model.recipients,
      subject: model.subject,
      content: model.content,
      sentDateTimeUTC: model.sentDateTimeUTC,
    });
  }
}
