import { AccessType, StreetAddressModel } from '@/models/shared-models';
import { UserStatusWithActions } from '@/models/user-models';
import {
  GetProjectUserDetailsResponseModel,
  PostUpdateProjectUserDetailsModel,
  ProjectUserStatusesModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { LastModified } from '@/utils/type-utils';

export interface IProjectUser {
  gid: string;
  firstName: string;
  lastName: string;
  email: string;
  organisation: UserStatusWithActions | null;
  project: UserStatusWithActions | null;
  contracts: UserStatusWithActions[];
}

export class ProjectUser implements IProjectUser {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public organisation: UserStatusWithActions | null = null;
  public project: UserStatusWithActions | null = null;
  public contracts: UserStatusWithActions[] = [];

  public get name(): string {
    return ((this.firstName || '') + ' ' + (this.lastName || '')).trim();
  }
  public constructor(value: IProjectUser) {
    Object.assign(this, value);
  }

  public static createEmpty(): ProjectUser {
    return new ProjectUser({
      gid: '',
      firstName: '',
      lastName: '',
      email: '',
      organisation: null,
      project: null,
      contracts: [],
    });
  }

  public static createFromApiResponse(model: ProjectUserStatusesModel): ProjectUser {
    return new ProjectUser({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      organisation:
        model.organisations?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.organisations?.[0]) : null,
      project: model.projects?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.projects?.[0]) : null,
      contracts: model.contracts.map(contract => UserStatusWithActions.createFromApiResponse(contract)),
    });
  }

  public static compareByName(a: ProjectUser, b: ProjectUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const projectNameA = a.name;
    const projectNameB = b.name;
    return projectNameA.localeCompare(projectNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByEmail(a: ProjectUser, b: ProjectUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const codeA = a.email;
    const codeB = b.email;
    return codeA.localeCompare(codeB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByProjectRole(a: ProjectUser, b: ProjectUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    let titleA = '';
    let titleB = '';
    if (a.project) {
      titleA = a.project.title;
    }
    if (b.project) {
      titleB = b.project.title;
    }

    // im adding this but Dan, you might think of a better way to handle this ( or if its even required at all )
    // if they are both undefined, then we want to sort them as equal - otherwise if one is undefined,
    // we want to sort it as greater than the other one
    // logically that seems ok to me but again, you might have a better idea.
    // Treat empty strings as greater than any non-empty value
    if (!titleA && !titleB) {
      return 0;
    } else if (!titleA && titleB) {
      return 1 * sortModifier;
    } else if (titleA && !titleB) {
      return -1 * sortModifier;
    }

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, projectUser: ProjectUser) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return projectUser.name.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], projectUser: ProjectUser) {
    if (statuses.length) {
      return statuses.some(
        status =>
          projectUser.organisation?.status === status ||
          projectUser.project?.status === status ||
          projectUser.contracts.some(x => x.status === status),
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], projectUser: ProjectUser) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return projectUser.organisation != null;
          case AccessType.Projects:
            return projectUser.project != null;
          case AccessType.Contracts:
            return projectUser.contracts.length > 0;
          default:
            return false;
        }
      });
    }
    return true;
  }
}

export interface IProjectUserDetails {
  gid: string;
  firstName: string;
  lastName: string;
  businessName: string | null;
  email: string;
  streetAddress: StreetAddressModel | null;
  postalAddress: StreetAddressModel | null;
  mobile: string | null;
  phone: string | null;
  fax: string | null;
  projectUserStatus?: UserStatusWithActions;
  projectGid: string;
  projectName: string;
  lastModified: LastModified;
  lastLoginUtc?: ZonelessDate | null;
  lastLoginIpAddress?: string | undefined;
}

export class ProjectUserDetails implements IProjectUserDetails {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public businessName: string | null = null;
  public email!: string;
  public streetAddress: StreetAddressModel | null = null;
  public postalAddress: StreetAddressModel | null = null;
  public mobile: string | null = null;
  public phone: string | null = null;
  public fax: string | null = null;
  public projectUserStatus?: UserStatusWithActions;
  public projectGid!: string;
  public projectName!: string;
  public lastModified!: LastModified;
  public lastLoginUtc?: ZonelessDate | null = null;
  public lastLoginIpAddress?: string | undefined;

  public constructor(value: IProjectUserDetails) {
    Object.assign(this, value);
  }

  public static createEmpty(): ProjectUserDetails {
    return new ProjectUserDetails({
      gid: '',
      firstName: '',
      lastName: '',
      businessName: null,
      email: '',
      streetAddress: null,
      postalAddress: null,
      mobile: null,
      phone: null,
      fax: null,
      projectUserStatus: UserStatusWithActions.createEmpty(),
      projectGid: '',
      projectName: '',
      lastModified: LastModified.createEmpty(),
      lastLoginUtc: new ZonelessDate(),
      lastLoginIpAddress: '',
    });
  }

  public static createFromApiResponse(model: GetProjectUserDetailsResponseModel): ProjectUserDetails {
    return new ProjectUserDetails({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      businessName: model.businessName ?? null,
      email: model.email,
      streetAddress: model.streetAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.streetAddress) : null,
      postalAddress: model.postalAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.postalAddress) : null,
      mobile: model.mobile ?? null,
      phone: model.phone ?? null,
      fax: model.fax ?? null,
      projectUserStatus: model.projectUserStatus
        ? UserStatusWithActions.createFromApiResponse(model.projectUserStatus)
        : undefined,
      projectGid: model.projectGid,
      projectName: model.projectName,
      lastModified: model.lastModified
        ? LastModified.createFromApiResponse(model.lastModified)
        : LastModified.createEmpty(),
      lastLoginUtc: model.lastLoginUtc ? new ZonelessDate(model.lastLoginUtc) : null,
      lastLoginIpAddress: model.lastLoginIpAddress,
    });
  }
}

export interface IProjectUserDetailsFormObject {
  functionalRoleTitle: string;
}

export class ProjectUserDetailsFormObject implements IProjectUserDetailsFormObject {
  public functionalRoleTitle!: string;

  public constructor(value: IProjectUserDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty(): ProjectUserDetailsFormObject {
    return new ProjectUserDetailsFormObject({
      functionalRoleTitle: '',
    });
  }

  public static createFromApiResponse(model: GetProjectUserDetailsResponseModel): ProjectUserDetailsFormObject {
    return new ProjectUserDetailsFormObject({
      functionalRoleTitle: model.projectUserStatus ? model.projectUserStatus.title : '',
    });
  }

  public static createRequestModel(model: ProjectUserDetailsFormObject): PostUpdateProjectUserDetailsModel {
    return new PostUpdateProjectUserDetailsModel({
      functionalRoleTitle: model.functionalRoleTitle,
    });
  }
}
