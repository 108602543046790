































import Styles from '@/styles/variables.scss';
import { Component, Vue } from 'vue-property-decorator';
import { UnsavedChangesModalResult } from './unsaved-changes-modal-models';

@Component({})
export default class OtUnsavedChangesModal extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private resolve!: (value: UnsavedChangesModalResult | PromiseLike<UnsavedChangesModalResult>) => void;
  private reject!: () => void;
  private showModalLocal = false;

  private discardButtonColor = Styles.otColorRed300;
  private reviewButtonColor = Styles.otColorBlue500;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  public open() {
    this.$emit('open');

    this.showModalLocal = true;
    return new Promise<UnsavedChangesModalResult>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public close(result: UnsavedChangesModalResult) {
    this.$emit('close', result);
    this.resolve(result);
    this.showModalLocal = false;
  }

  // this is when the user clicks the OK button in the dialog
  // all we want to do here is indicate that the user clicked ok
  // and resolve the promise which was started when the coder
  // called open() on us. This'll get the result of ok:true back to them
  // and their code that has been awaiting this can continue on
  private async ok() {
    const result = new UnsavedChangesModalResult({ ok: true });
    this.close(result);
  }

  private cancel() {
    const result = new UnsavedChangesModalResult({ ok: false });
    this.close(result);
  }

  private clickedOutside() {
    this.cancel();
  }

  // * LIFECYCLE
}
