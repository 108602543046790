





































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtCheckboxGroupSet from '@/components/global/checkbox-group/dependencies/ot-checkbox-group-set.vue';

export class CheckboxGroupItem<T> {
  public label!: string;
  public value!: T;
  public actionButtonText!: string | null;
  public subText!: string | null;
  public error!: string | null;

  constructor(
    label: string,
    value: T,
    actionButtonText: string | null = null,
    subText: string | null = null,
    error: string | null = null,
  ) {
    this.label = label;
    this.value = value;
    this.actionButtonText = actionButtonText;
    this.subText = subText;
    this.error = error;
  }
}

export class CheckboxGroupSet<T, S = unknown> {
  public label!: string;
  public items!: CheckboxGroupSet<T, S> | Array<CheckboxGroupItem<T | S> | CheckboxGroupSet<T, S>>;

  constructor(label: string, value: CheckboxGroupSet<T, S> | Array<CheckboxGroupItem<T | S> | CheckboxGroupSet<T, S>>) {
    this.label = label;
    this.items = value;
  }
}

export type CheckboxGroupType<T, S = unknown> = CheckboxGroupItem<T | S> | CheckboxGroupSet<T, S>;

@Component({
  components: {
    OtFieldArchetype,
    OtCheckbox,
    OtCheckboxGroupSet,
  },
})
export default class OtCheckboxGroup extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private value!: CheckboxGroupItem<unknown>[];
  @Prop({ default: () => [] }) private items!: CheckboxGroupType<unknown, unknown>[];
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: false }) private vertical!: boolean;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop() private rules?: Array<(value: boolean) => boolean | string>;
  @Prop({ type: Boolean, default: false }) private box!: boolean;
  @Prop() private beforeCheckboxValueSet?: (item: CheckboxGroupItem<unknown>, value: boolean) => Promise<boolean>;

  // * REFS
  @Ref('checkboxSetRef') private readonly checkboxSetRef!: OtCheckboxGroupSet | OtCheckboxGroupSet[];

  // * DATA
  private isRequiredRule = () => {
    return !!this.value.length || this.requiredMessage || '';
  };
  private hasError = false;

  // * COMPUTED
  private get showDescription() {
    return !!this.$slots.description;
  }

  // * WATCHERS

  // * METHODS
  private setValue(val: CheckboxGroupItem<unknown>[]) {
    this.$emit('input', val);
    this.$nextTick(() => {
      this.resetManualErrorState();
    });
  }

  public resetManualErrorState() {
    if (Array.isArray(this.checkboxSetRef)) {
      this.checkboxSetRef?.forEach(c => c.resetManualErrorState());
    } else {
      this.checkboxSetRef?.resetManualErrorState();
    }
  }

  private get inputRules(): Array<(value: boolean) => boolean | string> {
    const rules = this.rules || [];
    if (this.requiredMessage && !this.disabled) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  private actionClicked(item: CheckboxGroupItem<unknown>) {
    this.$emit('actionClicked', item);
  }

  // * LIFECYCLE
}
