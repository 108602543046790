






















































































































































import OtButton from '@/components/global/ot-button.vue';
import OtExpansionPanel from '@/components/global/ot-expansion-panel.vue';
import { OtOpenSegmentStatusSet, OtSegmentResolution, OtSegmentStatus } from '@/types/status-enums';
import { compareToBrowserTimeZone, convertAndFormatDateAndTime, formatDateAndTime } from '@/utils/date-utils';
import { OtSegmentProcessTypesThatCanAffectPC } from '@/wf-components/models/data-driven-enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ROUTE_CONTRACT_DEFAULT } from '../projects/contracts/contract-routes';
import { ROUTE_PROJECT_DEFAULT } from '../projects/projects-routes';
import { SegmentInstanceDetails } from './claims-models';
import { ROUTE_WORKFLOW_DETAILS_EDIT } from './claims-routes';

@Component({
  components: {
    OtExpansionPanel,
    OtButton,
  },
})
export default class OtClaimDetails extends Vue {
  // * PROPS
  @Prop() private claimDetails!: SegmentInstanceDetails;

  // * REFS

  // * DATA

  // * COMPUTED
  private get contractRoute() {
    return ROUTE_CONTRACT_DEFAULT;
  }
  private get projectRoute() {
    return ROUTE_PROJECT_DEFAULT;
  }
  private get workflowDetailsEditRoute() {
    return ROUTE_WORKFLOW_DETAILS_EDIT;
  }

  private get showClaimOutcome() {
    return (
      this.claimDetails.status !== OtSegmentStatus.InProgress && this.claimDetails.status !== OtSegmentStatus.Suspended
    );
  }

  private get showDocumentDetails() {
    return Boolean(this.claimDetails.documentName);
  }

  private get claimOutcome() {
    switch (this.claimDetails.status) {
      case OtSegmentStatus.Completed:
      case OtSegmentStatus.LegalDispute:
      case OtSegmentStatus.Disputed:
        if (this.claimDetails.resolution === OtSegmentResolution.Accepted) {
          if (
            this.claimDetails.grantedTime &&
            OtSegmentProcessTypesThatCanAffectPC.has(this.claimDetails.workflowSegment.segmentProcessType)
          ) {
            return `Granted (${this.claimDetails.grantedTimeFormatted})`;
          } else {
            return 'Accepted';
          }
        } else if (this.claimDetails.resolution === OtSegmentResolution.Rejected) {
          return 'Rejected';
        }
        break;

      case OtSegmentStatus.Withdrawn:
        return 'Withdrawn';

      case OtSegmentStatus.Cancelled:
        return 'Cancelled';

      default:
        return '';
    }
    return '';
  }
  private get isEditEnabled() {
    // this check is the same check as in ot-claims-table (OtClaimsTable) getClaimLink where we decide if we are taking them to the
    // "fill in the claim" or this view the claim. Probably a bit over the top, but it's super future proof
    return OtOpenSegmentStatusSet.has(this.claimDetails.status);
  }

  private get showReceivalDescription() {
    return this.claimDetails.documentReceivalType?.value === 'OTHER';
  }

  private get timeZoneComparison() {
    return compareToBrowserTimeZone(this.claimDetails.contract.timezone);
  }

  // * WATCHERS

  // * METHODS
  private convertAndFormatDateAndTime(date: Date | null) {
    if (date) {
      return convertAndFormatDateAndTime(date, this.claimDetails.contract.timezone, { overrideJoin: '-' });
    }
    return '';
  }

  private formatDate(date: Date | null) {
    if (date) {
      return formatDateAndTime(date, { excludeTime: true });
    }
    return '';
  }

  private formatDateAndTime(date: Date | null) {
    if (date) {
      return formatDateAndTime(date, { overrideJoin: '-' });
    }
    return '';
  }

  // * LIFECYCLE
}
