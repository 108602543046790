






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { OtDataDrivenOptionValue } from '../models/data-driven-result';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtRadioGroup,
  },
})
export default class WfParallelRadioButtonsFilledEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private label: string | null = null;
  private hint: string | null = null;
  private placeholder: string | null = null;
  private requiredMessage: string | null = null;

  // * COMPUTED
  private get radioOptions(): IRadioGroupOption[] {
    return this.question.optionsOrdered.map(o => {
      return { label: o.title, key: o.key };
    });
  }

  private selectedValuePrivate: IRadioGroupOption | null = null;
  private get selectedValue(): IRadioGroupOption | null {
    return this.selectedValuePrivate;
  }
  private set selectedValue(val: IRadioGroupOption | null) {
    this.selectedValuePrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-parallel-radio-buttons-filled-edit -> result -> ResultType is incorrect. Expected OptionsValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const val = this.radioOptions.find(o => o.key === this.result?.key) || null;
    this.selectedValuePrivate = val;
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenOptionValue({
      resultType: 'OptionsValueModel',
      key: this.selectedValue?.key || '',
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.hint = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
