







































import { Component, Vue } from 'vue-property-decorator';
import OtAddressField from '@/components/global/ot-address-field.vue';

@Component({
  components: {
    OtAddressField,
  },
})
export default class OtAddressFieldeWrapper extends Vue {
  // * PROPS

  // * DATA
  private testValue1Private = null;
  private get testValue1() {
    return this.testValue1Private;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private set testValue1(val: any | null) {
    if (val) {
      console.log('Address Wrapper, set value', { current: this.testValue1Private, val, valLabel: val.label });
    } else {
      console.log('Address Wrapper, set value to null', { current: this.testValue1Private });
    }

    this.testValue1Private = val;
  }
  private testValue2 = null;
  private testValue3 = null;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
