







































import {
  ROUTE_PROJECT,
  ROUTE_PROJECT_WORKFLOWS,
  ROUTE_PROJECT_CONTRACTS,
  ROUTE_PROJECT_CREATE,
  ROUTE_PROJECT_DETAILS,
  ROUTE_PROJECT_DOCUMENTS,
  ROUTE_PROJECT_USERS,
} from './projects-routes';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { ProjectDetails } from './project-models';
import { OtProjectStatus, OtStatusType } from '@/types/status-enums';
import { IInputAction, IInputTab } from '@/components/global/common-models';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { Contract } from './contracts/contract-models';
import { SegmentInstance } from '../claims/claims-models';
import { ROUTE_CONTRACT_ADD } from './contracts/contract-routes';
import { ProjectUser } from './users/project-user-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '@/areas/invitations/ot-invite-user-dialog.vue';
import { ROUTE_WORKFLOW_ADD } from '../claims/claims-routes';
import OtRequestContractDialog from '@/components/specific-modals/ot-request-contract-dialog.vue';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
    OtButton,
    OtTag,
    OtInviteUserDialog,
    OtRequestContractDialog,
  },
})
export default class OtProjectsLayout extends Vue {
  // * PROPS

  // * REFS
  @Ref('requestContractDialogRef')
  private readonly requestContractDialogRef!: OtRequestContractDialog;

  // * DATA

  private newClaimButton: IInputAction = {
    actionName: 'New Workflow',
    action: { name: ROUTE_WORKFLOW_ADD },
  };

  private newProjectButton: IInputAction = {
    actionName: 'New Project',
    action: { name: ROUTE_PROJECT_CREATE },
  };

  private newContractButton: IInputAction = {
    actionName: 'New Contract',
    action: { name: ROUTE_CONTRACT_ADD },
  };

  private get requestContractButton(): IInputAction {
    return {
      actionName: 'Request Contract',
      action: () => {
        this.requestContractDialogRef.open({
          projectGid: this.projectGid,
        });
        // no need to await the result and check the didRequest, the contracts are not visible anywhere so we don't need to reload the list
      },
    };
  }

  private newUserButton: IInputAction = {
    actionName: 'New User',
    action: () => this.createNewUser(),
  };

  private get projectsTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Contracts',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_CONTRACTS,
        },
      },
      {
        tabId: 1,
        tabText: 'Workflows',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_WORKFLOWS,
        },
      },
      {
        tabId: 2,
        tabText: 'Project Details',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_DETAILS,
        },
      },
      {
        tabId: 3,
        tabText: 'Documents',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_DOCUMENTS,
        },
      },
      {
        tabId: 4,
        tabText: 'Users',
        tabRoute: {
          params: { projectGid: this.projectGid },
          name: ROUTE_PROJECT_USERS,
        },
      },
    ];
  }

  private api = new OtApi();
  private loading = false;
  private project: ProjectDetails | null = null;
  private contracts: Contract[] = [];
  private projectUsers: ProjectUser[] = [];
  private segmentInstances: SegmentInstance[] = [];

  // * COMPUTED
  private get projectGid() {
    return this.$route.params.projectGid;
  }

  private get projectName() {
    return this.project?.name || '';
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  private get primaryContextBtn() {
    switch (this.currentRouteName) {
      case ROUTE_PROJECT:
        return this.newProjectButton;
      case ROUTE_PROJECT_DETAILS:
        return this.newClaimButton;
      case ROUTE_PROJECT_USERS:
        return this.newUserButton;
      case ROUTE_PROJECT_CONTRACTS:
        if (vxm.userProfile.userProfile?.canCreateContract) {
          return this.newContractButton;
        } else {
          return this.requestContractButton;
        }
      case ROUTE_PROJECT_WORKFLOWS:
        return this.newClaimButton;
      case ROUTE_PROJECT_DOCUMENTS:
        return this.newClaimButton;

      default:
        console.warn(
          `OtProjectLayout.primaryContextBtn - no primary button defined for '${this.currentRouteName}' - defaulting to newClaimButton`,
        );
        return this.newClaimButton;
    }
  }

  private get disableContextButton() {
    // Suspended/Complete projects cannot have contracts added so if the project is in one of those states, disable the button
    switch (this.primaryContextBtn) {
      case this.newContractButton:
      case this.newClaimButton:
      case this.requestContractButton:
        return this.project?.status === OtProjectStatus.Suspended || this.project?.status === OtProjectStatus.Complete;
      default:
        return false;
    }
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.loading = true;

    if (!this.project || this.project.gid !== this.projectGid) {
      await this.handleReloadProjectDetails();
    }
    // contracts
    if (this.$route.name === ROUTE_PROJECT_CONTRACTS && !this.contracts.length) {
      await this.handleReloadContracts();
    }
    // projectUsers
    if (this.$route.name === ROUTE_PROJECT_USERS && !this.projectUsers.length) {
      await this.handleReloadProjectUsers();
    }
    // claims
    if (this.$route.name === ROUTE_PROJECT_WORKFLOWS && !this.segmentInstances.length) {
      await this.handleReloadSegmentInstances();
    }
    this.loading = false;
  }

  // * METHODS

  setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.projectName });
  }

  updateProject(projectGid: string) {
    this.$router.push({ name: this.currentRouteName || ROUTE_PROJECT, params: { projectGid } });
  }

  private async handleReloadProjectDetails(callback?: () => void) {
    const result = await executeApi(
      () => this.api.projects().getProjectDetails(this.projectGid),
      'Load Project Details',
    );
    if (result.success && result.data) {
      this.project = ProjectDetails.createFromApiResponse(result.data);
      vxm.breadcrumbs.setProjectName({ projectName: this.project.name });
    }
    if (callback) {
      callback();
    }
  }

  private async handleReloadContracts(callback?: () => void) {
    const result = await executeApi(() => this.api.contracts().getContracts(this.projectGid), 'Load Contracts');
    if (result.success && result.data?.contracts) {
      this.contracts = result?.data?.contracts?.map(contract => Contract.createFromApiResponse(contract));
    }
    if (callback) {
      callback();
    }
  }
  private async handleReloadProjectUsers(callback?: () => void) {
    const result = await executeApi(() => this.api.projects().getProjectUsers(this.projectGid), 'Load Users');
    if (result.success && result.data?.users) {
      this.projectUsers = result?.data?.users?.map(user => ProjectUser.createFromApiResponse(user));
    }
    if (callback) {
      callback();
    }
  }

  private async handleReloadSegmentInstances(callback?: () => void) {
    const apiResponse = await executeApi(
      () => this.api.segmentInstances().getSegmentInstances(undefined, this.projectGid),
      'Load Workflows',
    );
    if (apiResponse.success && apiResponse.data?.segmentInstances) {
      this.segmentInstances = apiResponse.data.segmentInstances.map(s => SegmentInstance.createFromApiResponse(s));
    }
    if (callback) {
      callback();
    }
  }

  private getTagStatus(status: OtProjectStatus): TagStatus {
    return { type: OtStatusType.Project, status };
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async createNewUser() {
    if (!this.project) return;

    const params = new InviteUserDialogParams({
      isReinvite: false,
      organisationGid: this.project.organisationGid,
      inviteToOrganisation: false,
      projects: [this.project.gid],
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      this.handleReloadProjectUsers();
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
  }
}
