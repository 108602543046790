






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';

export interface IRadioGroupOption {
  key: string;
  label: string;
  useHtmlInLabel?: boolean;
  hint?: string;
}

@Component({
  components: {
    OtFieldArchetype,
  },
})
export default class OtRadioGroup extends Vue {
  // * PROPS
  @Prop({ default: null }) private value!: IRadioGroupOption | null;
  @Prop({ default: () => [] }) private options!: Array<IRadioGroupOption>;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private secondaryLabel?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private horizontal!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;
  @Prop({ type: Boolean, default: false }) private box!: boolean;
  @Prop({ type: Boolean, default: true }) private fullWidth!: boolean;
  @Prop({ type: Boolean, default: false }) private compact!: boolean;
  @Prop({ type: Boolean, default: false }) private error!: boolean;
  @Prop() private rules?: Array<(value: number | null) => boolean | string>;

  // * REFS

  // * DATA
  private isRequiredRule = (value: number | null) => value !== null || this.requiredMessage || '';

  // * COMPUTED
  private get selectedValue(): number | null {
    const key = this.value?.key;
    const foundOption = this.options.findIndex(o => o.key === key);
    return foundOption !== -1 ? foundOption : null;
  }
  private set selectedValue(value: number | null) {
    if (value !== null) {
      this.$emit('input', this.options[value]);
    } else {
      this.$emit('input', null);
    }
  }

  private get inputRules(): Array<(value: number | null) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  private get showDescription() {
    return !!this.$slots.description;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
