import { TimePeriodUnit as ApiTimePeriodUnit } from '@/services/generated/api';

export enum OtTimePeriodUnitEnum {
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS',
}

export class OtTimePeriodUnit {
  public value!: OtTimePeriodUnitEnum;
  public singular!: string;
  public plural!: string;

  public constructor(value: OtTimePeriodUnitEnum, singular: string, plural: string) {
    this.value = value;
    this.singular = singular;
    this.plural = plural;
  }

  public static readonly Hours = new OtTimePeriodUnit(OtTimePeriodUnitEnum.Hours, 'Hour', 'Hours');
  public static readonly Days = new OtTimePeriodUnit(OtTimePeriodUnitEnum.Days, 'Day', 'Days');
  public static readonly Weeks = new OtTimePeriodUnit(OtTimePeriodUnitEnum.Weeks, 'Week', 'Weeks');
}

export const timePeriods: OtTimePeriodUnit[] = [OtTimePeriodUnit.Hours, OtTimePeriodUnit.Days, OtTimePeriodUnit.Weeks];

export const timePeriodsMap: Map<string, OtTimePeriodUnit> = new Map(timePeriods.map(x => [x.value, x]));

export const parsedOtTimePeriodUnit: { [key in ApiTimePeriodUnit]: OtTimePeriodUnit } = {
  [ApiTimePeriodUnit.Weeks]: OtTimePeriodUnit.Weeks,
  [ApiTimePeriodUnit.Days]: OtTimePeriodUnit.Days,
  [ApiTimePeriodUnit.Hours]: OtTimePeriodUnit.Hours,
};

export const parsedApiTimePeriodUnit: { [key in OtTimePeriodUnitEnum]: ApiTimePeriodUnit } = {
  [OtTimePeriodUnitEnum.Weeks]: ApiTimePeriodUnit.Weeks,
  [OtTimePeriodUnitEnum.Days]: ApiTimePeriodUnit.Days,
  [OtTimePeriodUnitEnum.Hours]: ApiTimePeriodUnit.Hours,
};
