













































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import { OtOrganisationStatus, OtStatusType } from '@/types/status-enums';
import { Organisation } from '../models/organisation-models';
import { formatDateAndTime } from '@/utils/date-utils';
import { ROUTE_SETTINGS_EDIT } from './../settings-routes';

@Component({
  components: {
    OtTag,
    OtButton,
  },
})
export default class OtOrganisationDetailsIndex extends Vue {
  // * PROPS

  @Prop() private organisation!: Organisation;

  // * REFS

  // * DATA

  // * COMPUTED
  get hasPostalAddress(): boolean {
    return Boolean(this.organisation.postalAddress);
  }

  get editRoute(): string {
    return ROUTE_SETTINGS_EDIT;
  }

  // * WATCHERS

  // * METHODS

  // GET THE STATUS OF THE ORGANISATION
  private getOrganisationStatus(status: OtOrganisationStatus): TagStatus {
    return { type: OtStatusType.Organisation, status };
  }

  private formatDateAndTime(date: Date) {
    return formatDateAndTime(date);
  }
}

// * LIFECYCLE
