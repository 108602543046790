import { LastModifiedModel } from '@/services/generated/api';
import { format } from 'date-fns';

export interface IValidate {
  validate(): boolean;
  resetValidation(): void;
  reset(): void;
}
export interface IBlurrable {
  blur(): void;
}

export function isBlurrable(obj: unknown): obj is IBlurrable {
  // oh come on now typescript and linters, make up your mind
  // vscode has no problem with obj.blur here
  // but the compiler says "Property 'blur' does not exist on type 'object'."
  // so, shut up compiler, we're already checking if it has a blur property/method/field
  // THIS IS SAFE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof obj === 'object' && obj !== null && 'blur' in obj && typeof (obj as any).blur === 'function';
}

function blurCombo(combos: unknown) {
  if (!combos) {
    return;
  }
  if (Array.isArray(combos)) {
    for (const combo of combos) {
      if (isBlurrable(combo)) {
        combo.blur();
      }
    }
  } else {
    if (isBlurrable(combos)) {
      combos.blur();
    }
  }
}
export function blurCombos(...combos: unknown[]) {
  if (!combos) {
    return;
  }

  for (const combo of combos) {
    blurCombo(combo);
  }
}

export interface IVForm extends IValidate {
  $el: HTMLFormElement;
  register: (input: IValidate) => void;
  unregister: (input: IValidate) => void;
}

export function asForm(el: unknown) {
  return (el as IVForm) || null;
}

export interface IVuetifyCheckbox extends IValidate {
  lazyValue: boolean;
}

export interface IDictionary<T> {
  [key: string]: T;
}

export interface IApiError {
  errors: IDictionary<string[]>;
  status: number | undefined;
}

export interface ILastModified {
  dateModifiedUtc: Date;
  modifiedByGid: string;
  modifiedByFirstName: string;
  modifiedByLastName: string;
}

export class LastModified implements ILastModified {
  public dateModifiedUtc!: Date;
  public modifiedByGid!: string;
  public modifiedByFirstName!: string;
  public modifiedByLastName!: string;

  public constructor(value: ILastModified) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new LastModified({
      dateModifiedUtc: new Date(),
      modifiedByGid: '',
      modifiedByFirstName: '',
      modifiedByLastName: '',
    });
  }

  public static createFromApiResponse(response: LastModifiedModel) {
    return new LastModified({
      dateModifiedUtc: response.utc || new Date(),
      modifiedByGid: response.byGid || '',
      modifiedByFirstName: response.byFirstName || '',
      modifiedByLastName: response.byLastName || '',
    });
  }

  public get formattedString() {
    if (this.modifiedByFirstName || this.modifiedByLastName) {
      return `${format(this.dateModifiedUtc, 'dd/LL/yyyy')} by ${this.modifiedByFirstName} ${this.modifiedByLastName}`;
    }
    return `${format(this.dateModifiedUtc, 'dd/LL/yyyy')}`;
  }
}
