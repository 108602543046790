import { RouteConfig } from 'vue-router';
import OtComingSoon from '@/components/global/ot-coming-soon.vue';
import OtOrganisationCalendarDetails from '@/areas/settings/calendars/ot-organisation-calendar-details.vue';
import OtAddEditOrganisationCalendar from '@/areas/settings/calendars/ot-add-edit-organisation-calendar.vue';
import OtSettingsCalendarIndex from '@/areas/settings/ot-settings-calendar-index.vue';
import OtSettingsUsersIndex from '@/areas/settings/ot-settings-users-index.vue';
import OtOrganisationDetailsIndex from '@/areas/settings/organisations/ot-organisation-details-index.vue';
import OtEditOrganisationDetails from '@/areas/settings/organisations/ot-edit-organisation-details.vue';
import OtOrganisationUsersLayout from '@/areas/settings/organisation-users/ot-organisation-users-layout.vue';
import OtOrganisationUsersDetails from '@/areas/settings/organisation-users/ot-organisation-users-details.vue';
import OtEditOrganisationUserDetails from '@/areas/settings/organisation-users/ot-edit-organisation-user-details.vue';
import OtOrganisationUserProjectsIndex from '@/areas/settings/organisation-users/ot-organisation-user-projects-index.vue';
import OtOrganisationUserContractsIndex from '@/areas/settings/organisation-users/ot-organisation-user-contracts-index.vue';

import { BreadcrumbsId } from '@/components/global/common-models';
import OtSettingsLayout from '@/areas/settings/ot-settings-layout.vue';
import OtOrganisationCalendarLayout from '@/areas/settings/ot-organisation-calendar-layout.vue';

export const ROUTE_SETTINGS = 'Settings';
export const ROUTE_SETTINGS_USERS = 'UserSettings';
export const ROUTE_SETTINGS_CALENDARS = 'CalendarSettings';
export const ROUTE_SETTINGS_EDIT = 'EditOrganisation';
export const ROUTE_SETTINGS_ORG_USERS = 'UserSettingsOrg';
export const ROUTE_SETTINGS_ORG_USERS_EDIT = 'UserSettingsOrgEdit';
export const ROUTE_SETTINGS_ORG_USERS_LAYOUT = 'UserSettingsOrgLayout';
export const ROUTE_SETTINGS_ORG_USERS_PROJECTS = 'UserSettingsOrgProjects';
export const ROUTE_SETTINGS_ORG_USERS_CONTRACTS = 'UserSettingsOrgContracts';

export const settingsRoutes: Array<RouteConfig> = [
  {
    path: '/settings/:orgId',
    component: OtSettingsLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: ROUTE_SETTINGS,
        component: OtOrganisationDetailsIndex,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}`,
        },
      },
      {
        path: 'calendars',
        name: ROUTE_SETTINGS_CALENDARS,
        component: OtSettingsCalendarIndex,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Calendars`,
        },
      },
      {
        path: 'users',
        name: ROUTE_SETTINGS_USERS,
        component: OtSettingsUsersIndex,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Users`,
        },
      },
    ],
  },
  {
    path: '/settings/:orgId/users/:userGid',
    name: ROUTE_SETTINGS_ORG_USERS_LAYOUT,
    component: OtOrganisationUsersLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: ROUTE_SETTINGS_ORG_USERS,
        component: OtOrganisationUsersDetails,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Users/${BreadcrumbsId.UserId}`,
        },
      },
      {
        path: 'projects',
        name: ROUTE_SETTINGS_ORG_USERS_PROJECTS,
        component: OtOrganisationUserProjectsIndex,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Users/${BreadcrumbsId.UserId}/projects`,
        },
      },
      {
        path: 'contracts',
        name: ROUTE_SETTINGS_ORG_USERS_CONTRACTS,
        component: OtOrganisationUserContractsIndex,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Users/${BreadcrumbsId.UserId}/contracts`,
        },
      },
    ],
  },
  {
    path: '/settings/:orgId/edit',
    name: ROUTE_SETTINGS_EDIT,
    component: OtEditOrganisationDetails,
    meta: {
      requiresAuth: true,
      breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Edit`,
    },
  },
  {
    path: '/settings/:orgId/users/:userGid/edit',
    name: ROUTE_SETTINGS_ORG_USERS_EDIT,
    component: OtEditOrganisationUserDetails,
    meta: {
      requiresAuth: true,
      breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Users/${BreadcrumbsId.UserId}/Edit`,
    },
  },
  {
    path: '/settings/:orgId',
    component: OtOrganisationCalendarLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'calendars/add',
        name: 'AddCalendar',
        component: OtAddEditOrganisationCalendar,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Calendars/Add`,
        },
      },
      {
        path: 'calendars/add/:calendarGid',
        name: 'DuplicateCalendar',
        component: OtAddEditOrganisationCalendar,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Calendars/Add/${BreadcrumbsId.CalendarId}`,
        },
      },
      {
        path: 'calendars/:calendarGid',
        name: 'ViewCalendar',
        component: OtOrganisationCalendarDetails,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Calendars/${BreadcrumbsId.CalendarId}`,
        },
      },
      {
        path: 'calendars/:calendarGid/edit',
        name: 'EditCalendar',
        component: OtAddEditOrganisationCalendar,
        meta: {
          breadcrumb: `Settings/${BreadcrumbsId.OrganisationId}/Calendars/${BreadcrumbsId.CalendarId}/Edit`,
        },
      },
    ],
  },
];
