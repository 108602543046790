


























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { vxm } from '@/store';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtPhoneNumberField from '@/components/global/ot-phone-number-field.vue';
import OtApi, { executeApi } from '@/services/api.service';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtAddressField from '@/components/global/ot-address-field.vue';
import OtSelect from '@/components/global/ot-select.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtComboBox from '@/components/global/ot-combo-box.vue';
import { IInputTab } from '@/components/global/common-models';
import { dirtyFormClass } from '@/utils/validation-utils';
import { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import { blurCombos, IVForm } from '@/utils/type-utils';
import { OtUserStatus, OtStatusType } from '@/types/status-enums';
import { ContractUserDetails, ContractUserDetailsFormObject } from './contract-user-models';
import { ROUTE_CONTRACT_USERS, ROUTE_CONTRACT_USER_EDIT } from './contract-routes';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtTag,
    OtButton,
    OtTextField,
    OtTextarea,
    OtPhoneNumberField,
    OtAddressField,
    OtSelect,
    OtComboBox,
  },
})
export default class OtEditContractUserDetails extends Vue {
  // * PROPS

  // * REFS
  @Ref('contractUserDetailsFormRef') private readonly contractUserDetailsFormRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private formData = ContractUserDetailsFormObject.createEmpty();
  private contractUser = ContractUserDetails.createEmpty();
  private contractUserJobTitles: string[] = [];
  private isLoading = true;
  private originalThumbprint = '';
  private currentThumbprint = '';
  private saving = false;

  private get settingsTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'USER DETAILS',
        tabRoute: {
          params: { contractUserGid: this.contractUser.gid },
          name: ROUTE_CONTRACT_USER_EDIT,
        },
      },
    ];
  }

  // Validation rules

  public validate() {
    return this.contractUserDetailsFormRef.validate();
  }

  // * COMPUTED

  private get userStatusGid(): string {
    return this.contractUser.contractUserStatus?.gid || '';
  }

  private get contractUserStatus(): OtUserStatus {
    const status = this.contractUser.contractUserStatus?.status;
    // NOTE - the default here is inactive - it's never going to hit the other side of the || ?
    return status || OtUserStatus.Inactive;
  }

  private get dirtyFormClass(): string {
    return dirtyFormClass;
  }

  private get formIsDirty(): boolean {
    return this.originalThumbprint !== this.currentThumbprint;
  }

  get contractUserJobTitleItems(): IAutocompleteItem<string>[] {
    return this.contractUserJobTitles.map(t => {
      return {
        label: t,
        data: t,
      };
    });
  }

  get contractUsersRoute(): string {
    return ROUTE_CONTRACT_USERS;
  }

  private get projectGid() {
    return this.$route.params.projectGid || '';
  }

  private get contractGid() {
    return this.$route.params.contractGid || '';
  }

  private get userGid() {
    return this.$route.params.userGid || '';
  }
  private get userName() {
    return this.contractUser.firstName + ' ' + this.contractUser.lastName || '';
  }

  // * WATCHERS

  @Watch('routePath')
  private async checkRoutePath() {
    if (!this.contractUser || this.contractUser.gid !== this.userGid) {
      this.isLoading = true;
      await this.getContractUserDetails();
      this.isLoading = false;
    }
  }

  // watch the organisation user details and update the thumbprint
  @Watch('formData', { deep: true })
  private formDataChanged(val: ContractUserDetailsFormObject) {
    this.currentThumbprint = this.getContractUserDetailsThumbprint(val);
  }

  // * METHODS

  private navigateBackward() {
    // according to the ENG we literally just go back in the browser history
    return this.$router.back();
  }

  private getTagStatus(status: OtUserStatus): TagStatus {
    return { type: OtStatusType.User, status };
  }

  private setUserNameToBreadcrumb() {
    vxm.breadcrumbs.setUserName({ userName: this.userName });
  }

  private setProjectNameToBreadcrumb() {
    vxm.breadcrumbs.setProjectName({ projectName: this.contractUser.projectName });
  }

  private setContractNameToBreadcrumb() {
    vxm.breadcrumbs.setContractName({ contractName: this.contractUser.contractName });
  }

  public setFormToCleanState() {
    this.originalThumbprint = this.currentThumbprint;
  }

  private getContractUserDetailsThumbprint(contractUserDetails: ContractUserDetailsFormObject): string {
    const vals = {
      jobTitle: contractUserDetails.functionalRoleTitle || '',
    };
    return JSON.stringify(vals);
  }

  private async updateContractUserDetails() {
    blurCombos(this.$refs.jobTitleCombo);
    this.$nextTick(() => {
      this.updateContractUserDetailsInner();
    });
  }

  private async updateContractUserDetailsInner() {
    const valid = this.validate();

    if (valid && this.formData) {
      this.saving = true;
      const requestModel = ContractUserDetailsFormObject.createRequestModel(this.formData);

      const apiResponse = await executeApi(
        () =>
          this.api
            .contractUser()
            .postUpdateContractUserDetails(this.contractUser.contractUserStatus?.gid || '', undefined, requestModel),
        'Update Contract User Details',
      );
      if (apiResponse && apiResponse.status) {
        this.setFormToCleanState();
        // Even though we set the form to be clean just above,
        // the dirty modal still appears unless we reroute on the next tick
        this.$nextTick(() => {
          this.navigateBackward();
        });
      }
      this.saving = false;
    }
  }

  private async getContractUserDetails() {
    const result = await executeApi(
      () => this.api.contracts().getContractUserDetails(this.contractGid, this.userGid),
      'Load Contract User Details',
    );
    if (result.success && result.data && result.data) {
      this.contractUser = ContractUserDetails.createFromApiResponse(result.data);
      this.formData = ContractUserDetailsFormObject.createFromApiResponse(result.data);
      this.originalThumbprint = this.getContractUserDetailsThumbprint(this.formData);
      this.currentThumbprint = this.originalThumbprint;
      this.setProjectNameToBreadcrumb();
      this.setContractNameToBreadcrumb();
      this.setUserNameToBreadcrumb();
    }
  }
  private async getContractUserJobTitles() {
    const result = await executeApi(
      () => this.api.contractUser().getContractUserFunctionalRoleTitles(),
      'Load Functional Role Titles',
    );
    if (result.success && result.data && result.data) {
      this.contractUserJobTitles = result.data;
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
    this.getContractUserJobTitles();
  }
}
