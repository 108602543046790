



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenDateValue } from '../models/data-driven-result';
import { ZonelessDate } from '@/types/zoneless-date';
import OtDatePicker from '@/components/global/ot-date-picker.vue';

@Component({
  components: {
    OtDatePicker,
  },
})
export default class WfDateSelectorEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public formContainerElement?: HTMLElement;

  // * REFS

  // * DATA
  private title: string | null = null;
  private description: string | null = null;

  // * COMPUTED
  private dateValuePrivate: ZonelessDate | null = null;
  private get dateValue() {
    return this.dateValuePrivate;
  }
  private set dateValue(val: ZonelessDate | null) {
    this.dateValuePrivate = val;
    this.onChange();
  }

  private get dateRequiredMessage() {
    if (this.question.isMandatory) {
      return `Date is required`;
    }
    return undefined;
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'DateValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-date-selector-edit -> result -> ResultType is incorrect. Expected DateValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.dateValuePrivate = this.result?.date ? new ZonelessDate(this.result?.date) : null;
  }

  // * METHODS
  public onChange() {
    // We're using zoneless date to make sure the client timezone doesn't mess with the evaluated date string
    const result = new OtDataDrivenDateValue({
      resultType: 'DateValueModel',
      date: new ZonelessDate(this.dateValue),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.valueChanged();
  }
}
