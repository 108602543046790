




































































import { vxm } from '@/store';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { BreadcrumbsId, IInputBreadcrumb, IInputIcon } from './common-models';

@Component({})
export default class OtBreadcrumbs extends Vue {
  // * PROPS
  @Prop() overrideCrumbsData?: IInputBreadcrumb[];

  // * REFS

  // * DATA
  private arrowLeftIcon: IInputIcon = { icon: '$arrowLeft', iconColor: 'black', action: null };
  private get projectName(): string {
    return vxm.breadcrumbs.projectName;
  }
  private get userName(): string {
    return vxm.breadcrumbs.userName;
  }

  private get contractName(): string {
    return vxm.breadcrumbs.contractName;
  }

  private get documentName(): string {
    return vxm.breadcrumbs.documentName;
  }

  private get calendarName(): string {
    return vxm.breadcrumbs.calendarName;
  }

  private get workflowName(): string {
    return vxm.breadcrumbs.workflowName;
  }

  private get claimName(): string {
    return vxm.breadcrumbs.claimName;
  }

  private get organisationName(): string {
    return vxm.breadcrumbs.organisationName;
  }

  // * COMPUTED
  public get crumbs(): IInputBreadcrumb[] {
    const crumbsFromRoute: string = this.$route.meta.breadcrumb;

    if (crumbsFromRoute) {
      // The path is inside the URL string
      // e.g. for the url: "https://onetrack.com.au/projects/123/edit"
      // The path would be "/projects/123/edit"
      const pathArray = this.$route.path.split('/');

      // Getting rid of the empty element
      // The pathArray will initially have the following elements = ["", "path1", "path2". "path3"]
      pathArray.shift();

      // The breadcrumbs will be manually inserted into the routes when it is first created.
      // If the path is "/projects/123/edit", the breadcrumbs should be "projects/id/edit"
      // pathArray and breadcrumbs arrays MUST have the same length (after pathArray has got rid of the empty element)
      const crumbsList: string[] = crumbsFromRoute.split('/');

      let accumulatedHref = '';

      const crumbsFromMetaData = crumbsList.map((b, index) => {
        let breadcrumbText: string = b;

        switch (b) {
          case BreadcrumbsId.ProjectId:
            breadcrumbText = this.projectName;
            break;
          case BreadcrumbsId.UserId:
            breadcrumbText = this.userName;
            break;
          case BreadcrumbsId.ContractId:
            breadcrumbText = this.contractName;
            break;
          case BreadcrumbsId.DocumentId:
            breadcrumbText = this.documentName;
            break;
          case BreadcrumbsId.CalendarId:
            breadcrumbText = this.calendarName;
            break;
          case BreadcrumbsId.WorkflowId:
            breadcrumbText = this.workflowName;
            break;
          case BreadcrumbsId.ClaimId:
            breadcrumbText = this.claimName;
            break;
          case BreadcrumbsId.OrganisationId:
            breadcrumbText = this.organisationName;
            break;
          default:
            breadcrumbText = b;
        }

        const href = accumulatedHref + '/' + pathArray[index];
        accumulatedHref = href;

        return {
          text: breadcrumbText,
          disabled: index >= crumbsList.length - 1 ? true : false, // Disable if is the last item in the list
          href: href,
        };
      });

      // Add the dashboard link in to every breadcrumb
      if (this.$route.name !== 'Dashboard') {
        crumbsFromMetaData.unshift({ text: 'Dashboard', disabled: false, href: '/dashboard' });
      }

      // Adding an empty element so that the '/' would show up in the breadcrumb when the crumbsList only has 1 item
      if (crumbsFromMetaData.length < 2) {
        crumbsFromMetaData.push({
          text: ' ',
          disabled: true,
          href: '',
        });
      }

      return crumbsFromMetaData;
    }

    // if the breadcrumbs is being used in the Playground area OR the route doesn't have any breadcrumbs
    return this.overrideCrumbsData || [];
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
