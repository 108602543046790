





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import OtTextField from '@/components/global/ot-text-field.vue';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { numericKeyboardEventKeys, numericPunctuationEventKeys } from '@/well-known-values/key-codes';
import { filterPaste } from '@/utils/string-utils';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';
import { OtDataDrivenResponse, IResponsesGroupedByLayoutKey } from '../models/data-driven-response';
import { OtDataDrivenTextValue } from '../models/data-driven-result';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';

@Component({
  components: {
    OtTextField,
  },
})
export default class WfNumericalEntryEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private label?: string | null = null;
  private hint?: string | null = null;
  private placeholder?: string | null = null;
  private requiredMessage?: string | null = null;

  // * COMPUTED
  private textValuePrivate: string | null = null;
  private get textValue() {
    return this.textValuePrivate;
  }
  private set textValue(value: string | null) {
    this.textValuePrivate = value;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'TextValueModel') {
        return this.value.result;
      }
      console.warn(
        'wf-numerical-entry-edit -> result -> ResultType is incorrect. Expected TextValueModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.textValuePrivate = this.result?.text || null;
  }

  // * METHODS
  private filterKeypress(event: KeyboardEvent) {
    if (!numericKeyboardEventKeys.includes(event.key) || !numericPunctuationEventKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  private handlePaste(event: ClipboardEvent) {
    const pasteResult = filterPaste(event, this.textValue);

    if (pasteResult) {
      this.textValue = pasteResult.textValue;
      const textField = event.target as HTMLInputElement;
      this.$nextTick(() => {
        textField.selectionStart = pasteResult.startPos + pasteResult.data.length;
        textField.selectionEnd = pasteResult.startPos + pasteResult.data.length;
      });
    }
  }

  public onChange() {
    const result = new OtDataDrivenTextValue({
      resultType: 'TextValueModel',
      text: this.textValue || '',
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.label = this.question.title;
    this.hint = this.question.description;
    this.placeholder = this.question.placeholder;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : undefined;

    this.valueChanged();
  }
}
