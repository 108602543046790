import { EntityModifiedResponseModel as ApiEntityModifiedResponseModel } from '@/services/generated/api';

export interface IEntityModifiedResponse {
  rowVersion: string;
}

export class EntityModifiedResponse implements IEntityModifiedResponse {
  public rowVersion!: string;

  public constructor(value: IEntityModifiedResponse) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiEntityModifiedResponseModel) {
    return new EntityModifiedResponse({ ...model });
  }
}
