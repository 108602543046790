import { Cell, Column, ValueType } from 'exceljs';
import { format } from 'ssf';

// lifted from https://stackoverflow.com/a/75429518/11569

// Convert Date object to Microsoft serial date aka ms date aka OA date
const dateToSerial = (date: Date): number => {
  const timezoneOffset = date.getTimezoneOffset() / (60 * 24);
  const msDate = date.getTime() / 86400000 + (25569 - timezoneOffset);
  return msDate;
};

export const autoFitColumn = (column: Partial<Column>) => {
  const numFmt = column.numFmt;
  let maxLength = 6;
  // this is dumb. The .columns says that it's a Partial of a column. So, of course, eachCell method might be missing :/
  if (column.eachCell) {
    column.eachCell({ includeEmpty: true }, (cell: Cell) => {
      let columnLength: number;
      if (numFmt && cell.value != undefined) {
        switch (cell.type) {
          case ValueType.Date: {
            const serialDate = dateToSerial(cell.value as Date);
            const formattedDate = format(numFmt, serialDate);
            columnLength = formattedDate.length;
            break;
          }
          case ValueType.Number: {
            const formattedNumber = format(numFmt, cell.value as number);
            columnLength = formattedNumber.length;
            break;
          }
          default: {
            const formatted = format(numFmt, cell.value);
            columnLength = formatted.length;
            break;
          }
        }
      } else {
        columnLength = cell.text.length;
      }
      maxLength = Math.max(maxLength, columnLength);
    });
  }
  column.width = maxLength + 2;
};

// regex taken from https://stackoverflow.com/a/42210346/11569
// but I'm not actually going to use it
// const badCharactersRegex = /[/\\?%*:|"<>]/g;
export const cleanStringForFilename = (val: string) => {
  // ditch anything that's not a normal character
  // https://stackoverflow.com/a/50654588/11569
  return val.replace(/(\W+)/gi, '-');
};
