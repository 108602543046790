

















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IInputIcon } from '../common-models';
import styles from '@/styles/variables.scss';
import { v4 as uuid } from 'uuid';

interface WizardStepWithId {
  id: string;
  name: string;
}

@Component({})
export default class OtWizardArchetype extends Vue {
  // * PROPS
  @Prop() private wizardTitle!: string;
  @Prop() private instanceTitle!: string;
  @Prop() private wizardSteps!: string[];
  @Prop() private activeStep!: string;
  @Prop() private continueText!: string;
  @Prop({ default: false }) private showContinueText!: boolean;

  // * REFS
  private stepperElement?: HTMLElement;

  // * DATA
  private color = styles.otColorBlue500;

  // Arrow Buttons to show that there are more steps
  private chevronLeft: IInputIcon = { icon: '$chevronLeft', iconColor: styles.otColorBlue500, action: null };
  private chevronRight: IInputIcon = { icon: '$chevronRight', iconColor: styles.otColorBlue500, action: null };
  private showArrowBtns = false;
  private disableLeftArrowBtn = false;
  private disableRightArrowBtn = false;

  // * COMPUTED
  private get percentageValue() {
    return Math.round((this.currentStepIndex / this.wizardSteps.length) * 100);
  }

  private get currentStepIndex(): number {
    return this.wizardSteps.indexOf(this.activeStep);
  }

  private get wizardStepsWithId(): WizardStepWithId[] {
    return this.wizardSteps.map(s => ({ id: uuid(), name: s }));
  }

  // * WATCHERS
  // Keeps active step in the middle
  @Watch('activeStep')
  private handleStepChange() {
    if (this.showArrowBtns) {
      const stepElementList: HTMLElement[] = this.$refs[`step${this.currentStepIndex}`] as HTMLElement[];

      if (stepElementList[0]) {
        const stepElement = stepElementList[0];

        stepElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

        // Wait until the DOM has finished scrolling
        setTimeout(() => {
          if (this.stepperElement) {
            this.setDisableOnArrowBtns(this.stepperElement);
          }
        }, 250);
      }
    }
  }

  // * METHODS
  private scrollByArrowBtn(value: number) {
    if (this.stepperElement) {
      this.stepperElement.scrollBy({ left: value, behavior: 'smooth' });
      this.setDisableOnArrowBtns(this.stepperElement);
    }
  }

  // Check if the Stepper is scrollable
  // If the Stepper is scrollable, we need to show the arrow buttons
  private handleWindowResize() {
    if (this.stepperElement) {
      this.showArrowBtns = this.stepperElement.scrollWidth > this.stepperElement.clientWidth;
      this.setDisableOnArrowBtns(this.stepperElement);
    }
  }

  private setDisableOnArrowBtns(stepperElement: HTMLElement) {
    // disable the left arrow if there isn't anymore space to scroll to the left
    this.disableLeftArrowBtn = stepperElement.scrollLeft === 0;
    // disable the right arrow, if there isn't anymore space to scroll to the right
    this.disableRightArrowBtn = stepperElement.scrollLeft === stepperElement.scrollWidth - stepperElement.clientWidth;
  }

  // * LIFECYCLE
  protected created() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  protected mounted() {
    this.stepperElement = this.$refs.stepperElement as HTMLElement;

    this.$nextTick(() => {
      this.handleWindowResize();
      this.handleStepChange();
    });
  }

  protected destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
}
