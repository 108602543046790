

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { IWorkflowComponentDisplayDefinition } from '../models/workflow-component-definition';

@Component({})
export default class WfInformationPanelDisplay extends Vue implements IWorkflowComponentDisplayDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get title() {
    return this.question.title;
  }

  private get panelItems() {
    return this.question.options;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
