import { AccessType, StreetAddressModel } from '@/models/shared-models';
import { UserStatusWithActions } from '@/models/user-models';
import {
  GetContractUserDetailsResponseModel,
  PostUpdateContractUserDetailsModel,
  ContractUserStatusesModel,
} from '@/services/generated/api';
import { OtUserStatus } from '@/types/status-enums';
import { ZonelessDate } from '@/types/zoneless-date';
import { LastModified } from '@/utils/type-utils';

export interface IContractUser {
  gid: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationUserStatus: UserStatusWithActions | null;
  projectUserStatus: UserStatusWithActions | null;
  contractUserStatus: UserStatusWithActions | null;
}

export class ContractUser implements IContractUser {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public organisationUserStatus: UserStatusWithActions | null = null;
  public projectUserStatus: UserStatusWithActions | null = null;
  public contractUserStatus: UserStatusWithActions | null = null;

  public get name(): string {
    return ((this.firstName || '') + ' ' + (this.lastName || '')).trim();
  }
  public constructor(value: IContractUser) {
    Object.assign(this, value);
  }

  public static createEmpty(): ContractUser {
    return new ContractUser({
      gid: '',
      firstName: '',
      lastName: '',
      email: '',
      organisationUserStatus: null,
      projectUserStatus: null,
      contractUserStatus: null,
    });
  }

  public static createFromApiResponse(model: ContractUserStatusesModel): ContractUser {
    return new ContractUser({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      organisationUserStatus:
        model.organisations?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.organisations?.[0]) : null,
      projectUserStatus:
        model.projects?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.projects?.[0]) : null,
      contractUserStatus:
        model.contracts?.length > 0 ? UserStatusWithActions.createFromApiResponse(model.contracts?.[0]) : null,
    });
  }

  public static compareByName(a: ContractUser, b: ContractUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const contractNameA = a.name;
    const contractNameB = b.name;
    return contractNameA.localeCompare(contractNameB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByEmail(a: ContractUser, b: ContractUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    const codeA = a.email;
    const codeB = b.email;
    return codeA.localeCompare(codeB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static compareByContractRole(a: ContractUser, b: ContractUser, ascending: boolean) {
    const sortModifier = ascending ? 1 : -1;
    let titleA = '';
    let titleB = '';
    if (a.contractUserStatus) {
      titleA = a.contractUserStatus.title;
    }
    if (b.contractUserStatus) {
      titleB = b.contractUserStatus.title;
    }

    // if they are both undefined, then we want to sort them as equal - otherwise if one is undefined,
    // we want to sort it as greater than the other one
    // logically that seems ok to me but again, you might have a better idea.
    // Treat empty strings as greater than any non-empty value
    if (!titleA && !titleB) {
      return 0;
    } else if (!titleA && titleB) {
      return 1 * sortModifier;
    } else if (titleA && !titleB) {
      return -1 * sortModifier;
    }

    return titleA.localeCompare(titleB, undefined, { sensitivity: 'base' }) * sortModifier;
  }

  public static filterByName(keyword: string, contractUser: ContractUser) {
    if (keyword.length) {
      const regex = new RegExp(keyword, 'i');
      return contractUser.name.match(regex);
    }
    return true;
  }

  public static filterByUserStatus(statuses: OtUserStatus[], contractUser: ContractUser) {
    if (statuses.length) {
      return statuses.some(
        status =>
          contractUser.organisationUserStatus?.status === status ||
          contractUser.projectUserStatus?.status === status ||
          contractUser.contractUserStatus?.status === status,
      );
    }
    return true;
  }

  public static filterByTypeOfAccess(typeOfAccess: AccessType[], contractUser: ContractUser) {
    if (typeOfAccess.length) {
      return typeOfAccess.some(type => {
        switch (type) {
          case AccessType.Organisations:
            return contractUser.organisationUserStatus != null;
          case AccessType.Projects:
            return contractUser.projectUserStatus != null;
          case AccessType.Contracts:
            return contractUser.contractUserStatus != null;
          default:
            return false;
        }
      });
    }
    return true;
  }
}

export interface IContractUserDetails {
  gid: string;
  firstName: string;
  lastName: string;
  businessName: string | null;
  email: string;
  streetAddress: StreetAddressModel | null;
  postalAddress: StreetAddressModel | null;
  mobile: string | null;
  phone: string | null;
  fax: string | null;
  projectGid: string;
  projectName: string;
  contractUserStatus?: UserStatusWithActions;
  contractGid: string;
  contractName: string;
  lastModified: LastModified;
  lastLoginUtc?: ZonelessDate | null;
  lastLoginIpAddress?: string | undefined;
}

export class ContractUserDetails implements IContractUserDetails {
  public gid!: string;
  public firstName!: string;
  public lastName!: string;
  public businessName: string | null = null;
  public email!: string;
  public streetAddress: StreetAddressModel | null = null;
  public postalAddress: StreetAddressModel | null = null;
  public mobile: string | null = null;
  public phone: string | null = null;
  public fax: string | null = null;
  public projectGid!: string;
  public projectName!: string;
  public contractUserStatus?: UserStatusWithActions;
  public contractGid!: string;
  public contractName!: string;
  public lastModified!: LastModified;
  public lastLoginUtc?: ZonelessDate | null = null;
  public lastLoginIpAddress?: string | undefined;

  public constructor(value: IContractUserDetails) {
    Object.assign(this, value);
  }

  public static createEmpty(): ContractUserDetails {
    return new ContractUserDetails({
      gid: '',
      firstName: '',
      lastName: '',
      businessName: null,
      email: '',
      streetAddress: null,
      postalAddress: null,
      mobile: null,
      phone: null,
      fax: null,
      projectGid: '',
      projectName: '',
      contractUserStatus: UserStatusWithActions.createEmpty(),
      contractGid: '',
      contractName: '',
      lastModified: LastModified.createEmpty(),
      lastLoginUtc: new ZonelessDate(),
      lastLoginIpAddress: '',
    });
  }

  public static createFromApiResponse(model: GetContractUserDetailsResponseModel): ContractUserDetails {
    return new ContractUserDetails({
      gid: model.gid,
      firstName: model.firstName,
      lastName: model.lastName,
      businessName: model.businessName ?? null,
      email: model.email,
      streetAddress: model.streetAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.streetAddress) : null,
      postalAddress: model.postalAddress?.line1 ? StreetAddressModel.createFromApiResponse(model.postalAddress) : null,
      mobile: model.mobile ?? null,
      phone: model.phone ?? null,
      fax: model.fax ?? null,
      projectGid: model.projectGid,
      projectName: model.projectName,
      contractUserStatus: model.contractUserStatus
        ? UserStatusWithActions.createFromApiResponse(model.contractUserStatus)
        : undefined,
      contractGid: model.contractGid,
      contractName: model.contractName,
      lastModified: model.lastModified
        ? LastModified.createFromApiResponse(model.lastModified)
        : LastModified.createEmpty(),
      lastLoginUtc: model.lastLoginUtc ? new ZonelessDate(model.lastLoginUtc) : null,
      lastLoginIpAddress: model.lastLoginIpAddress,
    });
  }
}

export interface IContractUserDetailsFormObject {
  functionalRoleTitle: string;
}

export class ContractUserDetailsFormObject implements IContractUserDetailsFormObject {
  public functionalRoleTitle!: string;

  public constructor(value: IContractUserDetailsFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty(): ContractUserDetailsFormObject {
    return new ContractUserDetailsFormObject({
      functionalRoleTitle: '',
    });
  }

  public static createFromApiResponse(model: GetContractUserDetailsResponseModel): ContractUserDetailsFormObject {
    return new ContractUserDetailsFormObject({
      functionalRoleTitle: model.contractUserStatus ? model.contractUserStatus.title : '',
    });
  }

  public static createRequestModel(model: ContractUserDetailsFormObject): PostUpdateContractUserDetailsModel {
    return new PostUpdateContractUserDetailsModel({
      functionalRoleTitle: model.functionalRoleTitle,
    });
  }
}
