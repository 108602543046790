






































































import { Component, Vue } from 'vue-property-decorator';
import OtWizardArchetype from '@/components/global/archetypes/ot-wizard-archetype.vue';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';

@Component({
  components: {
    OtWizardArchetype,
    OtButton,
  },
})
export default class OtWizardWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private items = [{ title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me' }, { title: 'Click Me 2' }];

  private wizardSteps1: string[] = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
  private activeStep1 = 'Step 1';
  private wizardTitle1 = 'Wizard Title';
  private instanceTitle1 = 'Instance Title';

  private wizardSteps2: string[] = [
    'Step 1',
    'Step 2',
    'Step 3',
    'Step 4',
    'Step 5',
    'Step 6',
    'Step 7',
    'Step 8',
    'Step 9',
    'Step 10',
    'Step 11',
    'Step 12',
    'Step 13',
    'Step 14',
    'Step 15',
    'Step 16',
    'Step 17',
    'Step 18',
    'Step 19',
    'Step 20',
    'Step 21',
    'Step 22',
    'Step 23',
    'Step 24',
    'Step 25',
  ];
  private activeStep2 = 'Step 15';
  private wizardTitle2 = 'Wizard Title';
  private instanceTitle2 = 'Instance Title';
  private nextBtnLabel = 'Next Step';
  private btnSize = OtBtnSize.Large;
  private btnStyle = OtBtnStyle.Primary;
  private btnType = OtBtnType.TextMiddle;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private onPrevStep() {
    const currentActiveStepIndex = this.wizardSteps2.indexOf(this.activeStep2);
    if (currentActiveStepIndex <= 0) {
      return;
    }

    this.activeStep2 = this.wizardSteps2[currentActiveStepIndex - 1];
  }

  private onNextStep() {
    const currentActiveStepIndex = this.wizardSteps2.indexOf(this.activeStep2);
    if (currentActiveStepIndex >= this.wizardSteps2.length - 1) {
      return;
    }

    this.activeStep2 = this.wizardSteps2[currentActiveStepIndex + 1];
  }

  // * LIFECYCLE
}
