







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { vxm } from '@/store';
import { SnackbarItem, SnackbarTypeEnum } from './snackbar-models';
import Styles from '@/styles/variables.scss';

@Component({})
export default class OtSnackbar extends Vue {
  // * PROPS

  // * REFS

  // * DATA

  // * COMPUTED
  private get vxmSnackbarItemQueue() {
    return vxm.snackbar.snackbarAlertsQueue;
  }

  // * WATCHERS
  @Watch('vxmSnackbarItemQueue')
  private vxmSnackbarItemQueueChanged(items: SnackbarItem[]) {
    for (const item of items) {
      setTimeout(() => {
        if (item.isVisible === false) {
          const ref = this.$refs['snackbarContent' + item.id] as HTMLDivElement[];
          if (ref && item.ref) {
            ref[0].innerHTML = '';
            ref[0].appendChild(item.ref);
            ref[0].firstElementChild?.classList.add('--visible');
          }
        }
        item.isVisible = true;
      }, 200);
    }
  }

  // * METHODS
  private messageIsArray(message: string | string[]): boolean {
    return Array.isArray(message) && message.length > 1;
  }

  private singleMessage(message: string | string[]) {
    if (Array.isArray(message)) {
      if (message.length > 0) {
        return message[0];
      }
      return undefined;
    }
    return message;
  }

  private close(id: string) {
    this.showSnackbar(id, false);
  }

  private showSnackbar(id: string, value: boolean) {
    const item = this.vxmSnackbarItemQueue.find(item => item.id === id);
    if (item) {
      item.isVisible = value;
      if (value === false) {
        setTimeout(() => {
          vxm.snackbar.removeFromSnackbar(id);
        }, 200);
      }
    }
  }

  private snackbarTypeClass(type: SnackbarTypeEnum): string {
    const baseClass = 'ot-snackbar__item';
    switch (type) {
      case SnackbarTypeEnum.Success:
        return `${baseClass} --success`;
      case SnackbarTypeEnum.Error:
        return `${baseClass} --error`;
      case SnackbarTypeEnum.Primary:
        return `${baseClass} --primary`;
      case SnackbarTypeEnum.Default:
      default:
        return `${baseClass} --default`;
    }
  }

  private snackbarColor(type: SnackbarTypeEnum) {
    switch (type) {
      case SnackbarTypeEnum.Success:
        return Styles.otColorGreen300;
      case SnackbarTypeEnum.Error:
        return Styles.otColorRed300;
      case SnackbarTypeEnum.Primary:
        return Styles.otColorBlue500;
      case SnackbarTypeEnum.Default:
      default:
        return Styles.otColorBlue200;
    }
  }

  // * LIFECYCLE
}
