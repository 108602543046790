import {
  GetInvitationDetailsInvitationComponentModel,
  GetInvitationDetailsResponseModel,
  PostAcceptInvitationRequestModel,
  PostAcceptInvitationUserContextModel,
} from '@/services/generated/api';

export interface IAcceptInvitationComponent {
  /** The public identifier for the Table: Organisation User Invitation, Table: Project User Invitation or Table: Contract User Invitation */
  gid: string;
  /** The ‘Organisation Job Title’ or ‘Project Functional Role Title’ or ‘Contract Functional Role Title’ the recipient will have if they accept the invitation */
  title: string;
  /** The Code (or Reference) of the Organisation, Project or Contract this invitation sub component is for */
  code: string;
  /** The Name of the Organisation, Project or Contract this invitation sub component is for */
  name: string;
  /** Whether the user wants to accept this or not */
  isAccepted: boolean;
}

export class AcceptInvitationComponent implements IAcceptInvitationComponent {
  gid!: string;
  /** The ‘Organisation Job Title’ or ‘Project Functional Role Title’ or ‘Contract Functional Role Title’ the recipient will have if they accept the invitation */
  title!: string;
  /** The Code (or Reference) of the Organisation, Project or Contract this invitation sub component is for */
  code!: string;
  /** The Name of the Organisation, Project or Contract this invitation sub component is for */
  name!: string;
  /** Whether the user wants to accept this or not */
  isAccepted!: boolean;
  public constructor(value: IAcceptInvitationComponent) {
    Object.assign(this, value);
  }
  public static createEmpty() {
    return new AcceptInvitationComponent({
      code: '',
      gid: '',
      isAccepted: false,
      name: '',
      title: '',
    });
  }

  public static createFromApiResponse(model: GetInvitationDetailsInvitationComponentModel): AcceptInvitationComponent {
    return new AcceptInvitationComponent({
      gid: model.gid || '',
      code: model.code || '',
      isAccepted: true,
      name: model.name || '',
      title: model.title || '',
    });
  }
}

export interface IAcceptInviationFormObject {
  gid: string;
  inviterFirstName: string;
  inviterLastName: string;
  recipientFirstName: string;
  recipientLastName: string;
  inviterEmail: string;
  recipientEmail: string;
  organisationCode: string;
  organisationName: string;
  completedDateUtc: Date | null;
  organisations: IAcceptInvitationComponent[];
  projects: IAcceptInvitationComponent[];
  contracts: IAcceptInvitationComponent[];
}

export class AcceptInviationFormObject implements IAcceptInviationFormObject {
  gid!: string;
  inviterFirstName!: string;
  inviterLastName!: string;
  recipientFirstName!: string;
  recipientLastName!: string;
  inviterEmail!: string;
  recipientEmail!: string;
  organisationCode!: string;
  organisationName!: string;
  completedDateUtc!: Date | null;
  organisations!: IAcceptInvitationComponent[];
  projects!: IAcceptInvitationComponent[];
  contracts!: IAcceptInvitationComponent[];

  public constructor(value: IAcceptInviationFormObject) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new AcceptInviationFormObject({
      contracts: [],
      gid: '',
      inviterEmail: '',
      inviterFirstName: '',
      inviterLastName: '',
      organisationCode: '',
      organisationName: '',
      organisations: [],
      projects: [],
      recipientEmail: '',
      recipientFirstName: '',
      recipientLastName: '',
      completedDateUtc: null,
    });
  }
  public static createFromApiResponse(model: GetInvitationDetailsResponseModel): AcceptInviationFormObject {
    return new AcceptInviationFormObject({
      contracts:
        model.contracts && model.contracts.length > 0
          ? model.contracts?.map(x => AcceptInvitationComponent.createFromApiResponse(x))
          : [],
      gid: model.gid || '',
      inviterEmail: model.inviterEmail || '',
      inviterFirstName: model.inviterFirstName || '',
      inviterLastName: model.inviterLastName || '',
      organisationCode: model.organisationCode || '',
      organisationName: model.organisationName || '',
      organisations:
        model.organisations && model.organisations.length > 0
          ? model.organisations?.map(x => AcceptInvitationComponent.createFromApiResponse(x))
          : [],
      projects:
        model.projects && model.projects.length > 0
          ? model.projects?.map(x => AcceptInvitationComponent.createFromApiResponse(x))
          : [],
      recipientEmail: model.recipientEmail || '',
      recipientFirstName: model.recipientFirstName || '',
      recipientLastName: model.recipientLastName || '',
      completedDateUtc: model.completedDateUtc || null,
    });
  }

  public static createRequestModel(model: AcceptInviationFormObject): PostAcceptInvitationRequestModel {
    return new PostAcceptInvitationRequestModel({
      organisations: model.organisations.map(
        x =>
          new PostAcceptInvitationUserContextModel({
            accepted: x.isAccepted,
            gid: x.gid,
          }),
      ),
      projects: model.projects.map(
        x =>
          new PostAcceptInvitationUserContextModel({
            accepted: x.isAccepted,
            gid: x.gid,
          }),
      ),
      contracts: model.contracts.map(
        x =>
          new PostAcceptInvitationUserContextModel({
            accepted: x.isAccepted,
            gid: x.gid,
          }),
      ),
    });
  }
}
