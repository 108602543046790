





































import { Component, Vue, Ref } from 'vue-property-decorator';
import OtFormModal from '@/components/global/modal/ot-form-modal.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';

import { FormModalParams } from '../global/modal/form-modal-models';
import { IVForm } from '@/utils/type-utils';

export class ClaimSuspensionDialogResult {
  public doSuspend = false;
  public suspendReason = '';

  constructor(params?: Partial<ClaimSuspensionDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

@Component({
  components: {
    OtFormModal,
    OtTextarea,
    OtCheckbox,
    OtFieldArchetype,
  },
})
export default class OtClaimSuspensionDialog extends Vue {
  // * PROPS

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;
  @Ref('theCheckbox') private readonly theCheckbox!: OtCheckbox;

  // * DATA
  private isCheckboxSelectedPrivate = false;
  private get isCheckboxSelected() {
    return this.isCheckboxSelectedPrivate;
  }
  private set isCheckboxSelected(val: boolean) {
    this.isCheckboxSelectedPrivate = val;
    // this is SUCH a messy hack
    // the insides of the checkbox are ridiculously complex. Like, why do we need SO MUCH code for a checkbox?
    // the basic problem is there's a sync'd error state property, for reasons I don't fully understand, and it never gets set to false
    // there's a comment in the code saying "if you're having problems, call resetManualErrorState"
    // So, I am. I wish there was a better solution. Throw away the custom components and work with native vuetify would be THE solution I reckon
    this.theCheckbox.resetManualErrorState();
  }
  private suspendReason: string | null = null;

  private checkboxRules = [(value: string) => !!value || 'Please confirm your intention to suspend this workflow'];

  private dodgyHack = 0;

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  public async open(): Promise<ClaimSuspensionDialogResult> {
    this.isCheckboxSelected = false;
    this.suspendReason = '';
    // For some reason the form REALLY DOES NOT want to drop previous validation errors
    // I don't want to run through every form component resetting its validation
    // I would have thought resetValidation on the form does it, but here we are
    // resetValidation is OK if you've tried to submit the form and gotten a validation error
    // but, if you've ticked and unticked the checkbox, resetValidation doesn't reset that, and the next time
    // you open the form you're looking at the validation error from last time
    // This dodgy hack causes Vue to recreate the form completely
    this.dodgyHack += 1;
    await this.$nextTick();
    this.modalContentRef.reset();
    this.modalContentRef.resetValidation();

    const params = new FormModalParams({
      title: 'Suspend Workflow',
      formRef: this.modalContentRef,
      confirmText: 'Confirm Workflow Suspension',
    });
    const result = await this.formModalRef.open(params);
    if (result.ok) {
      return new ClaimSuspensionDialogResult({
        doSuspend: true,
        suspendReason: this.suspendReason || undefined,
      });
    }
    return new ClaimSuspensionDialogResult({
      doSuspend: false,
    });
  }

  // * LIFECYCLE
}
