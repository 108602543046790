












import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectUserContract } from './project-user-contract-models';
import OtProjectUserContractsTable from './ot-project-user-contracts-table.vue';

@Component({
  components: {
    OtProjectUserContractsTable,
  },
})
export default class OtProjectUserContractsIndex extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private projectUserContracts!: ProjectUserContract[];

  // * REFS

  // * DATA

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
