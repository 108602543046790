

























import { Component, Ref, Vue } from 'vue-property-decorator';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import OtButton from '@/components/global/ot-button.vue';
import { v4 as uuid } from 'uuid';
import AdditionalInformationDialogExampleDefinition from '@/components/workflow-engine/example-data/additional-information-dialog-example-definition';
import { OtDataDrivenInstance } from '@/wf-components/models/data-driven-instance';

@Component({
  components: {
    OtWorkflowEngineProcessor,
    OtButton,
  },
})
export default class WorkflowAdditionalInformationDialogWrapper extends Vue {
  // * PROPS

  // * REFS
  @Ref('workflowEngineProcessorRef') private readonly workflowEngineProcessorRef!: OtWorkflowEngineProcessor;

  // * DATA
  private finalStepGuid = uuid();
  private definition = AdditionalInformationDialogExampleDefinition;
  private instance = OtDataDrivenInstance.createEmpty();
  private isInDisplayMode = false;

  // * COMPUTED
  private get layoutKey() {
    if (this.definition.layoutsOrdered[0]) {
      return this.definition.layoutsOrdered[0].key;
    }
    console.warn('workflow-engine-wrapper -> layoutKey:  No page layouts found');
    return '';
  }

  // * WATCHERS

  // * METHODS
  private async submitWorkflowEngine() {
    const result = await this.workflowEngineProcessorRef.submit();
    console.log('workflow-additional-information-dialog-wrapper -> submitWorkflowEngine -> result:', result);
  }

  // * LIFECYCLE
}
