






























import OtButton from '@/components/global/ot-button.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtApi, { executeApi } from '@/services/api.service';
import downloadjs from 'downloadjs';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OtTextField,
    OtButton,
  },
})
export default class SegmentInstanceLogWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private api = new OtApi();
  private segmentInstanceGid = '';

  // * COMPUTED

  // * WATCHERS

  // * METHODS
  private async doDownload() {
    const apiResult = await executeApi(
      () => this.api.segmentInstances().getSegmentInstanceMessageDetails(this.segmentInstanceGid),
      'Downloading log',
    );

    if (apiResult.success && apiResult.data) {
      const contentType =
        (apiResult.data.headers && apiResult.data.headers['content-type']) || 'application/octet-stream';
      downloadjs(apiResult.data.data, `log.xlsx`, contentType);
    }
  }

  // * LIFECYCLE
}
