


































import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { IDataDrivenOptionsList, OtDataDrivenOptionsListWithOther } from '../models/data-driven-result';
import { OtDataDrivenValue } from '../models/data-driven-value';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';

@Component({
  components: {
    OtCheckboxGroup,
    OtTextarea,
  },
})
export default class WfCheckboxListWithOtherEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  // * REFS

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private requiredMessage: string | null = null;

  // * COMPUTED
  private get items(): CheckboxGroupItem<string>[] {
    if (this.question.selectedOptionsFromQuestionKeys?.length) {
      const foundQuestions = this.definition.layoutsOrdered
        .flatMap(l => l.sectionsOrdered.flatMap(s => s.questionsOrdered))
        .filter(q => this.question.selectedOptionsFromQuestionKeys?.includes(q.key));

      let selectedOptionCheckboxes: CheckboxGroupItem<string>[] = [];
      for (const question of foundQuestions) {
        const responseForQuestion = this.instance.responses.find(
          r => r.questionKey === question.key && r.result.resultType === 'OptionsListModel',
        );

        if (responseForQuestion) {
          const selectedOptions = (responseForQuestion.result as IDataDrivenOptionsList).values.filter(
            o => !this.question.ignoreSelectedOptionsFromQuestionKeys?.includes(o.key),
          );
          const selectedOptionsWithTitle = selectedOptions.filter(o => o.title);
          selectedOptionCheckboxes = selectedOptionCheckboxes.concat(
            selectedOptionsWithTitle.map(o => new CheckboxGroupItem(o.title || '', o.key)),
          );

          const selectedOptionKeysWithTitle = selectedOptionsWithTitle.map(o => o.key);
          const selectedOptionKeys = selectedOptions
            .filter(o => !selectedOptionKeysWithTitle.includes(o.key))
            .map(o => o.key);
          const originalQuestionOptions = question.options?.filter(o => selectedOptionKeys.includes(o.key)) || [];
          selectedOptionCheckboxes = selectedOptionCheckboxes.concat(
            originalQuestionOptions.map(o => new CheckboxGroupItem(o.title, o.key)),
          );
        }
      }
      if (selectedOptionCheckboxes.length) {
        return selectedOptionCheckboxes;
      }

      console.warn(
        'wf-checkbox-list-with-other-edit -> items -> Unable to find any responses for the question key: ',
        this.question.selectedOptionsFromQuestionKeys,
      );
      return [];
    }
    return this.question.optionsOrdered.map(o => new CheckboxGroupItem(o.title, o.key)) || [];
  }

  private localValuePrivate: CheckboxGroupItem<string>[] = [];
  private get localValue(): CheckboxGroupItem<string>[] {
    return this.localValuePrivate;
  }
  private set localValue(val: CheckboxGroupItem<string>[]) {
    this.localValuePrivate = val;
    this.onChange();
  }

  private get disableTextarea() {
    return !this.isOtherSelected;
  }
  private get keyOfOtherItem() {
    const options = this.question.options;
    if (!options || options.length === 0) {
      return undefined;
    }
    return options[options.length - 1].key;
    // I'd like to use .at, but the vue compiler doesn't have a new enough version of ts
    // and we get an error
    // Property 'at' does not exist on type 'OtDataDrivenOption[]'.
    // whereas vscode thinks it is pukka
    //return this.question.options?.at(-1)?.key;
  }
  private get isOtherSelected() {
    return this.localValue.some(x => x.value === this.keyOfOtherItem);
  }
  private get otherRequiredMessage() {
    if (this.isOtherSelected) {
      return 'This field is required';
    }
    return null;
  }
  private otherDescriptionPrivate: string | null = null;
  private get otherDescription() {
    return this.otherDescriptionPrivate;
  }
  private set otherDescription(val: string | null) {
    this.otherDescriptionPrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'OptionsListWithOtherModel') {
        return this.value.result;
      }
      console.warn(
        'wf-checkbox-list-with-other-edit -> result -> ResultType is incorrect. Expected OptionsListWithOtherModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    const val = this.result?.values.map(v => this.findCheckboxItemFromKey(v.key)) || [];
    this.localValuePrivate = val.filter(i => i !== undefined) as CheckboxGroupItem<string>[];
    this.otherDescriptionPrivate = this.result?.otherText ?? null;
  }

  // * METHODS
  private findCheckboxItemFromKey(key: string): CheckboxGroupItem<string> | undefined {
    return this.items.find(i => i.value === key);
  }

  public onChange() {
    const result = new OtDataDrivenOptionsListWithOther({
      resultType: 'OptionsListWithOtherModel',
      otherText: this.isOtherSelected ? this.otherDescription : null,
      values: this.localValue.map(
        (v, index) =>
          new OtDataDrivenValue({
            key: v.value,
            orderIndex: index,
          }),
      ),
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.requiredMessage = this.question.isMandatory ? 'This field is mandatory' : null;

    this.valueChanged();
  }
}
