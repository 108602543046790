

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  TiptapVuetify,
  History,
  Bold,
  Italic,
  Underline,
  ListItem,
  BulletList,
  Heading,
  Paragraph,
  Blockquote,
  HorizontalRule,
  HardBreak,
} from 'tiptap-vuetify';
import { asForm, IValidate, IVForm } from '@/utils/type-utils';
import OtFieldArchetype from './archetypes/ot-field-archetype.vue';
import { removeAllHtmlTags } from '@/utils/string-utils';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
    TiptapVuetify,
    OtFieldArchetype,
  },
})
export default class OtFormatTextarea extends Vue implements IValidate {
  // * PROPS
  @Prop() private value!: string | null;
  @Prop({ default: () => [] }) private rules!: Array<(value: string | null) => boolean | string>;
  @Prop({ type: String }) private requiredMessage?: string;
  @Prop({ type: String }) private label?: string;
  @Prop({ type: String }) private hint?: string;
  @Prop({ type: String }) private description?: string;
  @Prop({ type: Boolean, default: false }) private light!: boolean;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly!: boolean;

  // * REFS

  // * DATA
  private tipTapExtensions = [
    History,
    Bold,
    Italic,
    Underline,
    ListItem,
    BulletList,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3],
        },
      },
    ],
    HorizontalRule,
    Paragraph,
    Blockquote,
    HardBreak,
  ];

  private isRequiredRule = (value: string | null) =>
    (!!value && !!removeAllHtmlTags(value)) || this.requiredMessage || '';
  private valid = true;
  private hasBeenValidatedBefore = false;
  private errorBucket: string[] = [];
  private hasFocus = false;
  private componentKey = uuid();

  // * COMPUTED
  private internalValuePrivate: string | null = null;
  private get internalValue() {
    return this.internalValuePrivate;
  }
  private set internalValue(val: string | null) {
    this.internalValuePrivate = val;
    if (this.hasBeenValidatedBefore) {
      this.validate();
    }
    this.$emit('input', val);
  }

  private get inputRules(): Array<(value: string | null) => boolean | string> {
    if (this.disabled || this.readonly) {
      return [];
    }

    const rules = this.rules || [];
    if (this.requiredMessage) {
      rules.unshift(this.isRequiredRule);
    }
    return rules;
  }

  private get currentError() {
    return this.errorBucket[0] || null;
  }

  private get editorProperties() {
    return { editable: !this.readonly };
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    this.internalValuePrivate = this.value;
  }

  // because editor properties get used in the creation stage,
  // they won't update unless we reload the component any time it changes
  @Watch('editorProperties', { deep: true })
  private editorPropertiesChanged() {
    this.componentKey = uuid();
  }

  // * METHODS
  public resetValidation() {
    this.valid = true;
    this.errorBucket = [];
    this.hasBeenValidatedBefore = false;
  }

  public reset() {
    this.resetValidation();
    this.internalValue = null;
  }

  // even though we aren't using the 'force' parameter we still need it
  // because vuetify will be calling this function and it expects one there
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(force = false, value?: string | null): boolean {
    if (!this.readonly) {
      const errorBucket = [];
      value = value || this.internalValuePrivate;

      this.hasBeenValidatedBefore = true;

      for (let index = 0; index < this.inputRules.length; index++) {
        const rule = this.inputRules[index];
        const valid = typeof rule === 'function' ? rule(value) : rule;

        if (valid === false || typeof valid === 'string') {
          errorBucket.push(valid || '');
        }
      }

      this.errorBucket = errorBucket;
      this.valid = errorBucket.length === 0;

      return this.valid;
    }
    return true;
  }

  private onFocus() {
    this.hasFocus = true;
  }

  private onBlur() {
    this.hasFocus = false;
    this.validate();
  }

  // * LIFECYCLE
  private mounted() {
    this.valueChanged();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;

    // Find the first form element and register this component to it if it is found
    // so that the validation on this component can trigger when the form.validate() function is called
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.register(this);
    }
  }
  private beforeDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Vue | null = this;
    while ((el = el.$parent) && !el.$el.matches.call(el.$el, '.v-form'));
    const form = asForm(el);
    if (form) {
      form.unregister(this);
    }
  }
}
