




























































// import { AuthService } from '@/services/auth.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PLAYGROUND_COMPONENTS } from './playground-components';

// import axios from 'axios';

// const auth = new AuthService();

@Component({
  components: {},
})
export default class Index extends Vue {
  @Prop(String)
  public component!: string;

  private get selectedComponent() {
    return this.components.find(components => components.componentName === this.component);
  }

  private components = PLAYGROUND_COMPONENTS;
}
