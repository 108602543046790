
















import { Component, Vue } from 'vue-property-decorator';
import OtDateTimeField from '@/components/global/ot-date-time-field.vue';
import OtAutocomplete, { IAutocompleteItem } from '@/components/global/ot-autocomplete.vue';
import { getTimeZones } from '@vvo/tzdb';
import { format } from 'date-fns';

@Component({
  components: {
    OtDateTimeField,
    OtAutocomplete,
  },
})
export default class OtDateTimeFieldWrapper extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private selectedTimezone: IAutocompleteItem<string> | null = null;
  private date: Date | null = new Date();
  private allTimezones = getTimeZones()
    .filter(t => t.countryCode === 'AU')
    .flatMap(t => t.group)
    .sort((a, b) => a.localeCompare(b));

  // * COMPUTED
  private get selectedTimezoneText() {
    return this.selectedTimezone?.data || null;
  }
  private get allTimezoneItems(): IAutocompleteItem<string>[] {
    const timezoneNames: IAutocompleteItem<string>[] = this.allTimezones.map(t => {
      return { label: t, data: t };
    });
    return timezoneNames;
  }

  // * WATCHERS

  // * METHODS
  private formatDate(date: Date | null) {
    if (date) {
      return format(date, 'dd-LL-yyyy HH:mm');
    }
    return '';
  }

  // * LIFECYCLE
}
