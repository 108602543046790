



















import { Component, Vue } from 'vue-property-decorator';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import { IInputAction } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import OtProjectsTable from './ot-projects-table.vue';
import { Project } from './project-models';
import { PermissionLevel } from '@/services/generated/api';
import { ROUTE_PROJECT_CREATE } from './projects-routes';
import { vxm } from '@/store';
import { ProfileOrganisationModel } from '../profile/models';
import { OtProjectStatus } from '@/types/status-enums';

@Component({
  components: {
    OtLoadingSpinner,
    OtStandardHeaderArchetype,
    OtProjectsTable,
  },
})
export default class OtProjectsIndex extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private newProjectButton: IInputAction = {
    actionName: 'New Project',
    action: { name: ROUTE_PROJECT_CREATE },
  };
  private loading = false;
  private api = new OtApi();
  private projects: Project[] = [];
  private userOrganisations: ProfileOrganisationModel[] = [];

  // * COMPUTED
  private get primaryContextBtn() {
    return this.newProjectButton;
  }

  private get isUserAdminOrManager() {
    return this.projects.some(x => x.status === OtProjectStatus.Active);
  }

  // * WATCHERS

  // * METHODS

  private async handleReloadProjects(callback?: () => void) {
    const apiResponse = await executeApi(() => this.api.projects().getProjects(PermissionLevel.View), 'Load Projects');
    if (apiResponse.success && apiResponse.data?.projects) {
      const projectData = apiResponse.data.projects.map(s => Project.createFromApiResponse(s));
      this.projects = projectData.map(s => s);
    }

    if (callback) {
      callback();
    }
  }

  private async getUserProfile() {
    const results = await vxm.userProfile.getUserProfile();
    if (results) {
      this.userOrganisations = results.organisations;
    }
  }

  // * LIFECYCLE
  private async created() {
    this.loading = true;
    await Promise.all([this.handleReloadProjects(), this.getUserProfile()]);
    this.loading = false;
  }
}
