// Data Driven Section Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2372927529/Data+Driven+Section+Object

import { DataDrivenSectionModel as ApiDataDrivenSectionModel } from '@/services/generated/api';
import Handlebars from 'handlebars';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenQuestion } from './data-driven-question';

export interface IDataDrivenSection {
  key: string;
  readonly title: string | null;
  orderIndex: number;
  readonly description: string | null;
  questions: OtDataDrivenQuestion[];
}

export class OtDataDrivenSection implements IDataDrivenSection {
  public key!: string;
  public orderIndex!: number;
  public questions!: OtDataDrivenQuestion[];

  private titleOriginal: string | null = null;
  private titleTemplate: HandlebarsTemplateDelegate | null = null;
  private titlePrivate: string | null = null;
  public get title() {
    return this.titlePrivate;
  }
  private descriptionOriginal: string | null = null;
  private descriptionTemplate: HandlebarsTemplateDelegate | null = null;
  private descriptionPrivate: string | null = null;
  public get description() {
    return this.descriptionPrivate;
  }

  constructor(value: IDataDrivenSection) {
    const { title, description, ...rest } = { ...value };
    Object.assign(this, rest);
    this.titlePrivate = title;
    this.descriptionPrivate = description;

    this.titleOriginal = title;
    this.descriptionOriginal = description;

    // Pretty simple approach to seeing "does it smell like a template"
    if (this.titleOriginal?.includes('{{')) {
      this.titleTemplate = Handlebars.compile(this.titleOriginal);
    }
    if (this.descriptionOriginal?.includes('{{')) {
      this.descriptionTemplate = Handlebars.compile(this.descriptionOriginal);
    }
  }

  public runTemplates(contextObject: unknown) {
    if (this.titleTemplate) {
      this.titlePrivate = this.titleTemplate(contextObject);
    }
    if (this.descriptionTemplate) {
      this.descriptionPrivate = this.descriptionTemplate(contextObject);
    }
  }

  public get questionsOrdered() {
    const questionsOrdered = [...this.questions];
    questionsOrdered.sort((a, b) => a.orderIndex - b.orderIndex);
    return questionsOrdered;
  }

  public static createFromApiResponse(model: ApiDataDrivenSectionModel) {
    return new OtDataDrivenSection({
      key: model.key,
      title: model.title || null,
      orderIndex: model.orderIndex,
      description: model.description || null,
      questions: model.questions.map(q => OtDataDrivenQuestion.createFromApiResponse(q)),
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenSection>) {
    return new OtDataDrivenSection({
      key: json.key || uuid(),
      title: json.title || null,
      orderIndex: json.orderIndex || 0,
      description: json.description || null,
      questions: json.questions?.map(q => OtDataDrivenQuestion.createFromJson(q)) || [],
    });
  }
}
