























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OtTable from '@/components/global/table/ot-table.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import {
  Calendar,
  OtCalendarStatus,
  parsedApiCalendarEnums,
  parsedOtCalendarEnums,
} from '@/areas/settings/models/calendar-models';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtApi, { executeApi } from '@/services/api.service';
import { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import { CalendarStatus, PostCalendarStatusRequestModel } from '@/services/generated/api';
import { OtStatusType } from '@/types/status-enums';

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
  },
})
export default class OtSettingsCalendarIndex extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private calendars!: Calendar[];

  // * REFS

  // * DATA
  private api = new OtApi();
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;
  private page = 1;
  private searchText = '';

  private bodyData: Calendar[] = [];

  private tableColumns: IExtendedColumnData<Calendar>[] = [
    {
      index: 0,
      label: 'Calendar',
      key: 'calendar',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: Calendar.compareByName,
    },
    {
      index: 2,
      label: 'Status',
      key: 'status',
      isActive: false,
      ascending: false,
      sortable: false,
    },
    {
      index: 2,
      label: '',
      key: 'actionMenu',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];
  private rowLoadingStates: { [key: string]: boolean } = {};

  private statusFilters: CheckboxGroupItem<OtCalendarStatus>[] = [
    new CheckboxGroupItem('Active', OtCalendarStatus.Active),
    new CheckboxGroupItem('Inactive', OtCalendarStatus.Inactive),
  ];
  private selectedFilters: CheckboxGroupItem<OtCalendarStatus>[] = [];

  private activeColumn = this.tableColumns[0];

  // * COMPUTED
  private get sortedBodyData() {
    return sortTableData(this.tableColumns, this.activeColumn, this.calendars);
  }

  private get filteredBodyData() {
    const parsedStatuses = this.selectedFilters.map(s => s.value);
    return this.sortedBodyData.filter(row => {
      return Calendar.filterByStatus(parsedStatuses, row) && Calendar.filterByKeyword(this.searchText, row);
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS
  @Watch('calendars', { deep: true })
  private calendarsChanged() {
    this.setRowLoadingStates();
  }

  // * METHODS
  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private getChangeCalendarStatusText(calendar: Calendar): string {
    switch (calendar.status) {
      case OtCalendarStatus.Active:
        return 'Deactivate';
      case OtCalendarStatus.Inactive:
        return 'Reactivate';
      default:
        return '';
    }
  }

  private async setCalendarStatus(calendar: Calendar) {
    let status: CalendarStatus = 0;
    this.rowLoadingStates[calendar.gid] = true;

    switch (calendar.status) {
      case OtCalendarStatus.Active:
        status = parsedApiCalendarEnums[OtCalendarStatus.Inactive];
        break;
      case OtCalendarStatus.Inactive:
        status = parsedApiCalendarEnums[OtCalendarStatus.Active];
        break;
    }

    const postResponse = await executeApi(
      () =>
        this.api
          .calendar()
          .postCalendarStatus(calendar.gid, undefined, new PostCalendarStatusRequestModel({ calendarStatus: status })),
      `Set Calendar ${calendar.name} Status to ${parsedOtCalendarEnums[status]} (${calendar.gid})`,
    );

    if (postResponse.success) {
      this.$emit('reloadCalendars', () => {
        this.rowLoadingStates[calendar.gid] = false;
      });
    }
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.calendars.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  private getTagStatus(status: OtCalendarStatus): TagStatus {
    return { type: OtStatusType.Calendar, status };
  }

  // * LIFECYCLE
  private created() {
    // This initial dictionary population is required otherwise the
    // value doesn't always bind correctly and the loading could get stuck
    this.setRowLoadingStates();
  }
}
