// Data Driven Option Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2373091397/Data+Driven+Option+Object

import { DataDrivenOptionModel as ApiDataDrivenOptionModel } from '@/services/generated/api';
import { ConsequenceEnum, parsedOtConsequenceEnum } from './data-driven-enums';
import { v4 as uuid } from 'uuid';

export interface IDataDrivenOption {
  key: string;
  title: string;
  orderIndex: number;
  description: string | null;
  consequence: ConsequenceEnum | null;
  actionKey: string | null;
}

export class OtDataDrivenOption implements IDataDrivenOption {
  public key!: string;
  public title!: string;
  public orderIndex!: number;
  public description!: string | null;
  public consequence!: ConsequenceEnum | null;
  public actionKey!: string | null;

  constructor(value: IDataDrivenOption) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenOptionModel) {
    return new OtDataDrivenOption({
      key: model.key,
      title: model.title || '',
      orderIndex: model.orderIndex,
      description: model.description || null,
      consequence: model.consequence ? parsedOtConsequenceEnum[model.consequence] : null,
      actionKey: model.actionKey || null,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenOption>) {
    return new OtDataDrivenOption({
      key: json.key || uuid(),
      title: json.title || '',
      orderIndex: json.orderIndex || 0,
      description: json.description || null,
      consequence: json.consequence || null,
      actionKey: json.actionKey || null,
    });
  }
}
