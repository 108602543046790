import { createModule, mutation } from 'vuex-class-component';

const vuexModule = createModule({
  namespaced: 'app',
  strict: false,
});

export default class AppModule extends vuexModule {
  private _initialHistoryCount = 0;

  // * GETTERS
  public get initialHistoryCount() {
    return this._initialHistoryCount;
  }

  public get appHistoryCount() {
    return this.initialHistoryCount - window.history.length;
  }

  // * MUTATIONS
  @mutation public setInitialHistoryCount(count: number) {
    this._initialHistoryCount = count;
  }
}
