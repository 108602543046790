













































import { IVForm } from '@/utils/type-utils';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { FormModalParams, FormModalSizeEnum } from '../global/modal/form-modal-models';
import OtFormModal from '../global/modal/ot-form-modal.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtFormattableTextarea from '@/components/global/ot-formattable-textarea.vue';
import { isEmail } from '@/utils/validation-utils';
import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';

export class EmailContractorDialogResult {
  public doSendEmail = false;
  public recipientEmails: string[] = [];
  public emailSubject = '';
  public emailContent = '';

  constructor(params?: Partial<EmailContractorDialogResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class EmailContractorDialogParams {
  public recipientEmails: string[] = [];
  public emailSubject: string | null = null;
  public emailContent: string | null = null;
  public readonly = false;
  public isOverride = false;

  public constructor(params?: Partial<EmailContractorDialogParams>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

@Component({
  components: {
    OtTextField,
    OtFormModal,
    OtFormattableTextarea,
    OtFieldArchetype,
  },
})
export default class OtEmailContractorDialog extends Vue {
  // * PROPS

  // * REFS
  @Ref('formModalRef') private readonly formModalRef!: OtFormModal;
  @Ref('modalContentRef') private readonly modalContentRef!: IVForm;

  // * DATA
  private recipientsEmailValue: string | null = null;
  private emailSubject: string | null = null;
  private emailContent: string | null = null;
  private readonly = false;
  private isOverride = false;

  private recipientRules: Array<(value: string | null) => boolean | string> = [
    (value: string | null) => {
      const allEmails = this.getEmailsFromString(value);
      if (!allEmails.length) {
        return `At least 1 email is required`;
      }
      for (const email of allEmails) {
        if (email && !isEmail(email)) {
          return `Invalid Email: ${email}`;
        }
      }

      return true;
    },
  ];

  // * COMPUTED
  private get recipientEmails(): string[] {
    return this.getEmailsFromString(this.recipientsEmailValue);
  }
  private set recipientEmails(val: string[]) {
    this.recipientsEmailValue = `${val.join('; ')}`;
  }

  // * WATCHERS

  // * METHODS
  public async open(params: EmailContractorDialogParams): Promise<EmailContractorDialogResult> {
    this.modalContentRef.resetValidation();
    this.recipientEmails = params.recipientEmails;
    this.emailSubject = params.emailSubject;
    this.emailContent = params.emailContent;
    this.readonly = params.readonly ?? false;
    this.isOverride = params.isOverride ?? false;

    const baseParams = new FormModalParams({
      title: this.readonly ? `Email Details` : `Send Email to Contractor`,
      formRef: this.modalContentRef,
      confirmText: this.readonly ? `Close` : `Send Email`,
      hideCancelButton: this.readonly,
      size: FormModalSizeEnum.Regular,
    });
    const result = await this.formModalRef.open(baseParams);
    if (result.ok) {
      return new EmailContractorDialogResult({
        doSendEmail: true,
        recipientEmails: this.recipientEmails || undefined,
        emailSubject: this.emailSubject || undefined,
        emailContent: this.emailContent || undefined,
      });
    }
    return new EmailContractorDialogResult({
      doSendEmail: false,
    });
  }

  private getEmailsFromString(value: string | null): string[] {
    const emails = value?.split(';').map(e => e.trim()) || [];
    return emails.filter(e => !!e);
  }

  // * LIFECYCLE
}
