// Data Driven Definition Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2373091347/Data+Driven+Definition+Object

import { DataDrivenDefinitionModel as ApiDataDrivenDefinitionModel } from '@/services/generated/api';
import { OtDataDrivenEvalutationRules } from './data-driven-evaluation-rules';
import { OtDataDrivenLayout } from './data-driven-layout';
import { v4 as uuid } from 'uuid';
import { OtDataDrivenInstance } from './data-driven-instance';

export interface IDataDrivenDefinition {
  gid: string;
  versionId: number;
  layouts: OtDataDrivenLayout[];
  finalStep: string | null;
  defaultResponses: OtDataDrivenInstance | null;
  evaluation: OtDataDrivenEvalutationRules | null;
}

export class OtDataDrivenDefinition implements IDataDrivenDefinition {
  public gid!: string;
  public versionId!: number;
  public layouts!: OtDataDrivenLayout[];
  public finalStep!: string | null;
  public defaultResponses!: OtDataDrivenInstance | null;
  public evaluation!: OtDataDrivenEvalutationRules | null;

  constructor(value: IDataDrivenDefinition) {
    Object.assign(this, value);
  }

  public get layoutsOrdered() {
    const layoutsOrdered = [...this.layouts];
    layoutsOrdered.sort((a, b) => a.orderIndex - b.orderIndex);
    return layoutsOrdered;
  }

  public static createFromApiResponse(model: ApiDataDrivenDefinitionModel) {
    return new OtDataDrivenDefinition({
      gid: model.gid,
      versionId: model.versionId,
      layouts: model.layouts.map(l => OtDataDrivenLayout.createFromApiResponse(l)),
      finalStep: model.finalStep || null,
      defaultResponses: model.defaultResponse
        ? OtDataDrivenInstance.createFromApiResponse(model.defaultResponse)
        : null,
      evaluation: model.evaluation ? OtDataDrivenEvalutationRules.createFromApiResponse(model.evaluation) : null,
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenDefinition>) {
    return new OtDataDrivenDefinition({
      gid: model.gid || uuid(),
      versionId: model.versionId || 0,
      layouts: model.layouts?.map(l => OtDataDrivenLayout.createFromJson(l)) || [],
      finalStep: model.finalStep || null,
      evaluation: model.evaluation ? OtDataDrivenEvalutationRules.createFromJson(model.evaluation) : null,
      defaultResponses: model.defaultResponses ? OtDataDrivenInstance.createFromJson(model.defaultResponses) : null,
    });
  }
}
