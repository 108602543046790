import { DataDrivenModifiedModel as ApiDataDrivenModifiedModel } from '@/services/generated/api';

export interface IDataDrivenModified {
  utc: Date;
  byGid: string;
  byFirstName: string;
  byLastName: string;
}

export class OtDataDrivenModified implements IDataDrivenModified {
  public utc!: Date;
  public byGid!: string;
  public byFirstName!: string;
  public byLastName!: string;

  constructor(value: IDataDrivenModified) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new OtDataDrivenModified({
      utc: new Date(),
      byGid: '',
      byFirstName: '',
      byLastName: '',
    });
  }

  public static createFromApiResponse(model: ApiDataDrivenModifiedModel) {
    return new OtDataDrivenModified({ ...model });
  }

  public static createFromJson(model: Partial<OtDataDrivenModified>) {
    return new OtDataDrivenModified({
      utc: model.utc || new Date(),
      byGid: model.byGid || '',
      byFirstName: model.byFirstName || '',
      byLastName: model.byLastName || '',
    });
  }

  public static compareByDate(left: OtDataDrivenModified | null, right: OtDataDrivenModified | null) {
    if (left && right) {
      return left.utc.valueOf() - right.utc.valueOf();
    }
    if (!left && !right) {
      return 0;
    }
    if (left && !right) {
      return -1;
    }
    if (!left && right) {
      return 1;
    }
    // the compiler seems to think that there is a chance we'll return undefined without this line
    // it's pretty simple boolean cases, we've covered all of them... whatevs. If we break boolean logic, I guess these things are the same
    return 0;
  }
}
