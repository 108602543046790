














































































































import { Contract, parsedApiContractEnums } from '@/areas/projects/contracts/contract-models';
import OtCheckboxGroup, { CheckboxGroupItem } from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import { FormModalParams } from '@/components/global/modal/form-modal-models';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';
import OtTag, { TagStatus } from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import OtTrafficLights from '@/components/global/ot-traffic-lights.vue';
import { SnackbarItem, SnackbarTypeEnum } from '@/components/global/snackbar/snackbar-models';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import { IColumnData, IExtendedColumnData } from '@/components/global/table/ot-table-models';
import { getPaginatedBodyData, handleHeaderClick, sortTableData } from '@/components/global/table/ot-table-utils';
import OtTable from '@/components/global/table/ot-table.vue';
import OtApi, { executeApi, ExecuteApiValue, IApiErrorOverride } from '@/services/api.service';
import {
  PostCopyContractRequestModel,
  PostSaveContractAsContractTemplateRequestModel,
  PostUpdateContractStatusRequestModel,
} from '@/services/generated/api';
import { vxm } from '@/store';
import { OtContractStatus, OtProjectStatus, OtStatusType, OtUserStatus } from '@/types/status-enums';
import { formatDate } from '@/utils/date-utils';
import { capitalizeString } from '@/utils/string-utils';
import { IVForm } from '@/utils/type-utils';
import { v4 as uuid } from 'uuid';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { ProjectDetails } from '../project-models';
import { ROUTE_CONTRACT_ADD, ROUTE_CONTRACT_DEFAULT } from './contract-routes';

interface IMenuOption {
  key: string;
  label: string;
  onClick: (contract: Contract) => void;
}

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtButton,
    OtTag,
    OtTrafficLights,
    OtTextField,
    OtTextarea,
  },
})
export default class OtProjectContractsIndex extends Vue {
  // * PROPS
  @Prop({ default: () => [] }) private contracts!: Contract[];
  @Prop() private project!: ProjectDetails;

  // * REFS
  @Ref('formModalRef') private formModalRef!: IVForm;

  // * DATA
  private api = new OtApi();
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;
  private page = 1;
  private searchText = '';

  private templateName = '';
  private templateDescription = '';

  private bodyData: Contract[] = [];

  private tableColumns: IExtendedColumnData<Contract>[] = [
    {
      index: 0,
      label: 'Ref',
      key: 'reference',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByReference,
    },
    {
      index: 1,
      label: 'Contract',
      key: 'name',
      isActive: true,
      ascending: true,
      sortable: true,
      sortFunction: Contract.compareByName,
    },
    {
      index: 2,
      label: 'Type',
      key: 'typeName',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByType,
    },
    {
      index: 3,
      label: 'Contract Date',
      key: 'startDate',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByDate,
    },
    {
      index: 4,
      label: 'PC Date',
      key: 'effectivePracticalCompletionDate',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByEffectivePracticalCompletionDate,
    },
    {
      index: 5,
      label: 'Contract Health',
      key: 'trafficLights',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByTrafficLights,
    },
    {
      index: 6,
      label: 'Status',
      key: 'status',
      isActive: false,
      ascending: false,
      sortable: true,
      sortFunction: Contract.compareByStatus,
    },
    {
      index: 7,
      label: '',
      key: 'actionMenu',
      isActive: false,
      ascending: false,
      sortable: false,
    },
  ];
  private rowLoadingStates: { [key: string]: boolean } = {};

  private statusFilters: CheckboxGroupItem<OtContractStatus>[] = [
    new CheckboxGroupItem('Active', OtContractStatus.Active),
    new CheckboxGroupItem('Pending', OtContractStatus.Pending),
    new CheckboxGroupItem('Draft', OtContractStatus.Draft),
    new CheckboxGroupItem('Cancelled', OtContractStatus.Cancelled),
  ];

  private selectedFilters: CheckboxGroupItem<OtContractStatus>[] = [];

  private activeColumn = this.tableColumns[0];

  // * COMPUTED
  private get userCanCreateContract() {
    return Boolean(vxm.userProfile.userProfile?.canCreateContract);
  }

  private get sortedBodyData() {
    return sortTableData(this.tableColumns, this.activeColumn, this.contracts);
  }

  private get filteredBodyData() {
    let parsedStatuses = this.selectedFilters.map(s => s.value);
    if (!this.userCanCreateContract) {
      // doesn't matter what is selected, if the user cannot create a contract, they cannot see draft contracts
      parsedStatuses = parsedStatuses.filter(x => x !== OtContractStatus.Draft);
      if (!parsedStatuses.length) {
        // empty array means we will show everything. We don't want this
        // we don't want Draft shown, so go to the source of the filters and get everything not draft
        parsedStatuses = this.statusFilters.filter(x => x.value !== OtContractStatus.Draft).map(x => x.value);
      }
    }
    return this.sortedBodyData.filter(row => {
      return Contract.filterByStatus(parsedStatuses, row) && Contract.filterByKeyword(this.searchText, row);
    });
  }

  private get paginatedBodyData() {
    return getPaginatedBodyData(this.filteredBodyData);
  }

  private get currentBodyData() {
    return this.paginatedBodyData[this.page - 1];
  }

  private get pageCount() {
    let currentMaxPage = this.paginatedBodyData.length;
    if (this.page > currentMaxPage && currentMaxPage >= 1) {
      currentMaxPage = this.paginatedBodyData.length - 1;
      this.page = currentMaxPage;
    }
    return currentMaxPage;
  }

  // * WATCHERS
  @Watch('contracts', { deep: true })
  private contractsChanged() {
    this.setRowLoadingStates();
  }

  // * METHODS

  private getTagStatus(status: OtContractStatus): TagStatus {
    return { type: OtStatusType.Contract, status };
  }

  private isUnfinishedContract(contract: Contract) {
    return contract.status === OtContractStatus.Draft;
  }

  private updateCurrentPage(value: number) {
    this.page = value;
  }

  private headerClick(header: IColumnData) {
    const handledHeaders = handleHeaderClick(this.tableColumns, this.activeColumn, header);
    this.tableColumns = handledHeaders.tableColumns;
    this.activeColumn = handledHeaders.activeColumn;
  }

  private setContractToActive(contract: Contract) {
    if (contract.status === OtContractStatus.Pending) {
      this.setContractStatus(contract, OtContractStatus.Active);
    }
  }

  private setContractToDraft(contract: Contract) {
    if (contract.status === OtContractStatus.Cancelled) {
      this.setContractStatus(contract, OtContractStatus.Draft);
    }
  }

  // NOTE this is not required in the ENG - but leaving here for confirmation
  // https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2585362433/V07105R02+-+Project+Contracts+List
  private async copyContract(contract: Contract) {
    this.rowLoadingStates[contract.gid] = true;

    const errorOverride: IApiErrorOverride = {
      status: 422,
      message: `Unable copy contract ${contract.reference}, ${ExecuteApiValue.ValidationError}`,
    };

    const postResponse = await executeApi(
      () => this.api.contracts().postCopyContract(contract.gid, undefined, new PostCopyContractRequestModel()),
      `Copy Contract ${contract.reference}`,
      errorOverride,
    );

    if (postResponse.success) {
      this.$emit('reloadContracts', () => {
        this.rowLoadingStates[contract.gid] = false;
      });

      // nav to the newly created contract
      if (postResponse.data?.gid) {
        const destination: RawLocation = {
          name: ROUTE_CONTRACT_ADD,
          params: { projectGid: contract.projectGid },
          query: { contractGid: postResponse.data.gid },
        };
        this.$router.push(destination);
      } else {
        console.error(
          'ot-project-contracts-index -> copyContract -> Unable to find contract gid after cloning. postResponse:  ',
          postResponse,
        );
        const snackbarError = new SnackbarItem({
          type: SnackbarTypeEnum.Error,
          message: `There was a problem copying contract ${contract.reference}, please refresh your browser and look for the copy in the list.`,
        });
        vxm.snackbar.addToSnackbarQueue(snackbarError);
      }
    } else {
      this.rowLoadingStates[contract.gid] = false;
    }
  }

  private async setContractStatus(contract: Contract, status: OtContractStatus) {
    this.rowLoadingStates[contract.gid] = true;
    const errorOverride: IApiErrorOverride[] = [
      {
        status: 422,
        message: `Unable to change the status of contract ${contract.reference} to ${capitalizeString(
          status.toString().toLowerCase(),
        )}, ${ExecuteApiValue.ValidationError}`,
      },
      {
        status: 409,
        message: `Unable to change the status of contract ${contract.reference} to ${capitalizeString(
          status.toString().toLowerCase(),
        )} - contract has been modified by someone else. Please refresh the page and try again.`,
      },
    ];

    const postResponse = await executeApi(
      () =>
        this.api.contracts().postUpdateContractStatus(
          contract.gid,
          new PostUpdateContractStatusRequestModel({
            contractStatus: parsedApiContractEnums[status],
            rowVersion: contract.rowVersion,
          }),
        ),
      `Set Contract ${contract.gid} Status to ${status}`,
      errorOverride,
    );

    if (postResponse.success) {
      // can drop this rowVersion on the floor - we'll reload the contracts which will get the latest row version
      this.$emit('reloadContracts', () => {
        this.rowLoadingStates[contract.gid] = false;
      });
    } else {
      this.rowLoadingStates[contract.gid] = false;
    }
  }

  private setRowLoadingStates() {
    this.rowLoadingStates = this.contracts.reduce((a, x) => ({ ...a, [x.gid]: false }), {});
  }

  private formatDate(date: Date | null): string {
    if (date) {
      return formatDate(date);
    }
    return '';
  }

  private getContractRoute(contract: Contract): RawLocation {
    if (contract.status === OtContractStatus.Draft) {
      return {
        name: ROUTE_CONTRACT_ADD,
        params: { projectGid: contract.projectGid },
        query: { contractGid: contract.gid },
      };
    } else {
      return { name: ROUTE_CONTRACT_DEFAULT, params: { projectGid: contract.projectGid, contractGid: contract.gid } };
    }
  }

  private isContractCancelled(contract: Contract) {
    return contract.status === OtContractStatus.Cancelled;
  }

  private getContractOptionMenuItems(contract: Contract): IMenuOption[] {
    const parentProjectStatus = this.project.status;
    const isParentProjectActive = parentProjectStatus === OtProjectStatus.Active;
    const isParentProjectPending = parentProjectStatus === OtProjectStatus.Pending;
    const isContractOrgUserStatusActive = contract?.organisationUser?.status === OtUserStatus.Active;

    if (contract.status === OtContractStatus.Pending) {
      if (isParentProjectActive && this.userCanCreateContract) {
        return [
          {
            key: uuid(),
            label: 'Set to active',
            onClick: this.setContractToActive,
          },
        ];
      } else {
        return [];
      }
    } else if (contract.status === OtContractStatus.Cancelled) {
      if ((isParentProjectActive || isParentProjectPending) && this.userCanCreateContract) {
        return [
          {
            key: uuid(),
            label: 'Set to draft',
            onClick: this.setContractToDraft,
          },
        ];
      } else {
        return [];
      }
    } else if (contract.status === OtContractStatus.Active) {
      // can only save as a template if you're an active ORG user. Don't care about the project or contract, just org
      if (isContractOrgUserStatusActive && this.userCanCreateContract) {
        return [
          {
            key: uuid(),
            label: 'Save as template',
            onClick: this.saveContractAsTemplate,
          },
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  private saveContractAsTemplate(contract: Contract) {
    if (contract.status === OtContractStatus.Active) {
      this.openCreateTemplateModal(contract);
    }
  }

  private async openCreateTemplateModal(contract: Contract) {
    const modalParams = new FormModalParams({
      title: 'Save Contract as Template',
      formRef: this.formModalRef,
      cancelText: 'Cancel',
      confirmText: 'Save Template',
      bodyContainerCss: {
        overflow: 'visible',
      },
      onBeforeConfirmClose: async () => {
        const errorOverride: IApiErrorOverride = {
          status: 422,
          message: `Unable to create contract template: ${ExecuteApiValue.ValidationError}`,
        };

        const postResponse = await executeApi(
          () =>
            this.api.contractTypes().postSaveContractAsContractTemplate(
              undefined,
              new PostSaveContractAsContractTemplateRequestModel({
                name: this.templateName,
                sourceContractGid: contract.gid,
                description: this.templateDescription || undefined,
              }),
            ),
          `Creating contract template`,
          errorOverride,
        );

        return postResponse.success;
      },
    });
    const result = await vxm.modal.openFormModal(modalParams);
    if (result.ok) {
      // Eng doesn't say to go or do anything here, so we won't
      this.formModalRef.reset();
    }
  }

  // * LIFECYCLE
  private created() {
    // This initial dictionary population is required otherwise the
    // value doesn't always bind correctly and the loading could get stuck
    this.setRowLoadingStates();
  }
}
