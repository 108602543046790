// Data Driven Sub Option Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2427551909/Data+Driven+Sub+Option+Object

import { DataDrivenSubOptionModel as ApiDataDrivenSubOptionModel } from '@/services/generated/api';

export interface IDataDrivenSubOption {
  key: string;
  title: string;
}

export class OtDataDrivenSubOption implements IDataDrivenSubOption {
  public key!: string;
  public title!: string;

  constructor(value: IDataDrivenSubOption) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenSubOptionModel) {
    return new OtDataDrivenSubOption({
      key: model.key,
      title: model.title,
    });
  }

  public static createFromJson(json: Partial<OtDataDrivenSubOption>) {
    return new OtDataDrivenSubOption({
      key: json.key || '',
      title: json.title || '',
    });
  }
}
