







































































import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtDurationSelection, { IDurationSelectionObject } from '@/components/global/ot-duration-selection.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { ZonelessDate } from '@/types/zoneless-date';
import { IValidate } from '@/utils/type-utils';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenDaysOrDate } from '../models/data-driven-result';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';

const dateOption: IRadioGroupOption = {
  label: 'Date',
  key: 'Date',
};
const daysOption: IRadioGroupOption = {
  label: 'Period',
  key: 'Days',
};

@Component({
  components: {
    OtRadioGroup,
    OtTextarea,
    OtFieldArchetype,
    OtDurationSelection,
    OtDatePicker,
  },
})
export default class WfDaysOrDateEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public formContainerElement?: HTMLElement;

  // * REFS
  @Ref('checkboxRef') private checkboxRef!: IValidate;
  @Ref('daysInputRef') private daysInputRef!: IValidate;
  @Ref('dateInputRef') private dateInputRef!: IValidate;

  // * DATA
  private title: string | null = null;
  private description: string | null = null;
  private showErrorMessage = false;
  private isRequired = false;

  private checkboxRules: Array<(value: boolean) => boolean | string> = [
    (value: boolean) => value === true || 'Required',
  ];

  // yes, these have to be getters. Dan does not know why right now, it is 7pm and this fixes the render issue about findIndex and undefined
  private get dateOptions(): IRadioGroupOption[] {
    return [dateOption];
  }
  private get daysOptions(): IRadioGroupOption[] {
    return [daysOption];
  }

  // * COMPUTED

  private dateValuePrivate: IRadioGroupOption | null = null;
  private get dateValue() {
    return this.dateValuePrivate;
  }
  private set dateValue(val: IRadioGroupOption | null) {
    this.dateValuePrivate = val;
    if (val?.key === dateOption.key) {
      // make sure the other radio has nothing selected
      this.daysValuePrivate = null;
      // they've said "I'd like to pick a date", so we need to clear out the days model
      this.daysInputValuePrivate = null;
    }
    this.onChange();
  }

  private daysValuePrivate: IRadioGroupOption | null = null;
  private get daysValue() {
    return this.daysValuePrivate;
  }
  private set daysValue(val: IRadioGroupOption | null) {
    this.daysValuePrivate = val;
    if (val?.key === daysOption.key) {
      this.dateValuePrivate = null;
      this.dateInputValue = null;
    }
    this.onChange();
  }

  private daysInputValuePrivate: IDurationSelectionObject | null = null;
  private get daysInputValue() {
    return this.daysInputValuePrivate;
  }
  private set daysInputValue(val: IDurationSelectionObject | null) {
    this.daysInputValuePrivate = val;
    this.onChange();
  }

  private dateInputValuePrivate: ZonelessDate | null = null;
  private get dateInputValue() {
    return this.dateInputValuePrivate;
  }
  private set dateInputValue(val: ZonelessDate | null) {
    this.dateInputValuePrivate = val;
    this.onChange();
  }

  private get isDateSelected() {
    return this.dateValuePrivate?.key === dateOption.key;
  }
  private get isDaysSelected() {
    return this.daysValuePrivate?.key === daysOption.key;
  }

  private selectedValuePrivate: IRadioGroupOption | null = null;
  private get selectedValue(): IRadioGroupOption | null {
    return this.selectedValuePrivate;
  }
  private set selectedValue(val: IRadioGroupOption | null) {
    this.selectedValuePrivate = val;
    this.onChange();
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'DaysOrDateModel') {
        return this.value.result;
      }
      console.warn(
        'wf-days-or-date-edit -> result -> ResultType is incorrect. Expected DaysOrDateModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  private get controlIsValid() {
    if (!this.isRequired) {
      // not mandatory, don't care
      return true;
    }
    // mandatory, must select one of the radios
    return this.isDateSelected || this.isDaysSelected;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    console.log('wf-days-or-date-edit -> valueChanged ');

    // need to be a bit careful here
    // value changed cal fire even if we have no date or days
    // so, we won't touch the radios at all if we don't have one of them

    if (this.result?.date) {
      // it is a date selection
      // set it, and unset the days
      this.dateValuePrivate = dateOption;
      this.dateInputValuePrivate = new ZonelessDate(this.result.date);
      this.daysValuePrivate = null;
      this.daysInputValuePrivate = null;
    } else if (this.result?.calendarType) {
      // it is a days selection
      // set it, and unset the date
      this.daysValuePrivate = daysOption;
      this.daysInputValuePrivate = { duration: this.result.number, selectedCalendar: this.result.calendarType };
      this.dateValuePrivate = null;
      this.dateInputValuePrivate = null;
    } else {
      // not a date or days, reset everything entered on screen
      // but leave the radios alone, they might not have actually filled a value in yet
      this.daysInputValuePrivate = null;
      this.dateInputValuePrivate = null;
    }

    // after all of that, reset the validation so everything thinks validation has not run
    // maybe. Commenting this out for now
    // this.checkboxRef.resetValidation();
    // this.dateInputRef.resetValidation();
    // this.daysInputRef.resetValidation();
  }

  // * METHODS
  public onChange() {
    const result = new OtDataDrivenDaysOrDate({
      resultType: 'DaysOrDateModel',
      calendarType: this.daysInputValuePrivate?.selectedCalendar || null,
      date: this.dateInputValuePrivate ? new ZonelessDate(this.dateInputValuePrivate) : null,
      number: this.daysInputValuePrivate ? this.daysInputValuePrivate.duration : null,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;
    this.description = this.question.description;
    this.isRequired = this.question.isMandatory;

    this.valueChanged();
  }

  private mounted() {
    this.$watch('checkboxRef.validationState', (state: string) => {
      this.showErrorMessage = state === 'error';
    });
  }
}
