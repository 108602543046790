var render, staticRenderFns
import script from "./ot-workflow-engine-processor.vue?vue&type=script&lang=ts&"
export * from "./ot-workflow-engine-processor.vue?vue&type=script&lang=ts&"
import style0 from "./ot-workflow-engine-processor.vue?vue&type=style&index=0&id=628c0989&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628c0989",
  null
  
)

export default component.exports