
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CreateElement } from 'vue';
import { VNode } from 'vue/types/umd';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import OtWorkflowEngineRenderer from '@/components/workflow-engine/ot-workflow-engine-renderer.vue';
import _ from 'lodash';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '@/wf-components/models/data-driven-response';
import OtWorkflowConfirmDialog from '@/components/workflow-engine/ot-workflow-confirm-dialog.vue';
import { DangerModalParams, FormModalResult } from '../global/modal/form-modal-models';
import { IWorkflowConfirmDialog } from '@/wf-components/models/workflow-component-definition';
import { LayoutTypeEnum } from '@/wf-components/models/data-driven-enums';
import { IDataDrivenLayout } from '@/wf-components/models/data-driven-layout';
import {
  IDataDrivenModelWithKey,
  IDataDrivenModelWithValues,
  OtDataDrivenOptionsList,
} from '@/wf-components/models/data-driven-result';
import OtDangerModal from '@/components/global/modal/ot-danger-modal.vue';
import { joinArray } from '@/utils/string-utils';
import { sanitizer } from '@/utils/xss-utils';
import { OtDataDrivenOption } from '@/wf-components/models/data-driven-option';
import { OtClientEvaluation } from '@/wf-components/models/client-evaluation';

interface IPromiseResponseObject {
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  reject: (reason?: unknown) => void;
}

export interface IExecuteDialogCommandEmitobject {
  command: string;
  promise: IPromiseResponseObject;
}

export interface IConfirmValueChangeEmitobject {
  promise: IPromiseResponseObject;
  items: string[];
}

@Component({
  components: {
    OtWorkflowEngineRenderer,
  },
})
export default class OtWorkflowEngineProcessor extends Vue {
  // * PROPS
  @Prop() definition!: OtDataDrivenDefinition;
  @Prop() layoutKey!: string | null;
  @Prop() instance!: OtDataDrivenInstance;
  @Prop() evaluation!: OtClientEvaluation;
  @Prop() parentInstances?: OtDataDrivenInstances;
  @Prop() instances?: OtDataDrivenInstances;
  @Prop({ default: false }) displayMode!: boolean;

  // * REFS

  // * DATA
  private originalDialogValues: { [layoutKey: string]: OtDataDrivenResponse[] } = {};
  private confirmChangesDialogRef = 'confirmChangesDialogRef';

  // * COMPUTED
  private get defaultValuesGroupedByLayout(): IResponsesGroupedByLayoutKey[] {
    if (this.definition.defaultResponses) {
      return this.groupResponsesByLayout(this.definition.defaultResponses.responses);
    }
    return [];
  }

  public get allPageLayoutKeys() {
    return this.definition.layoutsOrdered.filter(l => l.type === LayoutTypeEnum.Page).map(l => l.key);
  }

  // * WATCHERS

  // * METHODS
  private createDialogThumbprints() {
    const dialogLayoutKeys = this.getDialogLayoutKeysForSelectedLayout();
    for (const layoutKey of dialogLayoutKeys) {
      this.originalDialogValues[layoutKey] = this.getResponsesForLayout(layoutKey);
    }
  }

  private getResponsesForLayout(layoutKey: string): OtDataDrivenResponse[] {
    const foundLayout = this.definition.layouts.find(l => l.key === layoutKey) || null;
    if (foundLayout) {
      const allLayoutQuestionKeys = foundLayout.sections.flatMap(s => s.questions.map(q => q.key));
      const foundLayoutResponses = this.instance.responses.filter(r => allLayoutQuestionKeys.includes(r.questionKey));
      return foundLayoutResponses;
    }
    return [];
  }

  private getDialogLayoutKeysForSelectedLayout(): string[] {
    if (this.layoutKey) {
      const selectedLayout = this.definition.layouts.find(l => l.key === this.layoutKey) || null;
      if (selectedLayout) {
        const actionKeys = selectedLayout.sections.flatMap(s =>
          s.questions.flatMap(q => q.options?.filter(o => o.actionKey !== null).map(o => o.actionKey as string) || []),
        );
        return actionKeys;
      }
    }
    return [];
  }

  private getAllConfirmDialogLayouts(): IDataDrivenLayout[] {
    return this.definition.layouts.filter(l => l.type === LayoutTypeEnum.ConfirmDialog);
  }

  private getLayoutRef(layoutKey: string): string {
    return `${layoutKey}-Ref`;
  }

  private async executeOnConfirmDialogCommand(params: IExecuteDialogCommandEmitobject) {
    const commandDialogRef = this.$refs[this.getLayoutRef(params.command)] as unknown as IWorkflowConfirmDialog;
    if (commandDialogRef) {
      const result = await commandDialogRef.open();
      params.promise.resolve(result.ok);
    } else {
      params.promise.reject(`Could not find ${params.command} ref`);
    }
  }

  private async openActionModal(params: {
    layoutKey: string;
    resolve: (value: FormModalResult | PromiseLike<FormModalResult | null> | null) => void;
  }) {
    const layoutRef = this.$refs[this.getLayoutRef(params.layoutKey)] as OtWorkflowEngineRenderer;
    layoutRef.resetValidation();
    const result = await layoutRef.openModal();
    params.resolve(result);
  }

  private resetResponsesForDialogLayout(layout: string, responsesToResetTo?: OtDataDrivenResponse[]) {
    const foundLayout = this.definition.layouts.find(l => l.key === layout);
    if (foundLayout) {
      // Delete all current responses for layout
      const layoutQuestionKeys = foundLayout.sections.flatMap(s => s.questions.map(q => q.key));
      this.instance.responses = this.instance.responses.filter(r => !layoutQuestionKeys.includes(r.questionKey));

      // Add responses back in if they're defined
      if (responsesToResetTo?.length) {
        this.instance.responses = this.instance.responses.concat(responsesToResetTo);
      }
    }

    this.$emit('update:instance', this.instance);
    this.$nextTick(() => {
      (this.$refs[this.getLayoutRef(layout)] as OtWorkflowEngineRenderer).resetValidation();
    });
  }

  private onChange(instance: OtDataDrivenInstance) {
    this.$emit('update:instance', instance);
  }

  public setFormToCleanState() {
    if (this.layoutKey) {
      const allLayoutKeys: string[] = this.getDialogLayoutKeysForSelectedLayout();
      allLayoutKeys.push(this.layoutKey);

      for (const layoutKey of allLayoutKeys) {
        (this.$refs[this.getLayoutRef(layoutKey)] as OtWorkflowEngineRenderer).setFormToCleanState();
      }
    }
  }

  public validate(): boolean {
    if (this.layoutKey) {
      // Validate main screen
      const layoutRef = this.$refs[this.getLayoutRef(this.layoutKey)] as OtWorkflowEngineRenderer;
      const mainScreenIsValid = layoutRef.validate();

      // Validate selected option dialogs
      // this gives us all the options that have an Action Key, from all the questions in all the sections in all the layouts
      const optionKeysWithActionKeys =
        this.definition.layouts
          .find(l => l.key === this.layoutKey)
          ?.sections.flatMap(s =>
            s.questions
              .filter(q => Boolean(q.options?.length))
              .flatMap(q =>
                (q.options as OtDataDrivenOption[])
                  .filter(o => Boolean(o.actionKey))
                  .map(o => ({ questionKey: q.key, key: o.key, actionKey: o.actionKey as string })),
              ),
          ) || [];

      // get a set of the question keys whos response we actually care about
      // option keys are not guaranteed to be unique. It is recommended they are
      // but for questions who source their options from other questions, they are not
      // The absolute head scratcher of a bug that we encoutered with this was
      // EventReason is the one with the ActionKeys on the options - it has ChangeOfLegislation among others in it
      // EventsAfterPC sources its options from EventReason - it can also have ChangeOfLegislation in it
      // We were finding EventsAfterPC having ChangeOfLegislationOption in it, so we were putting two and two togehter, coming up with five, validating the Change of Leglsiation dialog, and bailing
      const questionKeysThatHaveOptionsListsWithActionKeys = new Set(optionKeysWithActionKeys.map(x => x.questionKey));

      // this gets us all the responses that have an options list as the result type
      // AND they are a response to the questions we care about
      // (questions we care about being the ones that have an option that has an Action Key)
      // We don't care about every list. Only the lists with action keys in them, which launch dialogs
      const filteredResponses = this.instance.responses.filter(
        r =>
          r.result instanceof OtDataDrivenOptionsList &&
          questionKeysThatHaveOptionsListsWithActionKeys.has(r.questionKey),
      );

      // this gets us all the options from all the responses
      const filteredResponseValues = filteredResponses.flatMap(r =>
        (r.result as OtDataDrivenOptionsList).values.map(v => ({ questionKey: r.questionKey, key: v.key })),
      );

      // this matches the response up to the option with the action key on it
      // the question key comparison here is important, without it there's a risk we could
      // source options in a different question that happen to have the same key
      // option keys are not guaranteed to be unique. They have to be if you want to dependsOn to them
      // but other than that, you can have key Foo and key Bar in many different lists
      const filteredResponseValuesMatchedToOptionActionKey = filteredResponseValues
        .map(v => ({
          questionKey: v.questionKey,
          value: v,
          option: optionKeysWithActionKeys.find(o => o.key === v.key && o.questionKey === v.questionKey),
        }))
        .filter(x => x.option);

      // I don't know why this map happens. I guess so the value can be dropped on the floor?
      // Its done its job. I'm leaving this here
      const optionsThatExistInResponses = filteredResponseValuesMatchedToOptionActionKey.map(
        v => v.option as { questionKey: string; key: string; actionKey: string },
      );

      // holding spot for dialogs that have problems
      const dialogsThatFailValidation: { questionKey: string; key: string; actionKey: string }[] = [];

      for (const option of optionsThatExistInResponses) {
        const isValid = (this.$refs[this.getLayoutRef(option.actionKey)] as OtWorkflowEngineRenderer).validate();
        if (!isValid) {
          dialogsThatFailValidation.push(option);
        }
      }

      const optionsDictionaryGroupedByQuestionKey = _.groupBy(dialogsThatFailValidation, i => i.questionKey);
      layoutRef.setCheckboxWithActionErrors(optionsDictionaryGroupedByQuestionKey);

      return mainScreenIsValid && !dialogsThatFailValidation.length;
    }

    return false;
  }

  public async submit(validate = false): Promise<OtDataDrivenInstance | null> {
    if (!validate || this.validate()) {
      if (this.layoutKey) {
        if (this.layoutKey === this.allPageLayoutKeys[this.allPageLayoutKeys.length - 1]) {
          const proceedToSubmit = await this.onBeforeFinalSubmit();
          if (!proceedToSubmit) {
            return null;
          }
        }
        this.setFormToCleanState();
        const layoutRef = this.$refs[this.getLayoutRef(this.layoutKey)] as OtWorkflowEngineRenderer;
        return layoutRef.submit();
      }
    }
    return null;
  }

  private async onBeforeFinalSubmit(): Promise<boolean> {
    if (this.definition.finalStep) {
      const foundLayout = this.definition.layoutsOrdered.find(l => l.key === this.definition.finalStep);
      if (foundLayout) {
        if (!foundLayout.dependsOn) {
          // we're supposed to run a final step, but it has no dependsOn, just run it and return what it says I guess?
          const dialogRef = this.$refs[this.getLayoutRef(this.definition.finalStep)] as OtWorkflowConfirmDialog;
          const dialogResponse = await dialogRef.open();
          return dialogResponse.ok;
        }

        // need to check the dependsOn for the layout first before popping it
        const singleOptionResponses = this.instance.responses.filter(r => !!(r.result as IDataDrivenModelWithKey).key);
        const multiOptionResponses = this.instance.responses.filter(
          r => !!(r.result as IDataDrivenModelWithValues).values,
        );

        const singleOptionsResponseKeys = singleOptionResponses.map(r => (r.result as IDataDrivenModelWithKey).key);
        const multiOptionsResponseKeys = multiOptionResponses.flatMap(r =>
          (r.result as IDataDrivenModelWithValues).values.map(v => v.key),
        );

        // TODO dependsOn work evaluation and config into this?
        // Maybe. One day. We'd need to do this if we have a final step, which we never do
        // nor are there plans for one
        // so... no, not yet
        const selectedKeys = singleOptionsResponseKeys.concat(multiOptionsResponseKeys);

        // outer array is OR'd together
        // inner array, all keys have to be present (AND check)
        // because outer array is an OR, we can loop and short circuit
        for (const dependsOnOuter of foundLayout.dependsOn.options) {
          // make sure all of the keys in the inner array are present in the keys to be checked
          if (dependsOnOuter.every(o => selectedKeys.includes(o))) {
            // we have a match. Short circuit
            const dialogRef = this.$refs[this.getLayoutRef(this.definition.finalStep)] as OtWorkflowConfirmDialog;
            const dialogResponse = await dialogRef.open();
            return dialogResponse.ok;
          }
        }
      }
    }
    return true;
  }

  private async openConfirmValueChangeDialog(params: IConfirmValueChangeEmitobject) {
    const ref = this.$refs[this.confirmChangesDialogRef] as OtDangerModal;
    const sanitiizedItemArray: string[] = [];
    for (const item of params.items) {
      sanitiizedItemArray.push(sanitizer.process(item));
    }

    const dialogParams = new DangerModalParams({
      confirmText: 'Continue',
      title: 'Are you sure you want to deselect this option?',
      message: `Your changes to ${joinArray(sanitiizedItemArray, { boldItems: true })} will be lost if you continue`,
      useHtmlInMessage: true,
    });
    const response = await ref.open(dialogParams);
    params.promise.resolve(response.ok);
  }

  private setDefaultResponsesForLayout(layout: string) {
    const defaultResponses = this.defaultValuesGroupedByLayout.find(l => l.layoutKey === layout);
    if (defaultResponses) {
      const groupedResponses = this.groupResponsesByLayout(this.instance.responses);
      // Only add in the default responses for this layout if no other responses for this layout currently exist
      if (!groupedResponses.find(l => l.layoutKey === layout)?.responses.length) {
        this.instance.responses = this.instance.responses.concat(defaultResponses.responses);

        const pageLayoutRef = this.$refs[this.getLayoutRef(layout)] as OtWorkflowEngineRenderer;
        if (pageLayoutRef) {
          pageLayoutRef.processDependsOnComponents();
        }
      }
    }
    this.$emit('update:instance', this.instance);
  }

  private groupResponsesByLayout(responses: OtDataDrivenResponse[]) {
    const groupedResponses: IResponsesGroupedByLayoutKey[] = [];

    for (const layout of this.definition.layoutsOrdered) {
      const allQuestionKeysInLayout = layout.sectionsOrdered.flatMap(s => s.questionsOrdered.map(q => q.key));
      const foundResponses = responses.filter(r => allQuestionKeysInLayout.includes(r.questionKey)) || [];
      groupedResponses.push({ layoutKey: layout.key, responses: foundResponses });
    }

    return groupedResponses;
  }

  // * LIFECYCLE
  private created() {
    this.createDialogThumbprints();
    if (this.layoutKey) {
      this.setDefaultResponsesForLayout(this.layoutKey);
    }
  }

  private render(createElement: CreateElement): VNode | undefined {
    if (this.layoutKey) {
      const children: VNode[] = [];

      // ---- Add data driven dialogs ---- //
      const allLayoutKeysToRender: string[] = this.getDialogLayoutKeysForSelectedLayout();
      allLayoutKeysToRender.push(this.layoutKey);

      for (const layoutKey of allLayoutKeysToRender) {
        children.push(
          createElement(
            OtWorkflowEngineRenderer,
            {
              props: {
                definition: this.definition,
                instance: this.instance,
                evaluation: this.evaluation,
                parentInstances: this.parentInstances,
                instances: this.instances,
                layoutKey: layoutKey,
                displayMode: this.displayMode,
                defaultValuesGroupedByLayout: this.defaultValuesGroupedByLayout,
              },
              ref: this.getLayoutRef(layoutKey),
              on: {
                openActionModal: this.openActionModal,
                'update:instance': this.onChange,
                executeOnConfirmDialogCommand: this.executeOnConfirmDialogCommand,
                resetDialogResponses: this.resetResponsesForDialogLayout,
                setDialogDefaults: this.setDefaultResponsesForLayout,
                openConfirmValueChangeDialog: this.openConfirmValueChangeDialog,
              },
            },
            undefined,
          ),
        );
      }

      // ---- Add override command dialogs ---- //
      const confirmDialogLayouts = this.getAllConfirmDialogLayouts();
      for (const layout of confirmDialogLayouts) {
        children.push(
          createElement(
            OtWorkflowConfirmDialog,
            {
              props: {
                layout: layout,
              },
              ref: this.getLayoutRef(layout.key),
            },
            undefined,
          ),
        );
      }

      // ---- Add confirm changes dialog ---- //
      children.push(
        createElement(
          OtDangerModal,
          {
            ref: this.confirmChangesDialogRef,
          },
          undefined,
        ),
      );

      return createElement('div', { class: 'ot-workflow-engine-processor' }, children);
    }
    return undefined;
  }
}
