



















































































import OtFieldArchetype from '@/components/global/archetypes/ot-field-archetype.vue';
import OtAutocomplete from '@/components/global/ot-autocomplete.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtDatePicker from '@/components/global/ot-date-picker.vue';
import OtDateTimeField from '@/components/global/ot-date-time-field.vue';
import OtDurationSelection from '@/components/global/ot-duration-selection.vue';
import OtRadioGroup, { IRadioGroupOption } from '@/components/global/ot-radio-group.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTextField from '@/components/global/ot-text-field.vue';
import OtTextarea from '@/components/global/ot-textarea.vue';
import { documentReceivalTypes, OtDocumentReceivalTypeEnum } from '@/types/document-receival-type';
import { ZonelessDate } from '@/types/zoneless-date';
import { compareToBrowserTimeZone, getBrowserTimezone } from '@/utils/date-utils';
import { isFuture } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { OtDataDrivenDefinition } from '../models/data-driven-definition';
import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { OtDataDrivenQuestion } from '../models/data-driven-question';
import { IResponsesGroupedByLayoutKey, OtDataDrivenResponse } from '../models/data-driven-response';
import { OtDataDrivenDocumentDetails } from '../models/data-driven-result';
import { IWorkflowComponentEditDefinition } from '../models/workflow-component-definition';

@Component({
  components: {
    OtRadioGroup,
    OtTextarea,
    OtFieldArchetype,
    OtDurationSelection,
    OtDatePicker,
    OtDateTimeField,
    OtAutocomplete,
    OtCheckbox,
    OtTextField,
    OtTag,
  },
})
export default class WfDocumentDetailsEdit extends Vue implements IWorkflowComponentEditDefinition {
  // * PROPS
  @Prop() public question!: OtDataDrivenQuestion;
  @Prop() public value!: OtDataDrivenResponse | null;
  @Prop() public readonly definition!: OtDataDrivenDefinition;
  @Prop() public readonly instance!: OtDataDrivenInstance;
  @Prop({ default: () => [] }) public readonly context2!: { [key: string]: unknown };
  @Prop({ default: () => [] }) public readonly defaultValues!: IResponsesGroupedByLayoutKey[];
  @Prop({ default: false }) public readonly!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop() public formContainerElement?: HTMLElement;

  // * REFS
  @Ref('componentWrapperRef') private readonly componentWrapperRef!: HTMLDivElement;

  // * DATA
  private title: string | null = null;
  private targetTimeZone: string | null = null;
  private targetTimeZoneDescription: string | null = null;
  private targetTimeZoneContextKey: string | null = null;

  // * COMPUTED

  private get browserTimezone() {
    return getBrowserTimezone();
  }

  private get timeZoneComparison() {
    const timezone = this.targetTimeZone;
    if (!timezone) {
      // we don't have a target. Pretend like things match
      // dunno how this can happen though
      return {
        match: true,
      };
    }
    return compareToBrowserTimeZone(timezone);
  }

  private documentNamePrivate: string | null = null;
  private get documentName() {
    return this.documentNamePrivate;
  }
  private set documentName(val: string | null) {
    this.documentNamePrivate = val;
    this.onChange();
  }

  // probably doesn't need to be a getter, feels like it could be static, but getter won't hurt
  private get documentReceivalTypeOptions(): IRadioGroupOption[] {
    return documentReceivalTypes.map(t => {
      return {
        label: t.name,
        key: t.value,
      };
    });
  }

  private documentReceivalTypePrivate: IRadioGroupOption | null = null;
  private get documentReceivalType() {
    return this.documentReceivalTypePrivate;
  }
  private set documentReceivalType(val: IRadioGroupOption | null) {
    this.documentReceivalTypePrivate = val;
    this.onChange();
  }

  private get isReceivalDescriptionDisabled() {
    return this.disabled || this.documentReceivalTypePrivate?.key !== OtDocumentReceivalTypeEnum.Other;
  }

  private documentReceivalDescriptionPrivate: string | null = null;
  private get documentReceivalDescription() {
    return this.documentReceivalDescriptionPrivate;
  }
  private set documentReceivalDescription(val: string | null) {
    this.documentReceivalDescriptionPrivate = val;
    this.onChange();
  }

  private documentCreationDatePrivate: ZonelessDate | null = null;
  private get documentCreationDate() {
    return this.documentCreationDatePrivate;
  }
  private set documentCreationDate(val: ZonelessDate | null) {
    // val is actually a date but because the getter is a ZonelessDate,
    // typescript expects the setter to be a ZonelessDate too
    // Yes, this is whack. The date picker does a dumb thing. :sad-noises:
    if (val) {
      this.documentCreationDatePrivate = new ZonelessDate(val);
    } else {
      this.documentCreationDatePrivate = null;
    }
    this.onChange();
  }

  private get documentCreationDateErrorMessages() {
    if (this.documentCreationDate && isFuture(this.documentCreationDate)) {
      return 'Cannot be in the future';
    }
    return undefined;
  }

  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  private documentReceivedLocalBrowserPrivate: Date | null = null;
  private get documentReceivedLocalBrowser() {
    return this.documentReceivedLocalBrowserPrivate;
  }
  private set documentReceivedLocalBrowser(val: Date | null) {
    this.documentReceivedLocalBrowserPrivate = val;
    this.onChange();
  }

  private get documentReceivedLocalBrowserErrorMessages() {
    if (this.documentReceivedLocalBrowser && isFuture(this.documentReceivedLocalBrowser)) {
      return 'Cannot be in the future';
    }

    if (
      this.documentCreationDate &&
      this.documentReceivedLocalBrowser &&
      !(this.documentReceivedLocalBrowser >= this.documentCreationDate)
    ) {
      return 'Must be >= Document Date';
    }
    return undefined;
  }

  /** This should be the local timezone: 6pm contract timezone is represented as 6pm local timezone */
  private documentDeemedReceivedLocalBrowserPrivate: Date | null = null;
  private get documentDeemedReceivedLocalBrowser() {
    return this.documentDeemedReceivedLocalBrowserPrivate;
  }
  private set documentDeemedReceivedLocalBrowser(val: Date | null) {
    this.documentDeemedReceivedLocalBrowserPrivate = val;
    this.onChange();
  }

  private get documentDeemedReceivedLocalBrowserErrorMessages() {
    if (this.documentDeemedReceivedLocalBrowser && isFuture(this.documentDeemedReceivedLocalBrowser)) {
      return 'Cannot be in the future';
    }

    if (
      this.documentCreationDate &&
      this.documentDeemedReceivedLocalBrowser &&
      !(this.documentDeemedReceivedLocalBrowser >= this.documentCreationDate)
    ) {
      return 'Must be >= Document Date';
    }
    return undefined;
  }

  private get result() {
    if (this.value) {
      if (this.value.result.resultType === 'DocumentDetailsModel') {
        return this.value.result;
      }
      console.warn(
        'wf-document-details-edit -> result -> ResultType is incorrect. Expected DocumentDetailsModel but got:  ',
        this.value.result.resultType,
      );
    }
    return null;
  }

  // * WATCHERS
  @Watch('value')
  private valueChanged() {
    console.log('wf-document-details-edit -> valueChanged ');

    const result = this.result;
    this.documentNamePrivate = result?.documentName || null;
    this.documentCreationDatePrivate = result?.documentCreationDate || null;
    this.documentReceivalTypePrivate =
      this.documentReceivalTypeOptions.find(x => x.key === result?.documentReceivalType) || null;
    this.documentReceivalDescriptionPrivate = result?.documentReceivalDescription || null;

    // server to client - convert these.
    // See https://github.com/marnusw/date-fns-tz#time-zone-offset-helpers for a worked example of why we are doing this conversion
    if (this.targetTimeZone) {
      // hooray, we know what timezone we are dealing with
      this.documentReceivedLocalBrowserPrivate = result?.documentReceivedUtc
        ? utcToZonedTime(result.documentReceivedUtc, this.targetTimeZone)
        : null;
      this.documentDeemedReceivedLocalBrowserPrivate = result?.documentDeemedReceivedUtc
        ? utcToZonedTime(result.documentDeemedReceivedUtc, this.targetTimeZone)
        : null;
    } else {
      // boo, we don't know yet
      // we could probably use local timezone, but who knows if that'll be correct
      // we have no timezone, this is bad. We'll have to use the values raw from the server
      console.warn(
        'wf-document-details-edit -> valueChanged -> there is no target timezone. The document received and deemed received will not be converted. This will lead to problems if the browser is in a different timezone to the actual target timezone.',
      );
      this.documentReceivedLocalBrowserPrivate = result?.documentReceivedUtc || null;
      this.documentDeemedReceivedLocalBrowserPrivate = result?.documentDeemedReceivedUtc || null;
    }
  }

  // * METHODS
  public onChange() {
    if (!this.targetTimeZone) {
      console.warn(
        'wf-document-details-edit -> onChange -> there is no target timezone. The document received and deemed received will not be converted before saving. This will lead to problems if the browser is in a different timezone to the actual target timezone.',
      );
    }

    const result = new OtDataDrivenDocumentDetails({
      resultType: 'DocumentDetailsModel',
      documentName: this.documentNamePrivate,
      documentCreationDate: this.documentCreationDatePrivate,
      documentReceivalDescription: this.documentReceivalDescriptionPrivate,
      // the as is a bit nasty here, but IRadioOption doesn't have a generic version we could use for this, so here we are
      // we know that the key will be an enum value, that's how the selectable optiosn are put together
      documentReceivalType: this.documentReceivalTypePrivate?.key as OtDocumentReceivalTypeEnum,
      // client to server - convert these from local timezone to contract timezone using https://github.com/marnusw/date-fns-tz#zonedtimetoutc
      // See https://github.com/marnusw/date-fns-tz#time-zone-offset-helpers for a worked example of why we are doing this conversion
      documentReceivedUtc:
        this.documentReceivedLocalBrowserPrivate && this.targetTimeZone
          ? zonedTimeToUtc(this.documentReceivedLocalBrowserPrivate, this.targetTimeZone)
          : this.documentReceivedLocalBrowserPrivate,
      documentDeemedReceivedUtc:
        this.documentDeemedReceivedLocalBrowser && this.targetTimeZone
          ? zonedTimeToUtc(this.documentDeemedReceivedLocalBrowser, this.targetTimeZone)
          : this.documentDeemedReceivedLocalBrowser,
    });

    const val = new OtDataDrivenResponse({
      questionKey: this.question.key,
      result: result,
      systemControlled: this.value?.systemControlled ?? false,
    });
    this.$emit('input', val);
  }

  // * LIFECYCLE
  private created() {
    this.title = this.question.title;

    // look some things up in the config and context
    const targetTimeZoneDescriptionConfigEntry = this.question.configs?.find(
      x => x.key === 'TargetTimeZoneDescription',
    );
    if (!targetTimeZoneDescriptionConfigEntry) {
      console.warn(
        'wf-document-details-edit -> created -> Unable to find config value TargetTimeZoneDescription in question. Will default to "Contract".',
        this.question,
      );
    }
    this.targetTimeZoneDescription = targetTimeZoneDescriptionConfigEntry?.value || 'Contract';

    const targetTimeZoneContextKeyConfigEntry = this.question.configs?.find(x => x.key === 'TargetTimeZoneContextKey');
    if (!targetTimeZoneContextKeyConfigEntry) {
      console.warn(
        'wf-document-details-edit -> created -> Unable to find config value TargetTimeZoneContextKey in question. Will default to "ContractTimezone".',
        this.question,
      );
    }
    this.targetTimeZoneContextKey = targetTimeZoneContextKeyConfigEntry?.value || 'ContractTimezone';

    const targetTimeZOneContext2Entry = this.context2[this.targetTimeZoneContextKey];
    if (!targetTimeZOneContext2Entry) {
      console.warn(
        'wf-document-details-edit -> created -> Unable to find context entry matching TargetTimeZoneContextKey. Will default to browser timezone.',
        { context2: this.context2, targetTimeZoneContextKey: this.targetTimeZoneContextKey },
      );
    }
    if (targetTimeZOneContext2Entry && typeof targetTimeZOneContext2Entry !== 'string') {
      console.warn(
        'wf-document-details-edit -> created -> Found context2 entry matching TargetTimeZoneContextKey, but it is not a string. Will use it anyway. Bad Things might happen.',
        { context2: this.context2, targetTimeZoneContextKey: this.targetTimeZoneContextKey },
      );
    }
    this.targetTimeZone = (targetTimeZOneContext2Entry as string) || this.browserTimezone;

    this.valueChanged();
  }
}
