import { OtDataDrivenInstance } from '../models/data-driven-instance';
import { IDataDrivenModelWithKey, IDataDrivenModelWithValues } from '../models/data-driven-result';

export function getSelectedKeysFromResponses(instance: OtDataDrivenInstance | undefined | null) {
  const singleOptionResponses = instance?.responses?.filter(r => !!(r.result as IDataDrivenModelWithKey).key);
  const multiOptionResponses = instance?.responses?.filter(r => !!(r.result as IDataDrivenModelWithValues).values);

  const singleOptionsResponseKeys = singleOptionResponses?.map(r => (r.result as IDataDrivenModelWithKey).key) || [];
  const multiOptionsResponseKeys =
    multiOptionResponses?.flatMap(r => (r.result as IDataDrivenModelWithValues).values.map(v => v.key)) || [];

  const selectedKeys = singleOptionsResponseKeys.concat(multiOptionsResponseKeys);

  return selectedKeys;
}
