export interface IState {
  shortName: string;
  fullName: string;
}

// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2174746740/Enums#Postal-States
export const AustralianStates: IState[] = [
  {
    shortName: 'NSW',
    fullName: 'New South Wales',
  },
  {
    shortName: 'QLD',
    fullName: 'Queensland',
  },
  {
    shortName: 'SA',
    fullName: 'South Australia',
  },
  {
    shortName: 'TAS',
    fullName: 'Tasmania',
  },
  {
    shortName: 'VIC',
    fullName: 'Victoria',
  },
  {
    shortName: 'WA',
    fullName: 'Western Australia',
  },
  {
    shortName: 'NT',
    fullName: 'Northern Territory',
  },
  {
    shortName: 'ACT',
    fullName: 'Australian Capital Territory',
  },
];
