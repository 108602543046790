// Data Driven Evaluation Rules Object
// https://redgum.atlassian.net/wiki/spaces/ONETRACK/pages/2382758419/Data+Driven+Evaluation+Rules+Object

import { DataDrivenEvaluationRulesModel as ApiDataDrivenEvaluationRulesModel } from '@/services/generated/api';
import { OtDataDrivenRule } from './data-driven-rule';
import { OtDataDrivenConfig } from './data-driven-config';

export interface IDataDrivenEvalutationRules {
  rules: OtDataDrivenRule[];
  // parameters is purely for serverside use. It won't be coming over the wire.
  // parameters: OtDataDrivenProperty[] | null;
  configs: OtDataDrivenConfig[] | null;
  configKeys: string[];
}

export class OtDataDrivenEvalutationRules implements IDataDrivenEvalutationRules {
  public rules!: OtDataDrivenRule[];
  public configs!: OtDataDrivenConfig[] | null;
  public configKeys!: string[];

  constructor(value: IDataDrivenEvalutationRules) {
    Object.assign(this, value);
  }

  public static createFromApiResponse(model: ApiDataDrivenEvaluationRulesModel) {
    return new OtDataDrivenEvalutationRules({
      rules: model.rules.map(r => OtDataDrivenRule.createFromApiResponse(r)),
      configs: model.configs ? model.configs.map(c => OtDataDrivenConfig.createFromApiResponse(c)) : null,
      configKeys: model.configKeys ?? [], // zero point supporting null, it makes no semantic difference if null or empty array
    });
  }

  public static createFromJson(model: Partial<OtDataDrivenEvalutationRules>) {
    return new OtDataDrivenEvalutationRules({
      rules: model.rules?.map(r => OtDataDrivenRule.createFromJson(r)) || [],
      configs: model.configs ? model.configs.map(c => OtDataDrivenConfig.createFromJson(c)) : null,
      configKeys: model.configKeys ?? [],
    });
  }
}
