

























import { SegmentInstance } from '@/areas/claims/claims-models';
import { ROUTE_WORKFLOW } from '@/areas/claims/claims-routes';
import OtClaimsTable from '@/areas/claims/ot-claims-table.vue';
import OtCheckboxGroup from '@/components/global/checkbox-group/ot-checkbox-group.vue';
import OtButton from '@/components/global/ot-button.vue';
import OtCheckbox from '@/components/global/ot-checkbox.vue';
import OtDueDateTag from '@/components/global/ot-due-date-tag.vue';
import OtTag from '@/components/global/ot-tag.vue';
import OtTableHeader from '@/components/global/table/ot-table-header.vue';
import OtTable from '@/components/global/table/ot-table.vue';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ProjectDetails } from '../project-models';

@Component({
  components: {
    OtTable,
    OtTableHeader,
    OtCheckboxGroup,
    OtCheckbox,
    OtTag,
    OtButton,
    OtDueDateTag,
    OtClaimsTable,
  },
})
export default class OtProjectClaimsIndex extends Vue {
  // * PROPS

  @Prop({ default: () => [] }) private segmentInstances!: SegmentInstance[];
  @Prop({ default: () => null }) project!: ProjectDetails | null;

  // * REFS

  // * DATA

  // * COMPUTED
  private get workflowRoute() {
    return ROUTE_WORKFLOW;
  }
  private get baseExportFileName() {
    return this.project?.name ?? 'project';
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
