











import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OtDocumentsLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private data: unknown;

  // * COMPUTED

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
