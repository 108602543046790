export class UnsavedChangesModalResult {
  public ok = false;

  constructor(params?: Partial<UnsavedChangesModalResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class UnsavedChangesModal {
  public open!: () => Promise<UnsavedChangesModalResult>;
}
