//borrowed from https://www.typescriptlang.org/play?ssl=16&ssc=1&pln=10&pc=1#code/PTAEGMHsDtwUwA4BcDOoBGkBOXIHc4ATUAM1wFtQALJJBFALhBSQENwBrSANzixIA2+AHRRywVsABsAJikAOeQE4ZARmCrVAVilKAUCFDRIRuEVBITcAB4JsSC1Tgo4FvJANgkVAJZo-jq4wrgQA5AICoACuLqTYoHhUkAKu0FHk6HwoelDQLBBROHCwAJ4AYtjkrLR8AOpJKQBy6ZlYoAC8pnigAJLQSALCzRl8FVhVSAAUocUAtACCAKqhADSgAN56oKAsJSkMoKHghVjF4CWrWwVFpQehSwAil9vkPtA+5OllWOxIPjAPHwAcx8qAOAAYVlcqtYPl8fuA-gDgaDGKBIXoAL4ASgA3J5HAEAt4gtAQnBwpEYq4SPFjNAEg1Ui0sjkYPljjdzmMJkg+A84OAPqwBGhOmTun0BkMWVgedVpnMlqsNlddvtDpzTqVntdtec7o9da93p9yN9fv9oICQWDQDIoTj8QTvAEUEkogJiJlQDY7Fg+YQ1ugog4kCUEM5wFgfMhQAgBKwSkDcFFoMRCJBnNBQg4BD4OK4-fZsrl8rTxtUAMInM4lDqgSaEaqsA4sGPQIGgAA+Rll2I6AD5Vdttj4SI3w5HIBPm2wOu1OqF228gaEB5tR1vQKckIUGaEACShUAAalAc9Y+K3mKuY4nTZboAApKBVAvOuCN3ft7v93q63lGosHqZI4GGVphArCZHzYPEf1vLc-ywBktUAypqj5LABSFKpRSgjCpkveDbzZPIwOEIQgUmaDq1rUpJlUGQAGYABZsXgssKKomjCJrLkSkmGRhFUDj8S4lJKMgajaKQfj9UE0JaUgdc8SAA

export const currencyFormatterWholeNumber = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const currencyFormatterDecimals = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatCurrencySmart = (data: string | number) => {
  if (typeof data === 'string') {
    return '$' + data;
  }
  if (data % 1 === 0) {
    return currencyFormatterWholeNumber.format(data);
  }
  return currencyFormatterDecimals.format(data);
};

export const formatCurrency = (data: string | number) => {
  if (typeof data === 'string') {
    return '$' + data;
  }
  return currencyFormatterDecimals.format(data);
};
