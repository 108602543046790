import { IVForm } from '@/utils/type-utils';

export class FormModalResult {
  public ok = false;

  constructor(params?: Partial<FormModalResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export enum FormModalSizeEnum {
  Regular = 1,
  Large = 2,
  ExtraLarge = 3,
  Auto = 4,
  Max = 5,
}

export class FormModalParams {
  public title!: string;
  public formRef!: IVForm;
  public confirmText = 'Confirm';
  public cancelText = 'Cancel';
  public persistent = true;
  public hideOverlay = false;
  public size: FormModalSizeEnum = FormModalSizeEnum.Regular;
  public confirmColor?: string;
  public cancelColor?: string;
  public confirmClass?: string;
  public cancelClass?: string;
  public bodyContainerCss?: { [prop: string]: string };
  public onBeforeConfirmClose?: () => Promise<boolean>;
  public onValidate?: () => Promise<boolean>;
  public onAfterFormMounted?: () => void;
  public hideCancelButton = false;

  public constructor(value?: Partial<FormModalParams>) {
    if (value) {
      Object.assign(this, value);
    }
  }
}

export class FormModal {
  public open!: (params?: FormModalParams) => Promise<FormModalResult>;
}

export class DangerModalParams {
  public confirmText = 'Confirm';
  public cancelText = 'Cancel';
  public hideConfirmButton = false;
  public hideCancelButton = false;
  public title!: string;
  public titleIcon: string | null = null;
  public message!: string;
  public useHtmlInMessage = false;

  public constructor(value?: Partial<DangerModalParams>) {
    if (value) {
      Object.assign(this, value);
    }
  }
}

export class DangerModalResult {
  public ok = false;

  constructor(params?: Partial<DangerModalResult>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}

export class DangerModal {
  public open!: (params?: DangerModalParams) => Promise<DangerModalResult>;
}
