



































import { Location } from 'vue-router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IInputTab } from './common-models';

@Component({})
export default class OtTabs extends Vue {
  // * PROPS
  @Prop() private tabsData?: IInputTab[];

  private isTabActive(tabRoute: Location) {
    if (tabRoute.path) {
      return this.$route.path === tabRoute.path;
    }

    if (this.$route.name && tabRoute.name) {
      const result = this.$route.name.localeCompare(tabRoute.name, undefined, { sensitivity: 'base' });
      return result === 0 ? true : false;
    }

    return false;
  }
  // * REFS

  // * DATA

  // * COMPUTED
  private get hasFooter() {
    return !!this.$slots.footer;
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
