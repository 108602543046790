











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class OtSwitch extends Vue {
  // * PROPS
  @Prop() private value!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED
  private get localValue(): boolean {
    return this.value;
  }
  private set localValue(val: boolean) {
    this.$emit('input', val);
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
