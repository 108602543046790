


















import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import OtWorkflowEngineProcessor from '@/components/workflow-engine/ot-workflow-engine-processor.vue';
import { ContractDetails } from './contract-models';
import { ProjectDetails } from '../project-models';
import { OtDataDrivenDefinition } from '@/wf-components/models/data-driven-definition';
import { OtDataDrivenInstance, OtDataDrivenInstances } from '@/wf-components/models/data-driven-instance';
import { IWizardContentComponent } from '@/types/wizard-types';

@Component({
  components: {
    OtWorkflowEngineProcessor,
  },
})
export default class OtContractAmendmentsForm extends Vue implements IWizardContentComponent {
  // * PROPS
  @Prop() private contractDetails!: ContractDetails;
  @Prop() private projectDetails!: ProjectDetails;
  @Prop() private layoutKey!: string;
  @Prop() private definition!: OtDataDrivenDefinition;
  @Prop() private instance!: OtDataDrivenInstance;
  @Prop() private instances!: OtDataDrivenInstances;
  @Prop({ default: false }) private isSaving!: boolean;

  // * REFS
  @Ref('workflowEngineRef') private readonly workflowEngineRef!: OtWorkflowEngineProcessor;

  // * DATA

  // * COMPUTED
  private get instanceInternal() {
    return this.instance;
  }
  private set instanceInternal(val: OtDataDrivenInstance) {
    this.$emit('update:instance', val);
  }

  // * WATCHERS

  // * METHODS
  public validate() {
    return this.workflowEngineRef.validate();
  }

  public async submit(validate: boolean): Promise<OtDataDrivenInstance | null> {
    return this.workflowEngineRef.submit(validate);
  }

  public setFormToCleanState() {
    return this.workflowEngineRef.setFormToCleanState();
  }

  // * LIFECYCLE
}
