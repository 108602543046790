














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IColumnData } from '@/components/global/table/ot-table-models';
import OtButton, { OtBtnSize, OtBtnStyle, OtBtnType } from '@/components/global/ot-button.vue';

@Component({
  components: {
    OtButton,
  },
})
export default class OtTableHeader extends Vue {
  // * PROPS
  @Prop() private data!: IColumnData;

  // * DATA
  private buttonSize = OtBtnSize.Tiny;
  private buttonStyle = OtBtnStyle.Outline;
  private buttonType = OtBtnType.Icon;

  // * COMPUTED
  private get upActive() {
    return this.data.isActive && this.data.ascending;
  }
  private get downActive() {
    return this.data.isActive && !this.data.ascending;
  }

  // * WATCHERS

  // * METHODS
  private headerClick() {
    if (!this.data.sortable) {
      return;
    }
    this.$emit('headerClick', this.data);
  }

  // * LIFECYCLE
}
