




















import { Component, Vue, Watch } from 'vue-property-decorator';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import { IInputTab } from '@/components/global/common-models';
import { UserProfile } from './models';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import { vxm } from '@/store';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
  },
})
export default class OtProfileLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA
  private loading = false;
  private userProfile: UserProfile = UserProfile.createEmpty();
  private profileTabs: IInputTab[] = [
    {
      tabId: 0,
      tabText: 'User Profile',
      tabRoute: {
        name: 'UserProfile',
      },
    },
  ];

  // * COMPUTED
  private get sectionTitle(): string {
    return this.userProfile.fullName;
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.loading = true;
    await this.getProfile();
    this.loading = false;
  }

  // * METHODS
  private async getProfile() {
    const profile = vxm.userProfile.userProfile;

    if (profile) {
      this.userProfile = profile;
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();
  }
}
