import {
  UserActions as ApiOrganisationUserActions,
  OrganisationUserStatus as ApiOrganisationUserStatus,
  UserStatusModel,
  UserStatusWithActionsModel,
} from '@/services/generated/api';
import { OtUserAction } from '@/types/action-enums';
import { OtUserStatus } from '@/types/status-enums';

// STEREOTYPE: ENUM MAPPING AND MAP FOR DISPLAY
// map the org user status
export const OtOrgUserStatusMap = new Map<OtUserStatus, string>([
  [OtUserStatus.Active, 'Active'],
  [OtUserStatus.Invited, 'Invited'],
  [OtUserStatus.RejectedInvitation, 'Rejected Invitation'],
  [OtUserStatus.Inactive, 'Inactive'],
]);

// for mapping API to App enum
export const parsedOtOrgUserStatusEnums: { [key in ApiOrganisationUserStatus]: OtUserStatus } = {
  [ApiOrganisationUserStatus.Active]: OtUserStatus.Active,
  [ApiOrganisationUserStatus.Invited]: OtUserStatus.Invited,
  [ApiOrganisationUserStatus.RejectedInvitation]: OtUserStatus.RejectedInvitation,
  [ApiOrganisationUserStatus.Inactive]: OtUserStatus.Inactive,
};

// for mapping App to API enum
export const parsedApiOrgUserStatusEnums: { [key in OtUserStatus]: ApiOrganisationUserStatus } = {
  [OtUserStatus.Active]: ApiOrganisationUserStatus.Active,
  [OtUserStatus.Invited]: ApiOrganisationUserStatus.Invited,
  [OtUserStatus.RejectedInvitation]: ApiOrganisationUserStatus.RejectedInvitation,
  [OtUserStatus.Inactive]: ApiOrganisationUserStatus.Inactive,
};

// user status with actions

export const parsedApiOrgUserActionsEnums: { [key in ApiOrganisationUserActions]: OtUserAction } = {
  [ApiOrganisationUserActions.Create]: OtUserAction.Create,
  [ApiOrganisationUserActions.View]: OtUserAction.View,
  [ApiOrganisationUserActions.Edit]: OtUserAction.Edit,
  [ApiOrganisationUserActions.Deactivate]: OtUserAction.Deactivate,
  [ApiOrganisationUserActions.Reinvite]: OtUserAction.Reinvite,
  [ApiOrganisationUserActions.Invite]: OtUserAction.Invite,
};

export const parsedOtOrgUserActionsEnums: { [key in OtUserAction]: ApiOrganisationUserActions } = {
  [OtUserAction.Create]: ApiOrganisationUserActions.Create,
  [OtUserAction.View]: ApiOrganisationUserActions.View,
  [OtUserAction.Edit]: ApiOrganisationUserActions.Edit,
  [OtUserAction.Deactivate]: ApiOrganisationUserActions.Deactivate,
  [OtUserAction.Reinvite]: ApiOrganisationUserActions.Reinvite,
  [OtUserAction.Invite]: ApiOrganisationUserActions.Invite,
};

export interface IUserStatus {
  gid: string;
  status: OtUserStatus;
  title: string;
}

export class UserStatus implements IUserStatus {
  public gid!: string;
  public status!: OtUserStatus;
  public title!: string;

  public constructor(value: IUserStatus) {
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new UserStatus({
      gid: '',
      status: OtUserStatus.Active,
      title: '',
    });
  }

  public static createFromApiResponse(value: UserStatusModel): UserStatus {
    return new UserStatus({
      gid: value.gid,
      status: parsedOtOrgUserStatusEnums[value.status],
      title: value.title,
    });
  }
}

export interface IUserStatusWithActions extends IUserStatus {
  availableActions: OtUserAction[];
}

export class UserStatusWithActions extends UserStatus implements IUserStatusWithActions {
  public availableActions!: OtUserAction[];

  public constructor(value: IUserStatusWithActions) {
    super(value);
    Object.assign(this, value);
  }

  public static createEmpty() {
    return new UserStatusWithActions({
      gid: '',
      status: OtUserStatus.Active,
      title: '',
      availableActions: [],
    });
  }

  public static createFromApiResponse(value: UserStatusWithActionsModel): UserStatusWithActions {
    return new UserStatusWithActions({
      gid: value.gid,
      status: parsedOtOrgUserStatusEnums[value.status],
      title: value.title,
      availableActions: value.availableActions.map(action => parsedApiOrgUserActionsEnums[action]),
    });
  }
}

export function isInvitableOrReinvitable(item: UserStatusWithActions | null | undefined) {
  if (!item) {
    return true;
  }
  return item.availableActions.some(x => x === OtUserAction.Invite || x === OtUserAction.Reinvite);
}
