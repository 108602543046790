<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 4C14.2 4 16 6.2 16 9C16 11.8 14.2 14 12 14C9.80002 14 8.00002 11.8 8.00002 9C8.00002 6.2 9.80002 4 12 4ZM12 22C14.5 22 16.9 21 18.6 19.5C19.1 19.1 19.1 18.5 18.7 18.1C17.6 16.8 16.2 15.9 14.5 15.4C13.7 15.8 12.9 16 12 16C11.1 16 10.3 15.8 9.50002 15.4C7.90002 15.9 6.40002 16.8 5.30002 18.1C4.90002 18.5 5.00002 19.1 5.40002 19.5C7.10002 21 9.50002 22 12 22Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
    stroke: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style></style>
