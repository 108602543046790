







































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import OtStandardHeaderArchetype from '@/components/global/archetypes/ot-standard-header-archetype.vue';
import OtTabs from '@/components/global/ot-tabs.vue';
import OtLoadingSpinner from '@/components/global/ot-loading-spinner.vue';
import OtButton from '@/components/global/ot-button.vue';

import { IInputAction, IInputTab } from '@/components/global/common-models';
import OtApi, { executeApi } from '@/services/api.service';
import { vxm } from '@/store';
import { Calendar } from './models/calendar-models';
import { Organisation } from './models/organisation-models';

import { ROUTE_SETTINGS, ROUTE_SETTINGS_CALENDARS, ROUTE_SETTINGS_USERS } from './settings-routes';
import OtOrganisationDropdownSelector from './organisations/ot-organisation-dropdown-selector.vue';
import { OrganisationUser } from './models/organisation-user-models';
import OtInviteUserDialog, { InviteUserDialogParams } from '../invitations/ot-invite-user-dialog.vue';

@Component({
  components: {
    OtStandardHeaderArchetype,
    OtTabs,
    OtLoadingSpinner,
    OtOrganisationDropdownSelector,
    OtButton,
    OtInviteUserDialog,
  },
})
export default class OtSettingsLayout extends Vue {
  // * PROPS

  // * REFS

  // * DATA

  private newCalendarButton: IInputAction = {
    actionName: 'New Calendar',
    action: { name: 'AddCalendar' },
  };

  private newOrgButton: IInputAction = {
    actionName: 'New Organisation',
    // this is not actually used, we disable when this is displayed
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: () => {},
  };
  private newUserButton: IInputAction = {
    actionName: 'New User',
    action: () => this.createNewUser(),
  };

  private get settingsTabs(): IInputTab[] {
    return [
      {
        tabId: 0,
        tabText: 'Organisation Details',
        tabRoute: {
          params: { orgId: this.orgId },
          name: ROUTE_SETTINGS,
        },
      },
      {
        tabId: 1,
        tabText: 'Users',
        tabRoute: {
          params: { orgId: this.orgId },
          name: ROUTE_SETTINGS_USERS,
        },
      },
      {
        tabId: 2,
        tabText: 'Calendars',
        tabRoute: {
          params: { orgId: this.orgId },
          name: ROUTE_SETTINGS_CALENDARS,
        },
      },
    ];
  }

  private api = new OtApi();
  private loading = false;
  private allCalendars: Calendar[] = [];
  private organisation: Organisation | null = null;
  private organisationUsers: OrganisationUser[] = [];
  private lastLoadedCalendarsForOrg = '';

  // * COMPUTED
  private get orgId() {
    return this.$route.params.orgId || vxm.userProfile.defaultOrganisationId || '';
  }

  private get orgList() {
    return vxm.userProfile.userProfile?.organisations || [];
  }

  private get orgName() {
    return this.orgList.find(org => org.gid === this.orgId)?.name || '';
  }

  private get routePath(): string | null {
    return this.$route.path || null;
  }
  private get currentRouteName() {
    return this.$route.name;
  }

  private get primaryContextBtn() {
    switch (this.currentRouteName) {
      case ROUTE_SETTINGS:
        return this.newOrgButton;
      case ROUTE_SETTINGS_USERS:
        return this.newUserButton;
      case ROUTE_SETTINGS_CALENDARS:
        return this.newCalendarButton;
      default:
        console.warn(
          `OtSettingsLayout.primaryContextBtn - no primary button defined for '${this.currentRouteName}' - defaulting to newCalendarButton`,
        );
        return this.newCalendarButton;
    }
  }

  private get secondaryContextBtns() {
    switch (this.currentRouteName) {
      case ROUTE_SETTINGS:
        return [];
      case ROUTE_SETTINGS_USERS:
        return [];
      case ROUTE_SETTINGS_CALENDARS:
        return [];
      default:
        console.warn(
          `OtSettingsLayout.secondaryContextBtns - no secondary buttons defined for '${this.currentRouteName}' - defaulting to []`,
        );
        return [];
    }
  }

  private get disableContextButton() {
    return this.currentRouteName !== ROUTE_SETTINGS_CALENDARS && this.currentRouteName !== ROUTE_SETTINGS_USERS;
  }

  // * WATCHERS
  @Watch('routePath')
  private async checkRoutePath() {
    this.setOrgNameToBreadcrumb();
    if (this.$route.name === ROUTE_SETTINGS_CALENDARS && !this.allCalendars.length) {
      this.lastLoadedCalendarsForOrg = this.orgId;
      this.loading = true;
      await this.handleReloadCalendars();
      this.loading = false;
    }
    if (
      this.$route.name === ROUTE_SETTINGS_CALENDARS &&
      this.allCalendars.length &&
      this.orgId !== this.lastLoadedCalendarsForOrg
    ) {
      this.lastLoadedCalendarsForOrg = this.orgId;

      this.loading = true;
      await this.handleReloadCalendars();
      this.loading = false;
    }

    if (this.$route.name === ROUTE_SETTINGS && !this.organisation) {
      this.loading = true;
      await this.handleReloadOrganisationDetails();
      this.loading = false;
    }

    if (this.$route.name === ROUTE_SETTINGS && this.organisation && this.organisation.gid !== this.orgId) {
      this.loading = true;
      await this.handleReloadOrganisationDetails();
      this.loading = false;
    }

    if (this.$route.name === ROUTE_SETTINGS_USERS && !this.organisationUsers.length) {
      this.loading = true;
      await this.handleReloadOrganisationUsers();
      this.loading = false;
    }
  }

  // * METHODS
  setOrgNameToBreadcrumb() {
    vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
  }

  updateOrg(orgId: string) {
    this.$router.push({ name: this.currentRouteName || ROUTE_SETTINGS, params: { orgId } });
  }

  private async handleReloadCalendars(callback?: () => void) {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationCalendars(this.orgId),
      'Load Calendars',
    );
    if (result.success && result.data && result.data.calendars) {
      this.allCalendars = result.data.calendars.map(c => Calendar.createFromApiResponse(c));
    }

    if (callback) {
      callback();
    }
  }

  private async handleReloadOrganisationDetails() {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationDetails(this.orgId),
      'Load Organisation Details',
    );
    if (result.success && result.data) {
      this.organisation = Organisation.createFromApiResponse(result.data);
    }
  }

  private async handleReloadOrganisationUsers(callback?: () => void) {
    const result = await executeApi(
      () => this.api.organisation().getOrganisationUsers(this.orgId),
      'Load Organisation Users',
    );
    if (result.success && result.data && result.data?.users) {
      this.organisationUsers = result.data.users.map(u => OrganisationUser.createFromApiResponse(u));
    }

    if (callback) {
      callback();
    }
  }

  @Ref('inviteUserDialogRef')
  private readonly inviteUserDialogRef!: OtInviteUserDialog;

  private async createNewUser() {
    const params = new InviteUserDialogParams({
      isReinvite: false,
      organisationGid: this.orgId,
      inviteToOrganisation: true,
    });

    const dialogResult = await this.inviteUserDialogRef.open(params);
    if (dialogResult) {
      this.handleReloadOrganisationUsers();
    }
  }

  // * LIFECYCLE
  private created() {
    this.checkRoutePath();

    if (this.orgId) {
      vxm.breadcrumbs.setOrganisationName({ organisation: this.orgName });
    }
  }
}
