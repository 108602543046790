




























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class OtExpansionPanel extends Vue {
  // * PROPS
  @Prop() private title?: string;
  @Prop({ default: false, type: Boolean }) private alignTitleWithViewDetails!: boolean;
  @Prop({ default: true, type: Boolean }) private value!: boolean;

  // * REFS

  // * DATA
  private data: unknown;

  // * COMPUTED
  private get openPanels(): number | undefined {
    if (this.value) {
      return 0;
    }
    return undefined;
  }
  private set openPanels(val: number | undefined) {
    this.$emit('input', val === 0);
  }

  // * WATCHERS

  // * METHODS

  // * LIFECYCLE
}
