












import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  OtSegmentStatus,
  OtContractStatus,
  OtOrganisationStatus,
  OtProjectStatus,
  OtUserStatus,
  OtAmendedStatus,
  OtStatusType,
} from '@/types/status-enums';
import { OtCalendarStatus } from '@/areas/settings/models/calendar-models';

export type TagStatus =
  | {
      type: OtStatusType.Generic;
      status: OtAmendedStatus;
    }
  | {
      type: OtStatusType.Organisation;
      status: OtOrganisationStatus;
    }
  | {
      type: OtStatusType.Calendar;
      status: OtCalendarStatus;
    }
  | {
      type: OtStatusType.User;
      status: OtUserStatus;
    }
  | {
      type: OtStatusType.Segment;
      status: OtSegmentStatus;
    }
  | {
      type: OtStatusType.Contract;
      status: OtContractStatus;
    }
  | {
      type: OtStatusType.Project;
      status: OtContractStatus;
    }
  | {
      type: OtStatusType.Project;
      status: OtProjectStatus;
    };

@Component({})
export default class OtTag extends Vue {
  // * PROPS
  @Prop(String) private text?: string;
  @Prop(String) private suffix?: string;
  @Prop() private status?: TagStatus;
  @Prop({ type: Boolean, default: false }) private isInTable!: boolean;
  @Prop({ type: Boolean, default: false }) private inline!: boolean;

  // * REFS

  // * DATA

  // * COMPUTED

  private get computedText() {
    if (this.text) {
      return this.text;
    }
    if (this.status) {
      return this.status.status + (this.suffix ? `${this.suffix} ` : '');
    }
    return '';
  }

  // TODO need to add the user statuses in here
  private get className() {
    let appendedClass = '';
    if (this.status) {
      switch (this.getUniqueStatusName(this.status)) {
        case this.getUniqueStatusName({ type: OtStatusType.Project, status: OtProjectStatus.Active }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.Active }):
        case this.getUniqueStatusName({ type: OtStatusType.User, status: OtUserStatus.Active }):
        case this.getUniqueStatusName({ type: OtStatusType.Calendar, status: OtCalendarStatus.Active }):
        case this.getUniqueStatusName({ type: OtStatusType.Organisation, status: OtOrganisationStatus.Active }):
          appendedClass = '--success';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Project, status: OtProjectStatus.Pending }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.Pending }):
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.InProgress }):
        case this.getUniqueStatusName({ type: OtStatusType.User, status: OtUserStatus.Invited }):
        case this.getUniqueStatusName({ type: OtStatusType.Organisation, status: OtOrganisationStatus.Pending }):
          appendedClass = '--warning';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Project, status: OtProjectStatus.Suspended }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.Terminated }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.Cancelled }):
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.Suspended }):
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.Cancelled }):
        case this.getUniqueStatusName({ type: OtStatusType.User, status: OtUserStatus.RejectedInvitation }):
        case this.getUniqueStatusName({ type: OtStatusType.Calendar, status: OtCalendarStatus.Inactive }):
        case this.getUniqueStatusName({ type: OtStatusType.Organisation, status: OtOrganisationStatus.Suspended }):
          appendedClass = '--error';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Project, status: OtProjectStatus.Complete }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.PracticalCompletion }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.FinalCompletion }):
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.Completed }):
          appendedClass = '--primary';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.Disputed }):
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.LegalDispute }):
          appendedClass = '--error-outline';
          break;
        case this.getUniqueStatusName({
          type: OtStatusType.Segment,
          status: OtSegmentStatus.InfoRequestedAndSuspended,
        }):
          appendedClass = '--primary-outline';
          break;
        case this.getUniqueStatusName({
          type: OtStatusType.Segment,
          status: OtSegmentStatus.InfoRequestedAndNotSuspended,
        }):
          appendedClass = '--success-outline';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Segment, status: OtSegmentStatus.Withdrawn }):
        case this.getUniqueStatusName({ type: OtStatusType.User, status: OtUserStatus.Inactive }):
        case this.getUniqueStatusName({ type: OtStatusType.Organisation, status: OtOrganisationStatus.Inactive }):
        case this.getUniqueStatusName({ type: OtStatusType.Contract, status: OtContractStatus.Draft }):
          appendedClass = '--greyscale';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Generic, status: OtAmendedStatus.Amended }):
          appendedClass = '--success-box-outline';
          break;
        case this.getUniqueStatusName({ type: OtStatusType.Generic, status: OtAmendedStatus.Unamended }):
          appendedClass = '--greyscale-box-outline';
          break;
      }
    }

    if (this.isInTable) {
      return `${appendedClass} --in-table`;
    } else if (this.inline) {
      return `${appendedClass} --inline`;
    }
    return appendedClass;
  }

  // * WATCHERS

  // * METHODS
  // Becuase there is a lot of overlap in types,
  // we need to make sure each enum has a unique value to check against
  private getUniqueStatusName(tag: TagStatus) {
    return `${tag.type} + ${tag.status}`;
  }

  // * LIFECYCLE
}
